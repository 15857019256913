import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Cropper from "shared-components/Modal/Cropper";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { UploadUserProfile } from "services/AdminPortal/UploadService";
import { GetUserProfile, UpdateProfile, ChangePassword } from "services/AdminPortal/UserService";

import profileImg from "assets/img/user-img.svg";
import camera from "assets/icons/white/camera.svg";
import close from "assets/icons/grey/close-circle.svg";
import closeCircle from "assets/icons/orange/close-circle.svg";
import styles from "assets/jss/components/userSettingsStyle.js";

import { updateImg, updateProfileRequest, resetPassword, reset } from ".";

const useStyles = makeStyles(styles);

export default function UserProfile() {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const isDirty = useSelector(store => store.general.isDirty);
  const profile = useSelector(store => store.settings.profile);
  const timezoneLookup = useSelector(store => store.lookup.timezoneLookup);
  const [changePassword, setChangePassword] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [openCropModal, setOpenCropModal] = React.useState(false);

  const onButtonClick_image = () => {
    dispatch(setDirty(true));
    inputFile.current.click();
  }

  const handleOnChange_image = (e) => {
    dispatch(setDirty(true));
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleModal_crop();
    e.target.value = "";
  }
  
  const handleButtonClick_remove = () => {
    dispatch(setDirty(true));
    setImageUrl("");
    dispatch(updateImg(""));
  }

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    if (e.target.id === 'userName') {
      e.target.value = e.target.value.replace(/\s/g, '');
    }
    dispatch(updateProfileRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateProfileRequest({[e.target.name] : e.target.value}));
  };

  const handleOnClose_password = () => {
    dispatch(setDirty(true));
    setChangePassword(false);
    dispatch(resetPassword());
  };

  const handleButtonClick = () => {
    if (validateFields()) {
      dispatch(setDirty(false));
      if (imageFile) {
        dispatch(UploadUserProfile(imageFile))
        .then(() => {
          updateProfile();
        })
      } else {
        updateProfile();
      }
    }
  };

  const updateProfile = () => {
    dispatch(UpdateProfile())
    .then(() => {
      if (changePassword) {
        dispatch(ChangePassword(profile))
        .then((response) => {
          if (response.error) {
            setErrorMsg({field: "oldPassword", msg: response.payload.message});
          } else {
            resetState();
          }
        });
      } else {
        resetState();
      }
    });
  }

  const resetState = () => {
    dispatch(setDirty(false));
    setChangePassword(false);
  }

  const validateFields = () => {
    if (_.isEmpty(profile.name)) {
      setErrorMsg({field: "name", msg: "Name cannot be empty"});
      return false;
    }
    if (profile.phone && !validate.isPhoneNumber(profile.phone)) {
      setErrorMsg({field: "phone", msg: "Invalid phone number"});
      return false;
    }
    if (changePassword) {
      if (_.isEmpty(profile.oldPassword)) {
        setErrorMsg({field: "oldPassword", msg: "Password cannot be empty"});
        return false;
      }
      if (_.isEmpty(profile.newPassword)) {
        setErrorMsg({field: "newPassword", msg: "Password cannot be empty"});
        return false;
      }
      if (_.isEmpty(profile.confirmPassword)) {
        setErrorMsg({field: "confirmPassword", msg: "Password cannot be empty"});
        return false;
      }
      if (!validate.isValidPassword(profile.newPassword)) {
        setErrorMsg({field: "newPassword", msg: "Password should contain at least 8 characters, a combination of uppercase and lowercase letters, numbers and symbols"});
        return false;
      }
      if (!_.isEqual(profile.newPassword, profile.confirmPassword)) {
        setErrorMsg({field: "confirmPassword", msg: "Password does not match"});
        return false;
      }
      else {
        setErrorMsg(false);
        return true;
      }
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  const handleModal_crop = () => {
    setOpenCropModal(!openCropModal);
  }

  const handleButtonClick_crop = (blob) => {
    setOpenCropModal(false);
    setImageFile(blob);
    dispatch(updateImg(URL.createObjectURL(blob)));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetUserProfile());
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setDirty(false));
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        className={classes.container}
        classes={{
          root: classes.container,
          content: classes.content,
          cardActions: classes.actions
        }}
        title="Profile Settings"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick()}
              disabled={!isDirty}
            >
              Update
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={4} className={classes.label}>
            <Typography>User Image:</Typography>
          </Grid>
          <Grid item xs={8}>
            <input 
              ref={inputFile} 
              style={{"display": "none"}} 
              type="file" 
              accept="image/*"
              onChange={(e) => handleOnChange_image(e)}
            />
            <IconButton className={classes.profileImg} onClick={onButtonClick_image}>
              <img className={classes.img} src={profile.profileUrl ? profile.profileUrl : profileImg} alt="profile-pic" />
              <span className={classes.iconButton}>
                <img className={classes.cameraIcon} src={camera} alt="camera" />
              </span>
            </IconButton>
            <IconButton 
              className={classes.closeIcon} 
              onClick={() => handleButtonClick_remove()}
            >
              <img className={classes.icon} src={close} alt="close" />
            </IconButton>
          </Grid>
          <Grid item xs={4} className={classes.label}>
            <Typography>Username:</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField 
              id="userName"
              variant="outlined" 
              onChange={(e) => handleOnChange_text(e)}
              value={!profile.userName ? "" : profile.userName}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.label}>
            <Typography>Email Address:</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField 
              id="emailAddress"
              variant="outlined" 
              value={!profile.emailAddress ? "" : profile.emailAddress}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.label}>
            <Typography>Display Name:</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField 
              id="name"
              variant="outlined" 
              inputProps={{ maxLength: 64 }}
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(profile.name)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4} className={classes.label}>
            <Typography>Phone Number:</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField 
              id="phone"
              type="number"
              variant="outlined"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
              }} 
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(profile.phone)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={4} className={classes.label}>
            <Typography>Time Zone:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              name="timeZone"
              onChange={(e)=>handleOnChange_select(e)}
              value={renderValue(profile.timeZone)}
              placeholder="Select a Timezone"
            >
              {timezoneLookup && timezoneLookup.map((item, index) => {
                return <MenuItem key={index} value={item.code}>{item.displayName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.label}>
            <Typography>Password:</Typography>
          </Grid>
          <Grid item xs={8} className="flex justify-between">
            <a 
              className={changePassword ? classes.passwordTextDisabled : classes.passwordText} 
              onClick={()=>setChangePassword(true)} 
              disabled={changePassword}
            >
              Change Password
            </a>
            { changePassword &&
              <IconButton 
                onClick={()=>handleOnClose_password()}
              >
                <img className={classes.icon_24} src={closeCircle} alt="close-circle" />
              </IconButton>
            }
          </Grid>
          { changePassword && 
            <React.Fragment>
              <Grid item xs={4} className={classes.label}>
                <Typography>Old Password:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  id="oldPassword"
                  variant="outlined" 
                  type="password"
                  value={renderValue(profile.oldPassword)}
                  onChange={(e) => handleOnChange_text(e)}
                  errorMsg={errorMsg}
                />
              </Grid>
              <Grid item xs={4} className={classes.label}>
                <Typography>New Password:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  id="newPassword"
                  variant="outlined" 
                  type="password"
                  value={renderValue(profile.newPassword)}
                  onChange={(e) => handleOnChange_text(e)}
                  errorMsg={errorMsg}
                />
              </Grid>
              <Grid item xs={4} className={classes.label}>
                <Typography>Confirm Password:</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  id="confirmPassword"
                  variant="outlined" 
                  type="password"
                  value={renderValue(profile.confirmPassword)}
                  onChange={(e) => handleOnChange_text(e)}
                  errorMsg={errorMsg}
                />
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </Card>
      <Cropper
        open={openCropModal}
        onClose={() => handleModal_crop()}
        imageFile={imageUrl}
        onConfirm={(blob) => handleButtonClick_crop(blob)}
      />
    </React.Fragment>
  );
}
