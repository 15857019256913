import {
  icon_48,
  container,
  button,
  primaryColor
} from "assets/jss/generaliseStyle.js";

const headerStyle = () => ({
  appBar: {
    backgroundColor: "#F0F2F4", // custom#customiseise
    boxShadow: "0 0 10px 0 #AEC1AE",  // #customise
    borderBottom: "0",
    marginBottom: "0px",
    position: "sticky",
    width: "100%",
    zIndex: "1029",
    border: "0",
    padding: "0.521vw 0",
    transition: "all 150ms ease 0s",
    display: "block",
  },
  container: {
    ...container,
    display: "flex",
    justifyContent: "space-between"
  },
  leftContainer: {
    lineHeight: "1.831vw",
    flex: 1,
    minHeight: "2.396vw",
    maxHeight: "2.396vw",
  },
  title: {
    fontWeight: "700",
    fontSize: "1.563vw",
    color: primaryColor[4],
    float: "left",
    paddingTop: "0.260vw"
  },
  addNewButton: {
    ...button,
    minHeight: "2.396vw",
    maxHeight: "2.396vw",
    marginLeft: "1.563vw",
    fontWeight: "700",
  },
  menu: {
    color: primaryColor[4]
  },
  chatbotImg: {
    ...icon_48,
  }
});

export default headerStyle;
