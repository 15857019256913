import { createSlice } from "@reduxjs/toolkit";
import { Authenticate } from "services/TokenAuthService";

// initial state
const initialState = {
  userNameOrEmailAddress: null,
  password: null,
  rememberClient: false,
  error: false,
};

// create reducer and action creators
const login = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [Authenticate.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

// export actions
export const { loginRequest, reset } = login.actions;

// export the reducer
export default login.reducer;