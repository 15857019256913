import { createSlice } from "@reduxjs/toolkit";

import { 
  GetAllEquipmentCost
} from "services/UserPortal/EquipmentService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  totalCost: 0,
  costs: [],
};

const costEquip = createSlice({
  name: "costEquip",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      state.issuedId = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllEquipmentCost.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.totalCost = action.payload.result.totalCost;
      state.costs = action.payload.result.items;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = costEquip.actions;

// export the reducer
export default costEquip.reducer;