import {
  button,
  buttonSecondary,
  cardRadius,
  border
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table
} from "assets/jss/components/commonStyle.js";

const predictionStyle = {
  button,
  buttonSecondary,
  cardRadius,
  cardPaper,
  table,

  margin: {
    marginRight: "6.250vw"
  },
  flexContainer: {
    display: "flex",
    marginBottom: "2.083vw",
    "& p": {
      marginRight: "1.042vw",
      marginLeft: "1.042vw",
    }
  },
  label: {
    alignSelf: "center",
    width: "fit-content",
    whiteSpace: "nowrap"
  },
  buttonAlign: {
    justifyContent: "flex-end"
  },
  paper: {
    ...cardRadius,
    border,
  },
  cardContent: {
    height: "16.2vw",
    maxHeight: "25.625vw",
    overflow: "overlay",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    borderRadius: "0 0 0.833vw 0.833vw",
  },
  tableHeader: {
    '& thead > tr > th': {
      borderTopLeftRadius: "0.833vw",
      borderTopRightRadius: "0.833vw",
      left: "unset"
    },
  },
  content: {
    overflow: "hidden"
  }
};

export default predictionStyle;
  