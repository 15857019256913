import { createSlice } from "@reduxjs/toolkit";

import { SearchProduct } from "services/AdminPortal/ProductService";

// initial state
const initialState = {
  searchResultsCount: {},
  searchResults: {}
};

const searchProduct = createSlice({
  name: "searchProduct",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [SearchProduct.fulfilled]: (state, action) => {
      let productList = [];
      let productListCount = 0;
      Object.keys(action.payload.result).forEach((key) => {
        if (key === "productFinishList" || key === "productUnfinishList") {
          productListCount += action.payload.result[key] ? action.payload.result[key].totalCount : 0;
          productList = action.payload.result[key] ? [...productList, ...action.payload.result[key].items] : productList;
          if (productListCount) {
            state.searchResultsCount.productList = productListCount;
            state.searchResults.productList = productList;
          }
        } else {
          if (action.payload.result[key]) {
            state.searchResultsCount[key] = action.payload.result[key].totalCount;
            state.searchResults[key] = action.payload.result[key].items;
          }
        }
      })
    },
  },
});

// export actions
export const { reset } = searchProduct.actions;

// export the reducer
export default searchProduct.reducer;