import {
  icon_24,
  icon_64,
  cardRadius,
  border,
  button,
  buttonSecondary,
  backgroundColor,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  link,
} from "assets/jss/components/commonStyle.js";

const homeStyle = {
  icon_24,
  icon_64,
  cardRadius,
  cardPaper,
  button,
  buttonSecondary,
  table,
  link,

  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },

  cardButton: {
    margin: "-0.573vw 0"
  },
  cardMargin: {
    marginBottom: "2.083vw"
  },
  environmentPaper: {
    ...cardRadius,
    border,
    padding: "1.719vw 1.563vw",
    display: "flex",
    justifyContent: "space-between"
  },
  environmentIcon: {
    ...icon_64,
    alignSelf: "center",
    marginRight: "0.521vw"
  },
  environmentText: {
    alignSelf: "center",
    fontSize: "0.938vw",
  },
  environmentValue: {
    fontSize: "1.979vw",
    fontWeight: 700,
    color: secondaryColor[0]
  },
  environmentUnit: {
    fontSize: "0.938vw",
    fontWeight: 500,
    color: secondaryColor[0]
  },
  environmentValue2: {
    fontSize: "1.979vw",
    fontWeight: 700,
    color: "#00CFB4"
  },
  environmentUnit2: {
    fontSize: "0.938vw",
    fontWeight: 500,
    color: "#00CFB4"
  },
  logoImage: {
    marginTop: "2.083vw",
    marginBottom: "1.094vw",
    width: "3.333vw",
    height: "3.333vw",
  },
  logoTitle: {
    padding: "0vw 1.771vw 2.083vw 1.823vw",
    lineHeight: "1.302vw",
  },
  // Widgets
  widgetPaper: {
    height: "27.083vw",
    display: "flex",
    flexDirection: "column",
  },
  widgetDrag: {
    cursor: "pointer"
  },
  widgetContent:{
    overflow: "overlay",
    borderRadius: "0 0 0.833vw 0.833vw"
  },
  addPaper: {
    ...cardRadius,
    justifyContent: "center",
    border: "0.104vw dashed "+primaryColor[1],
    textAlign: "center",
    padding: "1.927vw",
    alignItems: "center",
  },
  widgetAddTitle: {
    fontSize: "1.771vw",
    fontWeight: 500,
    marginBottom: "1.406vw"
  },
  addButton: {
    marginTop: "2.188vw"
  },
  widgetModal: {
    width: "36.458vw"
  },
  modalContent: {
    textAlign: "left",
    paddingTop: "2.083vw"
  },
  widgetAction : {
    marginTop: "1.563vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  viewAllButton: {
    padding: "0.521vw 1.042vw"
  },
  pagination: {
    width: "fit-content",
    '& ul > li:not(:last-child)': {
      marginRight: "0.417vw",
    },
    '& ul > li > button': {
      borderRadius: "4px",
      padding: "0 0.260vw"
    },
    '& ul > li > button.Mui-selected': {
      color: whiteColor,
      backgroundColor: secondaryColor[0],
    },
  },
  // Widget Content
  widgetTitle: {
    fontWeight: 500,
    fontSize: "0.729vw !important",
    color: primaryColor[3],
    marginBottom: "0.521vw"
  },
  tableTitle: {
    fontSize: "0.729vw !important",
    color: primaryColor[3]+"B2"
  },
  tableContent: {
    fontWeight: 500,
    fontSize: "1.042vw",
    color: primaryColor[3],
  },
  tableSubContent: {
    fontSize: "0.833vw",
  },
  paper: {
    ...cardRadius,
    backgroundColor,
    padding: "1.042vw",
    height: "100%",
  },
  paperContent: {
    fontWeight: 500,
    // fontSize: "0.833vw",
  },
  paperValue: {
    fontWeight: 500,
    fontSize: "1.563vw",
  },
  listPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  progressTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  progressBar: {
    height: "0.938vw",
    borderRadius: "0.417vw",
    border: "0.052vw solid #F6F6F6",
    backgroundColor: "#F6F6F6",
    marginTop: "0.781vw",
  },
  progress: {
    backgroundColor: secondaryColor[0],
  },
  widgetMargin: {
    marginTop: "0.833vw"
  },
  halfPieChart: {
    display: "flex",
    justifyContent: "center",
    height: "10.417vw",
  },
  pieChart: {
    display: "flex",
    width: "11.458vw",
    height: "11.458vw",
  },
  legend: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  legendColor: {
    minWidth: "0.625vw",
    height: "0.625vw",
    borderRadius: "50%",
    backgroundColor: "#00CFB4",
    marginRight: "0.260vw"
  },
  legendColor2: {
    minWidth: "0.625vw",
    height: "0.625vw",
    borderRadius: "50%",
    backgroundColor: secondaryColor[0],
    marginRight: "0.260vw",
    marginLeft: "0.521vw",
  },
  legendLabel: {
    marginRight: "0.260vw",
    marginLeft: "0.260vw"
  },
  dropzone: {
    marginRight: "-0.833vw"
  },
  draggable: {
    paddingRight: "0.833vw",
    cursor: "pointer"
  },
}

export default homeStyle;