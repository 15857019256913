import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const customerStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  orange,
  icon,

  // Search Customer
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display customer
  table,

  // Add Edit Customer
  paper: {
    ...cardRadius,
    border,
    display: "flex",
    flexDirection: "column",
    margin: "1.563vw 15.104vw 5.208vw",
    padding: "3.646vw 7.292vw 0"
  },
  label: {
    marginTop: "0.833vw",
  },
  action: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "2.708vw 0",
    padding: 0
  },
}

export default customerStyle;