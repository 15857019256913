/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrMissionStatus } from "enums/Constants"; 
import { amrTaskTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, filterUrl } from 'common/functions';
import { setValues } from "../store/adhoc";
import { ExecuteGetAllRobots, ExecuteGetAllMission, ExecuteCancelMission } from "services/UserPortal/AMRService";

import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function TaskList() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.amr.adhoc.filter);
  const result = useSelector(store => store.user.amr.adhoc.result);
  const amrList = useSelector(store => store.user.amr.adhoc.amrList);
  const [newFilter, setNewFilter] = React.useState({robotId: [], task_type: "All", created_by: null, page: 0});

  const count = Math.ceil(result.length / rowsPerTable);

  const handleButtonClick_cancel = (id) => {
    dispatch(ExecuteCancelMission({missionId: id}))
    .then(() => {
      getAllMission();
    });
  }

  const handleOnChange_select = (e) => {
    _.remove(e.target.value, (n) => n === "Select Value");
    setNewFilter({...newFilter, [e.target.name] : e.target.value});
  };
  
  const getAllMission = (stopLoading) => {
    dispatch(ExecuteGetAllMission({...newFilter, stopLoading}));
  }

  React.useEffect(() => {
    getAllMission();
  },[newFilter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(ExecuteGetAllRobots({stopLoading: true}));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllMission(true);
  });

  return (
    <React.Fragment>
      <div className={classes.filterFields}>
        <div className={classes.filterField}>
          <Typography>AMR:</Typography>
          <Select
            name="robotId"
            multiple
            value={newFilter.robotId}
            renderValue={(value) => newFilter.robotId&&newFilter.robotId.length === 0 ? value :_.join(_.map(_.filter(amrList, (item) => _.includes(value, item.id)), (item) => item.alias), ', ')}
            onChange={(e)=>handleOnChange_select(e)}
            placeholder="Select Value"
          >
            { amrList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  <Checkbox checked={_.findIndex(newFilter.robotId, (selected) => selected === item.id) > -1} />
                  {item.alias}
                </MenuItem>
              );
            })} 
          </Select>
        </div>
        <div className={classes.filterField}>
          <Typography>Task Type:</Typography>
          <Select
            className={classes.filterSelect}
            name="task_type"
            onChange={(e)=>handleOnChange_select(e)}
            value={newFilter.task_type}
            placeholder="Select Value"
          >
            { ["All", "AdHoc", "Recurring"].map((item, index) => {
              return <MenuItem key={index} value={item}>{item}</MenuItem>;
            })} 
          </Select>
        </div>
      </div>
      <div className={classes.table}>
        <Table
          header={amrTaskTableHead}
        >
          { result.slice(filter.page * rowsPerTable, filter.page * rowsPerTable + rowsPerTable).map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.robot_id}</TableCell>
                  <TableCell>{item.missions}</TableCell>
                  <TableCell>{amrMissionStatus[item.state]}</TableCell>
                  <TableCell>{moment.unix(item.start_stamp).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell>{moment.unix(item.finish_stamp).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell>{moment.unix(item.create_stamp).format("DD/MM/YYYY HH:mm")}</TableCell>
                  <TableCell>{item.createdBy}</TableCell>
                  <TableCell>
                    { amrMissionStatus[item.state] === 'Pending' || amrMissionStatus[item.state] === 'Running'
                      ? <Button className={classes.cancelButton} onClick={()=>handleButtonClick_cancel(item.id)}>Cancel</Button> 
                      : null
                    }
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={filter.page+1}
          onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
        />
      }
    </React.Fragment>
  );
}
