/* eslint-disable no-unused-vars */
import React, {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import ImageMarker from 'react-image-marker';
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";

// import PurchaseOrder from "./PurchaseOrder";
// import WorkOrder from "./WorkOrder";
import { setSearchText } from "store/general";
import { ExecuteGetMapByName } from "services/UserPortal/AMRService";

import map from "./amr map.jpg";
import search from "assets/icons/grey/search.svg";
import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function Route() {
  const classes = useStyles();
  const canvasRef = useRef(null)
  const dispatch = useDispatch();
  const amrList = useSelector(store => store.user.amr.overview.result);
  const pathList = useSelector(store => store.user.amr.overview.pathList);
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const handleOnChange_text = (e) => {
    setSearchValue(e.target.value);
    let payload = _.cloneDeep(amrList);
    setFilteredList(_.filter(payload, ({subSaleOrderNumber}) => {
      return subSaleOrderNumber.toLowerCase().includes(e.target.value.toLowerCase());
    }));
  }

  const handleOnChange_checkbox = (e) => {
    let payload = _.cloneDeep(selected);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      if (selected.length < 5) {
        payload.push(e.target.id);
      }
    }
    setSelected(payload);
  }

  const handleOnClick_clear = () => {
    setSelected([]);
  }

  const generateList = ({index, style}) => {
    let list = searchValue ? filteredList : amrList;
    if (list[index]) {
      return (
        <ListItem key={index} className={classes.listItem} style={style}>
        <Checkbox 
          id={String(list[index].id)}
          checked={selected.includes(String(list[index].id))} 
          onClick={(e)=>handleOnChange_checkbox(e)} 
        />
          {list[index].customerName} ({list[index].customerRefNo})
        </ListItem>
      );
    }
    return null;
  }

  const getMarker = () => {
    dispatch(ExecuteGetMapByName({name: "greenphyto_level_4"}))
    .then(({payload}) => {
      if (payload && payload.result) {
        const result = payload.result.nodes;
        let location = [];
        for (let obj of result) {
          // need to know original image height and width px
          // top and left is in percentage
          location = _.unionBy(location , [{ ...obj, top: (obj.y/966)*100, left: (obj.x/2066)*100 }], "id");
        }
        setMarkers(location);
      }
    })
  }

  const CustomMarker = (obj) => {
    return (
      <div className={clsx(classes.markerDiv, classes.marker)}>
        <Typography className={classes.markerText}>{obj.id}</Typography>
      </div>
    );
  };
  

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (let obj of pathList) {
      const start = markers.find(({id}) => id === obj.src); // get start marker position
      const end = markers.find(({id}) => id === obj.dst); // get start marker position
      if (start && end) {
        ctx.strokeStyle = "#FFA50099";
        ctx.lineWidth = 5;
        ctx.beginPath(); // Start a new path
        ctx.moveTo(start.left * canvas.width / 100, start.top * canvas.height / 100); // Move the pen
        ctx.lineTo(end.left * canvas.width / 100, end.top * canvas.height / 100); // Draw a line
        ctx.stroke(); // Render the path
      }
    }
  }, [markers])

  // componentDidMount
  React.useEffect(() => {
    getMarker();
  },[]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.bottomSpace),
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title="Floor"
          >
            <div className={classes.flexComponent}>
              <Typography className={classes.clickable}>Floor 1</Typography>
              <Typography className={classes.clickable}>Floor 2</Typography>
            </div>
          </Card>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.bottomSpace),
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title="Overview of the Level"
          >
            <Typography>Floor Size: <span className={classes.orange}>5000m^2</span></Typography>
          </Card>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.bottomSpace),
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title="AMR"
          >
            <TextField 
              id="search"
              variant="outlined" 
              placeholder="Search" 
              value={searchValue}
              InputProps={{
                startAdornment: 
                  <InputAdornment position="start">
                    <img src={search} alt="search" className={classes.icon_24}/>
                  </InputAdornment>,
              }}
              onChange={(e) => handleOnChange_text(e)}
            />
            <Typography className={classes.clear} onClick={handleOnClick_clear} disabled={!selected.length}>Clear Selection</Typography>
            <div className={classes.list}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={searchValue ? filteredList.length : amrList.length}
                    itemSize={0.02604 * window.innerWidth}
                    width={width}
                  >
                    {generateList}
                  </List>
                )}
              </AutoSizer>
            </div>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Card 
            classes={{
              root: classes.cardPaper,
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title={
              <React.Fragment>
                Floor Plan&nbsp;&nbsp;<span className={classes.text}>{moment().format("DD/MM/YYYY HH:mm:ss")}</span>
              </React.Fragment>
            }
          >
            <div className={classes.mapDiv}>
              <canvas className={classes.canvas} ref={canvasRef} height={500} width={1000} />
              <ImageMarker
                src={map}
                markers={markers}
                // onAddMarker={(marker) => handleOnChange_marker(marker)}
                markerComponent={CustomMarker}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
