import {
  icon_24,
  icon_64,
  border,
  button,
  buttonSecondary,
  grayColor,
  whiteColor,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const warehouseStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  orange,
  link,
  tag,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,
  
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },

  // Finished Goods List
  masterTable: {
    '& thead > tr > th': {
      width: "fit-content",
      minWidth: "fit-content",
      maxWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },

  summaryContent: {
    display: "flex",
    marginTop: "1.458vw",
    height: "fit-content",
    maxHeight: "12.740vw",
    overflow: "overlay",
  },

  // Rack Usage
  rackTableDiv: {
    overflow: "overlay",
    // width: "fit-content",
  },
  rackTable: {
    borderCollapse: "separate",
    borderSpacing: "0.521vw"
  },
  rackCell: {
    border,
    minWidth: "5.208vw",
    width: "18.906vw",
    maxWidth: "18.906vw",
    textAlign: "center",
    backgroundColor: primaryColor[5],
    color: grayColor[0]
  },
  usedRackCell: {
    backgroundColor: primaryColor[7],
    color: whiteColor,
  },
  legend: {
    display: "flex",
    marginTop: "1.667vw",
  },
  colorBox: {
    border,
    width: "1.146vw",
    height: "1.146vw",
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  legendColor: {
    "&:nth-child(1)": {
      backgroundColor: primaryColor[7],
      color: whiteColor
    },
    "&:nth-child(2)": {
      backgroundColor: primaryColor[5],
      color: grayColor[0]
    },
  },
}

export default warehouseStyle;