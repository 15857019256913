// Create Edit Raw Material
// Step 1: Product Details (Product Category + Category Type)
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Radio from 'shared-components/Radio/Radio';
import Cropper from "shared-components/Modal/Cropper";

import ProductStepper from "./ProductStepper";
import ProductCategorySetting from "./ProductCategorySetting";
import ProductCategoryTypeSetting from "./ProductCategoryTypeSetting";
import { renderValue } from "common/functions";
import { GetAllProductCategory, GetProductCategoryByID, GenerateProductId, IsProductNameExist, GetProductPreHarvestMixProcess } from "services/AdminPortal/ProductService";
import { updateRequest, updateImg } from "./store/product";

import settings from "assets/icons/orange/settings.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductCreationForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const productImage = useSelector(store => store.admin.product.product.productImage);
  const allProductCategory = useSelector(store => store.admin.product.lookup.allProductCategory);
  const allProductCategoryType = useSelector(store => store.admin.product.lookup.allProductCategoryType);
  const [productCategorySetting, setProductCategorySetting] = React.useState(false);
  const [productCategoryTypeSetting, setProductCategoryTypeSetting] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const inputFile = React.createRef();
  const [openCropModal, setOpenCropModal] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [IDExist, setIDExist] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    const productCategoryName = _.find(allProductCategory, {"id": e.target.value}).productCategoryName;
    dispatch(updateRequest({
      [e.target.name] : e.target.value,
      "productCategoryName": productCategoryName,
      "productCategoryTypeID": null,
      "productID": "",
    }));
    dispatch(GetProductCategoryByID(e.target.value));
  };

  const handleOnChange_productCategoryType = (e) => {
    Promise.all([dispatch(updateRequest({[e.target.name] : Number(e.target.value)}))])
    .then(() => {
      let body = {
        productCategoryID: selectedProduct.productCategoryID,
        productCategoryTypeID: e.target.value,
        productID: selectedProduct.id ? selectedProduct.id : 0
      }
      dispatch(GenerateProductId(body))
      .then((result) =>{
        if(result.payload.result.isExist) {
          setIDExist(true);
          setErrorMsg({field: "productID", msg: "Product ID is already used"});
        } else {
          setIDExist(false);
          setErrorMsg({field: "", msg: ""});
        }
      })
    })
  };

  const onButtonClick_image = () => {
    inputFile.current.click();
  }

  const handleOnChange_image = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleModal_crop();
    e.target.value = "";
  }

  const handleModal_crop = () => {
    setOpenCropModal(!openCropModal);
  }

  const handleButtonClick_crop = (blob) => {
    setOpenCropModal(false);
    dispatch(updateImg(URL.createObjectURL(blob)));
    props.setImgFile(blob);
  }

  const validateFields = () => {
    if (!selectedProduct.productName) {
      setErrorMsg({field: "productName", msg: "Please add in product name"});
      return false;
    }
    if (!selectedProduct.productCategoryID) {
      setErrorMsg({field: "productCategoryID", msg: "Please select a product category"});
      return false;
    }
    if (!selectedProduct.productCategoryTypeID) {
      setErrorMsg({field: "productCategoryTypeID", msg: "Please select a product category type"});
      return false;
    }
    if (_.isEmpty(productImage)) {
      setErrorMsg({field: "imageFile", msg: "Please upload an image"});
      return false;
    }
    if (IDExist) {
      setErrorMsg({field: "productID", msg: "Product ID is already used"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const handleButtonClick_next = () => {
    if (validateFields()) {
      const data = {
        productName: selectedProduct.productName,
        id: selectedProduct.id ? selectedProduct.id : 0
      }
      dispatch(IsProductNameExist(data))
      .then((result) =>{
        if(result.payload.result) {
          setErrorMsg({field: "productName", msg: "Product name is already used"});
          return false;
        } else {
          if(selectedProduct.combinationProduct) {
            dispatch(GetProductPreHarvestMixProcess(selectedProduct.productCategoryTypeID));
          }
          props.setStep(1);
        }
      })
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllProductCategory());
  },[]);

  // product category is selected
  React.useEffect(() => {
    if (selectedProduct.productCategoryID) {
      dispatch(GetProductCategoryByID(selectedProduct.productCategoryID))
    }
  },[selectedProduct]);

  if (productCategoryTypeSetting) {
    return <ProductCategoryTypeSetting combinationProduct={selectedProduct.combinationProduct} setProductCategoryTypeSetting={setProductCategoryTypeSetting} />
  } else if (productCategorySetting){
    return <ProductCategorySetting setProductCategorySetting={setProductCategorySetting} />
  } else {
    return (
      <React.Fragment>
        <Card 
          title="Step 1: Please key in details for Product Creation"
          subheader={
            <React.Fragment>
              Please key in the necessary details needed for product creation process
              <ProductStepper activeStep={props.step} />
            </React.Fragment>
          }
          cardActions={
            <React.Fragment>
              <Button disabled/>
              <Button
                className={classes.button}
                onClick={()=> handleButtonClick_next()}
              >
                Next Step
              </Button>
            </React.Fragment>
          }
        >
          <div className={classes.content}>
            <div className = {classes.topContent}>
              <div className={classes.columnSpacing}>
                <Paper className={classes.topLeftPaper} elevation={0}>
                  <TextField 
                    label={<React.Fragment>Product Name <span className={classes.orange}>*</span></React.Fragment>}
                    id="productName"
                    variant="outlined" 
                    onChange={(e) => handleOnChange_text(e)}
                    placeholder="Enter Product Name"
                    value= {renderValue(selectedProduct.productName)}
                    errorMsg={errorMsg}
                  />
                  <div className="flex w-full">
                    <div className={classes.leftField}>
                      <Select
                        label={<React.Fragment>Product Category <span className={classes.orange}>*</span></React.Fragment>}
                        name="productCategoryID"
                        onChange={(e)=>handleOnChange_select(e)}
                        value={renderValue(selectedProduct.productCategoryID)}
                        placeholder="Choose product type"
                        errorMsg={errorMsg}
                        disabled={props.isEdit}
                      >
                        {allProductCategory && allProductCategory.map((item,index) => {
                          return <MenuItem key={index} value={item.id}>{item.productCategoryName}</MenuItem>;
                        })}  
                      </Select>
                    </div>
                    <IconButton 
                      className={clsx(classes.iconButton, classes.rightField)}
                      onClick={() => setProductCategorySetting(true)}
                    >
                    <img className={classes.icon} src={settings} alt="settings" />
                    </IconButton>
                  </div>
                  <TextField 
                    label="Proposed Product ID"
                    id="productID"
                    variant="outlined" 
                    disabled
                    value={renderValue(selectedProduct.productID)}
                    errorMsg={errorMsg}
                  />
                </Paper>
              </div>
              <Paper elevation={0} className={classes.productType}>
                {!selectedProduct.productCategoryID 
                  ?
                  <Typography className={classes.empty}>No Product Category Selected</Typography> 
                  : 
                  <React.Fragment>
                    <div className="flex">
                      <Typography className={classes.productTypeTitle}>{selectedProduct.productCategoryName} Type <span className={classes.orange}>*</span></Typography>
                      <IconButton 
                        className={classes.iconButton}
                        onClick={() => setProductCategoryTypeSetting(true)}
                      >
                      <img className={classes.icon} src={settings} alt="settings" />
                      </IconButton>
                    </div>
                    { errorMsg.field === "productCategoryTypeID" && <Typography className={classes.error}>{errorMsg.msg}</Typography> }
                    <div className={classes.productTypeContainer}>
                      <div className={classes.productTypeScroll}>
                        <Table
                          className={classes.productTypeTable}
                        >
                          {_.isEmpty(allProductCategoryType) ? null : allProductCategoryType.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  {item.productCategoryTypeName} ({item.productCategoryTypeShortForm})
                                </TableCell>
                                <TableCell align="right">
                                  <Radio 
                                    name="productCategoryTypeID"
                                    checked={selectedProduct.productCategoryTypeID ? item.id === selectedProduct.productCategoryTypeID : false}
                                    onChange={(e)=>handleOnChange_productCategoryType(e)}
                                    value={item.id}
                                    disabled={props.isEdit}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </Table>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </Paper>
            </div>
          </div>
          <Paper className={classes.bottomContent} elevation={0}>
            <div className={classes.productDescription}>
            <TextField 
              label="Product Description"
              id="productDesc"
              variant="outlined" 
              minRows={4}
              multiline
              placeholder="Enter product description"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(selectedProduct.productDesc)}
            />
            </div>
            <div className={classes.imageGroup}>
              <div>
                <Typography >Product Image <span className={classes.orange}>*</span>:</Typography>
                <Typography className={classes. imageText}>(Recommended size 1080 by 1080)</Typography>
                <Button
                  className={classes.uploadImageButton}
                  onClick={onButtonClick_image}
                >
                  Upload Image
                </Button>
              </div>
              <input 
                ref={inputFile} 
                style={{"display": "none"}} 
                type="file" 
                accept="image/*"
                onChange={(e) => handleOnChange_image(e)}
              />
              <FormControl>
                <div className={errorMsg.field === "imageUrl" ? clsx(classes.imgBorder, classes.imgBorderError) : classes.imgBorder}>
                  { productImage || props.imgFile
                  ? <img className={clsx(classes.cardRadius, classes.addImg)} src={productImage ? productImage : props.imgFile} alt="product-pic" />
                  : <img className={classes.newIcon} src="" />
                  }
                </div>
                {errorMsg.field === "imageFile" && <FormHelperText className={classes.error}>{errorMsg.msg}</FormHelperText>}
              </FormControl>
            </div>
          </Paper>
        </Card>
        <Cropper
          open={openCropModal}
          onClose={() => handleModal_crop()}
          imageFile={imageUrl}
          onConfirm={(blob) => handleButtonClick_crop(blob)}
        />
      </React.Fragment>
    )
  }
}
