import { createSlice } from "@reduxjs/toolkit";

import { GetAllASRSStationStatusList } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  result: [],
};

const station = createSlice({
  name: "station",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllASRSStationStatusList.fulfilled]: (state, action) => {
      state.result = action.payload.result.items;
      state.totalCount = action.payload.result.totalCount;
    },
  },
});

// export actions
export const { setValues, reset } = station.actions;

// export the reducer
export default station.reducer;