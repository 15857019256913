import { createSlice } from "@reduxjs/toolkit";

import { GetWarehouseRackUsage } from "services/UserPortal/WarehouseService";

// initial state
const initialState = {
  filter: {},
  rackList: [],
};

const rackUsage = createSlice({
  name: "rackUsage",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetWarehouseRackUsage.fulfilled]: (state, action) => {
      state.rackList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = rackUsage.actions;

// export the reducer
export default rackUsage.reducer;