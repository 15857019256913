import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import { setDirty } from "store/general";
import { GetUserFarmList, GetActiveFarm, SetActiveFarm, GetUserProfile } from "services/AdminPortal/UserService";

import styles from "assets/jss/components/userSettingsStyle.js";

import { updateFarmRequest, reset } from ".";

const useStyles = makeStyles(styles);

export default function FarmSettings() {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const farms = useSelector(store => store.settings.farms);
  const farm = useSelector(store => store.settings.farm);

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateFarmRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(SetActiveFarm())
      .then(() => {
        dispatch(GetUserProfile());
      });
    });
  };

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetUserFarmList());
    dispatch(GetActiveFarm());
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setDirty(false));
    }
  },[]);

  return (
    <Card 
      className={classes.container}
      classes={{
        root: classes.container,
        content: classes.content,
        cardActions: classes.actions
      }}
      title="Farm Settings"
      cardActions={
        <React.Fragment>
          <Button
            className={classes.buttonSecondary}
            onClick={()=>history.goBack()}
          >
            Go Back
          </Button>
          <Button
            className={classes.button}
            onClick={()=>handleButtonClick()}
            disabled={!isDirty}
          >
            Update
          </Button>
        </React.Fragment>
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={4} className={classes.label}>
          <Typography>Current Farm:</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField 
            id="currentFarm"
            variant="outlined" 
            value={!farm.currentFarm ? "" : farm.currentFarm.farmName}
            disabled
          />
        </Grid>
        <Grid item xs={4} className={classes.label}>
          <Typography>New Farm Profile:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Select
            name="newFarm"
            onChange={(e)=>handleOnChange_select(e)}
            placeholder="Select One"
            value={!farm.newFarm ? "" : farm.newFarm}
          >
            {farms && farms.map((item) => {
              return <MenuItem key={item.id} value={item.id}>{item.farmName}</MenuItem>;
            })} 
          </Select>
        </Grid>
      </Grid>
    </Card>
  );
}
