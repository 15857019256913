import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

import SearchRawMaterialResult from "./SearchRawMaterialResult";
import SearchBatchResult from "./SearchBatchResult";
import SearchReservedResult from "./SearchReservedResult";
import SearchIssuedResult from "./SearchIssuedResult";
import SearchReceivedResult from "./SearchReceivedResult";
import SearchCostResult from "./SearchCostResult";
import SearchReturnResult from "./SearchReturnResult";
import SearchReconciliationResult from "./SearchReconciliationResult";

const useStyles = makeStyles(styles);

export default function SearchResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  //count
  const rmTotalCount = useSelector(store => store.user.rawMaterial.search.rmTotalCount);
  const batchTotalCount = useSelector(store => store.user.rawMaterial.search.batchTotalCount);
  const reservedTotalCount = useSelector(store => store.user.rawMaterial.search.reservedTotalCount);
  const issuedTotalCount = useSelector(store => store.user.rawMaterial.search.issuedTotalCount);
  const receivedTotalCount = useSelector(store => store.user.rawMaterial.search.receivedTotalCount);
  const costTotalCount = useSelector(store => store.user.rawMaterial.search.costTotalCount);
  const returnTotalCount = useSelector(store => store.user.rawMaterial.search.returnTotalCount);
  const reconciliationTotalCount = useSelector(store => store.user.rawMaterial.search.reconciliationTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(rmTotalCount + batchTotalCount + costTotalCount + returnTotalCount + reconciliationTotalCount + reservedTotalCount + issuedTotalCount + receivedTotalCount) + " Search Result(s) From 8 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchRawMaterialResult />
      <SearchBatchResult />
      <SearchReservedResult />
      <SearchIssuedResult />
      <SearchReceivedResult />
      <SearchCostResult />
      <SearchReturnResult />
      <SearchReconciliationResult />
    </React.Fragment>
  );
}
