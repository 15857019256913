import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
// core components
import styles from "assets/jss/shared-components/cardStyle.js";

const useStyles = makeStyles(styles);
export default function CustomCard({title, subheader, action, children, cardActions, ...props}) {
  const classes = useStyles();

  return (
    <Card className={props.classes && props.classes.root ? clsx(props.classes.root, classes.card) : classes.card}>
      { (title || subheader || action) &&
        <React.Fragment>
          <CardHeader 
            classes={{
              root: subheader
                ? (props.classes && props.classes.header) ? clsx(props.classes.header, classes.header, classes.margin) : clsx(classes.header, classes.margin) 
                : (props.classes && props.classes.header) ? clsx(props.classes.header, classes.header) : classes.header,
              title: subheader
                ? (props.classes && props.classes.title) ? clsx(props.classes.title, classes.title, classes.marginTop) : clsx(classes.title, classes.marginTop)
                : (props.classes && props.classes.title) ? clsx(props.classes.title, classes.title) : classes.title,
              content: "w-full",
              subheader: classes.subheader,
              action: (props.classes && props.classes.action) ? clsx(props.classes.action, classes.action) : classes.action,
            }}
            title={title}
            subheader={subheader}
            action={action}
          />
          <Divider />
        </React.Fragment>
      }
      { children &&
        <React.Fragment>
          <CardContent className={props.classes && props.classes.content ? clsx(props.classes.content, classes.content) : classes.content}>
            {children}
          </CardContent>
        </React.Fragment>
      }
      { cardActions &&
        <CardActions className={props.classes && props.classes.cardActions ? clsx(props.classes.cardActions, classes.cardActions) : classes.cardActions} disableSpacing>
          {cardActions}
        </CardActions>
      }
    </Card>
  );
}

CustomCard.propTypes = {
  title: PropTypes.node,
  subheader: PropTypes.node,
  action: PropTypes.node,
  children: PropTypes.node,
  cardActions: PropTypes.node,
};