import {
  icon_64,
  button,
  buttonSecondary,
  border,
  cardRadius,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  table,
  tab,
  indicator,
  orange,
  loading,
} from "assets/jss/components/commonStyle.js";

const alarmStyle = {
  icon_64,
  button,
  buttonSecondary,
  table,
  tab,
  indicator,
  orange,
  loading,

  tabLabel: {
    textTransform: "capitalize",
    marginTop: "0.521vw",
    padding: "0.938vw",
    display: "flex",
    flexDirection: "row"
  },
  paper: {
    ...cardRadius,
    display: "flex",
    padding: "2.396vw 0.573vw 2.083vw",
    height: "11.458vw",
    border: "0.104vw solid "+ secondaryColor[0],
    fontSize: "1.094vw",
    fontWeight: "500",
    color: primaryColor[3],
    textAlign: "center",
    "&:hover": {
      backgroundColor: secondaryColor[0],
      color: whiteColor,
    }
  },
  paperItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },
  logoImage: {
    // marginTop: "1.146vw",
    width: "3.333vw",
    height: "3.333vw",
  },
  logoTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    width: "99%",
    margin: "auto"
  },
  cardActionDropdown: {
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },

  // Alarm Tab
  buttonDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.302vw"
  },

  // Notifications
  title: {
    fontWeight: 500,
    fontSize: "1.250vw",
    marginBottom: "0.521vw"
  },

  // Settings
  alarmPaper: {
    ...cardRadius,
    border,
    width: "41.667vw",
    margin: "auto",
    marginBottom: "1.302vw",
    padding: "1.823vw 7.292vw",
    color: primaryColor[3],
  },
  alarmLabel: {
    color: primaryColor[4],
    marginTop: "0.833vw"
  },
  alarmLabel2: {
    color: primaryColor[4],
    marginTop: "0.625vw"
  },
  alarmCheckbox: {
    marginLeft: "-0.625vw"
  },
  alarmButton: {
    display: "flex",
    justifyContent: "space-between"
  },
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
  },

  // All
  container: {
    width: "51.250vw",
    margin: "auto",
  },
  content: {
    margin: "2.240vw 7.292vw",
    padding: 0
  },
  notificationAction: {
    display: "flex",
    alignItems: "center",
    "& p:first-child": {
      color: "#899895",
      paddingRight: "0.521vw"
    }
  },
  notificationChip: {
    marginLeft: "0.521vw",
    backgroundColor: "#899895",
    color: whiteColor,
    borderRadius: "0.833vw",
    padding: "0.250vw 0.625vw",
    fontSize: "0.833vw",
  },
  orangeBackground: {
    backgroundColor: secondaryColor[0],
  },
  unread: {
    backgroundColor: "#FFF3EB",
  },
  unreadMsg: {
    color: secondaryColor[0]
  },
  unreadTime: {
    color: "#14342CBF"
  },
  notificationMenu: {
    height: "fit-content",
    maxHeight: "31.250vw",
    overflow: "overlay",
  },
  notificationItem: {
    display: "block",
    padding: "1.042vw 2.083vw",
  },
  notificationMsg: {
    marginTop: "0.521vw",
    display: "flex",
    whiteSpace: "break-spaces"
  },
  notificationTime: {
    marginTop: "0.521vw",
    color: "#14342C80",
    "&:first-letter": {
      textTransform: "capitalize",
    }
  },
};

export default alarmStyle;
  