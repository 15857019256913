import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

import SearchAssignmentResult from "./SearchAssignmentResult";
import SearchMasterResult from "./SearchMasterResult";
import SearchDOResult from "./SearchDOResult";

const useStyles = makeStyles(styles);

export default function SearchResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  //count
  const assignmentTotalCount = useSelector(store => store.user.delivery.search.assignmentTotalCount);
  const masterTotalCount = useSelector(store => store.user.delivery.search.masterTotalCount);
  const doTotalCount = useSelector(store => store.user.delivery.search.doTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(assignmentTotalCount + masterTotalCount + doTotalCount) + " Search Result(s) From 3 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchAssignmentResult />
      <SearchMasterResult />
      <SearchDOResult />
    </React.Fragment>
  );
}
