import {
  secondaryColor,
  buttonSecondary
} from "assets/jss/generaliseStyle.js";

const carouselStyle = {
  buttonSecondary, 
  img:{
    width: "100%"
  },
  button: {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    width: "auto",
    marginTop: "-1.146vw",
    padding: "0.833vw",
    color: "white",
    fontWeight: 500,
    fontSize: "0.938vw",
    transition: "0.6s ease",
    borderRadius: "0 0.156vw 0.156vw 0",
    userSelect: "none",
    "&:hover": {
      backgroundColor: secondaryColor[1],
    }
  },
  prev: {
    left: 0,
  },
  next: {
    right: 0,
    borderRadius: "0.156vw 0 0 0.156vw",
  },
  videoDiv: {
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  video: {
    height:"21.354vw"
  },
  zoom: {
    position: "absolute",
    bottom: 0,
    right: 0
  },
  zoomVideo: {
    width: '60vw',
  },
  modal: {
    width: '60vw',
    // height: '90vh'
  },
}

export default carouselStyle;