import { createSlice } from "@reduxjs/toolkit";

import { GetAllMonitoringProductionSetting, GetAllMonitoringStorageSetting, GetMonitoringProductionSettingById, GetMonitoringStorageSettingById, GetDeviceLookUp } from "services/UserPortal/MonitoringService";

// initial state
const initialState = {
  totalProductionCount: 0,
  production: [],
  totalStorageCount: 0,
  storage: [],
  setting: {},
  //lookup
  deviceList: []
};

const settingsMonitoring = createSlice({
  name: "settingsMonitoring",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllMonitoringProductionSetting.fulfilled]: (state, action) => {
      state.totalProductionCount = action.payload.result.totalCount;
      state.production = action.payload.result.items;
    },
    [GetAllMonitoringStorageSetting.fulfilled]: (state, action) => {
      state.totalStorageCount = action.payload.result.totalCount;
      state.storage = action.payload.result.items;
    },
    [GetMonitoringProductionSettingById.fulfilled]: (state, action) => {
      state.setting = action.payload.result;
    },
    [GetMonitoringStorageSettingById.fulfilled]: (state, action) => {
      state.setting = action.payload.result;
    },
    [GetDeviceLookUp.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = settingsMonitoring.actions;

// export the reducer
export default settingsMonitoring.reducer;