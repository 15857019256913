import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { GetTodayYesterdayData } from "services/UserPortal/SmartMeteringFlagshipService";

import styles from "assets/jss/components/UserPortal/smartMeteringStyle.js";

const useStyles = makeStyles(styles);

export default function EnergyConsumption() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.smartMetering.flagship.consumption);

  const getData = () => {
    dispatch(GetTodayYesterdayData());
  }

  React.useEffect(() => {
    getData();
  },[]);

  return (
    <div>
      <Typography className={classes.label}>Energy Consumption</Typography>
      <Paper className={classes.paper} elevation={0}>
        <Typography>Today: <span className={classes.orange}>{result.todayTotal}kWh</span></Typography>
        <Typography>Yesterday: <span className={classes.orange}>{result.yesterdayTotal}kWh</span></Typography>
      </Paper>
      {/* <Typography className={classes.sublabel}>Last Updated: {result.todayTotalEnergy && moment(result.todayTotalEnergy.dateTime).format('DD/MM/YYYY HH:mm')}</Typography> */}
    </div>
  );
}
