import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Tag from 'shared-components/Chip/Tag';

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/rm";
import { checkPermission, formatNumbers, roundTo2Decimal, renderValue, filterParam } from "common/functions";
import { warehouseRMTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllRawMaterialBatchByWarehouseId, GetWarehouseRackByWarehouseIdLookUp, GetAllRawMaterialBatchIdLookUp } from "services/UserPortal/WarehouseService";

import styles from "assets/jss/components/UserPortal/warehouseStyle.js";

const useStyles = makeStyles(styles);

export default function RawMaterial() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const warehouseList = useSelector(store => store.common.warehouseList);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const filter = useSelector(store => store.user.warehouse.rm.filter);
  const totalCount = useSelector(store => store.user.warehouse.rm.totalCount);
  const result = useSelector(store => store.user.warehouse.rm.rm);
  const allRackNumber = useSelector(store => store.user.warehouse.rm.allRackNumber);
  const rmBatchList = useSelector(store => store.user.warehouse.rm.rmBatchList);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('batchRefNo');
  const [newFilter, setNewFilter] = React.useState({warehouseId: null, rawMaterialBatchId: null, rackNumber: null, rawMaterialId: null, page: 0});
  const [rackPage, setRackPage] = React.useState(0); // for rack list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for rack list
  const [searchValue, setSearchValue] = React.useState(""); // for rack list
  const [selectedRack, setSelectedRack] = React.useState({}); // for rack list

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const setNewFilter_modal = (value) => {
    setNewFilter(value);
    if (value.rackNumber) {
      dispatch(GetWarehouseRackByWarehouseIdLookUp({
        warehouseId: value.warehouseId,
        page: 0,
        rackID: value.rackNumber
      })).then((response) => {
        if (!response.error) {
          setSelectedRack(response.payload.result.items[0]);
          setSearchValue(value.rackNumber);
        } else {
          setSelectedRack({});
          setSearchValue("");
        }
      })
    } else {
      setSelectedRack({});
      setSearchValue("");
    }
  }
  
  const handleOnChange_select = (e) => {
    if (e.target.name === "warehouseId") {
      setNewFilter({...newFilter, [e.target.name]: e.target.value, rackNumber: null});
    } else {
      setNewFilter({...newFilter, [e.target.name]: e.target.value});
    }
  };

  const handleButtonClick_delete = (key) => {
    if (key === "warehouseId") {
      dispatch(setValues({filter: {...filter, [key]: null, rackNumber: null, page: 0}, allRackNumber : []}));
    } else{
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };
  
  const getRMList = (stopLoading) => {
    if (filter.warehouseId) {
      dispatch(GetAllRawMaterialBatchByWarehouseId({...filter, stopLoading}));
    } else {
      dispatch(setValues({rm: [], totalCount: 0}))
    }
  }
  
  const handleOnChange_autocomplete = (value) => {
    setNewFilter({...newFilter, rackNumber: value.rackID});
    setSelectedRack(value);
  };

  const fetchMoreData = (search, pageNo) => {
    if (rackPage !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        warehouseId: newFilter.warehouseId,
        page: pageNo !== undefined ? pageNo : rackPage,
        rackID: search ? search : ""
      }
      dispatch(GetWarehouseRackByWarehouseIdLookUp(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setRackPage(pageNo !== undefined ? pageNo+1 : rackPage+1);
            if (search || rackPage === 0 || pageNo === 0) {
              dispatch(setValues({allRackNumber: response.payload.result.items}));
            } else {
              dispatch(setValues({allRackNumber: _.unionBy(allRackNumber, response.payload.result.items, "rackID")}));
            }
          } else {
            setRackPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (warehouseList.length) {
        dispatch(setValues({filter: {warehouseId: warehouseList[0].id, page: 0}}));
      } else {
        dispatch(setValues({filter: {warehouseId: null, page: 0}}));
      }
    }
  },[warehouseList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getRMList();
    }
  },[filter]);

  React.useEffect(() => {
    const param = {
      warehouseId: newFilter.warehouseId,
      page: 0
    }
    dispatch(GetWarehouseRackByWarehouseIdLookUp(param))
    .then((response) => {
      if (response.payload.result) {
        setRackPage(rackPage+1);
        dispatch(setValues({allRackNumber: response.payload.result.items}))
      }
    });
  },[newFilter.warehouseId]);

  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllRawMaterialBatchIdLookUp());
  },[]);

  useInterval(() => {
    getRMList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title={"Raw Materials List"}
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "warehouseId") {
                const warehouse = warehouseList.find(({id}) => id == filter[key]);
                label = warehouse && (warehouse.warehouseName+" ("+warehouse.warehouseId+")");
              }
              if (key === "rawMaterialBatchId") {
                const batch = rmBatchList.find(({batchId}) => batchId == filter[key]);
                label = batch && batch.batchRefNo;
              }
              if (key === "rawMaterialId") {
                const rm = rawMaterialList.find(({id}) => id == filter[key]);
                label = rm && (rm.rawMaterialName+" ("+rm.rawMaterialRefNo+")");
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter_modal}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the raw material list
                  <Select
                    className={classes.filterDropdown}
                    name="warehouseId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a warehouse"
                    value={renderValue(newFilter.warehouseId)}
                  >
                    {warehouseList && warehouseList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                    })} 
                  </Select>
                  <Autocomplete
                    className={classes.filterDropdown}
                    dataCount={rackPage*10}
                    options={allRackNumber}
                    fetchData={()=>fetchMoreData()}
                    renderOption={(option) => option.rackID}
                    isLoadingMore={isLoadingMore}
                    placeholder="Eg. R01-01"
                    onInputChange={(e, newInputValue) => {
                      if (e && e._reactName == "onBlur") {
                        setRackPage(0);
                        setSearchValue(newFilter.rackNumber?newFilter.rackNumber:"");
                        fetchMoreData(newInputValue, 0);
                      } 
                      if (e && e._reactName == "onClick") {
                        setRackPage(0);
                        setSearchValue(newInputValue);
                        fetchMoreData(undefined, 0);
                      }
                      if (e && e._reactName == "onChange") {
                        setRackPage(0);
                        setSearchValue(newInputValue);
                        fetchMoreData(newInputValue, 0);
                      }
                    }}
                    onChange={(_, newValue) => {
                      handleOnChange_autocomplete(newValue);
                    }}
                    inputValue={searchValue}
                    value={searchValue!==(selectedRack&&selectedRack.rackID) ? null : selectedRack}
                    renderValue={"rackID"}
                    disabled={!newFilter.warehouseId}
                  />
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialBatchId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material batch ID"
                    value={renderValue(newFilter.rawMaterialBatchId)}
                  >
                    {rmBatchList && rmBatchList.map((item, index) => {
                      return <MenuItem key={index} value={item.batchId}>{item.batchRefNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material"
                    value={renderValue(newFilter.rawMaterialId)}
                  >
                    {rawMaterialList && rawMaterialList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.rawMaterialName} ({item.rawMaterialRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={warehouseRMTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.batchRefNo}</TableCell>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{moment(item.dateOfCreation).isAfter(minDate) ? moment(item.dateOfCreation).format("DD/MM/YYYY") : ""}</TableCell>
                  <TableCell>{item.rackNumbers && item.rackNumbers.join(", ")}</TableCell>
                  <TableCell>{moment(item.expiryDate).isAfter(minDate) ? moment(item.expiryDate).format("DD/MM/YYYY") : ""}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyAdd))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyUsed))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyLeft))}</TableCell>
                  <TableCell>{item.quantityUOM}</TableCell>
                  {/* <TableCell>{formatNumbers(roundTo2Decimal(item.unitCost))}</TableCell> */}
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
        {checkPermission("Raw Materials", "readEdit") &&
          <Typography className={classes.link}>
            <Link to={{pathname: "/user/raw-material/management"}}>Raw Materials Module Page</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
