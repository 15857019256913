import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";
import FilterButton from "shared-components/Button/FilterButton";

import DetailsModal from "../DetailsModal";
import { rowsPerTable } from "config";
import { isPositive } from "common/validations";
import { checkPermission, formatNumbers, roundTo2Decimal, renderValue, filterParam } from "common/functions";
import { setDirty } from "store/general";
import { hrLogSheetTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest, setValues } from "../store/adminLogSheet";
import { GetAllLogSheet, GetProductProcessName, GetLotIdByWorkOrderIdAndProductId, GetTaskNumberList, CreateOrUpdateLogSheet, DeleteLogSheet } from "services/UserPortal/HumanResourceService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/hrStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function IndividualLogSheet() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const employeeList = useSelector(store => store.common.userList);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const filter = useSelector(store => store.user.hr.adminLogSheet.filter);
  const processList = useSelector(store => store.user.hr.adminLogSheet.processList);
  const taskList = useSelector(store => store.user.hr.adminLogSheet.taskList);
  const totalTime = useSelector(store => store.user.hr.adminLogSheet.totalTime);
  const totalCount = useSelector(store => store.user.hr.adminLogSheet.totalCount);
  const result = useSelector(store => store.user.hr.adminLogSheet.logSheet);
  const lotId = useSelector(store => store.user.hr.adminLogSheet.lotId);
  const log = useSelector(store => store.user.hr.adminLogSheet.log);
  const [newFilter, setNewFilter] = React.useState({employeeName: null, date: moment().format("YYYY-MM-DD"), page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productRefNo');
  const [entryId, setEntryId] = React.useState(false);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      dispatch(setValues({filter: {...filter, date: moment(value).format("YYYY-MM-DD"), page: 0}}));
    } 
  };

  const handleOnChange_selectFilter = (e) => {
    setNewFilter({...newFilter, [e.target.name] : e.target.value});
  };

  const handleModal_details = (value) => {
    setOpenDetailsModal(value);
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "workOrderId") {
      dispatch(updateRequest({ productId: null, productGrowthProcessId: null, operationTaskId: null }));
    }
    if (e.target.name === "productId") {
      dispatch(updateRequest({ productGrowthProcessId: null, operationTaskId: null }));
    }
    if (e.target.name === "productGrowthProcessId") {
      dispatch(updateRequest({ operationTaskId: null }));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setIsAddEdit(true);
    setEntryId(id);
    const value = _.find(result, (item) => item.id === id);
    const user = employeeList.find((item) => item.userName === filter.employeeName);
    dispatch(setValues({log: {...value, userId: user.id}}));
  }

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = [...result, {id: 0, date: moment().format("YYYY-MM-DD")}];
    setIsAddEdit(true);
    setEntryId(0);
    const user = employeeList.find((item) => item.userName === filter.employeeName);
    dispatch(setValues({logSheet: payload,  lotId: null, log: {date: moment(filter.date).format("YYYY-MM-DD"), isUserKeyInTask: true, userId: user.id}}));
  }

  const handleModal_delete = (id) => {
    setEntryId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteLogSheet(entryId))
    .then(() =>{
      dispatch(setValues({filter: {...filter, page: 0}}));
      resetState();
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateLogSheet(log))
      .then(({error}) =>{
        if (!error) {
          getLogSheet();
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (entryId === 0) {
      dispatch(setValues({logSheet: _.filter(result, (item) => item.id !== entryId), filter: {...filter, page: 0}}));
    }
    resetState();
  }

  const validateFields = () => {
    if (log.workOrderId && !log.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product id"});
      return false;
    }
    if (!log.productGrowthProcessId) {
      setErrorMsg({field: "productGrowthProcessId", msg: "Please select a process"});
      return false;
    }
    if (log.workOrderId && log.productId && !log.operationTaskId) {
      setErrorMsg({field: "operationTaskId", msg: "Please select a task number"});
      return false;
    }
    if (!isPositive(log.timeSpent)) {
      setErrorMsg({field: "timeSpent", msg: "Please enter a valid time spent"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setIsAddEdit(false);
    setEntryId(null);
    setErrorMsg(false);
    setOpenDeleteModal(false);
    dispatch(setDirty(false));
    dispatch(setValues({log: {}}));
  }

  const getLogSheet = (stopLoading) => {
    if (filter.employeeName && filter.date) {
      dispatch(GetAllLogSheet({...filter, stopLoading}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {employeeName: employeeList[0].userName, date: moment().format("YYYY-MM-DD"), page: 0}}));
      } else {
        dispatch(setValues({filter: {employeeName: null, date: moment().format("YYYY-MM-DD"), page: 0}}));
      }
    }
  },[employeeList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getLogSheet();
    }
  },[filter]);

  React.useEffect(() => {
    if (log.workOrderId) {
      dispatch(GetProductList(log.workOrderId));
    }
  },[log.workOrderId]);

  React.useEffect(() => {
    if (log.workOrderId && log.productId) {
      const product = productList.find(({id}) => id == log.productId);
      dispatch(GetProductProcessName({versionId: product ? product.productVersionId : 0}));
      dispatch(GetLotIdByWorkOrderIdAndProductId({workOrderId: log.workOrderId, productId: log.productId}));
    } else {
      dispatch(GetProductProcessName({versionId: 0}));
    }
  },[log.productId, productList]);

  React.useEffect(() => {
    if (log.workOrderId && log.productId && log.productGrowthProcessId) {
      dispatch(GetTaskNumberList({workOrderId: log.workOrderId, productId: log.productId, productProcessId: log.productGrowthProcessId}));
    }
  },[log.productGrowthProcessId]);
  
  return (
    <React.Fragment>
      <div className={clsx(classes.tabHeader, classes.tabMargin)}>
        <Typography className={classes.tabTitle}>Information on {filter.employeeName} Correct as at {moment().format("DD/MM/YYYY, HHmm")}</Typography>
        <div>
          <FilterButton 
            setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
            filter={filter}
            setNewFilter={setNewFilter}
            disabled={isAddEdit}
            content={
              <React.Fragment>
                Choose one or all filters to view individual employee log sheet
                <Select
                  className={classes.filterDropdown}
                  name="employeeName"
                  onChange={(e)=>handleOnChange_selectFilter(e)}
                  placeholder="Select an employee"
                  value={renderValue(newFilter.employeeName)}
                >
                  {employeeList && employeeList.map((item, index) => {
                    return <MenuItem key={index} value={item.userName}>{item.userName} ({item.employeeRefNo})</MenuItem>;
                  })} 
                </Select>
              </React.Fragment>
            }
          />
          <DateTimePicker
            closeOnSelect
            placeholder="Select a date"
            className={classes.cardActionDropdown}
            value={filter.date}
            onChange={(e) => handleOnChange_date(e)}
            disabled={isAddEdit}
          />
        </div>
      </div>
      <div className={classes.table}>
        <Table
          className={classes.mainTable}
          header={hrLogSheetTableHead}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          { result.map((item,index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                { isAddEdit && entryId === item.id
                  ? <Select
                      name="workOrderId"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={renderValue(log.workOrderId)}
                      placeholder="Select a work order"
                      errorMsg={errorMsg}
                    >
                      {workOrderList && workOrderList.map((option, i) => {
                      return <MenuItem key={i} value={option.id}>{option.workOrderNo}</MenuItem>;
                    })} 
                    </Select>
                  : item.workOrderNumber
                  }
                </TableCell>
                <TableCell>
                  { isAddEdit && entryId === item.id
                  ? <Select
                      name="productId"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={renderValue(log.productId)}
                      placeholder="Select a product"
                      disabled={!log.workOrderId}
                      errorMsg={errorMsg}
                    >
                      {productList.map((option) => {
                        return <MenuItem key={option.id} value={option.id}>{option.productName} ({option.productRefNo})</MenuItem>;
                      })} 
                    </Select>
                  : item.productRefNo
                  }
                </TableCell>
                <TableCell>
                  { isAddEdit && entryId === item.id
                  ? lotId
                  : item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />
                  }
                </TableCell>
                <TableCell>
                  { isAddEdit && entryId === item.id
                  ? <Select
                      name="productGrowthProcessId"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={renderValue(log.productGrowthProcessId)}
                      placeholder="Select a process name"
                      errorMsg={errorMsg}
                    >
                      {processList && processList.map((option, i) => {
                        return <MenuItem key={i} value={option.productProcessId}>{option.productProcessName}</MenuItem>;
                      })} 
                    </Select>
                  : item.productProcessName
                  }
                </TableCell>
                <TableCell>
                  { isAddEdit && entryId === item.id
                  ? <Select
                      name="operationTaskId"
                      onChange={(e)=>handleOnChange_select(e)}
                      value={renderValue(log.operationTaskId)}
                      placeholder="Select a task number"
                      disabled={!(log.workOrderId && log.productId)}
                      errorMsg={errorMsg}
                    >
                      {taskList && taskList.map((option, i) => {
                        return <MenuItem key={i} value={option.taskId}>{option.taskNumber}</MenuItem>;
                      })} 
                    </Select>
                  : item.taskNumber
                  }
                </TableCell>
                <TableCell>
                  { isAddEdit && entryId === item.id
                  ? <TextField 
                      id="timeSpent"
                      type="Number"
                      variant="outlined" 
                      onInput={(e)=>{ 
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                      }}
                      placeholder="Enter time spent"
                      onChange={(e) => handleOnChange_text(e)}
                      value={!log.timeSpent ? "" : log.timeSpent}
                      errorMsg={errorMsg}
                    />
                  : formatNumbers(roundTo2Decimal(item.timeSpent))
                  }
                </TableCell>
                <TableCell>{!isAddEdit && !item.isUserKeyInTask && <span className={classes.link} onClick={()=>handleModal_details(item)}>Details</span>}</TableCell>
                <TableCell align="right">
                  { canWrite && isAddEdit && entryId === item.id
                  ? <React.Fragment>
                      <IconButton 
                        type="confirm"
                        onClick={() => handleButtonClick_confirm()}
                      />
                      <IconButton 
                        type="close"
                        onClick={() => handleButtonClick_cancel()}
                      />
                    </React.Fragment>
                  : <React.Fragment>
                      <IconButton 
                        type="edit"
                        onClick={() => handleButtonClick_edit(item.id)}
                        disabled={Boolean(entryId && entryId !== item.id)}
                      />
                      <IconButton 
                        type="delete"
                        onClick={() => handleModal_delete(item.id)}
                        disabled={Boolean(entryId && entryId !== item.id)}
                      />
                    </React.Fragment>
                  }
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell className={classes.bold}>Total Time Spent</TableCell>
            <TableCell colSpan={3} className={classes.bold}>{formatNumbers(roundTo2Decimal(totalTime))} mins / {formatNumbers(roundTo2Decimal(totalTime/60))} hours</TableCell>
          </TableRow>
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={filter.page+1}
          onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          disabled={Boolean(isAddEdit)}
        />
      }
      {canWrite &&
        <div className={classes.cardAction}>
          <Button 
            className={classes.buttonSecondary}
            startIcon={<AddIcon />}
            onClick={()=>handleButtonClick_add()}
            disabled={isAddEdit}
          >
            Add Another Entry
          </Button>
        </div>
      }
      {checkPermission("Admin.Human Resource", "readEdit") &&
        <Typography className={classes.link}>
          <Link to={{pathname: "/admin/human-resource/user-configuration"}}>Go to “Human Resource Management” Module in the admin portal to configure hourly rates of employees</Link>
        </Typography>
      }
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete ths entry? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <DetailsModal 
        open={Boolean(openDetailsModal)}
        onClose={() => handleModal_details(null)}
        title="workOrderNumber"
        id="id"
        data={openDetailsModal}
      />
    </React.Fragment>
  );
}
