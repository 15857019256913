import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllDeliveryOrderForAssignment, GetDeliveryTripSetting, GetAllVehicles, GetAllDriver, GetCustomerList } from "services/UserPortal/DeliveryService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  results: [],
  assignment: {returnToStartPoint: true},
  deliveryTripDetails: [],
  startPoint: {
    longitude: "103.78930650819217",
    latitude: "1.3757771917613002"
  },
  inBetweenStopProcessingTime: 0,
  //Lookup
  vehicleList: [],
  driverList: [],
  customerList: [],
};

const assignment = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.assignment[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllDeliveryOrderForAssignment.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.results = action.payload.result.items;
    },
    [GetDeliveryTripSetting.fulfilled]: (state, action) => {
      state.startPoint = _.pick(action.payload.result, ["address", "latitude", "longitude"]);
      state.inBetweenStopProcessingTime = action.payload.result.inBetweenStopProcessingTime;
    },
    [GetAllVehicles.fulfilled]: (state, action) => {
      state.vehicleList = _.sortBy(action.payload.result, ["vehicleRefNo"]);
    },
    [GetAllDriver.fulfilled]: (state, action) => {
      state.driverList = _.sortBy(action.payload.result, ["name"]);
    },
    [GetCustomerList.fulfilled]: (state, action) => {
      state.customerList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = assignment.actions;

// export the reducer
export default assignment.reducer;