import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Modal from 'shared-components/Modal/Modal';
import Checkbox from "shared-components/Checkbox/Checkbox";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import { rowsPerTable } from "config";
import { setDirty } from "store/general";
import { renderValue, getCurrentTime, setStartTime, setEndTime, checkPermission } from "common/functions";
import { setValues, updateRequest } from "../store/task";
import { getOperationTaskDetail, goBackFromSubTask } from "./utils";
import { GetFrequencyList, UpdateOperationSubTaskList } from "services/UserPortal/OperationsService";

import AssignmentTable from "./AssignmentTable";
import styles from "assets/jss/components/UserPortal/operationsStyle.js";

const useStyles = makeStyles(styles);

export default function TaskAssignment(props) {
  const prop = props.location && props.location.state;

  const classes = useStyles();
  let history = useHistory()
  const dispatch = useDispatch();
  const task = useSelector(store => store.user.operations.task.task);
  const taskDetail = useSelector(store => store.user.operations.task.taskDetail);
  const rawMaterial = useSelector(store => store.user.operations.task.rawMaterial);
  const equipment = useSelector(store => store.user.operations.task.equipment);
  const other = useSelector(store => store.user.operations.task.other);
  const userList = useSelector(store => store.common.userList);
  const frequencyList = useSelector(store => store.user.operations.task.frequencyList);
  const selectedAction = useSelector(store => store.user.operations.task.selectedAction);
  const config = useSelector(store => store.user.operations.task.taskConfig);
  const [page, setPage] = React.useState({rm: 0, equip: 0, other: 0});
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [assignedUser, setAssignedUser] = React.useState(null);
  const [dateFilter, setDateFilter] = React.useState(prop && prop.dateFilter);

  const rmCount = Math.ceil(rawMaterial.length / rowsPerTable);
  const equipCount = Math.ceil(equipment.length / rowsPerTable);
  const otherCount = Math.ceil(other.length / rowsPerTable);

  const assignmentTable = [
    { title: "Raw Materials", data: rawMaterial, pageKey: "rm", refNoKey: "rawMaterialRefNo", count: rmCount },
    { title: "Equipment", data: equipment, pageKey: "equip", refNoKey: "equipmentRefNo", count: equipCount },
    { title: "Others", data: other, pageKey: "other", refNoKey: "deviceRefNo", count: otherCount },
  ]

  const handleOnChange_select = (e) => {
    if(e.target.name == "frequency" && e.target.value != "Once") {
      dispatch(updateRequest({[e.target.name] : e.target.value, numberOfRepeat: e.target.value == "Once" ? null : 1}));
    } else {
      dispatch(updateRequest({[e.target.name] : e.target.value}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateRequest({additionalMembers: value, additionalPersons : value.map(({id}) => id)}));
  };

  const handleOnChange_dateFilter = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      setDateFilter(moment(value).format("YYYY-MM-DD"));
    } 
  };

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      dispatch(updateRequest({[field]: newValue.format()}));
    } else {
      dispatch(updateRequest({[field]: value}));
    }
  };

  const handleOnChange_time = (value, field) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(config);
    if(value && value.isValid() && value != "Invalid Date") {
      if (field.includes("startTime")) {
        let endTimeISO = setEndTime(value, payload.endTime);
        payload.endTimeISO = endTimeISO;
        payload.endTime = moment(endTimeISO).format();
      }
      if (field.includes("endTime")) {
        let startTimeISO = setStartTime(payload.startTime, value);
        payload.startTimeISO = startTimeISO;
        payload.endTime = moment(startTimeISO).format();
      }
    }
    payload[field+"ISO"] = value;
    payload[field] = moment(value).format();
    dispatch(setValues({taskConfig: payload}));
  };

  const handleModal_edit = (isCancel) => {
    setOpenEditModal(!openEditModal);
    if (isCancel) {
      setErrorMsg(false);
      dispatch(setValues({taskConfig: {}}));
    } else {
      dispatch(setValues({taskConfig: {
        frequency: "Once", 
        startDate: moment(task.expectedStartDate).format(),
        startTime: getCurrentTime(task.expectedStartDate).format(), 
        endTime: getCurrentTime(task.expectedStartDate).add(1, "hour").format(),
        startTimeISO: getCurrentTime(task.expectedStartDate),
        endTimeISO: getCurrentTime(task.expectedStartDate).add(1, "hour"),
      }}));
    }
  }
  
  const handleButtonClick_save = () => {
    if (validateFields()) {
      setOpenEditModal(false);
      let body = _.cloneDeep(selectedAction);
      body = _.map(body, (item) => {
        return { ...item, ...config };
      });
      dispatch(UpdateOperationSubTaskList(body))
      .then(({error}) => {
        if (error) {
          setOpenEditModal(true);
        } else {
          handleModal_edit(true);
          dispatch(setDirty(false));
          setPage({rm: 0, equip: 0, other: 0});
          dispatch(setValues({selectedAction: []}));
          getOperationTask();
        }
      });
    }
  };

  const handleOnChange_assignedUserId = (e) => {
    setAssignedUser(e.target.value)
  };

  const handleButtonClick_saveAssignedUser = () => {
    let allSubtasks = [...rawMaterial, ...equipment, ...other];
    const update = _.filter(_.map(allSubtasks, item => {
      if (!item.isAction) {
        return ({..._.pick(item, ["id", "frequency", "additionalPersons", "startTime", "endTime"]), assignedUserId: assignedUser})
      }
    }), item => item)
    dispatch(UpdateOperationSubTaskList(update))
    .then(({error}) => {
      if (!error) {
        getOperationTask();
        setAssignedUser(null)
      }
    });
  }
  
  const handleButtonClick_back = () => {
    goBackFromSubTask(history, prop);
  };

  const validateFields = () => {
    if (!moment(config.startDate).isValid()) {
      setErrorMsg({field: "startDate", msg: "Invalid date"});
      return false;
    }
    if (moment(config.startDate).isBefore(moment(task.expectedStartDate, "day"))) {
      setErrorMsg({field: "startDate", msg: "Sub task should start on "+moment(task.expectedStartDate).format("DD/MM/YYYY")});
      return false;
    }
    if (!moment(config.startTime).isValid()) {
      setErrorMsg({field: "startTime", msg: "Invalid time"});
      return false;
    }
    if (!moment(config.endTime).isValid()) {
      setErrorMsg({field: "endTime", msg: "Invalid time"});
      return false;
    }
    if (moment(config.endTime).isSameOrBefore(moment(config.startTime))) {
      setErrorMsg({field: "endTime", msg: "Invalid time range"});
      return false;
    }
    // if (!config.assignedUserId) {
    //   setErrorMsg({field: "assignedUserId", msg: "Select a person"});
    //   return false;
    // }
    if (config.frequency != "Once" && !config.numberOfRepeat) {
      setErrorMsg({field: "numberOfRepeat", msg: "Invalid repetition"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }
  
  const getOperationTask = (stopLoading) => {
    if (!_.isEmpty(task) && prop) {
      getOperationTaskDetail({startDate: dateFilter, endDate: dateFilter, stopLoading}, history, prop);
    } else {
      handleButtonClick_back();
    }
  }

  React.useEffect(() => {
    getOperationTask();
  },[dateFilter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetFrequencyList());
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(setValues({rawMaterial: [], equipment: [], other: [], selectedId: []}));
    }
  },[]);

  const isCompleted = () => {
    let boo = true;
    let allSubtasks = [...rawMaterial, ...equipment, ...other];
    for (let i = 0; i < allSubtasks.length; i++) {
      if (!allSubtasks[i].isAction) {
        boo = false;
      }
    }
    return boo
  };

  return (
    <React.Fragment>
      <Card 
        title={prop&&prop.processName+" Process - "+task.taskName}
        subheader={
          <React.Fragment>
            <div className={classes.taskSubheader}>
              <Typography>Product:&nbsp;{prop.isMixProduct ? taskDetail.productName : taskDetail.parentProductName}</Typography>
              {prop.isMixProduct && <Typography>Parent Product:&nbsp;{taskDetail.parentProductName}</Typography>}
              <Typography>Work Order:&nbsp;{taskDetail.workOrderNumber}</Typography>
              <Typography>Lot ID:&nbsp;{taskDetail.lotId}</Typography>
            </div>
            {"Expected Start Date: "+moment(task.expectedStartDate).format("DD/MM/YYYY")}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <DateTimePicker
              closeOnSelect
              clearable
              placeholder="Start Date"
              className={classes.cardActionDropdown}
              value={dateFilter ? dateFilter : null}
              onChange={(e) => handleOnChange_dateFilter(e)}
              onClear={()=>setDateFilter(null)}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={handleButtonClick_back}>
              Go Back
            </Button>
            <Button className={classes.button} onClick={()=>handleModal_edit()} disabled={!selectedAction.length}>
              Edit Selected
            </Button>
          </React.Fragment>
        }
      >
        {checkPermission("Task Assignment", "readEdit") &&
          <div className={classes.assignedPerson}>
            <Typography className={classes.assignedPersonHeader}>Edit Assigned Person: </Typography>
            <Select
              name="assignedUserId"
              className={classes.assignedPersonSelect}
              onChange={(e)=>handleOnChange_assignedUserId(e)}
              value={renderValue(assignedUser)}
              placeholder="Select a person"
              errorMsg={errorMsg}
              disabled={selectedAction.length || !_.isEmpty(config) || isCompleted()}
            >
              {userList && userList.map((item) => {
                return <MenuItem key={item.id} value={item.id}>{item.userName}</MenuItem>;
              })} 
            </Select>
            <Button className={classes.button} onClick={()=>handleButtonClick_saveAssignedUser()} disabled={!assignedUser || selectedAction.length || !_.isEmpty(config)}>
              Set
            </Button>
          </div>
        }
        {_.map(assignmentTable, (item,index) => {
          return (
            <AssignmentTable 
              key={index}
              {...item}
              handleOnChange_text={handleOnChange_text}
              handleOnChange_select={handleOnChange_select}
              handleOnChange_date={handleOnChange_date}
              handleOnChange_time={handleOnChange_time}
              handleOnChange_autocomplete={handleOnChange_autocomplete}
              getOperationTask={getOperationTask} 
              validateFields={validateFields} 
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg} 
              page={page} 
              setPage={setPage} 
              task={task}
            />
          )
        })}
      </Card>
      <Modal
        open={openEditModal}
        className={classes.editPaper}
        classes={{
          content: classes.editContent
        }}
        onClose={() => handleModal_edit(true)}
        title={<Typography className={classes.modalTitle}>Editing Multiple Subtasks</Typography>}
        content={
          <Grid container spacing={2} className={classes.editModal}>
            <Grid item xs={12}>
              <DateTimePicker
                label="Start Date"
                name="startDate"
                minDate={moment(task.expectedStartDate).subtract(task.noOfAdjustableDays, "days")} // is include exclusion date
                maxDate={moment(task.expectedStartDate).add(task.noOfAdjustableDays, "days")} // is include exclusion date
                value={config.startDate}
                onChange={(e) => handleOnChange_date(e, "startDate")}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Frequency"
                name="frequency"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(config.frequency)}
                disabled={!task.ableToChangeFrequency}
              >
                {frequencyList && frequencyList.map((item) => {
                  return <MenuItem key={item} value={item}>{item}</MenuItem>;
                })} 
              </Select> 
            </Grid>
            <Grid item xs={6}>
              {config.frequency != "Once" &&
                <TextField 
                  label="No. of Repeats"
                  id="numberOfRepeat"
                  variant="outlined"
                  type="Number"
                  onInput={(e)=>{ 
                    e.target.value = Math.max(1, parseInt(e.target.value)).toString().slice(0,4)
                  }}
                  value={renderValue(config.numberOfRepeat)}
                  onChange={(e) => handleOnChange_text(e)}
                  errorMsg={errorMsg}
                />
              }
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label="Start Time"
                type="time"
                name="startTime"
                value={config.startTimeISO}
                onChange={(e) => handleOnChange_time(e, "startTime")}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label="End Time"
                type="time"
                name="endTime"
                value={config.endTimeISO}
                onChange={(e) => handleOnChange_time(e, "endTime")}
                errorMsg={errorMsg}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Select
                label="Assigned Person"
                name="assignedUserId"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(config.assignedUserId)}
                placeholder="Select a person"
                errorMsg={errorMsg}
              >
                {userList && userList.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.userName}</MenuItem>;
                })} 
              </Select>
            </Grid> */}
            <Grid item xs={12}>
              <Autocomplete
                label="Team Members"
                dataCount={userList.length}
                options={_.filter(userList, ({id}) => selectedAction[0] && selectedAction[0].assignedUserId !== id)}
                renderOption={(option) => (
                  <div key={option.id}>
                    <Checkbox checked={_.findIndex(config.additionalMembers, ({id}) => id === option.id) > -1} />
                    {option.userName}
                  </div>
                )}
                placeholder="Select team members"
                onChange={(_, newValue) => {
                  handleOnChange_autocomplete(newValue);
                }}
                value={config.additionalMembers ? config.additionalMembers : []}
                renderValue={"userName"}
                multiple
                // disabled={!config.assignedUserId}
              />
            </Grid>
          </Grid>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_edit(true)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_save()}>Apply</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
