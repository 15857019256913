import { createSlice } from "@reduxjs/toolkit";
import { 
  GetProductListByWorkOrderId,
  GetBatchIDList, 
  GetTrayPosition, 
  GetProductList,
  GetQCDetailsFromOperation,
  CalculateNurseryDuration, 
  GetProductInfoById, 
  GetAllGerminationReports, 
  GetGerminationReportDetail,
  GetBatchStartDateByBatchID,
  CalculateGerminationRate,
  CalculateDatesByWorkOrderIdAndProductId,
  GetSeedSupplierNameByBatchID
} from "services/UserPortal/QualityControlService";

// initial state
const initialState = {
  filter: {},
  //vege
  totalCount: 0,
  result: [],
  qualityControl: {},
  // lookup
  productList: [],
  trayPositionList: [],
  batchIDList: [],
  //filter
  filterProductList: [],
};

const germination = createSlice({
  name: "germination",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.qualityControl[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    //select work order to work on
    [GetAllGerminationReports.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.result = action.payload.result.items;
    },
    [GetGerminationReportDetail.fulfilled]: (state, action) => {
      state.qualityControl = action.payload.result;
    },
    // lookup
    [GetProductListByWorkOrderId.fulfilled]: (state, action) => {
      state.productList = action.payload.result;
    },
    [GetTrayPosition.fulfilled]: (state, action) => {
      state.trayPositionList = action.payload.result;
    },
    [GetBatchIDList.fulfilled]: (state, action) => {
      state.batchIDList = action.payload.result;
    }, 
    // filter
    [GetProductList.fulfilled]: (state, action) => {
      state.filterProductList = action.payload.result;
    },
    // calculation
    [GetQCDetailsFromOperation.fulfilled]: (state, action) => {
      state.qualityControl = {...state.qualityControl, ...action.payload.result};
    }, 
    [CalculateNurseryDuration.fulfilled]: (state, action) => {
      state.qualityControl.nurseryDuration = action.payload.result;
    }, 
    [CalculateGerminationRate.fulfilled]: (state, action) => {
      state.qualityControl.germinationRate = action.payload.result;
    },
    [GetProductInfoById.fulfilled]: (state, action) => {
      state.qualityControl.vegetableVariety = action.payload.result.vegetableVariety;
    },
    [GetBatchStartDateByBatchID.fulfilled]: (state, action) => {
      state.qualityControl.batchStartDate = action.payload.result;
    },
    [CalculateDatesByWorkOrderIdAndProductId.fulfilled]: (state, action) => {
      state.qualityControl.germinateDate = action.payload.result.germinateDate;
      state.qualityControl.transplantDate = action.payload.result.transplantDate;
      state.qualityControl.harvestDate = action.payload.result.harvestDate;
      state.qualityControl.batchOpenDate = action.payload.result.batchOpenDate;
    },
    [GetSeedSupplierNameByBatchID.fulfilled]: (state, action) => {
      state.qualityControl.supplierName = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = germination.actions;

// export the reducer
export default germination.reducer;