// User Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import Popper from "shared-components/Popper/Popper";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { setSearchText } from "store/general";
import { rowsPerTable } from "config";
import { SearchUser, DeleteUser, UntaggedUsersFromFarm } from "services/AdminPortal/UserService";
import { userTableHead } from "enums/AdminPortal/TableHeaders";
import { resetSearch } from ".";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import helper from "assets/icons/black/helper.svg";
import untag from "assets/icons/orange/untag.svg";
import alert from "assets/icons/orange/alert-line.svg";
import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/AdminPortal/userStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchUserResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResultsCount = useSelector(store => store.admin.user.searchResultsCount);
  const searchResults = useSelector(store => store.admin.user.searchResults);
  const canWrite = useRouteCanWrite();
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [userName, setUserName] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openUntagModal, setOpenUntagModal] = React.useState(false);
  const [openResultModal, setOpenResultModal] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const farmName = _.find(userDetail.farms, "isActive").farmName;
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const count = Math.ceil(searchResultsCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   if (property !== "action") {
  //     const isAsc = orderBy === property && order === 'asc';
  //     setOrder(isAsc ? 'desc' : 'asc');
  //     setOrderBy(property);
  //   }
  // };

  const handleModal_untag = (id, name) => {
    setUserId(id);
    setUserName(name);
    setOpenUntagModal(!openUntagModal);
  }

  const handleButtonClick_untag = () => {
    dispatch(UntaggedUsersFromFarm(userId))
    .then((response) => {
      setSuccess(response.payload.result.isSuccess);
      setOpenResultModal(true);
    });
  }

  const handleButtonClick_result = () => {
    dispatch(SearchUser({keyword: params.keyword, page}));
    resetState();
  }

  const handleModal_delete = (id) => {
    setUserId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteUser(userId))
    .then(() =>{
      dispatch(SearchUser({keyword: params.keyword, page: 0}));
      resetState();
    });
  }

  const resetState = () => {
    setUserId(null);
    setUserName(null);
    setPage(0);
    setSuccess(false);
    setOpenDeleteModal(false);
    setOpenUntagModal(false);
    setOpenResultModal(false)
  }

  React.useEffect(() => {
    dispatch(resetSearch());
    dispatch(SearchUser({keyword: params.keyword, page}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(resetSearch());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value) {
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  userTableHead[9].label = 
  <div className="flex">
    Untag / Edit / Delete
    <Popper 
      open={openPopper}
      className={classes.popper}
      closePopper={() => setOpenPopper(false)}
      buttonClick={() => setOpenPopper(false)}
      placement="left"
      title="Untag User"
      content="Untag an user from the farm to remove the user from accessing the particular farm’s information. "
    >
      <IconButton 
        onClick={() => setOpenPopper(true)}
      >
        <img className={classes.icon} src={helper} alt="helper" />
      </IconButton>
    </Popper>
  </div>;
  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(searchResultsCount) + " Search Result(s) From 1 Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount > 0 &&
        <React.Fragment>
          <Card 
            title = {
              <React.Fragment>
                {searchResultsCount + " Result(s) From User List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={filterActionTableHead(userTableHead, canWrite)}
                    // order={order}
                    // orderBy={orderBy}
                    // onRequestSort={handleRequestSort}
                  >
                    { searchResults.map((user) => {
                      return (
                        <TableRow key={user.id}>
                          <TableCell><Typography className={classes.ellipsis}>{highlightValue(user.name)}</Typography></TableCell>
                          <TableCell><Typography className={classes.ellipsis}>{highlightValue(user.userName)}</Typography></TableCell>
                          <TableCell><Typography className={classes.ellipsis}>{highlightValue(user.phone)}</Typography></TableCell>
                          <TableCell><Typography className={classes.emailEllipsis}>{highlightValue(user.emailAddress)}</Typography></TableCell>
                          <TableCell>{highlightValue(user.employeeId)}</TableCell>
                          <TableCell>{highlightValue(_.startCase(_.toLower(user.roleNames[0])))}</TableCell>
                          <TableCell>{highlightValue(user.gender)}</TableCell>
                          <TableCell>{highlightValue(user.workforceType)}</TableCell>
                          <TableCell>{highlightValue(user.country)}</TableCell>
                          <TableCell align="left" className={classes.tableAction}>
                            { user.id !== userDetail.id && canWrite &&
                              <IconButton 
                                onClick={() => handleModal_untag(user.id, user.name)}
                                disabled={userId===user.id}
                              >
                                <img className={classes.icon} src={untag} alt="untag" />
                              </IconButton>
                            }
                            {canWrite &&
                              <Link to={{pathname: "/admin/user/"+user.id, state: {prevPath: location.pathname+location.search}}}>
                                <CustomIconButton type="edit" />
                              </Link>
                            }
                            { user.id !== userDetail.id && canWrite &&
                              <CustomIconButton
                                type="delete" 
                                onClick={() => handleModal_delete(user.id)}
                                disabled={userId===user.id}
                              />
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
          <Modal
            open={openUntagModal}
            onClose={() => handleModal_untag(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content={"You are about to untag " + userName + " from " + farmName + ". Do you want to proceed?"}
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal_untag(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_untag()}>Untag</Button>
              </React.Fragment>
            }
          />
          <Modal
            open={openDeleteModal}
            onClose={() => handleModal_delete(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this user? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
          <Modal
            open={openResultModal}
            onClose={() => handleButtonClick_result()}
            icon={success ? <img className={classes.icon_64} src={confirm} alt="confirm" /> : <img className={classes.icon_64} src={alert} alt="alert" />}
            title={success ? "Success" : "Error"}
            content={ success
              ? "You have successfully untagged " + userName + " from " + farmName + "."
              : "This is " + userName + "'s last tagged farm. Please delete user if you want to remove user from the farm"
            }
            actions={
              <Button className={classes.button} onClick={() => handleButtonClick_result()}>OK</Button>
            }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
}
