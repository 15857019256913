const authStyle = () => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "#F0F9F0",
  },
  mainPanel: {
    overflow: "overlay",
    position: "relative",
    float: "right",
    height: "100vh",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  loginImg: {
    margin: "auto",
    height: "100vh"
  },
  container: {
    width: "50%",
    margin: "auto"
  }
});

export default authStyle;
