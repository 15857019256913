import {
  icon_24,
  drawerWidth,
  transition,
  container,
} from "assets/jss/generaliseStyle.js";

const portalStyle = () => ({
  icon_24, 
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    backgroundColor: "#F0F2F4", // #customise
  },
  mainPanel: {
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth})`,
    // },
    width: `calc(100% - ${drawerWidth})`,
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    // width: "100%",
    overflow: "hidden",
  },
  content: {
    padding: "1.771vw 1.781vw",
    height: "90vh",
    overflow: "overlay"
  },
  container,
  search: {
    paddingBottom: "1.771vw",
  },
  textfield: {
    minHeight: "2.969vw",
    height: "2.969vw",
    width: "29.167vw",
  },
  clearIcon: {
    ...icon_24, 
    cursor: "pointer",
  }
});

export default portalStyle;