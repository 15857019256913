import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { finishedGoodsAlarmTableHead, planningAlarmTableHead, deliveryAlarmTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, reset } from "./";
import { GetAllAlarmNotification } from "services/UserPortal/AlarmService";

import styles from "assets/jss/components/UserPortal/alarmStyle.js";

const useStyles = makeStyles(styles);

export default function Notification(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.alarm.totalCount);
  const result = useSelector(store => store.user.alarm.alarms);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('creationTime');
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState({timeStamp: null});
  
  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const items = [
    {title: "Finished Goods Module", url: "/user/alarm/details/finished-goods", tableHead: finishedGoodsAlarmTableHead, name: "UserPortal.FinishedGoods"},
    {title: "Planning Module", url: "/user/alarm/details/planning", tableHead: planningAlarmTableHead, name: "UserPortal.Planning"},
    {title: "Delivery Module", url: "/user/alarm/details/delivery", tableHead: deliveryAlarmTableHead, name: "UserPortal.Delivery"},
  ]

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      setFilter({...filter, timeStamp: moment(value).format("YYYY-MM-DD")});
    } 
  };

  const getAllAlarmNotification = (pageNo, stopLoading) => {
    if (alarmPage) {
      dispatch(GetAllAlarmNotification({...filter, page: pageNo, moduleName: alarmPage.name, isNotification: true, stopLoading}))
      .then((response) => {
        if (response.payload && response.payload.result) {
          dispatch(setValues({
            totalCount: response.payload.result.totalCount,
            alarms: response.payload.result.items,
          }));
        }
      });
    }
  }

  React.useEffect(() => {
    setPage(0);
    getAllAlarmNotification(0);
  },[filter]);

  React.useEffect(() => {
    getAllAlarmNotification(page);
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllAlarmNotification(page, true);
  });

  const alarmPage = items.find(({url})=> url===props.location.pathname);
  return (
    <Card 
      title={alarmPage && alarmPage.title}
      action={
        <React.Fragment>
          <DateTimePicker
            closeOnSelect
            clearable
            placeholder="Select a date"
            className={classes.cardActionDropdown}
            value={filter.timeStamp}
            onChange={(e) => handleOnChange_date(e)}
            onClear={()=>setFilter({...filter, timeStamp: null})}
          />
        </React.Fragment>
      }
      cardActions={
        <Button
          className={classes.buttonSecondary}
          onClick={()=>history.push("/user/alarm/details")}
        >
          Go Back
        </Button>
      }
    >
      <Typography className={classes.title}>Notifications</Typography>
      <Table
        className={classes.table}
        header={alarmPage && alarmPage.tableHead}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          return (
            <TableRow key={index}>
              {alarmPage && alarmPage.tableHead.map(({id}, i) => {
                let value = _.get(item, id);
                if (id.includes("Time") && value) {
                  value = moment(value).format("DD MMM YYYY, HHmm");
                }
                return <TableCell key={i}>{value}</TableCell>
              })}
            </TableRow>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
    </Card>
  );
}
