import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { formatNumbers, roundTo2Decimal } from "common/functions";
import { reset } from "../store/costOverview";
import { GetProfitAndLoss, DownloadProfitAndLoss } from "services/UserPortal/CostProductivityService";

import DateFilter from "./DateFilter";
import styles from "assets/jss/components/UserPortal/costProductivityStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');

export default function ProfitLoss() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profitLoss = useSelector(store => store.user.costProductivity.report.profitLoss);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});

  const handleButtonClick_export = () => {
    dispatch(DownloadProfitAndLoss({startDate: date.startDate, endDate: date.endDate}))
    .then((response) => {
      FileDownload(response.payload.data, 'profit_and_loss.xlsx');
    });
  };

  React.useEffect(() => {
    dispatch(GetProfitAndLoss({startDate: date.startDate, endDate: date.endDate}));
  },[date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          root: clsx(classes.cardPaper, classes.profitLossCard)
        }}
        title={<React.Fragment>Profit and Loss <span className={classes.rightCardTitle}>- {moment(date.startDate).format("DD/MM/YYYY")} to {moment(date.endDate).format("DD/MM/YYYY")}</span></React.Fragment>}
        action={
          <DateFilter date={date} setDate={setDate} />
        }
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleButtonClick_export()}
            >
              Export
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table>
            <TableRow>
              <TableCell className={classes.revenue}>Revenue</TableCell>
              <TableCell align="right" className={classes.revenue}>${formatNumbers(roundTo2Decimal(profitLoss.totalRevenue))}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost</TableCell>
              <TableCell align="right">${formatNumbers(roundTo2Decimal(profitLoss.totalCost))}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Profit / Loss</TableCell>
              <TableCell align="right">${formatNumbers(roundTo2Decimal(profitLoss.profitOrLoss))}</TableCell>
            </TableRow>
          </Table>
        </div>
      </Card>
    </React.Fragment>
  );
}
