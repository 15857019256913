/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// core components
import Card from "shared-components/Card/Card";

import { setValues } from "../store/report";
import { GetCostingBySaleOrder, GetCostingbyProduct, GetTotalCostByCustomer, DownloadTotalCostBySaleOrder, DownloadTotalCostByProduct, DownloadTotalCostByCustomer } from "services/UserPortal/CostProductivityService";

import ParamList from "./ParamList";
import DateFilter from "./DateFilter";
import ColumnChart from "./ColumnChart";
import LineChart from "./LineChart";
import styles from "assets/jss/components/UserPortal/costProductivityStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');

export default function CostReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const subSaleOrderList = useSelector(store => store.user.costProductivity.report.subSaleOrderList);
  const results = useSelector(store => store.user.costProductivity.report.reports);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});
  const [tab, setTab] = React.useState(0);
  const [selected, setSelected] = React.useState([]);

  const handleButtonClick_export = () => {
    if (selected.length) {
      switch (tab) {
        case 0: {
          dispatch(DownloadTotalCostBySaleOrder({SSOIds: selected}))
          .then((response) => {
            FileDownload(response.payload.data, 'total_cost_sale_order.xlsx');
          });
          break;
        } 
        case 1: {
          dispatch(DownloadTotalCostByProduct({ProductIds: selected, ...date}))
          .then((response) => {
            FileDownload(response.payload.data, 'total_cost_product.xlsx');
          });
          break;
        } 
        case 2: {
          dispatch(DownloadTotalCostByCustomer({CustomerIds: selected, ...date})).then((response) => {
            FileDownload(response.payload.data, 'total_cost_customer.xlsx');
          });
          break;
        } 
      }
    }
  };

  React.useEffect(() => {
    setSelected(subSaleOrderList.length ? _.map(subSaleOrderList.slice(0, subSaleOrderList.length > 5 ? 5 : subSaleOrderList.length), ({subSaleOrderId}) => subSaleOrderId.toString()) : []);
  },[subSaleOrderList]);

  React.useEffect(() => {
    if (selected.length) {
      switch (tab) {
        case 0: {
          dispatch(GetCostingBySaleOrder({SSOIds: selected}));
          break;
        } 
        case 1: {
          dispatch(GetCostingbyProduct({ProductIds: selected, ...date}));
          break;
        } 
        case 2: {
          dispatch(GetTotalCostByCustomer({CustomerIds: selected, ...date}));
          break;
        } 
      }
    } else {
      dispatch(setValues({reports: []}));
    }
  },[tab, selected, date]);

  return (
    <React.Fragment>
      <Typography className={classes.title}>Choose the parameters for comparison. Please take note that the dates indicated are the delivery dates for the orders.</Typography>
      <div className="flex">
        <ParamList selected={selected} setSelected={setSelected} tab={tab} setTab={setTab}  />
        <Card
          classes={{
            root: clsx(classes.rightCard, classes.cardPaper)
          }}
          title={<React.Fragment>Cost Chart <span className={classes.rightCardTitle}>{tab !== 0 && " - " + moment(date.startDate).format("DD/MM/YYYY") + " to " + moment(date.endDate).format("DD/MM/YYYY")}</span></React.Fragment>}
          action={ tab !== 0 &&
            <DateFilter date={date} setDate={setDate} />
          }
          cardActions={
            <React.Fragment>
              <Button disabled />
              <Button
                className={classes.buttonSecondary}
                onClick={()=>handleButtonClick_export()}
              >
                Export
              </Button>
            </React.Fragment>
          }
        >
          <Typography className={classes.smallText}>* Click and drag in the plot area to zoom in</Typography>
          { tab === 0
          ? <ColumnChart title="Cost" />
          : <LineChart title="Cost" date={date} tab={tab} />
          }
        </Card> 
      </div>
    </React.Fragment>
  );
}
