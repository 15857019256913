// @mui/icons-material
import w_home from "assets/icons/white/home.svg";
import w_customerOrder from "assets/icons/white/customer-order.svg";
import w_planning from "assets/icons/white/planning.svg";
import w_capacity from "assets/icons/white/capacity.svg";
import w_rawMaterial from "assets/icons/white/raw-material.svg";
import w_operations from "assets/icons/white/operations.svg";
import w_warehouse from "assets/icons/white/warehouse-user.svg";
import w_finishedGoods from "assets/icons/white/finished-goods.svg";
import w_delivery from "assets/icons/white/delivery.svg";
import w_monitoring from "assets/icons/white/monitoring.svg";
import w_climate from "assets/icons/white/climate.svg";
import w_hr from "assets/icons/white/human-resource.svg";
import w_equipment from "assets/icons/white/equipment.svg";
import w_cp from "assets/icons/white/cost-productivity.svg";
import w_qualityControl from "assets/icons/white/quality-control.svg";
import w_smartMetering from "assets/icons/white/smart-metering.svg";
import w_asrs from "assets/icons/white/asrs.svg";
import w_amr from "assets/icons/white/amr.svg";
import w_report from "assets/icons/white/report.svg";
import w_traceability from "assets/icons/white/traceability.svg";
import w_alarm from "assets/icons/white/alarm.svg";
import w_rfid from "assets/icons/white/rfid.svg";
import w_flow from "assets/icons/white/flow.svg";
import g_home from "assets/icons/default/home.svg";
import g_customerOrder from "assets/icons/default/customer-order.svg";
import g_planning from "assets/icons/default/planning.svg";
import g_capacity from "assets/icons/default/capacity.svg";
import g_rawMaterial from "assets/icons/default/raw-material.svg";
import g_operations from "assets/icons/default/operations.svg";
import g_warehouse from "assets/icons/default/warehouse-user.svg";
import g_finishedGoods from "assets/icons/default/finished-goods.svg";
import g_delivery from "assets/icons/default/delivery.svg";
import g_monitoring from "assets/icons/default/monitoring.svg";
import g_climate from "assets/icons/default/climate.svg";
import g_hr from "assets/icons/default/human-resource.svg";
import g_equipment from "assets/icons/default/equipment.svg";
import g_cp from "assets/icons/default/cost-productivity.svg";
import g_qualityControl from "assets/icons/default/quality-control.svg";
import g_smartMetering from "assets/icons/default/smart-metering.svg";
import g_asrs from "assets/icons/default/asrs.svg";
import g_amr from "assets/icons/default/amr.svg";
import g_report from "assets/icons/default/report.svg";
import g_traceability from "assets/icons/default/traceability.svg";
import g_alarm from "assets/icons/default/alarm.svg";
import g_rfid from "assets/icons/default/rfid.svg";
import g_flow from "assets/icons/default/flow.svg";
// core components/views for User layout
import HomePage from "components/UserPortal/Home/Home";
import COManagement from "components/UserPortal/CustomerOrder/Management/Management";
import COCapacity from "components/UserPortal/CustomerOrder/Capacity/Capacity";
import PlanningOutcome from "components/UserPortal/Planning/PlanningOutcome/PlanningOutcome";
import Conversion from "components/UserPortal/Planning/Conversion/Conversion";
import Adjustment from "components/UserPortal/Planning/Adjustment/Adjustment";
import UnassignedOrders from "components/UserPortal/Planning/UnassignedOrders/UnassignedOrders";
import MaximumOrders from "components/UserPortal/Planning/MaximumOrders/MaximumOrders";
import PlanningReport from "components/UserPortal/Planning/Report/Report";
import PlanningSettings from "components/UserPortal/Planning/Settings/Settings";
import Capacity from "components/UserPortal/Capacity/Capacity";
import RMManagement from "components/UserPortal/RawMaterial/Management/Management";
import RMReserve from "components/UserPortal/RawMaterial/Reserve/Reserve";
import RMIssue from "components/UserPortal/RawMaterial/Issue/Issue";
import RMReturn from "components/UserPortal/RawMaterial/Return/Return";
import RMReceipt from "components/UserPortal/RawMaterial/Receipt/Receipt";
import RMCost from "components/UserPortal/RawMaterial/Cost/Cost";
import RMReconciliation from "components/UserPortal/RawMaterial/Reconciliation/Reconciliation";
import OperationsOverview from "components/UserPortal/Operations/Overview/Overview";
import OverallTask from "components/UserPortal/Operations/Tasks/OverallTask";
import TaskAssignment from "components/UserPortal/Operations/Tasks/ProductList";
import OperationsSettings from "components/UserPortal/Operations/Settings/Settings";
import WarehouseMasterList from "components/UserPortal/Warehouse/MasterList/MasterList";
import WarehouseRMList from "components/UserPortal/Warehouse/RawMaterial/RawMaterial";
import WarehouseFGList from "components/UserPortal/Warehouse/FinishedGoods/FinishedGoods";
import RackUsage from "components/UserPortal/Warehouse/RackUsage/RackUsage";
// import FinishedGoodsIn from "components/UserPortal/FinishedGoods/In/In";
// import FinishedGoodsOut from "components/UserPortal/FinishedGoods/Out/Out";
import FinishedGoodsList from "components/UserPortal/FinishedGoods/MasterList/MasterList";
import DisposalList from "components/UserPortal/FinishedGoods/DisposalList/DisposalList";
// import DOCreation from "components/UserPortal/FinishedGoods/DOCreation/DOCreation";
import Assignment from "components/UserPortal/Delivery/Assignment/Assignment";
import DeliveryList from "components/UserPortal/Delivery/MasterList/MasterList";
import DeliveryOrder from "components/UserPortal/Delivery/DeliveryOrder/DeliveryOrder";
import DeliverySettings from "components/UserPortal/Delivery/Settings/Settings";
import ProductionMonitoring from "components/UserPortal/Monitoring/Production/Production";
import WorkOrder from "components/UserPortal/Monitoring/WorkOrder/WorkOrder";
import Equipment from "components/UserPortal/Monitoring/Equipment/Equipment";
// import FloorOps from "components/UserPortal/Monitoring/FloorOps/FloorOps";
import Storage from "components/UserPortal/Monitoring/Storage/Storage";
import MonitoringSettings from "components/UserPortal/Monitoring/Settings/Settings";
import DeviceControl from "components/UserPortal/Climate/DeviceControl/DeviceControl";
import ClimateMonitoring from "components/UserPortal/Climate/ClimateMonitoring/ClimateMonitoring";
import SensorControl from "components/UserPortal/Climate/SensorControl/SensorControl";
import SensorSettings from "components/UserPortal/Climate/SensorSettings/SensorSettings";
import LogSheet from "components/UserPortal/HumanResource/LogSheet/LogSheet";
import Availability from "components/UserPortal/HumanResource/Availability/Availability";
import LabourCost from "components/UserPortal/HumanResource/LabourCost/LabourCost";
import AdminLogSheet from "components/UserPortal/HumanResource/AdminLogSheet/AdminLogSheet";
import EquipmentReserve from "components/UserPortal/Equipment/Reserve/Reserve";
import EquipmentIssue from "components/UserPortal/Equipment/Issue/Issue";
import OverallAvailability from "components/UserPortal/Equipment/OverallAvailability/OverallAvailability";
import EquipmentCost from "components/UserPortal/Equipment/Cost/Cost";
import MaintenanceStatus from "components/UserPortal/Equipment/MaintenanceStatus/MaintenanceStatus";
import CostOverview from "components/UserPortal/CostProductivity/CostOverview/CostOverview";
import Efficiency from "components/UserPortal/CostProductivity/Efficiency/Efficiency";
import Harvest from "components/UserPortal/CostProductivity/Harvest/Harvest";
import CPReport from "components/UserPortal/CostProductivity/Report/Report";
import CPSettings from "components/UserPortal/CostProductivity/Settings/Settings";
import QualityControlGermination from "components/UserPortal/QualityControl/Germination/QualityControlGermination";
import QualityControlPhenotype from "components/UserPortal/QualityControl/Phenotype/QualityControlPhenotype";
import QualityControlVegetableGrowth from "components/UserPortal/QualityControl/VegetableGrowth/QualityControlVegetable";
import RDVegetable from "components/UserPortal/R&D/QualityControlVegetable";
import Report from "components/UserPortal/Report/Report";
import Traceability from "components/UserPortal/Traceability/Traceability";
import Alarm from "components/UserPortal/Alarm/Alarm";
import FarmAlarm from "components/UserPortal/Alarm/FarmSettings";
import GenerateQR from "components/UserPortal/CodeGenerator/GenerateQR";
import GenerateBarcode from "components/UserPortal/CodeGenerator/GenerateBarcode";
import Prediction from "components/UserPortal/Prediction/Prediction";
import SMOverview from "components/UserPortal/SmartMetering/Main/Overview";
import SMFOverview from "components/UserPortal/SmartMetering/Flagship/Overview";
import CurrentListings from "components/UserPortal/ASRS/CurrentListing/CurrentListings";
import ASRSMaintenance from "components/UserPortal/ASRS/Maintenance/Maintenance";
import ASRSMovement from "components/UserPortal/ASRS/Movement/Movement";
import ASRSSoftzones from "components/UserPortal/ASRS/Softzone/Softzones";
import ASRSStation from "components/UserPortal/ASRS/Station/Station";
import ASRSSettings from "components/UserPortal/ASRS/Settings/Settings";
import AMROverview from "components/UserPortal/AMR/Overview/Overview";
import AMRFleetManagement from "components/UserPortal/AMR/FleetManagement/FleetManagement";
import AMRTasks from "components/UserPortal/AMR/AdHoc/AdHoc";
import SustainabilityDashboard from "components/UserPortal/Sustainability/Dashboard/Dashboard";
import SustainabilityChart from "components/UserPortal/Sustainability/Chart/Chart";
import FlowControl from "components/UserPortal/FlowControl/Overview/FlowControl";
import FlowControlSettings from "components/UserPortal/FlowControl/Settings/Settings";
// core components/views for create/edit
import SearchCustomerOrderResult from "components/UserPortal/CustomerOrder/Search/SearchResult";
// import AddEditOrder from "components/UserPortal/CustomerOrder/Management/AddEditOrder";
import CODetails from "components/UserPortal/CustomerOrder/Management/Details";
import COCapacityDetails from "components/UserPortal/CustomerOrder/Capacity/Details";
import SearchPlanningResult from "components/UserPortal/Planning/Search/SearchResult";
import PlanningDetail from "components/UserPortal/Planning/Report/Detail";
import SearchRawMaterialResult from "components/UserPortal/RawMaterial/Search/SearchResult";
import SearchRMBatchResult from "components/UserPortal/RawMaterial/Search/SearchRMBatchResult";
import BatchList from "components/UserPortal/RawMaterial/Management/BatchList";
import AddEditBatch from "components/UserPortal/RawMaterial/Management/AddEditBatch";
import AddEditRMReserve from "components/UserPortal/RawMaterial/Reserve/AddEditReserve";
import AddEditRequest from "components/UserPortal/RawMaterial/Request/AddEditRequest";
import ViewEditReceipt from "components/UserPortal/RawMaterial/Receipt/ViewEditReceipt";
import AddReconciliation from "components/UserPortal/RawMaterial/Reconciliation/AddReconciliation";
import SearchWarehouseResult from "components/UserPortal/Warehouse/Search/SearchResult";
import OperationsProgress from "components/UserPortal/Operations/Overview/Progress";
import OperationsTasks from "components/UserPortal/Operations/Tasks/Tasks";
import TaskDetail from "components/UserPortal/Operations/Tasks/TaskDetail";
import TaskAssignmentDetail from "components/UserPortal/Operations/Tasks/TaskAssignment";
import CageDetails from "components/UserPortal/Operations/Overview/CageDetails";
import SearchFinishedGoodsResult from "components/UserPortal/FinishedGoods/Search/SearchResult";
// import AddOrders from "components/UserPortal/FinishedGoods/In/AddOrders";
// import AddFinishedGoods from "components/UserPortal/FinishedGoods/In/AddFinishedGoods";
// import DOCreationDetail from "components/UserPortal/FinishedGoods/DOCreation/Detail";
import SearchDeliveryResult from "components/UserPortal/Delivery/Search/SearchResult";
import DeliveryMapView from "components/UserPortal/Delivery/MasterList/MapView";
import DeliveryDetails from "components/UserPortal/Delivery/MasterList/Details";
import DeliveryOrderDetails from "components/UserPortal/Delivery/DeliveryOrder/Details";
import DeliveryOrderInvoice from "components/UserPortal/Delivery/DeliveryOrder/Invoice";
import MonitoringEquipmentDetail from "components/UserPortal/Monitoring/Equipment/EquipmentDetail";
import AddEditMonitoringSetting from "components/UserPortal/Monitoring/Settings/AddEditSettings";
import SearchClimateResult from "components/UserPortal/Climate/Search/SearchResult";
import ConfigureForm from "components/UserPortal/Climate/DeviceControl/ConfigureForm";
import AddEditSensorSetting from "components/UserPortal/Climate/SensorSettings/AddEditSensorSetting";
import SearchHumanResourceResult from "components/UserPortal/HumanResource/Search/SearchResult";
import SearchEquipmentResult from "components/UserPortal/Equipment/Search/SearchResult";
import AddEditReserve from "components/UserPortal/Equipment/Reserve/AddEditReserve";
import EditMaintenance from "components/UserPortal/Equipment/MaintenanceStatus/EditMaintenance";
import SearchQualityControlResult from "components/UserPortal/QualityControl/Search/SearchResult";
import AddEditQualityControlGermination from "components/UserPortal/QualityControl/Germination/AddEditQualityControlGermination";
import QualityControlGerminationReport from "components/UserPortal/QualityControl/Germination/GerminationReport";
import AddEditQualityControlPhenotype from "components/UserPortal/QualityControl/Phenotype/AddEditQualityControlPhenotype";
import QualityControlPhenotypeReport from "components/UserPortal/QualityControl/Phenotype/PhenotypeReport";
import AddEditQualityControlVegetableGrowth from "components/UserPortal/QualityControl/VegetableGrowth/AddEditQualityControlVegetable";
import QualityControlVegetableGrowthReport from "components/UserPortal/QualityControl/VegetableGrowth/Report";
import RDVegetableReport from "components/UserPortal/R&D/Report";
import AddEditRDVegetable from "components/UserPortal/R&D/AddEditQualityControlVegetable";
import ReportDashboard from "components/UserPortal/Report/Dashboard";
import ReportConfigurations from "components/UserPortal/Report/Configurations";
import AddEditReportConfigurations from "components/UserPortal/Report/AddEditConfigurations";
import ConfigurationReport from "components/UserPortal/Report/ConfigurationReport";
import AlarmTab from "components/UserPortal/Alarm/AlarmTab";
import Notification from "components/UserPortal/Alarm/Notification";
import AlarmSettings from "components/UserPortal/Alarm/Settings";
import AllAlarms from "components/UserPortal/Alarm/AllAlarms";
import AddCurrentListings from "components/UserPortal/ASRS/CurrentListing/AddCurrentListings";
import BatchCurrentListings from "components/UserPortal/ASRS/CurrentListing/Onboarding";
import AddEditMaintenance from "components/UserPortal/ASRS/Maintenance/AddEditMaintenance";
import MaintenanceDetails from "components/UserPortal/ASRS/Maintenance/MaintenanceDetails";
import AddEditMovement from "components/UserPortal/ASRS/Movement/AddEditMovement";
import MovementDetails from "components/UserPortal/ASRS/Movement/MovementDetails";
import EditFutureTask from "components/UserPortal/AMR/AdHoc/EditFutureTask";
import AddEditFlowControlDevice from "components/UserPortal/FlowControl/Overview/AddEditFlowControlDevice";

export const customerOrderRoutes = [
  // {
  //   path: "/sales-order/management/create",
  //   permissionName: "Customer Order",
  //   component: AddEditOrder,
  //   layout: "/user",
  // },
  {
    path: "/sales-order/management/:id",
    permissionName: "Customer Order",
    readOnly: true,
    component: CODetails,
    layout: "/user",
  },
  {
    path: "/sales-order/capacity/detail",
    permissionName: "Customer Order",
    component: COCapacityDetails,
    layout: "/user",
  },
  {
    path: "/sales-order/search",
    permissionName: "Customer Order",
    component: SearchCustomerOrderResult,
    readOnly: true,
    layout: "/user",
  },
];

export const planningRoutes = [
  {
    path: "/planning/report/detail",
    permissionName: "Planning",
    component: PlanningDetail,
    layout: "/user",
  },
  {
    path: "/planning/search",
    permissionName: "Planning",
    component: SearchPlanningResult,
    readOnly: true,
    layout: "/user",
  },
];

export const rawMaterialRoutes = [
  {
    path: "/raw-material/management/:id/search",
    permissionName: "Raw Materials",
    readOnly: true,
    component: SearchRMBatchResult,
    layout: "/user",
  },
  {
    path: "/raw-material/management/:id",
    permissionName: "Raw Materials",
    readOnly: true,
    component: BatchList,
    layout: "/user",
  },
  {
    path: "/raw-material/management/batch/create",
    permissionName: "Raw Materials",
    component: AddEditBatch,
    layout: "/user",
  },
  {
    path: "/raw-material/management/batch/:id",
    permissionName: "Raw Materials",
    component: AddEditBatch,
    layout: "/user",
  },
  {
    path: "/raw-material/reserve/create",
    permissionName: "Raw Materials",
    component: AddEditRMReserve,
    layout: "/user",
  },
  {
    path: "/raw-material/reserve/:id",
    permissionName: "Raw Materials",
    component: AddEditRMReserve,
    layout: "/user",
  },
  {
    path: "/raw-material/receipt/create",
    permissionName: "Raw Materials",
    component: AddEditRequest,
    layout: "/user",
  },
  {
    path: "/raw-material/receipt/:id",
    permissionName: "Raw Materials",
    component: ViewEditReceipt,
    layout: "/user",
  },
  {
    path: "/raw-material/reconciliation/create",
    permissionName: "Raw Materials",
    component: AddReconciliation,
    layout: "/user",
  },
  {
    path: "/raw-material/search",
    permissionName: "Raw Materials",
    component: SearchRawMaterialResult,
    readOnly: true,
    layout: "/user",
  },
];

export const operationRoutes = [
  {
    path: "/operations/process-overview/:id",
    permissionName: "Operations",
    component: OperationsProgress,
    readOnly: true,
    layout: "/user",
  },
  {
    path: "/operations/cage-details/:id",
    permissionName: "Operations",
    component: CageDetails,
    readOnly: true,
    layout: "/user",
  },
  {
    path: "/operations/overall-tasks/:id/:id",
    permissionName: "Operations",
    component: TaskDetail,
    readOnly: true,
    layout: "/user",
  },
  {
    path: "/operations/overall-tasks/:id",
    permissionName: "Operations",
    component: OperationsTasks,
    readOnly: true,
    layout: "/user",
  },
  {
    path: "/operations/task-assignment/:id/:id",
    permissionName: "Task Assignment",
    component: TaskAssignmentDetail,
    readOnly: true,
    layout: "/user",
  },
  {
    path: "/operations/task-assignment/:id",
    permissionName: "Task Assignment",
    component: OperationsTasks,
    readOnly: true,
    layout: "/user",
  },
];

export const warehouseRoutes = [
  {
    path: "/warehouse/search",
    permissionName: "Warehouse",
    component: SearchWarehouseResult,
    readOnly: true,
    layout: "/user",
  },
];

export const finishedGoodsRoutes = [
  // {
  //   path: "/finished-goods/in/orders",
  //   permissionName: "Finished Goods",
  //   component: AddOrders,
  //   layout: "/user",
  // },
  // {
  //   path: "/finished-goods/in/create",
  //   permissionName: "Finished Goods",
  //   component: AddFinishedGoods,
  //   layout: "/user",
  // },
  // {
  //   path: "/finished-goods/delivery-order-creation/:id",
  //   permissionName: "Finished Goods",
  //   component: DOCreationDetail,
  //   layout: "/user",
  // },
  {
    path: "/finished-goods/search",
    permissionName: "Finished Goods",
    component: SearchFinishedGoodsResult,
    readOnly: true,
    layout: "/user",
  },
];

export const deliveryRoutes = [
  {
    path: "/delivery/delivery-order/details/:id",
    permissionName: "Delivery",
    component: DeliveryOrderDetails,
    layout: "/user",
  },
  {
    path: "/delivery/delivery-order/invoice/:id",
    permissionName: "Delivery",
    component: DeliveryOrderInvoice,
    layout: "/user",
  },
  {
    path: "/delivery/master-list/map",
    permissionName: "Delivery",
    component: DeliveryMapView,
    layout: "/user",
  },
  {
    path: "/delivery/master-list/:id",
    permissionName: "Delivery",
    component: DeliveryDetails,
    layout: "/user",
  },
  {
    path: "/delivery/search",
    permissionName: "Delivery",
    component: SearchDeliveryResult,
    readOnly: true,
    layout: "/user",
  },
];

export const monitoringRoutes = [
  {
    path: "/monitoring/equipment/:id",
    permissionName: "Monitoring",
    component: MonitoringEquipmentDetail,
    layout: "/user",
  },
  {
    path: "/monitoring/settings/create",
    permissionName: "Monitoring",
    component: AddEditMonitoringSetting,
    layout: "/user",
  },
  {
    path: "/monitoring/settings/:id",
    permissionName: "Monitoring",
    component: AddEditMonitoringSetting,
    layout: "/user",
  },
];

export const climateRoutes = [
  {
    path: "/climate/device-control/create",
    permissionName: "Climate/Environment",
    component: ConfigureForm,
    layout: "/user",
  },
  {
    path: "/climate/device-control/:id",
    permissionName: "Climate/Environment",
    component: ConfigureForm,
    layout: "/user",
  },
  {
    path: "/climate/sensor-settings/create",
    permissionName: "Climate/Environment",
    component: AddEditSensorSetting,
    layout: "/user",
  },
  {
    path: "/climate/sensor-settings/:id",
    permissionName: "Climate/Environment",
    component: AddEditSensorSetting,
    layout: "/user",
  },
  {
    path: "/climate/search",
    permissionName: "Climate/Environment",
    component: SearchClimateResult,
    layout: "/user",
  },
];

export const hrRoutes = [
  {
    path: "/human-resource/search",
    permissionName: "Human Resource",
    component: SearchHumanResourceResult,
    readOnly: true,
    layout: "/user",
  },
];

export const equipmentRoutes = [
  {
    path: "/equipment/reserve/create",
    permissionName: "Equipment",
    component: AddEditReserve,
    layout: "/user",
  },
  {
    path: "/equipment/reserve/:id",
    permissionName: "Equipment",
    component: AddEditReserve,
    layout: "/user",
  },
  {
    path: "/equipment/maintenance-status/:id",
    permissionName: "Equipment",
    component: EditMaintenance,
    layout: "/user",
  },
  {
    path: "/equipment/search",
    permissionName: "Equipment",
    component: SearchEquipmentResult,
    readOnly: true,
    layout: "/user",
  },
];

export const qualityControlRoutes = [
  {
    path: "/quality-control/germination/create",
    permissionName: "Quality Control",
    component: AddEditQualityControlGermination,
    layout: "/user",
  },
  {
    path: "/quality-control/germination/report/:id",
    permissionName: "Quality Control",
    component: QualityControlGerminationReport,
    layout: "/user",
  },
  {
    path: "/quality-control/germination/:id",
    permissionName: "Quality Control",
    component: AddEditQualityControlGermination,
    layout: "/user",
  },
  {
    path: "/quality-control/phenotype/create",
    permissionName: "Quality Control",
    component: AddEditQualityControlPhenotype,
    layout: "/user",
  },
  {
    path: "/quality-control/phenotype/report/:id",
    permissionName: "Quality Control",
    component: QualityControlPhenotypeReport,
    layout: "/user",
  },
  {
    path: "/quality-control/phenotype/:id",
    permissionName: "Quality Control",
    component: AddEditQualityControlPhenotype,
    layout: "/user",
  },
  {
    path: "/quality-control/vegetable-growth/create",
    permissionName: "QCGrowthReport",
    component: AddEditQualityControlVegetableGrowth,
    layout: "/user",
  },
  {
    path: "/quality-control/vegetable-growth/report/:id",
    permissionName: "QCGrowthReport",
    component: QualityControlVegetableGrowthReport,
    layout: "/user",
  },
  {
    path: "/quality-control/vegetable-growth/:id",
    permissionName: "QCGrowthReport",
    component: AddEditQualityControlVegetableGrowth,
    layout: "/user",
  },
  {
    path: "/quality-control/search",
    permissionName: "Quality Control",
    component: SearchQualityControlResult,
    readOnly: true,
    layout: "/user",
  },
];

export const rdRoutes = [
  // {
  //   path: "/r&d/create",
  //   // permissionName: "Quality Control Report",
  //   component: AddEditRDVegetable,
  //   layout: "/user",
  // },
  {
    path: "/r&d/report/:id",
    permissionName: "RandDModule",
    component: RDVegetableReport,
    layout: "/user",
  },
  {
    path: "/r&d/:id",
    permissionName: "RandDModule",
    component: AddEditRDVegetable,
    layout: "/user",
  },
];

export const reportRoutes = [
  {
    path: "/report/dashboard/:id/configurations/report/:id",
    permissionName: "Report",
    component: ConfigurationReport,
    canCreate: true,
    layout: "/user",
  },
  {
    path: "/report/dashboard/:id/configurations/:id",
    permissionName: "Report",
    component: AddEditReportConfigurations,
    canCreate: true,
    layout: "/user",
  },
  {
    path: "/report/dashboard/:id/configurations/create",
    permissionName: "Report",
    component: AddEditReportConfigurations,
    canCreate: true,
    layout: "/user",
  },
  {
    path: "/report/dashboard/:id/configurations",
    permissionName: "Report",
    component: ReportConfigurations,
    canCreate: true,
    layout: "/user",
  },
  {
    path: "/report/dashboard/:id",
    permissionName: "Report",
    component: ReportDashboard,
    layout: "/user",
  },
];

export const alarmRoutes = [
  {
    path: "/alarm/details/operations",
    permissionName: "Operations",
    component: AlarmTab,
    layout: "/user",
  },
  {
    path: "/alarm/details/finished-goods",
    permissionName: "Finished Goods",
    component: Notification,
    layout: "/user",
  },
  {
    path: "/alarm/details/planning",
    permissionName: "Planning",
    component: Notification,
    layout: "/user",
  },
  {
    path: "/alarm/details/equipment",
    permissionName: "Equipment",
    component: AlarmTab,
    layout: "/user",
  },
  {
    path: "/alarm/details/raw-material",
    permissionName: "Raw Materials",
    component: AlarmTab,
    layout: "/user",
  },
  {
    path: "/alarm/details/delivery",
    permissionName: "Delivery",
    component: Notification,
    layout: "/user",
  },
  {
    path: "/alarm/details/climate",
    permissionName: "Climate/Environment",
    component: AlarmTab,
    layout: "/user",
  },
  {
    path: "/alarm/details/settings",
    component: AlarmSettings,
    layout: "/user",
  },
  {
    path: "/alarm/all",
    component: AllAlarms,
    layout: "/user",
  },
];

export const ASRSRoutes = [
  {
    path: "/vifs/current-listings/create",
    //permissionName: "Monitoring",
    component: AddCurrentListings,
    layout: "/user",
  },
  {
    path: "/vifs/current-listings/batch",
    //permissionName: "Monitoring",
    component: BatchCurrentListings,
    layout: "/user",
  },
  {
    path: "/vifs/maintenance/create",
    //permissionName: "Monitoring",
    component: AddEditMaintenance,
    layout: "/user",
  },
  {
    path: "/vifs/maintenance/:id/details",
    //permissionName: "Monitoring",
    component: MaintenanceDetails,
    layout: "/user",
  },
  {
    path: "/vifs/maintenance/:id",
    //permissionName: "Monitoring",
    component: AddEditMaintenance,
    layout: "/user",
  },
  {
    path: "/vifs/movement/create",
    //permissionName: "Monitoring",
    component: AddEditMovement,
    layout: "/user",
  },
  {
    path: "/vifs/movement/:id/details",
    //permissionName: "Monitoring",
    component: MovementDetails,
    layout: "/user",
  },
  {
    path: "/vifs/movement/:id",
    //permissionName: "Monitoring",
    component: AddEditMovement,
    layout: "/user",
  },
];

export const AMRRoutes = [
  {
    path: "/amr/ad-hoc-tasks/:id",
    //permissionName: "Monitoring",
    component: EditFutureTask,
    layout: "/user",
  },
];

export const flowControlRoutes= [
  {
    path: "/flow-control/overview/create",
    component: AddEditFlowControlDevice,
    layout: "/user",
  },
]

export const customerOrderSubRoutes = [
  {
    path: "/sales-order/management",
    name: "Management",
    permissionName: "Customer Order",
    subheader: "Management",
    // canCreate: true,
    component: COManagement,
    layout: "/user",
  },
  {
    path: "/sales-order/capacity",
    name: "Capacity",
    permissionName: "Customer Order",
    subheader: "Capacity",
    component: COCapacity,
    layout: "/user",
  },
];

export const planningSubRoutes = [
  {
    path: "/planning/planning-outcome",
    name: "Planning Outcome",
    permissionName: "Planning",
    subheader: "Planning Outcome",
    canCreate: true,
    component: PlanningOutcome,
    layout: "/user",
  },
  {
    path: "/planning/conversion",
    name: "Conversion",
    permissionName: "Planning",
    subheader: "Conversion",
    component: Conversion,
    layout: "/user",
  },
  {
    path: "/planning/adjustment",
    name: "Adjustment",
    permissionName: "Planning",
    subheader: "Adjustment",
    component: Adjustment,
    layout: "/user",
  },
  {
    path: "/planning/unassigned-orders",
    name: "Unassigned Orders",
    permissionName: "Planning",
    subheader: "Unassigned Orders",
    component: UnassignedOrders,
    layout: "/user",
  },
  {
    path: "/planning/maximum-orders-per-day",
    name: "Maximum Orders Per Day",
    permissionName: "Planning",
    subheader: "Maximum Orders Per Day",
    component: MaximumOrders,
    layout: "/user",
  },
  {
    path: "/planning/report",
    name: "Report",
    permissionName: "Planning",
    subheader: "Report",
    component: PlanningReport,
    layout: "/user",
  },
  {
    path: "/planning/settings",
    name: "Settings",
    permissionName: "Planning",
    subheader: "Settings",
    component: PlanningSettings,
    layout: "/user",
  },
];

export const rawMaterialSubRoutes = [
  {
    path: "/raw-material/management",
    name: "Management",
    permissionName: "Raw Materials",
    subheader: "Management",
    component: RMManagement,
    layout: "/user",
  },
  {
    path: "/raw-material/reserve",
    name: "Reserve",
    permissionName: "Raw Materials",
    subheader: "Reserve",
    canCreate: true,
    component: RMReserve,
    layout: "/user",
  },
  {
    path: "/raw-material/issue",
    name: "Issue",
    permissionName: "Raw Materials",
    subheader: "Issue",
    component: RMIssue,
    layout: "/user",
  },
  {
    path: "/raw-material/return",
    name: "Return",
    permissionName: "Raw Materials",
    subheader: "Return",
    component: RMReturn,
    layout: "/user",
  },
  {
    path: "/raw-material/receipt",
    name: "Request/Receipt",
    permissionName: "Raw Materials",
    subheader: "Request/Receipt",
    canCreate: true,
    component: RMReceipt,
    layout: "/user",
  },
  {
    path: "/raw-material/cost",
    name: "Cost",
    permissionName: "Raw Materials",
    subheader: "Cost",
    component: RMCost,
    layout: "/user",
  },
  {
    path: "/raw-material/reconciliation",
    name: "Reconciliation",
    permissionName: "Raw Materials",
    subheader: "Reconciliation",
    canCreate: true,
    component: RMReconciliation,
    layout: "/user",
  },
];

export const operationSubRoutes = [
  {
    path: "/operations/process-overview",
    name: "Process Overview",
    permissionName: "Operations",
    subheader: "Process Overview",
    component: OperationsOverview,
    layout: "/user",
  },
  {
    path: "/operations/overall-tasks",
    name: "Overall Tasks",
    permissionName: "Operations",
    subheader: "Overall Tasks",
    component: OverallTask,
    layout: "/user",
  },
  {
    path: "/operations/task-assignment",
    name: "Task Assignment",
    permissionName: "Task Assignment",
    subheader: "Task Assignment",
    component: TaskAssignment,
    layout: "/user",
  },
  {
    path: "/operations/settings",
    name: "Settings",
    permissionName: "Operations",
    subheader: "Settings",
    component: OperationsSettings,
    layout: "/user",
  },
];

export const warehouseSubRoutes = [
  {
    path: "/warehouse/master-list",
    name: "Master List",
    permissionName: "Warehouse",
    subheader: "Master List",
    canCreate: true,
    component: WarehouseMasterList,
    layout: "/user",
  },
  {
    path: "/warehouse/raw-material",
    name: "Raw Materials",
    permissionName: "Warehouse",
    subheader: "Raw Materials",
    component: WarehouseRMList,
    layout: "/user",
  },
  {
    path: "/warehouse/finished-goods",
    name: "Finished Goods",
    permissionName: "Warehouse",
    subheader: "Finished Goods",
    component: WarehouseFGList,
    layout: "/user",
  },
  {
    path: "/warehouse/rack-usage",
    name: "Rack Usage",
    permissionName: "Warehouse",
    subheader: "Rack Usage",
    component: RackUsage,
    layout: "/user",
  },
];

export const finishedGoodsSubRoutes = [
  // {
  //   path: "/finished-goods/in",
  //   name: "In",
  //   permissionName: "Finished Goods",
  //   subheader: "In",
  //   canCreate: true,
  //   component: FinishedGoodsIn,
  //   layout: "/user",
  // },
  // {
  //   path: "/finished-goods/out",
  //   name: "Out",
  //   permissionName: "Finished Goods",
  //   subheader: "Out",
  //   component: FinishedGoodsOut,
  //   layout: "/user",
  // },
  {
    path: "/finished-goods/master-list",
    name: "Master List",
    permissionName: "Finished Goods",
    subheader: "Master List",
    component: FinishedGoodsList,
    layout: "/user",
  },
  {
    path: "/finished-goods/disposal-list",
    name: "Disposal List",
    permissionName: "Finished Goods",
    subheader: "Disposal List",
    component: DisposalList,
    layout: "/user",
  },
  // {
  //   path: "/finished-goods/delivery-order-creation",
  //   name: "DO Creation",
  //   permissionName: "Finished Goods",
  //   subheader: "Delivery Order Creation",
  //   component: DOCreation,
  //   layout: "/user",
  // },
];

export const deliverySubRoutes = [
  {
    path: "/delivery/assignment",
    name: "Assignment",
    permissionName: "Assignment",
    subheader: "Vehicle & Driver Assignment",
    canCreate: true,
    component: Assignment,
    layout: "/user",
  },
  {
    path: "/delivery/master-list",
    name: "Master List",
    permissionName: "Delivery",
    subheader: "Master List",
    component: DeliveryList,
    layout: "/user",
  },
  {
    path: "/delivery/delivery-order",
    name: "Delivery Order",
    permissionName: "Delivery",
    subheader: "Delivery Order",
    component: DeliveryOrder,
    layout: "/user",
  },
  {
    path: "/delivery/settings",
    name: "Settings",
    permissionName: "Delivery",
    subheader: "Settings",
    component: DeliverySettings,
    layout: "/user",
  },
];

export const monitoringSubRoutes = [
  {
    path: "/monitoring/production",
    name: "Production",
    permissionName: "Monitoring",
    subheader: "Production",
    component: ProductionMonitoring,
    layout: "/user",
  },
  {
    path: "/monitoring/work-order",
    name: "Work Order",
    permissionName: "Monitoring",
    subheader: "Work Order",
    component: WorkOrder,
    layout: "/user",
  },
  {
    path: "/monitoring/equipment",
    name: "Equipment",
    permissionName: "Monitoring",
    subheader: "Equipment",
    component: Equipment,
    layout: "/user",
  },
  // {
  //   path: "/monitoring/floor-ops",
  //   name: "Floor Ops",
  //   permissionName: "Monitoring",
  //   subheader: "Floor Ops",
  //   component: FloorOps,
  //   layout: "/user",
  // },
  {
    path: "/monitoring/storage",
    name: "Storage",
    permissionName: "Monitoring",
    subheader: "Storage",
    component: Storage,
    layout: "/user",
  },
  {
    path: "/monitoring/settings",
    name: "Settings",
    permissionName: "Monitoring",
    subheader: "Settings",
    canCreate: true,
    component: MonitoringSettings,
    layout: "/user",
  },
];

export const climateSubRoutes = [
  {
    path: "/climate/sensor-control",
    name: "Sensors & Control",
    permissionName: "Climate/Environment",
    subheader: "Sensors & Control",
    component: SensorControl,
    layout: "/user",
  },
  {
    path: "/climate/device-control",
    name: "Device Control",
    permissionName: "Climate/Environment",
    subheader: "Device Control",
    component: DeviceControl,
    layout: "/user",
  },
  {
    path: "/climate/climate-monitoring",
    name: "Climate Monitoring",
    permissionName: "Climate/Environment",
    subheader: "Climate Monitoring",
    component: ClimateMonitoring,
    layout: "/user",
  },
  {
    path: "/climate/sensor-settings",
    name: "Sensor Settings",
    permissionName: "Climate/Environment",
    subheader: "Sensor Setting",
    canCreate: true,
    component: SensorSettings,
    layout: "/user",
  },
];

export const hrSubRoutes = [
  {
    path: "/human-resource/log-sheet",
    name: "Log Sheet",
    permissionName: "Human Resource",
    subheader: "Log Sheet",
    component: LogSheet,
    layout: "/user",
  },
  {
    path: "/human-resource/hr-availability",
    name: "HR Availability",
    permissionName: "Human Resource",
    subheader: "HR Availability",
    component: Availability,
    layout: "/user",
  },
  {
    path: "/human-resource/labour-cost",
    name: "Labour Cost",
    permissionName: "Human Resource",
    subheader: "Labour Cost",
    component: LabourCost,
    layout: "/user",
  },
  {
    path: "/human-resource/log-sheet-admin",
    name: "Admin Log Sheet",
    permissionName: "Log Sheet Admin",
    subheader: "Admin Log Sheet",
    component: AdminLogSheet,
    layout: "/user",
  },
];

export const equipmentSubRoutes = [
  {
    path: "/equipment/reserve",
    name: "Reserve",
    permissionName: "Equipment",
    subheader: "Reserve",
    canCreate: true,
    component: EquipmentReserve,
    layout: "/user",
  },
  {
    path: "/equipment/issue",
    name: "Issue",
    permissionName: "Equipment",
    subheader: "Issue",
    component: EquipmentIssue,
    layout: "/user",
  },
  {
    path: "/equipment/overall-availability",
    name: "Overall Availability",
    permissionName: "Equipment",
    subheader: "Overall Availability",
    component: OverallAvailability,
    layout: "/user",
  },
  {
    path: "/equipment/equipment-cost",
    name: "Cost",
    permissionName: "Equipment",
    subheader: "Cost",
    component: EquipmentCost,
    layout: "/user",
  },
  {
    path: "/equipment/maintenance-status",
    name: "Maintenance Status",
    permissionName: "Equipment",
    subheader: "Maintenance Status",
    component: MaintenanceStatus,
    layout: "/user",
  },
];

export const costProductivitySubRoutes = [
  {
    path: "/cost-and-productivity/cost-overview",
    name: "Cost Overview",
    permissionName: "Cost Report",
    subheader: "Cost Overview",
    canCreate: true,
    component: CostOverview,
    layout: "/user",
  },
  {
    path: "/cost-and-productivity/efficiency",
    name: "Efficiency",
    permissionName: "Cost Report",
    subheader: "Efficiency",
    component: Efficiency,
    layout: "/user",
  },
  {
    path: "/cost-and-productivity/harvest",
    name: "Harvest",
    permissionName: "Cost Report",
    subheader: "Harvest",
    component: Harvest,
    layout: "/user",
  },
  {
    path: "/cost-and-productivity/report",
    name: "Report",
    permissionName: "Cost Report",
    subheader: "Report",
    component: CPReport,
    layout: "/user",
  },
  {
    path: "/cost-and-productivity/settings",
    name: "Settings",
    permissionName: "Cost Report",
    subheader: "Settings",
    component: CPSettings,
    layout: "/user",
  },
];

export const qualityControlSubRoutes = [
  {
    path: "/quality-control/germination",
    name: "Germination",
    permissionName: "Quality Control",
    subheader: "Germination",
    canCreate: true,
    component: QualityControlGermination,
    layout: "/user",
  },
  {
    path: "/quality-control/phenotype",
    name: "Phenotype",
    permissionName: "Quality Control",
    subheader: "Phenotype",
    canCreate: true,
    component: QualityControlPhenotype,
    layout: "/user",
  },
  {
    path: "/quality-control/vegetable-growth",
    name: "Vegetable Growth",
    permissionName: "QCGrowthReport",
    subheader: "Vegetable Growth",
    canCreate: true,
    component: QualityControlVegetableGrowth,
    layout: "/user",
  },
];

export const alarmSubRoutes = [
  {
    path: "/alarm/details",
    name: "Alarm Details",
    subheader: "Alarm Details",
    canCreate: true,
    component: Alarm,
    layout: "/user",
  },
  {
    path: "/alarm/farm-settings",
    name: "Farm Alarm Settings",
    permissionName: "Farm Alarm Settings",
    subheader: "Farm Alarm Settings",
    component: FarmAlarm,
    layout: "/user",
  },
];

export const codeGeneratorSubRoutes = [
  {
    path: "/code-generator/qr",
    name: "QR Code",
    permissionName: "CodeGenerator",
    subheader: "QR Code Generator",
    component: GenerateQR,
    layout: "/user",
  },
  {
    path: "/code-generator/barcode",
    name: "Barcode",
    permissionName: "CodeGenerator",
    subheader: "Barcode Generator",
    component: GenerateBarcode,
    layout: "/user",
  },
];

export const smartMeteringSubRoutes = [
  {
    path: "/smart-energy/main",
    name: "Main",
    // permissionName: "CodeGenerator",
    subheader: "Smart Metering",
    component: SMOverview,
    layout: "/user",
  },
  {
    path: "/smart-energy/flagship",
    name: "Flagship",
    // permissionName: "CodeGenerator",
    subheader: "Smart Metering Flagship",
    component: SMFOverview,
    layout: "/user",
  },
];

export const ASRSSubRoutes = [
  {
    path: "/vifs/current-listings",
    name: "Current Listings",
    subheader: "Current Listings",
    canCreate: true,
    component: CurrentListings,
    layout: "/user",
  },
  {
    path: "/vifs/maintenance",
    name: "Maintenance",
    canCreate: true,
    subheader: "Maintenance",
    component: ASRSMaintenance,
    layout: "/user",
  },
  {
    path: "/vifs/movement",
    name: "Movement",
    canCreate: true,
    subheader: "Movement",
    component: ASRSMovement,
    layout: "/user",
  },
  {
    path: "/vifs/softzones",
    name: "Softzones",
    subheader: "Softzones",
    component: ASRSSoftzones,
    layout: "/user",
  },
  {
    path: "/vifs/station",
    name: "Station",
    subheader: "Station",
    component: ASRSStation,
    layout: "/user",
  },
  {
    path: "/vifs/settings",
    name: "Settings",
    subheader: "Settings",
    component: ASRSSettings,
    layout: "/user",
  },
];

export const AMRSubRoutes = [
  {
    path: "/amr/overview",
    name: "Overview",
    subheader: "Overview",
    canCreate: true,
    component: AMROverview,
    layout: "/user",
  },
  {
    path: "/amr/fleet-management",
    name: "Fleet Management",
    canCreate: true,
    subheader: "Fleet Management",
    component: AMRFleetManagement,
    layout: "/user",
  },
  {
    path: "/amr/ad-hoc-tasks",
    name: "Ad Hoc Tasks",
    canCreate: true,
    subheader: "Ad Hoc Tasks",
    component: AMRTasks,
    layout: "/user",
  },
];

export const flowControlSubRoutes = [
  {
    path: "/flow-control/overview",
    name: "Overview",
    subheader: "Overview",
    canCreate: true,
    component: FlowControl,
    layout: "/user",
  },
  {
    path: "/flow-control/settings",
    name: "Settings",
    subheader: "Settings",
    component: FlowControlSettings,
    layout: "/user",
  },
];

export const SustainabilitySubRoutes = [
  {
    path: "/sustainability/dashboard",
    name: "Dashboard",
    subheader: "Dashboard",
    component: SustainabilityDashboard,
    layout: "/user",
  },
  {
    path: "/sustainability/Chart",
    name: "Chart",
    subheader: "Chart",
    component: SustainabilityChart,
    layout: "/user",
  },
];

export const dashboardRoutes = [
  {
    path: "/home",
    name: "Home User",
    header: "FOMS User Portal",
    icon: {white: w_home, grey: g_home},
    component: HomePage,
    layout: "/user",
  },
  {
    path: "/sales-order",
    name: "Sales Order",
    permissionName: "Customer Order",
    header: "Sales Order",
    searchPlaceholder: "Search within Sales Order Module",
    icon: {white: w_customerOrder, grey: g_customerOrder},
    layout: "/user",
    subMenu: customerOrderSubRoutes
  },
  {
    path: "/planning",
    name: "Planning",
    permissionName: "Planning",
    header: "Planning",
    searchPlaceholder: "Search within Planning Module",
    icon: {white: w_planning, grey: g_planning},
    layout: "/user",
    subMenu: planningSubRoutes
  },
  {
    path: "/raw-material",
    name: "Raw Materials",
    permissionName: "Raw Materials",
    header: "Raw Materials",
    searchPlaceholder: "Search within Raw Materials Module",
    icon: {white: w_rawMaterial, grey: g_rawMaterial},
    layout: "/user",
    subMenu: rawMaterialSubRoutes
  },
  {
    path: "/operations",
    name: "Operations",
    permissionName: "Operations",
    header: "Operations",
    // searchPlaceholder: "Search within Operations Module",
    icon: {white: w_operations, grey: g_operations},
    layout: "/user",
    subMenu: operationSubRoutes
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    permissionName: "Warehouse",
    header: "Warehouse",
    searchPlaceholder: "Search within Warehouse Module",
    icon: {white: w_warehouse, grey: g_warehouse},
    layout: "/user",
    subMenu: warehouseSubRoutes
  },
  {
    path: "/finished-goods",
    name: "Finished Goods",
    permissionName: "Finished Goods",
    header: "Finished Goods",
    searchPlaceholder: "Search within Finished Goods Module",
    icon: {white: w_finishedGoods, grey: g_finishedGoods},
    layout: "/user",
    subMenu: finishedGoodsSubRoutes
  },
  {
    path: "/delivery",
    name: "Delivery",
    permissionName: "Delivery",
    header: "Delivery",
    searchPlaceholder: "Search within Delivery Module",
    icon: {white: w_delivery, grey: g_delivery},
    layout: "/user",
    subMenu: deliverySubRoutes
  },
  {
    path: "/monitoring",
    name: "Monitoring",
    permissionName: "Monitoring",
    header: "Monitoring",
    // searchPlaceholder: "Search within Monitoring Module",
    icon: {white: w_monitoring, grey: g_monitoring},
    layout: "/user",
    subMenu: monitoringSubRoutes
  },
  {
    path: "/capacity",
    name: "Capacity",
    permissionName: "Planning",
    header: "Capacity Management",
    // searchPlaceholder: "Search within Capacity Module",
    icon: {white: w_capacity, grey: g_capacity},
    component: Capacity,
    layout: "/user",
  },
  {
    path: "/climate",
    name: "Climate",
    permissionName: "Climate/Environment",
    header: "Climate",
    searchPlaceholder: "Search within Climate Module",
    icon: {white: w_climate, grey: g_climate},
    layout: "/user",
    subMenu: climateSubRoutes
  },
  {
    path: "/human-resource",
    name: "Human Resource",
    permissionName: "Human Resource",
    header: "Human Resource",
    searchPlaceholder: "Search within Human Resource Module",
    icon: {white: w_hr, grey: g_hr},
    layout: "/user",
    subMenu: hrSubRoutes
  },
  {
    path: "/equipment",
    name: "Equipment",
    permissionName: "Equipment",
    header: "Equipment",
    searchPlaceholder: "Search within Equipment Module",
    icon: {white: w_equipment, grey: g_equipment},
    layout: "/user",
    subMenu: equipmentSubRoutes
  },
  {
    path: "/cost-and-productivity",
    name: "Cost & Productivity",
    permissionName: "Cost Report",
    header: "Cost & Productivity",
    // searchPlaceholder: "Search within Cost & Productivity Module",
    icon: {white: w_cp, grey: g_cp},
    layout: "/user",
    subMenu: costProductivitySubRoutes
  },
  {
    path: "/quality-control",
    name: "Quality Control",
    permissionName: "Quality Control",
    header: "Quality Control",
    canCreate: true,
    searchPlaceholder: "Search within Quality Control Module",
    icon: {white: w_qualityControl, grey: g_qualityControl},
    layout: "/user",
    subMenu: qualityControlSubRoutes
  },
  {
    path: "/r&d",
    name: "R&D",
    permissionName: "RandDModule",
    header: "R&D - Experiment Report",
    // canCreate: true,
    // searchPlaceholder: "Search within Quality Control Module",
    icon: {white: w_qualityControl, grey: g_qualityControl},
    component: RDVegetable,
    layout: "/user",
    // subMenu: qualityControlSubRoutes
  },
  {
    path: "/smart-energy",
    name: "Smart Energy",
    //permissionName: "Smart Energy",
    header: "Smart Energy",
    // canCreate: true,
    // searchPlaceholder: "Search within Smart Energy Module",
    icon: {white: w_smartMetering, grey: g_smartMetering},
    layout: "/user",
    // component: SMOverview,
    subMenu: smartMeteringSubRoutes
  },
  {
    path: "/vifs",
    name: "VIFS",
    //permissionName: "VIFS",
    header: "VIFS",
    canCreate: true,
    // searchPlaceholder: "Search within VIFS Module",
    icon: {white: w_asrs, grey: g_asrs},
    layout: "/user",
    subMenu: ASRSSubRoutes
  },
  {
    path: "/amr",
    name: "AMR",
    //permissionName: "AMR",
    header: "AMR",
    canCreate: true,
    // searchPlaceholder: "Search within AMR Module",
    icon: {white: w_amr, grey: g_amr},
    layout: "/user",
    subMenu: AMRSubRoutes
  },
  {
    path: "/flow-control",
    name: "Flow Control",
    //permissionName: "Flow Control",
    header: "Flow Control",
    canCreate: true,
    // searchPlaceholder: "Search within Flow Control Module",
    icon: {white: w_flow, grey: g_flow},
    layout: "/user",
    subMenu: flowControlSubRoutes,
  },
  {
    path: "/report",
    name: "Report",
    permissionName: "Report",
    header: "Report",
    // searchPlaceholder: "Search within Cost & Productivity Module",
    icon: {white: w_report, grey: g_report},
    component: Report,
    layout: "/user",
  },
  {
    path: "/traceability",
    name: "Traceability",
    permissionName: "Traceability",
    header: "Traceability",
    // searchPlaceholder: "Search within Traceability Module",
    icon: {white: w_traceability, grey: g_traceability},
    component: Traceability,
    layout: "/user",
  },
  {
    path: "/alarm",
    name: "Alarm",
    header: "Alarm",
    icon: {white: w_alarm, grey: g_alarm},
    layout: "/user",
    subMenu: alarmSubRoutes
  },
  {
    path: "/code-generator",
    name: "Code Generator",
    permissionName: "CodeGenerator",
    header: "Code Generator",
    icon: {white: w_rfid, grey: g_rfid},
    layout: "/user",
    subMenu: codeGeneratorSubRoutes
  },
  {
    path: "/watt-calculator",
    name: "Intensity and Watt Calculator",
    permissionName: "WattCalculator",
    header: "Intensity and Watt Calculator",
    icon: {white: w_capacity, grey: g_capacity},
    component: Prediction,
    layout: "/user",
  },
  // {
  //   path: "/sustainability",
  //   name: "Sustainability",
  //   // permissionName: "QR Code", // TODO: change permission
  //   header: "Sustainability",
  //   icon: {white: w_monitoring, grey: g_monitoring},
  //   layout: "/user",
  //   subMenu: SustainabilitySubRoutes,
  // },
];
