import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// core components
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from "shared-components/Modal/Modal";

import { setDirty } from "store/general";
import { updateRequest, reset } from ".";
import { CreateOrUpdateAlarmNotificationSetting } from "services/UserPortal/AlarmService";

import alert from "assets/icons/orange/alert-line.svg";
import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/alarmStyle.js";

const useStyles = makeStyles(styles);

export default function BeforeAfter(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const setting = useSelector(store => store.user.alarm.setting);
  const alarmType = useSelector(store => store.user.alarm.alarmType);
  const [success, setSuccess] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id] : !setting[e.target.id]}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateAlarmNotificationSetting())
      .then(({error})=> {
        if (!error) {
          dispatch(setDirty(false));
          setSuccess(true);
        }
        setOpenModal(true);
      })
    }
  };

  const validateFields = () => {
    if (!setting.alarmTypeId) {
      setErrorMsg({field: "alarmTypeId", msg: "Please select a alarm type"});
      return false;
    }
    if (!setting.isSMS && !setting.isEmail && !setting.isAppNotification) {
      setErrorMsg({field: "notification", msg: "Please select a notification type"});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  const handleModal = () => {
    Promise.all([dispatch(setDirty(false))]);
    setOpenModal(false);
    setSuccess(false);
  }

  React.useEffect(() => {
    dispatch(reset());
  },[props.tab]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);
  
  const alarm = alarmType.find(({id})=>id==setting.alarmTypeId);
  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.alarmPaper}>
        <Grid container spacing={4}>
          <Grid item xs={4} className={classes.alarmLabel}>
            Alarm Type:
          </Grid>
          <Grid item xs={8}>
            <Select
              name="alarmTypeId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select an alarm type"
              value={setting.alarmTypeId}
              errorMsg={errorMsg}
            >
              {alarmType && alarmType.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.alarmTypeName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.alarmLabel2}>
            Notification Channels:
          </Grid>
          <Grid item xs={8}>
            <div className="flex">
              <Checkbox  
                className={classes.alarmCheckbox}
                id="isSMS"
                checked={Boolean(setting.isSMS)}
                onChange={(e) => handleButtonClick_checkbox(e)}
              />
              <Typography className={classes.alarmLabel2}>SMS</Typography>
            </div>
            <div className="flex">
              <Checkbox  
                className={classes.alarmCheckbox}
                id="isEmail"
                checked={Boolean(setting.isEmail)}
                onChange={(e) => handleButtonClick_checkbox(e)}
              />
              <Typography className={classes.alarmLabel2}>Email</Typography>
            </div>
            <div className="flex">
              <Checkbox  
                className={classes.alarmCheckbox}
                id="isAppNotification"
                checked={Boolean(setting.isAppNotification)}
                onChange={(e) => handleButtonClick_checkbox(e)}
              />
              <Typography className={classes.alarmLabel2}>In App Notification</Typography>
            </div>
            {errorMsg.field==="notification" && <Typography className={classes.error}>{errorMsg.msg}</Typography>}
          </Grid>
          <Grid item xs={12} className={classes.alarmButton}>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.push("/user/alarm/details")}
            >
              Go Back
            </Button>
            <Button 
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={success ? <img className={classes.icon_64} src={confirm} alt="confirm" /> : <img className={classes.icon_64} src={alert} alt="alert" />}
        title={success ? "Success" : setting.isSMS ? "SMS Unavailable" : "Error"}
        content={success
          ? alarm && "The repeat after event has successfully been configured to "+alarm.alarmTypeName+". The alarm will repeat until it has been resolved. " 
          : setting.isSMS ? "This user doesn't have a phone number for SMS alert." : "Error in saving settings. Please try again later."
        }
        actions={
          <Button className={classes.button} onClick={() => handleModal()}>OK</Button>
        }
      />
    </React.Fragment>
  );
}
