import * as adminRoutes from "routes/adminRoutes";
import * as userRoutes from "routes/userRoutes";
import _ from "lodash";
import { checkPermission } from "common/functions";

export const useRouteCanCreate = () => {
  let routesObj = _.pick(adminRoutes, ["dashboardRoutes"]);
  if (!location.pathname.includes("/admin")) {
    routesObj = _.pick(userRoutes, ["dashboardRoutes", "customerOrderSubRoutes", "climateSubRoutes", "equipmentSubRoutes", "rawMaterialSubRoutes", "monitoringSubRoutes", "qualityControlSubRoutes", "reportRoutes", "ASRSSubRoutes", "flowControlSubRoutes"]);
  }
  let routes = [];
  Object.keys(routesObj).forEach(key => routes = _.concat(routes, routesObj[key]));
  let value = false;
  routes.forEach((route) => {
    const permission = route.permissionName ? checkPermission(route.permissionName, "readEdit") : true;

    let splitRoute = (route.layout+route.path).split("/");
    let splitLocation = (window.location.pathname).split("/");
    let match = true;
    for (let i = 0; i < splitLocation.length; i++) {
      if (splitRoute[i] !== ":id" && splitLocation[i] !== splitRoute[i]) {
        match = false;
      }
    }
    if (match && permission) {
      value = route.canCreate;
    }
  });
  return value;
};