import {
  secondaryColor,
  border
} from "assets/jss/generaliseStyle.js";

const stepperStyle = {
  stepper: {
    padding: "1.198vw 0 0",
    marginLeft: "-8px",
  },
  line: {
    border
  },
  label: {
    fontSize: "0.833vw"
  },
  step: {
    color: secondaryColor[0] + "!important",
  },
}

export default stepperStyle;