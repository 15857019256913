import React from "react";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
// @mui/icons-material
import emptyImg from "assets/img/empty-img.svg";

import styles from "assets/jss/error-page/errorStyle.js";

const useStyles = makeStyles(styles);

export default function Empty(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <img className={classes.emptyImg} src={emptyImg} alt="greenphyto-empty" />
        <Typography className={classes.description}>{props.description}</Typography>
      </div>
    </div>
  );
}
