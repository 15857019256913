import {
  button,
  buttonSecondary,
  whiteColor,
  primaryColor
} from "assets/jss/generaliseStyle.js";

import {
  table,
  icon,
  cardPaper,
} from "assets/jss/components/commonStyle.js";

const recipeStyle = {
  button,
  buttonSecondary,

  icon,
  table,
  cardPaper,
  // Recipe
  productDropdown: {
    width: "11.771vw"
  },
  productCode: {
    fontWeight: 500,
    color: primaryColor[4]
  },
  marginBottom: {
    marginBottom: "1.667vw"
  },
  cardButton: {
    marginRight: "1.250vw"
  },
  tableHead: {
    fontWeight: 500,
    width: "11.458vw",
    minWidth: "11.458vw",
    maxWidth: "11.458vw"
  },
  timelineRow: {
    backgroundColor: whiteColor +" !important"
  },
  timelineCell: {
    fontSize: "0.625vw !important",
    opacity: 0.5,
    width: "9.375vw",
    minWidth: "9.375vw"
  },
  dayCell: {
    width: "6.250vw",
    minWidth: "6.250vw"
  },
  checkboxPadding: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1.250vw"
  },
  seeding: {
    borderBottom: "0.156vw solid #CC81B8",
  },
  germination: {
    borderBottom: "0.156vw solid #ED86B5",
  },
  harvesting: {
    borderBottom: "0.156vw solid #F5A35C",
  },
  packaging: {
    borderBottom: "0.156vw solid #F7DE64",
  },
  cutting: {
    borderBottom: "0.156vw solid #D1DE6C",
  },
  transferGrowth: {
    borderBottom: "0.156vw solid #94CE85",
  },
  transfer: {
    borderBottom: "0.156vw solid #6AAFB9",
  },
  growth: {
    borderBottom: "0.156vw solid #93D1DE",
  },
  nursery: {
    borderBottom: "0.156vw solid #CCCCCC",
  },
}

export default recipeStyle;