import {
  cardRadius,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

const idManagementStyle = {
  paper: {
    ...cardRadius,
    display: "flex",
    alignItems: "center",
    height: "14.583vw",
    border: "0.104vw solid "+ secondaryColor[0],
    fontSize: "1.094vw",
    padding: "0.573vw",
    fontWeight: "500",
    color: primaryColor[3],
    textAlign: "center",
    "&:hover": {
      backgroundColor: secondaryColor[0],
      color: whiteColor,
    }
  },
  paperItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  logoImage: {
    marginBottom: "1.146vw",
    width: "5vw",
    height: "5vw",
  },
  logoTitle: {
    fontSize: "1.250vw",
    fontWeight: 700,
  },
};

export default idManagementStyle;
  