import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { GetDashboard } from "services/UserPortal/SmartMeteringService";

import cost from "assets/icons/black/energy-cost.svg";
import watt from "assets/icons/black/energy-watt.svg";
import efficiency from "assets/icons/black/energy-efficiency.svg";
import styles from "assets/jss/components/UserPortal/smartMeteringStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.smartMetering.dashboard.result);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetDashboard());
  },[]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={4} className={classes.dashboardContainer}>
          <Paper elevation={0} className={classes.iconPaper}>
            <img src={cost} className={classes.dashboardIcon} />
          </Paper>
          <div>
            <Typography className={classes.label}>COST</Typography>
            <Paper className={classes.paper} elevation={0}>
              <Typography>Today: <span className={classes.orange}>${result.todayCost}</span></Typography>
              <Typography>Yesterday: <span className={classes.orange}>${result.yesterdayCost}</span></Typography>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.dashboardContainer}>
          <Paper elevation={0} className={classes.iconPaper}>
            <img src={watt} className={classes.dashboardIcon} />
          </Paper>
          <div>
            <Typography className={classes.label}>WATT PER HOUR</Typography>
            <Paper className={classes.paper} elevation={0}>
              <Typography>Today: <span className={classes.orange}>{result.todayWattPerHour}kWh</span></Typography>
              <Typography>Yesterday: <span className={classes.orange}>{result.yesterdayWattPerHour}kWh</span></Typography>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} className={classes.dashboardContainer}>
          <Paper elevation={0} className={classes.iconPaper}>
            <img src={efficiency} className={classes.dashboardIcon} />
          </Paper>
          <div>
            <Typography className={classes.label}>SYSTEM EFFICIENCY</Typography>
            <Paper className={classes.paper} elevation={0}>
              <Typography className={classes.orange}>0.8kW/RT</Typography>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
