// Product
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import { Link } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { GetAllProducts, DeleteProduct, GetAllProductGroup } from "services/AdminPortal/ProductService";
import { reset } from "./store/product";

import { rowsPerTable } from "config";
import { productTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";
import arrow from "assets/icons/orange/droplist-arrow.svg";

import { filterActionTableHead } from "common/functions";
import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function Product() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const finishProduct= useSelector(store => store.admin.product.product.productFinish);
  const unfinishProduct = useSelector(store => store.admin.product.product.productUnfinish);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const [order, setOrder] = React.useState({finished: 'asc', unfinished: 'asc'});
  const [orderBy, setOrderBy] = React.useState({finished: 'productID', unfinished: 'productID'});
  const [page, setPage] = React.useState({finished: 0, unfinished: 0});
  const [selectedId, setSelectedId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const finishCount = Math.ceil(finishProduct.length / rowsPerTable);
  const unfinishCount = Math.ceil(unfinishProduct.length / rowsPerTable);

  const handleRequestSortFinished = (e, property) => {
    const isAsc = orderBy.finished === property && order.finished === 'asc';
    setOrder({...order, finished: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, finished: property});
  };

  const handleRequestSortUnfinished = (e, property) => {
    const isAsc = orderBy.unfinished === property && order.unfinished === 'asc';
    setOrder({...order, unfinished: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, unfinished: property});
  };
  
  const handleModal = (id) => {
    setSelectedId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteProduct(selectedId))
    .then(() => {
      dispatch(GetAllProducts());
      setPage({finished: 0, unfinished: 0});
      setOpenModal(!openModal);
    })
  }

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllProducts());
    dispatch(GetAllProductGroup()); //for search func
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { finishProduct.length > 0 || unfinishProduct.length > 0 ?
      <React.Fragment>
        {/* Finished Product */}
        { finishProduct.length > 0 &&
          <Card 
            classes={{
              root: classes.topCard
            }}
            title = "Product List"
          >
            <Table
              className={classes.table}
              header={filterActionTableHead(productTableHead, canWrite)}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSortFinished}
            >
              { _.orderBy(finishProduct, [orderBy.finished], [order.finished])
              .slice(page.finished * rowsPerTable, page.finished * rowsPerTable + rowsPerTable)
              .map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.productID}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.productDesc}</TableCell>
                    <TableCell>
                      <Link to={{pathname: "/admin/products/process/"+item.id, state: {prevPath: location.pathname}}}>
                        <span className={classes.link}>Details</span>
                      </Link>
                    </TableCell>
                    <TableCell>{item.productDetail}</TableCell>
                    <TableCell align="right">
                      {canWrite &&
                        <React.Fragment>
                          <Link to={{pathname: "/admin/products/"+item.id}}>
                            <CustomIconButton type="edit" />
                          </Link>
                          <CustomIconButton 
                            type="delete"
                            onClick={() => handleModal(item.id)}
                          />
                        </React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                )
              })} 
            </Table>
            { finishCount > 1 &&
              <Pagination 
                count={finishCount} 
                page={page.finished+1}
                onChange={(e, pageNo)=>setPage({...page, finished: pageNo-1})}
              />
            }
          </Card>
        }
        {/* Unfinished Product */}
        { unfinishProduct.length > 0 &&
          <Card
            title = {
              <React.Fragment>
                  Unfinished Product List
                  <IconButton 
                    className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                    onClick={() => setCollapsed(!collapsed)}
                  >
                    <img className={classes.icon} src={arrow} alt="arrow" />
                  </IconButton>
                </React.Fragment>
            }
          >
            { !collapsed && 
              <React.Fragment>
                <Table
                  className={classes.table}
                  header={filterActionTableHead(productTableHead, canWrite)}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSortUnfinished}
                >
                  { _.orderBy(unfinishProduct, [orderBy.unfinished], [order.unfinished])
                  .slice(page.unfinished * rowsPerTable, page.unfinished * rowsPerTable + rowsPerTable)
                  .map((item) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item.productID}</TableCell>
                        <TableCell>{item.productName}</TableCell>
                        <TableCell>{item.productDesc}</TableCell>
                        <TableCell>NIL</TableCell>
                        <TableCell>{item.detail}</TableCell>
                        <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/products/"+item.id}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton 
                              type="delete"
                              onClick={() => handleModal(item.id)}
                            />
                          </React.Fragment>
                        }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
                { unfinishCount > 1 &&
                  <Pagination 
                    count={unfinishCount} 
                    page={page.unfinished+1}
                    onChange={(e, pageNo)=>setPage({...page, unfinished: pageNo-1})}
                  />
                }
              </React.Fragment>
            }
          </Card>
        }
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this product? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    : <Empty 
        description={"Your page is empty. " + (canWrite ? "Add a product to get started!" : "")}
      />
    }
    </React.Fragment>
  );
}
