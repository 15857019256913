import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";
import TextField from "shared-components/TextField/TextField";

import { renderValue, formatNumbers } from "common/functions";
import { rowsPerTable } from "config";
import { setValues, updateRequest, reset } from ".";
import { setSearchText } from "store/general";
import { cagesTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchCages, DeleteCage, UpdateCage } from "services/AdminPortal/CagesService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/cageStyle.js";

const useStyles = makeStyles(styles);

export default function SearchCagesResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResultsCount = useSelector(store => store.admin.cage.totalCount);
  const searchResults = useSelector(store => store.admin.cage.cages);
  const cage = useSelector(store => store.admin.cage.cage);
  const [page, setPage] = React.useState(0);
  const [id, setId] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(searchResultsCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleOnEdit = (item) => {
    dispatch(setValues({cage: item}));
    setId(item.id);
    setIsEdit(true);
  }

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnConfirm = () => {
    if (validateFields()) {
      dispatch(UpdateCage())
      .then(() => {
        dispatch(SearchCages({page, keyword: params.keyword}));
        dispatch(setValues({cage: {}}));
        setId(null);
        setIsEdit(false);
      });
    }
  };

  const handleOnCancel = () => {
    dispatch(setValues({cage: {}}));
    setId(null);
    setIsEdit(false);
  };

  const validateFields = () => {
    if (!cage.barcodeNumber) {
      setErrorMsg({field: "barcodeNumber", msg: "Please enter barcode number"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  const handleModal = (idNum) => {
    setId(idNum);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteCage(id))
    .then(() =>{
      resetState();
    })
  }

  const resetState = () => {
    setId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    dispatch(reset());
    dispatch(SearchCages({page, keyword: params.keyword}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(searchResultsCount) + " Search Result(s) From 1 Table:  ‘" + params.keyword + "’"}</Typography>
      <Card 
        classes={{
          root: classes.result,
        }}
        title = {
          <React.Fragment>
            {searchResultsCount + " Result(s) From Cages"}
            <IconButton 
              className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
              onClick={() => setCollapsed(!collapsed)}
            >
              <img className={classes.icon} src={arrow} alt="arrow" />
            </IconButton>
          </React.Fragment>
        }
      >
        { !collapsed && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={cagesTableHead}
              >
                { searchResults.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{('0'+((page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                      <TableCell>{highlightValue(item.cageRefNo)}</TableCell>
                      <TableCell>
                        {isEdit && id === item.id
                        ? <TextField 
                            id="barcodeNumber"
                            variant="outlined" 
                            placeholder="Enter a value"
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) => handleOnChange_text(e)}
                            value={renderValue(cage.barcodeNumber)}
                            errorMsg={errorMsg}
                          />
                        : highlightValue(item.barcodeNumber)
                        }
                      </TableCell>
                      <TableCell>{highlightValue(item.cageLocationId)}</TableCell>
                      <TableCell>{highlightValue(item.status)}</TableCell>
                      <TableCell align="right">
                        {isEdit && id === item.id
                        ? <React.Fragment>
                            <CustomIconButton 
                              type="confirm" 
                              onClick={handleOnConfirm}
                            />
                            <CustomIconButton 
                              type="close"
                              onClick={handleOnCancel}
                            />
                          </React.Fragment>
                        : <React.Fragment>
                            <CustomIconButton 
                              type="edit" 
                              onClick={() => handleOnEdit(item)}
                              disabled={isEdit && id !== item.id}
                            />
                            <CustomIconButton 
                              type="delete"
                              onClick={() => handleModal(item.id)}
                              disabled={isEdit && id !== item.id}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </React.Fragment>
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
