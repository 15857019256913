import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { withStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// core components
import CustomTableCell from "shared-components/Table/TableCell";

import TagBank from "./TagBank";
import CapacityBank from "./CapacityBank";
import ClimateBank from "./ClimateBank";
import AlarmBank from "./AlarmBank";
import { bankFormat } from "common/functions";
import { farmConfig } from "enums/Constants";

import styles from "assets/jss/shared-components/bankStyle.js";
import { whiteColor } from "assets/jss/generaliseStyle";

const TopLeftStickyTableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "0.833vw",
    backgroundColor: whiteColor
  },
  body: {
    top: 0,
    left: 0,
    position: "sticky",
    zIndex: 1
  }
})(TableCell);

const BottomLeftStickyTableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "0.833vw",
    backgroundColor: whiteColor
  },
  body: {
    bottom: 0,
    left: 0,
    position: "sticky",
    zIndex: 1
  }
})(TableCell);

const LeftStickyTableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "0.833vw",
    backgroundColor: whiteColor
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 1
  }
})(TableCell);

const BottomStickyTableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "0.833vw",
    backgroundColor: whiteColor
  },
  body: {
    bottom: 0,
    position: "sticky",
  }
})(TableCell);

const useStyles = makeStyles(styles);
export default function Bank(props) {
  const classes = useStyles();

  const noOfLevels = () => {
    let element = [];
    for (let i = parseInt(props.levels); i >= 1; i--) {
      element.push(
        <TableRow key={i}>
          <LeftStickyTableCell align="right" className={classes.labelFont}>{bankFormat(i)}</LeftStickyTableCell>
          {props.type==="tag" && <TagBank row={i} {...props} />}
          {props.type==="capacity" && <CapacityBank row={i} {...props} />}
          {props.type==="climate" && <ClimateBank row={i} {...props} />}
          {props.type==="alarm" && <AlarmBank row={i} {...props} />}
        </TableRow>
      );
    }
    return element;
  }

  const emptyCells = () => {
    let element = [];
    for (let i = 1; i <= parseInt(props.bays); i++) {
      element.push(<CustomTableCell key={i} />);
    }
    return element;
  }

  const bayLabel = () => {
    let element = [];
    for (let i = 1; i <= parseInt(props.bays); i++) {
      element.push(<BottomStickyTableCell key={i} align="center" className={classes.labelFont}>{bankFormat(i)}</BottomStickyTableCell>);
    }
    return element;
  }

  return (
    <Table>
      <TableBody>
        <TableRow className={props.type!=="tag" ? classes.colWidth : classes.tagColWidth}>
          <TopLeftStickyTableCell align="right" className={classes.labelFont}>{farmConfig.level}</TopLeftStickyTableCell>
          {emptyCells()}
        </TableRow>
        {noOfLevels()}
        <TableRow>
          <BottomLeftStickyTableCell align="right" className={classes.labelFont}>{farmConfig.bay}</BottomLeftStickyTableCell>
          {bayLabel()}
        </TableRow>
      </TableBody>
    </Table>
  );
}

Bank.propTypes = {
  header: PropTypes.array,
  levels: PropTypes.number,
  bays: PropTypes.number,
  data: PropTypes.array,
  selected: PropTypes.array,
  type: PropTypes.oneOf(['tag', 'climate', 'capacity', 'alarm']),
  isManual: PropTypes.bool
};