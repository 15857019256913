import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  cardPaper,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const roleStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  orange,
  cardPaper,

  // Search Role
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display Role
  icon,
  table,

  // Add Edit Role
  leftPaper: {
    border,
    width: "38.646vw",
    borderRadius: "0.625vw",
    padding: "2.083vw 1.563vw"
  },
  label: {
    margin: "0 0 0.365vw",
    '&:not(:first-child)': {
      marginTop: "1.563vw"
    },
  },
  rightCardContent: {
    height: "26.042vw",
    overflow: "overlay"
  },
  roleNameCell: {
    width: "10vw",
    minWidth: "10vw"
  },
  roleDescCell: {
    width: "18vw",
    minWidth: "18vw"
  },
  roleModuleCell: {
    width: "22vw",
    minWidth: "22vw",
    maxWidth: "22vw",

  },
  tableHeader: {
    '& thead > tr > th': {
      top: "-1.302vw",
      left: "unset"
    }
  },
  subdirectoryArrowRight: {
    paddingBottom: "0.278vw"
  }
}

export default roleStyle;