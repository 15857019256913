import {
  cardRadius,
  border,
  icon_24,
  icon_64,
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  link
} from "assets/jss/components/commonStyle.js";

const warehouseStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_24,
  icon_64,
  
  orange,

  // Search Warehouse
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display warehouse
  table,
  icon,
  link,

  // Add Edit warehouse
  paper: {
    ...cardRadius,
    border,
    display: "flex",
    flexDirection: "column",
    margin: "1.563vw 15.104vw 5.208vw",
    padding: "3.646vw 7.292vw 0"
  },
  label: {
    marginTop: "0.833vw",
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.708vw 0",
    padding: 0
  },
}

export default warehouseStyle;