/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";

import { renderValue, filterParam } from "common/functions";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/overview";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { GetHarvestingDashboard } from "services/UserPortal/OperationsService";

import errorImg from "assets/img/error-img.svg";
import styles from "assets/jss/components/UserPortal/operationsStyle.js";

const useStyles = makeStyles(styles);

export default function HarvestDetails({setView, ...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const harvestDashboard = useSelector(store => store.user.operations.overview.harvestDashboard);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetHarvestingDashboard(props.productId));
  },[]);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: classes.cardPaper,
        }}
        title="Harvesting in Progress"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={() => setView("details")}
            >
              Back
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={1} className={classes.hrDivider}>
          {harvestDashboard.map((item, index) => {
            return (
              <Grid item xs={2} key={index}>
                <Typography>{item.harvestLocationName}</Typography>
                <Typography>{item.employeeName}</Typography>
                <Typography>{moment(item.processDateTime).format("DD/MM/YYYY HH:mm")}</Typography>
              </Grid>
            )
          })}
        </Grid>
      </Card>
    </React.Fragment>
  );
}
