import {
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  icon,
} from "assets/jss/components/commonStyle.js";

const buttonStyle = {
  icon,
  button,
  buttonSecondary,
  
}

export default buttonStyle;