// Equipment Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { resetSearch } from ".";
import { setSearchText } from "store/general";
import { equipmentTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchEquipment } from "services/AdminPortal/EquipmentService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/AdminPortal/equipmentStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchEquipmentResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const searchResultsCount = useSelector(store => store.admin.equipment.searchResultsCount);
  const searchResults = useSelector(store => store.admin.equipment.searchResults);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('equipmentName');
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);

  const count = Math.ceil(searchResultsCount / rowsPerTable);
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  React.useEffect(() => {
    dispatch(resetSearch());
    dispatch(SearchEquipment({keyword: params.keyword, page}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(resetSearch());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(searchResultsCount) + " Search Result(s) From 1 Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount > 0 &&
        <Card 
          title = {
            <React.Fragment>
              {searchResultsCount + " Result(s) From Equipment List"}
              <IconButton 
                className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed(!collapsed)}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed &&
            <React.Fragment>
              <div className={classes.table}>
                <Table
                  header={filterActionTableHead(equipmentTableHead, canWrite)}
                  // order={order}
                  // orderBy={orderBy}
                  // onRequestSort={handleRequestSort}
                >
                  { searchResults.map((item,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{highlightValue(item.equipmentName)}</TableCell>
                        <TableCell>{highlightValue(item.equipmentRefNo)}</TableCell>
                        <TableCell className={classes.numberCell}>{highlightValue(item.shortForm)}</TableCell>
                        <TableCell className={classes.numberCell}>{highlightValue(formatNumbers(item.lowestDenominator))}</TableCell>
                        <TableCell className={classes.numberCell}>{highlightValue(item.lowestUOM)}</TableCell>
                        <TableCell className={classes.numberCell}>{highlightValue(formatNumbers(item.costPerUnit))}</TableCell>
                        <TableCell className={classes.numberCell}>{highlightValue(formatNumbers(item.availableUsagePerDay))}</TableCell>
                        <TableCell className={classes.numberCell}>{highlightValue(formatNumbers(item.accumulatedHoursForMaintenance))}</TableCell>
                        <TableCell>
                          {canWrite &&
                            <TableCell>
                              <Link className={classes.orange} to={{pathname: "/admin/equipment/configure/"+item.id}}>
                                Edit
                              </Link>
                            </TableCell>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </div>
              { count > 1 &&
                <Pagination 
                  count={count} 
                  page={page+1}
                  onChange={(e, pageNo)=>setPage(pageNo-1)}
                />
              }
            </React.Fragment>
          }
        </Card>
      }
    </React.Fragment>
  );
}
