import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from '@mui/material/Button';
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import Alert from "shared-components/Modal/Alert";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { setSearchText, setDirty } from "store/general";
import { filterActionTableHead } from "common/functions";
import { pairingConfigTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest, updateConfigRequest } from "../store/sensorControl";
import { GetAllSensorControlMapping, CreateOrUpdateSensorControlMapping } from "services/UserPortal/ClimateService";
import { GetDeviceList } from "services/UserPortal/CommonLookupService";

import { useRouteCanWrite } from "hooks";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function PairingConfiguration(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isDirty = useSelector(store => store.general.isDirty);
  const totalCount = useSelector(store => store.user.climate.sensorControl.totalCount);
  const config = useSelector(store => store.user.climate.sensorControl.config);
  const sensorDevice = useSelector(store => store.user.climate.sensorControl.sensorDevice);
  const controlDevice = useSelector(store => store.user.climate.sensorControl.controlDevice);
  const [configIndex, setConfigIndex] = React.useState(null);
  const [page, setPage] = React.useState(0); // for device list
  const [devicePage, setDevicePage] = React.useState({sensor: 1, control: 1}); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState({sensor: false, control: false}); // for device list
  const [searchValue, setSearchValue] = React.useState([{sensor: "", control: ""}]); // for device list
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleOnChange_autocomplete = (value, index, key) => {
    dispatch(setDirty(true));
    if (value) {
      dispatch(updateConfigRequest({index, value: {[key.replace("Id", "")]: value, [key]: value.id}}));
    }
  };
  
  const fetchMoreData = (search, pageNo, isSensorDevice) => {
    const key = isSensorDevice ? "sensor" : "control";
    if (devicePage[key] !== -1 || pageNo !== undefined) {
      setIsLoadingMore({...isLoadingMore, [key]: true});
      const param = {
        page: pageNo !== undefined ? pageNo : devicePage[key],
        DeviceRefNo: search ? search : "",
        isSensorDevice: isSensorDevice,
        isControlDevice: !isSensorDevice,
      }
      dispatch(GetDeviceList(param))  // pass in devicePage and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore({...isLoadingMore, [key]: false});
          if (response.payload.result&&response.payload.result.items.length) {
            setDevicePage({...devicePage, [key]: pageNo !== undefined ? pageNo+1 : devicePage[key]+1});
            if (search || devicePage[key] === 0 || devicePage[key] === 0) {
              dispatch(updateRequest({[key+"Device"]: response.payload.result.items}));
            } else {
              if (isSensorDevice) {
                dispatch(updateRequest({sensorDevice: _.unionBy(sensorDevice, response.payload.result.items, "id")}));
              } else {
                dispatch(updateRequest({controlDevice: _.unionBy(controlDevice, response.payload.result.items, "id")}));
              }
            }
          } else {
            setDevicePage({...devicePage, [key]: -1}); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleModal_delete = (index) => {
    setConfigIndex(index)
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_alert = () => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
    } else {
      handleButtonClick_exit();
    }
  }

  const handleAddConfig = () => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(config);
    let searchPayload = searchValue;
    const index = _.filter(payload, (item)=>{return !item.isDeleted}).length;
    payload.splice(index, 0, {});
    searchPayload.splice(index, 0, {sensor: "", control: ""});
    dispatch(updateRequest({config: payload}));
    setSearchValue(searchPayload);
  }

  const handleButtonClick_delete = () => {
    dispatch(setDirty(true));
    setOpenDeleteModal(!openDeleteModal);
    let payload = _.cloneDeep(config);
    let searchPayload = searchValue;
    if (payload[configIndex].id) {
      payload[configIndex].isDeleted = true;
      payload.push(payload.splice(configIndex, 1)[0]);
      searchPayload.push(searchPayload.splice(configIndex, 1)[0]);
    } else {
      payload.splice(configIndex, 1);
      searchPayload.splice(configIndex, 1);
    }
    setSearchValue(searchPayload);
    dispatch(updateRequest({config: payload}));
  }

  const handleButtonClick_exit = () => {
    dispatch(setDirty(false));
    setOpenAlertModal(false);
    props.setPairingConfiguration(false);
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateSensorControlMapping())
      .then((response)=> {
        if (!response.error) {
          dispatch(GetAllSensorControlMapping())
          .then((response) => {
            if (response.payload.result.items.length) {
              setSearchValue(response.payload.result.items.map((item) => {
                  return {sensor: item.sensorDevice.deviceRefNo, control: item.controlDevice.deviceRefNo}
                })
              );
            }
          });
          dispatch(setDirty(false));
        }
      });
    }
  }
  
  const validateFields = () => {  
    const unique = _.uniqWith(config, _.isEqual);
    for (let i = 0; i < config.length; i++) {
      if (!config[i].isDeleted) {
        if (!config[i].sensorDeviceId) {
          setErrorMsg({field: "sensorDeviceId"+i, msg: "Please select a sensor device"});
          return false;
        }
        if (!config[i].controlDeviceId) {
          setErrorMsg({field: "controlDeviceId"+i, msg: "Please select a control device"});
          return false;
        }
        if (config[i] !== unique[i]) {
          setErrorMsg({field: "sensorDeviceId"+i, msg: "Please select a unique pairing"});
          return false;
        }
      }
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllSensorControlMapping({}))
    .then((response) => {
      if (response.payload && response.payload.result && response.payload.result.totalCount) {
        setSearchValue(response.payload.result.items.map((item) => {
            return {sensor: item.sensorDevice.deviceRefNo, control: item.controlDevice.deviceRefNo}
          })
        );
      }
    });
    Promise.all([
      dispatch(GetDeviceList({IsSensorDevice: true, page: 0})),
      dispatch(GetDeviceList({IsControlDevice: true, page: 0}))
    ]).then((response) => {
      dispatch(updateRequest({sensorDevice: response[0].payload.result.items, controlDevice: response[1].payload.result.items}));
    })
    // componentDidUnmount
    return () => {
      dispatch(updateRequest({config: [{}]}));
    }
  },[]);

  const deviceAutocomplete = (isSensor, name, value, index) =>{
    if (searchValue.length === config.length) {
      return (
        <Autocomplete
          id={isSensor ? "sensorDeviceId"+index : "controlDeviceId"+index}
          dataCount={devicePage[name]*10}
          options={isSensor ? sensorDevice : controlDevice}
          fetchData={()=>fetchMoreData(undefined, undefined, isSensor)}
          renderOption={(option) => option.deviceRefNo}
          isLoadingMore={isLoadingMore[name]}
          placeholder={"Select a "+name+" device"}
          onInputChange={(e, newInputValue) => {
            const payload = searchValue;
            if (e && e._reactName == "onBlur") {
              payload[index] = {...payload[index], [name]: !_.isEmpty(value)?value.deviceRefNo:""};
              setSearchValue(payload);
              setDevicePage({...devicePage, [name]: 0});
              fetchMoreData(newInputValue, 0, true);
            } 
            if (e && e._reactName == "onClick") {
              payload[index] = {...payload[index], [name]: newInputValue};
              setSearchValue(payload);
              setDevicePage({...devicePage, [name]: 0});
              fetchMoreData(undefined, 0, isSensor);
            }
            if (e && e._reactName == "onChange") {
              payload[index] = {...payload[index], [name]: newInputValue};
              setSearchValue(payload);
              setDevicePage({...devicePage, [name]: 0});
              fetchMoreData(newInputValue, 0, isSensor);
            }
          }}
          onChange={(_, newValue) => {
            handleOnChange_autocomplete(newValue, index, isSensor ? "sensorDeviceId" : "controlDeviceId");
          }}
          inputValue={searchValue[index][name]}
          value={searchValue[index][name]!==(value&&value.deviceRefNo) ? null : value}
          renderValue={"deviceRefNo"}
          errorMsg={errorMsg}
          disabled={!canWrite}
        />
      )
    }
  }
  
  return (
    <React.Fragment>
      <Card 
        title="Pairing Configuration"
        subheader="Please pair Sensor to Control Device ID which Control Device will know which sensor data to be reference with:"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleModal_alert()}
            >
              Go Back
            </Button>
            {canWrite &&
              <Button
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
                disabled={!isDirty}
              >
                Save
              </Button>
            }
          </React.Fragment>
        }
      >
        <Card classes={{root: classes.pairPaper}}>
          <Table
            className={classes.table}
            header={filterActionTableHead(pairingConfigTableHead)}
          >
            {config.map((item, index) => {
              if (!item.isDeleted) {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.pairCell}>
                      {deviceAutocomplete(true, "sensor", item.sensorDevice, index)}
                    </TableCell>
                    <TableCell className={classes.pairCell}>
                      {deviceAutocomplete(false, "control", item.controlDevice, index)}
                    </TableCell>
                    <TableCell align="right" className={classes.pairCell}>
                      {canWrite &&
                        <IconButton
                          type="delete"
                          onClick={() => handleModal_delete(index)} 
                          className={classes.cellButton}
                        />
                      }
                    </TableCell>
                  </TableRow>
                )
              }
            })}
          </Table>          
          {canWrite &&
            <Button
              className={clsx(classes.buttonSecondary, classes.addButton)}
              startIcon={<AddIcon />} 
              onClick={()=>handleAddConfig()}
            >
              Add New
            </Button>
          }
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Card>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this pairing configuration? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleModal_alert()} />
    </React.Fragment>
  );
}
