import {
  border,
  cardRadius,
  button,
  buttonSecondary,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  link
} from "assets/jss/components/commonStyle.js";

const modalStyle = () => ({
  button,
  buttonSecondary,
  link,
  modal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    textAlign: "center",
    width: "max-content"
  },
  paper: {
    ...cardRadius,
    padding: "1.667vw 1.979vw",
    width: "28.021vw",
  },
  icon: {
    margin: "auto",
    width: "max-content",
    marginBottom: "1.302vw",
  },
  title: {
    marginBottom: "0.260vw",
    fontSize: "1.667vw",
    color: primaryColor[3],
  },
  content: {
    marginBottom: "2.552vw",
    color: "rgba(19, 50, 43, 0.75)",
    marginRight: "-3.333vw",
    paddingRight: "3.333vw",
  },
  actions: {
    '& button:not(:first-child)': {
      marginLeft: "0.833vw",
    }
  },

  // Alert
  alert: {
    display: "flex",
    alignItems: "flex-start",
    margin: "auto",
    width: "max-content"
  },
  alertPaper: {
    ...cardRadius,
    width: "32.552vw"
  },
  alertTitle: {
    padding: "1.563vw",
    marginBottom: "0.260vw",
    fontSize: "1.250vw",
    fontWeight: 500
  },
  alertContent: {
    padding: "1.563vw",
  },
  alertActions: {
    padding: "1.563vw",
    float: "right",
    '& button:not(:first-child)': {
      marginLeft: "0.833vw",
    }
  },

  // Cropper
  cropperPaper: {
    width: "31.250vw"
  },
  cropperContainer: {
    backgroundColor: primaryColor[1],
    margin: "0 -3.333vw 2.552vw",
  },
  cropper: {
    position: "relative !important",
    height: "50vh",
  },

  // View More
  moreList: {
    border,
    ...cardRadius,
    height: "13.021vw",
    overflow: "overlay",
    marginTop: "1.250vw"
  }
});

export default modalStyle;
  