// Product Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import Checkbox from "shared-components/Checkbox/Checkbox";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { checkPermission } from "common/functions";
import { rowsPerTable } from "config";
import { reset } from "./store/search";
import { setSearchText } from "store/general";
import { productTableHead, configureProductCategoryTypeTableHead, configureProductCategoryTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchProduct, DeleteProduct, DeleteProductCategory, DeleteProductCategoryType, GetAllProductGroup } from "services/AdminPortal/ProductService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchProductResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const searchResultsCount = useSelector(store => store.admin.product.search.searchResultsCount);
  const searchResults = useSelector(store => store.admin.product.search.searchResults);
  const allProductGroup = useSelector(store => store.admin.product.lookup.allProductGroup);
  const [order, setOrder] = React.useState({product: 'asc', category: 'asc', type: 'asc'});
  const [orderBy, setOrderBy] = React.useState({product: 'productID', category: 'productCategory', type: 'productCategoryType'});
  const [page, setPage] = React.useState({product: 0, category: 0, type: 0});
  const [id, setId] = React.useState(null);
  const [table, setTable] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState({product: false, category: false, type: false});

  const productCount = Math.ceil(searchResultsCount.productList / rowsPerTable);
  const categoryCount = Math.ceil(searchResultsCount.productCategoryList / rowsPerTable);
  const typeCount = Math.ceil(searchResultsCount.productCategoryTypeList / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const tableHead = () => {
    if (checkPermission("Admin.Products (Mix Product)", "none")) {
      return _.filter(configureProductCategoryTableHead, ({id}) => {
        return id !== "combinationProduct";
      });
    }
    return configureProductCategoryTableHead;
  }

  const handleRequestSortProduct = (e, property) => {
    const isAsc = orderBy.product === property && order.product === 'asc';
    setOrder({...order, product: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, product: property});
  };
  
  const handleRequestSortType = (e, property) => {
    const isAsc = orderBy.type === property && order.type === 'asc';
    setOrder({...order, type: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, type: property});
  };
  
  const handleRequestSortCategory = (e, property) => {
    const isAsc = orderBy.category === property && order.category === 'asc';
    setOrder({...order, category: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, category: property});
  };

  const handleModal = (table, id) => {
    setId(id);
    setTable(table);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    if (table === "product") {
      dispatch(DeleteProduct(id))
      .then(() =>{
        dispatch(SearchProduct(params.keyword));
        resetState();
      })
    } else if (table === "type") {
      dispatch(DeleteProductCategoryType(id))
      .then(() =>{
        dispatch(SearchProduct(params.keyword));
        resetState();
      })
    } else if (table === "category") {
      dispatch(DeleteProductCategory(id))
      .then(() =>{
        dispatch(SearchProduct(params.keyword));
        resetState();
      })
    }
  }

  const getProductGroupName = (id) => {
    if (allProductGroup && id !== 0) {
      return _.find(allProductGroup, {"id": id}).productGroupName;
    }
  }

  const resetState = () => {
    setId(null);
    setPage({product: 0, category: 0, type: 0});
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
  dispatch(reset());
    dispatch(SearchProduct(params.keyword));
  },[params.keyword]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllProductGroup());
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  const totalCount = _.isEmpty(searchResultsCount) ? 0 : Object.values(searchResultsCount).reduce((a, b) => a + b);
  const totalTableCount = Object.keys(searchResults).length;
  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(totalCount) + " Search Result(s) From " + totalTableCount + " Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount.productList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.productList + " Result(s) From Product List"}
              <IconButton 
                className={collapsed.product ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, product: !collapsed.product})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.product && 
            <React.Fragment>
              <div className={classes.table}>
                <Table
                  header={filterActionTableHead(productTableHead, canWrite)}
                  order={order.product}
                  orderBy={orderBy.product}
                  onRequestSort={handleRequestSortProduct}
                >
                  { _.orderBy(searchResults.productList, [orderBy.product], [order.product])
                  .slice(page.product * rowsPerTable, page.product * rowsPerTable + rowsPerTable)
                  .map((product,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{highlightValue(product.productID)}</TableCell>
                        <TableCell>{highlightValue(product.productName)}</TableCell>
                        <TableCell>{highlightValue(product.productDesc)}</TableCell>
                        <TableCell>
                          <Link to={{pathname: "/admin/products/process/"+product.id, state: {prevPath: location.pathname}}}>
                            <span className={classes.link}>Details</span>
                          </Link>
                        </TableCell>
                        <TableCell>NIL</TableCell>
                        <TableCell align="right">
                          {canWrite &&
                            <React.Fragment>
                              <Link to={{pathname: "/admin/products/"+product.id}}>
                                <CustomIconButton type="edit" />
                              </Link>
                              <CustomIconButton
                                type="delete" 
                                onClick={() => handleModal("product", product.id)}
                              />
                            </React.Fragment>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })} 
                </Table>
              </div>
              { productCount > 1 &&
                <Pagination 
                  count={productCount} 
                  page={page.product+1}
                  onChange={(e, pageNo)=>setPage({...page, product: pageNo-1})}
                />
              }
            </React.Fragment>
          }
        </Card>
      }
      { searchResultsCount.productCategoryList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.productCategoryList + " Result(s) From Product Category"}
              <IconButton 
                className={collapsed.category ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, category: !collapsed.category})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.category && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(tableHead(), canWrite)}
                order={order.category}
                orderBy={orderBy.category}
                onRequestSort={handleRequestSortCategory}
              >
                { _.orderBy(searchResults.productCategoryList, [orderBy.category], [order.category])
                .slice(page.category * rowsPerTable, page.category * rowsPerTable + rowsPerTable)
                .map((category,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{highlightValue(category.productCategoryName)}</TableCell>
                      <TableCell>{highlightValue(category.productCategoryShortForm)}</TableCell>
                      {!checkPermission("Admin.Products (Mix Product)", "none") &&
                        <TableCell align="center">
                          <Checkbox 
                            checked={category.combinationProduct}
                            disabled
                          />
                        </TableCell>
                      }
                      <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/products/category/"+category.id, state: {prevPath: location.pathname+location.search}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton
                              type="delete" 
                              onClick={() => handleModal("category", category.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { categoryCount > 1 &&
              <Pagination 
                count={categoryCount} 
                page={page.category+1}
                onChange={(e, pageNo)=>setPage({...page, category: pageNo-1})}
              />
            }
          </React.Fragment>
          }
        </Card>
      }
      { searchResultsCount.productCategoryTypeList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.productCategoryTypeList + " Result(s) From Product Category Type"}
              <IconButton 
                className={collapsed.type ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, type: !collapsed.type})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.type && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(configureProductCategoryTypeTableHead, canWrite)}
                order={order.type}
                orderBy={orderBy.type}
                onRequestSort={handleRequestSortType}
              >
                { _.orderBy(searchResults.productCategoryTypeList, [orderBy.type], [order.type])
                .slice(page.type * rowsPerTable, page.type * rowsPerTable + rowsPerTable)
                .map((type,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{highlightValue(type.productCategoryTypeName)}</TableCell>
                      <TableCell>{highlightValue(type.productCategoryTypeShortForm)}</TableCell>
                      <TableCell>{allProductGroup.length>0?highlightValue(getProductGroupName(type.productGroupId)):null}</TableCell>
                      {type.productMixList && <TableCell>{type.productMixList}</TableCell>}
                      <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/products/type/"+type.id, state: {prevPath: location.pathname+location.search, editData: type}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton
                              type="delete" 
                              onClick={() => handleModal("type", type.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { typeCount > 1 &&
              <Pagination 
                count={typeCount} 
                page={page.type+1}
                onChange={(e, pageNo)=>setPage({...page, type: pageNo-1})}
              />
            }
          </React.Fragment>
          }
        </Card>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
