// Farm Capacity
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers } from "common/functions";
import { setSearchText } from "store/general";
import { farmCapacityTableHead } from "enums/AdminPortal/TableHeaders";
import { GetCurrentFarmCapacity, GetCapacityCategoryByFarmId } from "services/AdminPortal/CapacityService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/AdminPortal/capacityStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function FarmCapacity() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const farmCapacity = useSelector(store => store.admin.capacity.farmCapacity.farmCapacity);
  const categories = useSelector(store => store.admin.capacity.farmCapacity.categories);
  const [opened, setOpened] = React.useState({});

  const handleButtonClick_collapse = (index) => {
    const prevState = opened[index];
    setOpened({...opened, [index]: !prevState})
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetCurrentFarmCapacity());
    dispatch(GetCapacityCategoryByFarmId());
  },[]);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const farmId = _.find(userDetail.farms, "isActive").id;
  return (
    <React.Fragment>
      <Card title = "Current Farm Capacity & Cages">
        <Grid container spacing={4} className={classes.content}>
          <Grid item xs={6}>
            <Card 
              classes={{
                root: clsx(classes.cardPaper, classes.marginBottom),
                title: clsx(classes.cardTitle, classes.cardHeader),
                content: classes.farmCapacityContent
              }}
              title={
                <React.Fragment>
                  Current Farm Capacity
                  {canWrite &&
                    // Link to Farm edit
                    <Link to={{pathname: "/admin/farm/"+farmId, state: {step: 1}}} className={classes.headerButton}>
                      <CustomIconButton type="edit" />
                    </Link>
                  }
                </React.Fragment>
              }
            >
              <Table 
                className={classes.table}
                header={farmCapacityTableHead}
              >
                { farmCapacity.banks && farmCapacity.banks.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.bankName}</TableCell>
                      <TableCell align="center">{formatNumbers(item.numberOfBays)}</TableCell>
                      <TableCell align="center">{formatNumbers(item.numberOfLevels)}</TableCell>
                      <TableCell align="center">{formatNumbers(item.numberOfCages)}</TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </Card>
            <Card 
              classes={{
                root: classes.cardPaper,
                title: classes.cardTitle,
                content: classes.calculationContent
              }}
              title="Calculation of Cages"
            >
              <Table className={classes.table}>
                <TableRow />
                { farmCapacity.calculationCages && farmCapacity.calculationCages.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.calculationDesc}>{item.description}:</TableCell>
                      <TableCell>{formatNumbers(item.count)}</TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card 
              classes={{
                root: classes.cardPaper,
                title: clsx(classes.cardTitle, classes.cardHeader),
                content: classes.categoryCardContent
              }}
              title={
                <React.Fragment>
                  Cages Categories
                  {canWrite && 
                    // Link to Cages Categories edit
                    <CustomIconButton type="edit" className={classes.headerButton} onClick={()=>history.push(location.pathname+"/edit")} />
                  }
                </React.Fragment>
              }
            >
              { categories.map((item, index) => {
                return (
                  <Card
                    key={index}
                    classes={{
                      root: clsx(classes.cardPaper, classes.collapsePaper),
                      title: clsx(classes.cardTitle, classes.cardHeader),
                      content: classes.listCardContent
                    }}
                    title={
                      <React.Fragment>
                        {item.capacityCategoryName}
                        <IconButton 
                          className={!opened[index] ? clsx(classes.collapsedIcon, classes.headerButton) : clsx(classes.collapsedIcon, classes.rotate, classes.headerButton)}
                          onClick={() => handleButtonClick_collapse(index)}
                        >
                          <img className={classes.icon} src={arrow} alt="arrow" />
                        </IconButton>
                      </React.Fragment>
                    }
                    subheader={
                      <Grid container spacing={4} className={classes.cardSubheader}>
                        <Grid item xs={6}><Typography>Product / Group: {item.productList.map((product) => {return product.productName}).join(", ")}</Typography></Grid>
                        <Grid item xs={6}><Typography className="text-right">Process: {item.productProcess}</Typography></Grid>
                      </Grid>
                    }
                  >
                    { opened[index] &&
                      <Typography className={classes.listTitle}>{formatNumbers(item.capacities.length)} Cages Selected</Typography>
                    }
                  </Card>
                )
              })}
            </Card>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
