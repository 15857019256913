import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
// core components
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from "shared-components/Modal/Modal";

import { GetAllDriver, GetAllVehicles, GetDeliveryTripLookUp, GetDeliveryOrderLookUp } from "services/UserPortal/DeliveryService";

import search from "assets/icons/grey/search.svg";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

export default function FilterModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const driverList = useSelector(store => store.user.delivery.master.driverList);
  const vehicleList = useSelector(store => store.user.delivery.master.vehicleList);
  const tripList = useSelector(store => store.user.delivery.master.tripList);
  const doList = useSelector(store => store.user.delivery.master.doList);
  const [filterTab, setFilterTab] = React.useState(0);
  const [newFilter, setNewFilter] = React.useState({search: "", driverId: [], vehicleId: [], tripStatus: [], deliveryTripId: [], deliveryOrderId: []}); // for new filter
  const [filteredDriverList, setFilteredDriverList] = React.useState([]);
  const [filteredVehicleList, setFilteredVehicleList] = React.useState([]);
  const [filteredTripList, setFilteredTripList] = React.useState([]);
  const [filteredDOList, setFilteredDOList] = React.useState([]);

  const statusList = ["Pending", "In Progress", "Completed"];

  const handleOnChange_filter = (value) => {
    setFilterTab(value);
    setNewFilter({...newFilter, search: ""});
    setFilteredDriverList([]);
    setFilteredVehicleList([]);
    setFilteredTripList([]);
    setFilteredDOList([]);
  };

  const handleModal = () => {
    setNewFilter({search: "", driverId: [], vehicleId: [], tripStatus: [], deliveryTripId: [], deliveryOrderId: []});
    props.onClose();
  }

  const handleButtonClick_clear = () => {
    props.onClose();
    props.setFilter({driverId: [], vehicleId: [], tripStatus: [], deliveryTripId: [], deliveryOrderId: [], page: 0});
  }

  const handleButtonClick_filter = () => {
    props.onClose();
    props.setFilter({..._.omit(newFilter, ["search"]), page: 0});
  }

  const handleButtonClick_checkbox = (e, field) => {
    if (newFilter[field].length <= 5) {
      let payload = _.cloneDeep(newFilter[field]);
      const index = payload.findIndex((item) => item === e.target.value);
      if (index !== -1) {
        payload.splice(index,1);
      } else {
        payload = _.union(payload, [e.target.value]);
      }
      setNewFilter({...newFilter, [field]: payload});
    }
  };

  const handleOnSearch = (e, field) => {
    e.preventDefault();
    if (field === "driver") {
      setFilteredDriverList(driverList.filter(({name})=>name.toLowerCase().includes(newFilter.search)));
    } else if (field === "vehicle") {
      setFilteredVehicleList(vehicleList.filter(({vehicleRefNo})=>vehicleRefNo.toLowerCase().includes(newFilter.search)));
    } else if (field === "trip") {
      setFilteredTripList(tripList.filter(({deliveryTripRefNo})=>deliveryTripRefNo.toLowerCase().includes(newFilter.search)));
    } else if (field === "deliveryOrder") {
      setFilteredDOList(doList.filter(({deliveryOrderRefNo})=>deliveryOrderRefNo.toLowerCase().includes(newFilter.search)));
    }
  };

  const renderFilter = () => {
    switch (filterTab) {
      case 0:
        return (
          <React.Fragment>
            <form onSubmit={(e) => handleOnSearch(e, "driver")}>
              <TextField 
                id="driver"
                variant="outlined" 
                placeholder="Search Driver" 
                value={newFilter.searchValue}
                InputProps={{
                  startAdornment: 
                    <InputAdornment position="start">
                      <img src={search} alt="search" className={classes.icon_24}/>
                    </InputAdornment>,
                }}
                onChange={(e) => setNewFilter({...newFilter, search: e.target.value})}
              />
            </form>
            <List className={classes.list}>
              {_.map(filteredDriverList.length ? filteredDriverList : driverList, (item, index)=>{
                return (
                  <ListItem key={index} classes={{gutters: classes.listPadding}}>
                    <Checkbox 
                      value={item.id} 
                      checked={newFilter.driverId.includes(String(item.id))} 
                      onChange={(e)=>handleButtonClick_checkbox(e, "driverId")}
                    /> {item.name}
                  </ListItem>
                )
              })}
            </List>
          </React.Fragment>
        )
      case 1:
        return (
          <React.Fragment>
            <form onSubmit={(e) => handleOnSearch(e, "vehicle")}>
              <TextField 
                id="vehicle"
                variant="outlined" 
                placeholder="Search Vehicle" 
                value={newFilter.searchValue}
                InputProps={{
                  startAdornment: 
                    <InputAdornment position="start">
                      <img src={search} alt="search" className={classes.icon_24}/>
                    </InputAdornment>,
                }}
                onChange={(e) => setNewFilter({...newFilter, search: e.target.value})}
              />
            </form>
            <List className={classes.list} id="scrollableDiv">
              {_.map(filteredVehicleList.length ? filteredVehicleList : vehicleList, (item, index)=>{
                return (
                  <ListItem key={index} classes={{gutters: classes.listPadding}}>
                    <Checkbox 
                      value={item.id} 
                      checked={newFilter.vehicleId.includes(String(item.id))} 
                      onChange={(e)=>handleButtonClick_checkbox(e, "vehicleId")}
                    /> {item.vehicleRefNo}
                  </ListItem>
                )
              })}
            </List>
          </React.Fragment>
        )
      case 2:
        return (
          <React.Fragment>
            <List className={classes.list} id="scrollableDiv">
              {_.map(statusList, (item, index)=>{
                return (
                  <ListItem key={index} classes={{gutters: classes.listPadding}}>
                    <Checkbox 
                      value={item} 
                      checked={newFilter.tripStatus.includes(String(item))} 
                      onChange={(e)=>handleButtonClick_checkbox(e, "tripStatus")}
                    /> {item}
                  </ListItem>
                )
              })}
            </List>
          </React.Fragment>
        )
      case 3:
        return (
          <React.Fragment>
            <form onSubmit={(e) => handleOnSearch(e, "trip")}>
              <TextField 
                id="trip"
                variant="outlined" 
                placeholder="Search Trip" 
                value={newFilter.searchValue}
                InputProps={{
                  startAdornment: 
                    <InputAdornment position="start">
                      <img src={search} alt="search" className={classes.icon_24}/>
                    </InputAdornment>,
                }}
                onChange={(e) => setNewFilter({...newFilter, search: e.target.value})}
              />
            </form>
            <List className={classes.list} id="scrollableDiv">
              {_.map(filteredTripList.length ? filteredTripList : tripList, (item, index)=>{
                return (
                  <ListItem key={index} classes={{gutters: classes.listPadding}}>
                    <Checkbox 
                      value={item.id} 
                      checked={newFilter.deliveryTripId.includes(String(item.id))} 
                      onChange={(e)=>handleButtonClick_checkbox(e, "deliveryTripId")}
                    /> {item.deliveryTripRefNo}
                  </ListItem>
                )
              })}
            </List>
          </React.Fragment>
        )
      case 4:
        return (
          <React.Fragment>
            <form onSubmit={(e) => handleOnSearch(e, "deliveryOrder")}>
              <TextField 
                id="deliveryOrder"
                variant="outlined" 
                placeholder="Search Delivery Order" 
                value={newFilter.searchValue}
                InputProps={{
                  startAdornment: 
                    <InputAdornment position="start">
                      <img src={search} alt="search" className={classes.icon_24}/>
                    </InputAdornment>,
                }}
                onChange={(e) => setNewFilter({...newFilter, search: e.target.value})}
              />
            </form>
            <List className={classes.list} id="scrollableDiv">
              {_.map(filteredDOList.length ? filteredDOList : doList, (item, index)=>{
                return (
                  <ListItem key={index} classes={{gutters: classes.listPadding}}>
                    <Checkbox 
                      value={item.deliveryOrderId} 
                      checked={newFilter.deliveryOrderId.includes(String(item.deliveryOrderId))} 
                      onChange={(e)=>handleButtonClick_checkbox(e, "deliveryOrderId")}
                    /> {item.deliveryOrderRefNo}
                  </ListItem>
                )
              })}
            </List>
          </React.Fragment>
        )
    }
  };

  React.useEffect(() => {
    setNewFilter({search: "", ...props.filter})
  },[props.filter]);

  React.useEffect(() => {
    dispatch(GetAllDriver());
    dispatch(GetAllVehicles());
    dispatch(GetDeliveryTripLookUp());
    dispatch(GetDeliveryOrderLookUp())
  },[]);

  return (
    <React.Fragment>
      <Modal 
        {...props} 
        onClose={()=>handleModal()}
        className={classes.filterModal}
        title="Master List Filter"
        content={
          <div className={classes.filterContent}>
            <Tabs 
              classes={{
                indicator: classes.filterIndicator
              }}
              orientation="vertical"
              value={filterTab} 
              onChange={(e, value) => handleOnChange_filter(value)}
            >
              <Tab className={filterTab === 0 ? clsx(classes.filterTabLabel, classes.selectedFilter) : classes.filterTabLabel} label={"Driver ("+driverList.length+")"} />
              <Tab className={filterTab === 1 ? clsx(classes.filterTabLabel, classes.selectedFilter) : classes.filterTabLabel} label={"Vehicle ("+vehicleList.length+")"} />
              <Tab className={filterTab === 2 ? clsx(classes.filterTabLabel, classes.selectedFilter) : classes.filterTabLabel} label="Status (3)" />
              <Tab className={filterTab === 3 ? clsx(classes.filterTabLabel, classes.selectedFilter) : classes.filterTabLabel} label={"Trip Number ("+tripList.length+")"}/>
              <Tab className={filterTab === 4 ? clsx(classes.filterTabLabel, classes.selectedFilter) : classes.filterTabLabel} label={"DO Number ("+doList.length+")"}/>
            </Tabs>
            <Paper elevation={0} className={classes.filterPaper}>
              {renderFilter()}
            </Paper>
          </div>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleButtonClick_clear()}>Clear Filter</Button>
          <Button 
            className={classes.button}
            onClick={()=>handleButtonClick_filter()}
          >
            Apply Filter
          </Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
