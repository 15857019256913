import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllDeliveryOrderCreationList, GetDeliveryOrderCreationDetailLink, GetAllSaleOrder } from "services/UserPortal/FinishedGoodsService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  doCreationList: [],
  detailTotalCount: 0,
  doCreationDetailList: [],
  saleOrderList: []
};

const doCreation = createSlice({
  name: "doCreation",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllDeliveryOrderCreationList.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.doCreationList = action.payload.result.items;
    },
    [GetDeliveryOrderCreationDetailLink.fulfilled]: (state, action) => {
      state.detailTotalCount = action.payload.result.totalCount;
      state.doCreationDetailList = action.payload.result.items;
    },
    [GetAllSaleOrder.fulfilled]: (state, action) => {
      state.saleOrderList = _.orderBy(action.payload.result.items, ["saleOrderNumber"], ["desc"]);
    },
  },
});

// export actions
export const { setValues, reset } = doCreation.actions;

// export the reducer
export default doCreation.reducer;