import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// @mui/icons-material components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// core components
import styles from "assets/jss/shared-components/accordionStyle.js";

const useStyles = makeStyles(styles);
export default function CustomAccordion({header, children, ...props}) {
  const classes = useStyles();

  return (
    <Accordion 
      {...props}
      className={props.classes ? clsx(props.classes.root, classes.accordion) : classes.accordion}
      elevation={0}
    >
      <AccordionSummary
        classes={{content: props.classes.summary}}
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
      >
        <div className={props.classes ? clsx(props.classes.header, classes.header) : classes.header}>{header}</div>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

CustomAccordion.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};