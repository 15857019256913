import {
  icon_24,
  icon_38,
  icon_48,
  buttonSecondary,
  blackColor,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  link
} from "assets/jss/components/commonStyle.js";

const bankStyle = {
  link,
  icon_24,
  colWidth: {
    "& > td:not(:first-child)": {
      width: "23.438vw",
      minWidth: "10.417vw",
      maxWidth: "23.438vw"
    },
    "& > td:first-child": {
      width: "3.646vw",
      maxWidth: "3.646vw"
    }
  },
  tagColWidth: {
    "& > td:not(:first-child)": {
      width: "3.75vw",
      minWidth: "3.75vw",
      maxWidth: "3.75vw",
    },
    "& > td:first-child": {
      width: "3.646vw",
      maxWidth: "3.646vw",
    }
  },
  labelFont: {
    fontSize: "0.729vw",
    fontWeight: 500
  },
  cell: {
    border: "0.104vw solid #A5A7A5",
  },
  cell2: {
    border: "0.104vw solid "+primaryColor[3],
  },
  cell3: {
    border: "0.104vw solid "+primaryColor[3],
    padding: "0 !important"
  },
  tagCell: {
    border: "0.104vw solid #A5A7A5",
    cursor: "default"
  },
  markerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:not(:last-child)": {
      marginRight: "0.833vw"
    }
  },
  marker: {
    ...icon_38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: whiteColor,
    border: "0.208vw solid " + secondaryColor[0],
    borderRadius: "50%",
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
  },
  markerText: {
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
  },
  titleText: {
    color: primaryColor[3],
    fontWeight: 500,
  },
  listText: {
    color: primaryColor[3],
  },
  clickable: {
    cursor: "pointer"
  },
  tagPaper: {
    maxHeight: "12vw",
    width: "14vw",
    overflow: 'overlay',
    backgroundColor: "white",
    borderRadius: "0.833vw",
    boxShadow: "none",
    border: "0.052vw solid " + blackColor,
    padding: "0.833vw 1.250vw"
  },
  detailItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  // climate
  cellItem: {
    backgroundColor: primaryColor[1],
    padding: "0.833vw"
  },
  cellItem2: {
    padding: "0.25vw 0.833vw"
  },
  off: {
    backgroundColor: "#13322B66",
    color: whiteColor,
  },
  notUse: {
    backgroundColor: "#112A11",
    color: whiteColor
  },
  on: {
    backgroundColor: primaryColor[9],
  },
  selected: {
    backgroundColor: secondaryColor[0]
  },
  notAvailable: {
    backgroundColor: primaryColor[1],
    cursor: "context-menu",
  },
  details: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: "0.833vw",
    color: primaryColor[8],
    "& :first-child": {
      marginRight: "0.833vw"
    },
  },
  black: {
    color: blackColor,
  },
  notAvailableIcon: {
    ...icon_48,
    margin: "auto"
  },
  progressBar: {
    width: "3.646vw",
    height: "0.521vw",
    borderRadius: "0.260vw",
    border: "0.052vw solid "+primaryColor[8],
    backgroundColor: primaryColor[8],
    marginRight: "0.521vw"
  },
  progressBar2: {
    border: "0.052vw solid "+blackColor,
    backgroundColor: blackColor,
  },
  progress: {
    backgroundColor: primaryColor[6],
  },
  infoIcon: {
    marginLeft: "1.750vw"
  },

  // capacity
  reserved: {
    backgroundColor: "#a1adaa",
    color: whiteColor
  },
  partially: {
    backgroundColor: primaryColor[6],
  },
  fully: {
    backgroundColor: primaryColor[7],
  },
  unassigned: {
    backgroundColor: primaryColor[1],
    color: whiteColor
  },
  capacityCell: {
    position: "relative",
    cursor: "default",
    "&:hover > div": {
      opacity: 1
    }
  },
  hoverEvent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#00000080",
    top: 0,
    left: 0,
    width: "-webkit-fill-available",
    height: "100%",
    opacity: 0,
  },
  viewButton: {
    ...buttonSecondary,
    padding: "0 0.521vw",
    // marginTop: "0.521vw",
    minWidth: "fit-content"
  },

  // alarm
  alarmContainer: {
    position: "relative",
  },
  alarm: {
    position: "absolute",
    width: "11.302vw",
    transform: "translate(-2.604vw, -5.990vw)",
    backgroundColor: secondaryColor[0],
    border: "0.208vw solid "+secondaryColor[0],
    textAlign: "center",
    zIndex: 9999
  },
  alarmTitle: {
    paddingTop: "0.729vw",
    paddingBottom: "0.521vw",
    color: secondaryColor[0],
    backgroundColor: whiteColor,
  },
  alarmDetail: {
    padding: "0.521vw 0.729vw",
    color: whiteColor,
    cursor: "pointer"
  },
  arrow: {
    transform: "translate(2.083vw, -0.521vw)",
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: whiteColor,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: secondaryColor[0],
      transform: "rotate(45deg)",
      transformOrigin: "100% 0",
    }
  },
}

export default bankStyle;