import {
  cardRadius,
  border,
  icon_38,
  icon_64,
  button,
  buttonSecondary,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  whiteTable,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const farmStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  orange,

  // Search Farm
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display farm
  icon,
  helperIcon: {
    ...icon_38,
    padding: 0,
  },
  table,
  whiteTable,
  tableName: {
    width: "11.250vw"
  },
  tableAddress: {
    width: "28.646vw"
  },
  alignTextfield: {
    marginTop: "1.400vw"
  },
  helpButton: {
    margin: "-0.625vw 0 -0.625vw 0.833vw",
    padding: 0
  },
  detail: {
    color: secondaryColor[0],
    cursor: "pointer",
    marginLeft: "1.042vw",
  },

  // Add Edit Farm
  stepper: {
    width: "24.323vw"
  },
  title: {
    display: "flex",
    alignItems: "center"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  subContent: {
    width: "100%",
    alignSelf: "center",
    '&:first-child': {
      marginRight: "0.781vw",
    },
    '&:last-child': {
      marginLeft: "0.781vw",
    },
    '&:not(:first-child):not(:last-child)': {
      margin: "0 0.781vw",
    },
  },
  label: {
    margin: "2.083vw 0 0.365vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  rightPaper: {
    ...cardRadius,
    border,
    height: "16.250vw"
  },
  map: {
    ...cardRadius,
    height: "100%",
  },
  textfieldCell: {
    verticalAlign: "top"
  },
  bankName: {
    minWidth: "27.708vw"
  },
  cellButton: {
    marginTop: "0.208vw",
  },
  bankButton: {
    marginTop: "0.938vw"
  },
  actions: {
    justifyContent: "space-between"
  },
}

export default farmStyle;