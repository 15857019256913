import { createSlice } from "@reduxjs/toolkit";

import { GetLightPrediction, GetLightingType, GetFarmLocation, LightingPowerAndPercentagePrediction } from "services/UserPortal/PredictionService";

// initial state
const initialState = {
  lighting: {},
  results: {},
  // lookup
  lightingType: [],
  farmLocation: [],
};

// create reducer and action creators
const prediction = createSlice({
  name: "prediction",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.lighting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetLightPrediction.fulfilled]: (state, action) => {
      state.results = action.payload;
    },
    [LightingPowerAndPercentagePrediction.fulfilled]: (state, action) => {
      state.results = action.payload;
    },
    // lookup
    [GetLightingType.fulfilled]: (state, action) => {
      state.lightingType = action.payload;
    },
    [GetFarmLocation.fulfilled]: (state, action) => {
      state.farmLocation = action.payload;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = prediction.actions;

// export the reducer
export default prediction.reducer;