import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import FilterButton from "shared-components/Button/FilterButton";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { setValues } from "../store/disposal";
import { disposalListTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllDisposalList, GetAllSaleOrder } from "services/UserPortal/FinishedGoodsService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import styles from "assets/jss/components/UserPortal/finishedGoodsStyle.js";

const useStyles = makeStyles(styles);

export default function DisposalList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.finishedGoods.disposal.filter);
  const totalCount = useSelector(store => store.user.finishedGoods.disposal.totalCount);
  const result = useSelector(store => store.user.finishedGoods.disposal.disposeList);
  const productList = useSelector(store => store.common.productList);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');
  const [newFilter, setNewFilter] = React.useState({saleOrderId: null, page: 0});

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };
  
  const getDisposalList = (stopLoading) => {
    dispatch(GetAllDisposalList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getDisposalList();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllSaleOrder());
    dispatch(GetProductList());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getDisposalList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title="Disposal List"
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "productId") {
                const product = productList.find(({id}) => id == filter[key]);
                label = product && product.productName+"("+product.productRefNo+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the disposal list
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.masterTable}
            header={disposalListTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.productRefNo}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.disposedQty))}{item.uom}</TableCell>
                  {/* <TableCell>{formatNumbers(roundTo2Decimal(item.value))}</TableCell> */}
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
