import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterActionTableHead } from "common/functions";
import { setSelectedBatch, setSelectedRMID } from "../store/management";
import { batchListTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllRawMaterialBatch, GetAllRawMaterialBatchInfo } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";
import AddEditBatch from "./AddEditBatch";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function BatchList(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.rawMaterial.managementRM.batchCount);
  const batchList = useSelector(store => store.user.rawMaterial.managementRM.batchList);
  const batchRefList = useSelector(store => store.user.rawMaterial.managementRM.batchRefList);
  const supplierList = useSelector(store => store.common.supplierList);
  const warehouseList = useSelector(store => store.common.warehouseList);
  const [filter, setFilter] = React.useState({batchId: null, status: null, page: 0});
  const [newFilter, setNewFilter] = React.useState({batchId: null, status: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('batchRefNo');
  const [addEditBatch, setAddEditBatch] = React.useState(props.location.state && props.location.state.edit ? true : false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleEdit = (item) => {
    setAddEditBatch(true);
    dispatch(setSelectedBatch(item));
  }

  const handleButtonClick_goBack = () => {
    history.push("/user/raw-material/management");
    dispatch(setSelectedRMID(""));
  }

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      setFilter({...filter, [field]: moment(value).format("YYYY-MM-DD"), page: 0});
    } 
  };

  const handleButtonClick_deleteFilter = (key) => {
    setFilter({...filter, [key]: null, page: 0});
  };

  const displayRackList = (list) => {
    let result = [];
    if (list) {
      for (let i = 0; i < list.length; i++) {
        result.push(
          <Typography key={i}>
            {list[i]}
          </Typography>
        );
      }
    }
    return result;
  }

  const getAllRawMaterialBatch = (stopLoading) => {
    if (!addEditBatch) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "management");
      dispatch(setSelectedRMID(path[index+1]));
      dispatch(GetAllRawMaterialBatch({...filter, rawMaterialId: path[index+1], stopLoading}))
      .then(({error}) => {
        if (error) {
          history.push("/user/raw-material/management");
        }
      });
    }
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllRawMaterialBatch();
    }
  },[filter, addEditBatch]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllRawMaterialBatchInfo());
  },[]);

  useInterval(() => {
    getAllRawMaterialBatch(true);
  });

  if(addEditBatch) {
    return (
      <AddEditBatch addEditBatch={addEditBatch} setAddEditBatch={setAddEditBatch}/>
    )
  } else {
    const prop = props.location.state;
    return (
      <React.Fragment>
        <Card 
          title="Batch List"
          subheader={
            <React.Fragment>
              <Typography className={classes.subheader}>Raw Material Checking Now: {prop && prop.rawMaterialName}</Typography>
              { Object.keys(filter).map((key) => {
                if (filter[key] && key !== "page") {
                  let label = filter[key];
                  if (key === "batchRefNo") {
                    const batch = batchRefList.find(({batchRefNo}) => batchRefNo == filter[key]);
                    label = batch && batch.batchRefNo;
                  }
                  if (key === "warehouseId") {
                    const warehouse = warehouseList.find(({id}) => id == filter[key]);
                    label = warehouse && warehouse.warehouseName+"("+warehouse.warehouseId+")";
                  }
                  if (key === "supplierId") {
                    const supplier = supplierList.find(({id}) => id == filter[key]);
                    label = supplier && supplier.supplierName+"("+supplier.supplierId+")";
                  }
                  if (!key.toLowerCase().includes("date")) {
                    return (
                      <Tag
                        key={key}
                        variant="outlined"
                        tabIndex={-1}
                        label={label}
                        className={classes.tag}
                        onDelete={() => handleButtonClick_deleteFilter(key)}
                      />
                    )
                  }
                }
              })}
            </React.Fragment>
          }
          action={
            <React.Fragment>
              <FilterButton 
                setFilter={()=>setFilter({...newFilter, page: 0})}
                filter={filter}
                setNewFilter={setNewFilter}
                content={
                  <React.Fragment>
                    Choose one or all filters to search through the batch list
                    <Select
                      className={classes.filterDropdown}
                      name="batchRefNo"
                      onChange={(e)=>handleOnChange_select(e)}
                      placeholder="Select a batch reference number"
                      value={renderValue(newFilter.batchRefNo)}
                    >
                      {batchRefList && batchRefList.map((item, index) => {
                        return <MenuItem key={index} value={item.batchRefNo}>{item.batchRefNo}</MenuItem>;
                      })} 
                    </Select>
                    <Select
                      className={classes.filterDropdown}
                      name="warehouseId"
                      onChange={(e)=>handleOnChange_select(e)}
                      placeholder="Select a warehouse"
                      value={renderValue(newFilter.warehouseId)}
                    >
                      {warehouseList && warehouseList.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                      })} 
                    </Select>
                    <Select
                      className={classes.filterDropdown}
                      name="supplierId"
                      onChange={(e)=>handleOnChange_select(e)}
                      placeholder="Select a supplier"
                      value={renderValue(newFilter.supplierId)}
                    >
                      {supplierList && supplierList.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>{item.supplierName} ({item.supplierId})</MenuItem>;
                      })} 
                    </Select>
                  </React.Fragment>
                }
              />
              <DateTimePicker
                closeOnSelect
                clearable
                placeholder="Creation Date"
                className={classes.cardActionDropdown}
                value={filter.dateOfCreation}
                onChange={(e) => handleOnChange_date(e, "dateOfCreation")}
                onClear={()=>setFilter({...filter, dateOfCreation: null, page: 0})}
              />
              <DateTimePicker
                closeOnSelect
                clearable
                placeholder="Expiry Date"
                className={classes.cardActionDropdown}
                value={filter.expiryDate}
                onChange={(e) => handleOnChange_date(e, "expiryDate")}
                onClear={()=>setFilter({...filter, expiryDate: null, page: 0})}
              />
            </React.Fragment>
          }
          cardActions={
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleButtonClick_goBack()}
            >
              Go Back
            </Button>
          }
        >
          <div className={classes.table}>
            <Table
              className={classes.batchTable}
              header={filterActionTableHead(batchListTableHead, canWrite)}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { batchList.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.batchRefNo}</TableCell>
                    <TableCell>{moment(item.dateOfCreation).format("DD/MM/yyyy")}</TableCell>
                    <TableCell>{moment(item.expiryDate).format("DD/MM/yyyy")}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.qtyAdd))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.qtyUsed))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.qtyReconcilled))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.qtyLeft))}</TableCell>
                    <TableCell>{item.quantityUOM}</TableCell>
                    {/* <TableCell>{formatNumbers(roundTo2Decimal(item.unitCost))}</TableCell> */}
                    <TableCell>{formatNumbers(roundTo2Decimal(item.lossRatePercent))}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.supplierName}</TableCell>
                    <TableCell>{item.supplierRefId}</TableCell>
                    <TableCell>{item.warehouseRefId}</TableCell>
                    <TableCell>{displayRackList(item.rackNumbers)}</TableCell>
                    <TableCell align="right">
                      {canWrite &&
                        <React.Fragment>
                          <IconButton
                            type="edit"
                            onClick={() => handleEdit(item)}
                          />
                        </React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={filter.page+1}
              onChange={(e, pageNo)=>setFilter({...filter, page: pageNo-1})}
            />
          }
        </Card>
      </React.Fragment>
    );
  }
  
}
