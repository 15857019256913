import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

import SearchBatchResult from "./SearchBatchResult";

const useStyles = makeStyles(styles);

export default function SearchRMBatchResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  //count
  const batchTotalCount = useSelector(store => store.user.rawMaterial.search.batchTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(batchTotalCount) + " Search Result(s) From 1 Table:  ‘" + params.keyword + "’"}</Typography>
      <SearchBatchResult />
    </React.Fragment>
  );
}
