// Farm Capacity per Batch
// Cages & Plant tab
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";

import { renderValue, formatNumbers } from "common/functions";
import { setSearchText, setDirty } from "store/general";
import { updateRequest } from "./store/batchCapacity";
import { GetCageConfiguration, SaveCageConfigurations } from "services/AdminPortal/CapacityService";

import { useRouteCanWrite } from "hooks";

import styles from "assets/jss/components/AdminPortal/capacityStyle.js";

const useStyles = makeStyles(styles);

export default function CageConfiguration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const cagesConfiguration = useSelector(store => store.admin.capacity.batchCapacity.cagesConfiguration);
  const capacityCategories = useSelector(store => store.admin.capacity.batchCapacity.capacityCategories);
  const [productId, setProductId] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);

  const handleOnChange_text = (e, index) => {
    let payload = _.cloneDeep(capacityCategories);
    payload[index].totalAssignedCages = 0;
    payload[index].productList = payload[index].productList.map((product) => { 
      if (product.id === Number(e.target.id)) {
        let value = e.target.value > product.numberOfCages + payload[index].totalAvailableCages ? product.numberOfCages + payload[index].totalAvailableCages : e.target.value;
        product.numberOfCages = Number(value);
      }
      payload[index].totalAssignedCages += Number(product.numberOfCages);
      return product;
    });
    payload[index].totalAvailableCages = payload[index].totalCages - payload[index].totalAssignedCages;
    dispatch(updateRequest(payload));
  };

  const handleButtonClick_edit = (id) => {
    setProductId(id);
    dispatch(setDirty(true));
    setIsEdit(true);
  }

  const handleButtonClick_save = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(SaveCageConfigurations())
      .then(() =>{
        resetState();
      })
    })
  };
  
  const resetState = () => {
    setProductId(null);
    setIsEdit(false);
    dispatch(setDirty(false));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetCageConfiguration());
    // componentDidUnmount
    return () => {
      resetState();
    }
  },[]);

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card 
            classes={{
              root: classes.cardPaper,
              title: clsx(classes.cardTitle, classes.cardHeader),
              content: classes.cageCardContent
            }}
            title={
              <React.Fragment>
                Cages Configuration
                {canWrite &&
                  <Link to={{pathname: "/admin/capacity/farm-capacity"}} className={classes.headerButton}>
                    <IconButton type="edit" />
                  </Link>
                }
              </React.Fragment>
            }
          >            
            <Grid container spacing={4}>
              { cagesConfiguration.map((cage, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <Paper elevation={0} className={classes.cagesPaper}>
                      <Typography className={classes.cageNumber}>{formatNumbers(cage.count)}</Typography>
                      <Typography className={classes.cageDetail}>{cage.description}</Typography>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.cagesCard),
              title: classes.cardTitle,
              content: classes.cageCardContent
            }}
            title="Plants to be grown (based on cages)"
          >
              { capacityCategories.map((category, index) => {
                return (
                  <Table
                    key={index}
                    className={clsx(classes.table, classes.marginBottom)}
                  >
                    <TableRow>
                      <TableCell colSpan={3} className={classes.tableCellTitle}>No. of Cages for Grouping ({category.capacityCategoryName}) : {formatNumbers(category.totalCages)}</TableCell>
                    </TableRow>
                    { category.productList.map((item) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell className={classes.tableText}>{item.productName}</TableCell>
                          <TableCell className={clsx(classes.tableText, classes.tableCell)}>
                            {isEdit && productId === item.id 
                            ? <TextField 
                                id={""+item.id}
                                variant="outlined"
                                type="Number" 
                                onInput={(e)=>{ 
                                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                                }}
                                onChange={(e) => handleOnChange_text(e, index)}
                                value={renderValue(item.numberOfCages)}
                              />
                            : formatNumbers(item.numberOfCages)
                            }
                          </TableCell>
                          <TableCell className={classes.tableCellAction} align="right">
                            { isEdit && productId === item.id
                            ? <React.Fragment>
                                <IconButton 
                                  type="confirm"
                                  onClick={() => handleButtonClick_save()}
                                />
                                <IconButton 
                                  type="close"
                                  onClick={() => resetState()}
                                />
                              </React.Fragment>
                            : canWrite && 
                              <IconButton 
                                type="edit"
                                onClick={() => handleButtonClick_edit(item.id)}
                                disabled={(isEdit && productId !== item.id)}
                              />
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                )
              })}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
