// ID Management
import React from "react";
import { Link } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";

import device from "assets/icons/orange/device.svg";
import deviceFunction from "assets/icons/orange/device-function-id.svg";
import rawMaterial from "assets/icons/orange/raw-material.svg";
import product from "assets/icons/orange/products.svg";
import vehicle from "assets/icons/orange/vehicle.svg";

import deviceWhite from "assets/icons/white/device.svg";
import deviceFunctionWhite from "assets/icons/white/device-function-id.svg";
import rawMaterialWhite from "assets/icons/white/raw-material.svg";
import productWhite from "assets/icons/white/products.svg";
import vehicleWhite from "assets/icons/white/vehicle.svg";
import styles from "assets/jss/components/AdminPortal/idManagementStyle.js";

import { checkPermission } from "common/functions";

const useStyles = makeStyles(styles);

export default function IDManagement() {
  const classes = useStyles();
  const [hoverIndex, setHoverIndex] = React.useState(null);

  // Link to different module's ID
  const items = [
    {title: "Device Type ID", url: "/admin/device/type", icon: device, iconWhite: deviceWhite, name: "Admin.Device"},
    {title: "Device Function Type ID", url: "/admin/device/function", icon: deviceFunction, iconWhite: deviceFunctionWhite, name: "Admin.Device"},
    {title: "Raw Material Type ID", url: "/admin/raw-material/type", icon: rawMaterial, iconWhite: rawMaterialWhite, name: "Admin.Raw Material"},
    // {title: "Raw Material Variant Type ID", url: "/admin/raw-material/variant", icon: rawMaterialVariant, iconWhite: rawMaterialVariantWhite},
    {title: "Product Category ID", url: "/admin/products/category", icon: product, iconWhite: productWhite, name: "Admin.Products"},
    // {title: "Product Category Type ID", url: "/admin/products/type", icon: productCategory, iconWhite: productCategoryWhite},
    {title: "Vehicle Type ID", url: "/admin/vehicle/type", icon: vehicle, iconWhite: vehicleWhite, name: "Admin.Vehicle"},
  ]

  const handleMouseOver = (key) => {
    setHoverIndex(key);
  };

  const handleMouseOut = () => {
    setHoverIndex(null);
  }

  return (
    <Card 
      title="ID Management"
      subheader="Please click on the links to configure the IDs"
    >
      <Grid container spacing={4}>
        {items.map((item, key) => {
          if (checkPermission(item.name, "readEdit")) {
            return (
              <Grid item xs={12} sm={6} md={3} key={key}>
                <Link to={{pathname: item.url, state: {prevPath: location.pathname}}}>
                  <Paper elevation={0} className={classes.paper} 
                    onMouseOver={()=>handleMouseOver(key)}
                    onMouseOut={()=>handleMouseOut()}
                  >
                    <div className={classes.paperItem}>
                      <img className={classes.logoImage} src={(hoverIndex===key)?item.iconWhite:item.icon} alt={item.title} />
                      <Typography className={classes.logoTitle}>{item.title}</Typography>
                    </div>
                  </Paper>
                </Link>
              </Grid>
            );
          }
        })}
      </Grid>
    </Card>
  );
}
