import { createSlice } from "@reduxjs/toolkit";

import { GetAllPackagingType } from "services/AdminPortal/ProductService";

// initial state
const initialState = {
  totalCount: 0,
  packageTypes: [],
  package: {},
};

// create reducer and action creators
const packageSettings = createSlice({
  name: "packageSettings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.package[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllPackagingType.fulfilled]: (state, action) => {
      state.packageTotalCount = action.payload.result.totalCount;
      state.packageTypes = action.payload.result.items;
    },
  },
});

export const { setValues, updateRequest, reset } = packageSettings.actions;

// export the reducer
export default packageSettings.reducer;