/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";

// import PurchaseOrder from "./PurchaseOrder";
// import WorkOrder from "./WorkOrder";
import { setSearchText } from "store/general";

import search from "assets/icons/grey/search.svg";
import batchUpdate from "assets/img/batchUpdate-img.svg";
import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function Route() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const amrList = useSelector(store => store.user.amr.overview.result);
  const [selected, setSelected] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const handleOnChange_text = (e) => {
    setSearchValue(e.target.value);
    let payload = _.cloneDeep(amrList);
    setFilteredList(_.filter(payload, ({subSaleOrderNumber}) => {
      return subSaleOrderNumber.toLowerCase().includes(e.target.value.toLowerCase());
    }));
  }

  const handleOnChange_checkbox = (e) => {
    let payload = _.cloneDeep(selected);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      if (selected.length < 5) {
        payload.push(e.target.id);
      }
    }
    setSelected(payload);
  }

  const handleOnClick_clear = () => {
    setSelected([]);
  }

  const generateList = ({index, style}) => {
    let list = searchValue ? filteredList : amrList;
    if (list[index]) {
      return (
        <ListItem key={index} className={classes.listItem} style={style}>
        <Checkbox 
          id={String(list[index].id)}
          checked={selected.includes(String(list[index].id))} 
          onClick={(e)=>handleOnChange_checkbox(e)} 
        />
          {list[index].customerName} ({list[index].customerRefNo})
        </ListItem>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.bottomSpace),
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title="Floor"
          >
            <div className={classes.flexComponent}>
              <Typography className={classes.clickable}>Floor 1</Typography>
              <Typography className={classes.clickable}>Floor 2</Typography>
            </div>
          </Card>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.bottomSpace),
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title="Overview of the Level"
          >
            <Typography>Floor Size: <span className={classes.orange}>5000m^2</span></Typography>
          </Card>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.bottomSpace),
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title="AMR"
          >
            <TextField 
              id="search"
              variant="outlined" 
              placeholder="Search" 
              value={searchValue}
              InputProps={{
                startAdornment: 
                  <InputAdornment position="start">
                    <img src={search} alt="search" className={classes.icon_24}/>
                  </InputAdornment>,
              }}
              onChange={(e) => handleOnChange_text(e)}
            />
            <Typography className={classes.clear} onClick={handleOnClick_clear} disabled={!selected.length}>Clear Selection</Typography>
            <div className={classes.list}>
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={searchValue ? filteredList.length : amrList.length}
                    itemSize={0.02604 * window.innerWidth}
                    width={width}
                  >
                    {generateList}
                  </List>
                )}
              </AutoSizer>
            </div>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Card 
            classes={{
              root: classes.cardPaper,
              header: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardContent,
            }}
            title={
              <React.Fragment>
                Floor Plan&nbsp;&nbsp;<span className={classes.text}>{moment().format("DD/MM/YYYY HH:mm:ss")}</span>
              </React.Fragment>
            }
          >
            <img className={classes.img} src={batchUpdate} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
