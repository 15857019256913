import { createSlice } from "@reduxjs/toolkit";

import { 
  GetAllEquipmentReserve
} from "services/UserPortal/EquipmentService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  issues: [],
  issuedId: [],
};

const issueEquip = createSlice({
  name: "issueEquip",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      state.issuedId = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllEquipmentReserve.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.issues = action.payload.result.items;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = issueEquip.actions;

// export the reducer
export default issueEquip.reducer;