import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from '@mui/material/Typography';
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { setSearchText } from "store/general";
import { setValues } from "../store/capacity";
import { GetCustomerCapacityReport, GetProductList, GetCustomerList } from "services/UserPortal/CustomerOrderService";

import styles from "assets/jss/components/UserPortal/customerOrderStyle.js";

const useStyles = makeStyles(styles);

export default function Capacity() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedLang = useSelector(store => store.general.language);
  const productList = useSelector(store => store.user.customerOrder.management.productList);
  const filter = useSelector(store => store.user.customerOrder.capacity.filter);
  const customerList = useSelector(store => store.user.customerOrder.capacity.customerList);
  const report = useSelector(store => store.user.customerOrder.capacity.report);
  const [newFilter, setNewFilter] = React.useState({productId: null});
  const [month, setMonth] = React.useState(moment().startOf("month").format('M'));

  const calendarComponentRef = React.createRef();

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value})
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };

  const getCapacityReport = (stopLoading) => {
    if (filter.productId) {
      dispatch(GetCustomerCapacityReport({...filter, month, stopLoading}));
    } else {
      dispatch(setValues({report: []}));
    }
  }
  
  React.useEffect(() => {
    getCapacityReport();
  },[month, filter]);
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
    } else {
      if (productList.length) {
        dispatch(setValues({filter: {productId: productList[0].id}}));
      }
    }
  },[productList]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetProductList({isExcludeMixProduct: true}));
    dispatch(GetCustomerList());
  },[]);

  useInterval(() => {
    getCapacityReport(true);
  });

  return (
    <React.Fragment>
      <Card 
        classes={{
          action: "self-center"
        }}
        title = "Capacity Table"
        action={
          <div className={classes.legend}>
            Key: <span className={clsx(classes.eventIcon, classes.weight)}>W</span> Weight 
            <span className={clsx(classes.eventIcon, classes.percentage)}>P</span> Percentage of Whole Farm Capacity
            <span className={clsx(classes.eventIcon, classes.remain)}>R</span> Remain
            <span className={clsx(classes.eventIcon, classes.excess)}>E</span> Excess
          </div>
        }
      >
        <div className={classes.calendarHeader}>
          <div>
            <Typography className={classes.calendarTitle}>{filter.productId ? "Information Correct as at " + moment().format("DD/MM/YYYY - HH:mm") : "Please select a product"}</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key]) {
                let label = filter[key];
                if (key === "productId") {
                  const product = productList.find(({id}) => id == filter[key]);
                  label = product && (product.productName+" ("+product.productId+")");
                }
                if (key === "customerId") {
                  const customer = customerList.find(({id}) => id == filter[key]);
                  label = customer && (customer.customerName+" ("+customer.customerRefNo+")");
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </div>
          <div className={classes.calendarAction}>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: newFilter}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the capacity table
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productId})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="customerId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a customer"
                    value={renderValue(newFilter.customerId)}
                  >
                    {customerList && customerList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.customerName} ({item.customerRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </div>
        </div>
        <FullCalendar
          ref={calendarComponentRef}
          locales={allLocales}
          locale={selectedLang.code}
          plugins={[ dayGridPlugin ]}
          initialView="dayGridMonth"
          fixedWeekCount={false}
          datesSet={(dateInfo) => {
            setMonth(moment(dateInfo.view.getCurrentData().viewTitle, "MMMM YYYY").month()+1);
          }}
          headerToolbar={{
            start: 'title', // will normally be on the left. if RTL, will be on the right
            center: '',
            end: 'prevYear,prev,today,next,nextYear' // will normally be on the right. if RTL, will be on the left
          }}
          dayHeaderFormat={{
            weekday: 'long'
          }}
          height="41.750vw"
          eventContent={({event})=>{
            return (
              <div className={classes.event}>
                <Typography className={classes.eventText}>
                  <span className={clsx(classes.event2Icon, classes.weight)}>W</span>
                  {formatNumbers(roundTo2Decimal(event.extendedProps.capacityWeight))}kg
                </Typography>
                <Typography className={classes.eventText}>
                  <span className={clsx(classes.event2Icon, classes.percentage)}>P</span>
                  {formatNumbers(roundTo2Decimal(event.extendedProps.capacityPercentage))}%
                </Typography>
                <Typography className={classes.eventText}>
                  <span className={clsx(classes.event2Icon, classes.remain)}>R</span>
                  {formatNumbers(roundTo2Decimal(event.extendedProps.remainCapacity))}kg
                </Typography>
                {moment(event.start).isSame(moment().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0})) &&
                  <Typography className={classes.eventText}>
                    <span className={clsx(classes.event2Icon, classes.excess)}>E</span>
                    {formatNumbers(roundTo2Decimal(event.extendedProps.excessCapacity))}kg
                  </Typography>
                }
                {/* {moment(event.start).isAfter(moment().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0})) &&
                  <Typography className={classes.eventText}>
                    <span className={clsx(classes.event2Icon, classes.forecast)}>F</span>
                    {formatNumbers(roundTo2Decimal(event.extendedProps.excessCapacity))}kg
                  </Typography>
                } */}
                <div className={classes.hoverEvent}>
                  <Button 
                    className={clsx(classes.buttonSecondary, classes.viewMore)}
                    onClick={()=>history.push({pathname: "/user/sales-order/capacity/detail", state:{date: event.start, productId: filter.productId}})}
                  >
                    View More
                  </Button>
                </div>
              </div>
            )
          }}
          events={report}
        />
      </Card>
    </React.Fragment>
  );
}
