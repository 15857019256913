import {
  cardRadius,
  button,
  buttonSecondary,
  backgroundColor,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

const tableStyle = {
  cardRadius,
  button,
  buttonSecondary,
  table: {
    width: "-webkit-fill-available",
    "& tbody > tr:nth-child(even)": {
      backgroundColor: whiteColor
    },
    "& tbody > tr:nth-child(odd)": {
      backgroundColor: backgroundColor
    },
  },
  expandableTable: {
    // margin: "0 -1.979vw",
    '& thead > tr > th:first-child': {
      width: "5vw",
      minWidth: "5vw"
    },
    "& tbody > tr": {
      backgroundColor: whiteColor
    },
    "& tbody > tr:nth-child(4n+1)": {
      backgroundColor: backgroundColor
    },
    "& tbody > tr:nth-child(4n+2)": {
      backgroundColor: backgroundColor
    },
    "& tbody > tr > td:first-child": {
      paddingLeft: "1.667vw !important"
    },
  },
  headFont: {
    color: primaryColor[3],
    backgroundColor: whiteColor,
    fontWeight: 500
  },
  required: {
    color: secondaryColor[0]
  },
  headButton: {
    width: "8.250vw",
    minWidth: "8.250vw"
  },
  headRow: {
    '& th': {
      fontSize: "0.938vw",
      lineHeight: "1.094vw"
    },
    '& th:first-child': {
      paddingLeft: "2.292vw"
    },
    '& th:last-child': {
      paddingRight: "2.292vw"
    }
  },
  expandableHeadRow: {
    '& th': {
      fontSize: "0.938vw",
      lineHeight: "1.094vw"
    },
    '& th:first-child': {
      paddingLeft: "1.667vw"
    },
    '& th:last-child': {
      paddingRight: "2.292vw"
    }
  },
  paginationDiv: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0.729vw",
  },
  pagination: {
    width: "fit-content",
    '& ul > li > button.Mui-disabled': {
      opacity: 1,
      '& svg': {
        opacity: 0.38
      }
    },
    '& ul > li:first-child > button': {
      borderRadius: "4px 0 0 4px"
    },
    '& ul > li:last-child > button': {
      borderRadius: "0 4px 4px 0",
    },
    '& ul > li:not(:first-child):not(:last-child) > button': {
      borderRadius: 0,
    },
    '& ul > li:not(:first-child):not(:last-child) > button.Mui-selected': {
      backgroundColor: "inherit",
      color: secondaryColor[0],
    },
  },
  jumpPagination: {
    marginLeft: "1.563vw",
    fontSize: "0.833vw"
  },
  textPagination: {
    marginLeft: "0.521vw",
    fontSize: "0.833vw",
    width: "2.604vw",
    '& div': {
      paddingTop: "0.104vw !important",
      paddingBottom: "0.104vw !important",
    },
    '& div > fieldset': {
      border: "0.052vw solid #D9D9D6",
    },
  }
}

export default tableStyle;