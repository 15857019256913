import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from 'highcharts/highcharts-more'
import addDumbbellModule from 'highcharts/modules/dumbbell'
import addLollipopModule from 'highcharts/modules/lollipop'
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { checkPermission, renderValue } from "common/functions";
import { useInterval } from 'common/utils';
import { filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/availability";
import { GetOverallAvailability } from "services/UserPortal/EquipmentService";

import styles from "assets/jss/components/UserPortal/equipmentStyle.js";

const useStyles = makeStyles(styles);

highchartsMore(Highcharts)
addDumbbellModule(Highcharts)
addLollipopModule(Highcharts)
export default function OverallAvailability() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const equipmentList = useSelector(store => store.common.equipmentList);
  const filter = useSelector(store => store.user.equipment.availability.filter);
  const availability = useSelector(store => store.user.equipment.availability.availability);
  const [sortBy] = React.useState("Accumulative Hours");
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, equipmentId: null, date: null});

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      dispatch(setValues({filter: {...filter, date: moment(value).format("YYYY-MM-DD")}}));
    } 
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value})
  };

  const getOverallAvailability = (stopLoading) => {
    if (filter.date) {
      dispatch(GetOverallAvailability({...filter, sortBy, stopLoading}));
    }
  }

  React.useEffect(() => {
    getOverallAvailability();
  },[sortBy, filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {...filterParam(newFilter), date: moment().format("YYYY-MM-DD")}}));
  },[]);
  
  const config = () => {
    return ({
      chart: {
        zoomType: 'y',
        scrollablePlotArea: {
          minWidth: availability.length > 10 ? 2000 : 1000,
          scrollPositionX: 1
        }
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: availability.map((item) => {
          return item.equipmentName+"<br/>("+item.equipmentRefNo+")";
        })
      },
      yAxis: {
        title: {
          text: "Hours"
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          },
          tooltip: {
            headerFormat: '',
            pointFormat: '{series.name}',
            style:{
              fontSize: "0.833vw",
              whiteSpace: "normal"
            }
          },
          pointWidth: 60
        },
        lollipop: {
          dataLabels: {
            enabled: false,
            // align: 'left',
            // rotation: 270,
          },
          tooltip: {
            headerFormat: '',
            pointFormat: 'Available Hours: {point.y}',
            style:{
              fontSize: "0.833vw",
              whiteSpace: "normal"
            }
          },
          marker: {
            symbol: "triangle"
          },
          // pointPlacement: -0.2
        }
      },
      series: _.flattenDeep(_.union(
        availability.map((item, index) => {
          return [
            item.equipmentReserveList.map((data) => {
              return {
                type: "column",
                color: "#6DBA6D",
                states: {
                  hover: {
                      color: '#FF8A3D',
                  }
                },
                data: [[index, data.hour]],
                name: data.workOrderNo+", "+ data.productRefNo+", "+data.processName+", "+data.taskName+", "+data.hour+" hours",
                // stack: item.equipmentRefNo
              };
            }),
          ]
        }),
        [{
          type: "lollipop",
          color: "#FF8A3D",
          data: availability.map((item,index) => {
            return [index, item.equipmentAvailableHour]
          })
        }]
      ))
    })
  };

  useInterval(() => {
    getOverallAvailability(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && key !== "date");
  return (
    <React.Fragment>
      <Card 
        title = "Overall Availability"
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "equipmentId") {
                const equipment = equipmentList.find(({id}) => id == filter[key]);
                label = equipment && equipment.equipmentName;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: newFilter}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the availability list
                  <Select
                    className={classes.filterDropdown}
                    name="equipmentId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select an equipment"
                    value={renderValue(newFilter.equipmentId)}
                  >
                    {equipmentList && equipmentList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.equipmentName}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateTimePicker
              closeOnSelect
              placeholder="Select a date"
              className={classes.cardActionDropdown}
              value={filter.date}
              onChange={(e) => handleOnChange_date(e)}
            />
          </React.Fragment>
        }
      >
        <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.highChart }} />
        <Typography className={classes.smallText}>* Click and drag in the plot area to zoom in</Typography>
        {checkPermission("Admin.Equipment", "readEdit") &&
          <Typography className={classes.link}>
            <Link to={{pathname: "/admin/equipment"}}>Go to Equipment Module on the Admin Portal to add in the cost and hours for work</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
