// Create User
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import Alert from "shared-components/Modal/Alert";
import IconButton from "shared-components/Button/IconButton";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, resetUser, reset } from ".";
import { GetAllRoles, GetAllUser, CreateUser, DeleteUser } from "services/AdminPortal/UserService";
import { genderLookup } from "enums/Lookups.js";

import TagExistingUsers from "./TagExistingUsers";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/userStyle.js";

const useStyles = makeStyles(styles);
export default function AddUser() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const countryLookup = useSelector(store => store.lookup.countryLookup);
  const workforceTypeLookup = useSelector(store => store.lookup.workforceTypeLookup);
  const rolesLookup = useSelector(store => store.admin.user.roles);
  const users = useSelector(store => store.admin.user.users);
  const user = useSelector(store => store.admin.user.user);
  const [isChanged, setIsChanged] = React.useState(false);
  const [tagExisting, setTagExisting] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const handleModal_delete = (id) => {
    setUserId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_edit = (id) => {
    if (isChanged) {
      setUserId(id);
      setOpenEditModal(!openEditModal);
    } else {
      handleButtonClick_edit(id);
    }
  }

  const handleOnChange_text = (e) => {
    setIsChanged(true);
    if (e.target.id === 'userName') {
      e.target.value = e.target.value.replace(/\s/g, '');
    }
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    setIsChanged(true);
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };
  
  const handleButtonClick_batch = () => {
    if (isChanged) {
      setOpenAlertModal(!openAlertModal);
    } else {
      handleButtonClick_exit();
    }
  };

  const handleButtonClick_exit = () => {
    history.push("/admin/user/batch");
  }
  
  const handleButtonClick_existing = () => {
    if (isChanged) {
      setOpenAlertModal(!openAlertModal);
    } else {
      setTagExisting(true);
    }
  };

  const handleButtonClick_edit = (id) => {
    setUserId(id);
    setIsChanged(false);
    setOpenEditModal(false);
  };
  
  const handleButtonClick_delete = () => {
    dispatch(DeleteUser(userId))
    .then(() =>{
      dispatch(GetAllUser({page: 0, maxResultCount: 99}));
      resetState();
    });
  };

  const resetState = () => {
    setUserId(null);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_cancel = () => {
    setIsChanged(false);
    dispatch(resetUser());
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      setIsChanged(false);
      dispatch(CreateUser())
      .then((response) => {
        if (response.error) {
          setIsChanged(true);
          if (response.payload.message.includes('User name')) {
            setErrorMsg({field: "userName", msg: "Username already exists"});
          } else if (response.payload.message.includes('Email')) {
            setErrorMsg({field: "emailAddress", msg: "Email is already taken"});
          }
        } else {
          dispatch(GetAllUser({page: 0, maxResultCount: 99}));
          dispatch(resetUser());
        }
      });
    }
  };

  const validateFields = () => {
    if (_.isEmpty(user.name)) {
      setErrorMsg({field: "name", msg: "Please add in a name"});
      return false;
    }
    if (!user.roleId) {
      setErrorMsg({field: "roleId", msg: "Please select a user role"});
      return false;
    }
    if (_.isEmpty(user.userName)) {
      setErrorMsg({field: "userName", msg: "Please add in a username"});
      return false;
    }
    if (_.isEmpty(user.emailAddress)) {
      setErrorMsg({field: "emailAddress", msg: "Please add in user email"});
      return false;
    }
    if (_.isEmpty(user.workforceType)) {
      setErrorMsg({field: "workforceType", msg: "Please select a workforce type"});
      return false;
    }
    if (user.phone && !validate.isPhoneNumber(user.phone)) {
      setErrorMsg({field: "phone", msg: "Invalid phone number"});
      return false;
    }
    if (!validate.isEmail(user.emailAddress)) {
      setErrorMsg({field: "emailAddress", msg: "Please enter a valid email address"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllUser({page: 0, maxResultCount: 99}));
  },[tagExisting]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(GetAllRoles({page: 0, maxResultCount: 99}));
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  if (tagExisting) {
    return <TagExistingUsers setTagExisting={setTagExisting} />
  } else {
    return (
      <React.Fragment>
        <Card 
          title={
            <React.Fragment>
              User Creation
              <div className={classes.createHeaderButton}>
                <Button 
                  className={classes.buttonSecondary}
                  startIcon={<AddIcon />}
                  onClick={()=>handleButtonClick_batch()}
                >
                  Choose Batch Update
                </Button>
                <Button 
                  className={classes.buttonSecondary}
                  startIcon={<AddIcon />}
                  onClick={()=>handleButtonClick_existing()}
                >
                  Tag Existing Users
                </Button>
              </div>
            </React.Fragment>
          }
          subheader="Please key in details to add members." 
        >
          <div className={classes.content}>
            <Paper className={classes.leftContent} elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField 
                    label={<React.Fragment>Name <span className={classes.orange}>*</span></React.Fragment>}
                    id="name"
                    variant="outlined" 
                    inputProps={{ maxLength: 64 }}
                    placeholder="Please enter your name"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(user.name)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    label={<React.Fragment>Role <span className={classes.orange}>*</span></React.Fragment>}
                    name="roleId"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={renderValue(user.roleId)}
                    placeholder="Please select your role"
                    errorMsg={errorMsg}
                  >
                    {rolesLookup && rolesLookup.map((item) => {
                      return <MenuItem key={item.name} value={item.id}>{item.name}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    label={<React.Fragment>Username <span className={classes.orange}>*</span></React.Fragment>}
                    id="userName"
                    variant="outlined" 
                    inputProps={{ maxLength: 255 }}
                    placeholder="Please enter your username"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(user.userName)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    label={<React.Fragment>Email <span className={classes.orange}>*</span></React.Fragment>}
                    id="emailAddress"
                    variant="outlined" 
                    inputProps={{ maxLength: 255 }}
                    placeholder="eg. johndoe@gmail.com"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(user.emailAddress)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    label="Employee ID"
                    id="employeeId"
                    variant="outlined" 
                    inputProps={{ maxLength: 10 }}
                    placeholder="Please enter your ID"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(user.employeeId)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    label="Gender"
                    name="gender"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={renderValue(user.gender)}
                    placeholder="Please select your gender"
                  >
                    {genderLookup && genderLookup.map((item,index) => {
                      return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <TextField 
                    label="Phone Number"
                    id="phone"
                    type="number"
                    variant="outlined" 
                    onInput={(e)=>{ 
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                    }}
                    placeholder="eg. 88775566"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(user.phone)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    label={<React.Fragment>Workforce Type <span className={classes.orange}>*</span></React.Fragment>}
                    name="workforceType"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={renderValue(user.workforceType)}
                    placeholder="Select a type"
                    errorMsg={errorMsg}
                  >
                    {workforceTypeLookup && workforceTypeLookup.map((item, index) => {
                      return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    label={<React.Fragment>Country/Region <span className={classes.orange}>*</span></React.Fragment>}
                    name="country"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={renderValue(user.country)}
                    placeholder="Select a country"
                    errorMsg={errorMsg}
                  >
                    {countryLookup && countryLookup.map((item,index) => {
                      return <MenuItem key={index} value={item.countryName}>{item.countryName}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6} className="text-right">
                  <Button 
                    className={clsx(classes.buttonStyle, classes.buttonSecondary)}
                    onClick={()=>handleButtonClick_cancel()}
                    disabled={!isChanged}
                  >
                    Cancel
                  </Button>
                  <Button 
                    className={clsx(classes.buttonStyle, classes.button)}
                    startIcon={<AddIcon />}
                    onClick={()=>handleButtonClick_confirm()}
                    disabled={!isChanged}
                  >
                    Add User
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <div className={classes.rightContent}>
              <Paper className={classes.rightPaper} elevation={0}>
                <Typography>Users Added</Typography>
                { users && users.length === 0
                ? <div className={classes.emptyList}>
                    <Typography className={classes.empty}>The list is empty</Typography>
                  </div>
                : <div className={classes.userScroll}>
                    <div className={classes.userList}>
                      { users.map((user, index) => {
                        return (
                          <Paper key={index} className={classes.userDetail}>
                            <div className={classes.userHeader}>
                              <Typography className={classes.name}>{user.name}</Typography>
                              <div>
                                <Link to={{pathname: "/admin/user/"+user.id, state: {prevPath: location.pathname}}}>
                                  <IconButton type="edit" />
                                </Link>
                                { user.id !== userDetail.id &&
                                  <IconButton
                                    type="delete" 
                                    onClick={() => handleModal_delete(user.id)}
                                  />
                                }
                              </div>
                            </div>
                            <Typography className={classes.details}>{user.emailAddress}</Typography>
                            <Typography className={classes.details}>{user.roleName}</Typography>
                          </Paper>
                        )
                      })}
                    </div>
                  </div>
                }
              </Paper>
            </div>
          </div>
        </Card>
        <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this user? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openEditModal}
          onClose={() => handleModal_edit(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="You have not added the user you've entered. Editing this user will overwrite your current changes."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_edit(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_edit(userId)}>Edit</Button>
            </React.Fragment>
          }
        />
        <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleButtonClick_batch()} />
      </React.Fragment>
    )
  }
}
