// Create Edit Device Tagging
// Step 4: Tag Devices to Bank or Floor Plan
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImageMarker from 'react-image-marker';
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Bank from "shared-components/Bank/Bank";
import Modal from "shared-components/Modal/Modal";

import Loading from "error-page/Loading";
import { bankFormat } from "common/functions";
import DeviceTaggingStepper from "./DeviceTaggingStepper";
import { setDirty, setLoading } from "store/general";
import { farmConfig } from "enums/Constants";
import { resetSkipStep } from "./store/deviceTagging";
import { setValues, updateRequest, updateCurrentRequest, updateDeletedRequest, updateIndexRequest, resetDevices, reset } from "./store/devices";
import { GetAllFloorPlan, GetAllDeviceSearchList, GetAllBank, CreateDevicesTagging, GetDevicesTaggingFloorPlan, GetDevicesTaggingBank, GetDeviceLocation } from "services/AdminPortal/DeviceTaggingService";

import alert from "assets/icons/orange/alert-line.svg";
import search from "assets/icons/grey/search.svg";
import bin from "assets/icons/orange/delete-bin.svg";
import bin_disabled from "assets/icons/dark-grey/delete-bin.svg";
import plus from "assets/icons/orange/plus.svg";
import plus_disabled from "assets/icons/dark-grey/plus.svg";
import styles from "assets/jss/components/AdminPortal/deviceTaggingStyle.js";

const useStyles = makeStyles(styles);
export default function TagForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector(store => store.general.isLoading);
  const skipStep = useSelector(store => store.admin.deviceTagging.deviceTagging.skipStep);
  const floors = useSelector(store => store.admin.deviceTagging.floorPlan.floors);
  const banks = useSelector(store => store.admin.deviceTagging.devices.banks);
  const deviceList = useSelector(store => store.admin.deviceTagging.devices.deviceList);
  const devices = useSelector(store => store.admin.deviceTagging.devices.devices);
  const deletedDevices = useSelector(store => store.admin.deviceTagging.devices.deletedDevices);
  const [open, setOpen] = React.useState(true);
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState({});
  const [searchText, setSearchText] = React.useState((!_.isEmpty(skipStep) && skipStep.deviceRefNo) || "");
  const [isChanged, setIsChanged] = React.useState(false);
  const [changeSelection, setChangeSelection] = React.useState(null);
  const [alertAction, setAlertAction] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [zoom, setZoom] = React.useState(0);

  const handleOnChange_text = (e) => {
    setSearchText(e.target.value);
  };

  const handleOnChange_select = (target) => {
    if (target) {
      if (target.name === "floor") {
        const floor = floors.find((item) => item.id === target.value);
        setSelected({floor: target.value, sector: floor.sectors[0].id, bank: null});
      } else if (target.name === "sector") {
        setSelected({floor: selected.floor, sector: target.value, bank: null});
      } else if (target.name === "bank") {
        setSelected({floor: null, sector: null, bank: target.value});
      }
    }
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    getDeviceList(true);
  }
  
  const handleButtonClick_icon = (id) => {
    setIsChanged(true);   
    let payload = [];
    const index = _.findIndex(devices, ["deviceId", id]);
    const device = deviceList.find((item) => item.id === id);
    if (index !== -1) {  // alr exists,  remove
      const deleted = Object.assign({}, devices.find((item) => item.deviceId === id));
      if (deleted.id) {
        Object.assign(deleted, {isDeleted: true});
        dispatch(updateDeletedRequest([...deletedDevices, deleted]));
      }
      payload = devices.filter((item) => item.deviceId !== id);
      markers.splice(index, 1);
      dispatch(updateRequest(payload));
    } else {
      payload = _.cloneDeep(_.find(deletedDevices, (item) => item.deviceId === id));
      if (_.isEmpty(payload)) {
        payload = {deviceId: id, deviceRefNo: device.deviceRefNo}
      }
      Object.assign(payload, {isDeleted: false, floorPlanX: 0, floorPlanY: 0, cageLocationRefNo: null});
      dispatch(updateDeletedRequest(_.filter(deletedDevices, (item) => item.deviceId !== id)));
      dispatch(updateRequest([...devices, payload]));
    }
  };

  const handleOnClick_cell = (e) => {
    if (markers.length < devices.length) {
      const lastIndex = devices.length - 1;
      const transformed = { deviceId: devices[lastIndex].deviceId, cageLocationRefNo: selectedBank.bankRefNo+"-"+bankFormat(e.bay)+"-"+bankFormat(e.level) };
      setMarkers([...markers, transformed]);
      dispatch(updateIndexRequest({index: lastIndex, payload: transformed}));
    }
  }

  const handleOnChange_marker = (marker) => {
    if (markers.length < devices.length) {
      const lastIndex = devices.length - 1;
      const newMarker = { ...marker, ...devices[lastIndex] };
      setMarkers([...markers, newMarker]);
      const transformed = { ..._.omit(devices[lastIndex].id, ["top", "left"]), floorPlanY: marker.top, floorPlanX: marker.left };
      dispatch(updateIndexRequest({index: lastIndex, payload: transformed}));
    } 
  }

  const handleButtonClick_exit = () => {
    setChangeSelection(null);
    setOpenAlertModal(!openAlertModal);
  }

  const handleButtonClick_alert = (action, value) => {
    resetState();
    if (action === "select") {
      handleOnChange_select(value);
      setSearchText("");
    } else if (action === "view") {
      if (selected.bank !== null) { // prev in bank view
        setSelected({ 
          floor: floors[0].id, 
          sector: floors[0].sectors && floors[0].sectors[0].id, 
          bank: null
        })
      } else {
        setSelected({ 
          floor: null, 
          sector: null, 
          bank: banks.length && banks[0].id
        })
      }
    } else if (action === "back") {
      props.setStep(2);
    } else if (action === "complete") {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        history.push("/admin/device-tagging");
      })
    }
  }

  const handleModal_alert = (action, e) => {
    if (isChanged) {
      setOpenAlertModal(!openAlertModal);
      setAlertAction(action);
      if (action === "select") {
        setChangeSelection({name: e.target.name, value: e.target.value});
      }
    } else {
      if (e) {
        handleButtonClick_alert(action, {name: e.target.name, value: e.target.value});
      } else {
        handleButtonClick_alert(action);
      }
    }
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_skip = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      history.push("/admin/device-tagging");
    })
  }

  const handleButtonClick_save = () => {
    if (markers.length < devices.length) {
      setErrorMsg("You have not marked a device.");
    } else {
      let params = {
        floorId: selected.floor,
        sectorId: selected.sector,
      }
      if (selected.bank !== null) {
        params = {
          floorId: selectedBank.floorId,
          sectorId: selectedBank.sectorId,
          bankId: selected.bank
        }
      }
      dispatch(CreateDevicesTagging(params))
      .then((response) => {
        if (response.error) {
          setIsChanged(true);
        } else {
          resetState();
          getDeviceList();
        }
      });
    }
  }

  const getDeviceList = (isSearch) => {
    dispatch(GetAllDeviceSearchList({
      DeviceRefNo: isSearch ? searchText : "",
      FiltertaggedDevice: false
    }))
    .then(({payload}) => {
      if (payload && payload.result) {
        getTaggedList(payload.result.items, isSearch);
      } else {
        getTaggedList([], isSearch);
      }
    })
  }

  const getTaggedList = (list, isSearch) => {
    let values = list;
    if (!list) {
      values = _.filter(deviceList, ["isTagged", false]);
    }
    dispatch(GetAllDeviceSearchList({
      DeviceRefNo: isSearch ? searchText : "",
      FloorId: selected.floor,
      SectorId: selected.sector,
      BankId: selected.bank,
      FiltertaggedDevice: true
    }))
    .then(({payload}) => {
      if (payload && payload.result) {
        values = _.unionBy(values, payload.result.items, 'id');
      }
      dispatch(setValues(values));
    });
    getMarker(isSearch);
  }

  const getMarker = (isSearch) => {
    let params = { floorId: selected.floor, sectorId: selected.sector, bankId: selected.bank };
    if ((!params.floorId && !params.sectorId) && params.bankId) {
      dispatch(GetDevicesTaggingBank(params))
      .then(({payload}) => {
        if (payload && payload.result) {
          const result = payload.result.deviceList;
          let location = isSearch ? markers : [];
          for (let obj of result) {
            location = _.unionBy(location, [obj], "deviceId");
          }
          setMarkers(location);
        }
      })
    } else if (params.floorId && params.sectorId) {
      dispatch(GetDevicesTaggingFloorPlan(params))
      .then(({payload}) => {
        if (payload && payload.result) {
          const result = payload.result.deviceList;
          let location = isSearch ? markers : [];
          for (let obj of result) {
            location = _.unionBy(location , [{ ..._.omit(obj, ["floorPlanY", "floorPlanX"]), top: obj.floorPlanY, left: obj.floorPlanX }], "deviceId");
          }
          setMarkers(location);
        }
      })
    }
  }

  const CustomMarker = (obj) => {
    if (devices.length) {
      const device = devices[obj.itemNumber];
      if (device && !device.cageLocationRefNo) {
        return (
          <div className={classes.markerDiv}>
            <div className={classes.marker}>{device.deviceRefNo.substr(0, device.deviceRefNo.indexOf('-'))}</div>
            <Typography className={classes.markerText}>{device.deviceRefNo}</Typography>
          </div>
        );
      }
    }
    return null;
  };

  const resetState = () => {
    setOpenAlertModal(false);
    setIsChanged(false);
    setErrorMsg(null);
    setMarkers([]);
    dispatch(resetDevices());
    setZoom(0);
  }
  
  const getDeviceLocation = () => {
    dispatch(GetDeviceLocation(skipStep.deviceId))
    .then((response) => {
      if (response.error) {
        dispatch(setLoading(false));
      } else if (response.payload && response.payload.result) {
        if (response.payload.result.bankId) {
          setSelected({floor: null, sector: null, bank: response.payload.result.bankId});
        } else {
          setSelected({floor: response.payload.result.floorId, sector: response.payload.result.sectorId, bank: null});
        }
      }
    })
  }

  const handleButtonClick_zoom = (action) => {
    if (action === "in") {
      setZoom(zoom+1);
    } else {
      setZoom(zoom-1);
    }
  }

  React.useEffect(() => {
    dispatch(setDirty(isChanged));
  },[isChanged]);

  React.useEffect(() => {
    if ((selected.floor && selected.sector) || selected.bank) {
      getDeviceList(Boolean(skipStep && skipStep.deviceId));
      dispatch(resetSkipStep());
    }
  },[selected]);

  React.useEffect(() => {
    const intersection = devices.filter(devices => deviceList.some(deviceList => devices.deviceRefNo === deviceList.deviceRefNo));
    dispatch(updateCurrentRequest(intersection));
  },[devices, deviceList])

  // componentDidMount
  React.useEffect(() => {
    resetState();
    dispatch(GetAllBank({isGetAllBank: true}));
    if (!_.isEmpty(skipStep) && skipStep.step === 3) {
      dispatch(GetAllFloorPlan())
      .then((response) => {
        if (!response.payload.result) {
          props.setStep(0);
        } else {
          if (!skipStep.deviceId) { // from device tagging main page
            setSelected({floor: null, sector: null, bank: skipStep.bankId})
          } else { // from device page
            if (skipStep.location) {
              getDeviceLocation();
            } else {
              const floor = response.payload.result.items;
              setSelected({floor: floor[0].id, sector: floor[0].sectors && floor[0].sectors[0].id, bank: null});
            }
          }
        }
      })
    } else {  // go by normal route
      setSelected({floor: floors[0].id, sector: floors[0].sectors && floors[0].sectors[0].id, bank: null})
    }
    // componentDidUnmount
    return () => {
      resetState();
      setSearchText("");
      dispatch(reset());
      dispatch(resetSkipStep());
    }
  },[]);

  const selectedBank = banks.find((item) => item.id === selected.bank);
  let selectedFloor = floors.find((item) => item.id === selected.floor);
  if (!_.isEmpty(selected) && !isLoading) {
    const sector = selectedFloor && selectedFloor.sectors && selectedFloor.sectors.find((item) => item.id === selected.sector);
    return (
      <React.Fragment>
        <Card 
          title={"Step 4: Tag Devices to "+farmConfig.bank+" / Floor Plan"}
          subheader={
            <React.Fragment>
              Please Tag Devices to a {farmConfig.bank} or Floor Plan.
              <DeviceTaggingStepper activeStep={props.step} />
            </React.Fragment>
          }
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={() => handleModal_alert("back")}
              >
                Go Back
              </Button>
              <div>
                <Button
                  className={clsx(classes.buttonSecondary, classes.buttonMargin)}
                  onClick={()=>isChanged ? handleModal_alert("complete") : handleModal_warning()}
                >
                  Save Draft
                </Button>
                <Button
                  className={classes.button}
                  onClick={()=>isChanged ? handleModal_alert("complete") : handleButtonClick_skip()}
                >
                  Complete
                </Button>
              </div>
            </React.Fragment>
          }
        >
          <div className={classes.tagContent}>
            <IconButton
              className={classes.listIconButton2}
              onClick={()=>setOpen(!open)}
            >
              {!open ? <KeyboardArrowRightIcon className={classes.icon_24} /> : <KeyboardArrowLeftIcon className={classes.icon_24} />}
            </IconButton>
            <Drawer
              sx={{
                // width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: "25%",
                  position: 'absolute',
                  padding: "1.250vw 1.979vw",
                  // paddingLeft: "1.979vw",
                  marginLeft: "-1.979vw",
                  border: "0.052vw solid #CCC",
                  borderLeft: 0,
                  borderBottomRightRadius: "0.833vw",
                  overflow: "visible",
                  zIndex: 99,
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <Typography className={clsx(classes.bold, classes.cardTitle)}>Devices</Typography>
              <form onSubmit={(e) => handleOnSearch(e)}>
                <TextField 
                  className={classes.searchText}
                  id="searchText"
                  variant="outlined" 
                  placeholder="Search"
                  value={searchText}
                  InputProps={{
                    startAdornment: 
                      <InputAdornment position="start">
                        <img src={search} alt="search" className={classes.icon_24}/>
                      </InputAdornment>,
                  }}
                  onChange={(e) => handleOnChange_text(e)}
                />
              </form>
              <div className={classes.tagLeftContent}>
                { errorMsg && <Typography className={classes.error}>{errorMsg}</Typography> }
                { _.orderBy(deviceList, ["deviceRefNo"], ["asc"]).map((item, index) => {
                  const itemIndex = _.findIndex(devices, ["deviceId", item.id]);
                  return (
                    <div key={index} className={classes.buttonGrid}>
                      <IconButton 
                        className={classes.addTagButton}
                        onClick={() => handleButtonClick_icon(item.id)}
                        disabled={(markers.length < devices.length && itemIndex !== devices.length-1) || (!(sector && sector.floorPlanDiagramUrl) && !selectedBank)}
                      >
                        {itemIndex !== -1 
                        ? <React.Fragment>
                            { (markers.length < devices.length || (!(sector && sector.floorPlanDiagramUrl) && !selectedBank)) && itemIndex !== devices.length-1
                            ? <img className={classes.icon} src={bin_disabled} alt="bin" />
                            : <img className={classes.icon} src={bin} alt="bin" />
                            }
                          </React.Fragment> 
                        : <React.Fragment>
                            { (markers.length < devices.length || (!(sector && sector.floorPlanDiagramUrl) && !selectedBank))
                            ? <img className={classes.icon} src={plus_disabled} alt="plus" />
                            : <img className={classes.icon} src={plus} alt="plus" />
                            }
                          </React.Fragment> 
                        }
                      </IconButton>
                      <Typography className={classes.buttonLabel}>{item.deviceRefNo}</Typography>
                    </div>
                  )
                })}
              </div>
              <IconButton
                className={classes.listIconButton}
                onClick={()=>setOpen(!open)}
              >
                {!open ? <KeyboardArrowRightIcon className={classes.icon_24} /> : <KeyboardArrowLeftIcon className={classes.icon_24} />}
              </IconButton>
            </Drawer>
            <div className={classes.tagHeader}>
              <div>
                <Typography className={clsx(classes.bold, classes.cardTitle)}>
                  { selected.bank !== null
                    ? selectedBank ? farmConfig.bank+" / Floor Plan For Floor " + (selectedBank.floorNumber ? selectedBank.floorNumber : "-") + " & Sector " + (selectedBank.sectorNumber ? selectedBank.sectorNumber : "-") : farmConfig.bank+" / Floor Plan"
                    : "Floor Plan Diagram"
                  }
                </Typography>
                { selected.bank !== null
                  ? <Link className={classes.tagClickable} onClick={()=>handleModal_alert("view")}>Switch to Floorplan View</Link>
                  : <Link className={classes.tagClickable} onClick={()=>handleModal_alert("view")}>Switch to {farmConfig.bank} View</Link>
                }
              </div>
              <div className="flex">
                { selected.bank !== null
                ? <div className={classes.headerAction}>
                    <Select
                      className={classes.headerSelect}
                      name="bank"
                      onChange={(e)=>handleModal_alert("select", e)}
                      defaultValue=""
                      value={selected.bank}
                      disabled={banks.length === 0}
                    >
                      { banks && banks.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>{item.bankName}</MenuItem>;
                      })} 
                    </Select>
                  </div>
                : <div className={classes.headerAction}>
                    <Select
                      className={classes.headerSelect}
                      name="floor"
                      onChange={(e)=>handleModal_alert("select", e)}
                      defaultValue=""
                      value={selected.floor}
                    >
                      { floors && floors.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>Floor {item.floorNumber}</MenuItem>;
                      })} 
                    </Select>
                    <Select
                      className={classes.headerSelect}
                      name="sector"
                      onChange={(e)=>handleModal_alert("select", e)}
                      defaultValue=""
                      value={selected.sector ? selected.sector : (selectedFloor.sectors ? selectedFloor.sectors[0].id : null)}
                    >
                      { selectedFloor && selectedFloor.sectors && selectedFloor.sectors.map((item, index) => {
                        return <MenuItem key={index} value={item.id}>Sector {item.sectorNumber}</MenuItem>;
                      })} 
                    </Select>
                  </div>
                }
                <Button
                  className={classes.saveButtonMargin}
                  onClick={()=>handleButtonClick_save()}
                  disabled={!isChanged}
                >
                  Save
                </Button>
              </div>
            </div>
            <div className={classes.tagMainContent}>
              { selected.bank !== null
              ? <React.Fragment>
                  { selectedBank 
                  ? <div className={classes.bankView}>
                      <Bank 
                        type="tag"
                        levels={selectedBank.numberOfLevels}
                        bays={selectedBank.numberOfBays}
                        onCellClick={(e) => handleOnClick_cell(e)}
                        data={devices}
                        onDelete={(id)=>handleButtonClick_icon(id)}
                      />
                    </div>
                  : <Typography className={classes.emptyLabel}>No {farmConfig.bank} Selected</Typography>
                  }
                </React.Fragment>
              : <React.Fragment>
                  { sector && sector.floorPlanDiagramUrl 
                  ? <React.Fragment>
                      <div className={classes.zoomDiv}>
                        <IconButton 
                          className={classes.zoomButton}
                          onClick={()=>handleButtonClick_zoom("out")}
                          disabled={zoom === 0}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <IconButton 
                          className={classes.zoomButton}
                          onClick={()=>handleButtonClick_zoom("in")}
                          disabled={zoom === 4}
                        >
                          { zoom === 4
                          ? <img className={classes.icon} src={plus_disabled} alt="plus" />
                          : <img className={classes.icon} src={plus} alt="plus" />
                          }
                        </IconButton>
                      </div>
                      <ImageMarker
                        extraClass={clsx(classes.floorPlanImg, classes["floorPlanImg"+zoom])}
                        src={sector.floorPlanDiagramUrl}
                        markers={markers}
                        onAddMarker={(marker) => handleOnChange_marker(marker)}
                        markerComponent={CustomMarker}
                      />
                    </React.Fragment>
                  : <Typography className={classes.emptyLabel}>No Floor Plan</Typography>
                  }
                </React.Fragment>
              }
            </div>
          </div>
        </Card>
        <Modal
          open={openAlertModal}
          onClose={() => handleModal_alert()}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="You have not saved your current changes. Changing your selection will overwrite your current changes."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleButtonClick_exit()}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_alert(alertAction, changeSelection)}>OK</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openWarningModal}
          onClose={() => handleModal_warning()}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you want to save the details first? You will be directed to device tagging page."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_warning()}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_skip()}>Confirm</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  } else {
    return <Loading />
  }
}
