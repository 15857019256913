import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Switch from "shared-components/Switch/Switch";

import { rowsPerTable, minDate } from "config";
import { setDirty } from "store/general";
import { checkPermission } from "common/functions";
import { operationTaskStatus } from "enums/Constants";
import { setValues } from "../store/task";
import { getOperationTaskDetail, goBackFromSubTask } from "./utils";
import { UpdateOperationSubTaskList, SetCompleteTask, GetAllUnitOfMeasurement, UpdateOperationBatchOpenDate } from "services/UserPortal/OperationsService";

import TaskTable from "./TaskTable";
import styles from "assets/jss/components/UserPortal/operationsStyle.js";

const useStyles = makeStyles(styles);

export default function TaskDetail(props) {
  const prop = props.location && props.location.state;

  const classes = useStyles();
  let history = useHistory()
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const task = useSelector(store => store.user.operations.task.task);
  const taskDetail = useSelector(store => store.user.operations.task.taskDetail);
  const rawMaterial = useSelector(store => store.user.operations.task.rawMaterial);
  const equipment = useSelector(store => store.user.operations.task.equipment);
  const other = useSelector(store => store.user.operations.task.other);
  const selectedAction = useSelector(store => store.user.operations.task.selectedAction);
  const [batchOpenDate, setBatchOpenDate] = React.useState(null);
  const [page, setPage] = React.useState({rm: 0, equip: 0, other: 0});
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [filter, setFilter] = React.useState({showOwnTasks: prop && prop.showOwnTasks, date: prop && prop.dateFilter});
  const [disableComplete, setDisableComplete] = React.useState(false);

  const rmCount = Math.ceil(rawMaterial.length / rowsPerTable);
  const equipCount = Math.ceil(equipment.length / rowsPerTable);
  const otherCount = Math.ceil(other.length / rowsPerTable);
  
  const handleButtonClick_back = () => {
    goBackFromSubTask(history, prop);
  };

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      setFilter({...filter, date: moment(value).format("YYYY-MM-DD")});
    } 
  };

  const handleOnChange_batch = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      setBatchOpenDate(moment(value).format("YYYY-MM-DD"));
    } 
  };

  const handleButtonClick_saveDate = () => {
    dispatch(UpdateOperationBatchOpenDate({operationTaskId: task.id, batchOpenDate}))
    .then(({error}) => {
      if (!error) {
        dispatch(setValues({task: {...task, batchOpenDate: batchOpenDate}}));
      }
    });
  }
  
  const handleButtonClick_save = () => {
    // Promise.all(_.map(selectedAction, (item) => {
    //   if (!item.isEquipment && !item.isRawMaterial && item.deviceRefNo) {
    //     return dispatch(IsValidDeviceRefNo({deviceRefNo: item.deviceRefNo, operationSubTaskId: item.id}));
    //   }
    // }))
    // .then((response) => {
    //   let validated = true;
    //   _.map(response, (res, index) => {
    //     if (res && !res.payload.result) {
    //       validated = false;
    //       setErrorMsg({field: String(selectedAction[index].id), msg: "Please enter a valid ID"});
    //     }
    //   });
    //   if (validated) {
        dispatch(UpdateOperationSubTaskList(selectedAction))
        .then(({error}) => {
          if(!error) {
            if (_.reject(selectedAction, "isAction").length === 0) {
              dispatch(SetCompleteTask(location.pathname.split("/").pop()));
            }
            setPage({rm: 0, equip: 0, other: 0});
            dispatch(setDirty(false));
            getOperationTask();
            setErrorMsg(false);
          }
        });
    //   }
    // })
  };
  
  const getOperationTask = (stopLoading) => {
    if (!_.isEmpty(task)) {
      getOperationTaskDetail({startDate: filter.date, endDate: filter.date, showOwnTasks: filter.showOwnTasks, stopLoading}, history, prop, true);
    } else {
      handleButtonClick_back();
    }
  }
  
  // check if all storage is filled
  const isStorageFilled = () => {
    const elements = document.getElementsByClassName('storage');
    if (elements.length) {
      setDisableComplete(true);
    } else {
      setDisableComplete(false);
    }
  }

  React.useEffect(() => {
    if (!(prop.dateFilter && !filter.date)) {
      getOperationTask();
    }
  },[filter]);

  React.useEffect(() => {
    isStorageFilled();
  },[other]);

  // componentDidMount
  React.useEffect(() => {
    isStorageFilled();
    dispatch(GetAllUnitOfMeasurement());
    setBatchOpenDate(moment(task.batchOpenDate).isAfter(minDate) ? moment(task.batchOpenDate).format() : null);
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(setValues({rawMaterial: [], equipment: [], other: [], selectedId: []}));
    }
  },[]);

  const taskTable = [
    { title: "Raw Materials", data: rawMaterial, pageKey: "rm", refNoKey: "rawMaterialRefNo", count: rmCount },
    { title: "Equipment", data: equipment, pageKey: "equip", refNoKey: "equipmentRefNo", count: equipCount },
    { title: "Others", data: other, pageKey: "other", refNoKey: "deviceRefNo", count: otherCount },
  ]
  return (
    <React.Fragment>
      <Card 
        title={prop && prop.processName+" Process - "+task.taskName}
        subheader={
          <div className={classes.taskSubheader}>
            <Typography>Product:&nbsp;{prop.isMixProduct ? taskDetail.productName : taskDetail.parentProductName}</Typography>
            {prop.isMixProduct && <Typography>Parent Product:&nbsp;{taskDetail.parentProductName}</Typography>}
            <Typography>Work Order:&nbsp;{taskDetail.workOrderNumber}</Typography>
            <Typography>Lot ID:&nbsp;{taskDetail.lotId}</Typography>
          </div>
        }
        action={
          <React.Fragment>
            <div className={classes.taskSwitch}>
              <Typography className={classes.switchText}>My Tasks: </Typography>
              <Switch 
                checked={Boolean(filter.showOwnTasks)}
                onChange={() => setFilter({...filter, showOwnTasks: !filter.showOwnTasks})}
              />
            </div>
            <DateTimePicker
              closeOnSelect
              clearable
              placeholder="Start Date"
              className={classes.cardActionDropdown}
              value={filter.date}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>setFilter({...filter, date: null})}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={handleButtonClick_back}>
              Go Back
            </Button>
            {prop.taskStatus !== operationTaskStatus.completed && 
            <React.Fragment>
              {_.reject(selectedAction, "isAction").length === 0 
              ? <Button className={classes.button} onClick={handleButtonClick_save} disabled={!isDirty && disableComplete}>
                  Complete Task
                </Button>
              : <Button className={classes.button} onClick={handleButtonClick_save} disabled={!isDirty}>
                  Save
                </Button>
              }
            </React.Fragment>
            }
          </React.Fragment>
        }
      >
        {task.ableToAddBatchOpenDate && 
        <div className={classes.assignedPerson}>
          <Typography className={classes.assignedPersonHeader}>Batch Open Date: </Typography>
          <DateTimePicker
            className={classes.assignedPersonSelect}
            name="batchOpenDate"
            value={batchOpenDate}
            onChange={handleOnChange_batch}
            disabled={!checkPermission("Operations", "readEdit")}
          />
          {checkPermission("Operations", "readEdit") && 
          <Button className={classes.button} onClick={()=>handleButtonClick_saveDate()}>
            Set
          </Button>
          }
        </div>
        }
        {_.map(taskTable, (item,index) => {
          return (
            <TaskTable 
              key={index}
              {...item}
              taskStatus={prop.taskStatus}
              getOperationTask={getOperationTask} 
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg} 
              page={page} 
              setPage={setPage} 
            />
          )
        })}
      </Card>
    </React.Fragment>
  );
}
