import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// shared components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import Popper from "shared-components/Popper/Popper";
import Select from "shared-components/Select/Select";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable } from "config";
import { setSearchText, setDirty } from "store/general";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { setValues, updateRequest } from "../store/issue";
import { rmIssueTableHead } from "enums/UserPortal/TableHeaders";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { GetAllRawMaterialReserveOrIssue, BatchRawMaterialIssue, GetRawMaterialStatusList } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function Issue() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const lotIdList = useSelector(store => store.common.lotIdList);
  const filter = useSelector(store => store.user.rawMaterial.issueRM.filter);
  const totalCount = useSelector(store => store.user.rawMaterial.issueRM.totalCount);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const productList = useSelector(store => store.common.productList);
  const statusList = useSelector(store => store.user.rawMaterial.issueRM.statusList.reserveStatuses);
  const issues = useSelector(store => store.user.rawMaterial.issueRM.issues);
  const issuedId = useSelector(store => store.user.rawMaterial.issueRM.issuedId);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('workOrderNumber');
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, status: null, rawMaterialName: null, productName: null, reserveStartDate: null, reserveEndDate: null, page: 0});
  const [openPopper, setOpenPopper] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, reserveStartDate: moment(value.startDate).format("YYYY-MM-DD"), reserveEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_deleteTag = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(issuedId);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      payload.push(e.target.id);
    }
    dispatch(updateRequest(payload));
  };

  const handleButtonClick_save = () => {
    dispatch(BatchRawMaterialIssue())
    .then(({error}) => {
      if (!error) {
        dispatch(setValues({filter: {...filter, page: 0}}));
        dispatch(setDirty(false));
        dispatch(updateRequest([]));
      }
    });
  };

  const getIssueList = (stopLoading) => {
    dispatch(GetAllRawMaterialReserveOrIssue({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getIssueList();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetRawMaterialStatusList());
    dispatch(GetProductList());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getIssueList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title="Issuance List"
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "productId") {
                const product = productList.find(({id}) => id == filter[key]);
                label = product && product.productName+"("+product.productRefNo+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_deleteTag(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the issuance list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a status"
                    value={renderValue(newFilter.status)}
                  >
                    {statusList && statusList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialName"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material name"
                    value={renderValue(newFilter.rawMaterialName)}
                  >
                    {rawMaterialList && rawMaterialList.map((item, index) => {
                      return <MenuItem key={index} value={item.rawMaterialName}>{item.rawMaterialName}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Usage date"
              className={classes.cardActionDate}
              value={{startDate: filter.reserveStartDate, endDate: filter.reserveEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, reserveStartDate: null, reserveEndDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button disabled/>
            <div className={classes.issueAction}>
              <Button
                className={classes.buttonSecondary}
                disabled
              >
                Scan RFID Tags
              </Button>
              {canWrite &&
                <Button
                  className={classes.button}
                  onClick={()=>handleButtonClick_save()}
                  disabled={issuedId && issuedId.length === 0}
                >
                  Save
                </Button>
              }
            </div>
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.reserveTable}
            header={rmIssueTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { issues.map((item,index) => {
              var regExp = /\(([^)]+)\)/;
              var rackRefNo = regExp.exec(item.location);
              return (
                <TableRow key={index}>
                  <TableCell>{item.workOrderNumber}</TableCell>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{item.rawMaterialRefNo}</TableCell>
                  <TableCell>{item.rawMaterialBatchRefNo}</TableCell>
                  <TableCell>{moment(item.creationDate).format("DD/MM/yyyy HH:mm")}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.qtyReserve))}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.currentQty))}</TableCell>
                  <TableCell>{item.uom}</TableCell>
                  <TableCell>{moment(item.reserveDate).format("DD/MM/yyyy HH:mm")}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{item.productRefNo}</TableCell>
                  <TableCell>{item.unitCost.toFixed(4)}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.lossRatePercent))}</TableCell>
                  <TableCell>{item.location && item.location.split('(')[0]}{rackRefNo && " ("}{rackRefNo && <ViewMoreModal list={rackRefNo[1]} title="Rack Reference Number" />}{rackRefNo && ")"}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.issuedUserId ? moment(item.issuedTimeStamp).format("DD/MM/YYYY HH:mm") : ""}</TableCell>
                  <TableCell>{item.issuedUser}</TableCell>
                  <TableCell align="center">
                    <Popper
                      noButton
                      open={openPopper === index}
                      className={classes.popper}
                      closePopper={() => setOpenPopper(false)}
                      placement="left"
                      content="Unable to issue Raw Materials due to inadequate quantity."
                    >
                      <div 
                        onMouseOver={()=>item.unableToIssue && setOpenPopper(index)} 
                        onMouseOut={()=>setOpenPopper(false)}
                      >
                        <Checkbox 
                          id={String(item.id)}
                          checked={Boolean(item.issuedUser || issuedId.includes(String(item.id)))}
                          onChange={(e) => handleButtonClick_checkbox(e)}
                          disabled={Boolean(item.unableToIssue || item.issuedUser || !canWrite)}
                        />
                      </div>
                    </Popper>
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
