import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { fixedProcessList } from "enums/Constants";

import light from "assets/icons/black/light.svg";
import temperature from "assets/icons/black/temperature.svg";
import styles from "assets/jss/shared-components/timelineStyle.js";

const useStyles = makeStyles(styles);
export default function OperationsTimeline({value, workOrderId}) {
  const classes = useStyles();
  let history = useHistory();
  const tableRef = React.useRef(null);
  const [noOfDays, setNoOfDays] = React.useState(0);

  const renderHeader = (hidden) => {
    let cells = [];
    cells.push(<TableCell key="empty" className={clsx(classes.emptyCell, hidden&&classes.hidden)} />);
    for (let i = 0; i < noOfDays; i++) {
      if (i === noOfDays-1) {
        cells.push(<TableCell key={i} className={clsx(classes.tableHead, classes.tableCell, hidden&&classes.hidden)} align="right">Day {i+1}</TableCell>);
      } else if (i % 3 === 0) {
        cells.push(<TableCell key={i} className={clsx(classes.tableHead, classes.tableCell, hidden&&classes.hidden)} align="left">Day {i}</TableCell>);
      } else {
        cells.push(<TableCell key={i} className={clsx(classes.tableHead, classes.tableCell, hidden&&classes.hidden)} />);
      }
    }
    return cells;
  }

  const renderTable = (processList) => {
    let cells = [];
    for (let i = 0; i < processList.length; i++) {
      const process = fixedProcessList.find(({processName}) => processName === processList[i].process);
      cells.push(
        <TableCell 
          key={i}
          className={classes.tableCell}
          colSpan={Number(processList[i].days)}
        >
          <div className={clsx(classes.chip, classes[process.code])}>
            <span className={classes.rowTitle}>{processList[i].process} | {processList[i].days} Days</span>
          </div>
        </TableCell>
      );
    }
    return cells;
  }

  const renderStatus = (currentStatus) => {
    let cells = [];
    if (currentStatus && currentStatus.currentDay) {
      const day = currentStatus.currentDay ? Number(currentStatus.currentDay.replace("Day", "")) : 0;
      cells.push(<TableCell key="empty" colSpan={Number(day+1)} />);
      cells.push(
        <TableCell
          key="status"
          className={clsx(classes.tableCell, classes.statusCell)}
          // colSpan={3}
        >
          <div className={classes.status}>
            Current Status: <img className={classes.icon_24} src={light} />{currentStatus.lightingControl}% {currentStatus.lightingControlChannelTwo > 0 && currentStatus.lightingControlChannelTwo + '%'}<img className={classes.icon_24} src={temperature} />{currentStatus.temperature}°C
            <span className={classes.arrow} />
          </div>
        </TableCell>
      );
    }
    return cells;
  }

  const renderProduct = (item, index) => {
    let post = _.cloneDeep(item.postHarvestTimeLine);
    return (
      <div key={index}>
        {item.isMix && <Typography className={classes.timelineHeader}>{item.productName}({item.productRefNo}){item.lotId && " ("+item.lotId+")"}</Typography>}
        <Table className={item.isMix && clsx(classes.timelineBorder, classes.tableMargin)}>
          {_.map(item.preHarvestTimeLine, (process, i) => {
            return (
              <React.Fragment key={i}>
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={noOfDays} className={clsx(classes.mixProduct, classes.tableCell)}>
                    <span className={classes.rowTitle}>{process.productName}({process.productRefNo}){(!item.isMix && item.lotId) && " ("+item.lotId+")"}</span>
                    <span className={classes.link} onClick={() => history.push({pathname: "/user/operations/process-overview/" + item.productId, state: {workOrderId, product: item, process: item.productProcessStatus}})}>{item.productProcessStatus}</span>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableRow}>
                  {renderStatus(item.operationCurrentStatus)}
                </TableRow>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.emptyCell} />
                  {renderTable(process.processList, item)}
                  {_.map(item.postHarvestTimeLine, ({id, processList}, k) => {
                    if (id === process.id) {
                      post = _.reject(post, (p)=> p.id === id);
                      return (
                        <React.Fragment key={k}>
                          {renderTable(processList)}
                        </React.Fragment>
                      )
                    }
                  })}
                </TableRow>
              </React.Fragment>
            )
          })}
          {_.map(post, (process, i) => {
            return (
              <React.Fragment key={i}>
                <TableRow className={classes.tableRow}>
                  {item.isMix && 
                    <React.Fragment>
                      <TableCell className={classes.emptyCell} />
                      <TableCell className={classes.tableCell} colSpan={item.totalPreHarvestProcessDays}>
                        <div className={clsx(classes.chip, classes.Others)} disabled>
                          {item.productName.split(/[()]/)} Process | {item.totalPreHarvestProcessDays} Days
                        </div>
                      </TableCell>
                    </React.Fragment>
                  }
                  {renderTable(process.processList)}
                </TableRow>
              </React.Fragment>
            )
          })}
          <TableRow className={clsx(classes.tableRow, classes.cardRadius)}>
            {renderHeader(true)}
          </TableRow>
        </Table>
      </div>
    )
  }
  
  React.useEffect(() => {
    tableRef.current.scrollTo(0, 0);
    const totalDays = _.max(
      _.map(value, (timeline) => 
        _.sum(_.union(_.map(timeline.preHarvestTimeLine, (process) => process.processList.reduce((a, {days}) => a + days, 0)), _.map(timeline.postHarvestTimeLine, (process) => process.processList.reduce((a, {days}) => a + days, 0) + timeline.totalPreHarvestProcessDays)))
      )
    )
    setNoOfDays(isNaN(totalDays) ? 0 : totalDays);
  },[value]);
  
  return (
    <div className={classes.recipeTable} ref={tableRef}>
      <Table>
        <TableRow className={classes.tableRow}>
          {value.length>0 && renderHeader()}
        </TableRow>
      </Table>
      {_.map(value, (item, index)=> {
        return renderProduct(item, index);
      })}
    </div>
  );
}

OperationsTimeline.propTypes = {
  header: PropTypes.number,
  value: PropTypes.array,
  pathname: PropTypes.string, 
  prevPath: PropTypes.string,
};