import {
  cardRadius,
  border,
  icon_48,
  icon_64,
  button,
  buttonSecondary,
  primaryColor
} from "assets/jss/generaliseStyle.js";

import {
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange
} from "assets/jss/components/commonStyle.js";

const companyStyle = {
  cardRadius,
  icon_48,
  icon_64,
  button,
  buttonSecondary,
  orange,

  // Search Company
  table,
  result,
  collapsedIcon,
  rotate,
  highlight,
  icon,

  // Display company
  paper: {
    ...cardRadius,
    border,
    display: "flex",
    margin: "auto",
    marginBottom: "1.302vw",
    textAlign: "center",
    padding: "1.823vw",
    width: "52.188vw",
    color: primaryColor[3],
  },
  iconGroup: {
    textAlign: "end"
  },
  img: {
    ...cardRadius,
    border,
    display: "flex",
    margin: "auto",
    width: "14.427vw",
    height: "14.427vw",
    marginBottom: "1.563vw"
  },
  name: {
    fontSize: "1.667vw",
  },
  address: {
    fontSize: "1.094vw",
    color: "rgba(19, 50, 43, 0.65)",
    marginBottom: "2.604vw"
  },
  grid: {
    marginBottom: "1.563vw",
  },
  gridPaper: {
    ...cardRadius,
    border,
    display: "flex",
    alignItems: "center",
    height: "100%",
    minHeight: "6.823vw",
    margin: "0 0.781vw",
    padding: "1.042vw",
  },
  detail: {
    textAlign: "left",
    marginLeft: "0.521vw"
  },
  label: {
    fontSize: "0.729vw"
  },
  value: {
    fontSize: "1.094vw"
  },

  //NewEditCompany
  newPaper: {
    ...cardRadius,
    border,
    display: "flex",
    flexDirection: "column",
    margin: "1.563vw 15.104vw 5.208vw",
    padding: "3.646vw 7.292vw 0"
  },
  imgBorder: {
    ...cardRadius,
    display: "flex",
    height: "8.646vw",
    width: "8.646vw",
    border: "0.104vw solid #DDDDDD",
    marginRight: "0.677vw",
  },
  imgBorderError: {
    border: "0.104vw solid #FF0000",
  },
  addImg: {
    minWidth: "100%"
  },
  errorMsg: {
    textAlign: "right",
    color: "#FF0000",
    marginRight: "0.677vw",
  },
  newIcon: {
    margin: "auto",
    width: "2.500vw",
    height: "2.500vw",
    opacity: 0.5
  },
  newLabel: {
    alignSelf: "center"
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.708vw 0",
    padding: 0
  },
  uploadButton: {
    ...buttonSecondary,
    height: "fit-content"
  },
};

export default companyStyle;
  