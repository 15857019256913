// RFID
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import Select from "shared-components/Select/Select";
import CustomIconButton from "shared-components/Button/IconButton";

import Groupings from "./Groupings";
import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { rowsPerTable } from "config";
import { updateRequest, updateRFIDRequest, reset } from ".";
import { rfidTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllDevices, GetAllRFIDGroup, GetAllRFIDs, DeleteRFID, CreateOrUpdateRFID } from "services/AdminPortal/RFIDService";

import alert from "assets/icons/orange/alert-line.svg";
import settings from "assets/icons/orange/settings.svg";
import styles from "assets/jss/components/AdminPortal/rfidStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function RFID() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.admin.rfid.totalRFIDCount);
  const RFIDs = useSelector(store => store.admin.rfid.RFIDs);
  const RFID = useSelector(store => store.admin.rfid.RFID);
  const devices = useSelector(store => store.admin.rfid.devices);
  const groupings = useSelector(store => store.admin.rfid.groupings);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('serialNo');
  const [page, setPage] = React.useState(0);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [rfidId, setRfidId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [manageGroupings, setManageGroupings] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRFIDRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRFIDRequest({[e.target.name] : e.target.value}));
  };
  
  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setIsAddEdit(true);
    setRfidId(id);
    const value = _.find(RFIDs, (item) => item.id === id);
    dispatch(updateRFIDRequest(value));
  }

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = [...RFIDs, {id: 0}];
    setIsAddEdit(true);
    setRfidId(0);
    setPage(Math.ceil(payload.length / rowsPerTable)-1);
    dispatch(updateRequest({RFIDs: payload}));
  }

  const handleModal_delete = (id) => {
    setRfidId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteRFID(rfidId))
    .then(() =>{
      dispatch(GetAllRFIDs());
      setPage(0);
      resetState();
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateRFID())
      .then((response) =>{
        if (response.error) {
          if (response.payload.message.includes("RFID")) {
            setErrorMsg({field: "rfidId", msg: "RFID ID already exist"});
          }
        } else {
          dispatch(GetAllRFIDs());
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (rfidId === 0) {
      dispatch(updateRequest({RFIDs: _.filter(RFIDs, (item) => item.id !== rfidId)}));
      setPage(0);
    }
    resetState();
  }

  const validateFields = () => {
    if (!RFID.deviceId) {
      setErrorMsg({field: "deviceId", msg: "Please select a device"});
      return false;
    }
    if (!RFID.rfidId) {
      setErrorMsg({field: "rfidId", msg: "Please enter a RFID id"});
      return false;
    }
    if (!RFID.rfidGroupId) {
      setErrorMsg({field: "rfidGroupId", msg: "Please select a group name"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setIsAddEdit(false);
    setRfidId(null);
    setErrorMsg(false);
    setOpenModal(false);
    dispatch(setDirty(false));
    dispatch(updateRequest({RFID: {}}));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllRFIDs());
    dispatch(GetAllDevices());
    dispatch(GetAllRFIDGroup());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const reducedList = RFIDs.reduce((item,{deviceId}) => item.add(deviceId), new Set());
  const deviceLookup = devices.filter(({id}) => !reducedList.has(id));
  if (manageGroupings) {
    return <Groupings setManageGroupings={setManageGroupings} />
  } else {
    return (
      <React.Fragment>
        <Card 
          classes={{
            content: classes.cardContent
          }}
          title="RFID Management"
          subheader="Please pair Device ID to RFID ID. Device ID is extracted from Device Management where IDs are created earlier."
          action={canWrite &&
            <React.Fragment>
              <Button
                className={clsx(classes.buttonSecondary, classes.marginRight)}
                startIcon={<AddIcon />}
                onClick={() => history.push("/admin/rfid/batch")}
                disabled={isAddEdit}
              >
                Download / Upload CSV File
              </Button>
              <Button
                className={classes.buttonSecondary}
                startIcon={<AddIcon />}
                // onClick={() => handleButtonClick_add()}
                disabled={isAddEdit}
              >
                Scan RFID Tags
              </Button>
            </React.Fragment>
          }
        >
          <Paper elevation={0} className={classes.paper}>
            <Table
              className={classes.table}
              header={rfidTableHead.map((head, index) => {
                if (index === 3) {
                  head.label = <React.Fragment>
                    Groupings
                    {canWrite &&
                      <IconButton 
                        onClick={() => setManageGroupings(true)}
                        disabled={isAddEdit}
                      >
                        <img className={classes.icon} src={settings} alt="settings" />
                      </IconButton>
                    }
                  </React.Fragment>
                } 
                return head
              })}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            >
              { _.orderBy(RFIDs, [orderBy], [order])
              .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
              .map((item,index) => {
                const deviceList = item.id ? [{id: item.id, deviceRefNo: item.deviceRefNo}, ...deviceLookup] : deviceLookup;
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.snCell}>
                      { isAddEdit && rfidId === item.id
                      ? ('0'+((page*rowsPerTable)+1+index)).slice(-2)
                      : item.serialNo
                      }
                    </TableCell>
                    <TableCell>
                      { isAddEdit && rfidId === 0 && rfidId === item.id
                      ? <Select
                          name="deviceId"
                          onChange={(e)=>handleOnChange_select(e)}
                          value={renderValue(RFID.deviceId)}
                          placeholder="Select a device"
                          errorMsg={errorMsg}
                        >
                          {deviceList.map((device) => {
                            return <MenuItem key={device.id} value={device.id}>{device.deviceRefNo}</MenuItem>;
                          })} 
                        </Select>
                      : item.deviceRefNo
                      }
                    </TableCell>
                    <TableCell>
                      { isAddEdit && rfidId === item.id
                      ? <TextField 
                          id="rfidId"
                          variant="outlined" 
                          inputProps={{ maxLength: 255 }}
                          onChange={(e) => handleOnChange_text(e)}
                          value={renderValue(RFID.rfidId)}
                          errorMsg={errorMsg}
                        />
                      : item.rfidId
                      }
                    </TableCell>
                    <TableCell>
                      { isAddEdit && rfidId === item.id
                      ? <Select
                          name="rfidGroupId"
                          onChange={(e)=>handleOnChange_select(e)}
                          value={renderValue(RFID.rfidGroupId)}
                          placeholder="Select a group"
                          errorMsg={errorMsg}
                        >
                          {groupings && groupings.map((group) => {
                            return <MenuItem key={group.id} value={group.id}>{group.groupName}</MenuItem>;
                          })} 
                        </Select>
                      : item.rfidGroupName
                      }
                    </TableCell>
                    <TableCell align="right">
                      { canWrite && (isAddEdit && rfidId === item.id
                      ? <React.Fragment>
                          <CustomIconButton 
                            type="confirm"
                            onClick={() => handleButtonClick_confirm()}
                          />
                          <CustomIconButton 
                            type="close"
                            onClick={() => handleButtonClick_cancel()}
                          />
                        </React.Fragment>
                      : <React.Fragment>
                          <CustomIconButton 
                            type="edit"
                            onClick={() => handleButtonClick_edit(item.id)}
                            disabled={Boolean(rfidId && rfidId !== item.id)}
                          />
                          <CustomIconButton 
                            type="delete"
                            onClick={() => handleModal_delete(item.id)}
                            disabled={Boolean(rfidId && rfidId !== item.id)}
                          />
                        </React.Fragment>
                        )
                      }
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
                disabled={Boolean(isAddEdit)}
              />
            }
            {canWrite &&
              <Button
                className={clsx(classes.buttonSecondary, classes.paperButton)}
                startIcon={<AddIcon />}
                onClick={() => handleButtonClick_add()}
                disabled={isAddEdit}
              >
                Add More Records
              </Button>
            }
          </Paper>
        </Card>
        <Modal
          open={openModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete the relationship of this RFID to Device ID? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
