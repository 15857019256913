import React from "react";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Chip from "@mui/material/Chip";
// core components
import styles from "assets/jss/shared-components/chipStyle.js";

const useStyles = makeStyles(styles);

export default function Tag(props) {
  const classes = useStyles();
  return (
    <Chip
      classes={{
        root: classes.tagRoot,
        deleteIcon: classes.tagDelete
      }}
      {...props}
    />
  );
}