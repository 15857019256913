import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Accordion from "shared-components/Accordion/Accordion";

import { minDate } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { reset } from "../store/assignment";
import { GetAllDeliveryTrip, GetDeliveryTripSetting } from "services/UserPortal/DeliveryService";

import Maps from "./Maps";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

export default function MapView() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const results = useSelector(store => store.user.delivery.master.results);
  const [grouped, setGrouped] = React.useState({progress: [], pending: [], completed: []});
  const [expanded, setExpanded] = React.useState(null);
  
  const handleButtonClick_accordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  
  const getAllDeliveryTrips = (stopLoading) => {
    dispatch(GetAllDeliveryTrip({page: 0, maxResultCount: 9999, stopLoading}));
  }

  const renderDetails = (item, index) => {
    return (
      <div key={index} className={classes.accordionItem}>
        <b>Trip ID: </b>{item.deliveryTripRefNo}<br />
        <b>Start Time: </b>{moment(item.tripStartTime).isAfter(minDate) ? moment(item.tripStartTime).format("DD/MM/YYYY HH:mm") : "-"}<br />
        <b>End Time: </b>{moment(item.tripEndTime).isAfter(minDate) ? moment(item.tripEndTime).format("DD/MM/YYYY HH:mm") : "-"}<br />
        <b>Driver: </b>{item.driverName}<br />
        <b>Vehicle Plate: </b>{item.vehiclePlate}<br />
        <b>Vehicle ID: </b>{item.vehicleRefNo}<br />
      </div>
    )
  }

  React.useEffect(() => {
    const progress = _.filter(results, (item)=>item.status === "In Progress");
    const pending = _.filter(results, (item)=>item.status === "Pending");
    const completed = _.filter(results, (item)=>item.status === "Completed");
    setGrouped({progress, pending, completed});
  },[results]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    getAllDeliveryTrips();
    dispatch(GetDeliveryTripSetting());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllDeliveryTrips(true);
  }, 10000);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: "flex"
        }}
        title="Map View"
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button
              className={classes.button}
              onClick={() => history.push("/user/delivery/master-list")}
            >
              List View
            </Button>
          </React.Fragment>
        }
      >
        <Card
          classes={{
            root: clsx(classes.cardPaper, classes.mapCardLeft),
            title: classes.cardTitle,
            content: classes.cardContent
          }}
          title="Trips"
        >
          <Accordion
            classes={{
              root: classes.subAccordion,
              header: classes.header
            }}
            expanded={expanded === 0}
            onChange={handleButtonClick_accordion(0)}
            header={"In Progress ("+grouped.progress.length+")"}
          >
            <div className={classes.accordionContent}>
              {grouped.progress.map((item,index) => {
                if (item.status === "In Progress") {
                  return renderDetails(item, index);
                }
              })}
            </div>
          </Accordion>
          <Accordion
            classes={{
              root: classes.subAccordion,
              header: classes.header
            }}
            expanded={expanded === 1}
            onChange={handleButtonClick_accordion(1)}
            header={"Not Yet Started ("+grouped.pending.length+")"}
          >
            <div className={classes.accordionContent}>
              {grouped.pending.map((item,index) => {
                if (item.status === "Pending") {
                  return renderDetails(item, index);
                }
              })}
            </div>
          </Accordion>
          <Accordion
            classes={{
              root: classes.subAccordion,
              header: classes.header
            }}
            expanded={expanded === 2}
            onChange={handleButtonClick_accordion(2)}
            header={"Completed ("+grouped.completed.length+")"}
          >
            <div className={classes.accordionContent}>
              {grouped.completed.map((item,index) => {
                if (item.status === "Completed") {
                  return renderDetails(item, index);
                }
              })}
            </div>
          </Accordion>
        </Card>
        <Card
          classes={{
            root: clsx(classes.cardPaper, classes.mapCardRight),
            title: classes.cardTitle,
            content: classes.cardContent
          }}
          title="Current Location of Vehicles (In Progress)"
        >
          <Maps progress={grouped.progress} />
        </Card>
      </Card>
    </React.Fragment>
  );
}
