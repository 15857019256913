import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";

import AllOverview from "./AllOverview";
import BankOverview from "./BankOverview";
import CameraView from "./CameraView";
import CageView from "./CageView";
import { setValues } from "../store/production";
import { farmConfig } from "enums/Constants";
import { setSearchText } from "store/general";
import { renderValue } from 'common/functions';
import { GetBankOverview, GetBankLookUp } from "services/UserPortal/MonitoringService";

import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function Production(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.monitoring.production.filter);
  const bankList = useSelector(store => store.user.monitoring.production.bankList);
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [bankId, setBankId] = React.useState("all");
  const [newFilter, setNewFilter] = React.useState({bankId: "all"});
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetBankLookUp());
    dispatch(GetBankOverview());
    dispatch(setValues({filter: newFilter}));
  },[]);

  React.useEffect(() => {
    filter.bankId && setBankId(filter.bankId)
  },[filter]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return (bankId === "all" ? <AllOverview setBankId={setBankId}/> : <BankOverview bankId={bankId} />)
      case 1:
        return <CameraView bankId={bankId} />
      case 2:
        return <CageView bankId={bankId} />
    } 
  }

  return (
    <React.Fragment>
      <Card 
        title="Production Monitoring"
        subheader={
          <React.Fragment>
            Choose any one of the links under the {farmConfig.bank} summary table column to view the details.
            <Tabs 
              tabs={bankId === "all" ? ["Overview of "+farmConfig.bank] : ["Overview of "+farmConfig.bank, "Camera View", farmConfig.bank+" / "+farmConfig.cage+" View"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
            />
          </React.Fragment>
        }
        
        action={(tab === 0)&&
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose a {farmConfig.bank}
                  <Select
                    className={classes.filterDropdown}
                    name="bankId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder={"Select a "+farmConfig.bank}
                    value={renderValue(newFilter.bankId)}
                  >
                    {tab===0 && <MenuItem value="all">All {farmConfig.banks}</MenuItem>}
                    {bankList && bankList.map((item, index) => {
                      return <MenuItem key={index} value={item.bankId}>{item.bankName}</MenuItem>;
                    })}
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
