import {
  icon_64,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

const errorStyle = {
  icon_64,
  container: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    textAlign: "center",
    width: "max-content",
    height: "88vh"
  },
  errorImg: {
    width: "48.375vw",
    height: "17.672vw",
    margin: "auto",
    marginBottom: "2.604vw"
  },
  emptyImg: {
    width: "39.067vw",
    height: "25.326vw",
    margin: "auto",
    marginBottom: "2.604vw"
  },
  description: {
    fontSize: "1.250vw",
    color: "rgba(17, 42, 17, 0.7)"
  },

  // Loading
  progress: {
    width: "4.167vw !important",
    height: "4.167vw !important",
    color: secondaryColor[0],
    marginBottom: "2.083vw",
  },
  loadingDesc: {
    fontSize: "1.667vw",
    color: primaryColor[3],
  }
};

export default errorStyle;
    