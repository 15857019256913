import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import preval from "preval.macro";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @material-ui/icon
import SmartphoneIcon from '@mui/icons-material/Smartphone';
// core components
import Card from "shared-components/Card/Card";

import APK from "./APK";
import Patents from "./Patents";
import patentWhite from "assets/icons/white/patent.svg";
import patent from "assets/icons/orange/patent.svg";
import styles from "assets/jss/components/userSettingsStyle.js";

import { reset } from ".";

const useStyles = makeStyles(styles);

export default function About() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hoverIndex, setHoverIndex] = React.useState(null);
  const [page, setPage] = React.useState(false);

  const handleMouseOver = (key) => {
    setHoverIndex(key);
  };

  const handleMouseOut = () => {
    setHoverIndex(null);
  };

  const renderContainer = () => {
    switch(page) {
      case 0:
        return <APK setPage={setPage} />
      case 1:
        return <Patents setPage={setPage} />
      default: 
        return (
          <Card 
            className={classes.container}
            classes={{
              // root: classes.container,
              // content: classes.content,
              cardActions: classes.actions
            }}
            title="About"
          >
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <Paper elevation={0} className={classes.paper} 
                  onMouseOver={()=>handleMouseOver(0)}
                  onMouseOut={()=>handleMouseOut()}
                  onClick={()=>setPage(0)}
                >
                  <SmartphoneIcon className={clsx(classes.logoImage, hoverIndex === 0 && classes.white)} />
                  <div className={classes.logoTitle}>Mobile App</div>
                </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={0} className={classes.paper} 
                  onMouseOver={()=>handleMouseOver(1)}
                  onMouseOut={()=>handleMouseOut()}
                  onClick={()=>setPage(1)}
                >
                  <img className={classes.logoImage} src={(hoverIndex===1)?patentWhite:patent} alt="patent" />
                  <div className={classes.logoTitle}>Patents Information</div>
                </Paper>
              </Grid>
            </Grid>
            <Typography className={classes.instruction} style={{marginTop: "1.250vw"}}>Build Date: {preval`module.exports = new Date().toLocaleString();`}.</Typography>
          </Card>
        );
    }
  }

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      {renderContainer()}
    </React.Fragment>
  );
}
