import { createSlice } from "@reduxjs/toolkit";
import { GetOperationTimeLineByWorkOrderId, GetOperationProcessProgress, GetCageIDList, GetHarvestingDashboard } from "services/UserPortal/OperationsService";

// initial state
const initialState = {
  filter: {},
  products: [],
  processOverview: {},
  cageIDList: [],
  harvestDashboard: [],
};

const overviewOperations = createSlice({
  name: "overviewOperations",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetOperationTimeLineByWorkOrderId.fulfilled]: (state, action) => {
      state.products = action.payload.result;
    },
    [GetOperationProcessProgress.fulfilled]: (state, action) => {
      state.processOverview = action.payload.result;
    },
    [GetCageIDList.fulfilled]: (state, action) => {
      state.cageIDList = action.payload.result;
    },
    [GetHarvestingDashboard.fulfilled]: (state, action) => {
      state.harvestDashboard = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = overviewOperations.actions;

// export the reducer
export default overviewOperations.reducer;