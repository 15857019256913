import { createSlice } from "@reduxjs/toolkit";

import { GetAllEmployees, GetEmployee, GetAllTimeInterval } from "services/AdminPortal/HumanResourceService";

// initial state
const initialState = {
  totalCount: 0,
  employees: [],
  employee: {},
  timeInterval: [],
};

const hrConfiguration = createSlice({
  name: "hrConfiguration",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.employee = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.employee[key] = action.payload[key];
      })
    },
    resetEmployee: (state) => {
      state.employee = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllEmployees.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.employees = action.payload.result.items;
    },
    [GetEmployee.fulfilled]: (state, action) => {
      state.employee = action.payload.result;
    },
    [GetAllTimeInterval.fulfilled]: (state, action) => {
      state.timeInterval = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, resetEmployee, reset } = hrConfiguration.actions;

// export the reducer
export default hrConfiguration.reducer;