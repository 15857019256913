import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tag from 'shared-components/Chip/Tag';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";

import { tripStatus } from "enums/Constants";
import { minDate } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { renderValue, filterParam } from 'common/functions';
import { setValues, reset } from "../store/master";
import { GetDeliveryTripDetail, GetDeliveryTripSetting, GetDeliveryTripLocationByTripId } from "services/UserPortal/DeliveryService";

import DetailMaps from "./DetailMaps";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

export default function Details() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.delivery.master.filter);
  const results = useSelector(store => store.user.delivery.master.details);
  const lastKnownLocation = useSelector(store => store.user.delivery.master.lastKnownLocation);
  const [newFilter, setNewFilter] = React.useState({deliveryOrderRefNo: null});

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };

  const getTripDetails = () => {
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "master-list");
    dispatch(GetDeliveryTripDetail({id: path[index+1]}))
    .then(({error}) => {
      if (error) {
        history.push("/user/delivery/master-list");
      }
    });
  }

  const getTripLocation = (stopLoading) => {
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "master-list");
    dispatch(GetDeliveryTripLocationByTripId({tripId: path[index+1], stopLoading}));
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    getTripDetails();
    dispatch(GetDeliveryTripSetting());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    if (results.status === "In Progress") {
      getTripLocation(true);
    }
  }, 10000);
  
  return (
    <React.Fragment>
      <Card 
        classes={{
          content: "flex"
        }}
        title="Details of the Trip"
        subheader={
          <React.Fragment>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>Vehicle Details:</Typography>
                <Typography>{results.vehicleRefNo} ({results.vehiclePlate})</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Driver Details:</Typography>
                <Typography>{results.driverName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Trip ID:</Typography>
                <Typography>{results.deliveryTripRefNo}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Delivery Order Number:</Typography>
                <Typography>{results.deliveryOrders && results.deliveryOrders.map(({deliveryOrderRefNo}) => deliveryOrderRefNo).join(", ")}</Typography>
              </Grid>
            </Grid>
            { Object.keys(filter).map((key) => {
              if (filter[key]) {
                let label = filter[key];
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: newFilter}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose a filter to view the the delivery order
                  <Select
                    className={classes.filterDropdown}
                    name="deliveryOrderRefNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a delivery order"
                    value={renderValue(newFilter.deliveryOrderRefNo)}
                  >
                    {results.deliveryOrders && results.deliveryOrders.map((item, index) => {
                      return <MenuItem key={index} value={item.deliveryOrderRefNo}>{item.deliveryOrderRefNo}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
        cardActions={
          <Button
            className={classes.buttonSecondary}
            onClick={()=>history.push("/user/delivery/master-list")}
          >
            Go Back
          </Button>
        }
      >
        <Card
          classes={{
            root: clsx(classes.cardPaper, classes.cardLeft),
            title: classes.cardTitle,
            content: classes.cardContent
          }}
          title="Number of Stops"
          action={
            <div className={classes.cardTitle}>
              <span className={classes.detailStatus}>Start Time:</span>
              <span className={classes.detailDate}>{moment(results.tripStartTime).isAfter(minDate) ? moment(results.tripStartTime).format("DD/MM/YYYY HH:mm") : "-"}</span>
              <span className={classes.detailStatus}>End Time:</span>
              <span className={classes.detailDate}>{moment(results.tripEndTime).isAfter(minDate) ? moment(results.tripEndTime).format("DD/MM/YYYY HH:mm") : "-"}</span>
            </div>
          }
        >
          {results.deliveryTripDetails && results.deliveryTripDetails.map((item,index) => { 
            if (item.deliveryOrder && (!filter.deliveryOrderRefNo || filter.deliveryOrderRefNo === item.deliveryOrder.deliveryOrderRefNo)) {
              return (
                <Grid className={classes.detailGrid} container spacing={0} key={index}>
                  <Grid item xs={2} className={classes.detailStatus}>Stop {index + 1}:</Grid>
                  <Grid item xs={10} className="flex">
                    <span className={classes.detailLabels}>Location: </span>
                    <span className={classes.detailAddress}>{item.endPoint}</span>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={10} className="flex">
                    <span className={classes.detailLabels}>Status: </span>
                    <span className={clsx(classes.detailStatus, item.deliveryOrder.deliveryOrderStatus.status === tripStatus.started && classes.progress, item.deliveryOrder.deliveryOrderStatus.status === tripStatus.delivered && classes.completed)}>{item.deliveryOrder.deliveryOrderStatus.status}</span>
                    {item.deliveryOrder.deliveryOrderStatus.status === tripStatus.delivered && <span>&nbsp;({moment(item.deliveryOrder.signedDateTime).format("DD/MM/YYYY HH:mm")})</span>}
                  </Grid>
                </Grid>
              )
            }
          })}
        </Card>
        <Card
          classes={{
            root: clsx(classes.cardPaper, classes.cardRight),
            title: classes.cardTitle,
            content: classes.cardContent
          }}
          title={"Location of "+results.driverName}
        >
          <DetailMaps 
            stops={results.deliveryTripDetails ?? []} 
            status={results.status} 
            deliveryRoute={results.deliveryRoute} 
            location={{latitude: lastKnownLocation.lastKnownLocationLatitude, longitude: lastKnownLocation.lastKnownLocationLongitude}} 
            driverName={results.driverName}
            deliveryTripRefNo={results.deliveryTripRefNo}
          />
        </Card>
      </Card>
    </React.Fragment>
  );
}
