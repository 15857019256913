import {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  backgroundColor,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const hrStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  orange,
  tag,
  link,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  cardAction: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.563vw",
  },
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  mainTable: {
    '& thead > tr > th:not(:last-child)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },

  // Log Sheet
  tabHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabMargin: {
    marginBottom: "1.563vw"
  },
  tabContent: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap"
  },
  tabTitle: {
    fontSize: "1.250vw",
    alignSelf: "center"
  },
  tabSubheader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.563vw"
  },
  radio: {
    marginLeft: "0.625vw",
    marginRight: "2.396vw"
  },
  bold: {
    fontWeight: 500
  },
  resultContainer: {
    textAlign: "center",
    margin: "auto"
  },
  resultText: {
    fontSize: "1.250vw",
    margin: "2.083vw 0 1.042vw"
  },

  // Labour Cost
  switch: {
    margin: "0 2.083vw 0 1.042vw"
  },
  detailTitle: {
    fontSize: "1.354vw",
    fontWeight: 500,
  },
  divider: {
    margin: "1.719vw -1.979vw 2.083vw",
  },
  floatRight: {
    float: "right"
  },

  // Availability
  overallTitle: {
    fontWeight: 500,
    fontSize: "0.938vw",
    textAlign: "center"
  },
  timeline: {
    overflow: "overlay",
    height: "31.250vw"
  },
  timelineTable: {
    borderCollapse: "separate",
    borderSpacing: "0 0.521vw",
  },
  colWidth: {
    "& > td:not(:first-child)": {
      width: "5.729vw",
      minWidth: "5.729vw",
      maxWidth: "5.729vw",
    },
    "& > td:nth-child(2)": {
      paddingLeft: "0 !important"
    },
    "& > td:first-child": {
      width: "10.417vw",
      minWidth: "10.417vw",
      maxWidth: "10.417vw"
    }
  },
  tableLabel: {
    fontWeight: 500,
    fontSize: "0.938vw",
    borderTop: "-10px solid " + whiteColor,
  },
  stickyBackground: {
    position:"absolute",
    top: "-0.625vw",
    left: 0,
    height: "1.042vw",
    width: "10.417vw",
    backgroundColor: whiteColor,
  },
  availableTimeline: {
    "&:nth-child(2)": {
      paddingLeft: "0.833vw !important",
      borderLeft: "0.156vw solid" + secondaryColor[0],
      borderTopLeftRadius: "0.938vw",
      borderBottomLeftRadius: "0.938vw",
    },
    "&:last-child": {
      paddingRight: "0.833vw !important",
      borderRight: "0.156vw solid" + secondaryColor[0],
      borderTopRightRadius: "0.938vw",
      borderBottomRightRadius: "0.938vw",
    },
    borderTop: "0.156vw solid"+secondaryColor[0],
    borderBottom: "0.156vw solid"+secondaryColor[0]+"!important",
  },
  availableTimelineLabel:{
    position:"absolute",
    top: "-0.625vw",
    color: secondaryColor[0],
    backgroundColor: whiteColor,
    fontWeight: 500,
    fontSize: "0.833vw",
    padding: "0 0.208vw",
    width: "max-content",
    zIndex: 1,
  },
  labelFont: {
    fontSize: "1.354vw",
    fontWeight: 500
  },
  tableCell: {
    position: "relative",
    borderBottom: "none",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    width: "5.729vw",
    minWidth: "5.729vw",
    maxWidth: "5.729vw",
  },
  hiddenContainer: {
    // display: "none"
  },
  woContainer: {
    display: "flex",
    alignItems: "center",
    top: "1.042vw",
    position: "absolute"
  },
  chip: {
    borderRadius: "1.250vw",
    padding: "0.625vw 0.521vw",
    fontSize: "0.729vw",
    fontWeight: 500,
    border: "0.104vw solid #000",
    position: "relative",
    textAlign: "center",
  },
  woChip: {
    backgroundColor: "#8CC2C9",
    height: "fit-content",
  },
  1: {
    backgroundColor: "#ff9e9e !important",
  },
  2: {
    backgroundColor: "#F9E894 !important",
  },
  3: {
    backgroundColor: "#DEE897 !important",
  },
  4: {
    backgroundColor: "#B2DCA8 !important",
  },
  mainChip: {
    backgroundColor: "#C4C4C4"
  },
  idleChip: {
    backgroundColor: "#F6F972"
  },
  emptyDesc: {
    height: "4vw"
  },
  popper: {
    zIndex: 99,
    marginBottom: "1.042vw",
    "& div": {
      padding: "0.625vw 0.521vw",
      backgroundColor: backgroundColor
    },
    "& div > span": {
      color: backgroundColor
    }
  },
  popperContent: {
    color: primaryColor[4]+" !important",
  },

  // Admin Log Sheet
  emailIcon: {
    color: secondaryColor[0]
  },
  iconDisabled: {
    color: "#5a706b"
  },

  // Detail Modal
  detailModal: {
    // textAlign: "left",
    padding: "1.302vw 1.979vw 1.302vw 0",
    width: "40vw"
  },
  modalContent: {
    display: "flex",
    marginTop: "1.458vw",
    height: "fit-content",
    maxHeight: "12.740vw",
    overflow: "overlay",
    paddingLeft: "1.979vw"
  },
}

export default hrStyle;