import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetSubSaleOrderWeight, CalculateNumberOfPackage, CalculateExpiredDate, GetAllFinishedGoodsInAndOut, GetPackageList, GetProductListBySubSaleOrderId } from "services/UserPortal/FinishedGoodsService";

// initial state
const initialState = {
  filter: {},
  summary: [],
  totalCount: 0,
  detail: [],
  checkInList: [],
  finishedGoods: {dateIn: moment().format("YYYY-MM-DD"), expectedDateOut: moment().format("YYYY-MM-DD")},
  order: {},
  //lookup
  subSaleOrderList: [],
  allRackNumber: [],
  packetList: [],
  subSaleOrderWeight: {},
  productList: [],
};

const finishedGoodsIn = createSlice({
  name: "finishedGoodsIn",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.finishedGoods[key] = action.payload[key];
      })
    },
    updateExcessOrderRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.order[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [CalculateNumberOfPackage.fulfilled]: (state, action) => {
      state.finishedGoods.numberOfPacket = action.payload.result;
    },
    [CalculateExpiredDate.fulfilled]: (state, action) => {
      state.finishedGoods.expiredDate = action.payload.result;
    },
    [GetAllFinishedGoodsInAndOut.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.detail = action.payload.result.items;
    },
    [GetPackageList.fulfilled]: (state, action) => {
      state.packetList = action.payload.result;
    },
    [GetSubSaleOrderWeight.fulfilled]: (state, action) => {
      state.order.weight = action.payload.result.weight;
      state.order.uom = action.payload.result.uom;
    },
    [GetProductListBySubSaleOrderId.fulfilled]: (state, action) => {
      state.productList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = finishedGoodsIn.actions;

// export the reducer
export default finishedGoodsIn.reducer;