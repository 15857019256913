import { createSlice } from "@reduxjs/toolkit";

import { SearchFinishedGoodsInList, SearchFinishedGoodsOutList, SearchFinishedGoodsMasterList, SearchFinishedGoodsDisposalList, SearchFinishedGoodsDOCreation } from "services/UserPortal/FinishedGoodsService";

// initial state
const initialState = {
  fgTotalCount: 0,
  inTotalCount: 0,
  outTotalCount: 0,
  disposalTotalCount: 0,
  doTotalCount: 0,
  fgResults: [],
  inResults: [],
  outResults: [],
  disposalResults: [],
  doResults: [],
};

const fgSearch = createSlice({
  name: "fgSearch",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [SearchFinishedGoodsInList.fulfilled]: (state, action) => {
      state.inTotalCount = action.payload.result.totalCount;
      state.inResults = action.payload.result.items;
    },
    [SearchFinishedGoodsOutList.fulfilled]: (state, action) => {
      state.outTotalCount = action.payload.result.totalCount;
      state.outResults = action.payload.result.items;
    },
    [SearchFinishedGoodsMasterList.fulfilled]: (state, action) => {
      state.fgTotalCount = action.payload.result.totalCount;
      state.fgResults = action.payload.result.items;
    },
    [SearchFinishedGoodsDisposalList.fulfilled]: (state, action) => {
      state.disposalTotalCount = action.payload.result.totalCount;
      state.disposalResults = action.payload.result.items;
    },
    [SearchFinishedGoodsDOCreation.fulfilled]: (state, action) => {
      state.doTotalCount = action.payload.result.totalCount;
      state.doResults = action.payload.result.items;
    },
  },
});

// export actions
export const { setValues, reset } = fgSearch.actions;

// export the reducer
export default fgSearch.reducer;