import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Radio from 'shared-components/Radio/Radio';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Select from "shared-components/Select/Select";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import DetailsModal from "../DetailsModal";
import { rowsPerTable } from "config";
import { renderValue, formatNumbers, roundTo2Decimal } from "common/functions";
import { useInterval } from 'common/utils';
import { hrPastLogSheetTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest } from "../store/logSheet";
import { GetAllLogSheet } from "services/UserPortal/HumanResourceService";

import styles from "assets/jss/components/UserPortal/hrStyle.js";

const useStyles = makeStyles(styles);

export default function Records(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const totalTime = useSelector(store => store.user.hr.logSheet.totalTime);
  const totalCount = useSelector(store => store.user.hr.logSheet.totalCount);
  const isLogged = useSelector(store => store.user.hr.logSheet.isLogged);
  const result = useSelector(store => store.user.hr.logSheet.logSheet);
  const [filter, setFilter] = React.useState({date: moment().format("YYYY-MM-DD")});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('workOrderNumber');
  const [page, setPage] = React.useState(0);
  const [workingDay, setWorkingDay] = React.useState(null);
  const [workingDayType, setWorkingDayType] = React.useState(null);
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);

  const leaveTypeList = [
    {name: "Full Day Leave", value: "FullDayLeave"},
    {name: "Half Day AM Leave", value: "HalfDayAMLeave"},
    {name: "Half Day PM Leave", value: "HalfDayPMLeave"},
  ];
  
  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      setFilter({...filter, date: moment(value).format("YYYY-MM-DD")});
    } 
  };

  const handleButtonClick_radio = (e) => {
    dispatch(updateRequest({[e.target.name]: e.target.value}));
  };

  const handleModal_details = (value) => {
    setOpenDetailsModal(value);
  };

  const getLogSheet = (pageNo, stopLoading) => {
    dispatch(GetAllLogSheet({...filter, page: pageNo, stopLoading}))
    .then((response) => {
      if (!response.error) {
        const item = response.payload.result.items;
        if (item && item.length) {
          setWorkingDay(item[0].workingDayType.includes("Leave") ? "Leave" : item[0].workingDayType);
          setWorkingDayType(item[0].workingDayType);
        }
      }
    });
  }

  React.useEffect(() => {
    setPage(0);
    getLogSheet(0);
  },[filter, props.tab]);

  React.useEffect(() => {
    getLogSheet(page);
  },[page]);

  useInterval(() => {
    getLogSheet(page, true);
  });

  return (
    <React.Fragment>
      <div className={classes.tabHeader}>
        <Typography className={classes.tabTitle}>{"Information Correct as at " + moment().format("DD/MM/YYYY, HHmm")}</Typography>
        <DateTimePicker
          closeOnSelect
          placeholder="Select a date"
          maxDate={moment()}
          className={classes.cardActionDropdown}
          value={filter.date}
          onChange={(e) => handleOnChange_date(e)}
        />
      </div>
      {!isLogged
      ? <div className={classes.resultContainer}>
          <Typography className={classes.resultText}>Log sheet not submitted for this date</Typography>
          <Typography className={classes.resultDetail}>Submit log sheet in &quot;New Entry&quot; tab</Typography>
        </div>
      : <React.Fragment>
          <div className={clsx(classes.tabContent, classes.tabMargin)}>
            <Typography>Full Working Day:</Typography>
            <Radio 
              className={classes.radio}
              checked={workingDay === "FullWorkingDay"}
              onChange={(e)=>handleButtonClick_radio(e)}
              disabled
            />
            <Typography>Rest Day:</Typography>
            <Radio 
              className={classes.radio}
              checked={workingDay === "Rest"}
              disabled
            />
            <Typography>Leave:</Typography>
            <Radio 
              className={classes.radio}
              checked={workingDay === "Leave"}
              disabled
            />
            {workingDay === "Leave" && 
              <Select
                className={classes.cardActionDropdown}
                name="workingDayType"
                placeholder=""
                value={renderValue(workingDayType)}
                disabled
              >
                {leaveTypeList && leaveTypeList.map((option, i) => {
                return <MenuItem key={i} value={option.value}>{option.name}</MenuItem>;
              })} 
              </Select>
            }
          </div>
          <Table
            className={classes.table}
            header={hrPastLogSheetTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.workOrderNumber}</TableCell>
                    <TableCell>{item.productRefNo}</TableCell>
                    <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                    <TableCell>{item.productProcessName}</TableCell>
                    <TableCell>{item.taskNumber}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.timeSpent))}</TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell className={classes.bold}>Total Time Spent</TableCell>
              <TableCell className={classes.bold}>{formatNumbers(roundTo2Decimal(totalTime))} mins / {formatNumbers(roundTo2Decimal(totalTime/60))} hours</TableCell>
            </TableRow>
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </React.Fragment>
      }
      <DetailsModal 
        open={Boolean(openDetailsModal)}
        onClose={() => handleModal_details(null)}
        title="workOrderNumber"
        id="id"
        data={openDetailsModal}
      />
    </React.Fragment>
  );
}
