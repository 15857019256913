import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// core components
import Modal from "shared-components/Modal/Modal";
import Radio from 'shared-components/Radio/Radio';
import TextField from 'shared-components/TextField/TextField';
import Checkbox from "shared-components/Checkbox/Checkbox";

import { renderValue } from "common/functions";
import { farmConfig } from "enums/Constants";
import { setSearchText, setDirty } from "store/general";
import { updateRequest, reset } from "../store/settings";
import { GetPlanningParametersByFarmId } from "services/UserPortal/PlanningService";

import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function Parameters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const parameters = useSelector(store => store.user.planning.settings.parameters);
  const [openModal, setOpenModal] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleOnChange_radio = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.name]: e.target.value}));
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id]: parameters[e.target.id]}));
  };

  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
  }

  const handleButtonClick_save = () => {
    // dispatch(CreateOrUpdatePlanningConfiguration())
    // .then(({error})=> {
    //   if (!error) {
    //     dispatch(setDirty(false));
    //   }
    //   setOpenModal(true);
    // })
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetPlanningParametersByFarmId());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.settingsTitle}>Basis of Rounding Off for Tray/{farmConfig.cage} Calculation:</Typography>
      <div>
        <Radio 
          id="roundingBasis"
          value="Per Order Basis"
          checked={parameters.roundingBasis==="Per Order Basis"}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Per Order Basis<br/>
        <Radio 
          id="roundingBasis"
          value="Per Product Basis"
          checked={parameters.roundingBasis==="Per Product Basis"}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Per Product Basis
      </div>
      <Typography className={classes.settingsTitle}>Planning Days Ahead of Production Cycle:</Typography>
      <div className={classes.settingsDiv}>
        <TextField 
          className={classes.settingsTextfield}
          id="planningDays"
          variant="outlined" 
          type="Number"
          onInput={(e)=>{ 
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,5)
          }}
          placeholder="Enter number of days"
          onChange={(e) => handleOnChange_text(e)}
          value={renderValue(parameters.planningDays)}
          disabled
        />days
      </div>
      <Typography className={classes.settingsTitle}>Allow for Part Thereof of Orders? (i.e. allow for some orders to pass through although not all orders in Sales orders can be fulfilled)</Typography>
      <div>
        <Radio 
          id="allowedForPartThereOf"
          checked={parameters.allowedForPartThereOf}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />True<br/>
        <Radio 
          id="allowedForPartThereOf"
          checked={!parameters.allowedForPartThereOf}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />False
      </div>
      <Typography className={classes.settingsTitle}>Factors for Planning:</Typography>
      <div>
        <Checkbox 
          id="checkIfRawMaterial"
          checked={Boolean(parameters.checkIfRawMaterial)}
          onChange={(e) => handleButtonClick_checkbox(e)}
          disabled
        />Adequacy of Raw Materials<br/>
        <Checkbox 
          id="checkIfEquipment"
          checked={Boolean(parameters.checkIfEquipment)}
          onChange={(e) => handleButtonClick_checkbox(e)}
          disabled
        />Availability of Equipment<br/>
        <Checkbox 
          id="checkIfCageLocation"
          checked={Boolean(parameters.checkIfCageLocation)}
          onChange={(e) => handleButtonClick_checkbox(e)}
          disabled
        />Adequacy of Cage Location<br/>
        <Checkbox 
          id="checkIfProductLeadTime"
          checked={Boolean(parameters.checkIfProductLeadTime)}
          onChange={(e) => handleButtonClick_checkbox(e)}
          disabled
        />Adequacy of Product Lead Time<br/>
        <Checkbox 
          id="checkIfDailyLimit"
          checked={Boolean(parameters.checkIfDailyLimit)}
          onChange={(e) => handleButtonClick_checkbox(e)}
          disabled
        />Maximum Daily Order Quantity Check<br/>
      </div>
      <Typography className={classes.settingsTitle}>Product which is of priority</Typography>
      <div>
        <Radio 
          id="priorityType"
          checked={parameters.priorityType==="Primary Product"}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Primary Product<br/>
        <Radio 
          id="priorityType"
          checked={parameters.priorityType==="Mixed Product"}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Mixed Product
      </div>
      <Typography className={classes.settingsTitle}>Basis of Planning</Typography>
      <div>
        <Radio 
          id="planningBasis"
          checked={parameters.planningBasis==="Per Tray Planning"}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Per Tray Planning<br/>
        <Radio 
          id="planningBasis"
          checked={parameters.planningBasis==="Per Cage Planning"}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Per Cage Planning
      </div>
      <Typography className={classes.settingsTitle}>Per Tray Round Up</Typography>
      <div>
        <Radio 
          id="perTrayRoundUp"
          checked={Boolean(parameters.perTrayRoundUp)}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Yes<br/>
        <Radio 
          id="perTrayRoundUp"
          checked={Boolean(!parameters.perTrayRoundUp)}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />No
      </div>
      <Typography className={classes.settingsTitle}>Loss Rate Calculation</Typography>
      <div>
        <Radio 
          id="loopBackward"
          checked={Boolean(parameters.lossRateCalculation === "Loop Forward Method")}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Loop Forward Method<br/>
        <Radio 
          id="loopBackward"
          checked={Boolean(parameters.lossRateCalculation === "Loop Backward Method")}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Loop Backward Method<br/>
        <Radio 
          id="loopBackward"
          checked={Boolean(parameters.lossRateCalculation === "Greenphyto's Prototype farm calculation")}
          onChange={(e)=>handleOnChange_radio(e)}
          disabled
        />Greenphyto&apos;s Protoype Farm Calculation
      </div>
      <div className={classes.settingsButton}>
        <Button className={classes.buttonSecondary} onClick={() => handleButtonClick_cancel()} disabled>Cancel</Button>
        <Button className={classes.button} onClick={() => handleButtonClick_save()} disabled>Save</Button>
      </div>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={<img className={classes.icon_64} src={confirm} alt="confirm" />}
        title="Success"
        content="Your selection has been saved."
        actions={
          <React.Fragment>
            <Button className={classes.button} onClick={() => handleModal()}>Okay</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
