import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetAllEnergyMonitoring, GetAllEnergyDevices, GetAllEnergyUnit } from "services/UserPortal/SmartMeteringService";

// initial state
const initialState = {
  filter: {startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")},
  result: {},
  costResult: [],
  // lookup
  deviceList: [],
  unitList: []
};

const smParameters = createSlice({
  name: "smParameters",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    // updateRequest: (state, action) => {
    //   Object.keys(action.payload).forEach((key) => {
    //     state.batch[key] = action.payload[key];
    //   })
    // },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllEnergyMonitoring.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
    // lookup
    [GetAllEnergyDevices.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
    [GetAllEnergyUnit.fulfilled]: (state, action) => {
      state.unitList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = smParameters.actions;

// export the reducer
export default smParameters.reducer;