import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "shared-components/Checkbox/Checkbox";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Radio from 'shared-components/Radio/Radio';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import PairingConfiguration from "./PairingConfiguration";
import { setDirty } from "store/general";
import { sensorControlTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest } from "../store/sensorControl";
import { UpdateSensorControlConfiguration  } from "services/UserPortal/ClimateService";
import { GetFunctionLibraryList } from "services/UserPortal/CommonLookupService";

import { useRouteCanWrite } from "hooks";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function SensorControl(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isDirty = useSelector(store => store.general.isDirty);
  const libraries = useSelector(store => store.user.climate.sensorControl.libraries);
  const [libraryId, setLibraryId] = React.useState(null);
  const [selectedLibraryId, setSelectedLibraryId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [pairingConfiguration, setPairingConfiguration] = React.useState(false);

  const prop = props.location && props.location.state;

  const handleOnChange_select = (e) => {
    setSelectedLibraryId(Number(e.target.value));
  };

  const handleOnChange_checkbox = (e) => {
    dispatch(setDirty(true));
    const {id, value} = e.target;
    let payload = _.cloneDeep(libraries);
    let library = _.find(payload, ({id}) => id === selectedLibraryId);
    let index = _.findIndex(library.deviceFunctionList, ({id}) => id === Number(value));
    library.deviceFunctionList[index][id] = !library.deviceFunctionList[index][id];
    payload = payload.map((item) => {
      if (item.id === selectedLibraryId) {
        return {...library, isConfigured: true}
      }
      return item;
    })
    dispatch(updateRequest({libraries: payload}));
  };

  const handleModal = (id) => {
    setLibraryId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(libraries);
    payload = payload.map((library) => {
      if (library.id == libraryId) {
        library.deviceFunctionList = _.map(library.deviceFunctionList, (func) => {
          func.isSensorDevice = false;
          func.isControlDevice = false;
          func.isCamera = false;
          return func;
        })
        return {...library, isConfigured: false}
      }
      return library
    });
    dispatch(updateRequest({libraries: payload}));
    setSelectedLibraryId(null);
    setOpenModal(!openModal);
  }

  const handleButtonClick_save = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(UpdateSensorControlConfiguration());
    })
  };

  const handleButtonClick_cancel = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(GetFunctionLibraryList());
    })
  };
  
  // componentDidMount
  React.useEffect(() => {
    dispatch(GetFunctionLibraryList());
    if (prop) {
      setPairingConfiguration(prop.pairingConfiguration);
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
    }
  },[]);

  if (pairingConfiguration) {
    return <PairingConfiguration setPairingConfiguration={setPairingConfiguration} />
  } else {
    const selectedLibrary = _.find(libraries, ({id})=> id === selectedLibraryId);
    return (
      <React.Fragment>
        <Card 
          title="Sensors & Control Configuration" 
          action={
            <Button
              className={classes.buttonSecondary}
              onClick={()=>setPairingConfiguration(true)}
              disabled={isDirty}
            >
              Pairing Configuration
            </Button>
          }
          cardActions={canWrite &&
            <React.Fragment>
              <Button disabled />
              <div>
                <Button
                  className={classes.buttonSecondary}
                  onClick={()=>handleButtonClick_cancel()}
                  disabled={!isDirty}
                >
                  Cancel
                </Button>
                <Button
                  className={clsx(classes.button, classes.buttonMargin)}
                  onClick={()=>handleButtonClick_save()}
                  disabled={!isDirty}
                >
                  Save
                </Button>
              </div>
            </React.Fragment>
          }
        >
          <div className={classes.content}>
            <div className={clsx(classes.subContent, classes.leftContent)}>
              <Paper elevation={0} className={classes.newPaper} >
                <Typography className={classes.label}>Device Library</Typography>
                <Select
                  className={classes.select}
                  name="deviceLibrary"
                  onChange={(e)=>handleOnChange_select(e)}
                  value={selectedLibraryId && _.find(libraries, (lib) => lib.id === selectedLibraryId && !lib.isConfigured) ? selectedLibraryId : ""}
                  placeholder="Select a Device Library"
                >
                  { libraries.map((item) => {
                    if (!item.isConfigured) {
                      return (
                        <MenuItem key={item.id} value={item.id}>{item.functionLibraryName}</MenuItem>
                      )
                    }
                  })} 
                </Select>
                { libraries.map((item) => {
                  if (item.isConfigured) {
                    return (
                      <div key={item.id} className={classes.content}>
                        <div>
                          <Radio 
                            checked={Number(selectedLibraryId) === item.id}
                            onChange={(e)=>handleOnChange_select(e)}
                            value={item.id}
                            name="deviceLibrary"
                          />
                          {item.functionLibraryName}
                        </div>
                        {canWrite &&
                          <IconButton
                            type="delete"
                            onClick={() => handleModal(item.id)}
                          />
                        }
                      </div>
                    )
                  }
                })}
              </Paper>
            </div>
            <div className={classes.subContent}>
              <Card
                classes={{
                  root: classes.cardPaper,
                  title: classes.cardTitle,
                  content: classes.cardContent
                }}
                title="Functions in Library"
              >
                <Table
                  stickyHeader
                  className={clsx(classes.table, classes.funcTable)}
                  header={sensorControlTableHead}
                >
                  { selectedLibrary && selectedLibrary.deviceFunctionList.map((item, index) => {
                    const configuration = _.find(selectedLibrary.deviceFunctionList, ({id}) => id === Number(item.id));
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.deviceFunctionName}</TableCell>
                        <TableCell align="center">{item.shortDeviceFunctionName}</TableCell>
                        <TableCell align="center">
                          <Checkbox 
                            id="isSensorDevice"
                            value={item.id} 
                            checked={Boolean(configuration && configuration.isSensorDevice)}
                            onChange={(e) => handleOnChange_checkbox(e)}
                            disabled={!canWrite}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            id="isControlDevice"
                            value={item.id} 
                            checked={Boolean(configuration && configuration.isControlDevice)}
                            onChange={(e) => handleOnChange_checkbox(e)}
                            disabled={!canWrite}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            id="isCamera"
                            value={item.id} 
                            checked={Boolean(configuration && configuration.isCamera)}
                            onChange={(e) => handleOnChange_checkbox(e)}
                            disabled={!canWrite}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </Card>
            </div>
          </div>
        </Card>
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this library configuration? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  }
}
