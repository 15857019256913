import {
  icon_24,
  whiteColor
} from "assets/jss/generaliseStyle.js";

const switchStyle = {
  root: {
    width: "2.917vw",
    height: "1.667vw",
    padding: 0,
  },
  switchBase: {
    padding: "0.208vw !important",
    '&$checked': {
      color: whiteColor,
      '& + $track': {
        background: 'linear-gradient(136.67deg, #86D295 8.34%, #00CFB4 95.26%)',  // #customise
        opacity: 1,
        border: 'none',
      },
      transform: "translateX(1.250vw)"
    },
  },
  thumb: {
    ...icon_24
  },
  track: {
    borderRadius: "0.938vw",
    border: "0.104vw solid #D0D6D5",
    backgroundColor: "#D0D6D5",
    opacity: 1,
  },
  checked: {},
  focusVisible: {},

  rootsm: {
    width: "2.005vw",
    height: "1.146vw",
    padding: 0,
  },
  switchBasesm: {
    padding: "0.143vw !important",
    '&$checked': {
      color: whiteColor,
      '& + $track': {
        background: 'linear-gradient(136.67deg, #86D295 8.34%, #00CFB4 95.26%)',  // #customise
        opacity: 1,
        border: 'none',
      },
      transform: "translateX(0.859vw)"
    },
  },
  thumbsm: {
    width: "0.859vw",
    height: "0.859vw",
  },
}

export default switchStyle;