import React from "react";
import { useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { cagesPreviewTableHead } from "enums/AdminPortal/TableHeaders";

import styles from "assets/jss/components/AdminPortal/cageStyle.js";

const useStyles = makeStyles(styles);

export default function Preview() {
  const classes = useStyles();
  const results = useSelector(store => store.admin.cage.cagePreview);
  const [page, setPage] = React.useState(false);

  const count = Math.ceil(results.length / rowsPerTable);
  
  return (
    <React.Fragment>
      <div className={classes.table}>
        <Table
          header={cagesPreviewTableHead}
        >
          { results.slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable).map((item,index) => {
            return (
              <TableRow key={index}>
                <TableCell>{('0'+((page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                <TableCell>{item.cageRefNo}</TableCell>
                <TableCell>{item.barcodeNumber}</TableCell>
              </TableRow>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
    </React.Fragment>
  )
}
