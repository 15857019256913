import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import FilterButton from "shared-components/Button/FilterButton";
import Select from "shared-components/Select/Select";

import PurchaseOrder from "./PurchaseOrder";
import WorkOrder from "./WorkOrder";
import { renderValue } from 'common/functions';
import { setSearchText } from "store/general";
import { setValues } from "./";

import styles from "assets/jss/components/UserPortal/traceabilityStyle.js";

import { GetPurchaseOrderLookup, GetSaleOrderLookup, GetWorkOrderLookup, GetTraceabilityByWO, GetTraceabilityBySO } from "services/UserPortal/TraceabilityService";

const useStyles = makeStyles(styles);

export default function Traceability() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.traceability.filter);
  const saleOrder = useSelector(store => store.user.traceability.saleOrder);
  const purchaseOrder = useSelector(store => store.user.traceability.purchaseOrder);
  const workOrder = useSelector(store => store.user.traceability.workOrder);
  const [tab, setTab] = React.useState(0);
  const [newFilter, setNewFilter] = React.useState({purchaseOrderNumber: null, saleOrderNumber: null, workOrderNumber: null});

  const handleOnChange_select = (e) => {
    if (e.target.name === "purchaseOrderNumber") {
      setNewFilter({...newFilter, saleOrderNumber: null, [e.target.name]: e.target.value});
      dispatch(GetSaleOrderLookup(e.target.value));
    } else {
      setNewFilter({...newFilter, [e.target.name]: e.target.value});
    }
  };

  React.useEffect(() => {
    if (tab === 0 && filter.saleOrderNumber) {
      dispatch(GetTraceabilityBySO(filter.saleOrderNumber));
    } else if (tab === 1 && filter.workOrderNumber) {
      dispatch(GetTraceabilityByWO(filter.workOrderNumber));
    } else {
      dispatch(setValues({SOTraceability : {}, WOTraceability: {}}));
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetPurchaseOrderLookup());
    dispatch(GetWorkOrderLookup());
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <PurchaseOrder />
      case 1:
        return <WorkOrder />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title = "Traceability"
        subheader={
          <React.Fragment>
            Please select a Sales Order or Work Order in the Filter to trace the order. Only completed Sales Order or Work Order can be searched:
            <Tabs 
              tabs={["Purchase Order / Sales Order", "Work Order"]}
              value={tab} 
              onChange={(e,value) => setTab(value)}
            />
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
              tab === 0 ?
                <React.Fragment>
                  Choose one or all filters to search through the traceability list
                  <Select
                    className={classes.filterDropdown}
                    name="purchaseOrderNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a purchase order"
                    value={renderValue(newFilter.purchaseOrderNumber)}
                  >
                    {purchaseOrder && purchaseOrder.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.purchaseOrderNumber}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="saleOrderNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a sale order"
                    value={renderValue(newFilter.saleOrderNumber)}
                    disabled={!newFilter.purchaseOrderNumber}
                  >
                    {saleOrder && saleOrder.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.saleOrderNumber}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              :
                <React.Fragment>
                  Choose a work order to search through the traceability list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNumber)}
                  >
                    {workOrder && workOrder.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNumber}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
