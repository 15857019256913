import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Card from "@mui/material/Card";
// import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import {loginRoutes as routes} from "routes/loginRoutes.js";

import logo from "assets/logo/logo-green.svg";
import styles from "assets/jss/layouts/formStyle.js";

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/account") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    {localStorage.getItem("accessToken") 
    ? <Redirect from="/" to="/user/home" />
    : <Redirect from="/" to="/account/request_reset_password" />}
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Form() {
  // styles
  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      <Card className={classes.container}>
        <div>
          <img className={classes.logo} src={logo} alt="greenphyto-logo" />
        </div>
        <CardContent className={classes.content}>{switchRoutes}</CardContent>
      </Card>
    </div>
  );
}
