import {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  border,
  primaryColor,
  secondaryColor,
  whiteColor,
  grayColor,
  graphColors
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  cardTitle,
  orange,
  link,
  icon,
  collapsedIcon,
  rotate,
} from "assets/jss/components/commonStyle.js";

const smartMeteringStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  cardTitle,
  orange,
  link,
  icon,
  collapsedIcon,
  rotate,

  // Date Tab
  dateContainer: {
    display: "flex",
    marginLeft: "auto",
    // marginTop: "-1.250vw",
    marginBottom: "0.250vw",
  },
  dateTab: {
    // border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw",
    marginBottom: "0.250vw",
    marginLeft: "auto",
    height: "3vw",
    width: "fit-content"
  },
  dateLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minWidth: "4.167vw !important",
    maxWidth: "4.167vw",
    minHeight: "1.875vw"
  },
  selectedHourTab: {
    color: secondaryColor[0] + " !important",
    backgroundColor: secondaryColor[0] + "20",
  },

  topComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1.250vw",
  },
  scaleComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  aggregationContainer: {
    marginRight: "1.042vw"
  },
  flexComponent: {
    display: "flex",
    alignItems: "center",
    overflow: "overlay",
    '& :not(:last-child)': {
      marginRight: "1.250vw"
    }
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  costContainer: {
    marginLeft: "1.250vw",
    alignSelf: "start"
  },
  margin: {
    marginLeft: "1.250vw"
  },
  label: {
    fontWeight: 500,
    marginBottom: "0.313vw"
  },
  sublabel: {
    fontSize: "0.750vw",
    color: grayColor[0],
    textAlign: "right"
  },
  text: {
    fontWeight: 400,
    fontSize: "0.938vw"
  },
  clickable: {
    cursor: "pointer"
  },
  bottomSpace: {
    marginBottom: "1.250vw"
  },
  width: {
    width: "fit-content",
  },
  paper: {
    border,
    borderRadius: "0.313vw",
    padding: "0.750vw",
    display: "flex",
    alignItems: "center",
    '& :not(:last-child)': {
      marginRight: "1.250vw"
    }
  },
  title: {
    fontWeight: 500,
    fontSize: "1.094vw",
    marginBottom: "1.250vw"
  },
  chartPaper: {
    border,
    borderRadius: "0.833vw",
    padding: "1.250vw",
    // '&:not(:first-child)': {
    //   marginTop: "1.250vw"
    // }
  },
  cardHeader: {
    padding: "1.250vw !important",
    marginBottom: 0,
  },
  cardContent: {
    padding: "1.250vw !important",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
    width: 'fit-content'
  },
  dateScale: {
    marginTop: "1.750vw",
  },
  dateIndicator: {
    backgroundColor: "transparent"
  },
  value: {
    color: secondaryColor[0],
    fontSize: "1.250vw",
  },
  progress: {
    color: secondaryColor[0],
    margin: "auto"
  },
  img: {
    width: "100%",
  },
  graphContent: {
    display: "flex",
    padding: "0 0.500vw",
    position: "relative",
    // height: "38vw",
  },
  paper2: {
    position: "relative",
    // overflow: "overlay",
    height: "29vw",
  },
  formIconButton: {
    border,
    position: "absolute",
    right: "-2.292vw",
    top: "-0.052vw",
    borderRadius: 0,
    borderLeft: 0,
    backgroundColor: 'white',
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  formIconButton2: {
    border,
    position: "absolute",
    left: "-1.979vw",
    top: "5.125vw",
    borderRadius: 0,
    borderLeft: 0,
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
    backgroundColor: whiteColor
  },
  formIconButton3: {
    border,
    position: "absolute",
    left: "-1.979vw",
    borderRadius: 0,
    borderLeft: 0,
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
    backgroundColor: whiteColor
  },
  listTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    marginTop: "0.750vw",
    "& p": {
      fontWeight: 500,
    }
  },
  listTopComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    marginTop: "0.250vw",
  },
  switchLabel: {
    marginRight: "0.521vw",
    alignSelf: "center",
  },
  switchAction: {
    display: "flex",
    alignItems: "center"
  },
  list: {
    overflow: "overlay",
    marginTop: "0.521vw",
    height: "23vw",
    marginRight: "-1.563vw",
  },
  list2: {
    overflow: "overlay",
    height: "27vw",
    marginRight: "-1.563vw",
  },
  subList: {
    overflow: "overlay",
    height: "16vw",
  },
  listPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  subListPadding: {
    paddingLeft: "1.250vw",
    paddingRight: 0
  },
  chartContainer: {
    display: "flex",
    height: "24.250vw",
    minHeight: "24.250vw",
  },
  highChart: {
    // display: "flex",
    // height: "32vw",
    // minHeight: "32vw",
    width: "100%",
  },
  legend: {
    display: "flex",
    marginTop: "1.667vw",
    marginLeft: "1.563vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  colorBox: {
    border,
    width: "1.146vw",
    height: "1.146vw",
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  legendColor: {
    "&:nth-child(1)": {
      backgroundColor: graphColors[0]
    },
    "&:nth-child(2)": {
      backgroundColor: graphColors[1],
    },
    "&:nth-child(3)": {
      backgroundColor: graphColors[2],
    },
    "&:nth-child(4)": {
      backgroundColor: graphColors[3],
    },
    "&:nth-child(5)": {
      backgroundColor: graphColors[4],
    }
  },
  accordion: {
    border,
    backgroundColor: whiteColor,
    borderRadius: "0.833vw",
  },
  accordionSummary: {
    margin: "1.250vw 0  !important",
  },
  //Dashboard
  dashboardContainer: {
    display: "flex",
    alignItems: "end"
  },
  iconPaper: {
    backgroundColor: primaryColor[1],
    padding: "0.750vw",
    marginRight: "0.750vw"
  },
  dashboardIcon: {
    width: "1.750vw",
    height: "1.750vw"
  }
}

export default smartMeteringStyle;