import { 
  cardRadius, 
  icon_24, 
  whiteColor,
  blackColor,
  primaryColor
} from "assets/jss/generaliseStyle";

const popperStyle = () => ({
  // Popper
  popper: {
    zIndex: 9999,
    '&[data-popper-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 10,
      marginRight: 10,
      "&::before": {
        transformOrigin: "0 100%"
      }
    },
    '&[data-popper-placement*="top"] $arrow': {
      bottom: 0,
      left: 6,
      marginBottom: "-0.71em",
      marginLeft: 10,
      marginRight: 10,
      "&::before": {
        transformOrigin: "100% 0"
      }
    },
    '&[data-popper-placement*="top-end"] $arrow': {
      bottom: 0,
      left: '83%',
      marginBottom: "-0.71em",
      marginLeft: 10,
      marginRight: 10,
      "&::before": {
        transformOrigin: "100% 0"
      }
    },
    '&[data-popper-placement*="right"] $arrow': {
      left: 0,
      top: '45%',
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 10,
      marginBottom: 10,
      "&::before": {
        transformOrigin: "100% 100%"
      }
    },
    '&[data-popper-placement*="left"] $arrow': {
      right: 0,
      top: '45%',
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      // marginTop: 4,
      // marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0"
      }
    }
  },
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: "#386538", // #customise
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "currentColor",
      transform: "rotate(45deg)"
    }
  },
  container: {
    ...cardRadius,
    position: "relative",
    backgroundColor: "#386538", // #customise
    color: whiteColor,
    padding: "1.563vw",
  },
  icon: {
    ...icon_24,
    position: "absolute",
    right: "1.042vw",
    top: "1.042vw",
    cursor: "pointer"
  },
  title: {
    fontSize: "1.094vw",
    marginBottom: "1.042vw"
  },
  content: {
    fontSize: "0.833vw",
    lineHeight: "1.458vw",
  },
  button: {
    backgroundColor: "#5EA65E", // #customise
    color: whiteColor,
    padding: "0.521vw",
    width: "4.531vw",
    marginTop: "1.302vw"
  },

  // Popover
  paper: {
    borderLeft: "0.052vw solid " + blackColor,
    borderRadius: "0 0.833vw 0.833vw 0",
    background: primaryColor[2],
    color: whiteColor,
    padding: "0.547vw 0 0.938vw",
    boxShadow: "inset 0.313vw 0 0.313vw "+primaryColor[4]+"B3",
  }
});

export default popperStyle;
    