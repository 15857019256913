/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// core components
import AdminNavbarLinks from "shared-components/Navbars/AdminNavbarLinks.js";
import Popover from "shared-components/Popper/Popover";

import { DashboardTutorialPopper } from "enums/TutorialPopper";
import { checkPermission } from "common/functions";
import { setAdminPortal } from "store/general";

import arrow_grey from "assets/icons/default/droplist-arrow.svg";
import arrow_white from "assets/icons/white/droplist-arrow.svg";
import styles from "assets/jss/shared-components/sidebarStyle.js";
import logoImg from "assets/logo/logo.svg";

const useStyles = makeStyles(styles);
export default function Sidebar(props) {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  const strapiNavigation = useSelector(store => store.strapi.navigation);
  const strapiSidebar = useSelector(store => store.strapi.sidebar);
  const isAdminPortal = useSelector(store => store.general.isAdminPortal);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [scrolled, setScrolled] = React.useState(false);
  const [closeAllPopper, setCloseAllPopper] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSubMenu, setOpenSubMenu] = React.useState(null);
  const [userDetail, setUserDetail] = React.useState(null);

  const sidebar = React.createRef();

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName || location.pathname.includes(routeName+"/");
  }

  const handleScroll = (e) => {
    setScrolled(e.target.scrollTop > 0);
    anchorEl && setAnchorEl(null);
    openSubMenu && setOpenSubMenu(null);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const onMouseClick = (e, path) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setOpenSubMenu(path);
  }

  const resetStore = (path) => {
    if (location.pathname !== path) {
      dispatch({type: 'reset'});
    }
  }

  // React.useEffect(() => {
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [sidebar]);

  React.useEffect(() => {
    if (location.pathname.includes("/user/")) {
      dispatch(setAdminPortal(false));
    }
    if (location.pathname.includes("/admin/")) {
      dispatch(setAdminPortal(true));
    }
  }, [location]);

  React.useEffect(() => {
    const checkUserData = () => {
      setUserDetail(JSON.parse(localStorage.getItem("userDetail")));
    }
    checkUserData();
    window.addEventListener("storage", checkUserData);
    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);

  const { routes } = props;

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
          listItemClasses = classNames({
            [" " + classes.selectedColor]: activeRoute(prop.layout + prop.path),
          });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        const tutorial = DashboardTutorialPopper[key-1];
        if (prop.permissionName ? !checkPermission(prop.permissionName, "none") : true) {
          const attributes = _.find(strapiSidebar, ({attributes}) => attributes.title==prop.name);
          return (
            <React.Fragment key={key}>
              <NavLink
                to={prop.subMenu ? "/" : prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                onClick={(e) => prop.subMenu ? onMouseClick(e, prop.path) : resetStore(prop.layout + prop.path)}
              >
                <ListItem button className={classes.itemLink + listItemClasses}>
                  <img src={activeRoute(prop.layout + prop.path)?prop.icon.white:prop.icon.grey}
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                  <ListItemText
                    primary={attributes ? attributes.attributes.name : (prop.name.includes("Home") ? "Home" : prop.name)}
                    className={classNames(classes.itemText, whiteFontClasses)}
                    disableTypography={true}
                  />
                  {prop.subMenu &&
                    <img src={activeRoute(prop.layout + prop.path)?arrow_white:arrow_grey}
                      className={classNames(classes.subMenuIcon, whiteFontClasses)}
                    />
                  }
                </ListItem>
              </NavLink>
            </React.Fragment>
          );
        } else {
          return null
        }
      })}
    </List>
  );

  var sublinks = (
    <React.Fragment>
      {routes.map((prop, key) => {
        if (prop.permissionName ? !checkPermission(prop.permissionName, "none") : true) {
          if (prop.subMenu) {
            return (
              <Popover
                key={key}
                classes={{paper: classes.subItemPaper}}
                open={openSubMenu === prop.path}
                onClose={()=>{setAnchorEl(null); setOpenSubMenu(null);}}
                anchorEl={anchorEl}
              >
                { prop.subMenu.map((item, index) => {
                    var activePro = " ";
                    var listItemClasses;
                      listItemClasses = classNames({
                        [" " + classes.selectedColor]: activeRoute(prop.layout + item.path),
                      });
                    const whiteFontClasses = classNames({
                      [" " + classes.whiteFont]: activeRoute(prop.layout + item.path),
                    });
                    if((item.permissionName !== prop.permissionName) ? !checkPermission(item.permissionName, "none") : true) {
                      const attributes = _.find(strapiSidebar, ({attributes}) => attributes.title==item.name);
                      return (
                        <NavLink
                          key={index}
                          to={prop.layout + item.path}
                          className={activePro + classes.item}
                          activeClassName="active"
                          onClick={() => resetStore(prop.layout + item.path)}
                        >
                          <ListItem button className={classNames(classes.subItemLink, classes.itemLink, listItemClasses)}>
                            <ListItemText
                              primary={attributes ? attributes.attributes.name : item.name}
                              className={classNames(classes.subItemText, classes.itemText, whiteFontClasses)}
                              disableTypography={true}
                            />
                          </ListItem>
                        </NavLink>
                      )
                    }
                })}
              </Popover>
            );
          } return null;
        } return null;
      })}
    </React.Fragment>
  );

  const attributes = _.find(strapiNavigation, ({attributes}) => attributes.title==(isAdminPortal ? "Admin Portal" : "User Portal"));
  var brand = (
    <div className={scrolled ? clsx(classes.logo, classes.boxShadow) : classes.logo}>
      <div className={classes.logoImage}>
        <img src={logoImg} alt="greenphyto logo" />
      </div>
      <div className={classes.logoSubheader}>
        {attributes ? attributes.attributes.label : (isAdminPortal ? "Admin Portal" : "User Portal")}
      </div>
    </div>
  );

  return (
    // Cannot use hidden as it creates to poppers
    // TODO: Mobile view cannot open drawer, to fix later if required
    // <React.Fragment>
    //   { window.innerWidth < 960
    //   ? <Drawer
    //       variant="permanent"
    //       anchor="right"
    //       open={props.open}
    //       classes={{
    //         paper: classes.drawerPaper,
    //       }}
    //       onClose={props.handleDrawerToggle}
    //       ModalProps={{
    //         keepMounted: true, // Better open performance on mobile.
    //       }}
    //     >
    //       {brand}
    //       <div className={classes.sidebarWrapper}>
    //         {/* <AdminNavbarLinks /> */}
    //         <div className={classes.sidebarWrapper} ref={sidebar}>{links}</div>
    //       </div>
    //     </Drawer>
    //   : <Drawer
    //       anchor="left"
    //       variant="permanent"
    //       open
    //       classes={{
    //         paper: classes.drawerPaper,
    //       }}
    //     >
    //       {brand}
    //       <div 
    //         ref={sidebar} 
    //         className={classes.sidebarWrapper} 
    //         onScroll={(e)=>handleScroll(e)}
    //       >
    //         {links}
    //       </div>
    //     </Drawer>
    //   }
    // </React.Fragment>
    <div>
      {/* <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
        </Drawer>
      </Hidden> */}
      {/* <Hidden smDown implementation="css"> */}
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper} ref={sidebar}>{links}</div>
        </Drawer>
      {/* </Hidden> */}
      {sublinks}
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};