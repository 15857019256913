import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  modules: [],
  module: {
  },
};

// create reducer and action creators
// cannot use module due to package conflict
const modules = createSlice({
  name: "modules",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.module[key] = action.payload[key];
      })
    },
    resetModule: (state) => {
      state.module = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
  },
});

// export actions
export const { updateRequest, resetModule, reset } = modules.actions;

// export the reducer
export default modules.reducer;