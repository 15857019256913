import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin  from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// @mui/icons-material
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
// core components
import Card from "shared-components/Card/Card";

import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from "common/functions";
import { setSearchText } from "store/general";
import { GetPlanningReport } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function Report() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedLang = useSelector(store => store.general.language);
  const report = useSelector(store => store.user.planning.report.report);
  const [month, setMonth] = React.useState(moment().startOf("month").format('M'));

  const calendarComponentRef = React.createRef();
  
  React.useEffect(() => {
    dispatch(GetPlanningReport({month}));
  },[month]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  useInterval(() => {
    dispatch(GetPlanningReport({month, stopLoading: true}));
  });

  return (
    <React.Fragment>
      <Card 
        title = "Report for Planning Module"
      >
        <FullCalendar
          ref={calendarComponentRef}
          locales={allLocales}
          locale={selectedLang.code}
          plugins={[ dayGridPlugin, interactionPlugin ]}
          initialView="dayGridMonth"
          fixedWeekCount={false}
          datesSet={(dateInfo) => {
            setMonth(moment(dateInfo.view.getCurrentData().viewTitle, "MMMM YYYY").month()+1);
          }}
          headerToolbar={{
            start: 'title', // will normally be on the left. if RTL, will be on the right
            center: '',
            end: 'prevYear,prev,today,next,nextYear' // will normally be on the right. if RTL, will be on the left
          }}
          dayHeaderFormat={{
            weekday: 'long'
          }}
          height="46.875vw"
          eventContent={({event})=>{
            return (
              <div className={classes.event}>
                <Typography className={classes.eventText}>Capacity: {formatNumbers(roundTo2Decimal(event.extendedProps.capacity))}%</Typography>
                <Typography className={classes.eventText}>
                  Raw Materials: {event.extendedProps.rawmaterial ? <Check className={classes.eventIcon} /> : <Close className={clsx(classes.eventIcon, classes.eventIcon2)} />}
                </Typography>
                <Typography className={classes.eventText}>Excess Capacity: {formatNumbers(roundTo2Decimal(event.extendedProps.excessCapacity))}kg</Typography>
                <div className={classes.hoverEvent}>
                  <Button 
                    className={clsx(classes.buttonSecondary, classes.viewMore)}
                    onClick={()=>history.push({pathname: "/user/planning/report/detail", state:{date: event.start, excessCapacity: event.extendedProps.excessCapacity}})}
                  >
                    View More
                  </Button>
                </div>
              </div>
            )
          }}
          events={report}
          // eventClick={(info)=>{
          //   history.push({pathname: "/user/planning/report/detail", state:{date: info.event.start, excessCapacity: info.event.extendedProps.excessCapacity}});
          // }}
          // dateClick={(info)=>{
          //   history.push({pathname: "/user/planning/report/detail", state:{date: info.date}});
          // }}
        />
      </Card>
    </React.Fragment>
  );
}
