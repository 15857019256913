import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// @mui/material components
import { withStyles, makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputBase from '@mui/material/InputBase';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material components
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// core components
import { whiteColor } from "assets/jss/generaliseStyle.js";
import styles from "assets/jss/shared-components/selectStyle.js";

const InputCss = withStyles({
  root: {
    backgroundColor: whiteColor,
    border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw",
    width: '-webkit-fill-available',
    '&:hover': {
      border: '0.104vw solid #444444'
    },
    '&:focus': {
      borderRadius: "0.313vw",
      border: '0.104vw solid #444444',
    },
  },
  input: {
    padding: "0.860vw 0.625vw",
    fontSize: "0.938vw",
    paddingRight: "1.250vw",
    '&:focus': {
      backgroundColor: "unset"
    },
  },
})(InputBase);

const useStyles = makeStyles(styles);
export default function CustomSelect({label, children, placeholder, errorMsg, className, ...props}) {
  const classes = useStyles();

  const hasValue = Boolean(props.value && props.value.length !== 0);
  if (props.multiple) {
    placeholder = [placeholder];
  }
  return (
    <React.Fragment>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <FormControl className="w-full">
        <Select
          {...props} 
          className={
            clsx(className, ((errorMsg && errorMsg.field==props.name) && classes.selectError), ((!hasValue && placeholder) && classes.placeholder), (props.disabled && classes.selectDisabled))
          }
          classes={{
            root: "text-left",
            icon: classes.icon
          }}
          value={hasValue ? props.value : placeholder}
          input={<InputCss value={hasValue ? props.value : placeholder} />}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
        >
          { placeholder &&
            <MenuItem value={placeholder} disabled>
              {placeholder}
            </MenuItem>
          }
          {children}
        </Select>
        {(errorMsg && errorMsg.field==props.name) && <FormHelperText className={classes.errorMsg}>{errorMsg.msg}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  );
}

CustomSelect.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};