import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import * as XLSX from 'xlsx';
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// core components
import Accordion from "shared-components/Accordion/Accordion";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import ViewMoreModal from 'shared-components/Modal/ViewMoreModal';

import { farmConfig } from "enums/Constants";
import { setSearchText } from "store/general";
import { traceabilityTableHead, environmentDataTableHead } from "enums/UserPortal/TableHeaders";

import { DownloadTraceabilityBySO } from "services/UserPortal/TraceabilityService";

import styles from "assets/jss/components/UserPortal/traceabilityStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');

export default function PurchaseOrder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SOTraceability = useSelector(store => store.user.traceability.SOTraceability);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');
  const [openModal, setOpenModal] = React.useState(false);
  const [expanded, setExpanded] = React.useState(null);
  const [environment, setEnvironment] = React.useState(null);

  const traceabilityProductDtos = SOTraceability && SOTraceability.traceabilityProductDtos;

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleButtonClick_accordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleButtonClick_download = () => {
    dispatch(DownloadTraceabilityBySO(SOTraceability.soId))
    .then((response) => {
      FileDownload(response.payload.data, SOTraceability.purchaseOrderNo+'.xlsx');
    })
  }

  const handleModal = () => {
    setEnvironment(null);
    setOpenModal(!openModal);
  }

  const onClick_viewEnv = (index) => {
    setEnvironment(traceabilityProductDtos[index].growthDetail.environmentDatas);
    setOpenModal(!openModal);
  }

  const onDownload = async (link, deviceId) => {
    try {
      let response = await fetch(link);
      let responseJson = await response.json();
      const worksheet = XLSX.utils.json_to_sheet(responseJson.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      return XLSX.writeFile(workbook, deviceId+".xlsx");
     } catch(error) {
      console.error(error);
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // dispatch(GetAllWarehouse());
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { traceabilityProductDtos ? traceabilityProductDtos.map((item,index) => {
          return (
            <Accordion
              key={index}
              classes={{
                root: classes.accordion,
              }}
              expanded={expanded === index}
              onChange={handleButtonClick_accordion(index)}
              header={item.productName}
            >
              <Paper
                elevation={0}
                className={classes.content}
              >
                <Paper
                  elevation={0}
                  className={classes.paper}
                >
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <div className={classes.vertical}>
                        <Typography className={classes.title}>Customer & Delivery Details:</Typography>
                        <Typography className={classes.details}>
                          Customer: <span className={classes.value}>{item.customerDeliveryDetail && item.customerDeliveryDetail.customerName}</span>
                        </Typography>
                        <Typography className={classes.details}>
                          Order Delivered: <span className={classes.value}>{item.customerDeliveryDetail && item.customerDeliveryDetail.orderDelivered}</span>
                        </Typography>
                        <Typography className={classes.details}>
                          Delivery Date: <span className={classes.value}>{item.customerDeliveryDetail && moment(item.customerDeliveryDetail.deliverDate).format("DD-MM-YYYY")}</span>
                        </Typography>
                        <Typography className={classes.details}>
                          Delivery Order: <span className={classes.value}>{item.customerDeliveryDetail && item.customerDeliveryDetail.doNumber}</span>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.title}>Growth Details:</Typography>
                      <Typography className={classes.details}>
                        Work Order: <span className={classes.value}>{item.growthDetail && item.growthDetail.workOrderNumber}</span>
                      </Typography>
                      <Typography className={classes.details}>
                        Environmental Conditions: <span className={clsx(classes.value, classes.link)} onClick={() => onClick_viewEnv(index)}>View</span>
                      </Typography>
                      <Typography className={classes.details}>
                        Start & End Date (Germination): <span className={classes.value}>{item.growthDetail && moment(item.growthDetail.germinationStartDate).format("DD-MM-YYYY")} - {item.growthDetail && moment(item.growthDetail.germinationEndDate).format("DD-MM-YYYY")}</span>
                      </Typography>
                      <Typography className={classes.details}>
                        Start & End Date (Growth): <span className={classes.value}>{item.growthDetail && moment(item.growthDetail.growthStartDate).format("DD-MM-YYYY")} - {item.growthDetail && moment(item.growthDetail.growthEndDate).format("DD-MM-YYYY")}</span>
                      </Typography>
                      <Typography className={classes.details}>
                        {farmConfig.cage} Info: <span className={classes.value}>{item.growthDetail && <ViewMoreModal list={item.growthDetail.cageInfos.map((item) => item.cageId).join(",")} title={farmConfig.cage +" Info"} />}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr />
                  <Typography className={clsx(classes.marginTop, classes.title)}>Raw Materials & Storage Details:</Typography>
                  <Typography className={classes.detailLabel}>
                    Storage Environmental Conditions: <span className={clsx(classes.marginLeft, classes.link)}>View</span>
                  </Typography>
                  <Typography>
                    Raw Materials Used:
                  </Typography>
                  <Table
                    className={classes.table}
                    header={traceabilityTableHead}
                    // order={order}
                    // orderBy={orderBy}
                    // onRequestSort={handleRequestSort}
                  >
                    { item.rawMaterialDetails.map((item,index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{item.rawMaterialName}</TableCell>
                          <TableCell>{item.rawMaterialId}</TableCell>
                          <TableCell>{item.batchNumber}</TableCell>
                          <TableCell>{moment(item.issuedDate).format("DD-MM-YYYY")}</TableCell>
                          <TableCell>{item.supplierName}</TableCell>
                          <TableCell>{item.usedQty}</TableCell>
                          <TableCell>{item.uom}</TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                  <div className={classes.downloadButton}>
                    <Button
                      className={classes.button}
                      onClick={() => handleButtonClick_download()}
                    >
                      Export as CSV File
                    </Button>
                  </div>
                </Paper>
              </Paper>
            </Accordion>
          )
        })
      : <Typography>No Sales Order Selected in Filter</Typography>
    }
        <Modal
          className={classes.batchModal}
          classes = {{content: classes.scroll}}
          open={openModal}
          onClose={() => handleModal()}
          title="Environment Data"
          content={
            <div className={classes.batchContent}>
              <Table
                className={classes.table}
                stickyHeader 
                header={environmentDataTableHead}
              >
                { environment && environment.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.deviceId}</TableCell>
                      <TableCell>{moment(item.readingDate).format("DD-MM-YYYY")}</TableCell>
                      <TableCell><span className={clsx(classes.value, classes.link)} onClick={() => onDownload(item.readingDataUrl, item.deviceId)}>Download</span></TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
          }
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal()}>Close</Button>
            </React.Fragment>
          }
        />
    </React.Fragment>
  );
}
