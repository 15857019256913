import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllSensorControlMapping } from "services/UserPortal/ClimateService";
import { GetFunctionLibraryList } from "services/UserPortal/CommonLookupService";

// initial state
const initialState = {
  libraries: [],
  totalCount: 0,
  config: [{}],
  sensorDevice: [],
  controlDevice: [],
};

const sensorControl = createSlice({
  name: "sensorControl",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateConfigRequest: (state, action) => {
      Object.keys(action.payload.value).forEach((key) => {
        state.config[action.payload.index][key] = action.payload.value[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetFunctionLibraryList.fulfilled]: (state, action) => {
      state.libraries = _.orderBy(action.payload.result, ["functionLibraryName"], ["asc"]);
    },
    [GetAllSensorControlMapping.fulfilled]: (state, action) => {
      state.config = action.payload.result.totalCount ? action.payload.result.items : [{}];
      state.totalCount = action.payload.result.totalCount;
    },
  },
});

// export actions
export const { updateRequest, updateConfigRequest, reset } = sensorControl.actions;

// export the reducer
export default sensorControl.reducer;