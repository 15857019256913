import { createSlice } from "@reduxjs/toolkit";

// import { GetAllASRSsettingsASRSStatusList } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  filter: {},
  settings: [],
  setting: {}
};

const settingsFlowControl = createSlice({
  name: "settingsFlowControl",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    // [GetAllASRSsettingsASRSStatusList.fulfilled]: (state, action) => {
    //   state.result = action.payload.result.items;
    //   state.totalCount = action.payload.result.totalCount;
    // },
  },
});

// export actions
export const { setValues, updateRequest, reset } = settingsFlowControl.actions;

// export the reducer
export default settingsFlowControl.reducer;