import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Radio from 'shared-components/Radio/Radio';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import DetailsModal from "../DetailsModal";
import { rowsPerTable } from "config";
import { isPositive } from "common/validations";
import { renderValue, formatNumbers, roundTo2Decimal } from "common/functions";
import { setDirty } from "store/general";
import { hrLogSheetTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest, setValues } from "../store/logSheet";
import { GetAllLogSheet, GetProductProcessName, GetLotIdByWorkOrderIdAndProductId, GetTaskNumberList, CreateOrUpdateLogSheet, SubmitLogSheetList, DeleteLogSheet } from "services/UserPortal/HumanResourceService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/hrStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function NewEntry() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const processList = useSelector(store => store.user.hr.logSheet.processList);
  const taskList = useSelector(store => store.user.hr.logSheet.taskList);
  const totalTime = useSelector(store => store.user.hr.logSheet.totalTime);
  const totalCount = useSelector(store => store.user.hr.logSheet.totalCount);
  const totalWorkingHours = useSelector(store => store.user.hr.logSheet.totalWorkingHours);
  const isLogged = useSelector(store => store.user.hr.logSheet.isLogged);
  const newEntry = useSelector(store => store.user.hr.logSheet.newEntry);
  const lotId = useSelector(store => store.user.hr.logSheet.lotId);
  const result = useSelector(store => store.user.hr.logSheet.logSheet);
  const log = useSelector(store => store.user.hr.logSheet.log);
  const [filter] = React.useState({date: moment().format("YYYY-MM-DD")});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productRefNo');
  const [page, setPage] = React.useState(0);
  const [entryId, setEntryId] = React.useState(false);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [workingDay, setWorkingDay] = React.useState("FullWorkingDay");

  const leaveTypeList = [
    {name: "Full Day Leave", value: "FullDayLeave"},
    {name: "Half Day AM Leave", value: "HalfDayAMLeave"},
    {name: "Half Day PM Leave", value: "HalfDayPMLeave"},
  ];

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_radio = (e) => {
    let payload = _.cloneDeep(log);
    if (e.target.name === "workingDay") {
      setWorkingDay(e.target.value);
    }
    if (e.target.value !== "Leave") {
      dispatch(setValues({log: {...payload, workingDayType: e.target.value}}));
    } else {
      dispatch(setValues({log: {...payload, workingDayType: "FullDayLeave"}}));
    }
  };
  
  const handleButtonClick_submit = () => {
    dispatch(SubmitLogSheetList())
    .then(() =>{
      getLogSheet(0);
      setPage(0);
      resetState();
    })
  }

  const handleModal_details = (value) => {
    setOpenDetailsModal(value);
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "workOrderId") {
      dispatch(updateRequest({ productId: null, productGrowthProcessId: null, operationTaskId: null}));
    }
    if (e.target.name === "productId") {
      dispatch(updateRequest({ productGrowthProcessId: null, operationTaskId: null}));
    }
    if (e.target.name === "productGrowthProcessId") {
      dispatch(updateRequest({ operationTaskId: null}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setIsAddEdit(true);
    setEntryId(id);
    const value = _.find(result, (item) => item.id === id);
    dispatch(setValues({newEntry: value}));
  }

  const handleModal_delete = (id) => {
    setEntryId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteLogSheet(entryId))
    .then(() =>{
      getLogSheet(0);
      setPage(0);
      resetState();
    })
  }

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = [...result, {id: 0}];
    setIsAddEdit(true);
    setEntryId(0);
    dispatch(setValues({logSheet: payload, lotId: null, newEntry: {date: moment().format("YYYY-MM-DD"), isUserKeyInTask: true}}));
  }

  const handleModal_confirm = () => {
    setOpenConfirmModal(!openConfirmModal);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateLogSheet(newEntry))
      .then(({error}) =>{
        if (!error) {
          getLogSheet(page);
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (entryId === 0) {
      dispatch(setValues({logSheet: _.filter(result, (item) => item.id !== entryId)}));
      setPage(0);
    }
    resetState();
  }

  const validateFields = () => {
    if (newEntry.workOrderId && !newEntry.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product id"});
      return false;
    }
    if (!newEntry.productGrowthProcessId) {
      setErrorMsg({field: "productGrowthProcessId", msg: "Please select a process"});
      return false;
    }
    if (log.workOrderId && log.productId && !log.operationTaskId) {
      setErrorMsg({field: "operationTaskId", msg: "Please select a task number"});
      return false;
    }
    if (!isPositive(newEntry.timeSpent)) {
      setErrorMsg({field: "timeSpent", msg: "Please enter a valid time spent"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setIsAddEdit(false);
    setEntryId(null);
    setErrorMsg(false);
    setOpenDeleteModal(false);
    setOpenConfirmModal(false);
    dispatch(setDirty(false));
    dispatch(setValues({newEntry: {}}));
  }

  const getLogSheet = (pageNo, stopLoading) => {
    dispatch(GetAllLogSheet({...filter, page: pageNo, stopLoading}));
  }

  React.useEffect(() => {
    setPage(0);
    getLogSheet(0);
  },[filter]);

  React.useEffect(() => {
    getLogSheet(page);
  },[page]);

  React.useEffect(() => {
    if (newEntry.workOrderId) {
      dispatch(GetProductList(newEntry.workOrderId));
    } 
  },[newEntry.workOrderId]);

  React.useEffect(() => {
    if (newEntry.workOrderId && newEntry.productId && productList.length) {
      const product = productList.find(({id}) => id == newEntry.productId);
      dispatch(GetProductProcessName({versionId: product ? product.productVersionId : 0}));
      dispatch(GetLotIdByWorkOrderIdAndProductId({workOrderId: newEntry.workOrderId, productId: newEntry.productId}));
    } else {
      dispatch(GetProductProcessName({versionId: 0}));
    }
  },[newEntry.productId, productList]);

  React.useEffect(() => {
    if (newEntry.workOrderId && newEntry.productId && newEntry.productGrowthProcessId) {
      dispatch(GetTaskNumberList({workOrderId: newEntry.workOrderId, productId: newEntry.productId, productProcessId : newEntry.productGrowthProcessId}));
    }
  },[newEntry.productGrowthProcessId]);

  return (
    <React.Fragment>
      <div className={classes.tabHeader}>
        <Typography className={classes.tabTitle}>{"Information Correct as at " + moment().format("DD/MM/YYYY, HHmm")}</Typography>
        {/* <DateTimePicker
          closeOnSelect
          placeholder="Select a date"
          className={classes.cardActionDropdown}
          value={filter.date}
          onChange={(e) => handleOnChange_date(e)}
        /> */}
      </div>
      {isLogged
      ? <div className={classes.resultContainer}>
          <Typography className={classes.resultText}>Submitted log sheet for today</Typography>
          <Typography className={classes.resultDetail}>View submitted log sheet in &quot;Past Records&quot; tab</Typography>
        </div>
      : <React.Fragment>
          <div className={clsx(classes.tabContent, classes.tabMargin)}>
            <Typography>Full Working Day:</Typography>
            <Radio 
              className={classes.radio}
              name="workingDay"
              value="FullWorkingDay"
              checked={workingDay === "FullWorkingDay"}
              onChange={(e)=>handleButtonClick_radio(e)}
            />
            <Typography>Rest Day:</Typography>
            <Radio 
              className={classes.radio}
              name="workingDay"
              value="Rest"
              checked={workingDay === "Rest"}
              onChange={(e)=>handleButtonClick_radio(e)}
            />
            <Typography>Leave:</Typography>
            <Radio 
              className={classes.radio}
              name="workingDay"
              value="Leave"
              checked={workingDay === "Leave"}
              onChange={(e)=>handleButtonClick_radio(e)}
            />
            {workingDay === "Leave" &&
              <Select
                className={classes.cardActionDropdown}
                name="workingDayType"
                onChange={(e)=>handleButtonClick_radio(e)}
                value={renderValue(log.workingDayType)}
                placeholder="Select a leave type"
                errorMsg={errorMsg}
              >
                {leaveTypeList && leaveTypeList.map((option, i) => {
                return <MenuItem key={i} value={option.value}>{option.name}</MenuItem>;
              })} 
              </Select>
            }
          </div>
          <div className={classes.table}>
            <Table
              className={classes.mainTable}
              header={hrLogSheetTableHead}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { result.map((item,index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>
                        { isAddEdit && entryId === item.id
                        ? <Select
                            name="workOrderId"
                            onChange={(e)=>handleOnChange_select(e)}
                            value={renderValue(newEntry.workOrderId)}
                            placeholder="Select a work order"
                            errorMsg={errorMsg}
                          >
                            {workOrderList && workOrderList.map((option, i) => {
                            return <MenuItem key={i} value={option.id}>{option.workOrderNo}</MenuItem>;
                          })} 
                          </Select>
                        : item.workOrderNumber
                        }
                      </TableCell>
                      <TableCell>
                        { isAddEdit && entryId === item.id
                        ? <Select
                            name="productId"
                            onChange={(e)=>handleOnChange_select(e)}
                            value={renderValue(newEntry.productId)}
                            placeholder="Select a product"
                            disabled={!newEntry.workOrderId}
                            errorMsg={errorMsg}
                          >
                            {productList.map((option) => {
                              return <MenuItem key={option.id} value={option.id}>{option.productName} ({option.productRefNo})</MenuItem>;
                            })} 
                          </Select>
                        : item.productRefNo
                        }
                      </TableCell>
                      <TableCell>
                        { isAddEdit && entryId === item.id
                        ? lotId
                        : item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />
                        }
                      </TableCell>
                      <TableCell>
                        { isAddEdit && entryId === item.id
                        ? <Select
                            name="productGrowthProcessId"
                            onChange={(e)=>handleOnChange_select(e)}
                            value={renderValue(newEntry.productGrowthProcessId)}
                            placeholder="Select a process name"
                            errorMsg={errorMsg}
                          >
                            {processList && processList.map((option, i) => {
                              return <MenuItem key={i} value={option.productProcessId}>{option.productProcessName}</MenuItem>;
                            })} 
                          </Select>
                        : item.productProcessName
                        }
                      </TableCell>
                      <TableCell>
                        { isAddEdit && entryId === item.id
                        ? <Select
                            name="operationTaskId"
                            onChange={(e)=>handleOnChange_select(e)}
                            value={renderValue(newEntry.operationTaskId)}
                            placeholder="Select a task number"
                            disabled={!(newEntry.workOrderId && newEntry.productId)}
                            errorMsg={errorMsg}
                          >
                            {taskList && taskList.map((option, i) => {
                              return <MenuItem key={i} value={option.taskId}>{option.taskNumber}</MenuItem>;
                            })} 
                          </Select>
                        : item.taskNumber
                        }
                      </TableCell>
                      <TableCell>
                        { isAddEdit && entryId === item.id
                        ? <TextField 
                            id="timeSpent"
                            type="Number"
                            variant="outlined" 
                            onInput={(e)=>{ 
                              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                            }}
                            placeholder="Enter time spent"
                            onChange={(e) => handleOnChange_text(e)}
                            value={!newEntry.timeSpent ? "" : newEntry.timeSpent}
                            errorMsg={errorMsg}
                          />
                        : formatNumbers(roundTo2Decimal(item.timeSpent))
                        }
                      </TableCell>
                      <TableCell>{!isAddEdit && !item.isUserKeyInTask && <span className={classes.link} onClick={()=>handleModal_details(item)}>Details</span>}</TableCell>
                      <TableCell align="right">
                        { canWrite && isAddEdit && entryId === item.id
                        ? <React.Fragment>
                            <IconButton 
                              type="confirm"
                              onClick={() => handleButtonClick_confirm()}
                            />
                            <IconButton 
                              type="close"
                              onClick={() => handleButtonClick_cancel()}
                            />
                          </React.Fragment>
                        : <React.Fragment>
                            <IconButton 
                              type="edit"
                              onClick={() => handleButtonClick_edit(item.id)}
                              disabled={Boolean(entryId && entryId !== item.id)}
                            />
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal_delete(item.id)}
                              disabled={Boolean(entryId && entryId !== item.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              })}
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell className={classes.bold}>Total Time Spent</TableCell>
                <TableCell colSpan={3} className={classes.bold}>{formatNumbers(roundTo2Decimal(totalTime))} mins / {formatNumbers(roundTo2Decimal(totalTime/60))} hours</TableCell>
              </TableRow>
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
              disabled={Boolean(isAddEdit)}
            />
          }
          {canWrite &&
            <div className={classes.cardAction}>
              <Button 
                className={classes.buttonSecondary}
                startIcon={<AddIcon />}
                onClick={()=>handleButtonClick_add()}
                disabled={Boolean(isAddEdit)}
              >
                Add Another Entry
              </Button>
              <Button 
                className={classes.button}
                onClick={()=>handleModal_confirm()}
                disabled={Boolean(isAddEdit)}
              >
                Submit
              </Button>
            </div>
          }
        </React.Fragment>
      }
      <Modal
        open={openConfirmModal}
        onClose={() => handleModal_confirm()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content={
          log.workingDayType === "FullDayLeave" || workingDay === "Rest"
          ? <React.Fragment>
              {"You have selected "+(log.workingDayType === "FullDayLeave" ? "“On Leave”" : "“Rest Day”")+" for "+moment().format("DD/MM/YYYY")+". Do you still want to submit the records, you cannot edit anymore once you have submitted. "}
            </React.Fragment>
          : <React.Fragment>
              {formatNumbers(roundTo2Decimal(totalTime/60)) == formatNumbers(roundTo2Decimal(workingDay === "Leave" ? totalWorkingHours/2 : totalWorkingHours)) && "Once you submit the records, you cannot edit anymore. Do you still want to submit the record?"}
              {formatNumbers(roundTo2Decimal(totalTime/60)) > formatNumbers(roundTo2Decimal(workingDay === "Leave" ? totalWorkingHours/2 : totalWorkingHours)) && "You have only logged "+formatNumbers(roundTo2Decimal(totalTime/60))+" hours while your total working hours should be "+formatNumbers(roundTo2Decimal(workingDay === "Leave" ? totalWorkingHours/2 : totalWorkingHours))+" hours. Please take note that you cannot edit anymore once you have submitted."}
              {formatNumbers(roundTo2Decimal(totalTime/60)) < formatNumbers(roundTo2Decimal(workingDay === "Leave" ? totalWorkingHours/2 : totalWorkingHours)) && "You have logged "+formatNumbers(roundTo2Decimal(totalTime/60))+" hours while your total working hours is "+formatNumbers(roundTo2Decimal(workingDay === "Leave" ? totalWorkingHours/2 : totalWorkingHours))+" hours. The additional hour(s) log will be counted towards overtime hour(s). Please take note that you cannot edit anymore once you have submitted."}
            </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_confirm()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_submit()}>Submit</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete ths entry? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <DetailsModal 
        open={Boolean(openDetailsModal)}
        onClose={() => handleModal_details(null)}
        title="workOrderNumber"
        id="id"
        data={openDetailsModal}
      />
    </React.Fragment>
  );
}
