import {
  cardRadius,
  icon_64,
  button,
  buttonSecondary,
  border
} from "assets/jss/generaliseStyle.js";

import {
  orange,
} from "assets/jss/components/commonStyle.js";

const reportStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  
  orange,
  indicator: {
    backgroundColor: "transparent"
  },

  cardTitle: {
    position: "relative",
    margin: 0,
    fontSize: "1.094vw !important",
    display: "flex",
    alignItems: "center"
  },
  cardHeader: {
    alignSelf: "baseline"
  },
  cardBorder: {
    border,
    boxShadow: "none",
  },
  content: {
    marginBottom: "2.083vw"
  }
}

export default reportStyle;