import { createSlice } from "@reduxjs/toolkit";

import { GetProductList, GetRecipeByProductId } from "services/AdminPortal/RecipeService";

// initial state
const initialState = {
  products: [],
  recipe: {},
  control: {},
};

const recipe = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.control = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.control[key] = action.payload[key];
      })
    },
    resetControl: (state) => {
      state.control = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetProductList.fulfilled]: (state, action) => {
      state.products = action.payload.result;
    },
    [GetRecipeByProductId.fulfilled]: (state, action) => {
      state.recipe = action.payload.result;
    },
    [GetRecipeByProductId.rejected]: (state) => {
      state.recipe = {};
    },
  },
});

// export actions
export const { setValues, updateRequest, resetControl, reset } = recipe.actions;

// export the reducer
export default recipe.reducer;