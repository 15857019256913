import React from "react";
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// core components
import styles from "assets/jss/shared-components/tabStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTabs({tabs, disabled, ...props}) {
  const classes = useStyles();

  return (
    <Tabs 
      {...props}
      className={clsx(props.className, classes.tab)}
      classes={{
        indicator: classes.indicator
      }}
    >
      {tabs.map((item, index) => {
        return <Tab key={index} className={classes["tabLabel"+(props.value===index?"Selected":"")]} label={item} disabled={disabled} />
      })}
    </Tabs>
  );
}

CustomTabs.propTypes = {
  tabs: PropTypes.array,
  disabled: PropTypes.bool,
};