import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers } from "common/functions";
import { operationClosedAlarmTableHead, equipmentClosedAlarmTableHead, rawMaterialClosedAlarmTableHead, climateClosedAlarmTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, reset } from ".";
import { GetAllAlarmNotification } from "services/UserPortal/AlarmService";

import styles from "assets/jss/components/UserPortal/alarmStyle.js";

const useStyles = makeStyles(styles);

export default function AlarmTable(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.alarm.totalCount);
  const result = useSelector(store => store.user.alarm.alarms);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('ruleNo');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const items = [
    {title: "Operation Module", url: "/user/alarm/details/operations", tableHead: operationClosedAlarmTableHead, name: "UserPortal.Operation"},
    {title: "Equipment Module", url: "/user/alarm/details/equipment", tableHead: equipmentClosedAlarmTableHead, name: "UserPortal.Equipment"},
    {title: "Raw Material Module", url: "/user/alarm/details/raw-material", tableHead: rawMaterialClosedAlarmTableHead, name: "UserPortal.RawMaterial"},
    {title: "Climate & Environment Module", url: "/user/alarm/details/climate", tableHead: climateClosedAlarmTableHead, name: "UserPortal.ClimateEnvironment"},
  ]

  const getAllAlarmNotification = (pageNo, stopLoading) => {
    if (alarmPage && props.index) {
      dispatch(GetAllAlarmNotification({...props.filter, moduleName: alarmPage.name, page: pageNo, isNotification: props.index===2, IsResolved: props.index===1, stopLoading}))
      .then((response) => {
        if (response.payload && response.payload.result) {
          dispatch(setValues({
            totalCount: response.payload.result.totalCount,
            alarms: response.payload.result.items,
          }));
        }
      });
    }
  }

  React.useEffect(() => {
    setPage(0);
    getAllAlarmNotification(0);
  },[props.index, props.filter]);

  React.useEffect(() => {
    getAllAlarmNotification(page);
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllAlarmNotification(page, true);
  });

  const alarmPage = items.find(({url})=>url===props.location.pathname);
  return (
    <React.Fragment >
      <Table
        className={classes.table}
        header={(props.index === 2 && alarmPage) ? 
          alarmPage.tableHead.filter(({id}) => alarmPage.title === "Equipment Module" ? !id.includes("resolve") && !id.includes("userName") : !id.includes("resolve")) : 
          alarmPage.tableHead}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          return (
            <TableRow key={index}>
              {(props.index === 2 && alarmPage)
              ? alarmPage.tableHead.filter(({id}) => alarmPage.title === "Equipment Module" ? !id.includes("resolve") && !id.includes("userName") : !id.includes("resolve")).map(({id}, i) => {
                  let value = _.get(item, id);
                  if (id.includes("Time") && value) {
                    value = moment(value).format("DD MMM YYYY, HHmm");
                  }
                  if (id.includes("weight") && value) {
                    value = formatNumbers(value);
                  }
                  return <TableCell key={i}>{value}</TableCell>
                })
              : alarmPage.tableHead.map(({id}, i) => {
                  let value = _.get(item, id);
                  if (id.includes("Time") && value) {
                    value = moment(value).format("DD MMM YYYY, HHmm");
                  }
                  if (id.includes("weight") && value) {
                    value = formatNumbers(value);
                  }
                  return <TableCell key={i}>{value}</TableCell>
                })
              }
            </TableRow>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
      <div className={classes.buttonDiv}>
        <Button
          className={classes.buttonSecondary}
          onClick={()=>history.push("/user/alarm/details")}
        >
          Go Back
        </Button>
      </div>
    </React.Fragment>
  );
}
