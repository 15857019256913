import React from "react";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
// @mui/icons-material
import errorImg from "assets/img/error-img.svg";

import styles from "assets/jss/error-page/errorStyle.js";

const useStyles = makeStyles(styles);

export default function Error() {
  const classes = useStyles();

  return (
    <div className={clsx("h-screen", classes.container)}>
      <div>
        <img className={classes.errorImg} src={errorImg} alt="greenphyto-error" />
        <Typography className={classes.description}>Sorry! The page you’re looking for doesn’t exist.</Typography>
      </div>
    </div>
  );
}
