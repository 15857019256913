// Trays Per Configuration
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Popper from "shared-components/Popper/Popper";

import { formatNumbers, roundTo2Decimal } from "common/functions";
import { setSearchText } from "store/general";
import { rowsPerTable } from "config";
import { reset } from "./store/configuration";
import { capacityTrayConfigurationTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllCapacityTrayConfig, GetAllProduct } from "services/AdminPortal/CapacityService";

import helper from "assets/icons/black/helper.svg";
import styles from "assets/jss/components/AdminPortal/capacityStyle.js";

const useStyles = makeStyles(styles);

export default function TrayConfiguration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const configurations = useSelector(store => store.admin.capacity.configuration.configurations);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productId');
  const [page, setPage] = React.useState(0);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [productList, setProductList] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  const count = Math.ceil(configurations.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOnChange_select = (e) => {
    setSelectedProduct(e.target.value);
  };

  React.useEffect(() => {
    if (selectedProduct) {
      dispatch(GetAllCapacityTrayConfig(selectedProduct));
    }
  },[selectedProduct]);
  
  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllProduct())
    .then(({payload}) => {
      let list = [];
      if (payload && payload.result) {
        for (let obj of payload.result) {
          if (obj.products) {
            for (let item of obj.products) {
              list.push(item);
            }
          }
        }
      }
      setProductList(list);
      setSelectedProduct(list[0] && list[0].id);
    });
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  capacityTrayConfigurationTableHead[5].label = 
    <div className="flex">
      Weight per Plant (kg)
      <Popper 
        open={openPopper}
        className={classes.popper}
        closePopper={() => setOpenPopper(false)}
        buttonClick={() => setOpenPopper(false)}
        placement="bottom-start"
        title="Seedling Weight Not Required"
        content="Weight for plant will be covered in later stages."
      >
        <IconButton 
          onClick={() => setOpenPopper(true)}
        >
          <img className={classes.icon} src={helper} alt="helper" />
        </IconButton>
      </Popper>
    </div>;

  return (
    <React.Fragment>
      <Card 
        title = "Trays Per Configuration"
        subheader="Configure maximum number of individual products for trays and cages to be occupied and estimated average weight of product"
        action={
          <Select
            className={classes.productDropdown}
            name="productId"
            onChange={(e)=>handleOnChange_select(e)}
            value={selectedProduct}
            placeholder="Select a product"
          >
            {productList.map((item) =>{
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.productName}
                </MenuItem>
              )
            })}
          </Select>
        }
      >
        <div className={classes.table}>
          <Table
            header={capacityTrayConfigurationTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(configurations, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes.configCellWidth}>{item.product && item.product.productID}</TableCell>
                  <TableCell className={classes.configCellWidth}>{item.product && item.product.productName}</TableCell>
                  <TableCell className={classes.configCellWidth}>{item.process}</TableCell>
                  <TableCell>{formatNumbers(item.plantPerTray)}</TableCell>
                  <TableCell>{formatNumbers(item.trayPerCage)}</TableCell>
                  <TableCell>{item.weightPerPlant ? formatNumbers(item.weightPerPlant) : "NA"}</TableCell>
                  <TableCell>{item.weightPerPlant ? formatNumbers(roundTo2Decimal(Number(item.weightPerTray))) : "NA"}</TableCell>
                  <TableCell>{item.weightPerPlant ? formatNumbers(roundTo2Decimal(Number(item.weightPerCage))) : "NA"}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
    </React.Fragment>
  );
}
