import React from "react";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";

import logo from "assets/logo/logo-green.png";
import styles from "assets/jss/layouts/printStyle"

const useStyles = makeStyles(styles);

export default function PrintFormat({printing, children}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {printing && 
        <div className={classes.printContainer}>
          <div className="page-header"><img className={classes.logo} src={logo} /></div>
          {children}
          <div className="page-footer">
            <Typography className={classes.printFooter}><span className="font-bold">Greenphyto Pte Ltd</span> (Company GST Reg No. 201408170M)</Typography>
            <Typography className={classes.printFooter}>13 Tukang Innovation Drive | Greenphyto Innovation Centre | Singapore 619677</Typography>
            <Typography className={classes.printFooter}>T (65) 6967 6361 | https://greenphyto.com/</Typography>
          </div>
        </div>
      }
    </React.Fragment>
  );
}
