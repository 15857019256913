// Farm
// Bank Details
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { farmConfig } from "enums/Constants";
import { hideCages, rowsPerTable } from "config";
import { GetAllBank, DeleteBank } from "services/AdminPortal/FarmService";
import { bankTableHead } from "enums/AdminPortal/TableHeaders";
import { resetBanks } from ".";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/farmStyle.js";

const useStyles = makeStyles(styles);

import { useRouteCanWrite } from "hooks";

export default function BankDetails(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalBankCount = useSelector(store => store.admin.farm.totalBankCount);
  const banks = useSelector(store => store.admin.farm.banks);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('bankName');
  const [page, setPage] = React.useState(0);
  const [bankId, setBankId] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const count = Math.ceil(totalBankCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_exit = () => {
    props.setBankDetails(false);
    props.setFarmId(null);
  }

  const handleModal_delete = (id) => {
    setBankId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleModal_error = () => {
    setOpenErrorModal(false);
    resetState();
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteBank(bankId))
    .then((response) => {
      if (response.payload.message.includes("used")) {
        setOpenErrorModal(true);
      } else {
        dispatch(GetAllBank({id: props.id, page: 0}));
        resetState();
      }
    })
  }

  const resetState = () => {
    setBankId(null);
    setPage(0);
    setOpenDeleteModal(!openDeleteModal);
  }

  React.useEffect(() => {
    dispatch(GetAllBank({id: props.id, page}));
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(resetBanks());
    }
  },[]);

  // componentDidMount
  React.useEffect(() => {
    if (isDisabled) {
      history.push("/admin/farm/create")
    }
  },[isDisabled]);

  const bank = banks.find((item) => item.id === bankId);
  return (
    <React.Fragment>
      <Card 
        title = {farmConfig.bank+" Details of " + props.farmName}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleButtonClick_exit()}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={filterActionTableHead(bankTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { banks.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.bankName}</TableCell>
                  <TableCell>{formatNumbers(item.numberOfBays)}</TableCell>
                  <TableCell>{formatNumbers(item.numberOfLevels)}</TableCell>
                  {!hideCages && <TableCell>{formatNumbers(item.numberOfCages)}</TableCell>}
                  <TableCell align="right">
                    {userDetail.farms.find((userFarm) => userFarm.id === props.id) && canWrite &&
                      <React.Fragment>
                        <Link to={{pathname: "/admin/farm/"+props.id, state: {step: 1}}}>
                          <IconButton type="edit" />
                        </Link>
                        <IconButton 
                          type="delete"
                          onClick={() => handleModal_delete(item.id)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        {userDetail.farms.find((userFarm) => userFarm.id === props.id) && canWrite &&
          <Link to={{pathname: "/admin/farm/"+props.id, state: {step: 1, add: true}}}>
            <Button
              className={clsx(classes.buttonSecondary, classes.bankButton)}
              startIcon={<AddIcon />}
            >
              Add More {farmConfig.bank}
            </Button>
          </Link>
        }
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete one of your banks? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openErrorModal}
        onClose={() => handleModal_error()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Error"
        content={"As " + (bank && bank.bankName) + " has already been tagged with devices or selected for capacity categories, the bank information cannot be edited or deleted. Please use Capacity module to manage the capacity of the farm."}
        actions={<Button className={classes.button} onClick={() => handleModal_error()}>OK</Button>}
      />
    </React.Fragment>
  );
}
