import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"

import { graphColors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/sustainabilityStyle.js";

const useStyles = makeStyles(styles);

export default function FinancialChart() {
  const classes = useStyles();
  const results = useSelector(store => store.user.sustainability.chart.conversion);

  const start = moment("05 2024", "MM YYYY");
  const end = moment().add(5, "year");
  const months = [];

  while (end.isSameOrAfter(start, 'month')) {
    months.push({id: start.format('M YYYY'), label: start.format('MMM YYYY')});
    start.add(1, 'month');
  }

  const config = () => {
    return ({
      colors: graphColors,
      chart: {
        type: "column",
        zoomType: 'x',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: [{
        categories: months.map(({label}) => label),
        crosshair: true
      }],
      yAxis: {
        labels: {
          format: '${value}',
        }
      },
      series: [
        {
          name: "Financial",
          data: months.map((item, i) => {
            const value = results.kwh && results.kwh.find((val) => val && val.month+" "+val.year === item.id);
            return {
              x: i,
              y: value && value.pV_Value,
              color: graphColors[0]
            };
          }),
        }
      ],
    })
  };
  
  return (
    <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.highChart }} />
  );
}
