import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import { GetAllManufacturer } from "services/AdminPortal/ManufacturerService";
import { GetAllDevice, GetDevice, GetDeviceHierarchy, GetProposedDeviceRefNo, GetDeviceListForHierarchy, UploadBatchDevice } from "services/AdminPortal/DeviceService";

// initial state
const initialState = {
  batchDevices: [],
  batchDevice: {},
  deviceNames: [],
  manufacturer: [],
  hierarchy: [],
  finishedDevices: [],
  unfinishedDevices: [],
  deviceHierarchy: {},
  device: {
    deviceFunctions: [],
    deviceChildIds: []
  },
};

const device = createSlice({
  name: "device",
  initialState,
  reducers: {
    deleteRequest: (state, action) => {
      if (state.device[action.payload.key].length > 1) {
        state.device[action.payload.key].splice(action.payload.index, 1);
      } else {
        state.device[action.payload.key] = [];
      }
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.device[key] = action.payload[key];
      })
    },
    setBatchValues: (state, action) => {
      state.batchDevice = action.payload;
    },
    deleteBatchRequest: (state, action) => {
      state.batchDevices = _.filter(state.batchDevices, (item) => item.lineNo !== action.payload);
    },
    updateBatchesRequest: (state, action) => {
      state.batchDevices = state.batchDevices.map((item) => {
        if (item.lineNo === action.payload.deviceIndex) {
          return action.payload.value;
        } else {
          return item;
        }
      });
    },
    updateBatchRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batchDevice[key] = action.payload[key];
      })
    },
    resetTesting: (state) => {
      state.device.testing = null;
    },
    resetBatch: (state) => {
      state.batchDevice = {};
    },
    resetDevices: (state) => {
      state.finishedDevices = [];
      state.unfinishedDevices = [];
    }, 
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllDevice.fulfilled]: (state, action) => {
      state.finishedDevices = action.payload.result.deviceFinishList.items;
      state.unfinishedDevices = action.payload.result.deviceUnfinishList.items;
      state.deviceNames = _.uniq([...action.payload.result.deviceFinishList.items, ...action.payload.result.deviceUnfinishList.items].map(e => e.deviceName).filter(el => el)).sort();
    },
    [GetDevice.fulfilled]: (state, action) => {
      const value = action.payload.result;
      state.device = _.omit(value, ["farm"]);
      if (value.childDevices) {
        state.device.deviceChildIds = value.childDevices.map((item) => {return item.id});
      } else {
        state.device.deviceChildIds = [];
      }
      state.device.deviceFunctions = value.deviceFunctions.map((item) => {return item.id});
    },
    [GetDeviceHierarchy.fulfilled]: (state, action) => {
      state.deviceHierarchy = action.payload.result;
    },
    [GetProposedDeviceRefNo.fulfilled]: (state, action) => {
      state.device.deviceRefNo = action.payload.result;
    },
    [GetAllManufacturer.fulfilled]: (state, action) => {
      if (action.payload.result) {
        state.manufacturer = _.orderBy(action.payload.result.items, ["manufacturerName"], ["asc"]);
      } else {
        state.manufacturer = [];
      }
    },
    [GetDeviceListForHierarchy.fulfilled]: (state, action) => {
      state.hierarchy = _.orderBy(action.payload.result, ["deviceRefNo"], ["asc"]);
    },
    [UploadBatchDevice.fulfilled]: (state, action) => {
      state.batchDevices = action.payload.result.results.map((item) => {
        if ((!item.manufacturerID || !item.manufacturerName) || (!item.deviceParent && !item.deviceChilds)) {
          item.isDraft = true;
        }
        return item;
      });
    },
  },
});

// export actions
export const { deleteRequest, updateRequest, resetTesting,
  setBatchValues, deleteBatchRequest, updateBatchRequest, updateBatchesRequest, resetBatch,
  resetDevices, reset } = device.actions;

// export the reducer
export default device.reducer;