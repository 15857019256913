import { createSlice } from "@reduxjs/toolkit";

import { GetAllRawMaterialRequestOrReceived, GetRawMaterialStatusList, GetReceiptDetail } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  receiptList: [],
  receipt: {},
  statusList: {},
  suppliers: [],
  warehouses: [],
  allRackNumber: []
};

const receiptRM = createSlice({
  name: "receiptRM",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.receipt.rawMaterialBatch[key] = action.payload[key];
      })
    },
    updateReceiptDate: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.receipt[key] = action.payload[key];
      })
    },
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllRawMaterialRequestOrReceived.fulfilled]: (state, action) => {
      state.totalCount= action.payload.result.totalCount;
      state.receiptList = action.payload.result.items;
    },
    [GetRawMaterialStatusList.fulfilled]: (state, action) => {
      state.statusList = action.payload.result;
    },
    [GetReceiptDetail.fulfilled]: (state, action) => {
      state.receipt = action.payload.result;
      state.receipt.rawMaterialBatch.rackNumbers = action.payload.result.rawMaterialBatch.rackNumbers && action.payload.result.rawMaterialBatch.rackNumbers.map((item) => {return {rackID: item}});
    },
  },
});

// export actions
export const { updateRequest, reset, setValues, updateReceiptDate } = receiptRM.actions;

// export the reducer
export default receiptRM.reducer;