import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import { GetAllDevicesTagging } from "services/AdminPortal/DeviceTaggingService";

// initial state
const initialState = {
  devicesTagging: {
    bankList: [],
    floorPlanList: []
  },
  skipStep: {},
  isCompleted: {
    step2: false,
    step3: false,
    step4: false,
  }
};

const deviceTagging = createSlice({
  name: "deviceTagging",
  initialState,
  reducers: {
    setCompleted: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.isCompleted[key] = action.payload[key];
      })
    },
    setSkipStep: (state, action) => {
      state.skipStep = action.payload;
    },
    resetSkipStep: (state, action) => {
      state.skipStep = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllDevicesTagging.fulfilled]: (state, action) => {
      let payload = _.orderBy(action.payload.result.floorPlanList, ["floorNumber", "sectorNumber"], ["asc", "asc"]);
      state.devicesTagging = {
        bankList: action.payload.result.bankList,
        floorPlanList: payload
      };
    },
  },
});

// export actions
export const { setCompleted, setSkipStep, resetSkipStep, reset } = deviceTagging.actions;

// export the reducer
export default deviceTagging.reducer;