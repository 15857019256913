import { alpha } from "@mui/material/styles";
import { 
  whiteColor 
} from "assets/jss/generaliseStyle";

const textFieldStyle = (theme) => ({
  root: {
    maxHeight: '3.646vw',
    border: '0.104vw solid #9DD79D',
    overflow: 'hidden',
    borderRadius: "0.313vw",
    backgroundColor: '#fcfcfb',
    '&:hover': {
      backgroundColor: whiteColor,
    },
    '&.Mui-focused': {
      backgroundColor: whiteColor,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInputLabel-filled': {
      transform: "translate(0.625vw, 1.150vw) scale(1)"
    },
    '& .MuiInputLabel-shrink': {
      transform: "translate(0.625vw, 0.521vw) scale(0.75)"
    }
  },
  textfieldGrid: {
    flex: "1 1",
    maxHeight: "3.542vw"
  },
  textfield: {
    width: '-webkit-fill-available',
  },
  transparent: {
    backgroundColor: "transparent !important",
  },
  focused: {
    '&:hover': {
      backgroundColor: whiteColor,
    },
    '&.Mui-focused': {
      backgroundColor: whiteColor,
    },
    '& .MuiFilledInput-input': {
      padding: "1.5vw 0.625vw 0.521vw",
    },
  },
  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "rgba(19, 50, 43, 0.5)",
      opacity: 1
    },
    padding: "0 !important"
  },
  adornment: {
    height: '1.250vw',
    width: '1.250vw',
  },
  container: {
    maxHeight: '3.646vw',
    width: 'auto',
    margin: '0 0.938vw',
  },
  label: {
    textAlign: "left",
    margin: "1.563vw 0 0.365vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  textFieldDiv: {
    position: "relative"
  },
  error: {
    borderColor: "#FF0000",
  },
  message: {
    color: "#FF0000",
    textAlign: "right",
    fontSize: "0.729vw",
    position: "absolute",
    right: 0,
    bottom: "-1.250vw"
  }
});

export default textFieldStyle;
  