/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { setSearchText, setDirty } from "store/general";
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { flowControlSettingsTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, updateRequest, reset } from "../store/settings";
// import { GetIntervalTime, GetDeliveryTripSetting, CreateOrUpdateTripSetting } from "services/UserPortal/DeliveryService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/flowControlStyle.js";

const useStyles = makeStyles(styles);

export default function Settings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const filter = useSelector(store => store.user.flowControl.settings.filter);
  const totalCount = useSelector(store => store.user.flowControl.settings.totalCount);
  const result = useSelector(store => store.user.flowControl.settings.settings);
  const setting = useSelector(store => store.user.flowControl.settings.setting);
  const [newFilter, setNewFilter] = React.useState({page: 0});
  const [editId, setEditId] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleButtonClick_edit = (item) => {
    setEditId(item.id);
    dispatch(setValues({setting: item}));
  }

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  };

  const handleButtonClick_confirmEdit = () => {
    if (validateFields()) {
      // dispatch(CreateOrUpdateRawMaterialReserve(editItem))
      // .then(() =>{
      //   setEditId(null);
      //   dispatch(setValues({setting: {}}));
      // })
    }
  }

  const handleButtonClick_cancel = () => {
    dispatch(setDirty(false));
    setEditId(null);
    dispatch(setValues({setting: {}}));
  }

  const validateFields = () => {
    if (!setting.alarmTypeId) {
      setErrorMsg({field: "alarmTypeId", msg: "Please select a alarm type"});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));  // no filter button
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: classes.settingsCard
        }}
        title = "Settings"
      >
        <div className={classes.table}>
          <Table
            header={flowControlSettingsTableHead}
            // header={filterActionTableHead(rmReserveTableHead, canWrite)}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.productName}</TableCell>
                  {(editId && item.id === editId)
                  ? <React.Fragment>
                      <TableCell>
                        <TextField
                          className={classes.alignTextfield}
                          id="recipeVolumePerTrayNursery"
                          variant="outlined"
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,40)
                          }}
                          onChange={(e) => handleOnChange_text(e.target.value)}
                          value={setting.recipeVolumePerTrayNursery}
                          errorMsg={errorMsg}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.alignTextfield}
                          id="recipeVolumePerTrayNursery"
                          variant="outlined"
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,40)
                          }}
                          onChange={(e) => handleOnChange_text(e.target.value)}
                          value={setting.recipeVolumePerTrayNursery}
                          errorMsg={errorMsg}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.alignTextfield}
                          id="recipeVolumePerTrayNursery"
                          variant="outlined"
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,40)
                          }}
                          onChange={(e) => handleOnChange_text(e.target.value)}
                          value={setting.recipeVolumePerTrayNursery}
                          errorMsg={errorMsg}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.alignTextfield}
                          id="recipeVolumePerTrayNursery"
                          variant="outlined"
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,40)
                          }}
                          onChange={(e) => handleOnChange_text(e.target.value)}
                          value={setting.recipeVolumePerTrayNursery}
                          errorMsg={errorMsg}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <CustomIconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirmEdit()}
                        />
                        <CustomIconButton 
                          type="close"
                          onClick={() => setEditId(null)}
                        />
                      </TableCell>
                    </React.Fragment>
                  : <React.Fragment>
                      <TableCell>{formatNumbers(roundTo2Decimal(item.recipeVolumePerTrayNursery))}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(item.recipeVolumePerTrayGrowth))}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(item.nurseryRequirement))}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(item.growthRequirement))}</TableCell>
                      <TableCell align="right">
                        <CustomIconButton 
                          type="edit"
                          onClick={() => handleButtonClick_edit(item)}
                          disabled={editId?true:false}
                        />
                      </TableCell>
                    </React.Fragment>
                  } 
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
