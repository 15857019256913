export const DashboardTutorialPopper = [
  {
    step: 0,
    link: "/admin/company",
    title: "Create New Company",
    content: "Let's start by creating your own company. Click on the company button to get started!",
  },
  {
    step: 2,
    link: "/admin/farm",
    title: "Create New Farm",
    content: "Awesome! Now lets move on to adding a farm. Click on the farm button to continue."
  }
]

export const TutorialPopper = [
  {
    step: 1,
    title: "Create your own company!",
    content: "Let’s start by creating your own company. Click on the  “Add New” button to begin!"
  }
]