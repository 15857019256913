import React, { useRef } from "react";
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from "prop-types";
// @mui/material components
import { withStyles } from "@mui/styles";
import TableRow from "@mui/material/TableRow";
// core components
import CustomTableRow from "shared-components/Table/TableRow";
import { backgroundColor, whiteColor, primaryColor } from "assets/jss/generaliseStyle.js";

const TableRowCss = withStyles({
  root: {
    color: primaryColor[3],
    cursor: "pointer",
    '& td': {
      fontSize: "0.938vw",
    },
    '&:nth-of-type(odd)': {
      backgroundColor,
    },
    '&:nth-of-type(even)': {
      backgroundColor: whiteColor,
    },
    '& td:first-child': {
      paddingLeft: "2.292vw"
    },
    '& td:last-child': {
      paddingRight: "2.292vw"
    }
  },
})(TableRow);

export default function DragTableRow({ id, index, onMoveItem, children, ...props }) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "div",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [, drag] = useDrag({
    type: "div",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  if (props.disabled) {
    return (
      <CustomTableRow>
        {children}
      </CustomTableRow>
    )
  } else {
    return (
      <TableRowCss {...props} ref={ref} data-handler-id={handlerId}>
        {children}
      </TableRowCss>
    );
  }
}

DragTableRow.propTypes = {
  children: PropTypes.node,
};