// Edit User Configuration
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import { isPositiveDecimal } from "common/validations";
import { renderValue, roundTo2Decimal } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { daysOfWeek } from "enums/Time";
import { updateRequest, resetEmployee } from "../store/configuration";
import { GetEmployee, CreateOrUpdateEmployee, GetAllTimeInterval } from "services/AdminPortal/HumanResourceService";

import styles from "assets/jss/components/AdminPortal/humanResourceStyle.js";

const useStyles = makeStyles(styles);
export default function EditHumanResource() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const employee = useSelector(store => store.admin.humanResource.configuration.employee);
  const timeInterval = useSelector(store => store.admin.humanResource.configuration.timeInterval);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateEmployee())
        .then(({error})=>{
          if (error) {
            dispatch(setDirty(true));
          } else {
            history.push("/admin/human-resource/user-configuration");
          }
        });
      })
    }
  };

  const validateFields = () => {
    if (!employee.hourRate || (employee.hourRate && !isPositiveDecimal(employee.hourRate))) {
      setErrorMsg({field: "hourRate", msg: "Please enter a valid hour rate"});
      return false;
    }
    if (employee.dayRate && !isPositiveDecimal(employee.dayRate)) {
      setErrorMsg({field: "dayRate", msg: "Please enter a valid day rate"});
      return false;
    }
    if (employee.monthRate && !isPositiveDecimal(employee.monthRate)) {
      setErrorMsg({field: "monthRate", msg: "Please enter a valid month rate"});
      return false;
    }
    if (employee.yearRate && !isPositiveDecimal(employee.yearRate)) {
      setErrorMsg({field: "yearRate", msg: "Please enter a valid year rate"});
      return false;
    }
    if (employee.otHourRate && !isPositiveDecimal(employee.otHourRate)) {
      setErrorMsg({field: "otHourRate", msg: "Please enter a valid overtime hourly rate"});
      return false;
    }
    if (!employee.numberOfWorkingHoursPerShift || (employee.numberOfWorkingHoursPerShift && !isPositiveDecimal(employee.numberOfWorkingHoursPerShift))) {
      setErrorMsg({field: "numberOfWorkingHoursPerShift", msg: "Please enter a valid shift hour"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(GetAllTimeInterval());
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "user-configuration");
    if (path[index+1] != "create") {
      dispatch(GetEmployee(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push("/admin/human-resource/user-configuration");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetEmployee());
      dispatch(resetNav());
    }
  },[]);

  const renderDays = (start, end) => {
    return daysOfWeek.slice(start, end).map((day, index) => {
      return (
        <Grid item key={index}>
          <Grid container spacing={2} key={index}>
            <Grid item xs={2} className={classes.label}>
              <Typography>{day}:</Typography>
            </Grid>
            <Grid item xs={10} className={classes.label}>
              <Select
                name={day.toLowerCase()+"From"}
                className={classes.select}
                onChange={(e)=>handleOnChange_select(e)}
                value={!employee[day.toLowerCase()+"From"] ? "-" : employee[day.toLowerCase()+"From"]}
              >
                <MenuItem value="-">-</MenuItem>
                {timeInterval.map((item, i) => {
                  return <MenuItem key={i} value={item.timeInterval}>{item.timeInterval}</MenuItem>;
                })} 
              </Select>
              <Typography className={classes.dayLabel}>To</Typography>
              <Select
                name={day.toLowerCase()+"To"}
                className={classes.select}
                onChange={(e)=>handleOnChange_select(e)}
                value={!employee[day.toLowerCase()+"To"] ? "-" : employee[day.toLowerCase()+"To"]}
              >
                <MenuItem value="-">-</MenuItem>
                {timeInterval.map((item, i) => {
                  return <MenuItem key={i} value={item.timeInterval}>{item.timeInterval}</MenuItem>;
                })} 
              </Select>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  return (
    <React.Fragment>
      <Card 
        title="Employee Details"
      >
        <Paper className={classes.paper} elevation={0}>
          <Typography className={classes.sectionHeader}>General Employee Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} className={classes.label}>
              <Typography>Employee ID:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField 
                className={classes.textField}
                variant="outlined" 
                inputProps={{ maxLength: 10 }}
                value={renderValue(employee.employeeId)}
                disabled
              />
            </Grid>
            <Grid item xs={1} className={classes.label}>
              <Typography>Employee Name:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField 
                className={classes.textField}
                variant="outlined" 
                value={renderValue(employee.employeeName)}
                disabled
              />
            </Grid>
            <Grid item xs={1} className={classes.label}>
              <Typography>Workforce Type:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField 
                className={classes.textField}
                variant="outlined" 
                value={renderValue(employee.workforceType)}
                disabled
              />
            </Grid>
            <Grid item xs={1} className={classes.label}>
              <Typography>Employment Status:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField 
                className={classes.textField}
                variant="outlined" 
                value={renderValue(employee.employeeStatus)}
                disabled
              />
            </Grid>
          </Grid>
          <Typography className={classes.sectionHeader}>
            Payment Rates<br />
            <span className={classes.sectionSubHeader}>Please enter figures for any one of the fields.</span>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={1} className={classes.label}>
              <Typography>Hourly Rate:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField 
                id="hourRate"
                className={classes.textField}
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                placeholder="Enter hourly rate"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(employee.hourRate)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={1} className={classes.label}>
              <Typography>Daily Rate:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField 
                id="dayRate"
                className={classes.textField}
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                placeholder="Enter daily rate"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(employee.dayRate)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={1} className={classes.label}>
              <Typography>Monthly Rate:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField 
                id="monthRate"
                className={classes.textField}
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                placeholder="Enter monthly rate"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(employee.monthRate)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={1} className={classes.label}>
              <Typography>Yearly Rate:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField 
                id="yearRate"
                className={classes.textField}
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                placeholder="Enter yearly rate"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(employee.yearRate)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={1} className={clsx(classes.label, classes.addButton)}>
              <Typography>Overtime Hourly Rate:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField 
                id="otHourRate"
                className={clsx(classes.textField, classes.addButton)}
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                placeholder="Enter overtime hourly rate"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(employee.otHourRate)}
                errorMsg={errorMsg}
              />
            </Grid>
          </Grid>
          <Typography className={classes.sectionHeader}>Working Hours</Typography>
          <Grid container spacing={2}>
            <Grid item xs={2} className={classes.label}>
              <Typography>Number of working hours per shift:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField 
                id="numberOfWorkingHoursPerShift"
                className={classes.textField}
                variant="outlined" 
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                placeholder="Enter working hours per shift"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(employee.numberOfWorkingHoursPerShift)}
                errorMsg={errorMsg}
              />
            </Grid>
          </Grid>
          <Typography className={classes.sectionHeader}>Work Schedule</Typography>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Grid container spacing={2} direction="column">
                {renderDays(0, 4)}
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={2} direction="column">
                {renderDays(4)}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.action}>
          <Button
            className={classes.buttonSecondary}
            onClick={()=>history.goBack()}
          >
            Go Back
          </Button>
          <Button
            className={classes.button}
            onClick={()=>handleButtonClick_save()}
          >
            Save
          </Button>
        </div>
      </Card>
    </React.Fragment>
  )
}
