import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material components
import { makeStyles } from "@mui/styles"
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// @mui/icons-material
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// core components
import TextField from "shared-components/TextField/TextField";

import { setValues } from "store/general";
import { ChatWithAIBotWithMemory } from "services/AIService";

import close from "assets/icons/orange/close.svg";
import chatbot from "assets/img/chatbot.svg";
import styles from "assets/jss/shared-components/chatbotStyle.js";

const useStyles = makeStyles(styles);
export default function Chatbot({...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contentRef = React.useRef(null);
  const sessionId = useSelector(store => store.general.sessionId);
  const chatHistory = useSelector(store => store.general.chatHistory);
  const [isLoading, setIsLoading] = React.useState(false);
  const [query, setQuery] = React.useState("");
  
  const handleOnChange_text = (e) => {
    setQuery(e.target.value);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!isLoading && query) {
      setIsLoading(true);
      setQuery("");
      let payload = [...chatHistory, query];
      Promise.all([dispatch(setValues({chatHistory: [...chatHistory, query]}))])
      .then(() => {
        if (contentRef.current) {
          contentRef.current.scroll({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
        }
        dispatch(ChatWithAIBotWithMemory({session_id: sessionId ?? '', question: query}))
        .then((res) => {
          setIsLoading(false);
          if (res.error) {
            dispatch(setValues({chatHistory: [...payload, "An error has occurred"], sessionId: res.payload.session_id}));
          } else {
            dispatch(setValues({chatHistory: [...payload, res.payload.response.replaceAll('\n', '<br/>')], sessionId: res.payload.session_id }));
          }
          if (contentRef.current) {
            contentRef.current.scroll({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
          }
        });
      })
    }
  };

  const closeChatbot = () => {
    props.setChatbotOpen(!props.chatbotOpen);
    localStorage.setItem('chatbotOpen', false);
  }

  return (
    <div className={classes.chatbotModal}>
      <IconButton className={classes.modalIcon} onClick={closeChatbot}>
        <img className={classes.icon_24} src={close} alt="close" />
      </IconButton>
      <div className={classes.modalHeader}>
        <img className={classes.modalImg} src={chatbot} alt="chatbot" />
        <Typography className={classes.modalTitle}>I&apos;m FOMS AI Bot, your virtual assistant.</Typography>
      </div>
      <div className={classes.modalScroll} ref={contentRef}>
        <div className={classes.modalContent}>
          {_.map(chatHistory, (item, i) => {
            if (item) {
              return (
                <Typography key={i} className={(i%2 ? classes.userChat : classes.aiChat)}>{typeof item === 'object' ? item.message : item}</Typography>
              )
            }
          })}
          {isLoading &&
            <Typography className={classes.aiChatTyping}>Typing...</Typography>
          }
        </div>
      </div>
      <form onSubmit={(e) => handleButtonClick(e)}>
        <TextField 
          className={classes.textField} 
          placeholder="Type your query here..."
          InputProps={{
            endAdornment: 
              <InputAdornment position="end">
                <IconButton
                  onClick={(e)=>handleButtonClick(e)}
                >
                  <KeyboardArrowRightIcon className={classes.textFieldIcon} />
                </IconButton>
              </InputAdornment>,
          }}
          onChange={(e) => handleOnChange_text(e)}
          value={query}
        />
      </form>
    </div>
  );
}