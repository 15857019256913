/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Checkbox from "shared-components/Checkbox/Checkbox";
// @material-ui/icon
import GoogleIcon from '@mui/icons-material/Google';
// core components
import TextField from "shared-components/TextField/TextFieldWithLabel.js";

import user from "assets/icons/black/username.svg";
import lock from "assets/icons/black/password.svg";
import eye from "assets/icons/orange/eye.svg";
import eyeOff from "assets/icons/orange/eye-off.svg";
import styles from "assets/jss/components/Authentication/authStyle.js";

import { setAdminPortal } from "store/general";
import { loginRequest, reset } from "./store/login";
import { Authenticate, GoogleLogin } from "services/TokenAuthService";
import { FOMS_URL } from "config";

const useStyles = makeStyles(styles);

export default function Login() {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const credentials = useSelector(store => store.credentials.login);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [clicked, setClicked] = React.useState(false);
  const [checked, setChecked] = React.useState(true);

  const handleOnChange_text = (e) => {
    if (e.target.id === 'userName') {
      e.target.value = e.target.value.replace(/\s/g, '');
    }
    dispatch(loginRequest({ [e.target.id]: e.target.value }));
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (validateFields() && !clicked) {
      setClicked(true);
      dispatch(Authenticate())
      .then((result) => {
        setClicked(false);
        const hasAdmin = JSON.parse(localStorage.getItem("hasAdmin"));
        const userDetail = JSON.parse(localStorage.getItem("userDetail"));
        if (!result.error) {
          if (hasAdmin && !userDetail.companyId) {
            dispatch(setAdminPortal(true));
            history.push("/admin/home");
          } else {
            dispatch(setAdminPortal(false));
            history.push("/user/home");
          }
        }
      })
    }
  };

  const validateFields = () => {
    if (_.isEmpty(credentials.userNameOrEmailAddress)) {
      setErrorMsg({ field: "userNameOrEmailAddress", msg: "Username cannot be empty." });
      return false;
    }
    if (!checked) {
      setErrorMsg({field: 'checkbox', msg: 'Please agree to all the terms and conditions before logging in.'});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const handleCredentialResponse = (response) => {
    // console.log(response.credential);
    dispatch(GoogleLogin({tokenId: response.credential}))
    .then((result) => {
      setClicked(false);
      const hasAdmin = JSON.parse(localStorage.getItem("hasAdmin"));
      const userDetail = JSON.parse(localStorage.getItem("userDetail"));
      if (!result.error) {
        if (hasAdmin && !userDetail.companyId) {
          dispatch(setAdminPortal(true));
          history.push("/admin/home");
        } else {
          dispatch(setAdminPortal(false));
          history.push("/user/home");
        }
      }
    })
  }

  // componentDidMount
  React.useEffect(() => {
    if (credentials.error) {
      setErrorMsg({ field: "password", msg: "Incorrect Username/Password. Try again." });
    }
  }, [credentials]);

  // componentDidMount
  React.useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      history.push("/admin/home")
    }
    /* global google */
    // google.accounts.id.initialize({
    //   client_id: '1092296843058-rivth2q82t9ntm1uv47v24hbbhdobb5k.apps.googleusercontent.com',
    //   callback: handleCredentialResponse
    // });
    // google.accounts.id.renderButton(
    //   document.getElementById("signInDiv"),
    //   { size: "large", logo_alignment: "center", width: 380 }
    // )
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  }, []);

  return (
    <>
      <form onSubmit={(e) => handleButtonClick(e)}>
        <Typography className={classes.title}>
          Log In
        </Typography>
        <Typography className={classes.subtitle}>
          Welcome to Farm Operation Management System. Please enter your credentials to proceed.
        </Typography>
        <TextField
          id="userNameOrEmailAddress"
          className={classes.textfield}
          startAdornment={<img src={user} alt="user-line" />}
          label="Username / Email"
          variant="filled"
          onChange={(e) => handleOnChange_text(e)}
          errorMsg={errorMsg}
          error={errorMsg}
        />
        <TextField
          id="password"
          className={classes.textfield}
          type={showPassword ? 'text' : 'password'}
          startAdornment={<img src={lock} alt="lock-line" />}
          endAdornment={
            <IconButton
              className={classes.adornment}
              classes={{ label: classes.adornment }}
              onClick={handleShowPasswordClick}
            >
              {showPassword
                ? <img className={classes.adornment} src={eye} alt="eye" />
                : <img className={classes.adornment} src={eyeOff} alt="eye-off" />
              }
            </IconButton>
          }
          label="Password"
          variant="filled"
          onChange={(e) => handleOnChange_text(e)}
          errorMsg={errorMsg}
        />
        <div className={classes.subfield}>
          <a href="/account/request_reset_password" className={classes.orange}>Forget Password?</a>
        </div>
        <Button
          type="submit"
          className={classes.button}
          onClick={(e) => handleButtonClick(e)}
        >
          Sign In
        </Button>
        <div className={classes.termsConditions}>
          <Checkbox 
            className={errorMsg.field === "checkbox" ? classes.checkboxError : null} 
            checked={checked}
            onChange={(e) => setChecked(!checked)}
          />
          <span>I agree to the&nbsp;<a href="https://userguide.greenphyto.com/user-terms-of-service/" rel="noreferrer" target="_blank" className={classes.orange}>Terms & Conditions</a>&nbsp;and&nbsp;<a href="https://userguide.greenphyto.com/privacy-policy/" rel="noreferrer" target="_blank" className={classes.orange}>Privacy Policy</a></span>
        </div>
        {/* <Button
          className={classes.googleButton}
          startIcon={<GoogleIcon />}
          onClick={(e) => handleGoogleLogin(e)}
        >
          Sign In With Google
        </Button> */}
        {/* <div className={classes.googleContainer} id="signInDiv" /> */}
        {/* <Typography className={classes.description}>
          Don&apos;t have an account?&nbsp;
          <a href="/signup" className={classes.orange}>Sign up here</a>
        </Typography> */}
      </form>
    </>
  );
}
