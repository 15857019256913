// Task Settings
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { useRouteCanWrite } from "hooks";
import { rowsPerTable } from "config";
import { taskSettingsTableHead } from "enums/AdminPortal/TableHeaders";
import { renderValue, filterActionTableHead } from "common/functions";
import { setDirty } from "store/general";
import { setValues, updateRequest } from "../store/settings";
import { GetAllTaskSetting, CreateOrUpdateTaskSetting, DeleteTaskSetting } from "services/AdminPortal/HumanResourceService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/humanResourceStyle.js";

const useStyles = makeStyles(styles);

export default function TaskSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.admin.humanResource.settings.totalCount);
  const results = useSelector(store => store.admin.humanResource.settings.tasks);
  const task = useSelector(store => store.admin.humanResource.settings.task);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('typeId');
  const [page, setPage] = React.useState(0);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [taskId, setTaskId] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setTaskId(id);
    setIsAddEdit(true);
    dispatch(setValues({task: _.find(results, (item) => item.id === id)}));
  }

  const handleModal_delete = (id) => {
    setTaskId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteTaskSetting(taskId))
    .then(() =>{
      dispatch(GetAllTaskSetting({page: 0}));
      setPage(0);
      resetState();
    })
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateTaskSetting())
      .then(({error}) => {
        if (!error) {
          dispatch(GetAllTaskSetting({page: 0}));
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (taskId === 0) {
      dispatch(setValues({tasks: _.filter(results, (item) => item.id !== taskId)}));
      setPage(0);
    }
    resetState();
  }

  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = [...results, {id: 0}];
    setIsAddEdit(true);
    setTaskId(0);
    dispatch(setValues({tasks: payload}));
  }

  const validateFields = () => {
    if (!task.taskName) {
      setErrorMsg({field: "taskName", msg: "Please enter task name"});
      return false;
    } 
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setPage(0);
    setIsAddEdit(false);
    setTaskId(null);
    setOpenDeleteModal(false);
    setErrorMsg(false);
    dispatch(setValues({task: {}}));
  }

  React.useEffect(() => {
    dispatch(GetAllTaskSetting({page}));
  },[page]);

  return (
    <React.Fragment>
      <Card 
        classes={{
          content: classes.cardContent
        }}
        title="Task Settings"
        subheader="Please click on &ldquo;Add More Task&rdquo; to configure task for the employees" 
      >
        <Paper elevation={0} className={classes.settingsPaper}>
          <Table
            className={classes.table}
            header={filterActionTableHead(taskSettingsTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { results.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    { isAddEdit && taskId === item.id
                    ? <TextField 
                        id="taskName"
                        variant="outlined" 
                        inputProps={{ maxLength: 255 }}
                        onChange={(e) => handleOnChange_text(e)}
                        value={renderValue(task.taskName)}
                        errorMsg={errorMsg}
                      />
                    : item.taskName
                    }
                  </TableCell>
                  <TableCell align="right" className={isAddEdit && taskId === item.id ? classes.tableCell : null}>
                    { isAddEdit && taskId === item.id
                    ? <React.Fragment>
                        <IconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirm()}
                        />
                        <IconButton 
                          type="close"
                          onClick={() => handleButtonClick_cancel()}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        <IconButton
                          type="edit" 
                          onClick={() => handleButtonClick_edit(item.id)}
                          disabled={(taskId && taskId !== item.id)}
                        />
                        <IconButton
                          type="delete" 
                          onClick={() => handleModal_delete(item.id)}
                          disabled={(taskId && taskId !== item.id)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
              disabled={Boolean(isAddEdit)}
            />
          }
          <Button
            className={clsx(classes.buttonSecondary, classes.addButton)}
            startIcon={<AddIcon />}
            onClick={() => handleButtonClick_add()}
            disabled={isAddEdit}
          >
            Add More Task
          </Button>
        </Paper>
      </Card>
      <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this task? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
    </React.Fragment>
  )
}
