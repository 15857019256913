import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from '@mui/material/CircularProgress';
// core components
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Select from "shared-components/Select/Select";

import { renderValue } from "common/functions";
import { setValues } from "store/common";
import { GetDeviceUnits } from "services/UserPortal/ClimateService";
import { GetDeviceList } from "services/UserPortal/CommonLookupService";

import search from "assets/icons/grey/search.svg";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function ParamList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceList = useSelector(store => store.common.deviceList);
  const functionList = useSelector(store => store.common.functionList);
  const [page, setPage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [searchValue, setSearchValue] = React.useState(""); // for device list

  const handleOnChange_text = (e) => {
    setSearchValue(e.target.value);
  }

  const handleOnChange_select = (e) => {
    if (e.target.name === "function") {
      fetchMoreData(null, 0, e.target.value);
      dispatch(setValues({deviceCount: 0, deviceList: []}));
    } else {
      props.setFilter({...props.filter, [e.target.name]: e.target.value});
    }
  };
  
  const fetchMoreData = (search, pageNo, functionId) => {
    if ((page !== -1 || pageNo !== undefined) && functionId) {
      setIsLoadingMore(true);
      const param = {
        bankId: props.bankId,
        page: pageNo !== undefined ? pageNo : page,
        DeviceRefNo: search ? search : "",
        DeviceFunctionId: functionId
      }
      dispatch(GetDeviceList(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result.items.length) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({deviceList: response.payload.result.items}));
              dispatch(GetDeviceUnits(functionId))
              .then(({payload}) => {
                if (payload.result && payload.result.length) {
                  props.setFilter({...props.filter, function: functionId, deviceId: [response.payload.result.items[0].id], unit: payload.result[0]});
                }
              });
            } else {
              dispatch(setValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            dispatch(GetDeviceUnits(functionId))
            .then(({payload}) => {
              if (payload.result && payload.result.length) {
                props.setFilter({...props.filter, function: functionId, deviceId: [], unit: payload.result[0]});
              }
            });
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleOnChange_checkbox = (e) => {
    let payload = _.cloneDeep(props.filter.deviceId);
    if (payload.includes(Number(e.target.value))) {
      payload.splice(payload.indexOf(Number(e.target.value)), 1);
    } else {
      if (props.filter.deviceId.length < 5) {
        payload.push(Number(e.target.value));
      }
    }
    props.setFilter({...props.filter, deviceId: payload});
  }

  const handleOnClick_clear = () => {
    props.setFilter({...props.filter, deviceId: []});
  }

  React.useEffect(() => {
    fetchMoreData(searchValue, 0, props.filter.function);
  },[searchValue]);

  return (
    <React.Fragment>
      <Select
        className={classes.paramDropdown}
        name="function"
        onChange={(e)=>handleOnChange_select(e)}
        value={renderValue(props.filter.function)}
        placeholder="Select a Device Function"
      >
        {_.filter(functionList, ({deviceFunctionName}) => !deviceFunctionName.includes('Controller')).map((item, index) => {
          return <MenuItem key={index} value={item.id}>{item.deviceFunctionName}</MenuItem>;
        })} 
      </Select>
      <TextField 
        id="search"
        variant="outlined" 
        placeholder="Search" 
        value={searchValue}
        InputProps={{
          startAdornment: 
            <InputAdornment position="start">
              <img src={search} alt="search" className={classes.icon_24}/>
            </InputAdornment>,
        }}
        onChange={(e) => handleOnChange_text(e)}
      />
      <Typography className={classes.link} onClick={handleOnClick_clear} disabled={!props.filter.deviceId.length}>Clear Selection</Typography>
      <List className={classes.list} id="scrollableDiv">
        <InfiniteScroll
          dataLength={page*10}
          next={()=>fetchMoreData()}
          hasMore={true}
          loader={ isLoadingMore && 
            <div className={"w-full text-center"}>
              <CircularProgress className={classes.loading} size={"1.250vw"} />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {deviceList.map((item, index)=>{
            return (
              <ListItem key={index} classes={{gutters: classes.listPadding}}>
                <Checkbox 
                  value={item.id} 
                  checked={props.filter.deviceId.includes(item.id)} 
                  onChange={(e)=>handleOnChange_checkbox(e)}
                /> {item.deviceRefNo}
              </ListItem>
            )
          })}
        </InfiniteScroll>
      </List>
    </React.Fragment>
  );
}
