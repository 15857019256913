// Create Edit Farm
// Stepper
import React from "react";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Stepper from "shared-components/Stepper/Stepper";

import { farmConfig } from "enums/Constants";

import styles from "assets/jss/components/AdminPortal/farmStyle.js";

const useStyles = makeStyles(styles);

export default function FarmStepper(props) {
  const classes = useStyles();

  const farmSteps = ['Create a Farm', 'Add a '+farmConfig.bank];

  return (
    <Stepper 
      className={classes.stepper}
      steps={farmSteps}
      activeStep={props.activeStep}
    />
  )
}
