import {
  border,
  icon_24,
  blackColor,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

const orange = {
  color: secondaryColor[0] + ' !important'
}

const link = {
  color: secondaryColor[0],
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  },
  "&:not(:first-child)": {
    marginTop: "1.302vw"
  },
}

const cardPaper = {
  border,
  boxShadow: "none",
}

const cardTitle = {
  fontSize: "1.094vw !important",
  display: "flex",
  alignItems: "center",
  marginTop: 0,
}

const icon = {
  ...icon_24,
  padding: 0,
}

// Table
const table = {
  margin: "0 -1.979vw",
  overflow: "overlay"
}

const whiteTable = {
  "& thead > tr > th:last-child": {
    minWidth: "unset",
    width: "6.250vw"
  },
  "& tbody > tr": {
    backgroundColor: whiteColor + " !important"
  },
}

const subTable = {
  margin: "0 4.167vw",
  '& thead > tr > th:first-child': {
    width: "unset !important",
    minWidth: "unset !important",
    paddingLeft: "1.667vw"
  },
  '& thead > tr > th': {
    backgroundColor: "transparent !important",
    width: "fit-content !important",
    minWidth: "fit-content !important",
    whiteSpace: "nowrap !important"
  },
  "& tbody > tr": {
    backgroundColor: "transparent !important",
  },
}

const loading = {
  height: "0.104vw",
  width: "0.104vw",
  color: blackColor,
}

// Search
const result = {
  marginBottom: "0.938vw",
  fontSize: "1.094vw",
  fontWeight: 500,
}

const collapsedIcon = {
  border,
  padding: "0.313vw !important",
  marginLeft: "1.042vw",
  borderRadius: "0.208vw"
}

const rotate = {
  transform: "rotate(180deg)"
}

const highlight = {
  color: whiteColor,
  backgroundColor: secondaryColor[0]
}

// Tab
const tab = {
  marginBottom: "-1.250vw"
}

const indicator = {
  backgroundColor: secondaryColor[0]
}

const tabLabel = {
  textTransform: "capitalize",
  fontSize: "1.094vw",
  fontWeight: 500,
  // marginTop: "0.729vw"
}

// Filter Tag
const tag = {
  marginTop: "0.521vw",
  marginRight: "1.042vw",
}

// Form
const sectionHeader = {
  marginBottom: "1.563vw",
  color: primaryColor[3],
  fontSize: "1.094vw",
  fontWeight: 500,
  '&:not(:first-child)': {
    marginTop: "3.021vw",
  },
}

export {
  orange,
  link,
  cardPaper,
  cardTitle,
  table,
  whiteTable,
  subTable,
  loading,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  tab,
  indicator,
  tabLabel,
  tag,
  sectionHeader,
}