// Create Edit Vehicle
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import VehicleType from "./VehicleType";
import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { isPositiveDecimal } from "common/validations";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, resetVehicle } from ".";
import { GetAllUnitOfMeasurement, GetVehicle, GetAllVehicleType, CreateOrUpdateVehicle, GetProposedVehicleRefNo } from "services/AdminPortal/VehicleService";

import settings from "assets/icons/orange/settings.svg";
import styles from "assets/jss/components/AdminPortal/vehicleStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditVehicle(props) {
  const classes = useStyles();  // required for styles
  let history = useHistory(); // required for change in url
  const dispatch = useDispatch(); // required to call api/store
  const vehicle = useSelector(store => store.admin.vehicle.vehicle);
  const vehicleTypes = useSelector(store => store.admin.vehicle.vehicleTypes);
  const uomLookup = useSelector(store => store.admin.vehicle.uomLookup);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [vehicleTypeSetting, setVehicleTypeSetting] = React.useState(false);

  // split url
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "vehicle");

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));  // general update store "vehicle"
    if (e.target.id === "carPlateNo" && e.target.value.length > 4) {  // special cases
      dispatch(GetProposedVehicleRefNo({VehicleTypeId: vehicle.vehicleTypeId, CarPlateNo: e.target.value}));
    }
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));  // general update store "vehicle"
    if (e.target.name === "vehicleTypeId" && vehicle.carPlateNo && vehicle.carPlateNo.length > 4) {  // special cases
      dispatch(GetProposedVehicleRefNo({VehicleTypeId: e.target.value, CarPlateNo: vehicle.carPlateNo}));
    }
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {  // if validation is true === no errors
      Promise.all([dispatch(setDirty(false))])  // to allow change in pathname, else it will be blocked with warning
      .then(() => {
        dispatch(CreateOrUpdateVehicle())
        .then(({error})=>{
          if (error) {
            dispatch(setDirty(true));
          } else {
            history.push("/admin/vehicle");
          }
        });
      })
    }
  };

  const validateFields = () => {
    if (!vehicle.vehicleName) {
      setErrorMsg({field: "vehicleName", msg: "Please enter a vehicle name"});
      return false;
    }
    if (!vehicle.vehicleTypeId) {
      setErrorMsg({field: "vehicleTypeId", msg: "Please select a vehicle type"});
      return false;
    }
    if (!vehicle.capacity) {
      setErrorMsg({field: "capacity", msg: "Please enter vehicle capacity"});
      return false;
    }
    if (!isPositiveDecimal(vehicle.capacity)) {
      setErrorMsg({field: "capacity", msg: "Please enter a valid vehicle capacity"});
      return false;
    }
    if (!vehicle.unitOfMeasurement) {
      setErrorMsg({field: "unitOfMeasurement", msg: "Please select a unit"});
      return false;
    }
    if (!vehicle.carPlateNo) {
      setErrorMsg({field: "carPlateNo", msg: "Please enter a car plate number"});
      return false;
    }
    if (!vehicle.vehicleDescription) {
      setErrorMsg({field: "vehicleDescription", msg: "Please enter a description"});
      return false;
    }
    if (vehicle.contact && !validate.isPhoneNumber(vehicle.contact)) {
      setErrorMsg({field: "contact", msg: "Invalid phone number"});
      return false;
    }
    if (vehicle.email && !validate.isEmail(vehicle.email)) {
      setErrorMsg({field: "email", msg: "Please enter a valid email address"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  // on value vehicleTypeSetting change
  React.useEffect(() => {
    dispatch(GetAllVehicleType());
  },[vehicleTypeSetting]);

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));  // disable add new button
    // get lookups
    dispatch(GetAllVehicleType());
    dispatch(GetAllUnitOfMeasurement());
    // check if its create / edit mode
    if (path[index+1] !== "create") {
      dispatch(GetVehicle(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push((prop && prop.prevPath) || "/admin/vehicle");
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(resetVehicle()); // clear store
      dispatch(resetNav());
    }
  },[]);

  if (vehicleTypeSetting) { // change component
    return <VehicleType setVehicleTypeSetting={setVehicleTypeSetting} />
  } else {  // initial component
    return (
      <Card 
        title = "Please key in details for Vehicle Creation"
      >
        <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={classes.label}>
              <Typography>Vehicle Name <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="vehicleName"  // required for onChange
                variant="outlined"  // design
                inputProps={{ maxLength: 100 }}
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Please enter vehicle name"
                value={renderValue(vehicle.vehicleName)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Vehicle Type <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                name="vehicleTypeId"  // required for onChange
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Please select a vehicle type"
                value={renderValue(vehicle.vehicleTypeId)}
                errorMsg={errorMsg}
                disabled={path[index+1] !== "create"}
              >
                {vehicleTypes && vehicleTypes.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.vehicleTypes}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={1} className="flex">
              {/* Link to Vehicle Type */}
              <IconButton 
                className={classes.iconButton}
                onClick={() => setVehicleTypeSetting(true)}
              >
              <img className={classes.icon} src={settings} alt="settings" />
              </IconButton>
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Capacity <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField 
                id="capacity"
                type="Number"
                variant="outlined" 
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Please enter capacity"
                value={renderValue(vehicle.capacity)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                name="unitOfMeasurement"
                placeholder="Please select a unit"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(vehicle.unitOfMeasurement)}
                errorMsg={errorMsg}
              >
                {uomLookup && uomLookup.map((item) => {
                  return <MenuItem key={item.unitOfMeasurementShortForm} value={item.unitOfMeasurementShortForm}>{item.unitOfMeasurementShortForm}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Car Plate <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="carPlateNo"
                variant="outlined" 
                inputProps={{ maxLength: 20 }}
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Eg. SGJ 6316P"
                value={renderValue(vehicle.carPlateNo)}
                errorMsg={errorMsg}
                disabled={path[index+1] !== "create"}
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Vehicle Description <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="vehicleDescription"
                variant="outlined" 
                multiline
                minRows={4}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Please enter a small description of the vehicle"
                value={renderValue(vehicle.vehicleDescription)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Driver Name :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="driverName"
                variant="outlined" 
                inputProps={{ maxLength: 100 }}
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Enter the designated driver’s name"
                value={renderValue(vehicle.driverName)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Contact Number :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="contact"
                type="number"
                variant="outlined" 
                onInput={(e)=>{ 
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,15)
                }}
                placeholder="eg. 88775566"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(vehicle.contact)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Email Address :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="email"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                placeholder="Please enter driver's email address"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(vehicle.email)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Proposed Vehicle ID:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="vehicleRefNo"
                variant="outlined" 
                onChange={(e) => handleOnChange_text(e)}
                value={vehicle.vehicleRefNo || ""}
                disabled
              />
            </Grid>
          </Grid>
          <div className={classes.action}>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Card>
    );
  }
}
