import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Modal from "shared-components/Modal/Modal";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Switch from "shared-components/Switch/Switch";
import CustomIconButton from "shared-components/Button/IconButton";

import { useRouteCanWrite } from "hooks";
import { minDate, rowsPerTable } from "config";
import { filterActionTableHead, renderValue } from 'common/functions';
import { sensorSettingsTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllSensorControlMapping, GetAllSensorSetting, DeleteSensorSetting } from "services/UserPortal/ClimateService";

import settings from "assets/icons/orange/settings.svg";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const ruleTotalCount = useSelector(store => store.user.climate.sensorSettings.totalCount);
  const sensorSettings = useSelector(store => store.user.climate.sensorSettings.sensorSettings);
  const configTotalCount = useSelector(store => store.user.climate.sensorControl.totalCount);
  const config = useSelector(store => store.user.climate.sensorControl.config);
  const [controlId, setControlId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const count = Math.ceil(ruleTotalCount / rowsPerTable);

  const handleButtonClick_switch = () => {
    props.setSelectedDevice({...props.selectedDevice, isFollowReceipt : props.selectedDevice && !props.selectedDevice.isFollowReceipt});
  };

  const handleOnChange_select = (e) => {
    if (e.target.name === "isFollowNormalLight") {
      props.setSelectedDevice({...props.selectedDevice, [e.target.name] : Boolean(e.target.value)});
    } else {
      props.setSelectedDevice({...props.selectedDevice, [e.target.name] : e.target.value});
    }
  };

  const handleModal_delete = (id) => {
    setControlId(id)
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteSensorSetting(controlId))
    .then((response) => {
      if (!response.error) {
        setPage(0);
        setOpenDeleteModal(!openDeleteModal);
        dispatch(GetAllSensorSetting({
          deviceId: props.selectedDevice,
          page: 0, deviceFunctionId: props.selectedFunction
        }));
      }
    });
  }

  const showDetails = () => {
    const info = props.selectedDevice && props.selectedDevice.currentWorkOrderInfo;
    if (info && info.errorMessage) {
      return info.errorMessage;
    } else if (info) {
      return (
        <React.Fragment>
          {info.workOrderNo}&nbsp;
          ({info.currageStage && info.currageStage.currentDay})<br/>
          {info.productName}&nbsp;
          ({info.productRefNo})&nbsp;
          ({info.lotId})&nbsp;
          <br/>
          {info.currageStage && "Lighting Control: " + info.currageStage.lightingControl}<br/>
          {info.currageStage && "Temperature: " + info.currageStage.temperature}<br/>
          {info.currageStage && "Light Duration: " + info.currageStage.lightDuration}
        </React.Fragment>
      )
    }
  }

  React.useEffect(() => {
    if (props.selectedDevice) {
      dispatch(GetAllSensorSetting({
        deviceId: props.selectedDevice.deviceId,
        deviceFunctionId: props.selectedFunction,
        page: 0, stopLoading: true
      }));
      dispatch(GetAllSensorControlMapping({
        deviceId: props.selectedDevice.deviceId,
        page: 0, stopLoading: true
      }));
    }
  },[props.selectedDevice]);

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalGrid}>
        <Grid item xs={3} className={classes.modalGridItem}>
          <Typography className={classes.label}>Device ID:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.modalGridItem}>
          {props.selectedDevice && props.selectedDevice.deviceRefNo}
        </Grid>
        <Grid item xs={3} className={classes.modalGridItem}>
          <Typography className={classes.label}>Pairing with:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.modalGridItem}>
          {configTotalCount 
          ? _.map(config, ({sensorDevice}) => {
              return sensorDevice && sensorDevice.deviceRefNo;
            })
          : "No Pairing"
          }
          <Link to={{pathname: "/user/climate/sensor-control", state: {pairingConfiguration: true, deviceId: props.selectedDevice && props.selectedDevice.deviceId}}}>
            <IconButton 
              className={clsx(classes.iconButton, classes.rightField)}
            >
              <img className={classes.icon} src={settings} alt="settings" />
            </IconButton>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.label}>Current Stage:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.modalGridItem}>
          {showDetails()}
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.label}>Watt:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.modalGridItem}>
          {props.selectedDevice && props.selectedDevice.wattage}W
        </Grid>
        <Grid item xs={3} className={classes.modalGridItem}>
          <Typography className={classes.label}>Follow Recipe:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.modalGridItem}>
          <Switch 
            checked={props.selectedDevice && props.selectedDevice.isFollowReceipt}
            onChange={() => handleButtonClick_switch()}
          />
        </Grid>
        <Grid item xs={3} className={classes.modalGridItem}>
          <Typography className={classes.label}>UV Light:</Typography>
        </Grid>
        <Grid item xs={5} className={classes.modalGridItem}>
          <Select
            name="isFollowNormalLight"
            onChange={(e)=>handleOnChange_select(e)}
            value={renderValue(props.selectedDevice && String(props.selectedDevice.isFollowNormalLight))}
            placeholder="Select action"
          >
            <MenuItem value={"true"}>UV light turn on with normal light</MenuItem>
            <MenuItem value={"false"}>UV lights turn on after normal light is turned off</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4} className={classes.modalGridItem} />
        <Grid item xs={3} className={classes.modalGridItem}>
          <Typography className={classes.label}>Rules Applied:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.modalGridItem}>
          <Link to={{pathname: "/user/climate/sensor-settings/create", state: {deviceId: props.selectedDevice && props.selectedDevice.deviceId, deviceFunctionId: props.selectedFunction, tab: 1}}}>
            <Button
              className={classes.buttonSecondary}
              startIcon={<AddIcon />}
            >
              Add New Rule
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.modalGridItem}>
          <Table
            className={classes.table}
            header={filterActionTableHead(sensorSettingsTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { sensorSettings.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{('0'+((page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                  <TableCell>{item.ruleName}</TableCell>
                  <TableCell>{item.deviceFunctionName}</TableCell>
                  <TableCell>{item.deviceRefNo}</TableCell>
                  <TableCell>
                    <Checkbox 
                      checked={Boolean(item.isActive)}
                      disabled
                    />
                  </TableCell>
                  <TableCell>{moment(item.creationTime).isAfter(minDate) && moment(item.creationTime).format("DD/MM/YYYY")}</TableCell>
                  <TableCell align="right">
                    {canWrite &&
                      <React.Fragment>
                        <Link to={{pathname: "/user/climate/sensor-settings/"+item.id}}>
                          <CustomIconButton type="edit" />
                        </Link>
                        <CustomIconButton 
                          type="delete"
                          onClick={() => handleModal_delete(item.id)}
                        />
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Grid>
      </Grid>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this sensor setting? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
