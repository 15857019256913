import React from "react";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";

import { bankFormat } from "common/functions";
import close from "assets/icons/white/close.svg";
import styles from "assets/jss/shared-components/bankStyle.js";

const useStyles = makeStyles(styles);
export default function CapacityBank(props) {
  const classes = useStyles();

  const noOfBays = () => {
    let element = [];
    for (let i = 1; i <= parseInt(props.bays); i++) {
      const cageDetails = props.data.find((item) => {
        if (item.cageRefNo && item.cageRefNo.endsWith(bankFormat(i) + "-" + bankFormat(props.row))) {
          return item;
        }
      })
      element.push(
        renderCell({level: props.row, bay: i}, cageDetails)
      );
    }
    return element;
  }

  const renderCell = (value, cageDetails) => {
    // const isSelected = props.selected.find((item) => {
    //   if (item) {
    //     const location = item.split("-");
    //     if (location[1] === format(value.bay) && location[2] === format(value.level)) {
    //       return item;
    //     }
    //   }
    // })
    return (
      <TableCell
        key={value.bay} 
        className={ clsx(classes.cell2, classes.capacityCell,
          cageDetails && cageDetails.reservedQty > 0 ? cageDetails.reservedQty === cageDetails.totalQty ? classes.fully : classes.partially : classes.unassigned,
        )} 
      >
        {cageDetails && cageDetails.reservedQty > 0
        ? <React.Fragment>
            <div className={clsx(classes.details, classes.black)}>
              {cageDetails.reservedQty}/{cageDetails.totalQty} trays occupied
            </div>
            <div className={classes.hoverEvent}>
              <Button 
                className={classes.viewButton}
                onClick={()=>props.onCellClick(cageDetails)}
              >
                View More
              </Button>
            </div>
          </React.Fragment>
        : <React.Fragment>
            <img className={classes.notAvailableIcon} src={close} alt="not-available" />
            <div className={classes.hoverEvent}>
              <Button 
                className={classes.viewButton}
                onClick={()=>props.onCellClick(cageDetails)}
              >
                Assign
              </Button>
            </div>
          </React.Fragment>
        }
      </TableCell>
    )
  }

  return (noOfBays());
}