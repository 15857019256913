import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";
import Select from "shared-components/Select/Select";

import { rowsPerTable } from "config";
import { setDirty } from "store/general";
import { checkPermission, renderValue, formatNumbers, roundTo2Decimal } from "common/functions";
import { isValidPrice } from "common/validations";
import { cpSettingsTableHead } from "enums/UserPortal/TableHeaders";
import { updateRequest, setValues } from "../store/settings";
import { GetAllCostCategory, GetAllCostSetting, GetCostSettingById, CreateOrUpdateCostSetting, DeleteCostSetting } from "services/UserPortal/CostProductivityService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/costProductivityStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function CostType(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isDirty = useSelector(store => store.general.isDirty);
  const costCategories = useSelector(store => store.user.costProductivity.settings.costCategories);
  const totalCount = useSelector(store => store.user.costProductivity.settings.totalCount);
  const results = useSelector(store => store.user.costProductivity.settings.settings);
  const setting = useSelector(store => store.user.costProductivity.settings.setting);
  const uomList = useSelector(store => store.user.costProductivity.settings.uomList);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('description');
  const [page, setPage] = React.useState(0);
  const [tab, setTab] = React.useState(0);
  const [settingId, setSettingId] = React.useState(null);
  const [isAddEdit, setIsAddEdit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };
  
  const handleButtonClick_add = () => {
    dispatch(setDirty(true));
    const payload = [...results, {id: 0}];
    setIsAddEdit(true);
    setSettingId(0);
    dispatch(setValues({settings: payload, setting: {costCategoryId: costCategories[tab] && costCategories[tab].id}}));
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateCostSetting())
      .then(({error}) =>{
        if (!error) {
          getAllCostSetting(page);
          resetState();
        }
      })
    }
  }

  const handleButtonClick_cancel = () => {
    if (settingId === 0) {
      dispatch(setValues({settings: _.filter(results, (item) => item.id !== settingId)}));
      setPage(0);
    }
    resetState();
  }

  const handleButtonClick_edit = (id) => {
    dispatch(setDirty(true));
    setIsAddEdit(true);
    setSettingId(id);
    dispatch(GetCostSettingById({id}));
  }

  const handleModal_delete = (value) => {
    setSettingId(value);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteCostSetting(settingId))
    .then(() =>{
      getAllCostSetting(0);
      setPage(0);
      resetState();
    })
  }

  const validateFields = () => {
    if (!setting.description) {
      setErrorMsg({field: "description", msg: "Please enter a description"});
      return false;
    }
    if (!isValidPrice(setting.value)) {
      setErrorMsg({field: "value", msg: "Enter a valid value"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    setIsAddEdit(false);
    setSettingId(null);
    setErrorMsg(false);
    setOpenModal(false);
    dispatch(setDirty(false));
    dispatch(setValues({setting: {}}));
  }

  const getAllCostSetting = (pageNo) => {
    const costCategory = costCategories[tab];
    if (costCategory && costCategory.name !== "Labour Cost") {
      dispatch(GetAllCostSetting({costCategoryId : costCategory.id, page: pageNo}));
    }
  }

  React.useEffect(() => {
    getAllCostSetting(page)
  },[tab, page, costCategories]);

  React.useEffect(() => {
    if (props.costType) {
      dispatch(GetAllCostCategory(props.costType));
    }
  },[props.costType]);

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.settingsPaper}>
        <div className={classes.tabContainer}>
          <Tabs 
            classes={{
              indicator: classes.settingsIndicator
            }}
            orientation="vertical"
            value={tab} 
            onChange={(e, value) => setTab(value)}
          >
            {_.map(costCategories, (item, index) => {
              return (
                <Tab key={index} className={tab === index ? clsx(classes.settingsTabLabel, classes.selectedSettings) : classes.settingsTabLabel} label={item.name} disabled={isDirty} />
              )
            })}
          </Tabs>
        </div>
        <div className={classes.contentContainer}>
          { (costCategories[tab] && costCategories[tab].name === "Labour Cost")
          ? <React.Fragment>
              <Typography className={classes.settingLabel}>Labour Cost</Typography>
              <Typography className={classes.settingLabel}>Edit the costs on: </Typography>
              <Link to={{pathname: "/admin/human-resource/user-configuration"}} disabled={!checkPermission("Admin.Human Resource", "readEdit")}>
                <Button
                  className={classes.button}
                  disabled={!checkPermission("Admin.Human Resource", "readEdit")}
                >
                  Administration Portal - Human Resource Module
                </Button>
              </Link>
            </React.Fragment>
          : <React.Fragment>
              <Typography className={classes.settingLabel}>{costCategories[tab] && costCategories[tab].name}</Typography>
              <Table
                className={classes.settingTable}
                header={cpSettingsTableHead}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { results.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        { isAddEdit && settingId === item.id
                        ? <TextField
                            variant="outlined" 
                            id="description"
                            onChange={(e)=>handleOnChange_text(e)}
                            value={renderValue(setting.description)}
                            placeholder="Enter a description"
                            errorMsg={errorMsg}
                          />
                        : item.description
                        }  
                      </TableCell>
                      <TableCell>
                        { isAddEdit && settingId === item.id
                        ? <Select
                            name="uom"
                            value={renderValue(setting.uom)}
                            onChange={(e)=>handleOnChange_select(e)}
                            placeholder="Select unit of measurement"
                          >
                            {uomList.map((item, index) => {
                              return <MenuItem key={index} value={item.unitOfMeasurementShortForm}>{item.unitOfMeasurementShortForm}</MenuItem>;
                            })} 
                          </Select>
                        : item.uom
                        }  
                      </TableCell>
                      <TableCell>
                        { isAddEdit && settingId === item.id
                        ? <TextField
                            variant="outlined" 
                            id="value"
                            type="Number"
                            onInput={(e)=>{ 
                              // decimal
                              const reg = /^\d*(\.\d{0,2})?$/
                              if (!reg.test(e.target.value)) {
                                e.target.value = roundTo2Decimal(e.target.value);
                              }
                            }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            onChange={(e)=>handleOnChange_text(e)}
                            value={renderValue(setting.value)}
                            placeholder="Enter a value"
                            errorMsg={errorMsg}
                          />
                        : "$" + formatNumbers(roundTo2Decimal(item.value))
                        }  
                      </TableCell>
                      <TableCell align="right">
                        { canWrite && isAddEdit && settingId === item.id
                        ? <React.Fragment>
                            <IconButton 
                              type="confirm"
                              onClick={() => handleButtonClick_confirm()}
                            />
                            <IconButton 
                              type="close"
                              onClick={() => handleButtonClick_cancel()}
                            />
                          </React.Fragment>
                        : <React.Fragment>
                            <IconButton 
                              type="edit"
                              onClick={() => handleButtonClick_edit(item.id)}
                              disabled={Boolean(isAddEdit && settingId !== item.id)}
                            />
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal_delete(item.id)}
                              disabled={Boolean(isAddEdit && settingId !== item.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
              { count > 1 &&
                <Pagination 
                  count={count} 
                  page={page+1}
                  onChange={(e, pageNo)=>setPage(pageNo-1)}
                  disabled={Boolean(isAddEdit)}
                />
              }
              <Button
                className={classes.buttonSecondary}
                startIcon={<AddIcon />}
                onClick={handleButtonClick_add}
                disabled={isAddEdit}
              >
                Add New
              </Button>
            </React.Fragment>
          }
        </div>
      </Paper>
      <Modal
        open={openModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this vehicle? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
