// Create Edit Device Tagging
// Step 2: Upload Floor Plan
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// core components
import Card from "shared-components/Card/Card";
import Radio from 'shared-components/Radio/Radio';
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from "shared-components/Modal/Modal";

import DeviceTaggingStepper from "./DeviceTaggingStepper";
import { setDirty } from "store/general";
import { setCompleted, resetSkipStep } from "./store/deviceTagging";
import { setFloorPlanValues, updateFloorPlanRequest, updateImg, resetFloorPlan } from "./store/floorPlan";
import { GetAllFloorPlan, UpdateFloorPlanDiagram } from "services/AdminPortal/DeviceTaggingService";
import { UploadFloorPlanDiagram } from "services/AdminPortal/UploadService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/deviceTaggingStyle.js";

const useStyles = makeStyles(styles);
export default function FloorPlanForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const isCompleted = useSelector(store => store.admin.deviceTagging.deviceTagging.isCompleted);
  const skipStep = useSelector(store => store.admin.deviceTagging.deviceTagging.skipStep);
  const floors = useSelector(store => store.admin.deviceTagging.floorPlan.floors);
  const floorPlan = useSelector(store => store.admin.deviceTagging.floorPlan.floorPlan);
  const [selected, setSelected] = React.useState({floor: floors[0].id, sector: null});
  const [changeSelection, setChangeSelection] = React.useState(null);
  const [imageFile, setImageFile] = React.useState(null);
  const [isChanged, setIsChanged] = React.useState(false);
  const [alertAction, setAlertAction] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [openStatusModal, setOpenStatusModal] = React.useState(false);
  const [openSummaryModal, setOpenSummaryModal] = React.useState(false);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    disabled: _.isEmpty(floorPlan),
    onDrop: files => {
      setIsChanged(true);
      setImageFile(files[0]);
      dispatch(updateFloorPlanRequest({noFloorPlanRequire: false}));
      dispatch(updateImg(URL.createObjectURL(files[0])));
    }
  });

  const onButtonClick_image = () => {
    inputFile.current.click();
  }
  
  const handleOnChange_image = (e) => {
    setIsChanged(true);
    setImageFile(e.target.files[0]);
    dispatch(updateFloorPlanRequest({noFloorPlanRequire: false}));
    dispatch(updateImg(URL.createObjectURL(e.target.files[0])));
  }

  const handleOnChange_radio = (target) => {
    if (target.id === "floor") {
      setSelected({...selected, floor: parseInt(target.value)});
      dispatch(setFloorPlanValues({}));
    } else {
      setSelected({...selected, sector: parseInt(target.value)});
      const sector = floor.sectors.find((item) => item.id === parseInt(target.value));
      dispatch(setFloorPlanValues(sector));
    }
  }

  const handleButtonClick_checkbox = (e) => {
    setIsChanged(true);
    dispatch(updateFloorPlanRequest({[e.target.id]: !floorPlan.noFloorPlanRequire}));
    if (!floorPlan.noFloorPlanRequire) {
      dispatch(updateImg(null));
      setImageFile(null);
    }
  };

  const handleButtonClick_skip = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      history.push("/admin/device-tagging");
    })
  }

  const handleButtonClick_exit = () => {
    setChangeSelection(null);
    setOpenAlertModal(!openAlertModal);
  }
  
  const handleButtonClick_alert = (action, value) => {
    if (action === "select") {
      resetState();
      handleOnChange_radio(value);
    } else if (action === "back") {
      resetState();
      props.setStep(0);
    } else if (action === "next") {
      handleModal_summary();
    } else if (action === "complete") {
      handleButtonClick_skip();
    }
  }

  const handleModal_alert = (action, e) => {
    if (isChanged) {
      setOpenAlertModal(!openAlertModal);
      setAlertAction(action);
      if (action === "select") {
        setChangeSelection({id: e.target.id, value: e.target.value});
      }
    } else {
      if (e) {
        handleButtonClick_alert(action, {id: e.target.id, value: e.target.value});
      } else {
        handleButtonClick_alert(action);
      }
    }
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleModal_status = () => {
    setOpenStatusModal(!openStatusModal);
  }

  const handleModal_summary = () => {
    setOpenSummaryModal(!openSummaryModal);
  }

  const handleButtonClick_save = () => {
    setIsChanged(false);
    if (imageFile) {
      dispatch(UploadFloorPlanDiagram(imageFile))
      .then(() => {
        dispatch(UpdateFloorPlanDiagram())
        .then(() => {
          dispatch(GetAllFloorPlan());
        })
      })
    } else {
      dispatch(UpdateFloorPlanDiagram())
      .then(() => {
        dispatch(GetAllFloorPlan());
      })
    }
  }

  const handleButtonClick_next = () => {
    props.setStep(2);
  }

  const resetState = () => {
    setOpenAlertModal(false);
    setIsChanged(false);
  }
  
  React.useEffect(() => {
    if (!_.isEmpty(floor) && selected.sector) {
      const sector = floor.sectors.find((item) => item.id === selected.sector);
      dispatch(setFloorPlanValues(sector));
    }
  },[floors]);

  React.useEffect(() => {
    dispatch(setDirty(isChanged));
  },[isChanged]);

  // componentDidMount
  React.useEffect(() => {
    if (!_.isEmpty(skipStep) && skipStep.step === 1) {
      dispatch(GetAllFloorPlan());
      setSelected({floor: skipStep.floorId, sector: skipStep.sectorId});
      dispatch(resetSkipStep());
    }
    // componentDidUnmount
    return () => {
      dispatch(resetFloorPlan());
      dispatch(setDirty(false));
    }
  },[]);

  let updatedCount = 0; 
  let totalNoOfSectors = 0;
  floors.forEach((item) => {
    if (item.sectors) {
      totalNoOfSectors += item.sectors.length;
      updatedCount += _.filter(item.sectors, "noFloorPlanRequire").length;
      updatedCount += _.filter(item.sectors, "floorPlanDiagramUrl").length;
    }
  })
  const floor = floors.find((item) => item.id === selected.floor);
  if (totalNoOfSectors === updatedCount) {
    dispatch(setCompleted({step2: true}));
  } else {
    dispatch(setCompleted({step2: false}));
  }
  return (
    <React.Fragment>
      <Card 
        title="Step 2: Upload floor plans for each floor"
        subheader={
          <React.Fragment>
            Please select the Level and Sector and click “Upload Floor Plan” to upload floor plan
            <DeviceTaggingStepper activeStep={props.step} />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleModal_alert("back")}
            >
              Go Back
            </Button>
            <div>
              <Button
                className={clsx(classes.buttonSecondary, classes.buttonMargin)}
                onClick={()=>isChanged ? handleModal_alert("complete") : handleModal_warning()}
              >
                Save Draft
              </Button>
              <Button
                className={clsx(classes.buttonSecondary, classes.buttonMargin)}
                onClick={()=>isChanged ? handleModal_alert("next") : handleModal_summary()}
                disabled={isCompleted.step2}
              >
                Skip this Step
              </Button>
              <Button
                className={classes.button}
                onClick={()=>isChanged ? handleModal_alert("next") : handleModal_summary()}
                disabled={!isCompleted.step2}
              >
                Next Step
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div className={classes.content}>
          <Card
            classes={{
              root: clsx(classes.cardPaper, classes.floorCardLeft),
              title: classes.cardTitle
            }}
            title= "Floors and Sectors"
            action={
              <React.Fragment>
                <input 
                  ref={inputFile} 
                  style={{"display": "none"}} 
                  type="file" 
                  accept="image/*"
                  onChange={(e) => handleOnChange_image(e)}
                />
                <Link 
                  className={_.isEmpty(floorPlan) ? classes.disabled : classes.clickable} 
                  onClick={()=> !_.isEmpty(floorPlan) && onButtonClick_image()}
                >
                  Upload Floor Plan
                  <ArrowForwardIcon />
                </Link>
              </React.Fragment>
            }
          >
            <Link className={classes.clickable} onClick={()=>handleModal_status()}>View Floor Plan Status</Link>
            <Typography className={clsx(classes.formLabel, classes.bold)}>Levels:</Typography>
            <Grid container spacing={4}>
              { floors.map((item, index)=>{
                return (
                  <Grid item className={classes.buttonGrid} xs={6} sm={4} key={index}>
                    <Radio 
                      id="floor"
                      className={classes.radio}
                      value={item.id}
                      checked={item.id === selected.floor}
                      onChange={(e)=>handleModal_alert("select", e)}
                    />
                    <Typography className={classes.buttonLabel}>Floor {item.floorNumber}</Typography>
                  </Grid>
                )
              })}
            </Grid>
            <Typography className={clsx(classes.formLabel, classes.bold)}>Sectors:</Typography>
            <Grid container spacing={4}>
              { floor && floor.sectors && floor.sectors.map((item,index)=>{
                return (
                  <Grid item className={classes.buttonGrid} xs={6} sm={4} key={index}>
                    <Radio 
                      id="sector"
                      className={classes.radio}
                      value={item.id}
                      checked={item.id === selected.sector}
                      onChange={(e)=>handleModal_alert("select", e)}
                    />
                    <Typography className={classes.buttonLabel}>Sector {item.sectorNumber}</Typography>
                  </Grid>
                )
              })}
            </Grid>
            <Typography className={clsx(classes.formLabel, classes.bold)}>Floorplan:</Typography>
            <div className={classes.floorCardAction}>
              <div className={classes.buttonGrid}>
                <Checkbox 
                  id="noFloorPlanRequire"
                  className={classes.radio}
                  checked={floorPlan && floorPlan.noFloorPlanRequire ? true : false}
                  onChange={(e) => handleButtonClick_checkbox(e)}
                />
                <Typography className={classes.buttonLabel}>No Floorplan Required</Typography>
              </div>
              <Button
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
                disabled={!isChanged}
              >
                Save
              </Button>
            </div>
          </Card>
          <Card
            classes={{
              root: clsx(classes.cardPaper, classes.cardRight),
              title: classes.cardTitle,
              content: classes.cardRightContent
            }}
            title= "Floor Plan Diagram"
          >
            <div {...getRootProps({className: isDragActive ? clsx(classes.dropZone, classes.activeDropZone) : classes.dropZone})}>
              <input {...getInputProps()} />
              { floorPlan && floorPlan.floorPlanDiagramUrl 
              ? <img className={classes.floorPlanImg} src={floorPlan.floorPlanDiagramUrl} alt="company-pic" />
              : <React.Fragment>
                  <Typography className={classes.emptyLabel}>No Diagram Uploaded</Typography>
                  <Typography className={classes.emptyLabel}>Drag & drop an image file here, or click to upload an image file</Typography>
                </React.Fragment>
              }
            </div>
          </Card>
        </div>
      </Card>
      <Modal
        open={openAlertModal}
        onClose={() => handleModal_alert()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="You have not saved your current changes. Changing your selection will overwrite your current changes."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleButtonClick_exit()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_alert(alertAction, changeSelection)}>OK</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you want to save the details first? You will be directed to the device tagging page."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_warning()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_skip()}>Confirm</Button>
          </React.Fragment>
        }
      />
      <Modal
        className={classes.statusModal}
        classes={{
          content: classes.statusContent
        }}
        open={openStatusModal}
        onClose={() => handleModal_status()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Floor Plan Status Summary"
        content={
          <React.Fragment>
            { floors.map((floorObj) => {
              return floorObj.sectors && floorObj.sectors.map((item, index) => {
                return (
                  <div key={index} className={classes.summaryContent}>
                    <Typography className={classes.summaryLabel}>Floor {floorObj.floorNumber} - Sector {item.sectorNumber}:</Typography>
                    <Typography className={classes.summaryText}>
                      { item.noFloorPlanRequire 
                      ? "No Floor Plan Required"
                      : item.floorPlanDiagramUrl
                        ? "Floor Plan Uploaded"
                        : "Not Indicated"
                      }
                    </Typography>
                  </div>
                )
              })
            })}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.button} onClick={() => handleModal_status()}>OK</Button>
          </React.Fragment>
        }
      />
      <Modal
        className={classes.summaryModal}
        open={openSummaryModal}
        onClose={() => handleModal_summary()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Floor Plan Status Summary"
        content={
          <React.Fragment>
            <div className={classes.summaryContent}>
              <Typography className={classes.summaryLabel}>Floor plan(s) updated:</Typography>
              <Typography className={classes.summaryText}>{updatedCount} Floors &amp; Sectors</Typography>
            </div>
            <div className={classes.summaryContent}>
              <Typography className={classes.summaryLabel}>Floor plan(s) not updated:</Typography>
              <Typography className={classes.summaryText}>{totalNoOfSectors-updatedCount} Floors &amp; Sectors</Typography>
            </div>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_summary()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_next()}>Next Step</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
