import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import Tag from 'shared-components/Chip/Tag';
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import FilterButton from "shared-components/Button/FilterButton";

import { useRouteCanWrite } from "hooks";
import { setSearchText } from "store/general";
import { renderValue, filterParam } from "common/functions";
import { setValues } from "../store/adjustment";
import { GetWorkOrdersLookupForAdjustment, GetSubSaleOrdersByWorkOrderIdForAdjustment, GetSimilarSubSaleOrdersForAdjustment, CreatePlanningAdjustment } from "services/UserPortal/PlanningService";

import AdjustmentTable from "./AdjustmentTable";
import alert from "assets/icons/orange/alert-line.svg";
import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function Adjustment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.planning.adjustment.filter);
  const workOrderList = useSelector(store => store.user.planning.adjustment.workOrderList);
  const adjust = useSelector(store => store.user.planning.adjustment.adjust);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, deliveryStartDate: null, deliveryEndDate: null});
  const [openModal, setOpenModal] = React.useState(null);

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, deliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), deliveryEndDate: moment(value.endDate).format("YYYY-MM-DD")}}));
    }
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };

  const handleButtonClick_save = () => {
    dispatch(CreatePlanningAdjustment())
    .then(({error})=>{
      if (!error) {
        handleModal("success");
      } else {
        handleModal("error");
      }
    });
  };

  const handleModal = (res) => {
    setOpenModal(res);
    if (!res) {
      dispatch(setValues({adjust: {workOrderId: filter.workOrderId}}));
    }
  };
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderId: workOrderList[0].id}}));
      } else {
        dispatch(setValues({filter: {workOrderId: null}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    if (adjust.oldOrder) {
      dispatch(GetSimilarSubSaleOrdersForAdjustment({...filter, product_Id: adjust.oldOrder.product_Id, page: 0}));
    } else {
      dispatch(setValues({similarCount: 0, similar: []}));
    }
  },[adjust]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil))) && filter.workOrderId) {
      dispatch(setValues({adjust: {workOrderId: filter.workOrderId}}));
      dispatch(GetSubSaleOrdersByWorkOrderIdForAdjustment({...filter, page: 0}));
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetWorkOrdersLookupForAdjustment());
    dispatch(setValues({filter: {...filterParam(newFilter)}}));
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Adjustment for Work Orders"
        subheader={
          <React.Fragment>
            <Typography>Please take note that existing orders can only be exchanged only if the same order has the same delivery date, same product and has a capacity used which is the same or lesser than the existing orders.</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && !key.includes("Date") && key !== "url") {
                let label = filter[key];
                if (key === "workOrderId") {
                  const workOrder = workOrderList.find(({id}) => id == filter[key]);
                  label = workOrder && workOrder.workOrderNumber;
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the planning outcomes
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNumber}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Delivery date"
              className={classes.cardActionDropdown}
              value={{startDate: filter.deliveryStartDate, endDate: filter.deliveryEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, deliveryStartDate: null, deliveryEndDate: null}}))}
            />
          </React.Fragment>
        }
      >
        <div className={classes.adjustContent}>
          <AdjustmentTable filter={filter} current={true} />
          <AdjustmentTable filter={filter} current={false} />
          {canWrite &&
            <div className={classes.adjustButton}>
              <Button 
                className={classes.button}
                disabled={!(adjust.oldSubSaleOrderNumber && adjust.newSubSaleOrderNumber)}
                onClick={()=>handleButtonClick_save()}
              >
                Save
              </Button>
            </div>
          }
        </div>
      </Card>
      <Modal
        open={Boolean(openModal)}
        onClose={() => handleModal(null)}
        icon={openModal === "success" ? <img className={classes.icon_64} src={confirm} alt="confirm" /> : <img className={classes.icon_64} src={alert} alt="alert" />}
        title={openModal === "success" ? "Success" : "Error"}
        content={openModal === "success" 
        ? adjust.oldSubSaleOrderNumber + " has been swapped successfully with " + adjust.newSubSaleOrderNumber
        : "Error in swapping " + adjust.oldSubSaleOrderNumber + " & " + adjust.newSubSaleOrderNumber}
        actions={
          <React.Fragment>
            <Button className={classes.button} onClick={() => handleModal(null)}>Okay</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
