import React from "react";
import moment from "moment"
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// core components
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import styles from "assets/jss/shared-components/tabStyle.js";

const useStyles = makeStyles(styles);

export default function DateTabs(props) {
  const classes = useStyles();
  const [tab, setTab] = React.useState(false);

  const handleOnChange_tab = (e, value) => {
    setTab(value);
    // if (value === 0) {  // day
    //   props.setDate({startDate: moment().startOf('day').format(), endDate: moment().endOf('day').format()});
    // } else if (value === 1) {  // week
    //   props.setDate({startDate: moment().subtract(7,'d').startOf('day').format(), endDate: moment().endOf('day').format()});
    // } else if (value === 2) {  // month
    //   props.setDate({startDate: moment().subtract(1, 'months').startOf('day').format(), endDate: moment().endOf('day').format()});
    // }
    props.onChange && props.onChange();
  };

  const handleOnChange_date = (value) => {
    if (value) {
      props.setDate({startDate: moment(value.startDate).startOf('day').format(), endDate: moment(value.endDate).endOf('day').format()});
      props.onChange && props.onChange();
      setTab(false);
    }
  };

  // React.useEffect(() => {
  //   if (moment(props.date.endDate).isSame(moment(props.date.startDate), 'day') && moment(props.date.endDate).diff(moment(props.date.startDate), 'hours') > 12) {
  //     setTab(0);
  //   } else if (moment(props.date.endDate).diff(moment(props.date.startDate), 'days') === 7) {
  //     setTab(1);
  //   } else if (moment(props.date.endDate).diff(moment(props.date.startDate), 'month') === 1) {
  //     setTab(2);
  //   } else {
  //     setTab(false);
  //   }
  // },[props.date]);

  React.useEffect(() => {
    if (props.hourTab != undefined && props.hourTab !== false) {
      setTab(false);
    }
  },[props.hourTab]);

  React.useEffect(() => {
    if (tab === 0) {  // day
      props.setDate({startDate: moment().startOf('day').format(), endDate: moment().endOf('day').format()});
    } else if (tab === 1) {  // week
      props.setDate({startDate: moment().startOf('day').subtract(7,'d').format(), endDate: moment().endOf('day').format()});
    } else if (tab === 2) {  // month
      props.setDate({startDate: moment().startOf('day').subtract(1, 'months').format(), endDate: moment().endOf('day').format()});
    }
  },[tab]);

  React.useEffect(() => {
    if (props.hourTab === undefined && props.hourTab === false) {
      setTab(1);
    }
  },[]);

  return (
    <React.Fragment>
      <Tabs 
        className={classes.dateTab}
        classes={{
          indicator: classes.dateIndicator
        }}
        value={tab} 
        onChange={handleOnChange_tab}
      >
        
        <Tab className={tab === 0 ? clsx(classes.dateLabel, classes.selectedTab) : classes.dateLabel} label="Day" />
        <Tab className={tab === 1 ? clsx(classes.dateLabel, classes.selectedTab) : classes.dateLabel} label="Week" />
        <Tab className={tab === 2 ? clsx(classes.dateLabel, classes.selectedTab) : classes.dateLabel} label="Month" />
        
      </Tabs>
      <DateRangePicker
        // placeholder="Usage date"
        className={classes.datepicker}
        value={{startDate: props.date.startDate, endDate: props.date.endDate}}
        onChange={(e) => handleOnChange_date(e)}
      />
    </React.Fragment>
  );
}

DateTabs.propTypes = {
  onChange: PropTypes.func,
};