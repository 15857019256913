import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllLogSheet, GetProductProcessName, GetTaskNumberList, GetDurationDetailByLogSheetId, GetLotIdByWorkOrderIdAndProductId } from "services/UserPortal/HumanResourceService";

// initial state
const initialState = {
  totalTime: 0,
  totalCount: 0,
  totalWorkingHours: 0,
  isLogged: false,
  logSheet: [],
  newEntry: {},
  log: {},
  lotId: null,
  processList: [],
  taskList: [],
  duration: []
};

const logSheet = createSlice({
  name: "logSheet",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.newEntry[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllLogSheet.fulfilled]: (state, action) => {
      state.totalTime = action.payload.result.totalTimeSpent;
      state.totalCount = action.payload.result.totalCount;
      state.totalWorkingHours = action.payload.result.totalWorkingHours;
      state.isLogged = action.payload.result.isLogged;
      state.logSheet = action.payload.result.items;
      state.log = {workingDayType: "FullWorkingDay", logSheetIdList: action.payload.result.items.map(({id}) => id)};
    },
    [GetProductProcessName.fulfilled]: (state, action) => {
      state.processList = action.payload.result;
    },
    [GetTaskNumberList.fulfilled]: (state, action) => {
      state.taskList = _.sortBy(action.payload.result, ["taskNumber"]);
    },
    [GetDurationDetailByLogSheetId.fulfilled]: (state, action) => {
      state.duration = action.payload.result;
    },
    [GetLotIdByWorkOrderIdAndProductId.fulfilled]: (state, action) => {
      state.lotId = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = logSheet.actions;

// export the reducer
export default logSheet.reducer;