import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const vehicleStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  
  orange,

  // Display vehicle
  table,
  icon,

  // Search Vehicle
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Add Edit Vehicle
  paper: {
    ...cardRadius,
    border,
    display: "flex",
    flexDirection: "column",
    margin: "1.563vw 15.104vw 5.208vw",
    padding: "3.646vw 7.292vw 0"
  },
  label: {
    marginTop: "0.833vw",
  },
  iconButton: {
    marginLeft: "-0.625vw"
  },
  action: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "2.708vw 0",
    padding: 0
  },
  addButton: {
    marginTop: "1.771vw",
  },

  // Vehicle Type
  buttonMargin: {
    marginRight: "1.042vw"
  },
}

export default vehicleStyle;