import React from "react";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
// core components
import Modal from 'shared-components/Modal/Modal';
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import filter from "assets/icons/orange/filter.svg";
import styles from "assets/jss/components/UserPortal/costProductivityStyle.js";

const useStyles = makeStyles(styles);

export default function DateFilter(props) {
  const classes = useStyles();
  const [dateFormatTab, setDateFormatTab] = React.useState(1);
  const [newDate, setNewDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});
  const [openModal, setOpenModal] = React.useState(false);

  const handleOnChange_dateFormatTab = (e, value) => {
    setDateFormatTab(value);
    if (value === 0) {  // day
      setNewDate({startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});
    } else if (value === 1) {  // week
      setNewDate({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});
    } else if (value === 2) {  // month
      setNewDate({startDate: moment().subtract(1, 'months').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});
    }
  };

  const handleOnChange_date = (value) => {
    setDateFormatTab(false);
    if (value) {
      setNewDate({...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")});
    }
  };

  const handleModal = () => {
    setOpenModal(!openModal);
    setNewDate(props.date);
  }

  const handleButtonClick_apply = () => {
    setOpenModal(!openModal);
    props.setDate(newDate);
  }

  return (
    <React.Fragment>
      <Button 
        className={clsx(classes.buttonSecondary, classes.rightCardAction)}
        onClick={()=>handleModal()}
        endIcon={<img className={classes.icon} src={filter} alt="filter" />}
      >
        Filter
      </Button>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        title="Timeline Filter"
        content={
          <React.Fragment>
            Choose one of the filter for the graph
            <Tabs 
              className={classes.modalDateTab}
              classes={{
                indicator: classes.dateIndicator
              }}
              value={dateFormatTab} 
              onChange={handleOnChange_dateFormatTab}
            >
              <Tab className={dateFormatTab === 0 ? clsx(classes.modalDateLabel, classes.selectedTab) : classes.modalDateLabel} label="Day" />
              <Tab className={dateFormatTab === 1 ? clsx(classes.modalDateLabel, classes.selectedTab) : classes.modalDateLabel} label="Week" />
              <Tab className={dateFormatTab === 2 ? clsx(classes.modalDateLabel, classes.selectedTab) : classes.modalDateLabel} label="Month" />
            </Tabs>
            OR
            <DateRangePicker
              // placeholder="Usage date"
              placement="right"
              className={classes.modalDatepicker}
              value={{startDate: newDate.startDate, endDate: newDate.endDate}}
              onChange={(e) => handleOnChange_date(e)}
            />
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal()}>Clear Filter</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_apply()}>Apply filter</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
