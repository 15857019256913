import {cardRadius} from "assets/jss/generaliseStyle";

const formStyle = {
  wrapper: {
    height: "100vh",
    backgroundColor: "#F0F9F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    ...cardRadius,
    width: "33.333vw",
    padding: "4.167vw",
  },
  content: {
    padding: 0
  },
  logo: {
    height: "3.281vw",
    margin: "auto",
    marginBottom: "3.646vw"
  },
};
  
export default formStyle;
    