import {
    cardRadius,
    border,
    icon_64,
    button,
    buttonSecondary,
    primaryColor,
    secondaryColor,
  } from "assets/jss/generaliseStyle.js";

  import {
    icon,
    cardPaper,
    result,
    highlight,
		collapsedIcon,
    rotate,
    orange,
    link,
  } from "assets/jss/components/commonStyle.js";
  
  const productStyle = {
    cardRadius,
    button,
    buttonSecondary,
    icon_64,

    icon,
    cardPaper,

    // Search Product
    result,
    highlight,
		collapsedIcon,
    rotate,
    orange,
    link,

    // link: {
    //   marginTop: "1.563vw",
    //   color: secondaryColor[0],
    //   cursor: "pointer"
    // },

    catTypeContent: {
      padding: "1.979vw",
      borderRadius: "0.833vw",
    },

    error: {
      color: "#FF0000",
      fontSize: "0.729vw",
      textAlign: "center"
    },

    lossRateError: {
      color: "#FF0000",
      fontSize: "0.729vw",
      textAlign: "left"
    },

    iconButton: {
      height: "fit-content",
      alignSelf: "center",
      marginRight: "-0.625vw",
      marginLeft: "1.771vw"
    },

    table: {
      margin: "0 -1.979vw"
    },

    buttonSpacing: {
      marginLeft: "1.042vw"
    },

    alignTextfield: {
      marginLeft: "-0.200vw"
    },

    editCell: {
      verticalAlign: "top"
    },

    // Stepper
    stepper: {
      padding: "1.198vw 0 0",
      marginLeft: "-8px",
    },

    step: {
      color: secondaryColor[0] + "!important",
    },

    //Product Creation
    topCard: {
      marginBottom: "2.604vw"
    },

    content: {
      display: "flex",
      width: "100%",
    },

    buttonMargin: {
      marginRight: "1.042vw"
    },

    columnSpacing: {
      //marginLeft: "9.375vw",
      marginRight: "2.083vw",
    },

    topLeftPaper: {
      ...cardRadius,
      border,
      padding: "2.083vw 1.979vw",
      '&:not(:first-child)': {
        marginTop: "1.563vw",
      },
      width: "38.125vw",
    },
    
    topContent: {
      margin: "auto",
      display: "flex",
    },

    bottomContent: {
      ...cardRadius,
      border,
      padding: "2.083vw 1.979vw",
      '&:not(:first-child)': {
        marginTop: "1.563vw",
      },
      width: "58.79vw",
      margin: "auto",
      display: "flex",
    },

    label: {
      marginBottom: "0.365vw",
      '&:not(:first-child)': {
        marginTop: "1.563vw",
      },
    },

    leftField: {
      width: "100%",
      marginTop: "1.563vw"
    },

    rightField: {
      marginTop: "3.125vw",
      alignSelf: "center"
    },

    productType: {
      ...cardRadius,
      border,
      padding: "1.083vw 0vw",
      width: "18.438vw",
      height: "21.875vw",
    },

    productTypeTitle: {
      fontSize: "0.938vw",
      fontWeight: "500",
      lineHeight: "1.094vw",
      marginLeft: "2.083vw",
      marginTop: "0.781vw",
      marginRight: "-0.625vw",
      width: "10.417vw"
    },

    productTypeContainer: {
      // margin: "0 -1.979vw",
      height: "-webkit-fill-available",
    },

    productTypeScroll: {
      width: "100%",
      height: "90%",
      overflow: "overlay",
      borderRadius: "0 0 0.833vw 0.833vw",
    },

    productTypeTable: {
      marginTop: "1.563vw"
    },

    empty: {
      height: "-webkit-fill-available",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "1.094vw",
      marginTop: "8.927vw",
      fontWeight: 500,
      color: "rgba(19, 50, 43, 0.25)",
    },

    productDescription: {
      width: "34.109vw",
      marginRight: "3.432vw"
    },

    imageGroup: {
      display: "flex",
    },

    imgBorder: {
      ...cardRadius,
      border: "0.104vw solid #DDDDDD",
      width: "8.854vw",
      height: "8.854vw",
    },

    imgBorderError: {
      border: "0.104vw solid #FF0000",
    },

    addImg: {
      width: "100%"
    },

    imageText: {
      fontSize: "0.729vw"
    },

    uploadImageButton: {
      border: "0.052vw solid #FF8A3D",
      color: secondaryColor[0],
      padding: "0vw 1.146vw",
      marginTop: "1.406vw"
    },

    checkbox: {
      marginLeft: "3.125vw"
    },

    //add new product
    productMixTypography: {
      marginTop: "1.563vw",
      marginBottom: "1.042vw"
    },

    addNewButton: {
      marginTop: "1.563vw",
      marginBottom: "1.042vw"
    },

    //product type settings
    productSettingTopCard: {
      marginBottom: "2.604vw",
      boxShadow: "none",
    },

    goBackButton: {
      marginTop: "1.563vw",
    },

    //grouping
    groupingName: {
      marginLeft: "1.563vw",
      marginBottom: "1.563vw",
      width: "60%"
    },

    //GrowthProcess
    growthProcessTable: {
      border,
      boxShadow: "none",
      marginBottom: "2.083vw"
    },

    addMoreProcess: {
      marginTop: "0.781vw"
    },

    lossRateCell: {
      width: "6.250vw",
      maxWidth: "6.250vw"
    },

    // Configure Package
    packageCard: {
      display: "flex",
      justifyContent: "center"
    },
    packagePaper: {
      ...cardRadius,
      border,
      padding: "2.083vw 1.979vw",
      width: "41.667vw"
    },

    //raw material and device creation
    rawMaterialButton: {
      marginTop: "1.563vw",
    },

    rawMaterialText: {
      fontSize: "0.938vw"
    },

    rawMaterialTable: {
      ...cardRadius,
      border,
      margin: "auto",
      padding: "1.302vw 1.979vw",
    },

    deviceTable: {
      ...cardRadius,
      border,
      margin: "auto",
      marginTop: "1.979vw",
      padding: "1.302vw 1.979vw",
    },

    unlink: {
      color: "#D3DDD3"
    },

    // Process
    subheader: {
      color: primaryColor[3]
    },
    imageDiv: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "0.521vw"
    },
    imageLabel: {
      marginTop: "0.729vw",
      marginRight: "1.250vw"
    },
    mixAction: {
      width: "41.667vw"
    },
    versionDiv: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "0.521vw"
    },
    versionLabel: {
      marginRight: "1.250vw",
      textAlign: "right",
    },
    versionContainer: {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "30%",
      "& :not(:last-child)": {
        marginRight: "1.250vw"
      }
    },
    version: {
      color: secondaryColor[0],
      textDecoration: "underline",
      cursor: "pointer"
    }
  }
  
  export default productStyle;