import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {loginRoutes as routes} from "routes/loginRoutes.js";
import { snackbarTime } from "config";
import { setError, setSuccess } from "store/general";

import styles from "assets/jss/layouts/authStyle.js";

import loginImg from "assets/img/login-img.svg";

const useStyles = makeStyles(styles);

export default function Authentication() {
  const dispatch = useDispatch();
  // styles
  const classes = useStyles();
  // states and functions
  const error = useSelector(store => store.general.error);
  const success = useSelector(store => store.general.success);
  const [mobile, setMobile] = React.useState(window.innerWidth < 960);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
    </Switch>
  );
  
  const resizeFunction = () => {
    setMobile(window.innerWidth < 960);
  };

  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(setError(false));
      dispatch(setSuccess(false));
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={0} className={classes.mainPanel} alignItems="center">
        { !mobile &&
          <Grid item xs={6}>
            <img className={classes.loginImg} src={loginImg} alt="greenphyto-login" />
          </Grid>
        }
        <Grid item xs={!mobile ? 6 : 12}>
          <div className={classes.container}>{switchRoutes}</div>
        </Grid>
      </Grid>
      <Snackbar 
        open={error != false} 
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={snackbarTime} 
        onClose={()=>dispatch(setError(false))}
      >
        <Alert severity="error" elevation={6} variant="filled">
          An error has occurred
        </Alert>
      </Snackbar>
      <Snackbar 
        open={success != false} 
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={snackbarTime} 
        onClose={()=>dispatch(setSuccess(false))}
      >
        <Alert severity="success" elevation={6} variant="filled">
          {success}
        </Alert>
      </Snackbar>
    </div>
  );
}
