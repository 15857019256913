import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  cardPaper,
  cardTitle,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const capacityStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  orange,
  icon,
  table,
  cardPaper,
  cardTitle,

  cardHeader: {
    position: "relative",
    margin: 0
  },
  accordionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerButton: {
    position: "absolute",
    right: 0
  },
  marginBottom: {
    marginBottom: "1.667vw"
  },
  accordion: {
    border,
    backgroundColor: whiteColor,
    "&:not(:last-child)": {
      marginBottom: "1.563vw !important",
    },
    "&:last-child": {
      marginTop: "1.563vw !important",
    }
  },
  subAccordion: {
    backgroundColor: whiteColor,
    borderRadius: "0 !important",
    margin: "0 !important",
    "&:first-child": {
      borderRadius: "0.625vw 0.625vw 0 0 !important",
    },
    "&:last-child": {
      borderRadius: "0 0 0.625vw 0.625vw !important",
    },
    "&:not(:last-child)": {
      borderBottom: border,
    },
  },

  // Search Capacity
  result,
  
  collapsedIcon,
  rotate,
  highlight,

  // Farm Capacity
  content: {
    padding: "0 6.250vw"
  },
  farmCapacityContent: {
    height: "fit-content",
    maxHeight: "24.740vw",
    overflow: "overlay"
  },
  categoryCardContent: {
    height: "fit-content",
    maxHeight: "41.510vw",
    overflow: "overlay"
  },
  calculationContent: {
    height: "fit-content",
    maxHeight: "14.583vw",
    overflow: "overlay"
  },
  collapsePaper: {
    marginTop: "1.667vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  cardSubheader: {
    paddingTop: "0.883vw",
  },
  calculationDesc: {
    width: "20.833vw",
    paddingLeft: "4.688vw !important",
  },
  selectedTitle: {
    fontWeight: 400,
  },
  listTitle: {
    fontWeight: 500,
    color: primaryColor[4],
  },
  listTitleBottom: {
    marginBottom: "0.938vw"
  },
  listCardContent: {
    height: "fit-content",
    maxHeight: "31.250vw",
    overflow: "overlay"
  },
  bankListCardContent: {
    height: "fit-content",
    maxHeight: "24.427vw",
    overflow: "overlay"
  },
  bayListCardContent: {
    height: "fit-content",
    maxHeight: "19.792vw",
    overflow: "overlay"
  },
  listHeader: {
    marginTop: "-0.938vw",
    marginBottom: "-0.938vw",
  },
  listContent: {
    display: "flex",
    flexWrap: "wrap",
    height: "-webkit-fill-available",
    marginTop: "-2.604vw !important",
    paddingTop: "2.604vw !important",
    margin: "-1.302vw -1.979vw",
    padding: "1.302vw 1.354vw"
  },
  checkboxPadding: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  editPaper: {
    ...cardRadius,
    border,
    // width: "45.156vw",
    padding: "1.458vw 1.875vw",
  },
  paper: {
    ...cardRadius,
    border,
    width: "45.156vw",
    padding: "1.458vw 1.875vw",
  },
  paperButton: {
    textAlign: "end",
    "& :not(:first-child)": {
      marginLeft: "1.250vw"
    }
  },
  disabled: {
    color: primaryColor[1]
  },
  selectGroup: {
    backgroundColor: whiteColor+" !important",
    position: "sticky",
    top: 0,
    zIndex: 1,
    fontWeight: 500
  },
  selectItem: {
    marginLeft: "1.042vw"
  },
  // Trays Per Configuration
  productDropdown: {
    width: "11.771vw"
  },
  configCellWidth: {
    width: "10vw",
    maxWidth: "10vw",
  },
  // Batch Capacity
  cagesPaper: {
    border,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "9.792vw"
  },
  cageNumber: {
    fontSize: "2.604vw",
    fontWeight: 500
  },
  cageDetail: {
    fontSize: "1.094vw",
    opacity: 0.5,
    textAlign: "center"
  },
  cageCardContent :{
    height: "fit-content",
    maxHeight: "28.490vw",
    overflow: "overlay"
  },
  tableCellTitle: {
    fontWeight: 500,
  },
  tableText: {
    opacity: 0.6,
  },
  tableCell: {
    width: "12.500vw",
  },
  tableCellAction: {
    width: "8.250vw"
  },
  tableAction: {
    display: "flex",
    alignItems: "center",
    color: primaryColor[4],
    margin: "-0.469vw 0"
  },
  popper: {
    width: "22.396vw"
  }
}

export default capacityStyle;