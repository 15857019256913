import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// core components
import Card from 'shared-components/Card/Card';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Carousel from "shared-components/Carousel/Carousel";
import Tag from 'shared-components/Chip/Tag';

import WorkOrder from "./WorkOrder";
import { farmConfig } from "enums/Constants";
import { useInterval } from 'common/utils';

import light from "assets/icons/black/light.svg";
import fan from "assets/icons/black/fan.svg";
import temperature from "assets/icons/black/temperature.svg";
import water from "assets/icons/black/water.svg";
import cloud from "assets/icons/black/cloud.svg";
import enter from "assets/icons/orange/enter.svg";
import noCamera from "assets/vids/no-camera.mp4";
import styles from "assets/jss/components/UserPortal/monitoringStyle.js";
import { GetBankDetailView } from "services/UserPortal/MonitoringService";

const useStyles = makeStyles(styles);

export default function BankOverview(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const bankList = useSelector(store => store.common.bankList);
  const detailView = useSelector(store => store.user.monitoring.production.detailView);
  const [date, setDate] = React.useState(moment());

  const handleOnClick_device = (deviceId, unit) => {
    const index = bankList.findIndex(({id}) => id===props.bankId);
    history.push({pathname: "/user/climate/climate-monitoring", state: {tab: index, bankId: props.bankId, deviceId, unit, date: detailView.readingDate}});
  };

  React.useEffect(() => {
    dispatch(GetBankDetailView(props.bankId));
  },[props.bankId]);

  useInterval(() => {
    setDate(moment());
  });

  useInterval(() => {
    setDate(moment());
  }, 1000);

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.paper}>
        { (detailView.cameraInfos && detailView.cameraInfos.length > 0)
        ?
          <Carousel 
            className={classes.camera}
            classes={{
              img: classes.camera
            }}
            data={
              detailView.cameraInfos.map((item) => {
                return (
                  item.streamUrl
                )
              })
            }
          />
        :
        <video 
          className={classes.camera}
          src={noCamera}
          autoPlay
          loop
        />
        }
        <div className={classes.detailDiv}>
          <div className={classes.detailHeaderDiv}>
            <div className={classes.detailHeader}>
              <Typography className={classes.detailTitle}>{props.noSummary ? detailView.cageRefNo : detailView.bankName}</Typography>
              <Typography>{moment(date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </div>
          </div>
          <div className={classes.detailContentDiv}>
            <Typography className={classes.contentTitle}>Product(s) Housed:</Typography>
            {detailView.productInfos && detailView.productInfos.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <Tag
                    variant="outlined"
                    tabIndex={index}
                    label={item.productName}
                    className={classes.tag}
                  />
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </Paper>
      <div className={classes.cardContainer}>
        <Paper className={classes.envPaper}>
          <img className={clsx(classes.icon_38, classes.iconMargin)} src={water} alt="water" />
          <Typography>Humidity</Typography>
          <Typography className={classes.envValue}>{detailView.humidityValue ?? 0}<img className={clsx(classes.icon_24, classes.enterButton)} src={enter} alt="enter" onClick={()=>handleOnClick_device(detailView.humiditySensorId, detailView.humidityUnit)} /></Typography>
        </Paper>
        <Paper className={classes.envPaper}>
          <img className={clsx(classes.icon_38, classes.iconMargin)} src={temperature} alt="temperature" />
          <Typography>Temperature</Typography>
          <Typography className={classes.envValue}>{detailView.temperatureValue ?? 0}<img className={clsx(classes.icon_24, classes.enterButton)} src={enter} alt="enter" onClick={()=>handleOnClick_device(detailView.temperatureSensorId, detailView.temperatureUnit)} /></Typography>
        </Paper>
        <Paper className={classes.envPaper}>
          <img className={clsx(classes.icon_38, classes.iconMargin)} src={cloud} alt="cloud" />
          <Typography>CO2 Injector</Typography>
          <Typography className={classes.envValue}>{detailView.cO2Value ?? 0}<img className={clsx(classes.icon_24, classes.enterButton)} src={enter} alt="enter" onClick={()=>handleOnClick_device(detailView.cO2SensorId, detailView.cO2Unit)} /></Typography>
        </Paper>
        <Paper className={classes.envPaper}>
          <img className={clsx(classes.icon_38, classes.iconMargin)} src={light} alt="light" />
          <Typography>LED Lights</Typography>
          <Typography className={classes.envValue}>{detailView.ledControllerValue ?? 0}<img className={clsx(classes.icon_24, classes.enterButton)} src={enter} alt="enter" onClick={()=>handleOnClick_device(detailView.ledControllerSensorId, detailView.ledControllerUnit)} /></Typography>
        </Paper>
        <Paper className={classes.envPaper}>
          <img className={clsx(classes.icon_38, classes.iconMargin)} src={fan} alt="fan" />
          <Typography>Ventilation Fans</Typography>
          <Typography className={classes.envValue}>{detailView.ventilationFanValue ?? "Unknown"}<img className={clsx(classes.icon_24, classes.enterButton)} src={enter} alt="enter" onClick={()=>handleOnClick_device(detailView.ventilationFanSensorId, detailView.ventilationFanUnit)} /></Typography>
        </Paper>
      </div>
      <div className={classes.cardContainer}>
        <WorkOrder bankId={props.bankId} />
        { !props.noSummary &&
          <Card
            classes={{
              root: clsx(classes.cardPaper, classes.cardBank),
              title: classes.cardMargin,
              content: classes.cardContent
            }}
            title={farmConfig.bank+" Summary"}
          >
            <Table
              className={(classes.table)}
              header={[{ id: 'title', label: farmConfig.bank+' Name' }, { id: 'bankName', align: "center", label: detailView.bankName }]}
            >
              <TableRow>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)}>Assigned {farmConfig.cages}</TableCell>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)} align="center">{detailView.assignedCages}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)}>Unassigned {farmConfig.cages}</TableCell>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)} align="center">{detailView.unAssignedCages}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)}>{farmConfig.cages} Reserved</TableCell>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)} align="center">{detailView.reservedCages}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)}>Total {farmConfig.cages}</TableCell>
                <TableCell className={clsx(classes.tableTitle, classes.bankText)} align="center">{detailView.totalCages}</TableCell>
              </TableRow>
            </Table>
          </Card>
        }
      </div>
    </React.Fragment>
  );
}
