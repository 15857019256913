import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
  whiteColor,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  sectionHeader,
} from "assets/jss/components/commonStyle.js";

const manufacturerStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  sectionHeader,
  
  orange,

  // Display manufacturer
  table,
  icon,

  // Add Edit Manufacturer
  paper: {
    ...cardRadius,
    border,
    padding: "3.021vw"
  },
  deviceCell: {
    width: "14.583vw"
  },
  textfieldCell: {
    verticalAlign: "top"
  },
  deviceTable: {
    "& thead > tr > th:last-child": {
      minWidth: "unset",
      width: "6.250vw"
    },
    "& tbody > tr": {
      backgroundColor: whiteColor + " !important"
    },
  },
  cellButton: {
    marginTop: "0.208vw",
  },
  addButton: {
    marginTop: "0.938vw"
  }
}

export default manufacturerStyle;