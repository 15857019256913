import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import IconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { sensorSettingsTableHead } from "enums/UserPortal/TableHeaders";
import { setSearchText } from "store/general";
import { minDate, rowsPerTable } from "config";
import { useRouteCanWrite } from "hooks";
import { reset } from "../store/sensorSettings";
import { GetAllSensorSetting, DeleteSensorSetting } from "services/UserPortal/ClimateService";

import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceControlDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.climate.sensorSettings.totalCount);
  const sensorSettings = useSelector(store => store.user.climate.sensorSettings.sensorSettings);
  const [page, setPage] = React.useState(0);
  const [settingId, setSettingId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);
  
  const handleModal_delete = (id) => {
    setSettingId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteSensorSetting(settingId))
    .then(() =>{
      dispatch(GetAllSensorSetting({page: 0, deviceFunctionId: props.selectedFunction, stopLoading: true}));
      resetState();
    })
  }

  const resetState = () => {
    setSettingId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    if (props.selectedFunction) {
      dispatch(GetAllSensorSetting({page, deviceFunctionId: props.selectedFunction, stopLoading: true}));
    }
  },[props.selectedFunction, page]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  const set = new Set(props.selectedDevices.map(({deviceId}) => deviceId))
  const res = sensorSettings.filter(obj => set.has(obj.deviceId))
  return (
    <React.Fragment>
      {res.length 
      ? <React.Fragment>
          <Card 
            classes={{
              root: clsx(classes.cardPaper, classes.cardMargin),
            }}
          >
            <div className={classes.table}>
              <Table
                header={sensorSettingsTableHead}
              >
                { res.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{('0'+((page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                      <TableCell>{item.ruleName}</TableCell>
                      <TableCell>{item.deviceFunctionName}</TableCell>
                      <TableCell>{item.deviceRefNo}</TableCell>
                      <TableCell>
                        <Checkbox 
                          checked={Boolean(item.isActive)}
                          disabled
                        />
                      </TableCell>
                      <TableCell>{moment(item.creationTime).isAfter(minDate) && moment(item.creationTime).format("DD/MM/YYYY")}</TableCell>
                      <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/user/climate/sensor-settings/"+item.id}}>
                              <IconButton type="edit" />
                            </Link>
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal_delete(item.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal_delete(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this sensor setting? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : null
      }
    </React.Fragment>
  );
}
