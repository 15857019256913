import {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardRadius,
  border,
  backgroundColor,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  subTable,
  whiteTable,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const deliveryStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  subTable,
  whiteTable,
  icon,
  link,
  tag,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  detailTable: {
    '& thead > tr > th:not(:last-child):not(:first-child)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
    '& thead > tr > th:nth-child(2)': {
      width: "10vw",
      minWidth: "10vw"
    },
    '& thead > tr > th:nth-child(4)': {
      width: "8vw",
      minWidth: "8vw",
      whiteSpace: "break-spaces"
    },
  },

  // Assignment
  cardDropdown: {
    width: "13.542vw"
  },
  assignModal: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    width: "max-content"
  },
  modalPaper: {
    ...cardRadius,
    height: "30.104vw",
    width: "60.208vw",
    display: "flex",
    flexDirection: "row"
  },
  leftModal: {
    padding: "3.125vw 2.813vw",
    width: "50%",
    overflow: "overlay"
  },
  rightModal: {
    padding: "0.521vw",
    paddingLeft: 0,
    width: "50%"
  },
  modalTitle: {
    fontSize: "1.667vw",
    textAlign: "center"
  },
  modalSubtitle: {
    fontSize: "0.938vw",
    textAlign: "center",
    color: "#13322BBF",
    marginTop: "0.208vw",
    marginBottom: "1.563vw"
  },
  estimateModal: {
    width: "30.104vw",
    paddingTop: "1.823vw"
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loading: {
    color: secondaryColor[0],
  },
  assignMap: {
    height: "100%",
    borderRadius: "0 0.833vw 0.833vw 0"
  },
  doList: {
    overflow: "overlay",
  },
  orderPaper: {
    ...cardRadius,
    border,
    height: "10.208vw",
    textAlign: "left",
    padding: "0.833vw 1.042vw",
    margin: "1.563vw 0",
    overflow: "overlay",
  }, 
  orderListItem: {
    padding: "0 !important",
    cursor: "pointer"
  },
  iconMargin: {
    marginLeft: "0.625vw"
  },
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
    textAlign: "right",
    marginTop: "-1.563vw",
    marginBottom: "1.563vw",
  },
  assignGrid: {
    textAlign: "left"
  },
  label: {
    alignSelf: "center",
    alignItems: "center"
  },
  estDetail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  assignLink: {
    ...orange,
    textAlign: "right",
    cursor: "pointer"
  },

  // Master List
  filterModal: {
    width: "max-content"
  },
  filterContent: {
    display: "flex",
    marginTop: "1.563vw"
  },
  filterPaper: {
    border,
    borderRadius: "0 0.313vw 0.313vw ",
    padding: "0.469vw 0.625vw",
    width: "20.260vw",
    height: "18.750vw",
    overflow: "overlay",
    textAlign: "left"
  },
  filterTabLabel: {
    border: '0.104vw solid #DDDDDD',
    borderRight: "none",
    borderRadius: "0.313vw 0 0 0.313vw",
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    opacity: 1,
    padding: "0.521vw 0.625vw",
    minHeight: "1.875vw",
    maxWidth: "initial",
    "&:not(:first-child)": {
      marginTop: "0.313vw"
    },
    "& > span": {
      alignItems: "start",
      textAlign: "-webkit-left"
    },
  },
  filterIndicator: {
    backgroundColor: "transparent"
  },
  list: {
    overflow: "overlay",
    height: "13.938vw",
  },
  selectedFilter: {
    ...orange,
  },
  mapCardLeft: {
    width: "35%",
    marginRight: "1.042vw"
  },
  mapCardRight: {
    width: "65%",
  },
  cardLeft: {
    width: "65%",
    marginRight: "1.042vw"
  },
  cardRight: {
    width: "35%",
  },
  cardTitle: {
    fontSize: "0.938vw",
    margin: "-0.520vw"
  },
  cardContent: {
    height: "21.406vw",
    overflow: "overlay"
  },
  map: {
    margin: "-1.302vw -1.979vw",
    padding: 0,
    height: "21.406vw",
  },
  accordion: {
    margin: "unset !important",
    marginTop: "1.563vw !important",
  },
  accordionContent: {
    width: "100%",
    backgroundColor: whiteColor,
  },
  accordionItem: {
    padding: "0.833vw 1.042vw",
    "&:first-child": {
      borderRadius: "0.625vw 0.625vw 0 0 !important",
    },
    "&:last-child": {
      borderRadius: "0 0 0.625vw 0.625vw !important",
    },
    "&:not(:last-child)": {
      borderBottom: border,
    },
  },
  detailDate: {
    color: primaryColor[3],
    marginLeft: "0.573vw",
    marginRight: "1.563vw",
    "&:last-child": {
      marginRight: 0
    }
  },
  detailGrid: {
    width: "-webkit-fill-available",
    margin: "0 -1.979vw",
    padding: "1.563vw 1.458vw",
    '&:nth-of-type(odd)': {
      backgroundColor,
    },
    '&:nth-of-type(even)': {
      backgroundColor: whiteColor,
    },
  },
  detailLabels: {
    fontWeight: 500,
    minWidth: "5.208vw"
  },
  detailAddress: {
    overflowWrap: "anywhere",
    marginBottom: "0.521vw"
  },
  detailStatus: {
    fontWeight: 500,
    fontSize: "0.938vw",
    color: primaryColor[3]
  },
  progress: {
    color: secondaryColor[0],
  },
  completed: {
    color: "#00891BBF"
  },
  infoWindow: {
    color: whiteColor,
    fontWeight: 500,
    textAlign: "center"
  },

  //Delivery Order
  paper: {
    ...cardRadius,
    border,
    padding: "1.666vw 1.979vw",
    margin: "2.604vw 0"
  }, 
  labelContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  detailLabel:{
    fontWeight: 700,
  },
  detailText: {
    margin: "0.938vw 0",
    "& div": {
      backgroundColor: whiteColor + " !important",
    },
  },
  signaturePaper: {
    ...cardRadius,
    border,
    height: "10.417vw",
    marginTop: "0.938vw"
  },
  signatureImg: {
    margin: "auto",
    height: "10.417vw",
    maxHeight: "10.417vw"
  },
  imageGrid: {
    padding: "0.938vw 0"
  },
  image: {
    border,
    position:"relative"
  },
  imageBorder: {
    width: "100%",
    aspectRatio: "1 / 1",
    cursor: "zoom-in"
  },
  deleteImage: {
    fontSize:"1.042vw",
    minWidth: "unset",
    maxWidth: "1.563vw",
    width: "1.563vw",
    height: "1.563vw",
    position: "absolute",
    background: "white",
    color: "black",
    top:"0",
    right: "0",
    cursor: "pointer",
    borderRadius: 0
  },
  helper: {
    fontSize: "0.729vw",
    color: primaryColor[0],
    textAlign: "right"
  },
  zoomModalContent: {
    marginRight: 0,
    paddingRight: 0,
  },
  zoomModal: {
    padding: "0.833vw",
    width: "52.083vw",
  },
  zoomImage: {
    width: "100%",
    maxHeight: "36.458vw",
    objectFit: "contain"
  },
  carouselButton: {
    left: 0,
    top: 0,
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-0.833vw',
    marginRight: '-0.833vw',
    position: "absolute",
    height: "100%",
    width: "auto",
    padding: "0.833vw",
    fontWeight: 500,
    fontSize: "0.938vw",
    transition: "0.6s ease",
    borderRadius: "0 0.156vw 0.156vw 0",
    userSelect: "none",
    "&:hover:not(:disabled)": {
      cursor: "pointer",
      backgroundColor: '#DDD',
    }
  },
  next: {
    left: 'unset',
    right: 0,
    borderRadius: "0.156vw 0 0 0.156vw",
  },

  // printing
  printTitle: {
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "30px",
  },
  printLabel: {
    fontWeight: 700,
  },
  printTable: {
    marginTop: "30px",
    marginBottom: "50px",
    "& thead > tr > th": {
      fontSize: "12px",
      borderTop: "1px solid #000",
      borderBottom: "1px solid #000",
      width: "fit-content !important",
      minWidth: "fit-content !important",
      whiteSpace: "nowrap !important"
    },
    "& thead > tr > th:first-child": {
      width: "12px",
    },
    "& tbody > tr": {
      backgroundColor: whiteColor
    },
    "& tbody > tr > td": {
      fontSize: "12px",
    },
  },
  print2Table: {
    marginTop: "30px",
    marginBottom: "30px",
    "& thead > tr > th": {
      fontSize: "12px",
      borderTop: "1px solid #000",
      borderBottom: "1px solid #000",
      width: "fit-content !important",
      minWidth: "fit-content !important",
      whiteSpace: "nowrap !important"
    },
    "& thead > tr > th:first-child": {
      width: "12px",
    },
    "& tbody > tr": {
      backgroundColor: whiteColor
    },
    "& tbody > tr > td": {
      fontSize: "12px",
    },
  },
  printTablePadding: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  printTableRow: {
    borderTop: "1px solid #000",
    borderBottom: "1px double #000",
  },
  printSignatureImg: {
    height: "80px",
  },
  signatureLine: {
    borderTop: "1px solid #000",
    width: "120px",
    marginBottom: "10px"
  },

  //Settings
  settingsCard: {
    display: "flex",
  },
  leftContent: {
    display: "flex",
    alignItems: "center"
  },
  settingsLabel: {
    color: primaryColor[4],
    alignSelf: "center"
  },
  settingsButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.083vw"
  },
  settingsMap: {
    ...cardRadius,
    height: "20.833vw",
  },
}

export default deliveryStyle;