import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";
import InfiniteAutocomplete from "shared-components/Select/InfiniteAutocomplete";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { reset as resetNav } from "shared-components/Navbars";
import { setValues, updateRequest, reset } from "../store/currentListings";
import { CreateOrUpdateASRSOnboardingRecord, GetAllCages, GetAllASRSSoftzone } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/asrsStyle.js";

const useStyles = makeStyles(styles);

export default function AddCurrentListings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const batch = useSelector(store => store.user.asrs.currentListings.batch);
  const cageList = useSelector(store => store.user.asrs.currentListings.cageList);
  const softzoneList = useSelector(store => store.user.asrs.currentListings.softzoneList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [page, setPage] = React.useState(0); // for softzone list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for softzone list
  const [searchValue, setSearchValue] = React.useState(""); // for softzone list

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (target, newValue) => {
    const item = _.find(cageList, ({cageRefNo}) => cageRefNo === newValue);
    dispatch(updateRequest({[target] : newValue, cageId: item.id}));
  };

  const handleOnChange_autocomplete = (target, newValue) => {
    dispatch(updateRequest({[target] : newValue, softzoneId: newValue.softzoneID}));
  };

  const fetchMoreData = (search, pageNo) => {
    if (page !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : page,
        softzoneName: search ? search : ""
      }
      dispatch(GetAllASRSSoftzone(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({softzoneList: response.payload.result.items}));
            } else {
              dispatch(setValues({softzoneList: _.unionBy(softzoneList, response.payload.result.items, "softzoneID")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleButtonClick_batch = () => {
    history.push("/user/vifs/current-listings/batch");
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(setDirty(false));
      dispatch(CreateOrUpdateASRSOnboardingRecord(batch))
      .then((response) => {
        if (!response.error) {
          history.push("/user/vifs/current-listings");
        }
      });
    }
  }

  const validateFields = () => {
    if (!batch.cageRefNo) {
      setErrorMsg({field: "cageRefNo", msg: "Please select a cage"});
      return false;
    }
    if (!batch.softzoneId) {
      setErrorMsg({field: "softzoneId", msg: "Please select a softzone id"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    dispatch(GetAllCages());
    dispatch(GetAllASRSSoftzone({page: 0}))
    .then((response) => {
      if (response.payload.result) {
        setPage(page+1);
        dispatch(setValues({softzoneList: response.payload.result.items}))
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(resetNav());
      dispatch(reset());
    }
  },[]);

  console.log(searchValue)
  return (
    <React.Fragment>
      <Card 
        title={
          <React.Fragment>
            VIFS - New Onboard
            <Button 
              className={clsx(classes.buttonSecondary, classes.cardActionDate)}
              startIcon={<AddIcon />}
              onClick={()=>handleButtonClick_batch()}
            >
              Choose Batch Upload
            </Button>
          </React.Fragment>
        }
      >
        <Paper elevation={0} className={classes.settingPaper}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={classes.settingLabel}>
              Cage ID:
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="cageRefNo"
                inputProps={{ maxLength: 50 }}
                value={batch.cageRefNo}
                onChange={(e, newValue) => {
                  handleOnChange_select('cageRefNo', newValue);
                }}
                options={cageList.map((listItem) => listItem.cageRefNo)}
                placeholder="Select a cage"
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.settingLabel}>
              Softzone ID:
            </Grid>
            <Grid item xs={8}>
              <InfiniteAutocomplete
                dataCount={page*10}
                options={softzoneList}
                fetchData={()=>fetchMoreData()}
                renderOption={(option) => option.softzoneName}
                isLoadingMore={isLoadingMore}
                placeholder="Select a softzone"
                onInputChange={(e, newInputValue) => {
                  if (e && e._reactName == "onClick") {
                    setPage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(undefined, 0);
                  }
                  if (e && e._reactName == "onChange") {
                    setPage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(newInputValue, 0);
                  }
                }}
                onChange={(_, newValue) => {
                  handleOnChange_autocomplete("softzoneName", newValue);
                }}
                inputValue={searchValue}
                value={searchValue!==batch.softzoneName ? null : batch.softzoneName}
                renderValue={"softzoneName"}
              />
            </Grid>
            <Grid item xs={4} className={classes.settingLabel}>
              Amelech ID:
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="amelechId"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(batch.amelechId)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.settingLabel}>
              Aranet ID:
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="aranetId"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(batch.aranetId)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.settingLabel}>
              Aranet ID 2:
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="aranetId2"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(batch.aranetId)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.settingLabel}>
              Aranet ID 3:
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="aranetId3"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(batch.aranetId)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={12} className={classes.settingButton}>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>history.push("/user/vifs/current-listings")}
              >
                Cancel
              </Button>
              <Button 
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Card>
    </React.Fragment>
  );
}
