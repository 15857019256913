import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/homeStyle.js";

const useStyles = makeStyles(styles);

export default function Planning() {
  const classes = useStyles();
  let history = useHistory();
  const results = useSelector(store => store.user.home.planningWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      {results.length
      ? <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <Typography className={classes.tableTitle}>Product Name</Typography>
              <Typography className={classes.paperContent}>{results[page] && results[page].productName}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <Typography className={classes.tableTitle}>PO Number</Typography>
              <Typography className={classes.paperContent}>{results[page] && results[page].purchaseOrderNumber}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <Typography className={classes.tableTitle}>SO Number</Typography>
              <Typography className={classes.paperContent}>{results[page] && results[page].subSaleOrderNumber}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <Typography className={classes.tableTitle}>Capacity Planned</Typography>
              <Typography className={classes.paperContent}>{results[page] && results[page].capacityPlanned}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={0} className={classes.paper}>
              <Typography className={classes.tableTitle}>Delivery Date</Typography>
              <Typography className={classes.paperContent}>{moment(results[page] && results[page].deliveryDate).format("DD/MM/YYYY")}</Typography>
            </Paper>
          </Grid>
        </Grid>
      : <Typography className={classes.tableTitle}>Nothing to show</Typography>
      }
      <div className={classes.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(classes.buttonSecondary, classes.viewAllButton)} onClick={()=>history.push("/user/planning/planning-outcome")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
