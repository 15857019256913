import { createSlice } from "@reduxjs/toolkit";

import { GetAllSensorSetting, GetSensorSetting } from "services/UserPortal/ClimateService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  sensorSettings: [],
  sensorSetting: {sensorSettingDetailList:[]},
};

const sensorSettings = createSlice({
  name: "sensorSettings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.sensorSetting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllSensorSetting.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.sensorSettings = action.payload.result.items;
    },
    [GetSensorSetting.fulfilled]: (state, action) => {
      state.sensorSetting = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = sensorSettings.actions;

// export the reducer
export default sensorSettings.reducer;