import React from "react";
// @mui/material components
import { withStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import { secondaryColor } from "assets/jss/generaliseStyle.js";

const OrangeRadio = withStyles({
  root: {
    color: secondaryColor[0],
    '&$checked': {
      color: secondaryColor[0],
      '&$disabled': {
        color: secondaryColor[0],
        opacity: 0.7
      },
    },
  },
  checked: {},
  disabled: {}
})(Radio);

export default function CustomRadio(props) {
  return (
    <OrangeRadio 
      {...props}
    />
  );
}