import React, {useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { useInterval } from 'common/utils';
import { minDate } from "config";
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { reset } from "../store/deliveryOrder";
import { deliveryOrderInvoiceTableHead } from "enums/UserPortal/TableHeaders";
import { GetDeliveryOrderById } from "services/UserPortal/DeliveryService";

import PrintFormat from "layouts/PrintFormat";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

export default function Invoice() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const details = useSelector(store => store.user.delivery.deliveryOrder.details);
  const [, setOnBeforeGetContentResolve] = useState(false);
  const [printing, setPrinting] = useState(false);

  const getDODetails = (stopLoading) => {
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "invoice");
    dispatch(GetDeliveryOrderById({id: path[index+1], stopLoading}))
    .then(({error}) => {
      if (error) {
        history.push("/user/delivery/delivery-order");
      }
    });
  }

  const handleButtonClick_print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice_"+details.invoiceNumber,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        setOnBeforeGetContentResolve(resolve);
      });
    },
  })

  // componentDidMount
  React.useEffect(() => {
    getDODetails();
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getDODetails(true);
  });

  if (details) {
    return (
      <React.Fragment>
        <Card 
          title={"Invoice of "+details.deliveryOrderRefNo}
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>history.goBack()}
              >
                Go Back
              </Button>
              <Button
                className={classes.button}
                onClick={handleButtonClick_print}
              >
                Print
              </Button>
            </React.Fragment>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={2} className={classes.detailLabel}>
              Delivery Date:
            </Grid>
            <Grid item xs={4}>
              {moment(details.actualDeliveryDate).isAfter(minDate) ? moment(details.actualDeliveryDate).format("DD/MM/YYYY") : moment(details.deliveryDate).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Delivery Address:
            </Grid>
            <Grid item xs={4}>
              {details.deliveryAddress}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Customer:
            </Grid>
            <Grid item xs={4}>
              {details.customerName}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Collect Person:
            </Grid>
            <Grid item xs={4}>
              {details.contactPerson}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Contact Number:
            </Grid>
            <Grid item xs={4}>
              {details.contactDetail}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Driver&apos;s Name:
            </Grid>
            <Grid item xs={4}>
              {details.driverName}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Reference Number:
            </Grid>
            <Grid item xs={4}>
              {details.deliveryOrderRefNo}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Driver&apos;s Number:
            </Grid>
            <Grid item xs={4}>
              {details.driverNumber}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              PO Number:
            </Grid>
            <Grid item xs={4}>
              {details.poNumber}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              SO Number:
            </Grid>
            <Grid item xs={4}>
              {details.saleOrderNumber}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Invoice Number:
            </Grid>
            <Grid item xs={4}>
              {details.invoiceNumber}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Term:
            </Grid>
            <Grid item xs={4}>
              {details.term}
            </Grid>
          </Grid>
          <Paper className={classes.paper} elevation={0}>
            <Table
              className={classes.table}
              header={deliveryOrderInvoiceTableHead}
            >
              { details.finishedGoodsProcesses && details.finishedGoodsProcesses.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.product_Id}</TableCell>
                    <TableCell>{formatNumbers(item.doNumberOfPacket)}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.price))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.amount))}</TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell colSpan={5} align="right" className={classes.detailLabel}>7% GST for total :</TableCell>
                <TableCell>${formatNumbers(roundTo2Decimal(details.gstAmount))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="right" className={classes.detailLabel}>Total:</TableCell>
                <TableCell>${formatNumbers(roundTo2Decimal(details.totalAmount))}</TableCell>
              </TableRow>
            </Table>
          </Paper>
        </Card>
        <div ref={componentRef}>
          <PrintFormat printing={printing}>
            <Typography className={classes.printTitle}>Tax Invoice</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={classes.printLabel}>
                    Customer Name:
                  </Grid>
                  <Grid item xs={8}>
                    {details.customerName}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    Customer Address:
                  </Grid>
                  <Grid item xs={4}>
                    {details.deliveryAddress}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={classes.printLabel}>
                    Invoice No.:
                  </Grid>
                  <Grid item xs={8}>
                    {details.invoiceNumber}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    Delivery Date:
                  </Grid>
                  <Grid item xs={8}>
                    {moment(details.actualDeliveryDate).isAfter(minDate) ? moment(details.actualDeliveryDate).format("DD/MM/YYYY") : moment(details.deliveryDate).format("DD/MM/YYYY")}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    DO No.:
                  </Grid>
                  <Grid item xs={8}>
                    {details.deliveryOrderRefNo}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    SO No.:
                  </Grid>
                  <Grid item xs={8}>
                    {details.saleOrderNumber}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    Term:
                  </Grid>
                  <Grid item xs={8}>
                    {details.term}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Table
              className={clsx(classes.whiteTable, classes.print2Table)}
              header={deliveryOrderInvoiceTableHead}
            >
              { details.finishedGoodsProcesses && details.finishedGoodsProcesses.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.product_Id}</TableCell>
                    <TableCell>{formatNumbers(item.doNumberOfPacket)}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.price))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.amount))}</TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell colSpan={5} align="right" className={classes.printTablePadding}>7% GST for total :</TableCell>
                <TableCell className={classes.printTablePadding}>${formatNumbers(roundTo2Decimal(details.gstAmount))}</TableCell>
              </TableRow>
              <TableRow className={classes.printTableRow}>
                <TableCell colSpan={5} align="right">TOTAL:</TableCell>
                <TableCell>${formatNumbers(roundTo2Decimal(details.totalAmount))}</TableCell>
              </TableRow>
            </Table>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.printLabel}>Remittance Details:</Grid>
              <Grid item xs={2}>Beneficiary Name:</Grid>
              <Grid item xs={10}>Greenphyto Pte Ltd</Grid>
              <Grid item xs={2}>Bank Name:</Grid>
              <Grid item xs={10}>DBS Bank Ltd</Grid>
              <Grid item xs={2}>Bank Address:</Grid>
              <Grid item xs={10}>12 Marina Boulevard,<br/>Marina Bay Financial Centre Tower 3,<br/>Singapore 018982</Grid>
              <Grid item xs={2}>Account Name:</Grid>
              <Grid item xs={10}>Greenphyto Pte Ltd</Grid>
              <Grid item xs={2}>Bank Code No:</Grid>
              <Grid item xs={10}>7171</Grid>
              <Grid item xs={2}>Branch Code No:</Grid>
              <Grid item xs={10}>003</Grid>
              <Grid item xs={2}>Bank Account No:</Grid>
              <Grid item xs={10}>003 928 20210</Grid>
              <Grid item xs={2}>Swift Code:</Grid>
              <Grid item xs={10}>DBSSSGSG</Grid>
            </Grid>
          </PrintFormat>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
