import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/homeStyle.js";

const useStyles = makeStyles(styles);

export default function FinishedGoods() {
  const classes = useStyles();
  let history = useHistory();
  const results = useSelector(store => store.user.home.finishedGoodsWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      { results.length 
      ? <React.Fragment>
          <Typography className={classes.widgetTitle}>5 Latest Checked In Finished Goods</Typography>
          <Table
            className={classes.table}
          >
            <TableRow>
              <TableCell className={classes.tableTitle}>Product Name:</TableCell>
              <TableCell className={classes.tableContent} align="right">{results[page] && results[page].productName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableTitle}>WO Number:</TableCell>
              <TableCell className={classes.tableContent} align="right">{results[page] && results[page].workOrderNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableTitle}>Quantity In:</TableCell>
              <TableCell className={classes.tableContent} align="right">{results[page] && results[page].qtyIn}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableTitle}>Number of Packets:</TableCell>
              <TableCell className={classes.tableContent} align="right">{results[page] && results[page].numberOfPackets}</TableCell>
            </TableRow>
          </Table>
        </React.Fragment>
        : <Typography className={classes.tableTitle}>Nothing to show</Typography>
      }
      <div className={classes.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(classes.buttonSecondary, classes.viewAllButton)} onClick={()=>history.push("/user/finished-goods/master-list")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
