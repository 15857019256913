import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable } from "config";
import { setSearchText } from "store/general";
import { useInterval } from 'common/utils';
import { isPositive } from 'common/validations';
import { setValues } from "../store/reserve";
import { rmReserveTableHead } from "enums/UserPortal/TableHeaders";
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { GetProductList } from "services/UserPortal/CommonLookupService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";
import { GetAllRawMaterialReserveOrIssue, CreateOrUpdateRawMaterialReserve, DeleteRawMaterialReserve } from "services/UserPortal/RawMaterialService";

const useStyles = makeStyles(styles);
import { filterActionTableHead } from "common/functions";
import { useRouteCanWrite } from "hooks";

export default function Reserve() {
  const classes = useStyles();
  const canWrite = useRouteCanWrite();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const lotIdList = useSelector(store => store.common.lotIdList);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const filter = useSelector(store => store.user.rawMaterial.reserveRM.filter);
  const totalCount = useSelector(store => store.user.rawMaterial.reserveRM.totalCount);
  const reserveList = useSelector(store => store.user.rawMaterial.reserveRM.reserveList);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const productList = useSelector(store => store.common.productList);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('workNumberNumber');
  const [openModal, setOpenModal] = React.useState(false);
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, rawMaterialName: null, productName: null, reserveStartDate: null, reserveEndDate: null, page: 0});
  const [editItem, setEditItem] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleOnChange_text = (value) => {
    let payload = _.cloneDeep(editItem);
    payload.qtyReserve = Number(value);
    setEditItem(payload);
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, reserveStartDate: moment(value.startDate).format("YYYY-MM-DD"), reserveEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleModal = (id) => {
    setDeleteId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteRawMaterialReserve(deleteId))
    .then(() =>{
      dispatch(setValues({filter: {...filter, page: 0}}));
      handleModal(null);
      setDeleteId(null);
    })
  }

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_deleteTag = (key) => {
    if (!editItem) {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const handleButtonClick_confirmEdit = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateRawMaterialReserve(editItem))
      .then(() =>{
        setEditItem(null);
        getReserveList();
      })
    }
  }

  const validateFields = () => {
    if (!isPositive(editItem.qtyReserve)) {
      setErrorMsg({field: "qtyReserve", msg: "Please enter a valid reserve quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const getReserveList = (stopLoading) => {
    dispatch(GetAllRawMaterialReserveOrIssue({...filter, status: "Reserved", stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getReserveList();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetProductList());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getReserveList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title={"Reservation List"}
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "productId") {
                const product = productList.find(({id}) => id == filter[key]);
                label = product && product.productName+"("+product.productRefNo+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_deleteTag(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              disabled={editItem}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the reserve list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialName"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material name"
                    value={renderValue(newFilter.rawMaterialName)}
                  >
                    {rawMaterialList && rawMaterialList.map((item, index) => {
                      return <MenuItem key={index} value={item.rawMaterialName}>{item.rawMaterialName}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Usage date"
              className={classes.cardActionDate}
              value={{startDate: filter.reserveStartDate, endDate: filter.reserveEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, reserveStartDate: null, reserveEndDate: null, page: 0}}))}
              disabled={editItem}
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.reserveTable}
            header={filterActionTableHead(rmReserveTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { reserveList.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.workOrderNumber}</TableCell>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{item.rawMaterialRefNo}</TableCell>
                  <TableCell>{item.rawMaterialBatchRefNo}</TableCell>
                  <TableCell>{moment(item.creationDate).format("DD/MM/yyyy HH:mm")}</TableCell>
                  <TableCell>
                    {(editItem && item.id === editItem.id)
                    ? <TextField
                        className={classes.alignTextfield}
                        id="qtyReserve"
                        variant="outlined"
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,40)
                        }}
                        onChange={(e) => handleOnChange_text(e.target.value)}
                        value={editItem.qtyReserve}
                        errorMsg={errorMsg}
                      />
                    : formatNumbers(roundTo2Decimal(item.qtyReserve))
                    }
                  </TableCell>
                  <TableCell>{item.isInfinityQty ? "-" : formatNumbers(roundTo2Decimal(item.currentQty))}</TableCell>
                  <TableCell>{item.uom}</TableCell>
                  <TableCell>{moment(item.reserveDate).format("DD/MM/yyyy HH:mm")}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{item.productRefNo}</TableCell>
                  <TableCell>{item.unitCost.toFixed(4)}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.lossRatePercent))}</TableCell>
                  <TableCell>{item.location}</TableCell>
                  <TableCell>{item.reserveByUserName}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell align="right">
                    {canWrite && (editItem && item.id === editItem.id
                    ? <React.Fragment>
                        <CustomIconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirmEdit()}
                        />
                        <CustomIconButton 
                          type="close"
                          onClick={() => setEditItem(null)}
                        />
                      </React.Fragment>
                    : <React.Fragment>
                        <CustomIconButton 
                          type="edit"
                          onClick={() => setEditItem(item)}
                          disabled={editItem?true:false}
                        />
                        {item.reserveByUserId!==0 &&
                          <CustomIconButton 
                            type="delete"
                            onClick={() => handleModal(item.id)}
                            disabled={editItem?true:false}
                          />
                        }
                      </React.Fragment>
                      )
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this reservation? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
