// Tag User from other Farms
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import Alert from "shared-components/Modal/Alert";
import IconButton from "shared-components/Button/IconButton";

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateExistingRequest, resetExisting } from ".";
import { TaggedUsersToFarm } from "services/AdminPortal/UserService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/userStyle.js";

const useStyles = makeStyles(styles);
export default function TagExistingUsers(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const existingUsers = useSelector(store => store.admin.user.existingUsers);
  const tagResult = useSelector(store => store.admin.user.tagResult);
  const [userIndex, setUserIndex] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [openSummaryModal, setOpenSummaryModal] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    let payload = Object.assign([], existingUsers);
    payload[e.target.id] = e.target.value;
    dispatch(updateExistingRequest(payload));
  };

  const onButtonClick_add = () => {
    dispatch(setDirty(true));
    let payload = Object.assign([], existingUsers);
    payload.push("");
    dispatch(updateExistingRequest(payload));
  };
  
  const handleModal_delete = (index) => {
    setUserIndex(index);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(setDirty(true));
    let payload = Object.assign([], existingUsers);
    payload.splice(userIndex, 1);
    setOpenDeleteModal(false);
    dispatch(updateExistingRequest(payload));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(TaggedUsersToFarm())
      .then(({error}) => {
        if (error) {
          dispatch(setDirty(false));
        } else {
          setOpenSummaryModal(true);
        }
      });
    }
  }
  
  const handleButtonClick_exit = () => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
    } else {
      props.setTagExisting(false);
    }
  };

  const validateFields = () => {
    for (let i = 0; i < existingUsers.length; i++) {
      if (!validate.isEmail(existingUsers[i])) {
        setErrorMsg({field: i, msg: "Please enter a valid email address"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    // componentDidUnmount
    return () => {
      setErrorMsg(false);
      dispatch(setDirty(false));
      dispatch(resetNav());
      dispatch(resetExisting());
    }
  },[]);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const farm = _.find(userDetail.farms, "isActive");
  const successList = _.filter(tagResult, "isSuccess")
  const failList = _.filter(tagResult, ["isSuccess", false])
  return (
    <React.Fragment>
      <Card
        title="Tag Existing Users"
        classes={{
          content: classes.existingContent
        }}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleButtonClick_exit()}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Paper className={classes.existingPaper} elevation={0}>
          { existingUsers.map((user, index) => {
            return (
              <React.Fragment key={index}>
                <Typography className={classes.existingLabel}>Existing User {index+1}</Typography>
                <div className={classes.existingAction}>
                  <TextField 
                    id={""+index}
                    className={classes.textfield}
                    variant="outlined" 
                    inputProps={{ maxLength: 64 }}
                    placeholder="Add in email address"
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(user)}
                    errorMsg={errorMsg}
                  />
                  { index > 0 && 
                    <IconButton
                      type="delete" 
                      onClick={() => handleModal_delete(index)}
                    />
                  }
                </div>
              </React.Fragment>
            )
          })}
          <Button 
            className={classes.existingButton} 
            startIcon={<AddIcon />} 
            onClick={() => onButtonClick_add()}
          >
            Tag More Users
          </Button>
        </Paper>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this user? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        className={classes.summaryModal}
        classes={{
          content: classes.summaryContent
        }}
        open={openSummaryModal}
        onClose={() => props.setTagExisting(false)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Summary"
        content={
          <React.Fragment>
            { successList.length > 0 &&
              <React.Fragment>
                <Typography className={classes.summaryLabel}>Successfully added following users to {farm.farmName}:</Typography>
                <List>
                  { successList.map((result, index) => {
                    return (
                      <ListItem key={index}>{result.user.userName}</ListItem>
                    )
                  })}
                </List>
              </React.Fragment>
            }
            { failList.length > 0 &&
              <React.Fragment>
                <Typography className={classes.summaryLabel}>Failed to add following users:</Typography>
                <List>
                  { failList.map((result, index) => {
                    if (result.user) {
                      return <ListItem key={index}>{result.user.userName}: {result.reason}</ListItem>
                    } else {
                      return <ListItem key={index}>{result.reason}</ListItem>
                    }
                  })}
                </List>
              </React.Fragment>
            }
          </React.Fragment>
        }
        actions={
          <Button className={classes.button} onClick={() => props.setTagExisting(false)}>OK</Button>
        }
      />
      <Alert open={openAlertModal} onConfirm={()=>props.setTagExisting(false)} onCancel={()=>handleButtonClick_exit()} />
    </React.Fragment>
  );
}
