// Device
// Location Details
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { filterActionTableHead } from "common/functions";
import { locationTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllDevice } from "services/AdminPortal/DeviceService";
import { DeleteDeviceLocation } from "services/AdminPortal/DeviceTaggingService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/deviceStyle.js";

const useStyles = makeStyles(styles);
export default function Location(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const handleModal_delete = () => {
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteDeviceLocation(props.locationDetail.id))
    .then(() => {
      props.setLocationDetail(false);
      dispatch(GetAllDevice());
    })
  }

  return (
    <React.Fragment>
      <Card 
        className={classes.cardRadius}
        title = "Location"
        cardActions={
          <Button
            className={classes.buttonSecondary}
            onClick={()=>props.setLocationDetail(false)}
          >
            Go Back
          </Button>
        }
      >
        <Table
          className={classes.table}
          header={filterActionTableHead(locationTableHead, props.canWrite)}
        >
          <TableRow>
            <TableCell>{props.locationDetail.deviceRefNo}</TableCell>
            <TableCell>{props.locationDetail.cageLocationRefNo}</TableCell>
            <TableCell>Floor {props.locationDetail.floorNumber}</TableCell>
            <TableCell>Sector {props.locationDetail.sectorNumber}</TableCell>
            <TableCell align="right">
              {props.canWrite &&
                // Jump to Device Tagging Step 4
                <React.Fragment>
                  <Link to={{pathname: "/admin/device-tagging/edit", state: {step: 3, deviceId: props.locationDetail.id, deviceRefNo: props.locationDetail.deviceRefNo, location: true}}}>
                    <IconButton type="edit" />
                  </Link>
                  <IconButton 
                    type="delete"
                    onClick={() => handleModal_delete()}
                  />
                </React.Fragment>
              }
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this location? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
