// Create Edit Device Settings
// Step 2: Device List
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Autocomplete from "shared-components/Select/Autocomplete";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { isUnique } from "common/validations";
import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { deviceIDTableHead } from "enums/AdminPortal/TableHeaders";
import { updateRequest } from ".";
import { GetAllDevice } from "services/AdminPortal/DeviceService";
import { CreateOrUpdateManufacturer } from "services/AdminPortal/ManufacturerService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/manufacturerStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const manufacturer = useSelector(store => store.admin.manufacturer.manufacturer);
  const originalDeviceList = useSelector(store => store.admin.manufacturer.originalDeviceList);
  const devices = useSelector(store => store.admin.manufacturer.devices);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [deviceIndex, setDeviceIndex] = React.useState(null);

  const handleOnChange_text = (e, index) => {
    let deviceList = _.cloneDeep(manufacturer.deviceList);
    deviceList[index] = {...deviceList[index], [e.target.id] : e.target.value};
    dispatch(updateRequest({deviceList}));
  };

  const handleOnChange_autocomplete = (newValue, index) => {
    let deviceDetail = devices.find((item) => item.deviceRefNo === newValue);
    if (deviceDetail) {
      let deviceList = _.cloneDeep(manufacturer.deviceList);
      deviceList[index] = {
        id : deviceDetail.id, 
        deviceRefNo: deviceDetail.deviceRefNo,
        hardwareId: deviceDetail.manufacturerID
      };
      dispatch(updateRequest({deviceList}));
    }
  };

  const handleModal = (id) => {
    setDeviceIndex(id);
    setOpenModal(!openModal);
  }

  const handleDelete = () => {
    setOpenModal(!openModal);
    let deviceList = _.cloneDeep(manufacturer.deviceList);
    deviceList.splice(deviceIndex, 1);
    dispatch(updateRequest({deviceList}));
    setErrorMsg(false);
  }

  const handleButtonClick_add = () => {
    dispatch(updateRequest({deviceList: [...manufacturer.deviceList, {}]}));
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      const deletedDevices = originalDeviceList.filter((id) => !reducedList.has(id));
      let deviceList = _.cloneDeep(manufacturer.deviceList);
      deviceList = [...deviceList, ..._.map(deletedDevices, (id)=>{return {id, isDeleted: true}})];
      Promise.all([
        dispatch(setDirty(false)),
        dispatch(updateRequest({deviceList}))
      ])
      .then(() => {
        dispatch(CreateOrUpdateManufacturer())
        .then(({error}) => {
          if (error) {
            dispatch(setDirty(true));
          } else {
            history.push("/admin/device-settings");
          }
        });
      })
    }
  };

  const validateFields = () => {
    for (let i = 0; i < manufacturer.deviceList.length; i++) {
      const device = manufacturer.deviceList[i];
      if (_.isEmpty(device)) {
        setErrorMsg({field: "id"+i, msg: "Please select a device"});
        return false;
      }
      if (!isUnique(device.id)) {
        setErrorMsg({field: "id"+i, msg: "Selected devices must be unique"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllDevice());
  },[]);
  
  // remove from list if selected
  const reducedList = manufacturer.deviceList.reduce((item,{id}) => item.add(id), new Set());
  const deviceLookup = devices.filter(({id}) => !reducedList.has(id));
  return (
    <React.Fragment>
      <Card 
        title="Device IDs List"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>props.setStep(0)}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Typography>
          Choose the Device ID which you have created previously in the Device Module. Key in the Reference ID which is the ID used to identify the relevant readings on the 3rd party side. If you have multiple readings for 1 device, you can key in all the reference IDs separated by a comma.
          <br /><br />
          For example, for a device for SE-CHT-00001, you can put in &quot;ID1, ID2, ID3&quot; which will correspondingly refer to C (CO2), H (Humidity), T (Temperature).
        </Typography>
        <Table
          className={clsx(classes.table, classes.deviceTable)}
          header={deviceIDTableHead}
        >
          { manufacturer.deviceList.map((item, index) => {
            const deviceList = item.id ? [{id: item.id, deviceRefNo: item.deviceRefNo}, ...deviceLookup] : deviceLookup;
            return (
              <TableRow key={index}>
                <TableCell className={clsx(classes.textfieldCell, classes.deviceCell)}>
                  <Autocomplete
                    id={"id"+index}
                    inputProps={{ maxLength: 50 }}
                    value={item.deviceRefNo}
                    onChange={(e, newValue) => {
                      handleOnChange_autocomplete(newValue, index);
                    }}
                    options={deviceList.map((listItem) => listItem.deviceRefNo)}
                    placeholder="Select a device"
                    errorMsg={errorMsg}
                  />
                </TableCell>
                <TableCell className={classes.textfieldCell}>
                  <TextField 
                    id="hardwareId"
                    variant="outlined" 
                    inputProps={{ maxLength: 100 }}
                    placeholder="Enter hardware ID"
                    onChange={(e) => handleOnChange_text(e, index)}
                    value={renderValue(item.hardwareId)}
                  />
                </TableCell>
                <TableCell className={classes.textfieldCell}>
                  <TextField 
                    id="referenceId"
                    variant="outlined" 
                    inputProps={{ maxLength: 100 }}
                    placeholder="Enter reference ID"
                    onChange={(e) => handleOnChange_text(e, index)}
                    value={renderValue(item.referenceId)}
                  />
                </TableCell>
                <TableCell className={classes.textfieldCell}>
                  <TextField 
                    id="otherIds"
                    variant="outlined" 
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => handleOnChange_text(e, index)}
                    value={renderValue(item.otherIds)}
                  />
                </TableCell>
                <TableCell className={classes.textfieldCell}>
                  <IconButton 
                    type="delete"
                    className={classes.cellButton}
                    onClick={() => handleModal(index)}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        <Button
          className={clsx(classes.buttonSecondary, classes.addButton)}
          startIcon={<AddIcon />}
          onClick={() => handleButtonClick_add()}
        >
          Add Device
        </Button>
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you want to delete the device's relationship with the manufacturer? The process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleDelete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  )
}
