// Create Edit Product
// Step 3: Create Raw Material
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import IconButton from "shared-components/Button/IconButton";

import { isPositiveDecimal } from "common/validations";
import { productRawMaterialConfigurationTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";
import { setValues, updateSubTable } from "./store/product";
import { GetProductRawMaterial } from "services/AdminPortal/ProductService";

const useStyles = makeStyles(styles);

export default function CreateRawMaterial(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const selectedPreHarvestProcess = useSelector(store => store.admin.product.product.productPreHarvestGrowthProcess);
  const selectedPostHarvestProcess = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const rawMaterial = useSelector(store => store.admin.product.product.subTable);
  const process = useSelector(store => store.admin.product.product.process);
  const allRawMaterial = useSelector(store => store.admin.product.lookup.rawMaterialList);
  const [isAdd, setIsAdd] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  var filteredRMList = [];

  //filter rawmaterial and equipment list
  if (!props.isFromVersion) {
    const reducedRMList = process.productRawMaterial && process.productRawMaterial.reduce((item,{rawMaterialName}) => item.add(rawMaterialName), new Set());
    filteredRMList = reducedRMList ? allRawMaterial.filter(({rawMaterialName}) => !reducedRMList.has(rawMaterialName)) : allRawMaterial;
  }

  const handleOnChange_select = (e) => {
    const rm = _.find(allRawMaterial, {'rawMaterialId': e.target.value});
    let payload = _.cloneDeep(rawMaterial);
    payload.uomrm = rm.unitOfMeasurement;
    dispatch(setValues({subTable: {
      ..._.omit(rm, ["farmId", "unitOfMeasurement"]),
      ...payload
    }}))
  };

  const handleOnChange_text = (e) => {
    dispatch(updateSubTable({[e.target.id]: e.target.value}))
  };

  const handleButtonClick_add = () => {
    let payload = _.cloneDeep(process);
    if (payload.productRawMaterial) {
      payload.productRawMaterial.push({});
    } else {
      payload.productRawMaterial = [{}];
    }
    dispatch(setValues({process: payload}));
    setEditIndex(payload.productRawMaterial.length-1);
    setIsAdd(true);
    props.setEditMode(true);
  }

  const handleButtonClick_edit = (item, index) => {
    dispatch(setValues({subTable: item}));
    setEditIndex(index);
    props.setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      let payload = _.cloneDeep(process);
      const obj = {
        ...rawMaterial,
        uomProduct: props.type === "productPreHarvestGrowthProcess" ? "Per Tray" : "Per Kg",
      }
      payload.productRawMaterial[editIndex] = obj;

      // update the parent array 
      let processPayload = _.cloneDeep(selectedPostHarvestProcess);
      if (props.type === "productPreHarvestGrowthProcess") {
        processPayload = _.cloneDeep(selectedPreHarvestProcess);
      }
      processPayload[props.growthEditIndex] = payload;
      dispatch(setValues({[props.type]: processPayload, process: payload}));
      
      resetState();
    }
  }

  const handleButtonClick_cancel = () => {
    if (!rawMaterial.id) {
      let payload = _.cloneDeep(process);
      payload.productRawMaterial.splice(editIndex, 1);
      dispatch(setValues({process: payload}));
    }
    resetState();
  }

  const handleModal = (index) => {
    setEditIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    let payload = _.cloneDeep(process);
    payload.productRawMaterial.splice(editIndex, 1);
    // update the parent array 
    let processPayload = _.cloneDeep(selectedPostHarvestProcess);
    if (props.type === "productPreHarvestGrowthProcess") {
      processPayload = _.cloneDeep(selectedPreHarvestProcess);
    }
    processPayload[props.growthEditIndex] = payload;
    dispatch(setValues({[props.type]: processPayload, process: payload}));
    setOpenModal(!openModal);
    resetState();
  }

  const resetState = () => {
    setErrorMsg(false);
    setIsAdd(false);
    setEditIndex(null);
    dispatch(setValues({subTable: {}}));
    props.setEditMode(false);
  }

  const validateFields = () => {
    if (!rawMaterial.rawMaterialId) {
      setErrorMsg({field: "rawMaterialId", msg: "Please select a value"});
      return false;
    }
    if (!rawMaterial.qtyrm) {
      setErrorMsg({field: "qtyrm", msg: "Please enter a quantity"});
      return false;
    }
    if (!isPositiveDecimal(rawMaterial.qtyrm)) {
      setErrorMsg({field: "qtyrm", msg: "Please enter a valid quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const rawMaterialTable = () => {
    let component = [];
    process.productRawMaterial && process.productRawMaterial.map((item,index) => {
      const selected = _.find(allRawMaterial, (obj) => obj.rawMaterialName === item.rawMaterialName);
      const rawMaterialList = item.rawMaterialName ? [selected, ...filteredRMList] : filteredRMList;
      component.push(
        <TableRow key={index}>
          <TableCell>{props.type === "productPreHarvestGrowthProcess" ? "Per Tray" : "Per Kg"}</TableCell>
          <TableCell>
            { isAdd && editIndex === index
            ? 
            <Select
              name="rawMaterialId"
              className={classes.alignTextfield}
              onChange={(e)=>handleOnChange_select(e)}
              value={rawMaterial.rawMaterialId}
              placeholder="Please select a raw material"
              errorMsg={errorMsg}
            >
              {rawMaterialList && rawMaterialList.map((rm) => {
                return <MenuItem key={rm.id} value={rm.rawMaterialId}>{rm.rawMaterialName}</MenuItem>;
              })} 
            </Select>
            : 
            item.rawMaterialName
            }
          </TableCell>
          <TableCell>
            { editIndex === index
            ? rawMaterial.rawMaterialRefNo
            : item.rawMaterialRefNo}
          </TableCell>
          <TableCell>
            { editIndex === index
            ? rawMaterial.uomrm
            : item.uomrm}
          </TableCell>
          <TableCell>
            { editIndex === index
            ?
            <TextField 
              id="qtyrm"
              className={classes.alignTextfield}
              variant="outlined" 
              //inputProps={{ maxLength: 30 }}
              onChange={(e) => handleOnChange_text(e)}
              value={rawMaterial.qtyrm}
              errorMsg={errorMsg}
            />
            :
            item.qtyrm
            }
          </TableCell>
          <TableCell>
            {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess"))
            ? (editIndex === index)
              ? <React.Fragment>
                  <IconButton 
                    type="confirm"
                    onClick={() => handleButtonClick_confirm(index)}
                  />
                  <IconButton 
                    type="close"
                    onClick={() => handleButtonClick_cancel()}
                  />
                </React.Fragment>
              : <React.Fragment>
                  <IconButton
                    type="edit" 
                    onClick={() => handleButtonClick_edit(item, index)}
                    disabled={props.editMode}
                  />
                  <IconButton 
                    type="delete"
                    onClick={() => handleModal(index)}
                    disabled={props.editMode}
                  />
                </React.Fragment>
            : <TableCell />
            }
          </TableCell>
        </TableRow>
      )
    })
    return component;
  }

  //componentDidMount
  React.useEffect(() => {
    dispatch(GetProductRawMaterial(props.type));
  },[]);

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.rawMaterialTable}>
        <Table
          className={classes.table}
          header={productRawMaterialConfigurationTableHead}
        >
          {rawMaterialTable()}
        </Table>
        {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess")) //check if user is in a non mix flow, or in a postharvest mix flow
        &&
        <Button
          className={clsx(classes.buttonSecondary, classes.addMoreProcess, classes.rawMaterialButton)}
          onClick={()=>handleButtonClick_add()}
          disabled={props.editMode}
        >
          + Add Raw Material
        </Button>
        }
        {!props.isFromVersion
        && <React.Fragment>
            <br/><br/>
            <Link to={{pathname: "/admin/raw-material"}} target="_blank" className={classes.link}>Go to Raw Material Creation to add new Raw Material</Link>
          </React.Fragment>
        }
      </Paper>
      <Modal
				open={openModal}
				onClose={() => handleModal(null)}
				icon={<img className={classes.icon_64} src={alert} alt="alert" />}
				title="Are you sure?"
				content="Do you really want to delete this raw material? This process cannot be undone."
				actions={
					<React.Fragment>
						<Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
						<Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
					</React.Fragment>
				}
			/>
    </React.Fragment>
  )
}
