import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { 
  GetBatchIDList, 
  GetProductList,
  GetTrayPosition, 
  GetAllRandDReports, 
  GetBoardType, 
  GetNutrientCategory, 
  GetRandDReportDetail, 
  GetQCDetailsFromOperation,
  GetGerminationDetailByWorkOrderIdAndProductId,
  CalculateNurseryDuration, 
  CalculateGrowthDuration, 
  CalculateTotalNetWeight, 
  CalculateAverageNetWeightPerPlant, 
  CalculateNutrientsVolPerPlant,
  CalculateActualNutrientsVolPerPlant,
  CalculateActualNutrientsVolPerTray,
  GetProductInfoById, 
  CalculateDatesByWorkOrderIdAndProductId,
  GeneratePlantMatrixFormat,
  GetSeedSupplierNameByBatchID
} from "services/UserPortal/QualityControlService";

// initial state
const initialState = {
  filter: {},
  //vege
  totalCount: 0,
  result: [],
  qualityControl: {healthConditions: []}, // for create/edit
  totalHorizontal: 0,
  totalVertical: 0,
  // lookup
  boardTypeList: [],
  trayPositionList: [],
  nutrientCatList: [],
  batchIDList: [],
  productList: []
};

const rndVegetable = createSlice({
  name: "rndVegetable",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.qualityControl[key] = action.payload[key];
      })
    },
    updateHCRequest: (state, action) => {
      state.qualityControl.healthConditions[action.payload.index].netWeight = action.payload.value;
    },
    reset: () => initialState,
  },
  extraReducers: {
    //select work order to work on
    [GetAllRandDReports.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.result = action.payload.result.items;
    }, 
    [GetRandDReportDetail.fulfilled]: (state, action) => {
      state.qualityControl = {
        plantMatrices: action.payload.result.plantMatrices && action.payload.result.plantMatrices.plantMatrixList,
        ..._.omit(action.payload.result, ["plantMatrices"]),
      };
      state.totalHorizontal = action.payload.result.plantMatrices && action.payload.result.plantMatrices.totalHorizontal;
      state.totalVertical = action.payload.result.plantMatrices && action.payload.result.plantMatrices.totalVertical;
    },
    // lookup
    [GetBoardType.fulfilled]: (state, action) => {
      state.boardTypeList = action.payload.result;
    },
    [GetTrayPosition.fulfilled]: (state, action) => {
      state.trayPositionList = action.payload.result;
    },
    [GetNutrientCategory.fulfilled]: (state, action) => {
      state.nutrientCatList = action.payload.result;
    },
    [GetBatchIDList.fulfilled]: (state, action) => {
      state.batchIDList = action.payload.result;
    }, 
    [GetProductList.fulfilled]: (state, action) => {
      state.productList = action.payload.result;
    },
    // calculation
    [GetQCDetailsFromOperation.fulfilled]: (state, action) => {
      state.qualityControl = {...state.qualityControl, ...action.payload.result};
    }, 
    [GetGerminationDetailByWorkOrderIdAndProductId.fulfilled]: (state, action) => {
      state.qualityControl = {...state.qualityControl, ..._.omit(action.payload.result, ['workOrderId', 'productId', 'germinateDate', 'transplantDate', 'nurseryDuration', 'id', 'initialEC', 'initialPHLevel', 'initialTDS', 'initialWaterLevel', 'finalEC', 'finalPHLevel', 'finalTDS', 'finalWaterLevel', 'trayPosition', 'trayPositionId'])};
    }, 
    [CalculateNurseryDuration.fulfilled]: (state, action) => {
      state.qualityControl.nurseryDuration = action.payload.result;
    }, 
    [CalculateGrowthDuration.fulfilled]: (state, action) => {
      state.qualityControl.growthDuration = action.payload.result;
    }, 
    [CalculateTotalNetWeight.fulfilled]: (state, action) => {
      state.qualityControl.totalNetWeight = action.payload.result;
    }, 
    [CalculateAverageNetWeightPerPlant.fulfilled]: (state, action) => {
      state.qualityControl.avrNetWeightPerPlant = action.payload.result;
    }, 
    [CalculateNutrientsVolPerPlant.fulfilled]: (state, action) => {
      state.qualityControl.nutrientsVolPerPlant = action.payload.result;
    },
    [CalculateActualNutrientsVolPerPlant.fulfilled]: (state, action) => {
      state.qualityControl.actualNutrientsVolPerPlant = action.payload.result;
    },
    [CalculateActualNutrientsVolPerTray.fulfilled]: (state, action) => {
      state.qualityControl.actualNutrientsVolPerTray = action.payload.result;
    },
    [GetProductInfoById.fulfilled]: (state, action) => {
      state.qualityControl.vegetableVariety = action.payload.result.vegetableVariety;
    },
    [CalculateDatesByWorkOrderIdAndProductId.fulfilled]: (state, action) => {
      state.qualityControl.germinateDate = action.payload.result.germinateDate;
      state.qualityControl.transplantDate = action.payload.result.transplantDate;
      state.qualityControl.harvestDate = action.payload.result.harvestDate;
    },
    [GeneratePlantMatrixFormat.fulfilled]: (state, action) => {
      state.totalHorizontal = action.payload.result.totalHorizontal;
      state.totalVertical = action.payload.result.totalVertical;
      state.qualityControl.plantMatrices = action.payload.result.plantMatrixList;
    },
    [GetSeedSupplierNameByBatchID.fulfilled]: (state, action) => {
      state.qualityControl.supplierName = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateHCRequest, reset } = rndVegetable.actions;

// export the reducer
export default rndVegetable.reducer;