import React from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// core components
import DateTabs from "shared-components/Tabs/DateTabs";

import styles from "assets/jss/components/UserPortal/smartMeteringStyle.js";

const useStyles = makeStyles(styles);

export default function GraphScale({filter, setValues, ...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hourTab, setHourTab] = React.useState(false);
  const [date, setDate] = React.useState({startDate: moment(filter.startDate).format(), endDate: moment(filter.endDate).format()});

  const renderAggregation = () => {
    let components = [];
    const aggregationScales = [{label: "10 Minutes", value: ''}, {label: "1 Hour", value: 1}, {label: "3 Hours", value: 3}, {label: "12 Hours", value: 12}];
    for (const scale of aggregationScales) {
      components.push(
        <Typography key={scale} className={props.aggregationScale.value === scale.value ? clsx(classes.clickable, classes.orange) : classes.clickable} onClick={()=>props.setAggregationScale(scale)}>{scale.label}</Typography>
      );
    }
    return components;
  }

  const handleOnChange_hourTab = (e, value) => {
    if (value === 0) {  // 1hr
      setDate({startDate: moment().subtract(1,'hours').format(), endDate: moment().format()});
    } else if (value === 1) {  // 3hr
      setDate({startDate: moment().subtract(3, 'hours').format(), endDate: moment().format()});
    } else if (value === 2) {  // 12hr
      setDate({startDate: moment().subtract(12,'hours').format(), endDate: moment().format()});
    } else if (value === 3) {  // 24hr
      setDate({startDate: moment().subtract(24,'hours').format(), endDate: moment().format()});
    }
    setHourTab(value);
  };

  // const renderTime = () => {
  //   let components = [];
  //   const timeScales = ["Year", "Month", "Week", "Day"];
  //   for (const scale of timeScales) {
  //     components.push(
  //       <Typography key={scale} className={props.timeScale === scale ? clsx(classes.clickable, classes.orange) : classes.clickable} onClick={()=>props.setTimeScale(scale)}>{scale}</Typography>
  //     );
  //   }
  //   return components;
  // }
  
  React.useEffect(() => {
    dispatch(setValues({filter: {...filter, ...date}}));
  },[date]);

  return (
    <div className={classes.scaleComponent}>
      { moment.duration(moment(filter.endDate).diff(moment(filter.startDate))).asHours() <= 24 &&
        <div className={classes.aggregationContainer}>
          <Typography className={classes.label}>Aggregation Scale</Typography>
          <Paper className={classes.paper} elevation={0}>
            {renderAggregation()}
          </Paper>
        </div>
      }
      <div>
        <div className={classes.dateContainer}>
          <DateTabs setDate={setDate} date={date} hourTab={hourTab} onChange={()=>setHourTab(false)} />
        </div>
        <Tabs 
          className={classes.dateTab}
          classes={{
            indicator: classes.dateIndicator
          }}
          value={hourTab} 
          onChange={handleOnChange_hourTab}
        >
          <Tab className={hourTab === 0 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<1hr" />
          <Tab className={hourTab === 1 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<3hr" />
          <Tab className={hourTab === 2 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<12hr" />
          <Tab className={hourTab === 3 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<24hr" />
        </Tabs> 
      </div>
      {/* <div className={classes.dateScale}> */}
        {/* <Typography className={classes.label}>Time Scale</Typography>
        <Paper className={classes.paper} elevation={0}>
          {renderTime()}
        </Paper> */}
        {/* <DateRangePicker
          clearable
          placeholder="Date"
          className={classes.cardActionDate}
          value={{startDate: filter.startDate, endDate: filter.endDate}}
          onChange={(e) => handleOnChange_date(e)}
          onClear={()=>dispatch(setValues({filter: {...filter, startDate: null, endDate: null}}))}
        />
      </div> */}
    </div>
  );
}
