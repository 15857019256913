import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { GetAllDevice } from "services/AdminPortal/DeviceService";
import { GetManufacturer, GetAllManufacturer, GetIntegrationTypes } from "services/AdminPortal/ManufacturerService";

// initial state
const initialState = {
  totalCount: 0,
  manufacturers: [],
  manufacturer: {
    deviceList: []
  },
  originalDeviceList: [],
  integrationTypes: [],
  devices: []
};

const manufacturer = createSlice({
  name: "manufacturer",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.manufacturer = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.manufacturer[key] = action.payload[key];
      })
    },
    resetManufacturer: (state) => {
      state.manufacturer = {};
      state.originalDeviceList = [];
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetManufacturer.fulfilled]: (state, action) => {
      state.manufacturer = action.payload.result;
      state.originalDeviceList = action.payload.result.deviceList.map(({id}) => id);
    },
    [GetAllManufacturer.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.manufacturers = action.payload.result.items;
    },
    [GetIntegrationTypes.fulfilled]: (state, action) => {
      state.integrationTypes = action.payload.result;
    },
    [GetAllDevice.fulfilled]: (state, action) => {
      state.devices = _.orderBy([...action.payload.result.deviceFinishList.items, ...action.payload.result.deviceUnfinishList.items], ["deviceRefNo"], ["asc"]);
    },
  },
});

// export actions
export const { setValues, updateRequest, resetManufacturer, reset } = manufacturer.actions;

// export the reducer
export default manufacturer.reducer;