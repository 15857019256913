import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { checkPermission, formatNumbers, roundTo2Decimal, renderValue, filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/labourCost";
import { labourCostTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllLabourCost } from "services/UserPortal/HumanResourceService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import styles from "assets/jss/components/UserPortal/hrStyle.js";

const useStyles = makeStyles(styles);

export default function LabourCost() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const filter = useSelector(store => store.user.hr.labourCost.filter);
  const totalCount = useSelector(store => store.user.hr.labourCost.totalCount);
  const totalCost = useSelector(store => store.user.hr.labourCost.totalCost);
  const costs = useSelector(store => store.user.hr.labourCost.costs);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productRefNo');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const getAllLabourCost = (stopLoading) => {
    if (filter.workOrderId) {
      dispatch(GetAllLabourCost({...filter, stopLoading}));
    } else {
      dispatch(setValues({ totalCount: 0, totalCost: 0, costs: []}));
    }
  }

  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderId: workOrderList[0].id, productId: null, page: 0}}));
      } else {
        dispatch(setValues({filter: {workOrderId: null, page: 0}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    if (newFilter.workOrderId) {
      dispatch(GetProductList(newFilter.workOrderId));
    }
  },[newFilter.workOrderId]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllLabourCost();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  useInterval(() => {
    getAllLabourCost(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title="Labour Cost"
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "workOrderId") {
                const workOrder = workOrderList.find(({id}) => id == filter[key]);
                label = workOrder && workOrder.workOrderNo;
              }
              if (key === "productId") {
                const product = productList && productList.find(({id}) => id == filter[key]);
                label = product && product.productName+" ("+product.productRefNo+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the cost list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                    disabled={!newFilter.workOrderId}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={labourCostTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { costs.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.productRefNo}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                    <TableCell>{item.productProcessName}</TableCell>
                    <TableCell>{item.taskNumber}</TableCell>
                    <TableCell>{item.userName}</TableCell>
                    <TableCell>{item.employeeRefNo}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.expectedDuration))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.actualDuration))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.expectedCost))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.actualCost))}</TableCell>
                    <TableCell>{item.differentPercent}</TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
            <TableRow>
              <TableCell colSpan={9} />
              <TableCell className={classes.bold}>Total Cost</TableCell>
              <TableCell className={classes.bold} colSpan={2}>{formatNumbers(roundTo2Decimal(totalCost))}</TableCell>
            </TableRow>
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
        {checkPermission("Admin.Human Resource", "readEdit") &&
          <Typography className={classes.link}>
            <Link to={{pathname: "/admin/human-resource/user-configuration"}}>Go to “Human Resource Management” Module in the admin portal to configure hourly rates of employees</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
