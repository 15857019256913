import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import ListItem from "@mui/material/ListItem";
// core components
import IconButton from "shared-components/Button/IconButton";

import updown from "assets/icons/orange/up-down.svg";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);
export default function DragList({ id, item, index, onMoveItem, onDropItem, ...props }) {
  const classes = useStyles();
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "div",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop() {
      onDropItem();
    }
  });
  const [, drag] = useDrag({
    type: "div",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const handleOnClick_delete = () => {
    const payload = _.cloneDeep(props.stops);
    payload.splice(index, 1);
    props.setStops(payload);
  }

  return (
    <ListItem ref={ref} data-handler-id={handlerId} className={classes.orderListItem}>
      {index+1}. {item.deliveryOrderRefNo}
      <img className={clsx(classes.icon_24, classes.iconMargin)} src={updown} alt="updown" />
      <IconButton 
        type="close"
        onClick={() => handleOnClick_delete()}
      />
    </ListItem>
  );
}
