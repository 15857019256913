import { createSlice } from "@reduxjs/toolkit";

import { SearchCapacity } from "services/AdminPortal/CapacityService";

// initial state
const initialState = {
  searchResultsCount: {},
  searchResults: {},
};

const searchCapacity = createSlice({
  name: "searchCapacity",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [SearchCapacity.fulfilled]: (state, action) => {
      Object.keys(action.payload.result).forEach((key) => {
        if (key !== "farmCapacitySummary") {
          if (action.payload.result[key]) {
            state.searchResultsCount[key] = action.payload.result[key].totalCount;
            state.searchResults[key] = action.payload.result[key].items;
          }
        } else {
          state.farmCapacitySummary = action.payload.result.farmCapacitySummary;
        }
      })
    },
  },
});

// export actions
export const { reset } = searchCapacity.actions;

// export the reducer
export default searchCapacity.reducer;