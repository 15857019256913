// Customer Order => Sales Order
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Autocomplete from "shared-components/Select/Autocomplete";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import Empty from "error-page/Empty";
import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { renderValue, filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/management";
import { customerOrderTableHead, subCustomerOrderTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllSaleOrders, GetSaleOrderStatusLookUp, GetPurchaseOrderLookUp, GetSaleOrderLookUp, GetCustomerList } from "services/UserPortal/CustomerOrderService";

import Helper from "./Helper";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/customerOrderStyle.js";

const useStyles = makeStyles(styles);

export default function Management() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const filter = useSelector(store => store.user.customerOrder.management.filter);
  const totalCount = useSelector(store => store.user.customerOrder.management.totalCount);
  const customerList = useSelector(store => store.user.customerOrder.management.customerList);
  const orders = useSelector(store => store.user.customerOrder.management.orders);
  const saleOrder = useSelector(store => store.user.customerOrder.management.saleOrder);
  const purchaseOrder = useSelector(store => store.user.customerOrder.management.purchaseOrder);
  const saleOrderStatus = useSelector(store => store.user.customerOrder.management.saleOrderStatus);
  const [newFilter, setNewFilter] = React.useState({customerId: null, saleOrderNumber: null, purchaseOrderNumber: null, saleOrderStatus: null, deliveryStartDate: null, deliveryEndDate: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [subOrder, setSubOrder] = React.useState('asc');
  const [subOrderBy, setSubOrderBy] = React.useState('subSaleOrderNumber');
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleSubRequestSort = (e, property) => {
    const isAsc = subOrderBy === property && subOrder === 'asc';
    setSubOrder(isAsc ? 'desc' : 'asc');
    setSubOrderBy(property);
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, deliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), deliveryEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_autocomplete = (newValue) => {
    setNewFilter({...newFilter, saleOrderNumber: newValue});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };
  
  const getAllSaleOrders = (stopLoading) => {
    dispatch(GetAllSaleOrders({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      setSubPage(0);
      getAllSaleOrders();
      setOpen(false);
    }
  },[filter]);

  React.useEffect(() => {
    setSubPage(0);
  },[open]);

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetCustomerList());
    dispatch(GetSaleOrderStatusLookUp());
    dispatch(GetPurchaseOrderLookUp());
    dispatch(GetSaleOrderLookUp());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllSaleOrders(true);
  });
  

  customerOrderTableHead[6].label = <Helper />;
  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      { totalCount === 0 && Object.values(filter).every(val => val === null)
      ? <Empty 
          description="Your page is empty. Add a sales order to get started!"
        />
      : <React.Fragment>
          <Card 
            title="List of Sales Orders"
            subheader={filteredKeys.length > 0 
            ? <React.Fragment>
                {filteredKeys.map((key) => {
                  let label = filter[key];
                  if (key === "customerId") {
                    const customer = customerList.find(({id}) => id == filter[key]);
                    label = customer && customer.customerName;
                  }
                  return (
                    <Tag
                      key={key}
                      variant="outlined"
                      tabIndex={-1}
                      label={label}
                      className={classes.tag}
                      onDelete={() => handleButtonClick_delete(key)}
                    />
                  )
                })}
              </React.Fragment>
            : null
            }
            action={
              <React.Fragment>
                <FilterButton 
                  setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
                  filter={filter}
                  setNewFilter={setNewFilter}
                  content={
                    <React.Fragment>
                      Choose one or all filters to search through the sales order list
                      <Select
                        className={classes.filterDropdown}
                        name="customerId"
                        onChange={(e)=>handleOnChange_select(e)}
                        placeholder="Select customer"
                        value={renderValue(newFilter.customerId)}
                      >
                        {customerList && customerList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.customerName} ({item.customerRefNo})</MenuItem>;
                        })} 
                      </Select>
                      <Autocomplete
                        className={classes.filterDropdown}
                        id="saleOrderNumber"
                        inputProps={{ maxLength: 50 }}
                        value={newFilter.saleOrderNumber}
                        onChange={(e, newValue) => {
                          handleOnChange_autocomplete(newValue);
                        }}
                        options={saleOrder}
                        placeholder="Select a sale order"
                      />
                      <Select
                        className={classes.filterDropdown}
                        name="purchaseOrderNumber"
                        onChange={(e)=>handleOnChange_select(e)}
                        placeholder="Select a purchase order"
                        value={renderValue(newFilter.purchaseOrderNumber)}
                      >
                        {purchaseOrder && purchaseOrder.map((item, index) => {
                          return <MenuItem key={index} value={item}>{item}</MenuItem>;
                        })} 
                      </Select>
                      <Select
                        className={classes.filterDropdown}
                        name="saleOrderStatus"
                        onChange={(e)=>handleOnChange_select(e)}
                        placeholder="Select a status"
                        value={renderValue(newFilter.saleOrderStatus)}
                      >
                        {saleOrderStatus && saleOrderStatus.map((item, index) => {
                          return <MenuItem key={index} value={item}>{item}</MenuItem>;
                        })} 
                      </Select>
                    </React.Fragment>
                  }
                />
                <DateRangePicker
                  clearable
                  placeholder="Delivery date"
                  className={classes.cardActionDate}
                  value={{startDate: filter.deliveryStartDate, endDate: filter.deliveryEndDate}}
                  onChange={(e) => handleOnChange_date(e)}
                  onClear={()=>dispatch(setValues({filter: {...filter, deliveryStartDate: null, deliveryEndDate: null, page: 0}}))}
                />
              </React.Fragment>
            }
          >
            <div className={classes.table}>
              <Table
                expandable
                header={customerOrderTableHead}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { orders.map((item,index) => {
                  const subCount = Math.ceil(item.subSaleOrders.length / rowsPerTable);
                  return (
                    <React.Fragment key={index}>
                      <TableRow 
                        hover
                        sx={{ cursor: 'pointer' }}
                        onClick={() => open === index? setOpen(false) : setOpen(index)}
                      >
                        <TableCell>
                          <IconButton 
                            className={open === index ? classes.rotate : null}
                            onClick={() => open === index? setOpen(false) : setOpen(index)}
                          >
                            <img className={classes.icon} src={arrow} alt="arrow" />
                          </IconButton>
                        </TableCell>
                        <TableCell>{item.saleOrderNumber}</TableCell>
                        <TableCell>{item.purchaseOrderNo}</TableCell>
                        <TableCell>{item.customerName}</TableCell>
                        <TableCell>{moment(item.deliveryDate).format("DD/MM/yyyy")}</TableCell>
                        <TableCell>{item.orderType}</TableCell>
                        <TableCell>{item.saleOrderStaus}</TableCell>
                        <TableCell><Link to={{pathname: "/user/sales-order/management/"+item.id}} className={classes.link}>Link</Link></TableCell>
                      </TableRow>
                      <TableRow>
                        { open===index && 
                          <TableCell className="pt-0 pb-0" colSpan={8}>
                            <Collapse in={open === index}>
                              <Table
                                className={classes.subTable}
                                header={subCustomerOrderTableHead}
                                order={subOrder}
                                orderBy={subOrderBy}
                                onRequestSort={handleSubRequestSort}
                              >
                                { _.orderBy(item.subSaleOrders, [subOrderBy], [subOrder])
                                .slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                                .map((subItem, subIndex) => {
                                  return (
                                    <TableRow key={subIndex}>
                                      <TableCell>{subItem.subSaleOrderNumber}</TableCell>
                                      <TableCell>{subItem.workOrderId ? subItem.workOrderId : "Not Issued"}</TableCell>
                                      <TableCell>{subItem.subSaleOrderStatus}</TableCell>
                                      <TableCell>{subItem.productComponent.split("(").join(" (")}</TableCell>
                                    </TableRow>
                                  )
                                })}
                              </Table>
                              { subCount > 1 &&
                                <Pagination 
                                  count={subCount} 
                                  page={subPage+1}
                                  onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                                />
                              }
                            </Collapse>
                          </TableCell>
                        }
                      </TableRow>
                    </React.Fragment>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={filter.page+1}
                onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
              />
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
