import { createSlice } from "@reduxjs/toolkit";

import { GetAllFinishedGoodsInAndOut, GetProductList } from "services/UserPortal/FinishedGoodsService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  out: [],
  checkOutList: [],
  disposeList: [],
  //lookup
  saleOrderList: [],
  productList: [],
};

const finishedGoodsOut = createSlice({
  name: "finishedGoodsOut",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllFinishedGoodsInAndOut.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.out = action.payload.result.items;
    },
    [GetProductList.fulfilled]: (state, action) => {
      state.productList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = finishedGoodsOut.actions;

// export the reducer
export default finishedGoodsOut.reducer;