import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImageMarker from 'react-image-marker';
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Select from "shared-components/Select/Select";

import { GetAllFloorPlan, GetSectorByFloorId, GetEquipmentByFloorandSectorIdOrBankId, GetEquipmentLocationFloorAndSectorView } from "services/UserPortal/MonitoringService";

import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function LocationView() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const allFloorPlan = useSelector(store => store.user.monitoring.equipment.allFloorPlan);
  const sector = useSelector(store => store.user.monitoring.equipment.sector);
  const equipmentList = useSelector(store => store.user.monitoring.equipment.equipment);
  const [filter, setFilter] = React.useState({floorId: null, sectorId: null, equipmentId: null});
  const [view, setView] = React.useState(false);
  const [markers, setMarkers] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState(false);
  
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "equipment");

  const handleOnChange_select = (e) => {
    setFilter({...filter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_view = () => {
    if (validateFields()) {
      setView(true);
      const param = {
        floorId: filter.floorId,
        sectorId: filter.sectorId,
        equipmentId: filter.equipmentId === "all" ? null : filter.equipmentId,
        equipmentType: path[index+1].replace("%20", " ")
      }
      dispatch(GetEquipmentLocationFloorAndSectorView(param))
      .then(({payload}) => {
        if (payload && payload.result) {
          let location = [];
          for (let obj of payload.result) {
            location = _.unionBy(location , [{ ..._.omit(obj, ["floorPlanY", "floorPlanX"]), top: obj.floorPlanY, left: obj.floorPlanX }], "id");
          }
          setMarkers(location);
        }
      });
    }
  };

  const validateFields = () => {
    if (!filter.floorId) {
      setErrorMsg({field: "floorId", msg: "Please select a floor"});
      return false;
    }
    if (!filter.sectorId) {
      setErrorMsg({field: "sectorId", msg: "Please select a sector"});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  React.useEffect(() => {
    if (filter.floorId) {
      dispatch(GetSectorByFloorId(filter.floorId));
      setFilter({...filter, sectorId: null, equipmentId: null});
      setView(false);
    }
  },[filter.floorId]);

  React.useEffect(() => {
    if (filter.sectorId) {
      const param = {
        floorId: filter.floorId,
        sectorId: filter.sectorId,
        equipmentType: path[index+1].replace("%20", " ")
      }
      dispatch(GetEquipmentByFloorandSectorIdOrBankId(param));
      setFilter({...filter, equipmentId: "all"});
      setView(false);
    }
  },[location.pathname, filter.sectorId]);

  React.useEffect(() => {
    dispatch(GetAllFloorPlan());
  },[]);

  const CustomMarker = (obj) => {
    if (obj) {
      return (
        <div className={classes.alarmContainer}>
          {obj.hasActiveAlarm &&
            <React.Fragment>
              <div className={classes.alarm}>
                <Typography className={classes.alarmTitle}>Alarm!</Typography>
                <Typography className={classes.alarmDetail} onClick={()=>history.push("/user/alarm/details/equipment")}>Click to view details</Typography>
              </div>
              <span className={classes.arrow} />
            </React.Fragment>
          }
          <div className={classes.markerDiv}>
            <div className={classes.marker}>{obj.equipmentRefNo.substr(0, obj.equipmentRefNo.indexOf('-'))}</div>
            <Typography className={classes.markerText}>{obj.equipmentRefNo}</Typography>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={classes.cardContainer}>
        <Typography className={classes.label}>Floor: </Typography>
        <Select
          name="floorId"
          placeholder="Select a floor"
          onChange={(e)=>handleOnChange_select(e)}
          value={filter.floorId}
          errorMsg={errorMsg}
        >
          {allFloorPlan && allFloorPlan.map((item, index) => {
            return <MenuItem key={index} value={item.id}>{item.floorNumber}</MenuItem>;
          })} 
        </Select>
        <Typography className={classes.label}>Sector: </Typography>
        <Select
          name="sectorId"
          placeholder="Select a sector"
          onChange={(e)=>handleOnChange_select(e)}
          value={filter.sectorId}
          disabled={!filter.floorId}
          errorMsg={errorMsg}
        >
          {sector && sector.map((item, index) => {
            return <MenuItem key={index} value={item.id}>{item.sectorNumber}</MenuItem>;
          })} 
        </Select>
        <Typography className={classes.label}>Equipment ID: </Typography>
        <Select
          name="equipmentId"
          placeholder="Select an equipment"
          onChange={(e)=>handleOnChange_select(e)}
          value={filter.equipmentId}
          disabled={!filter.floorId || !filter.sectorId}
        >
          <MenuItem key={index} value={"all"}>All Equipments</MenuItem>
          {equipmentList && equipmentList.map((item, index) => {
            return <MenuItem key={index} value={item.id}>{item.equipmentName + " (" + item.equipmentRefNo + ")"}</MenuItem>;
          })} 
        </Select>
        <Button 
          className={clsx(classes.button, classes.equipView)}
          onClick={()=>handleButtonClick_view()}
        >
          View
        </Button>
      </div>
      {view && 
        <ImageMarker
          extraClass={classes.floorPlanImg}
          src={filter.sectorId && sector.find(({id})=> id === filter.sectorId).floorPlanDiagramUrl}
          markers={markers}
          markerComponent={CustomMarker}
        />
      }
    </React.Fragment>
  );
}
