import {
  cardRadius,
  whiteColor,
  primaryColor,
  secondaryColor,
  buttonSecondary
} from "assets/jss/generaliseStyle.js";

const homeStyle = {
  cardContent: {
    padding: "2.083vw 1.667vw",
    "&:last-child": {
      paddingBottom: "2.083vw",
    }
  },
  cardTitle: {
    lineHeight: "1.302vw",
    fontWeight: "500",
    fontSize: "1.094vw",
    color: primaryColor[4],
    marginBottom: "1.042vw",
  },
  cardDescription: {
    fontSize: "0.833vw",
    lineHeight: "1.667vw",
    height: "1.563vw",
    fontWeight: "400",
    color: "#386538",
    marginBottom: "0.521vw",
  },
  cardData: {
    fontSize: "1.771vw",
    lineHeight: "1.667vw",
    height: "1.563vw",
    fontWeight: "400",
    color: primaryColor[4],
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "11.510vw",
    border: "0.104vw solid "+ secondaryColor[0],
    fontSize: "1.094vw",
    fontWeight: "500",
    color: primaryColor[3],
    textAlign: "center",
    "&:hover": {
      backgroundColor: secondaryColor[0],
      color: whiteColor,
    }
  },
  logoImage: {
    marginTop: "2.083vw",
    marginBottom: "1.094vw",
    width: "3.333vw",
    height: "3.333vw",
  },
  logoTitle: {
    padding: "0vw 1.771vw 2.083vw 1.823vw",
    lineHeight: "1.302vw",
  },
  topContainer: {
    paddingBottom: "1.667vw",
  },
  //newUserHomescreen
  newUserCard: {
    ...cardRadius,
    width: "82.188vw",
    height: "40.625vw"
  },
  newUserImage: {
    width: "18.229vw",
    height: "13.021vw",
    marginTop: "7.292vw",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "1.250vw",
  },
  newUserTitle: {
    textAlign: "center",
    fontWeight: "500",
    fontSize: "1.771vw",
    lineHeight: "2.075vw",
    marginBottom: "1.250vw",
    color: primaryColor[4],

  },
  newUserDescription: {
    width: "52.292vw",
    fontWeight: "400",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.094vw",
    lineHeight: "1.667vw",
    marginBottom: "1.250vw",
    color: primaryColor[4],
  },
  newUserButton: {
    ...buttonSecondary,
    width: "14.583vw",
    height: "3.125vw",
    marginLeft: "33.802vw",
  },
};

export default homeStyle;
