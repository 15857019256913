import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Tag from 'shared-components/Chip/Tag';
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import Modal from 'shared-components/Modal/Modal';

import { rowsPerTable } from "config";
import { deviceHistoryTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "store/common";
import { renderValue } from "common/functions";
import { GetAllDeviceUsageHistory, DownloadDeviceUsageHistory } from "services/UserPortal/ClimateService";
import { GetDeviceList } from "services/UserPortal/CommonLookupService";

import filterIcon from "assets/icons/orange/filter.svg";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');

export default function History(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceList = useSelector(store => store.common.deviceList);
  const bankList = useSelector(store => store.common.bankList);
  const floorSectorList = useSelector(store => store.user.climate.deviceControl.floorSectorList);
  const historyCount = useSelector(store => store.user.climate.deviceControl.historyCount);
  const history = useSelector(store => store.user.climate.deviceControl.history);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('ruleNo');
  const [page, setPage] = React.useState(0);
  const [devicePage, setDevicePage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [searchValue, setSearchValue] = React.useState("All Devices"); // for device list
  const [filter, setFilter] = React.useState({deviceId: null, actionStatus: null, startDate: null, endDate: null});
  const [newFilter, setNewFilter] = React.useState({deviceId: null, actionStatus: null, startDate: null, endDate: null});
  const [selectedDevice, setSelectedDevice] = React.useState({deviceRefNo: "All Devices", id: 0});
  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  const count = Math.ceil(historyCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleDownload = () => {
    dispatch(DownloadDeviceUsageHistory({
      bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
      floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
      sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector}))
    .then((response) => {
      FileDownload(response.payload, 'device_usage_history.xlsx');
    });
  };

  const handleModal_filter = () => {
    setOpenFilterModal(!openFilterModal);
    setNewFilter(filter);
  };

  const handleButtonClick_filter = () => {
    setOpenFilterModal(false);
    setFilter(newFilter);
  };

  const handleOnChange_date = (value) => {
    if (value) {
      setFilter({...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")});
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    if (key === "deviceId") {
      setSelectedDevice({deviceRefNo: "All Devices", id: 0});
    }
    setFilter({...filter, [key]: null});
  };
  
  const fetchMoreData = (search, pageNo) => {
    if (devicePage !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
        floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
        sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector,
        page: pageNo !== undefined ? pageNo : devicePage,
        DeviceRefNo: search ? search : "",
        IsControlDevice: true
      }
      dispatch(GetDeviceList(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result.items.length) {
            setDevicePage(pageNo !== undefined ? pageNo+1 : devicePage+1);
            if (search || devicePage === 0 || pageNo === 0) {
              dispatch(setValues({deviceList: response.payload.result.items}));
            } else {
              dispatch(setValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            setDevicePage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }
  
  React.useEffect(() => {
    setPage(0);
    setNewFilter({deviceId: null, actionStatus: null, location: null, startDate: null, endDate: null});
    setFilter({deviceId: null, actionStatus: null, location: null, startDate: null, endDate: null});
    dispatch(GetAllDeviceUsageHistory({
      bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
      floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
      sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector,
      page: 0}));
    const param = {
      page: 0,
      bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
      floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
      sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector,
      IsControlDevice: true
    }
    dispatch(GetDeviceList(param))
    .then((response) => {
      if (response.payload.result) {
        const result = response.payload.result.items
        dispatch(setValues({deviceList: result}));
        setDevicePage(1);
      }
    });
  },[props.tab, props.bankFloorTab]);

  React.useEffect(() => {
    setPage(0);
    dispatch(GetAllDeviceUsageHistory({
      ...filter,
      bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
      floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
      sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector, 
      page: 0}));
  },[filter]);

  React.useEffect(() => {
    dispatch(GetAllDeviceUsageHistory({
      ...filter,
      bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
      floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
      sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector, 
      page}));
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  const renderName = () => {
    const number = props.selectedFloorSector.name.replace("Floor ", "").split(" Sector ");
    if (Number(number[1]) === 1) {
      return 'Chamber ' + ((Number(number[0]) * (Number(number[1]) + 1))-1)
    } else if (Number(number[1]) === 2) {
      return 'Chamber ' + (Number(number[0]) * Number(number[1]))
    }
  }

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "url" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        classes={{
          root: classes.cardPaper,
        }}
        title={props.bankFloorTab ? renderName() : props.bank.bankName}
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "deviceId") {
                const device = deviceList.find(({id}) => id == filter[key]);
                label = device && device.deviceName + " (" + device.deviceRefNo + ")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null
        }
        action={
          <React.Fragment>
            <Button 
              className={classes.buttonSecondary}
              onClick={()=>handleModal_filter()}
              endIcon={<img className={classes.icon} src={filterIcon} alt="filter" />}
            >
              Filter
            </Button>
            <DateRangePicker
              clearable
              placeholder="Date"
              className={classes.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>setFilter({...filter, startDate: null, endDate: null})}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button disabled />
            <Button 
              className={classes.buttonSecondary}
              onClick={()=>handleDownload()}
            >
              Download CSV
            </Button>
          </React.Fragment>
        }
      >
        <Table
          className={classes.table}
          header={deviceHistoryTableHead}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          { history.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{moment(item.creationTime).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{moment(item.creationTime).format('HHmm')}</TableCell>
                    <TableCell>{item.location && item.location.locationDescription}</TableCell>
                    <TableCell>{item.deviceName}</TableCell>
                    <TableCell>{item.deviceRefNo}</TableCell>
                    <TableCell>{item.percentage}</TableCell>
                    <TableCell>{item.status}</TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })
          }
        </Table>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
      </Card>
      <Modal
        open={openFilterModal}
        onClose={() => handleModal_filter()}
        title="Search Filter"
        content={
          <React.Fragment>
            Choose one or all filters to search through the device usage history list
            <Autocomplete
              className={classes.filterDropdown}
              dataCount={devicePage*10}
              options={[{deviceRefNo: "All Devices", id: 0}, ...deviceList]}
              fetchData={()=>fetchMoreData()}
              renderOption={(option) => option.deviceRefNo}
              isLoadingMore={isLoadingMore}
              placeholder="Select a device"
              onInputChange={(e, newInputValue) => {
                if (e && e._reactName == "onBlur") {
                  setDevicePage(0);
                  setSearchValue(selectedDevice?selectedDevice.deviceRefNo:"All Devices");
                  fetchMoreData(newInputValue, 0);
                } 
                if (e && e._reactName == "onClick") {
                  setDevicePage(0);
                  setSearchValue(newInputValue);
                  fetchMoreData(undefined, 0);
                }
                if (e && e._reactName == "onChange") {
                  setDevicePage(0);
                  setSearchValue(newInputValue);
                  fetchMoreData(newInputValue, 0);
                }
              }}
              onChange={(_, newValue) => {
                setSelectedDevice(newValue);
                setNewFilter({...newFilter, deviceId: newValue.id});
              }}
              inputValue={searchValue}
              value={searchValue!==(selectedDevice&&selectedDevice.deviceRefNo) ? null : selectedDevice}
              renderValue={"deviceRefNo"}
            />
            <Select
              className={classes.filterDropdown}
              name="actionStatus"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select an action"
              value={renderValue(newFilter.actionStatus)}
            >
              <MenuItem value={"Open"}>Open</MenuItem> 
              <MenuItem value={"Close"}>Close</MenuItem> 
            </Select>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_filter()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_filter()}>Apply Filter</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
