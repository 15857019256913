import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Card from 'shared-components/Card/Card';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import { checkPermission } from "common/functions";
import { monitoringEquipTableHead } from "enums/UserPortal/TableHeaders";

import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function Summary() {
  const classes = useStyles();
  const equipmentSummary = useSelector(store => store.user.monitoring.equipment.equipmentOverview.equipmentDetailInfos);
  const [equipment, setEquipment] = React.useState(null);
  
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "equipment");
  
  // componentDidMount
  React.useEffect(() => {
    setEquipment(equipmentSummary && equipmentSummary.filter(({equipmentName}) => equipmentName === path[index+1].replace("%20", " ")));
  },[equipmentSummary, location.pathname]);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: clsx(classes.cardPaper, classes.cardEquipment),
          title: classes.cardMargin,
          content: classes.cardContent
        }}
        title="Equipment Summary"
      >
        <Table
          className={classes.table}
          header={monitoringEquipTableHead}
        >
          { equipment && equipment.map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.equipmentName}</TableCell>
                  <TableCell>{item.equipmentRefNo}</TableCell>
                  <TableCell>{item.location}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{!checkPermission("Equipment", "none")?<Link className={classes.link} to={{pathname: "/user/equipment/maintenance-status"}}>Details</Link>:""}</TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </Card>
    </React.Fragment>
  );
}
