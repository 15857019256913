import { createSlice } from "@reduxjs/toolkit";

import { SearchWareHouseMasterList, SearchWareHouseRawMaterialList, SearchWareHouseFinishedGoodsList } from "services/UserPortal/WarehouseService";

// initial state
const initialState = {
  masterTotalCount: 0,
  rawMaterialTotalCount: 0,
  finishedGoodsTotalCount: 0,
  masterResults: [],
  rawMaterialResults: [],
  finishedGoodsResults: [],
};

const warehouseSearch = createSlice({
  name: "warehouseSearch",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [SearchWareHouseMasterList.fulfilled]: (state, action) => {
      state.masterTotalCount = action.payload.result.warehouseList && action.payload.result.warehouseList.totalCount;
      state.masterResults = action.payload.result.warehouseList && action.payload.result.warehouseList.items;
    },
    [SearchWareHouseRawMaterialList.fulfilled]: (state, action) => {
      state.rawMaterialTotalCount = action.payload.result.totalCount;
      state.rawMaterialResults = action.payload.result.items;
    },
    [SearchWareHouseFinishedGoodsList.fulfilled]: (state, action) => {
      state.finishedGoodsTotalCount = action.payload.result.totalCount;
      state.finishedGoodsResults = action.payload.result.items;
    },
  },
});

// export actions
export const { reset } = warehouseSearch.actions;

// export the reducer
export default warehouseSearch.reducer;