import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import Popper from "shared-components/Popper/Popper";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, checkPermission, renderValue, filterParam } from "common/functions";
import { setSearchText } from "store/general";
import { setValues } from "../store/capacity";
import { productionOrderTableHead } from "enums/UserPortal/TableHeaders";
import { GetProductList, GetSaleOrderLookUp, GetCustomerOrderCapacityDetailReportByDateAndProduct, GetCustomerList } from "services/UserPortal/CustomerOrderService";

import helper from "assets/icons/black/helper.svg";
import styles from "assets/jss/components/UserPortal/customerOrderStyle.js";

const useStyles = makeStyles(styles);

export default function Details(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const productList = useSelector(store => store.user.customerOrder.management.productList);
  const filter = useSelector(store => store.user.customerOrder.capacity.filter);
  const customerList = useSelector(store => store.user.customerOrder.capacity.customerList);
  const totalCount = useSelector(store => store.user.customerOrder.capacity.totalCount);
  const orders = useSelector(store => store.user.customerOrder.capacity.orders);
  const saleOrder = useSelector(store => store.user.customerOrder.management.saleOrder);
  const [newFilter, setNewFilter] = React.useState({saleOrderNumber: null, productId: null, date: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [openPopper, setOpenPopper] = React.useState(false);

  const prop = props.location && props.location.state;
  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date" && moment(value).isAfter('2000-01-01', 'year')) {
      dispatch(setValues({filter: {...filter, date: moment(value).format("YYYY-MM-DD"), page: 0}}));
    } 
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };
  
  const getAllCapacityDetails = (stopLoading) => {
    if (filter.productId && filter.date) {
      dispatch(GetCustomerOrderCapacityDetailReportByDateAndProduct({...filter, stopLoading}));
    } else {
      dispatch(setValues({totalCount: 0, orders: []}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    dispatch(setValues({filter: {...search, date: prop && moment(prop.date).format("YYYY-MM-DD"), productId: prop && prop.productId, page: 0}}));
  },[prop]);

  React.useEffect(() => {
    getAllCapacityDetails();
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetProductList({isExcludeMixProduct: true}));
    dispatch(GetSaleOrderLookUp());
    dispatch(GetCustomerList());
    if (!(prop && prop.date)) {
      history.push("/user/sales-order/capacity");
    }
    // componentDidUnmount
    return () => {
      dispatch(setValues({ totalCount: 0, orders: [] }));
    }
  },[]);

  useInterval(() => {
    getAllCapacityDetails(true);
  });

  productionOrderTableHead[7].label = 
  <div className="flex items-center">
    Utilized (%)
    <Popper 
      noButton
      open={openPopper}
      className={classes.popper}
      closePopper={() => setOpenPopper(false)}
      placement="top"
      content="Calculated based on the product growth tray."
    >
      <IconButton 
        onClick={() => setOpenPopper(true)}
      >
        <img className={classes.icon} src={helper} alt="helper" />
      </IconButton>
    </Popper>
  </div>;
  return (
    <React.Fragment>
      <Card 
        title={"Production Orders Per Day (" + moment(filter.date).format("DD/MM/YYYY")+")"}
        subheader={
          <React.Fragment>
            { Object.keys(filter).map((key) => {
            if (filter[key] && key !== "page" && key !== "date") {
              let label = filter[key];
              if (key === "productId") {
                const product = productList.find(({id}) => id == filter[key]);
                label = product && (product.productName+" ("+product.productId+")");
              }
              if (key === "customerId") {
                const customer = customerList.find(({id}) => id == filter[key]);
                label = customer && customer.customerName;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            }
          })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the production order list
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productId})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="saleOrderNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a sale order"
                    value={renderValue(newFilter.saleOrderNumber)}
                  >
                    {saleOrder && saleOrder.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="customerId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select customer"
                    value={renderValue(newFilter.customerId)}
                  >
                    {customerList && customerList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.customerName} ({item.customerRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateTimePicker
              closeOnSelect
              clearable
              placeholder="Select a date"
              className={classes.cardActionDropdown}
              value={filter.date}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, date: null}}))}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.push("/user/sales-order/capacity")}
            >
              Go Back
            </Button>
            {(checkPermission("Finished Goods", "readEdit") && moment(filter.date).isSame(moment().set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0}))) &&
              <Button
                className={classes.button}
                onClick={()=>history.push("/user/finished-goods/in")}
              >
                Book Excess
              </Button>
            }
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={productionOrderTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { orders.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.product_Id}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.capacityWeight))}kg</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.totalPrice))}</TableCell>
                    <TableCell>{item.subSaleOrderNumber}</TableCell>
                    <TableCell>{item.currentStage}</TableCell>
                    <TableCell>{item.customerName}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.capacityPercentage))}</TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
