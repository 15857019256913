import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _ from "lodash"
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button"
// core components
import TextField from "shared-components/TextField/TextFieldWithLabel";

import * as validate from "common/validations";
import { setSuccess } from "store/general";
import { updateRequest, reset } from "./store/resetPassword";
import { Verify, ResetPassword } from "services/AdminPortal/ResetPasswordService";

import lock from "assets/icons/black/password.svg";
import eye from "assets/icons/orange/eye.svg";
import eyeOff from "assets/icons/orange/eye-off.svg";
import styles from "assets/jss/components/Authentication/formStyle.js";

const useStyles = makeStyles(styles);

export default function ResetPasswordForm() {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const credentials = useSelector(store => store.credentials.resetPassword);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showCfmPassword, setShowCfmPassword] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  
  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleShowNewPasswordClick = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowCfmPasswordClick = () => {
    setShowCfmPassword(!showCfmPassword);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (validateFields()) {
      dispatch(ResetPassword())
      .then(({error}) => {
        if (!error) {
          dispatch(setSuccess("Password reset successful"));
        }
        history.push("/login");
      });
    }
  };

  const validateFields = () => {
    if (!validate.isValidPassword(credentials.password)) {
      setErrorMsg({field: "password", msg: "Password does not meet minimum security requirements"});
      return false;
    }
    if (!_.isEqual(credentials.password, credentials.confirmPassword)) {
      setErrorMsg({field: "confirmPassword", msg: "Password does not match"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    dispatch(Verify(params))
    .then(({payload}) => {
      if (payload.success) {
        dispatch(updateRequest({email : params.email, token : params.code.replace(/\s/g, "+")}));
      } else {
        history.push("/login");
      }
    })
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <form onSubmit={(e) => handleButtonClick(e)}>
      <Typography className={classes.title}>Reset Password</Typography>
      <Typography className={classes.subtitle}>
        Please select a password that is at least 8 character’s long and contains a combination of uppercase and lowercase letters, numbers and/or symbols.
      </Typography>
      <TextField 
        id="password"
        className={classes.textfield}
        type={showNewPassword ? 'text' : 'password'}
        startAdornment={<img src={lock} alt="lock-line" />}
        endAdornment={
          <IconButton
            className={classes.adornment}
            classes={{label: classes.adornment}}
            onClick={handleShowNewPasswordClick}
          >
            { showNewPassword 
              ? <img className={classes.adornment} src={eye} alt="eye" /> 
              : <img className={classes.adornment} src={eyeOff} alt="eye-off" /> 
            }
          </IconButton>
        }
        label="New Password"
        variant="filled"
        onChange={(e) => handleOnChange_text(e)}
        errorMsg={errorMsg}
      />
      <TextField 
        id="confirmPassword"
        type={showCfmPassword ? 'text' : 'password'}
        startAdornment={<img src={lock} alt="lock-line" />}
        endAdornment={
          <IconButton
            className={classes.adornment}
            classes={{label: classes.adornment}}
            onClick={handleShowCfmPasswordClick}
          >
            { showCfmPassword 
              ? <img className={classes.adornment} src={eye} alt="eye" /> 
              : <img className={classes.adornment} src={eyeOff} alt="eye-off" /> 
            }
          </IconButton>
        }
        label="Confirm Password"
        variant="filled"
        onChange={(e) => handleOnChange_text(e)}
        errorMsg={errorMsg}
      />
      <Button 
        type="submit" 
        className={clsx(classes.button, classes.buttonStyle)}
        onClick={(e) => handleButtonClick(e)}
      >
        Reset Password
      </Button>
      <Button 
        className={clsx(classes.buttonSecondary, classes.buttonStyle)}
        onClick={() => history.push("/login")}
      >
        Back to Log In
      </Button>
    </form>
  );
}
