import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Modal from "shared-components/Modal/Modal";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// core components
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

import Video from "./Video";
import styles from "assets/jss/shared-components/carouselStyle.js";

const useStyles = makeStyles(styles);
export default function Carousel({data, ...props}) {
  const classes = useStyles();
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);

  const plusSlides = (plus) => {
    let newIndex = slideIndex + plus;
    if (newIndex < 0) newIndex = data.length - 1;
    if (newIndex >= data.length) newIndex = 0;
    setSlideIndex(newIndex);
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  return (
    <React.Fragment>
      <div {...props}>
        <div className={classes.videoDiv}>
          <Video className={classes.video} data={data} slideIndex={slideIndex} />
          <IconButton 
            className={classes.zoom}
            onClick={() => setOpenModal(!openModal)}
          >
            <ZoomOutMapIcon />
          </IconButton>
        </div>
        <a className={classes.button} onClick={()=>plusSlides(-1)}>&#10094;</a>
        <a className={clsx(classes.button, classes.next)} onClick={()=>plusSlides(1)}>&#10095;</a>
      </div>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={() => handleModal(null)}
        content={
          <React.Fragment>
            <Video className={classes.zoomVideo} data={data} slideIndex={slideIndex} />
            <a className={clsx(classes.button, classes.prev)} onClick={()=>plusSlides(-1)}>&#10094;</a>
            <a className={clsx(classes.button, classes.next)} onClick={()=>plusSlides(1)}>&#10095;</a>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Close</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}

Carousel.propTypes = {
  data: PropTypes.array,
};