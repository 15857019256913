import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { GetPurchaseOrderLookup, GetWorkOrderLookup, GetSaleOrderLookup, GetTraceabilityBySO, GetTraceabilityByWO } from "services/UserPortal/TraceabilityService";

// initial state
const initialState = {
  filter: {},
  saleOrder: [],
  workOrder: [],
  purchaseOrder: [],
  SOTraceability: {},
  WOTraceability: {}
};

// create reducer and action creators
const traceability = createSlice({
  name: "traceability",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetPurchaseOrderLookup.fulfilled]: (state, action) => {
      state.purchaseOrder = _.orderBy(action.payload.result, ["purchaseOrderNumber"], ["desc"]);
    },
    [GetWorkOrderLookup.fulfilled]: (state, action) => {
      state.workOrder = _.orderBy(action.payload.result, ["workOrderNumber"], ["desc"]);
    },
    [GetSaleOrderLookup.fulfilled]: (state, action) => {
      state.saleOrder = _.orderBy(action.payload.result, ["saleOrderNumber"], ["desc"]);
    },
    [GetTraceabilityBySO.fulfilled]: (state, action) => {
      state.SOTraceability = action.payload.result;
    },
    [GetTraceabilityByWO.fulfilled]: (state, action) => {
      state.WOTraceability = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = traceability.actions;

// export the reducer
export default traceability.reducer;