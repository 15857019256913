// User
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import Popper from "shared-components/Popper/Popper";
import CustomIconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";

import { setSearchText } from "store/general";
import { rowsPerTable } from "config";
import { GetAllUser, DeleteUser, UntaggedUsersFromFarm } from "services/AdminPortal/UserService";
import { userTableHead } from "enums/AdminPortal/TableHeaders";
import { reset } from ".";
import { filterActionTableHead } from "common/functions";

import helper from "assets/icons/black/helper.svg";
import untag from "assets/icons/orange/untag.svg";
import alert from "assets/icons/orange/alert-line.svg";
import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/AdminPortal/userStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);
export default function User() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.admin.user.totalCount);
  const users = useSelector(store => store.admin.user.users);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [userId, setUserId] = React.useState(null);
  const [userName, setUserName] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openUntagModal, setOpenUntagModal] = React.useState(false);
  const [openResultModal, setOpenResultModal] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));
  const farmName = _.find(userDetail.farms, "isActive") && _.find(userDetail.farms, "isActive").farmName;
  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   if (property !== "action") {
  //     const isAsc = orderBy === property && order === 'asc';
  //     setOrder(isAsc ? 'desc' : 'asc');
  //     setOrderBy(property);
  //   }
  // };

  const handleModal_untag = (id, name) => {
    setUserId(id);
    setUserName(name);
    setOpenUntagModal(!openUntagModal);
  }

  const handleButtonClick_untag = () => {
    dispatch(UntaggedUsersFromFarm(userId))
    .then((response) => {
      setSuccess(response.payload.result.isSuccess);
      setOpenResultModal(true);
    });
  }

  const handleButtonClick_result = () => {
    dispatch(GetAllUser({page}));
    resetState();
  }

  const handleModal_delete = (id) => {
    setUserId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteUser(userId))
    .then(() =>{
      dispatch(GetAllUser({page: 0}));
      resetState();
    });
  }

  const resetState = () => {
    setUserId(null);
    setUserName(null);
    setPage(0);
    setSuccess(false);
    setOpenDeleteModal(false);
    setOpenUntagModal(false);
    setOpenResultModal(false)
  }

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  React.useEffect(() => {
    dispatch(GetAllUser({page}));
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  userTableHead[9].label = 
  <div className="flex">
    Untag / Edit / Delete
    <Popper 
      open={openPopper}
      className={classes.popper}
      closePopper={() => setOpenPopper(false)}
      buttonClick={() => setOpenPopper(false)}
      placement="left"
      title="Untag User"
      content="Untag an user from the farm to remove the user from accessing the particular farm’s information. "
    >
      <IconButton 
        onClick={() => setOpenPopper(true)}
      >
        <img className={classes.icon} src={helper} alt="helper" />
      </IconButton>
    </Popper>
  </div>;
  return (
    <React.Fragment>
      { totalCount > 0 
      ? <React.Fragment>
          <Card 
            title = "User List"
          >
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(userTableHead, canWrite)}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { users.map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell><Typography className={classes.ellipsis}>{user.name}</Typography></TableCell>
                      <TableCell><Typography className={classes.ellipsis}>{user.userName}</Typography></TableCell>
                      <TableCell><Typography className={classes.ellipsis}>{user.phone}</Typography></TableCell>
                      <TableCell><Typography className={classes.emailEllipsis}>{user.emailAddress}</Typography></TableCell>
                      <TableCell>{user.employeeId}</TableCell>
                      <TableCell>{user.roleName}</TableCell>
                      <TableCell>{user.gender}</TableCell>
                      <TableCell>{user.workforceType}</TableCell>
                      <TableCell>{user.country}</TableCell>
                      { canWrite && 
                        <TableCell align="left" className={classes.tableAction}>
                          { user.id !== userDetail.id &&
                            <Tooltip title="Untag" placement="top">
                              <IconButton 
                                onClick={() => handleModal_untag(user.id, user.name)}
                                disabled={userId===user.id}
                              >
                                <img className={classes.icon} src={untag} alt="untag" />
                              </IconButton>
                            </Tooltip>
                          }
                          <Link to={{pathname: "/admin/user/"+user.id}}>
                            <CustomIconButton type="edit" />
                          </Link>
                          { user.id !== userDetail.id &&
                            <CustomIconButton 
                              type="delete"
                              onClick={() => handleModal_delete(user.id)}
                              disabled={userId===user.id}
                            />
                          }
                        </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </Card>
          <Modal
            open={openUntagModal}
            onClose={() => handleModal_untag(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content={"You are about to untag " + userName + " from " + farmName + ". Do you want to proceed?"}
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal_untag(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_untag()}>Untag</Button>
              </React.Fragment>
            }
          />
          <Modal
            open={openDeleteModal}
            onClose={() => handleModal_delete(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this user? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
          <Modal
            open={openResultModal}
            onClose={() => handleButtonClick_result()}
            icon={success ? <img className={classes.icon_64} src={confirm} alt="confirm" /> : <img className={classes.icon_64} src={alert} alt="alert" />}
            title={success ? "Success" : "Error"}
            content={ success
              ? "You have successfully untagged " + userName + " from " + farmName + "."
              : "This is " + userName + "'s last tagged farm. Please delete user if you want to remove user from the farm"
            }
            actions={
              <Button className={classes.button} onClick={() => handleButtonClick_result()}>OK</Button>
            }
          />
        </React.Fragment>
      : <Empty 
          description="Your page is empty. Add a user to get started!"
        />
      }
    </React.Fragment>
  );
}
