const printStyle = () => ({
  printContainer: {
    fontSize: "12px",
    marginTop: "2.083vw",
    zIndex: -9999,
  },
  logo: {
    marginBottom: "30px",
    height: "30px",
  },
  printFooter: {
    textAlign: "center",
    fontSize: "12px",
  },
});

export default printStyle;
