import {
  cardRadius,
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  primaryColor,
  border,
} from "assets/jss/generaliseStyle.js";

import {
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const asrsStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_24,
  icon_64,
  icon,
  link,
  tag,

  // Search asrs
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display asrs
  table: {
    margin: "0 -1.979vw",
    // overflow: "overlay"
  },
  snCell: {
    width: "5vw",
    maxWidth: "5vw"
  },
  tableAction: {
    minWidth: "10.625vw"
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw",
  },

  // Batch Update
  batchPaper: {
    ...cardRadius,
    textAlign: "center",
    padding: "3.021vw"
  },
  img: {
    margin: "auto",
    width: "26.406vw",
    height: "19.479vw"
  },
  batchTitle:{
    margin: "1.250vw",
    fontWeight: 500,
    fontSize: "1.771vw",
    color: primaryColor[4],
  },
  batchDesc: {
    fontSize: "1.094vw",
    color: "rgba(17, 42, 17, 0.7)",
    marginBottom: "1.250vw"
  },
  batchButton: {
    ...buttonSecondary,
    margin: "0 0.833vw",
  },
  batchTable: {
    margin: "0 -1.979vw",
    "& thead > tr > th:nth-last-child(2)": {
      minWidth: "unset",
      width: "5.250vw"
    },
  },
  tableIcon: {
    ...icon_24,
    margin: "0.25vw"
  },
  
  // Edit
  editDescription:{
    fontWeight: 500,
    margin: "1.250vw 0",
  },
  timeContainer: {
    display: "flex",
    alignItems: "center"
  },

  //maintenance
  jobName:{
    display: "flex",
    marginBottom: "1.250vw"
  },
  jobNameText:{
    paddingTop: "0.833vw",
  },
  jobNameTextfield:{
    width: "50%",
    marginLeft: "1.250vw"
  },
  dateTimeField:{
    margin: "1.250vw 0",
  },
  dash:{
    margin: "0 1.250vw",
  },
  addButton: {
    marginTop: "1.042vw",
  },
  timeField: {
    display: "flex",
    marginBottom: "0.365vw",
    '&:not(:first-child)': {
      marginTop: "1.563vw",
    },
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center"
  },
  // Settings
  settingPaper: {
    ...cardRadius,
    border,
    width: "45vw",
    margin: "auto",
    marginBottom: "1.302vw",
    padding: "1.823vw 7.292vw",
    color: primaryColor[3],
  },
  settingLabel: {
    color: primaryColor[4],
    marginTop: "0.833vw"
  },
  settingButton: {
    display: "flex",
    justifyContent: "space-between"
  },
}

export default asrsStyle;