import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import { minDate, rowsPerTable } from "config";
import { daysOfWeek } from "enums/Time";
import { deviceControlTableHead, subDeviceControlTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "store/common";
import { GetAllDeviceControlRule, DeleteDeviceControlRule } from "services/UserPortal/ClimateService";
import { GetDeviceList, GetAllSensorControlDeviceFunction } from "services/UserPortal/CommonLookupService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function ConfigurationDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceList = useSelector(store => store.common.deviceList);
  const bankList = useSelector(store => store.common.bankList);
  const floorSectorList = useSelector(store => store.user.climate.deviceControl.floorSectorList);
  const functionList = useSelector(store => store.common.functionList);
  const totalCount = useSelector(store => store.user.climate.deviceControl.rulesCount);
  const rules = useSelector(store => store.user.climate.deviceControl.rules);
  const [controlId, setControlId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [subOrder, setSubOrder] = React.useState('asc');
  const [subOrderBy, setSubOrderBy] = React.useState('ruleNo');
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [devicePage, setDevicePage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [searchValue, setSearchValue] = React.useState("All Devices"); // for device list
  const [selectedFunction, setSelectedFunction] = React.useState(null);
  const [selectedDevice, setSelectedDevice] = React.useState({deviceRefNo: "All Devices", id: 0});
  const [openMoreModal, setOpenMoreModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleSubRequestSort = (e, property) => {
    const isAsc = subOrderBy === property && subOrder === 'asc';
    setSubOrder(isAsc ? 'desc' : 'asc');
    setSubOrderBy(property);
  };

  const handleOnChange_select = (e) => {
    setSelectedFunction(e.target.value === "all" ? null : e.target.value);
    dispatch(setValues({deviceList: []}));
    setSelectedDevice("All Devices");
  };
  
  const fetchMoreData = (search, pageNo) => {
    if (devicePage !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
        floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
        sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector,
        page: pageNo !== undefined ? pageNo : devicePage,
        DeviceRefNo: search ? search : "",
        DeviceFunctionId: selectedFunction,
        IsControlDevice: true
      }
      dispatch(GetDeviceList(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result.item) {
            setDevicePage(pageNo !== undefined ? pageNo+1 : devicePage+1);
            if (search || devicePage === 0 || pageNo === 0) {
              dispatch(setValues({deviceList: response.payload.result.items}));
            } else {
              dispatch(setValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            setDevicePage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleModal_more = () => {
    setOpenMoreModal(!openMoreModal);
  }

  const handleModal_delete = (id) => {
    setControlId(id)
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteDeviceControlRule(controlId))
    .then((response) => {
      if (!response.error) {
        setPage(0);
        setOpenDeleteModal(!openDeleteModal);
        dispatch(GetAllDeviceControlRule({
          bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
          floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
          sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector, 
          page: 0
        }));
      }
    });
  }
  
  React.useEffect(() => {
    if (selectedDevice) {
      setPage(0);
      setOpen(false);
      dispatch(GetAllDeviceControlRule({
        bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
        floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
        sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector,
        page: 0, deviceFunctionId: selectedFunction, deviceId: selectedDevice.id}));
      const param = {
        page: 0,
        bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
        floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
        sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector,
        DeviceFunctionId: selectedFunction,
        IsControlDevice: true
      }
      dispatch(GetDeviceList(param))
      .then((response) => {
        if (response.payload.result) {
          const result = response.payload.result.items
          dispatch(setValues({deviceList: result}));
          setDevicePage(devicePage+1);
        }
      });
    }
  },[props.tab, props.bankFloorTab, selectedFunction, selectedDevice]);

  React.useEffect(() => {
    setSubPage(0);
  },[open]);

  React.useEffect(() => {
    dispatch(GetAllDeviceControlRule({
      bankId: props.bankFloorTab === 0 ? bankList[props.tab].id : 0,
      floorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].floor,
      sectorId: props.bankFloorTab === 0 ? 0 : floorSectorList[props.tab].sector, 
      page, deviceFunctionId: selectedFunction, deviceId: selectedDevice.id}));
    setOpen(false);
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllSensorControlDeviceFunction({isControl: true}));
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  const renderName = () => {
    const number = props.selectedFloorSector.name.replace("Floor ", "").split(" Sector ");
    if (Number(number[1]) === 1) {
      return 'Chamber ' + ((Number(number[0]) * (Number(number[1]) + 1))-1)
    } else if (Number(number[1]) === 2) {
      return 'Chamber ' + (Number(number[0]) * Number(number[1]))
    }
  }

  return (
    <React.Fragment>
      <Card 
        title={props.bankFloorTab ? renderName() : props.bank.bankName}
        classes={{
          root: classes.cardPaper,
        }}
        action={
          <React.Fragment>
            <Typography className={classes.label}>Device Function:</Typography>
            <Select
              className={classes.cardActionDropdown}
              name="deviceFunctionId"
              onChange={(e)=>handleOnChange_select(e)}
              value={selectedFunction}
              defaultValue="all"
            >
              <MenuItem value="all">All Functions</MenuItem>
              {functionList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.deviceFunctionName}</MenuItem>;
              })} 
            </Select>
            <Typography className={classes.label}>Device ID:</Typography>
            <Autocomplete
              dataCount={devicePage*10}
              options={[{deviceRefNo: "All Devices", id: 0}, ...deviceList]}
              fetchData={()=>fetchMoreData()}
              renderOption={(option) => option.deviceRefNo}
              isLoadingMore={isLoadingMore}
              placeholder="Select a device"
              onInputChange={(e, newInputValue) => {
                if (e && e._reactName == "onBlur") {
                  setDevicePage(0);
                  setSearchValue(selectedDevice?selectedDevice.deviceRefNo:"All Devices");
                  fetchMoreData(newInputValue, 0);
                } 
                if (e && e._reactName == "onClick") {
                  setDevicePage(0);
                  setSearchValue(newInputValue);
                  fetchMoreData(undefined, 0);
                }
                if (e && e._reactName == "onChange") {
                  setDevicePage(0);
                  setSearchValue(newInputValue);
                  fetchMoreData(newInputValue, 0);
                }
              }}
              onChange={(_, newValue) => {
                setSelectedDevice(newValue);
              }}
              inputValue={searchValue}
              value={searchValue!==(selectedDevice&&selectedDevice.deviceRefNo) ? null : selectedDevice}
              renderValue={"deviceRefNo"}
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            expandable
            className={classes.mainTable}
            header={deviceControlTableHead}
          >
            { rules.length === 0
            ? <TableRow>
                <TableCell />
                <TableCell colSpan={3}>No Rules Added</TableCell>
              </TableRow>
            : rules.map((item,index) => {
                const subCount = Math.ceil(item.ruleList.length / rowsPerTable);
                return (
                  <React.Fragment key={index}>
                    <TableRow
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => open === index ? setOpen(false) : setOpen(index)}
                    >
                      <TableCell>
                        <IconButton 
                          className={open === index ? classes.rotate : null}
                          onClick={() => open === index ? setOpen(false) : setOpen(index)}
                        >
                          <img className={classes.icon} src={arrow} alt="arrow" />
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.deviceFunctionName}</TableCell>
                      <TableCell>
                        {item.isForAllDeviceFunctionDevices 
                        ? <React.Fragment>
                            All Devices <span className={classes.link} onClick={handleModal_more}>(View)</span>
                          </React.Fragment>
                        : item.deviceRefNo
                        }
                      </TableCell>
                      <TableCell align="right">
                        <Link to={{pathname: "/user/climate/device-control/"+item.id, state: {bankFloorTab: props.bankFloorTab, tab: props.tab, deviceRefNo: item.deviceRefNo}}}>
                          <CustomIconButton type="edit" />
                        </Link>
                        <CustomIconButton 
                          type="delete"
                          onClick={() => handleModal_delete(item.id)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      { open===index && 
                        <TableCell className="pt-0 pb-0" colSpan={7}>
                          <Collapse in={open === index}>
                            <Table
                              className={classes.subTable}
                              header={subDeviceControlTableHead}
                              order={subOrder}
                              orderBy={subOrderBy}
                              onRequestSort={handleSubRequestSort}
                            >
                              { _.orderBy(item.ruleList, [subOrderBy], [subOrder])
                              .slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                              .map((subItem, subIndex) => {
                                return (
                                  <React.Fragment key={subIndex}>
                                    <TableRow>
                                      <TableCell>{('0'+((subPage*rowsPerTable)+1+subIndex)).slice(-2)}</TableCell>
                                      <TableCell>
                                        {daysOfWeek.map((day) => {
                                          if (subItem["is"+day]) {
                                            return day;
                                          }
                                        }).filter((day)=>day!=undefined).join(", ")}
                                      </TableCell>
                                      <TableCell>{moment(subItem.startDate).format("DD/MM/YYYY")}</TableCell>
                                      <TableCell>{moment(subItem.endDate).isAfter(minDate) && moment(subItem.endDate).format("DD/MM/YYYY")}</TableCell>
                                      <TableCell>{subItem.turnOnTime.replace("-", "")}</TableCell>
                                      <TableCell>{subItem.turnOffTime.replace("-", "")}</TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                )
                              })}
                            </Table>
                            { subCount > 1 &&
                              <Pagination 
                                count={subCount} 
                                page={subPage+1}
                                onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                              />
                            }
                          </Collapse>
                        </TableCell>
                      }
                    </TableRow>
                  </React.Fragment>
                )
              })
            }
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Link to={{pathname: "/user/climate/device-control/create", state: {tab: props.tab}}}>
          <Button
            className={clsx(classes.buttonSecondary, classes.addButton)}
            startIcon={<AddIcon />}
          >
            Create Rules
          </Button>
        </Link>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this device control? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openMoreModal}
        onClose={() => handleModal_more()}
        title="All Devices"
        content={
          deviceList &&
          <div className={classes.moreList} id="scrollableDiv">
            <InfiniteScroll
              dataLength={devicePage*10}
              next={()=>fetchMoreData()}
              hasMore={true}
              loader={ isLoadingMore && 
                <div className={"w-full text-center"}>
                  <CircularProgress className={classes.loading} size={"1.250vw"} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {deviceList.map((item, index) => (
                <ListItem key={index}>{item.deviceRefNo}</ListItem>
              ))}
            </InfiniteScroll>
          </div>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_more()}>Close</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
