import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from 'shared-components/Modal/Modal';

import { formatNumbers, roundTo2Decimal, filterActionTableHead } from "common/functions";
import { minDate, rowsPerTable } from "config";
import { reset } from "../store/search";
import { setSearchText } from "store/general";
import { unassignedOrdersTableHead } from "enums/UserPortal/TableHeaders";
import { SearchUnassignedOrderList } from "services/UserPortal/PlanningService";

import alert from "assets/icons/orange/alert-line.svg";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/planningStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchUnassignedResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.planning.search.unassignedTotalCount);
  const results = useSelector(store => store.user.planning.search.unassignedResults);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [statusMsg, setStatusMsg] = React.useState(null);
  const [openStatusModal, setOpenStatusModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleModal_status = (msg) => {
    setOpenStatusModal(!openStatusModal);
    setStatusMsg(msg);
  };

  const handleButtonClick_edit = (soId) => {
    history.push("/user/sales-order/management/"+soId)
  };

  React.useEffect(() => {
    dispatch(SearchUnassignedOrderList({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Unassigned List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={filterActionTableHead(unassignedOrdersTableHead, canWrite)}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.purchaseOrderNumber)}</TableCell>
                            <TableCell>{highlightValue(item.subSaleOrderNumber)}</TableCell>
                            <TableCell>{highlightValue(moment(item.deliveryDate).isAfter(minDate) ? moment(item.deliveryDate).format("DD/MM/yyyy") : "")}</TableCell>
                            <TableCell>{highlightValue(item.productName)}</TableCell>
                            <TableCell>{highlightValue(item.productId)}</TableCell>
                            <TableCell>{highlightValue(item.productComponent)}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.customerOrderWeight)))}{item.uom}</TableCell>
                            <TableCell>
                              {item.status 
                              ? <img className={clsx(classes.icon_24, classes.link)} src={alert} alt="alert" onClick={()=>handleModal_status(item.status)} /> 
                              : ""}
                            </TableCell>
                            <TableCell><span className={classes.link} onClick={()=>handleButtonClick_edit(item.soId)}>Edit</span></TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={openStatusModal}
        onClose={() => handleModal_status(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Status Alert"
        content={
          <React.Fragment>
            Order is recommended to be cancelled or postponed due to:<br /> <br />
            <span className="font-medium">{statusMsg}</span>
          </React.Fragment>
        }
        actions={
          <Button className={classes.button} onClick={() => handleModal_status(null)}>Okay</Button>
        }
      />
    </React.Fragment>
  );
}
