import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// shared-component
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { setValues } from "../store/costChart";
import { useInterval } from 'common/utils';

import { GetAllCostChartRequest } from "services/UserPortal/SmartMeteringService";

import EnergyConsumption from "./EnergyConsumption";
import EditCost from "./EditCost";
import ParamList from "./ParamList";
import LineChart from "./LineChart";
import styles from "assets/jss/components/UserPortal/smartMeteringStyle.js";

const useStyles = makeStyles(styles);

export default function CostCharts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const results = useSelector(store => store.user.smartMetering.costChart.result);
  const filter = useSelector(store => store.user.smartMetering.costChart.filter);
  const [selected, setSelected] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [open, setOpen] = React.useState(true);

  const getCostChart = (stopLoading) => {
    setIsLoading(!stopLoading);
    if ((filter.address && filter.address.length) || (filter.deviceName && filter.deviceName.length)) {
      dispatch(GetAllCostChartRequest({...filter, stopLoading: true}))
    } else {
      dispatch(setValues({result: []}));
    }
  }
  
  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")}}));
    }
  };

  React.useEffect(() => {
    if (selected[0] && typeof selected[0] === "number") {
      dispatch(setValues({filter: {...filter, address: selected, deviceName: []}}));
    } else {
      dispatch(setValues({filter: {...filter, deviceName: selected, address: []}}));
    }
  },[selected]);

  React.useEffect(() => {
    getCostChart();
  },[filter]);
  
  useInterval(() => {
    getCostChart(true);
  });

  return (
    <React.Fragment>
      <div className={classes.graphContent}>
        <IconButton
          className={classes.formIconButton2}
          onClick={()=>setOpen(!open)}
        >
          {!open ? <KeyboardArrowRightIcon className={classes.icon_24} /> : <KeyboardArrowLeftIcon className={classes.icon_24} />}
        </IconButton>
        <Drawer
          sx={{
            // width: drawerWidth,
            // flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: "30%",
              height: "33vw",
              position: 'absolute',
              padding: "1.563vw",
              marginLeft: "-1.979vw",
              border: "0.052vw solid #D9D9D6",
              borderLeft: 0,
              borderBottomRightRadius: "0.833vw",
              overflow: "visible",
              marginTop: "5.125vw",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <Paper className={classes.paper2} elevation={0}>
            <ParamList selected={selected} setSelected={setSelected} />
          </Paper>
          <IconButton
            className={classes.formIconButton}
            onClick={()=>setOpen(!open)}
          >
            {!open ? <KeyboardArrowRightIcon className={classes.icon_24} /> : <KeyboardArrowLeftIcon className={classes.icon_24} />}
          </IconButton>
        </Drawer>
        <div className={classes.highChart}>
          <div className={classes.topComponent}>
            <div className={classes.flexContainer}>
              <EnergyConsumption />
              <EditCost />
            </div>
            <DateRangePicker
              clearable
              placeholder="Date"
              className={classes.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, startDate: null, endDate: null}}))}
              disabled={isDirty}
            />
          </div>
          <Paper className={classes.chartPaper} elevation={0}>
            <Typography className={classes.title}>Cost Chart</Typography>
            <LineChart results={results} isLoading={isLoading} setIsLoading={setIsLoading} />
            {/* <div className={classes.legend}>
              {results && results.map((item, index) => {
                if (item) {
                  return (
                    <React.Fragment key={index}>
                      <div className={clsx(classes.colorBox, classes.legendColor)} />{item.deviceName}
                    </React.Fragment>
                  )
                }
              })}
            </div> */}
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
}
