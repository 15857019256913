import {
  cardRadius,
  icon_64,
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const cageStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  
  orange,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display Cage
  table,
  icon,
  
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },

  // Add Edit Cage
  gridContainer: {
    alignItems: 'center'
  },
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
  },
  radioList: {
    display: 'flex',
    alignItems: 'center'
  },
  radio: {
    marginRight: '1.25vw'
  }
}

export default cageStyle;