import { createSlice } from "@reduxjs/toolkit";

import { GetWorkOrderConversionToProduct } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  filter: {},
  conversions: [],
};

const conversion = createSlice({
  name: "conversion",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetWorkOrderConversionToProduct.fulfilled]: (state, action) => {
      state.conversions = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = conversion.actions;

// export the reducer
export default conversion.reducer;