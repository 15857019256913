import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Alert from "shared-components/Modal/Alert";
import Modal from "shared-components/Modal/Modal";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import TextField from "shared-components/TextField/TextField";
import FilterButton from "shared-components/Button/FilterButton";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';

import { formatNumbers, roundTo2Decimal, renderValue, filterUrl, filterParam } from 'common/functions';
import { isPositiveDecimal } from 'common/validations';
import { setDirty } from "store/general";
import { readjustTableHead } from "enums/UserPortal/TableHeaders";
import { setValues, reset } from "../store/report";
import { CreateRawMaterialRequest, GetPlanningReportByDateList, DownloadRawMaterialReserved, DownloadEquipmentReserved, DownloadOtherDevicesReserved, GetAllOtherDevicesByWorkOrderId } from "services/UserPortal/PlanningService";
import { GetProductList, GetProductGrowthProcessList } from "services/UserPortal/CommonLookupService";

import PlanningTable from "./PlanningTable";
import ExcessTable from "./ExcessTable";
import MissingTable from "./MissingTable";
import RMTable from "./RMTable";
import EquipmentTable from "./EquipmentTable";
import DeviceTable from "./DeviceTable";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');
export default function Detail(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const productList = useSelector(store => store.common.productList);
  const processList = useSelector(store => store.common.processList);
  const equipmentList = useSelector(store => store.common.equipmentList);
  const filter = useSelector(store => store.user.planning.report.filter);
  const deviceList = useSelector(store => store.user.planning.report.deviceList);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const planning = useSelector(store => store.user.planning.report.planning);
  const rawMaterial = useSelector(store => store.user.planning.report.rawMaterial);
  const [openReadjustModal, setOpenReadjustModal] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [tab, setTab] = React.useState(props.tab && props.tab !== -1 ? props.tab : 0);
  const [newFilter, setNewFilter] = React.useState({});
  const [newTab, setNewTab] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const prop = props.location && props.location.state;
  const workOrder = planning.length ? {workOrderNumber: planning[0].workOrderNumber, workOrderId: planning[0].workOrderId} : {};

  const handleOnChange_tab = (e, value) => {
    if (isDirty) {
      setOpenAlertModal(!openAlertModal);
      setNewTab(value);
    } else {
      setTab(value);
    }
  }
  
  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      dispatch(setValues({filter: {...filter, reservedDate: moment(value).format("YYYY-MM-DD")}}));
    } 
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };

  const handleButtonClick_exit = () => {
    setOpenAlertModal(false);
    setTab(newTab);
    dispatch(setDirty(false));
  }

  const handleOnChange_text = (e, index) => {
    const key = e.target.id.replace(/\d/g, '');
    let payload = _.cloneDeep(rawMaterial);
    payload[index][key] = e.target.value;
    dispatch(setValues({rawMaterial: payload}));
  };

  const handleModal_readjust = () => {
    if (rawMaterial.length > 0) {
      setOpenReadjustModal(!openReadjustModal);
    }
  }

  const handleButtonClick_download = () => {
    if (tab == 3) {
      dispatch(DownloadRawMaterialReserved({...filter, workOrderId: planning[0].workOrderId}))
      .then((response) => {
        FileDownload(response.payload.data, 'reserved_raw_material_report.xlsx');
      })
    } else if (tab == 4) {
      dispatch(DownloadEquipmentReserved({...filter, workOrderId: planning[0].workOrderId}))
      .then((response) => {
        FileDownload(response.payload.data, 'reserved_equipment_report.xlsx');
      })
    } else if (tab == 5) {
      dispatch(DownloadOtherDevicesReserved({...filter, workOrderId: planning[0].workOrderId}))
      .then((response) => {
        FileDownload(response.payload.data, 'reserved_other_devices_report.xlsx');
      })
    }
  }

  const handleModal_confirm = () => {
    if (validateFields()) {
      setOpenReadjustModal(!openReadjustModal);
      setOpenConfirmModal(!openConfirmModal);
    }
  }

  const handleButtonClick_cancel = () => {
    setOpenReadjustModal(false);
    setOpenConfirmModal(false);
  }

  const handleButtonClick_reorder = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      dispatch(CreateRawMaterialRequest())
      .then(() => {
        handleButtonClick_cancel();
        dispatch(GetPlanningReportByDateList({page: 0, date: moment(prop.date).format("YYYY-MM-DD")}))
        .then((response) => {
          const payload = response.payload.result.missingRawMaterialList;
          if (payload) {
            dispatch(setValues({missingCount: payload.totalCount, missing: payload.items}));
          }
        });
      });
    })
  }
  
  const validateFields = () => {
    for (let i=0; i<rawMaterial.length; i++) {
      if (!isPositiveDecimal(rawMaterial[i].quantity)) {
        setErrorMsg({field: "quantity"+i, msg: "Invalid quantity"});
        return false;
      }
      if (rawMaterial[i].quantity < rawMaterial[i].oldQuantity) {
        setErrorMsg({field: "quantity"+i, msg: "Cannot be less than the required quantity"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <PlanningTable state={props.location.state} />;
      case 1: 
        return <ExcessTable state={props.location.state} />;
      case 2: 
        return <MissingTable state={props.location.state} />;
      case 3: 
        return <RMTable state={props.location.state} />;
      case 4: 
        return <EquipmentTable state={props.location.state} />;
      case 5: 
        return <DeviceTable state={props.location.state} />;
    }
  }

  React.useEffect(() => {
    if (tab == 3) {
      setNewFilter({process: null, reservedDate: null, rawMaterialID: null});
      dispatch(setValues({filter: {...filterParam({productId: null, process: null, reservedDate: null, rawMaterialID: null})}}));
    } else if (tab == 4) {
      setNewFilter({process: null, reservedDate: null, equipmentID: null});
      dispatch(setValues({filter: {...filterParam({productId: null, process: null, reservedDate: null, equipmentID: null})}}));
    } else if (tab == 5) {
      setNewFilter({process: null, reservedDate: null, deviceId: null});
      dispatch(setValues({filter: {...filterParam({productId: null, process: null, reservedDate: null, deviceId: null})}}));
    } else {
      setNewFilter({});
      history.push({pathname: filterUrl({}), state: prop});
      dispatch(setValues({filter: {...filterParam({})}}));
    }
  },[tab]);

  React.useEffect(() => {
    if (!_.isEmpty(workOrder)) {
      dispatch(GetProductList(workOrder.workOrderId));
      dispatch(GetAllOtherDevicesByWorkOrderId({workOrderId: workOrder.workOrderId}));
    }
  },[planning]);

  React.useEffect(() => {
    dispatch(setDirty(Boolean(rawMaterial.length)));
  },[rawMaterial]);

  React.useEffect(() => {
    if (newFilter.productId) {
      const product = productList.find(({id}) => id == newFilter.productId);
      dispatch(GetProductGrowthProcessList(product.productVersionId));
    }
  },[newFilter.productId]);

  // componentDidMount
  React.useEffect(() => {
    if (!(prop && prop.date)) {
      history.push("/user/planning/report");
    } else {
      dispatch(setValues({filter: {...filter, reservedDate: moment(prop.date).format("YYYY-MM-DD")}}))
    }
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title={"Details on Work Order "+ (workOrder.workOrderNumber ? workOrder.workOrderNumber : "")}
        subheader={
          <React.Fragment>
            {/* {"Details on "+(props.location.state && moment(props.location.state.date).format("DD MMMM YYYY"))} */}
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "reservedDate" && key !== "page") {
                let label = filter[key];
                if (key === "productId") {
                  const product = productList.find(({id}) => id == filter[key]);
                  label = product && (product.productName+" ("+product.productRefNo+")");
                }
                if (key === "rawMaterialID") {
                  const rawMaterial = rawMaterialList.find(({id}) => id == filter[key]);
                  label = rawMaterial && rawMaterial.rawMaterialName;
                }
                if (key === "equipmentID") {
                  const equipment = equipmentList.find(({id}) => id == filter[key]);
                  label = equipment && equipment.equipmentName;
                }
                if (key === "deviceId") {
                  const device = deviceList.find(({deviceId}) => deviceId == filter[key]);
                  label = device && device.deviceName;
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
            <Tabs 
              className={_.isEmpty(_.omitBy(_.omit(filter, ["url", "reservedDate"]), _.isNil)) && classes.tabMargin}
              variant="scrollable"
              scrollButtons
              tabs={["PO & SO Selected", "Excess Capacity", "Missing Raw Materials", "Reserved Raw Materials", "Reserved Equipments", "Reserved Other Devices"]}
              value={tab} 
              onChange={handleOnChange_tab}
            />
          </React.Fragment>
        }
        action={
          <div className={classes.cardAction}>
            {/* { tab > 2 &&  */}
              <React.Fragment>
                <FilterButton 
                  setFilter={()=>dispatch(setValues({filter: {...newFilter}}))}
                  filter={filter}
                  setNewFilter={setNewFilter}
                  content={
                    <React.Fragment>
                      Choose one or all filters to search through the {tab===3 ? "raw material" : "equipment"} list
                      <Select
                        className={classes.filterDropdown}
                        name="productId"
                        onChange={(e)=>handleOnChange_select(e)}
                        placeholder="Select a product"
                        value={renderValue(newFilter.productId)}
                      >
                        {productList && productList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                        })} 
                      </Select>
                      <Select
                        className={classes.filterDropdown}
                        name="process"
                        onChange={(e)=>handleOnChange_select(e)}
                        placeholder="Select a process"
                        value={renderValue(newFilter.process)}
                        disabled={!newFilter.productId}
                      >
                        {processList && processList.map((item, index) => {
                          return <MenuItem key={index} value={item.processName}>{item.processName}</MenuItem>;
                        })} 
                      </Select>
                      { tab===3 &&
                        <Select
                          className={classes.filterDropdown}
                          name="rawMaterialID"
                          onChange={(e)=>handleOnChange_select(e)}
                          placeholder="Select a raw material"
                          value={renderValue(newFilter.rawMaterialID)}
                        >
                          {rawMaterialList && rawMaterialList.map((item, index) => {
                            return <MenuItem key={index} value={item.id}>{item.rawMaterialName} ({item.rawMaterialRefNo})</MenuItem>;
                          })} 
                        </Select>
                      }
                      { tab===4 &&
                        <Select
                          className={classes.filterDropdown}
                          name="equipmentID"
                          onChange={(e)=>handleOnChange_select(e)}
                          placeholder="Select an equipment"
                          value={renderValue(newFilter.equipmentID)}
                        >
                          {equipmentList && equipmentList.map((item, index) => {
                            return <MenuItem key={index} value={item.id}>{item.equipmentName} ({item.equipmentRefNo})</MenuItem>;
                          })} 
                        </Select>
                      }
                      { tab===5 &&
                        <Select
                          className={classes.filterDropdown}
                          name="deviceId"
                          onChange={(e)=>handleOnChange_select(e)}
                          placeholder="Select an device"
                          value={renderValue(newFilter.deviceId)}
                        >
                          {deviceList && deviceList.map((item, index) => {
                            return <MenuItem key={index} value={item.deviceId}>{item.deviceName}</MenuItem>;
                          })} 
                        </Select>
                      }
                    </React.Fragment>
                  }
                />
                <DateTimePicker
                  closeOnSelect
                  clearable
                  placeholder="Reserve Date"
                  className={classes.cardActionDropdown}
                  value={filter.reservedDate}
                  onChange={(e) => handleOnChange_date(e)}
                  onClear={()=>dispatch(setValues({filter: {...filter, reservedDate: null}}))}
                />
              </React.Fragment>
            {/* } */}
          </div>
        }
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.push("/user/planning/report")}
            >
              Go Back
            </Button>
            { tab === 2 && 
              <Button
                className={classes.button}
                onClick={()=>handleModal_readjust()}
                disabled={rawMaterial.length === 0}
              >
                Reorder Now
              </Button>
            }
            { tab > 2 && 
              <Button
                className={classes.button}
                onClick={()=>handleButtonClick_download()}
              >
                Download
              </Button>
            }
          </React.Fragment>
        }
      >
        {renderComponent()}
      </Card>
      <Modal
        className={classes.readjustModal}
        open={openReadjustModal}
        onClose={() => handleModal_readjust()}
        title={
          <React.Fragment>
            <Typography className={classes.readjustTitle}>Adjustment of Reorder</Typography>
            <Typography>Please check the quantity to reorder. You can readjust the quantity amount for reordering.</Typography>
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <Divider className={classes.divider} />
            <div className={clsx(classes.table, classes.reorderList)}>
              <Table
                header={readjustTableHead}
              >
                { rawMaterial.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.tableMargin}>{item.rawMaterialRefNo}</TableCell>
                      <TableCell className={classes.tableMargin}>{item.uom}</TableCell>
                      <TableCell className={classes.tableText}><TextField variant="outlined" value={formatNumbers(roundTo2Decimal(item.oldQuantity))} disabled /></TableCell>
                      <TableCell className={clsx(classes.tableArrow, classes.tableMargin)}><ArrowRightAltIcon className={classes.tableArrow} /></TableCell>
                      <TableCell className={classes.tableText}>
                        <TextField 
                          id={"quantity"+index}
                          variant="outlined" 
                          type="Number"
                          onInput={(e)=>{ 
                            e.target.value = Math.max(0, e.target.value).toString().slice(0,15)
                          }}
                          onChange={(e) => handleOnChange_text(e, index)}
                          value={item.quantity} 
                          errorMsg={errorMsg}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
          </React.Fragment>
        }
        actions={
          <div className={classes.readjustAction}>
            <Button className={classes.buttonSecondary} onClick={() => setOpenReadjustModal(!openReadjustModal)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleModal_confirm()}>Readjust</Button>
          </div>
        }
      />
      <Modal
        open={openConfirmModal}
        onClose={() => handleButtonClick_cancel()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Confirm Reorder?"
        content={
          <React.Fragment>
            These are your reorders:
            <List className={classes.reorderList}>
              { rawMaterial.map((item, index) => {
                return (
                  <ListItem key={index} className={classes.reorderListItem}>
                    {item.rawMaterialRefNo}&nbsp;&nbsp;&nbsp;{formatNumbers(roundTo2Decimal(item.oldQuantity))}{item.uom}&nbsp;&nbsp;to&nbsp;&nbsp;<span className={classes.reorderText}>{formatNumbers(roundTo2Decimal(item.quantity))}{item.uom}</span>
                  </ListItem>
                )
              })}
            </List>
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_confirm()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_reorder()}>Confirm</Button>
          </React.Fragment>
        }
      />
      <Alert open={openAlertModal} onConfirm={()=>handleButtonClick_exit()} onCancel={()=>handleOnChange_tab()} />
    </React.Fragment>
  );
}
