import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { GetCurrentFarmCapacity, GetCapacityCategoryByFarmId, GetAllBankAndCage, GetAllSoftzone, GetAllLocationBySoftzone, GetAllProduct, GetAllProductProcess } from "services/AdminPortal/CapacityService";

// initial state
const initialState = {
  farmCapacity: {},
  categories: [],
  category: {
    productId: []
  },
  bankList: [],
  softzoneList: [],
  locationList: [],
  product: [],
  process: []
};

const farmCapacity = createSlice({
  name: "farmCapacity",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.category = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateCategoryRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.category[key] = action.payload[key];
      })
    },
    resetCategory: (state) => {
      state.category = {
        productId: []
      };
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetCurrentFarmCapacity.fulfilled]: (state, action) => {
      state.farmCapacity = action.payload.result;
    },
    [GetCapacityCategoryByFarmId.fulfilled]: (state, action) => {
      state.categories = action.payload.result.map((category) => {
        return {
          ...category, 
          productId: category.productList.map((product) => {return product.id})
        }
      });
    },
    [GetAllBankAndCage.fulfilled]: (state, action) => {
      state.bankList = _.orderBy(action.payload.result, ["bankName"], ["asc"]);
    },
    [GetAllSoftzone.fulfilled]: (state, action) => {
      state.softzoneList = action.payload.result.items;
    },
    [GetAllLocationBySoftzone.fulfilled]: (state, action) => {
      state.locationList = action.payload.result[0].locations;
    },
    [GetAllProduct.fulfilled]: (state, action) => {
      state.product = _.orderBy(action.payload.result, ["productName"], ["asc"]);
    },
    [GetAllProductProcess.fulfilled]: (state, action) => {
      state.process = action.payload.result.sort();
    },
  },
});

// export actions
export const { setValues, updateRequest, updateCategoryRequest, addDeleteRequest, resetCategory, reset } = farmCapacity.actions;

// export the reducer
export default farmCapacity.reducer;