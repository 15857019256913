import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Popper from "@mui/material/Popper";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import styles from "assets/jss/shared-components/popperStyle.js";

import close from "assets/icons/white/close.svg";

const useStyles = makeStyles(styles);
export default function CustomPopper({children, title, content, closePopper, buttonClick, noButton, ...props}) {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <React.Fragment>
      {React.cloneElement(children, { ...children.props, ref: setAnchorEl })}
      {anchorEl &&
        <Popper
          {...props}
          className={clsx(props.className, classes.popper)}
          anchorEl={anchorEl} 
          transition
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper className={classes.container}>
                <ClickAwayListener onClickAway={closePopper}>
                  <div>
                    <span className={classes.arrow} ref={setArrowRef} />
                    {!noButton && <img className={classes.icon} src={close} alt="close-line" onClick={closePopper}/>}
                    {title && <Typography className={classes.title}>{title}</Typography>}
                    <Typography className={classes.content}>{content}</Typography>
                    { !noButton &&
                      <span className="flex justify-end">
                        <Button className={classes.button} onClick={buttonClick}>Okay</Button>
                      </span>
                    }
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      }
    </React.Fragment>
  );
}

CustomPopper.propTypes = {
  children: PropTypes.node,
  closePopper: PropTypes.func,
  buttonClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  noButton: PropTypes.bool,
};