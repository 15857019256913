import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import ImageMarker from 'react-image-marker';
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import Switch from "shared-components/Switch/Switch";
import Tabs from "shared-components/Tabs/Tabs";

import { useInterval } from 'common/utils';
import { controlModeLookup } from "enums/Lookups";
import { setDirty, setSuccess } from "store/general";
import { GetAllDeviceControl, SendControlCommandToDevice, SendControlCommandToDevices, GetControlMode, SetControlMode } from "services/UserPortal/ClimateService";
import { GetAllSensorControlDeviceFunction } from "services/UserPortal/CommonLookupService";
import { UpdateLightingDeviceSetting } from "services/AdminPortal/DeviceService";

import DeviceControlDetails from "./DeviceControlDetails";
import DeviceDetail from "./DeviceDetail";
import DetailModal from "./DetailModal";
import CO2CanisterDate from "./CO2CanisterDate";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceControlFloor(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const floorPlan = useSelector(store => store.user.climate.deviceControl.floorPlan);
  const functionList = useSelector(store => store.common.functionList);
  const [selectedFunction, setSelectedFunction] = React.useState(null);
  const [selectedControl, setSelectedControl] = React.useState(controlModeLookup[0].key);
  const [selectedDevice, setSelectedDevice] = React.useState({});
  const [selectedDevices, setSelectedDevices] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDetailModal, setOpenDetailModal] = React.useState(false);
  const [isTurnOn, setIsTurnOn] = React.useState(false);
  const [markers, setMarkers] = React.useState([]);
  const [tab, setTab] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnChange_text = (e) => {
    setSelectedDevice({...selectedDevice, value: e.target.value});
  };

  const handleButtonClick_switch = () => {
    setSelectedDevice({...selectedDevice, value: !isTurnOn ? 1 : 0});
    setIsTurnOn(!isTurnOn);
  };

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    setSelectedControl(e.target.value);
  };

  const handleButtonClick_adjust = () => {
    if (tab === 0) {
      dispatch(SendControlCommandToDevice({deviceId: selectedDevice.id, controlType: "Auto", controlValue: selectedDevice.value}))
      .then(({error}) => {
        if (!error) {
          dispatch(setDirty(false));
          setOpenModal(false);
          handleButtonClick_exit();
          dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
        }
      });
    } else {
      dispatch(UpdateLightingDeviceSetting({id: selectedDevice.id, isFollowNormalLight: selectedDevice.isFollowNormalLight, isFollowReceipt: selectedDevice.isFollowReceipt}))
      .then(({error}) => {
        if (!error) {
          dispatch(setDirty(false));
          dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
        }
      });
    }
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }

  const handleButtonClick_view = () => {
    dispatch(setDirty(openDetailModal));
    setOpenDetailModal(!openDetailModal);
  }

  const handleButtonClick_save = () => {
    if (selectedDevices.length) {
      dispatch(SendControlCommandToDevices(selectedDevices))
      .then((response) => {
        if (!response.error) {
          dispatch(setSuccess("Success! Changes will be reflected in a few minutes."));
          handleButtonClick_exit();
        }
      });
    } else {
      dispatch(SetControlMode({controlMode: selectedControl, floorId: props.selectedFloorSector.floor, sectorId: props.selectedFloorSector.sector, devcieFunctionId: selectedFunction}))
      .then(() => {
        handleButtonClick_exit();
      });
    }
  }

  const handleButtonClick_exit = () => {
    dispatch(setDirty(false));
    setSelectedDevices([]);
    setSelectedDevice({});
    dispatch(GetAllSensorControlDeviceFunction({isControl: true}))
    .then((response)=>{
      if (!response.error) {
        const result = response.payload.result;
        setSelectedFunction(result.length && result[0].id);
      }
    });
  }

  const getDeviceControl = (stopLoading) => {
    if (!isDirty && selectedFunction) {
      dispatch(GetControlMode({floorId: props.selectedFloorSector.floor, sectorId: props.selectedFloorSector.sector, devcieFunctionId: selectedFunction}))
      .then((response) => {
        setSelectedControl(response.payload.result);
        setTimeout(() => {
          setIsLoading(false);
       }, 1000);
      });
      getMarker(stopLoading);
    }
  }
  
  const getMarker = (stopLoading) => {
    if (props.selectedFloorSector) {
      dispatch(GetAllDeviceControl({floorId: props.selectedFloorSector.floor, sectorId: props.selectedFloorSector.sector, deviceFunctionId: selectedFunction, stopLoading}))
      .then(({payload}) => {
        if (payload && payload.result && payload.result.floor) {
          const result = payload.result.floor.sectors[0];
          let location = [];
          for (let obj of result.devices) {
            location = _.unionBy(location , [{ ..._.omit(obj, ["floorPlanY", "floorPlanX"]), top: obj.floorPlanY, left: obj.floorPlanX }], "id");
          }
          setMarkers(location);
        }
      })
    }
  }

  const handleOnClick_marker = (device) => {
    if (device.value) {
      handleModal();
      setSelectedDevice(device);
      setIsTurnOn(Boolean(device.value));
    } else {
      dispatch(setDirty(true));
      let payload = _.cloneDeep(selectedDevices);
      const index = selectedDevices.findIndex((item) => item.deviceId === device.id);
      if (index !== -1) {
        payload.splice(index,1);
      } else {
        payload = [...payload, {...device, deviceId: device.id, controlType: "Auto", controlValue: 1}];
      }
      if (payload.length === 0) {
        dispatch(setDirty(false));
      }
      setSelectedDevices(payload);
    } 
  }
  
  const CustomMarker = (obj) => {
    if (obj) {
      const index = selectedDevices.findIndex((item) => item.deviceId === obj.id);
      return (
        <div className={classes.markerDiv} onClick={() => handleOnClick_marker(obj)}>
          <div className={clsx(classes.marker, obj.value ? classes.on : classes.off, index !== -1 && classes.selected)}>{obj.deviceRefNo.substr(0, obj.deviceRefNo.indexOf('-'))}</div>
          <Typography className={classes.markerText}>{obj.deviceRefNo}</Typography>
        </div>
      );
    }
  };
  
  React.useEffect(() => {
    getDeviceControl();
  },[props.selectedFloorSector, selectedFunction]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllSensorControlDeviceFunction({isControl: true}))
    .then((response)=>{
      if (!response.error) {
        const result = response.payload.result;
        setSelectedFunction(result.length && result[0].id);
      }
    });
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getDeviceControl(true);
  });

  const renderName = () => {
    const number = props.selectedFloorSector.name.replace("Floor ", "").split(" Sector ");
    if (Number(number[1]) === 1) {
      return 'Chamber ' + ((Number(number[0]) * (Number(number[1]) + 1))-1)
    } else if (Number(number[1]) === 2) {
      return 'Chamber ' + (Number(number[0]) * Number(number[1]))
    }
  }

  const func = functionList.find(({id}) => selectedFunction === id);
  return (
    <React.Fragment>
      <Card 
        title={renderName()}
        classes={{
          root: classes.cardPaper,
        }}
        action={
          <React.Fragment>
            <Typography className={classes.label}>Device Function:</Typography>
            <Select
              className={classes.cardActionDropdown}
              name="deviceFunctionId"
              onChange={(e)=>setSelectedFunction(e.target.value)}
              placeholder="Select a device function"
              value={selectedFunction}
              disabled={isDirty}
            >
              {functionList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.deviceFunctionName}</MenuItem>;
              })} 
            </Select>
            <Typography className={classes.label}>Control Mode:</Typography>
            <Select
              className={classes.cardActionDropdown}
              name="mode"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a mode"
              value={selectedControl}
              disabled={isDirty}
            >
              {controlModeLookup.map((item, index) => {
                return <MenuItem key={index} value={item.key}>{item.displayName}</MenuItem>;
              })} 
            </Select>
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            {func && func.deviceFunctionName.includes("CO2") 
            ? <CO2CanisterDate data={markers} isDirty={isDirty} getDeviceControl={getDeviceControl} isLoading={isLoading} setIsLoading={setIsLoading} />
            : <div />
            }
            <div>
              <Button 
                className={classes.buttonSecondary}
                onClick={()=>handleButtonClick_exit()}
                disabled={!isDirty}
              >
                Cancel
              </Button>
              <Button 
                className={clsx(classes.button, classes.buttonMargin)}
                onClick={()=>handleButtonClick_view()}
                disabled={!isDirty}
              >
                View Details
              </Button>
              <Button 
                className={clsx(classes.button, classes.buttonMargin)}
                onClick={()=>handleButtonClick_save()}
                disabled={!isDirty}
              >
                {selectedControl === "Manual" ? "Save" : "Turn On"}
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <div className={classes.floorPlanView}>
          { floorPlan && floorPlan.floorPlanDiagramUrl 
            ? <React.Fragment>
                <ImageMarker
                  src={floorPlan.floorPlanDiagramUrl}
                  markers={markers}
                  markerComponent={CustomMarker}
                />
              </React.Fragment>
            : <Typography className={classes.emptyLabel}>No Floor Plan</Typography>
          }
        </div>
        <div className={classes.legend}>
          <div className={clsx(classes.colorBox, classes.off)} /> Device Off
          <div className={clsx(classes.colorBox, classes.notUse)} /> Device Not in Use
          <div className={clsx(classes.colorBox, classes.on)} /> Device On
          <div className={clsx(classes.colorBox, classes.selected)} /> Device Selected
          <div className={clsx(classes.colorBox, classes.notAvailable)} /> Not Available
        </div>
        {/* <div className={classes.legend}>
          <img className={clsx(classes.icon, classes.legendIcon)} src={light} alt="light" /> Light
          <img className={clsx(classes.icon, classes.legendIcon)} src={device} alt="device" /> Device
          <img className={clsx(classes.icon, classes.legendIcon)} src={cage} alt="cage" /> Cage
        </div> */}
      </Card>
      {selectedDevices && <DeviceControlDetails selectedDevices={selectedDevices} selectedFunction={selectedFunction} />}
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        title={"Adjustment for Device "+selectedDevice.deviceRefNo}
        content={
          <React.Fragment>
            <Tabs 
              tabs={["Light Adjustment", "Device Details"]}
              value={tab} 
              onChange={(e, value) => setTab(value)}
            />
            {tab === 0
            ? <Grid container spacing={4} className={classes.modalGrid}>
                <Grid item xs={5} className={classes.modalGridItem}>
                  <Typography className={classes.label}>Device Switch:</Typography>
                </Grid>
                <Grid item xs={7} className={classes.modalGridItem}>
                  <Switch 
                    checked={isTurnOn}
                    onChange={() => handleButtonClick_switch()}
                  />
                </Grid>
                {func && func.deviceFunctionName !== "CO2 Controller" &&
                  <React.Fragment>
                    <Grid item xs={5} className={classes.modalGridItem}>
                      <Typography className={classes.label}>Device Intensity:</Typography>
                    </Grid>
                    <Grid item xs={7} className={classes.modalGridItem}>
                      <TextField
                        id="controlValue"
                        type="Number"
                        variant="outlined" 
                        onInput={(e)=>{ 
                          e.target.value = e.target.value > 100 ? 100 : Math.max(1, parseInt(e.target.value)).toString().slice(0,3)
                        }}
                        onChange={(e)=>handleOnChange_text(e)}
                        placeholder="Enter value"
                        value={!isTurnOn ? "" : selectedDevice.value}
                        disabled={!isTurnOn}
                      />
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
            : <DeviceDetail selectedFunction={selectedFunction} selectedDevice={selectedDevice} />
            }
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_adjust()}>Confirm</Button>
          </React.Fragment>
        }
      />
      <DetailModal openDetailModal={openDetailModal} selectedDevices={selectedDevices} selectedFunction={selectedFunction} handleButtonClick_view={handleButtonClick_view} />
    </React.Fragment>
  );
}
