import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"

import { setValues, updateRequest } from "../store/assignment";

import stop from "assets/img/stop.svg";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

const Maps = (props) => {
  let google = window.google;
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapRef = React.useRef(null);
  const startPoint = useSelector(store => store.user.delivery.assignment.startPoint);
  const inBetweenStopProcessingTime = useSelector(store => store.user.delivery.assignment.inBetweenStopProcessingTime);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (mapRef.current && props.deliveryTime != "Invalid Date") {
      let lat = startPoint.latitude;
      let lng = startPoint.longitude;
      const defaultLatLng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
          zoom: 11,
          center: defaultLatLng,
          zoomControl: true,
        };
      setMap(new window.google.maps.Map(mapRef.current, mapOptions));
    }
  }, [mapRef, startPoint, props.stops, props.deliveryTime, props.returnToStartPoint])

  React.useEffect(() => {
    if (map && props.stops.length && props.deliveryTime && props.deliveryTime != "Invalid Date") {
      const geocoder = new google.maps.Geocoder();
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});

      directionsRenderer.setMap(map);
      
      const start = new google.maps.LatLng(startPoint.latitude, startPoint.longitude);
      const end = props.returnToStartPoint ? new google.maps.LatLng(startPoint.latitude, startPoint.longitude) : props.stops[props.stops.length-1].deliveryAddress;
      let waypts = [];
      for (let i = 0; i < props.stops.length; i++) {
        waypts.push({
          location: props.stops[i].deliveryAddress,
          stopover: true,
        });
      }

      const request = {
        origin: start,
        destination: end,
        waypoints: waypts,
        optimizeWaypoints: props.isFirstOpen,
        travelMode: 'DRIVING',
        drivingOptions: {
          departureTime: moment(props.deliveryTime).isAfter(moment()) ? new Date(props.deliveryTime) : new Date()
        }
      };
      directionsService.route(request)
      .then((response) => {
        directionsRenderer.setDirections(response);
        const result = response.routes[0];
        let payload = _.map(result.waypoint_order, (item, i) => {
          geocoder.geocode({'address': props.stops[i].endPoint}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const infoWindow = new google.maps.InfoWindow();
              let marker = new google.maps.Marker({
                position: results[0].geometry.location,
                map: map,
                icon: stop,
              });
              infoWindow.setContent("<div class="+classes.infoWindow+">Stop "+(i+1)+"</div>");
              infoWindow.open(map, marker);
            }
          });
          return ({
            ...props.stops[item], 
            routeOrder: i+1, 
            startPoint: result.legs[i].start_address,
            endPoint: result.legs[i].end_address,
            estimateTimeInMinute: Math.round(result.legs[i].duration.value/60),
            processingTimeInMinute: inBetweenStopProcessingTime,
            isLastRoute: props.returnToStartPoint || i < props.stops.length-1 ? false : true
          })
        })
        //Starting point
        const infoWindow = new google.maps.InfoWindow();
        let marker = new google.maps.Marker({
          map: map,
          title: location.value,
          position: start,
          icon: stop,
        });
        infoWindow.setContent("<div class="+classes.infoWindow+">Starting Point</div>");
        infoWindow.open(map, marker);
        if (props.returnToStartPoint) {
          payload.push({
            routeOrder: result.legs.length, 
            startPoint: result.legs[result.legs.length-1].start_address,
            endPoint: result.legs[result.legs.length-1].end_address,
            estimateTimeInMinute: Math.round(result.legs[result.legs.length-1].duration.value/60),
            processingTimeInMinute: 0,
            isLastRoute: true
          });
        }
        dispatch(updateRequest({deliveryRoute: JSON.stringify(response)}));
        dispatch(setValues({deliveryTripDetails: payload}));
        
        // toggle optimizing waypoint
        if (props.isFirstOpen) {
          props.setStops(payload.slice(0, -1));
          props.setIsFirstOpen(false);
        }
      })
      .catch((e) => window.alert("Directions request failed due to " + e));
    }
  }, [map]);

  return (
    <div className={classes.assignMap} ref={mapRef}></div>
  );
};

export default Maps;
