// User Configuration
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { rowsPerTable } from "config";
import { reset } from "../store/configuration";
import { humanResourceTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllEmployees, DeleteEmployee } from "services/AdminPortal/HumanResourceService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/humanResourceStyle.js";

import { filterActionTableHead } from "common/functions";
import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function HumanResource() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const totalCount = useSelector(store => store.admin.humanResource.configuration.totalCount);
  const employees = useSelector(store => store.admin.humanResource.configuration.employees);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('employeeId');
  const [page, setPage] = React.useState(0);
  const [employeeId, setEmployeeId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleModal = (id) => {
    setEmployeeId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteEmployee(employeeId))
    .then(() =>{
      dispatch(GetAllEmployees({page: 0}));
      resetState();
    })
  }
  
  const resetState = () => {
    setEmployeeId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  React.useEffect(() => {
    dispatch(GetAllEmployees({page}));
  },[page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      { totalCount > 0 
      ? <React.Fragment>
          <Card 
            title = "User Payment Scheme & Schedule "
          >
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(humanResourceTableHead, canWrite)}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { employees.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.employeeId}</TableCell>
                      <TableCell><Typography className={classes.ellipsis}>{item.employeeName}</Typography></TableCell>
                      <TableCell>{item.workforceType}</TableCell>
                      <TableCell>{item.hourRate}</TableCell>
                      <TableCell>{item.otHourRate}</TableCell>
                      <TableCell>{item.employeeStatus}</TableCell>
                      <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/human-resource/user-configuration/"+item.userId}}>
                              <IconButton type="edit" />
                            </Link>
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal(item.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete the payment and working details of this employee? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : <Empty description="There is no employee to display." />
      }
    </React.Fragment>
  );
}
