import {
  icon_24, 
  button, 
  buttonSecondary, 
  whiteColor,
  grayColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle";

import {
  table,
  icon,
} from "assets/jss/components/commonStyle.js";

const userSettingsStyle = {
  icon,
  icon_24,
  button,
  buttonSecondary,
  table,
  container: {
    width: "51.250vw",
    margin: "auto",
  },
  content: {
    margin: "2.240vw 7.292vw",
    padding: 0
  },
  profileImg: {
    position: "relative",
    height: "8.646vw",
    width: "8.646vw",
    borderRadius: "50%"
  },
  img: {
    position: "absolute",
    height: "8.646vw",
    width: "8.646vw",
    borderRadius: "50%",
  },
  iconButton: {
    position: "absolute",
    backgroundColor: secondaryColor[0],
    border: "0.355vw solid "+ whiteColor,
    borderRadius: "50%",
    height: "2.487vw",
    width: "2.487vw", 
    right: 0,
    bottom: 0,
  },
  cameraIcon: {
    borderRadius: "50%",
    padding: "0.313vw",
  },
  closeIcon: {
    height: "fit-content",
    verticalAlign: "top"
  },
  label: {
    margin: "auto",
  },
  passwordText: {
    cursor: "pointer",
    fontSize: "0.938vw",
    color: secondaryColor[0],
    alignSelf: "center"
  },
  passwordTextDisabled: {
    cursor: "context-menu",
    fontSize: "0.938vw",
    color: primaryColor[1],
    alignSelf: "center"
  },
  actions: {
    justifyContent: "space-between",
    margin: "0 7.292vw",
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "11.510vw",
    height: "11.510vw",
    border: "0.104vw solid "+ secondaryColor[0],
    fontSize: "1.094vw",
    fontWeight: "500",
    color: primaryColor[3],
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: secondaryColor[0],
      color: whiteColor,
    }
  },
  logoImage: {
    marginTop: "2.083vw",
    marginBottom: "1.094vw",
    width: "3.333vw",
    height: "3.333vw",
    color: secondaryColor[0],
  },
  logoTitle: {
    padding: "0vw 1.771vw 2.083vw 1.823vw",
    lineHeight: "1.302vw",
  },
  white: {
    color: whiteColor
  },
  filter: {
    display: "flex",
    alignItems: "center",
  },
  filterLabel: {
    fontWeight: 500,
    marginRight: "0.750vw",
    whiteSpace: "nowrap"
  },
  download: {
    display: "flex",
    alignItems: "center",
    "& a:not(:first-child)": {
      marginLeft: "1.250vw"
    }
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0.521vw",
    width: "fit-content",
    marginTop: "1.042vw",
    borderRadius: "0.521vw",
    backgroundColor: "black",
    cursor: "pointer"
  },
  downloadIcon: {
    width: "2.500vw",
    height: "2.500vw", 
    color: "#a4c639",
    marginRight: "0.938vw"
  },
  appleDownloadIcon: {
    width: "2.500vw",
    height: "2.500vw", 
    color: "white",
    marginRight: "0.938vw"
  },
  downloadText: {
    color: "white", 
    fontWeight: 500,
  },
  downloadText2: {
    color: "white", 
    fontSize: "0.625vw"
  },
  instruction: {
    fontSize: "0.729vw",
    marginTop: "0.260vw",
    color: grayColor[0],
  }
};
  
export default userSettingsStyle;
        