import {
  icon_24,
  icon_48,
  button,
  buttonSecondary,
  cardRadius,
  border,
  backgroundColor,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  link,
  icon,
} from "assets/jss/components/commonStyle.js";

const reportStyle = {
  icon_24,
  icon_48,
  cardPaper,
  table,
  button,
  buttonSecondary,
  cardRadius,
  link,
  icon,

  cardHeader: {
    display: "flex",
    alignItems: "center",
    "& :not(:first-child)": {
      marginLeft: "1.042vw",
    }
  },

  card: {
    backgroundColor,
    ...cardRadius,
    position: "relative",
    height: "auto",
    padding: "1.563vw",
    width: "100%",
    "&:not(:last-child)": {
      marginRight: "1.042vw"
    }
  },
  cardItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.250vw",
    textAlign: 'right',
  },
  title: {
    fontWeight: 500,
    fontSize: "1.250vw",
  },
  cardLink: {
    ...link,
    textAlign: 'right',
    fontWeight: 500,
  },
  label: {
    color: primaryColor[4],
    whiteSpace: "nowrap"
  },
  cardMargin: {
    marginTop: "1.042vw"
  },
  cardPaperHeader: {
    paddingTop: "1.563vw",
    paddingBottom: "1.250vw"
  },
  cardListItem: {
    "&:not(:first-child)": {
      marginTop: "1.042vw"
    }
  },
  modal: {
    width: "41.667vw"
  },
  modalContainer: {
    ...cardRadius,
    border,
    marginTop: "0.833vw",
    height: "15.625vw",
    overflow: "overlay",
    textAlign: "left"
  },
  modalTable: {
    ...cardRadius,
    '& thead > tr > th': {
      borderTopLeftRadius: "0.833vw",
      borderTopRightRadius: "0.833vw",
      left: "unset"
    },
  },
  highChart: {
    height: "15.625vw",
    width: "100%",
  },
  mainTable: {
    '& thead > tr > th:last-child': {
      width: "11vw",
      minWidth: "11vw"
    },
  },

  // Create
  content: {
    display: "flex",
    padding: "1.563vw",
    position: "relative",
    height: "29vw",
  },
  paper: {
    position: "relative",
    overflow: "overlay",
    height: "29vw",
    marginRight: "-1.563vw",
    paddingRight: "1.563vw"
  },
  formIconButton: {
    border,
    position: "absolute",
    right: "-2.292vw",
    top: "-0.052vw",
    borderRadius: 0,
    borderLeft: 0,
    backgroundColor: 'white',
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
  },
  formIconButton2: {
    border,
    position: "absolute",
    left: "-1.979vw",
    top: 0,
    borderRadius: 0,
    borderLeft: 0,
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  formButton: {
    display: 'flex',
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  //Report
  reportField: {
    width: "70% !important",
    margin: "auto !important"
  },
  highChart2: {
    // height: "33.698vw",
    width: "100%",
  },
  buttonRight: {
    display: 'flex',
    marginLeft: 'auto'
  }
};

export default reportStyle;
  