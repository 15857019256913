import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";

import { renderValue } from "common/functions";
import { CreateOrUpdateRawMaterialReserve } from "services/UserPortal/RawMaterialService";
import { GetNotYetStartedWorkOrderList, GetProductList, GetProductTaskList, GetProductGrowthProcessList, GetRawMaterialList } from "services/UserPortal/CommonLookupService";

import { isPositiveDecimal } from "common/validations";
import { setDirty, setSearchText } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, reset } from "../store/reserve";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditReserve() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const reservation = useSelector(store => store.user.rawMaterial.reserveRM.reservation);
  const workOrderList = useSelector(store => store.common.unstartedWorkOrderList);
  const productList = useSelector(store => store.common.productList);
  const processList = useSelector(store => store.common.processList);
  const taskList = useSelector(store => store.common.taskList);
  const rawMaterialList = useSelector(store => store.common.rawMaterialList);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: Number(e.target.value)}));
  };

  const handleOnChange_select = (e) => {
    if (e.target.name === "productId") {
      const product = productList.find(({id}) => id == e.target.value);
      dispatch(updateRequest({[e.target.name]: e.target.value, productVersionId: product.productVersionId, productGrowthProcessId: null, productTaskId: null}));
    } else if (e.target.name === "productGrowthProcessId") {
      dispatch(updateRequest({[e.target.name]: e.target.value, productTaskId: null}));
    } else {
      dispatch(updateRequest({[e.target.name]: e.target.value}));
    }
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateRawMaterialReserve(reservation))
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            history.push("/user/raw-material/reserve");
          }
        });
      });
    }
  };

  const validateFields = () => {
    if (!reservation.workOrderId) {
      setErrorMsg({field: "workOrderId", msg: "Please select a work order"});
      return false;
    }
    if (!reservation.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    if (!reservation.productGrowthProcessId) {
      setErrorMsg({field: "productGrowthProcessId", msg: "Please select a process"});
      return false;
    }
    if (!reservation.productTaskId) {
      setErrorMsg({field: "productTaskId", msg: "Please select a task"});
      return false;
    }
    if (!reservation.rawMaterialId) {
      setErrorMsg({field: "rawMaterialId", msg: "Please select a raw Material"});
      return false;
    }
    if (!reservation.qtyReserve || reservation.qtyReserve === "0") {
      setErrorMsg({field: "qtyReserve", msg: "Please enter reserve quantity"});
      return false;
    }
    if (!isPositiveDecimal(reservation.qtyReserve)) {
      setErrorMsg({field: "qtyReserve", msg: "Please enter a valid reserve quantity"});
      return false;
    }
    
    setErrorMsg(false);
    return true;
  };

  React.useEffect(() => {
    if (reservation.workOrderId) {
      dispatch(GetProductList(reservation.workOrderId));
    }
  },[reservation.workOrderId]);

  React.useEffect(() => {
    if (reservation.productGrowthProcessId) {
      dispatch(GetProductTaskList(reservation.productGrowthProcessId));
      dispatch(GetRawMaterialList(reservation.productGrowthProcessId));
    }
  },[reservation.productGrowthProcessId]);

  React.useEffect(() => {
    if (reservation.productVersionId) {
      dispatch(GetProductGrowthProcessList(reservation.productVersionId));
    }
  },[reservation.productVersionId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetNotYetStartedWorkOrderList());
    dispatch(setSearchText(""));
    dispatch(setAddNewButton(true));
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(reset());
      dispatch(resetNav());
    }
  },[]);

  const selectedRawMaterial = _.find(rawMaterialList, ({id}) => id === reservation.rawMaterialId);
  return (
    <React.Fragment>
      <Card 
        title = "Raw Materials Reservation"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.push("/user/raw-material/reserve")}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Typography className={classes.formTitle}>Reservation Details</Typography>
        <Grid container spacing={4}>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>Work Order No:</Typography>
            <Select
              name="workOrderId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a work order"
              value={renderValue(reservation.workOrderId)}
              errorMsg={errorMsg}
            >
              {workOrderList && workOrderList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>Product:</Typography>
            <Select
              name="productId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a product"
              value={renderValue(reservation.productId)}
              errorMsg={errorMsg}
              disabled={!reservation.workOrderId}
            >
              {productList && productList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>Process:</Typography>
            <Select
              name="productGrowthProcessId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a process"
              value={renderValue(reservation.productGrowthProcessId)}
              errorMsg={errorMsg}
              disabled={!reservation.productId}
            >
              {processList && processList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.processName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>Add Task:</Typography>
            <Select
              name="productTaskId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a task"
              value={renderValue(reservation.productTaskId)}
              errorMsg={errorMsg}
              disabled={!reservation.productGrowthProcessId}
            >
              {taskList && taskList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.taskName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={8}/>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>RM Name:</Typography>
            <Select
              name="rawMaterialId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a Raw Material"
              value={renderValue(reservation.rawMaterialId)}
              errorMsg={errorMsg}
              disabled={!reservation.productGrowthProcessId}
            >
              {rawMaterialList && rawMaterialList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.rawMaterialName}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>Measurement Unit:</Typography>
            <TextField
              id="uom"
              variant="outlined" 
              value={selectedRawMaterial ? selectedRawMaterial.uom : ""}
              disabled
            />
          </Grid>
          <Grid item xs={4} className="flex">
            <Typography className={classes.formLabel}>Qty Reserved:</Typography>
            <TextField
              id="qtyReserve"
              type="Number"
              variant="outlined" 
              placeholder="Enter reserve quantity"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(reservation.qtyReserve)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
