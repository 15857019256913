import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
// @mui/icons
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
// core components
import Card from "shared-components/Card/Card";

import { GetEquipmentOverview } from "services/UserPortal/MonitoringService";

import enter from "assets/icons/orange/enter.svg";
import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function Equipment() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const equipmentOverview = useSelector(store => store.user.monitoring.equipment.equipmentOverview.equipmentSummaryInfoDtos);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetEquipmentOverview());
    // componentDidUnmount
    return () => {
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Equipment Monitoring"
      >
        <Grid container spacing={4}>
          {equipmentOverview && equipmentOverview.map((item, index) => {
            return (
              <Grid key={index} item xs={3}>
                <Paper className={classes.envPaper}>
                  <div className={classes.equipTitle}>
                    <Typography className={classes.envValueBold}>{item.equipmentName}</Typography>
                    <PrecisionManufacturingOutlinedIcon className={clsx(classes.icon_38, classes.equipIcon)} />
                  </div>
                  <Typography>In Use: {item.inUsed}</Typography>
                  <Typography>Down: {item.down}</Typography>
                  <Typography>Maintenance: {item.maintenance}</Typography>
                  <IconButton 
                    className={classes.iconButton}
                    onClick={() => history.push("/user/monitoring/equipment/"+item.equipmentName)}
                  >
                    <img className={clsx(classes.icon_24, classes.enterButton)} src={enter} alt="enter" />
                  </IconButton>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Card>
    </React.Fragment>
  );
}
