import { createSlice } from "@reduxjs/toolkit";

import { ExecuteGetAllRobots, ExecuteGetAllMission, ExecuteGetMapByName, GetUpcomingMissions } from "services/UserPortal/AMRService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  result: [],
  upcoming: [],
  task: {},
  nodeList: [],
  //lookup
  amrList: [],
  cageList: [],
};

const amrAdHoc = createSlice({
  name: "amrAdHoc",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.task[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [ExecuteGetAllRobots.fulfilled]: (state, action) => {
      state.amrList = action.payload.result;
    },
    [ExecuteGetAllMission.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
    [GetUpcomingMissions.fulfilled]: (state, action) => {
      state.upcoming = action.payload.result;
    },
    [ExecuteGetMapByName.fulfilled]: (state, action) => {
      state.nodeList = action.payload.result.nodes;
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = amrAdHoc.actions;

// export the reducer
export default amrAdHoc.reducer;