import { createSlice } from "@reduxjs/toolkit";

import { SearchEquipmentCost, SearchEquipmentStatus, SearchEquipmentMaintenanceHistory } from "services/UserPortal/EquipmentService";

// initial state
const initialState = {
  reserveTotalCount: 0,
  issueTotalCount: 0,
  costTotalCount: 0,
  statusTotalCount: 0,
  maintenanceTotalCount: 0,
  reserveResults: [],
  issueResults: [],
  costResults: [],
  statusResults: [],
  maintenanceResults: [],
};

const equipSearch = createSlice({
  name: "equipSearch",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [SearchEquipmentCost.fulfilled]: (state, action) => {
      state.costTotalCount = action.payload.result.totalCount;
      state.costResults = action.payload.result.items;
    },
    [SearchEquipmentStatus.fulfilled]: (state, action) => {
      state.statusTotalCount = action.payload.result.totalCount;
      state.statusResults = action.payload.result.items;
    },
    [SearchEquipmentMaintenanceHistory.fulfilled]: (state, action) => {
      state.maintenanceTotalCount = action.payload.result.totalCount;
      state.maintenanceResults = action.payload.result.items;
    },
  },
});

// export actions
export const { setValues, reset } = equipSearch.actions;

// export the reducer
export default equipSearch.reducer;