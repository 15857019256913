import React, {useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import IconButton from "shared-components/Button/IconButton";

import { setLoading, setDirty } from "store/general";
import { minDate } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers } from 'common/functions';
import { updateRequest, setValues } from "../store/deliveryOrder";
import { deliveryOrderDetailTableHead } from "enums/UserPortal/TableHeaders";
import { GetDeliveryOrderById, UpdateDeliveryOrder, UploadDeliveryOrderImageList } from "services/UserPortal/DeliveryService";

import { useRouteCanWrite } from "hooks";
import PrintFormat from "layouts/PrintFormat";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

export default function Details() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const componentRef = useRef();
  const inputFile = React.createRef();
  const isDirty = useSelector(store => store.general.isDirty);
  const details = useSelector(store => store.user.delivery.deliveryOrder.details);
  const uploadImage = useSelector(store => store.user.delivery.deliveryOrder.uploadImage);
  const [listIndex, setListIndex] = useState(false);
  const [, setOnBeforeGetContentResolve] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [imageFile, setImageFile] = React.useState(details.deliveryOrderImages ? new Array(details.deliveryOrderImages.length).fill(null) : []);
  const [zoomModal, setZoomModal] = useState(false);
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [remarks, setRemarks] = useState(null);
  
  const handleOnClick_zoom = (item, index) => {
    setListIndex(index);
    setZoomModal(item);
  }

  const onClickArrow = (index) => {
    if (listIndex + index >= 0  && listIndex + index <= (uploadImage.deliveryOrderImages && uploadImage.deliveryOrderImages.length -1)) {
      setListIndex(listIndex + index);
      if (uploadImage.deliveryOrderImages && uploadImage.deliveryOrderImages[listIndex + index]) {
        setZoomModal(uploadImage.deliveryOrderImages[listIndex + index].imageUrl);
      }
    }
  }

  const handleOnEdit = () => {
    setIsOnEdit(!isOnEdit);
    setRemarks(details.remarks);
  }

  const handleOnChange_text = (e) => {
    setRemarks(e.target.value);
  };
  
  const handleButtonClick_save = () => {
    let payload = {..._.pick(details, ['id']), isRemark: true, remarks: remarks};
    dispatch(UpdateDeliveryOrder(payload))
    .then((response) => {
      if (!response.error) {
        setIsOnEdit(false);
        getDODetails();
      }
    });
  };

  const onButtonClick_image = () => {
    inputFile.current.click();
  }
  
  const handleOnChange_image = (e) => {
    dispatch(setDirty(true));
    const payload = _.map(e.target.files, (item) => {
      return {imageUrl: URL.createObjectURL(item), fileName: item.name, deliveryOrderId: details.id};
    });
    let currentImages = uploadImage.deliveryOrderImages ? _.cloneDeep(uploadImage.deliveryOrderImages) : []
    dispatch(updateRequest({deliveryOrderImages : [...currentImages, ...payload].slice(0, 5)}));
    setImageFile([...imageFile, ...e.target.files].slice(0, 5));
  }

  const handleButtonClick_deleteImage = (index) => {
    dispatch(setDirty(true));
    if (uploadImage.deliveryOrderImages[index].id){
      let deleted = _.cloneDeep(uploadImage.deliveryOrderImages);
      deleted[index].isDeleted = true;
      dispatch(updateRequest({deliveryOrderImages: deleted}));
      imageFile.splice(index, 1)
    } else {
      let deleted = _.cloneDeep(uploadImage.deliveryOrderImages);
      deleted.splice(index, 1);
      dispatch(updateRequest({deliveryOrderImages: deleted}));
      imageFile.splice(index, 1)
    }
  }

  const handleButtonClick_print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Delivery_Order_"+details.deliveryOrderRefNo,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        setOnBeforeGetContentResolve(resolve);
      });
    },
  })

  const getDODetails = (stopLoading) => {
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "details");
    dispatch(GetDeliveryOrderById({id: path[index+1], stopLoading}))
    .then((response) => {
      if (response.error) {
        history.push("/user/delivery/delivery-order");
      } else {
        setImageFile(response.payload.result.deliveryOrderImages ? new Array(response.payload.result.deliveryOrderImages.length).fill(null) : [])
      }
    });
  }

  React.useEffect(() => {
    if (isDirty) {
      Promise.all([dispatch(setLoading(true))])
      .then(() => {
        const files = imageFile.filter((item) => item != null);
        if (files.length) {
          dispatch(UploadDeliveryOrderImageList(files))
          .then((response) => {
            let order = _.cloneDeep(uploadImage);
            let images = _.cloneDeep(uploadImage.deliveryOrderImages);
            images = _.union(_.filter(images, ({id}) => id), _.map(response.payload.result, (item) => {item.deliveryOrderId = details.id; return item}));
            order.deliveryOrderImages = images;
            dispatch(UpdateDeliveryOrder(order))
            .then(({error}) => {
              if (!error) {
                getDODetails();
              }
            });
            dispatch(setDirty(false));
          })
        } else {
          let order = _.cloneDeep(uploadImage);
          dispatch(UpdateDeliveryOrder(order))
          .then(({error}) => {
            if (!error) {
              getDODetails();
            }
          });
          dispatch(setDirty(false));
        }
      })
    }
  },[imageFile, isDirty]);

  // componentDidMount
  React.useEffect(() => {
    getDODetails();
    // componentDidUnmount
    return () => {
      dispatch(setValues({ details: {}, uploadImage: {} }));
    }
  },[]);

  useInterval(() => {
    getDODetails(true);
  });

  if (details) {
    return (
      <React.Fragment>
        <Card 
          title={"Details of "+details.deliveryOrderRefNo}
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>history.goBack()}
              >
                Go Back
              </Button>
              <Button
                className={classes.button}
                onClick={handleButtonClick_print}
              >
                Print
              </Button>
            </React.Fragment>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={2} className={classes.detailLabel}>
              Delivery Date:
            </Grid>
            <Grid item xs={4}>
              {moment(details.actualDeliveryDate).isAfter(minDate) ? moment(details.actualDeliveryDate).format("DD/MM/YYYY") : moment(details.deliveryDate).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Delivery Address:
            </Grid>
            <Grid item xs={4}>
              {details.deliveryAddress}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Customer:
            </Grid>
            <Grid item xs={4}>
              {details.customerName}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Collect Person:
            </Grid>
            <Grid item xs={4}>
              {details.contactPerson}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Contact Number:
            </Grid>
            <Grid item xs={4}>
              {details.contactDetail}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Driver&apos;s Name:
            </Grid>
            <Grid item xs={4}>
              {details.driverName}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Driver&apos;s Number:
            </Grid>
            <Grid item xs={4}>
              {details.driverNumber}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              Reference Number:
            </Grid>
            <Grid item xs={4}>
              {details.deliveryOrderRefNo}
            </Grid>
            {/* <Grid item xs={2} className={classes.detailLabel}>
              PO Number:
            </Grid>
            <Grid item xs={4}>
              {details.poNumber}
            </Grid>
            <Grid item xs={2} className={classes.detailLabel}>
              SO Number:
            </Grid>
            <Grid item xs={4}>
              {details.saleOrderNumber}
            </Grid> */}
          </Grid>
          <Paper className={classes.paper} elevation={0}>
            <Table
              className={classes.table}
              header={deliveryOrderDetailTableHead}
            >
              { details.finishedGoodsProcesses && details.finishedGoodsProcesses.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.product_Id}</TableCell>
                    <TableCell>{item.isWeightOrder ? formatNumbers(item.weight) : formatNumbers(item.numberOfPacket)}</TableCell>
                    <TableCell>{item.uom}</TableCell>
                    <TableCell>{item.doNumberOfPacket}</TableCell>
                    <TableCell>{item.doRemarks}</TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </Paper>
          <div className={classes.labelContainer}>
            <Typography className={classes.detailLabel}>Remarks:</Typography>
            {isOnEdit
            ? <div>
                <IconButton 
                  type="confirm"
                  onClick={() => handleButtonClick_save()}
                />
                <IconButton 
                  type="close"
                  onClick={() => handleOnEdit()}
                />
              </div>
            : <IconButton type="edit" onClick={()=>handleOnEdit()} />
            }
          </div>
          <TextField
            className={classes.detailText}
            id="remarks"
            variant="outlined"
            multiline 
            minRows="3"
            inputProps={{ maxLength: 50 }}
            onChange={(e) => handleOnChange_text(e)}
            value={isOnEdit ? remarks : details.remarks}
            disabled={!isOnEdit}
          />
          <Typography className={classes.detailLabel}>Photos:</Typography>
          <Grid container spacing={2} className={classes.imageGrid}>
            {uploadImage.deliveryOrderImages && uploadImage.deliveryOrderImages.map((item, index) => {
              return (
                <Grid key={index} item xs={1}>
                  <div className={classes.image}>
                    <Button className={classes.deleteImage} onClick={()=>handleButtonClick_deleteImage(index)}><CloseIcon /></Button>
                    <img src={item.imageUrl} className={classes.imageBorder} onClick={()=>handleOnClick_zoom(item.imageUrl, index)} />
                  </div>
                </Grid>
              )
            })}
            {canWrite &&
              <Grid item xs={3}>
                <input 
                  ref={inputFile} 
                  style={{"display": "none"}} 
                  type="file" 
                  accept="image/*"
                  onChange={(e) => handleOnChange_image(e)}
                  multiple
                />
                <div style={{width: "fit-content"}}>
                  <Button
                    className={classes.buttonSecondary}
                    startIcon={<AddIcon />}
                    onClick={()=>onButtonClick_image()}
                    disabled={imageFile.length === 5}
                  >
                    Upload Image File(s)
                  </Button>
                  <Typography className={classes.helper}>(Max. 5 files)</Typography>
                </div>
              </Grid>
            }
          </Grid>
          <Typography className={classes.detailLabel}>Signature:</Typography>
          <Paper className={classes.signaturePaper} elevation={0}>
            <img className={classes.signatureImg} src={details.receiveSignature}/>
          </Paper>
        </Card>
        <div ref={componentRef}>
          <PrintFormat printing={printing}>
            <Typography className={classes.printTitle}>Delivery Note</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={classes.printLabel}>
                    Customer Name:
                  </Grid>
                  <Grid item xs={8}>
                    {details.customerName}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    Customer Address:
                  </Grid>
                  <Grid item xs={4}>
                    {details.deliveryAddress}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className={classes.printLabel}>
                    DO No.:
                  </Grid>
                  <Grid item xs={8}>
                    {details.deliveryOrderRefNo}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    Delivery Date:
                  </Grid>
                  <Grid item xs={8}>
                    {moment(details.actualDeliveryDate).isAfter(minDate) ? moment(details.actualDeliveryDate).format("DD/MM/YYYY") : moment(details.deliveryDate).format("DD/MM/YYYY")}
                  </Grid>
                  {/* <Grid item xs={4} className={classes.printLabel}>
                    PO No.:
                  </Grid>
                  <Grid item xs={8}>
                    {details.poNumber}
                  </Grid>
                  <Grid item xs={4} className={classes.printLabel}>
                    SO No.:
                  </Grid>
                  <Grid item xs={8}>
                    {details.saleOrderNumber}
                  </Grid> */}
                  <Grid item xs={4} className={classes.printLabel}>
                    Collect Person:
                  </Grid>
                  <Grid item xs={8}>
                    {details.contactPerson}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Table
              className={clsx(classes.whiteTable, classes.printTable)}
              header={deliveryOrderDetailTableHead}
            >
              { details.finishedGoodsProcesses && details.finishedGoodsProcesses.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.product_Id}</TableCell>
                    <TableCell>{item.isWeightOrder ? formatNumbers(item.weight) : formatNumbers(item.numberOfPacket)}</TableCell>
                    <TableCell>{item.uom}</TableCell>
                    <TableCell>{item.doNumberOfPacket}</TableCell>
                    <TableCell>{item.doRemarks}</TableCell>
                  </TableRow>
                )
              })}
            </Table>
            <span>Goods received by:</span>
            <img className={classes.printSignatureImg} src={details.receiveSignature}/>
            <hr className={classes.signatureLine} />
            <span>Date: {moment(details.signedDateTime).isAfter(minDate) ? moment(details.signedDateTime).format("DD/MM/YYYY") : ""}</span>
          </PrintFormat>
        </div>
        <Modal
          classes={{
            content: classes.zoomModalContent
          }}
          open={Boolean(zoomModal)}
          className={classes.zoomModal}
          onClose={() => handleOnClick_zoom(false)}
          content={
            <React.Fragment>
              <img src={zoomModal} className={classes.zoomImage} />
              <a className={classes.carouselButton} onClick={()=>onClickArrow(-1)}>&#10094;</a>
              <a className={clsx(classes.carouselButton, classes.next)} onClick={()=>onClickArrow(1)}>&#10095;</a>
            </React.Fragment>
          }
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleOnClick_zoom(false)}>Close</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
}
