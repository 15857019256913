import {
  drawerWidth,
  // transition,
  boxShadow,
  defaultFont,
  icon_16,
  whiteColor,
  grayColor,
  blackColor,
  primaryColor,
  hexToRgb,
} from "assets/jss/generaliseStyle.js";

const sidebarStyle = (theme) => ({
  boxShadow,
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1032",
    ...boxShadow,
    width: drawerWidth,
    background: primaryColor[2],
    height: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: drawerWidth,
    //   position: "fixed",
    //   height: "100%",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: drawerWidth,
    //   ...boxShadow,
    //   position: "fixed",
    //   display: "block",
    //   top: "0",
    //   height: "100vh",
    //   // right: "0",
    //   left: "0", //"auto",
    //   zIndex: "1032",
    //   visibility: "visible",
    //   overflowY: "visible",
    //   borderTop: "none",
    //   textAlign: "left",
    //   paddingRight: "0vh",
    //   paddingLeft: "0",
    //   transform: `translate3d(${drawerWidth}, 0, 0)`,
    //   ...transition,
    // },
  },
  logo: {
    position: "relative",
    padding: "2.250vw 0.781vw",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      height: "0.052vw",
      right: "0.781vw",
      width: "calc(100% - 1.563vw)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)",
    },
  },
  logoSubheader: {
    color: "white",
    fontSize: "0.729vw",
    fontWeight: "400",
    lineHeight: "0.833vw",
    textAlign: "center",
    paddingTop: "0.521vw",
  },
  logoImage: {
    width: "8vw",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8",
    },
  },
  list: {
    marginTop: "1.042vw",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor,
    },
  },
  itemLink: {
    width: "12.500vw",
    height: "2.604vw",
    color: primaryColor[1],
    transition: "all 300ms linear",
    margin: "0.391vw 0.417vw 0",
    borderRadius: "0.156vw",
    position: "relative",
    padding: "0.521vw 0.625vw",
    backgroundColor: "transparent",
    ...defaultFont,
  },
  subItemLink: {
    width: "10.938vw",
    margin: "0.391vw 0.625vw 0",
  },
  itemIcon: {
    width: "1.250vw",
    height: "1.250vw",
    float: "left",
    marginRight: "0.938vw",
  },
  subMenuIcon: {
    ...icon_16,
    transform: "rotate(270deg)",
    marginRight: "-0.208vw"
  },
  itemText: {
    ...defaultFont,
    lineHeight: "1.094vw",
    fontSize: "0.938vw",
    // color: whiteColor,
  },
  subItemText: {
    fontSize: "0.833vw",
  },
  whiteFont: {
    color: whiteColor,
  },
  selectedColor: {
    background: "linear-gradient(90deg, #86D295 0%, #00CFB4 100%)", // #customise
    // boxShadow:
    //   "0 12px 20px -10px rgba(" +
    //   hexToRgb(successColor[0]) +
    //   ",.28), 0 4px 20px 0 rgba(" +
    //   hexToRgb(blackColor) +
    //   ",.12), 0 7px 8px -5px rgba(" +
    //   hexToRgb(successColor[0]) +
    //   ",.2)",
    // "&:hover,&:focus": {
    //   backgroundColor: successColor[0],
    //   boxShadow:
    //     "0 12px 20px -10px rgba(" +
    //     hexToRgb(successColor[0]) +
    //     ",.28), 0 4px 20px 0 rgba(" +
    //     hexToRgb(blackColor) +
    //     ",.12), 0 7px 8px -5px rgba(" +
    //     hexToRgb(successColor[0]) +
    //     ",.2)",
    // },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "overlay",
    zIndex: "4",
    overflowScrolling: "touch",
    paddingBottom: "1.042vw"
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "0.677vw",
    },
  },
  popper: {
    width: "23.060vw",
    marginLeft: "1.979vw"
  }
});

export default sidebarStyle;
