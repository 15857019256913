import {
  border,
  button,
  whiteColor,
  primaryColor,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  link,
} from "assets/jss/components/commonStyle.js";

const traceabilityStyle = {
  button,
  orange,
  link,
  buttonSecondary,
  
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw !important"
  },
  action: {
    display: "flex",
    justifyContent: "flex-end",
    '& div[class*="MuiFormControl-root"]': {
      width: "unset"
    },
  },
  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  actionDropdown: {
    width: "11.771vw",
  },
  accordion: {
    margin: "unset !important",
    marginTop: "1.563vw !important",
  },
  subAccordion: {
    backgroundColor: whiteColor,
    borderRadius: "0 !important",
    margin: "0 !important",
    "&:first-child": {
      borderRadius: "0.625vw 0.625vw 0 0 !important",
    },
    "&:last-child": {
      borderRadius: "0 0 0.625vw 0.625vw !important",
    },
    "&:not(:last-child)": {
      borderBottom: border,
    },
  },
  header: {
    color: primaryColor[3]+"BF",
  },
  content: {
    borderRadius: "0.625vw",
    width: "100%",
    backgroundColor: whiteColor,
    padding: "1.563vw 1.042vw"
  },
  accordionContent: {
    width: "100%",
  },
  paper: {
    border,
    width: "100%",
    padding: "1.563vw 2.083vw",
  },
  vertical: {
    borderRight: border,
    paddingRight: "1.667vw",
    marginRight: "-1.667vw"
  },
  title: {
    fontWeight: 500,
    color: primaryColor[3],
    marginBottom: "2.135vw"
  },
  marginTop: {
    marginTop: "1.927vw"
  },
  marginLeft: {
    marginLeft: "2.135vw"
  },
  details: {
    marginBottom: "1.927vw",
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  value: {
    fontWeight: 500
  },
  detailLabel: {
    marginBottom: "1.927vw",
  },
  table: {
    margin: "1.406vw 0"
  },
  downloadButton: {
    display: "flex",
    justifyContent: "end"
  },
  batchModal: {
    paddingLeft: 0,
    paddingRight: 0
  },
  scroll: {
    height: "20vw",
    overflow: "overlay"
  }
}

export default traceabilityStyle;