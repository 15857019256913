import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";
import CustomIconButton from "shared-components/Button/IconButton";
import TextField from "shared-components/TextField/TextField";

import { formatNumbers, roundTo2Decimal } from "common/functions";
import { isPositive } from "common/validations";
import { rowsPerTable } from "config";
import { reset } from "../store/search";
import { setSearchText } from "store/general";
import { rmReturnTableHead } from "enums/UserPortal/TableHeaders";
import { SearchRawMaterialReturn, UpdateRawMaterialReturn } from "services/UserPortal/RawMaterialService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchReturnResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.rawMaterial.search.returnTotalCount);
  const results = useSelector(store => store.user.rawMaterial.search.returnResults);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleOnChange_text = (value) => {
    let payload = _.cloneDeep(editItem);
    payload.returnAmount = Number(value);
    payload.returnQty = Number(value);
    setEditItem(payload);
  };

  const handleButtonClick_confirmEdit = () => {
    if (validateFields()) {
      dispatch(UpdateRawMaterialReturn(editItem))
      .then(() =>{
        setEditItem(null);
        dispatch(SearchRawMaterialReturn({keyword: params.keyword, page}));
      })
    }
  }

  const validateFields = () => {
    if (!isPositive(editItem.returnAmount)) {
      setErrorMsg({field: "returnAmount", msg: "Invalid return quantity"});
      return false;
    }
    if (editItem.issuedAmount < editItem.returnAmount) {
      setErrorMsg({field: "returnAmount", msg: "Cannot exceed issued quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    dispatch(SearchRawMaterialReturn({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Return List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={rmReturnTableHead}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.rawMaterialName)}</TableCell>
                            <TableCell>{highlightValue(item.rawMaterialRefNo)}</TableCell>
                            <TableCell>{highlightValue(item.issuedAmount)}</TableCell>
                            <TableCell>{highlightValue(item.unitOfMeasurement)}</TableCell>
                            <TableCell>{highlightValue(item.productName)}</TableCell> 
                            <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                            <TableCell>
                              {(editItem && item.id === editItem.id)
                              ? <TextField
                                  className={classes.alignTextfield}
                                  id="returnAmount"
                                  variant="outlined"
                                  type="Number"
                                  onInput={(e)=>{ 
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,40)
                                  }}
                                  onChange={(e) => handleOnChange_text(e.target.value)}
                                  value={editItem.returnAmount}
                                  errorMsg={errorMsg}
                                />
                              : formatNumbers(roundTo2Decimal(item.returnAmount))
                              }
                            </TableCell>
                            <TableCell>{highlightValue(moment(item.issuedDate).format("YYYY-MM-DD"))}</TableCell>
                            <TableCell>{highlightValue(item.totalUsedAmount)}</TableCell>
                            <TableCell>{highlightValue(item.returnedUser)}</TableCell>
                            <TableCell>{highlightValue(item.rawMaterialBatchRefNo)}</TableCell>
                            <TableCell align="right">
                              {canWrite && (editItem && item.id === editItem.id
                              ? <React.Fragment>
                                  <CustomIconButton 
                                    type="confirm"
                                    onClick={() => handleButtonClick_confirmEdit()}
                                  />
                                  <CustomIconButton 
                                    type="close"
                                    onClick={() => setEditItem(null)}
                                  />
                                </React.Fragment>
                              : <React.Fragment>
                                  <CustomIconButton 
                                    type="edit"
                                    onClick={() => setEditItem(item)}
                                    disabled={editItem?true:false}
                                  />
                                </React.Fragment>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
