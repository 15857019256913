import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";

import { setDirty } from "store/general";
import { updateRequest } from "../store/vegetable";
import { renderValue, roundTo2Decimal } from "common/functions";
import { GetProductInfoById, GetGerminationDetailByWorkOrderIdAndProductId, GetBatchIDList, GetTrayPosition, GetQCDetailsFromOperation, CalculateDatesByWorkOrderIdAndProductId, GetSeedSupplierNameByBatchID,
  CalculateNurseryDuration, CalculateGrowthDuration, CalculateAverageNetWeightPerPlant,
  CreateOrUpdateQualityControl, UploadQualityControlImageList } from "services/UserPortal/QualityControlService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import HealthCondition from "./HealthCondition";
import styles from "assets/jss/components/UserPortal/qualityControlStyle.js";

const useStyles = makeStyles(styles);

export default function DetailForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const batchIDList = useSelector(store => store.user.qualityControl.vegetable.batchIDList);
  const boardTypeList = useSelector(store => store.user.qualityControl.vegetable.boardTypeList);
  const trayPositionList = useSelector(store => store.user.qualityControl.vegetable.trayPositionList)
  const qualityControl = useSelector(store => store.user.qualityControl.vegetable.qualityControl);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "quality-control");

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "workOrderId") {
      setErrorMsg(false);
      dispatch(updateRequest({productId: null, vegetableVariety: null}));
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateRequest({trayPosition: value}));
    dispatch(GetQCDetailsFromOperation({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, trayPositionId: value, isGerminationReport: false}));
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };
  
  const handleOnClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        Promise.all([
          (props.hcImages && props.hcImages.length) && dispatch(UploadQualityControlImageList(props.hcImages))
        ])
        .then((response) => {
          if (!response[0].error) {
            let qc = _.cloneDeep(qualityControl);
            let hc = _.cloneDeep(qualityControl.healthConditions);
            if (hc && response[0]) {
              hc = hc.map((item) => {
                const imageIndex = response[0].payload.result.findIndex(({imageLocation}) => imageLocation == item.healthConditionName);
                if (imageIndex !== -1) {
                  item.imageUrl = response[0].payload.result[imageIndex].imageUrl;
                }
                return item;
              })
            }
            qc.healthConditions = hc;
            dispatch(CreateOrUpdateQualityControl(qc))
            .then(({error}) => {
              if (!error) {
                history.push("/user/quality-control/vegetable-growth");
              } else {
                dispatch(updateRequest({healthConditions: hc}));
              }
            });
          }
        })
      })
    }
  }

  const validateFields = () => {
    if (!qualityControl.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }
  
  React.useEffect(() => {
    if (qualityControl.workOrderId) {
      dispatch(GetProductList(qualityControl.workOrderId));
    }
  },[qualityControl.workOrderId]);
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
      dispatch(GetBatchIDList({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}));
      dispatch(GetTrayPosition({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, isGerminationReport: false}));
      if (path[index+2] === "create") {
        dispatch(GetGerminationDetailByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then((response) => {
          if (response.payload && response.payload.result) {
            if (!response.payload.result.germinationSerialNo) {
              setErrorMsg({field: "productId", msg: "Germination report not created"});
            } else {
              setErrorMsg(false);
            }
          }
        });
        dispatch(CalculateDatesByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then(({payload}) => {
          if (payload.result) {
            dispatch(CalculateNurseryDuration({transplantDate: moment(payload.result.transplantDate).format("DD MMMM YYYY"), germinationDate: moment(payload.result.germinateDate).format("DD MMMM YYYY")}));
            dispatch(CalculateGrowthDuration({transplantDate: moment(payload.result.transplantDate).format("YYYY-MM-DD"), harvestDate: moment(payload.result.harvestDate).format("YYYY-MM-DD")}));
          }
        });
      }
    }
  },[qualityControl.productId]);

  React.useEffect(() => {
    if (qualityControl.batchNo) {
      dispatch(GetSeedSupplierNameByBatchID({batchID: qualityControl.batchNo}));
    }
  },[qualityControl.batchNo]);
  
  React.useEffect(() => {
    if (qualityControl.totalNetWeight && qualityControl.noOfPlantsHarvested) {
      dispatch(CalculateAverageNetWeightPerPlant({totalNetWeight: qualityControl.totalNetWeight, totalHarvestQty: qualityControl.noOfPlantsHarvested}));
    }
  },[qualityControl.totalNetWeight, qualityControl.noOfPlantsHarvested]);
  
  return (
    <React.Fragment>
      <Card 
        title = {(path[index+1] !== "create" ? "Edit" : "Add New") + " Production Details - Quality Control Entries"}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.push("/user/quality-control/vegetable-growth")}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleOnClick_save()}
              disabled={!qualityControl.workOrderId}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Select Work Order to Work On</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Work Order:</Typography>
            <Select
              name="workOrderId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a work order"
              value={renderValue(qualityControl.workOrderId)}
              errorMsg={errorMsg}
              disabled={path[index+2] !== "create"}
            >
              {workOrderList && workOrderList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Vegetable Name:</Typography>
            {path[index+2] === "create"
            ? <Select
                name="productId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a vegetable name"
                value={renderValue(qualityControl.productId)}
                errorMsg={errorMsg}
                disabled={!qualityControl.workOrderId}
              >
                {productList && productList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.productName}</MenuItem>;
                })}
              </Select>
            : <TextField 
                id="productId"
                variant="outlined" 
                value={renderValue(qualityControl.productName)}
                disabled
              />
            }
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Germination Serial No.:</Typography>
            <TextField 
              id="germinationSerialNo"
              variant="outlined" 
              placeholder=""
              value={renderValue(qualityControl.germinationSerialNo)}
              errorMsg={errorMsg}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>General Information</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Serial No:</Typography>
            <TextField 
              id="vegetableSerialNo"
              variant="outlined" 
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.vegetableSerialNo )}
              disabled = { !qualityControl.workOrderId }
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Vegetable Variety:</Typography>
            <TextField 
              id="vegetableVariety"
              variant="outlined" 
              placeholder=""
              value={renderValue(qualityControl.vegetableVariety)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid} />
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Batch No:</Typography>
            <Select
              name="batchNo"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a batch ID"
              value={renderValue(qualityControl.batchNo)}
              errorMsg={errorMsg}
              disabled={!qualityControl.productId}
            >
              {batchIDList && batchIDList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Tray Position:</Typography>
            <Autocomplete
              id="trayPosition"
              freeSolo
              inputProps={{ maxLength: 50 }}
              onInputChange={(e, newInputValue) => {
                handleOnChange_autocomplete(newInputValue);
              }}
              inputValue={renderValue(qualityControl.trayPosition)}
              options={_.compact(trayPositionList.map((item) => item.trayPosition))}
              placeholder="Select a tray position" 
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>No. of Plants Harvested:</Typography>
            <TextField 
              id="noOfPlantsHarvested"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.noOfPlantsHarvested)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Order No.:</Typography>
            <TextField 
              id="orderNo"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.orderNo)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Seed Supplier:</Typography>
            <TextField
              id="supplierName"
              variant="outlined"
              value={renderValue(qualityControl.supplierName)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Dates</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Harvest Date:</Typography>
            <TextField 
              name="harvestDate"
              variant="outlined" 
              value={renderValue(qualityControl.harvestDate && moment(qualityControl.harvestDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Weights</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Total Net Weight (kg):</Typography>
            <TextField 
              id="totalNetWeight"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.totalNetWeight)}
              // disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Avr Net Weight/Plant (g):</Typography>
            <TextField 
              id="avrNetWeightPerPlant"
              variant="outlined" 
              type = "number"
              inputProps={{ maxLength: 50 }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(parseFloat(qualityControl.avrNetWeightPerPlant).toFixed(0))}
              disabled
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Plant Board</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Board Type:</Typography>
            <Select
              name="boardType"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a board type"
              value={renderValue(qualityControl.boardType)}
            >
              {boardTypeList && boardTypeList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={7}>
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.grid}>
                <Typography className={classes.formTitle}>Environment Conditions (Final)</Typography>
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>Water Level (cm):</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalWaterLevel"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalWaterLevel)}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>EC (mS/cm):</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalEC"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalEC)}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>TDS (ppm):</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalTDS"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalTDS)}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>pH Level:</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalPHLevel"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalPHLevel)}
                />
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <Typography className={classes.formTitle}>Production Remarks</Typography>
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <TextField 
                  id="productionRemark"
                  variant="outlined" 
                  multiline
                  minRows={5}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.productionRemark)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} direction="column">
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.grid}>
                <Typography className={classes.formTitle}>Plant Parameters</Typography>
              </Grid>
            </Grid>
            <HealthCondition errorMsg={errorMsg} hcImages={props.hcImages} setHcImages={props.setHcImages} />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
