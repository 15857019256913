import React from "react";
// @mui/material components
import { makeStyles } from "@mui/styles"
// @mui/lab
import Pagination from '@mui/material/Pagination';

import styles from "assets/jss/components/UserPortal/homeStyle.js";

const useStyles = makeStyles(styles);
export default function CustomPagination({...props}) {
  const classes = useStyles();

  return (
    <Pagination 
      {...props}
      className={classes.pagination}
      variant="outlined" 
      shape="rounded" 
      hidePrevButton 
      hideNextButton
    />
  );
}