// Capacity Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers, roundTo2Decimal } from "common/functions";
import { rowsPerTable } from "config";
import { reset } from "./store/search";
import { setSearchText } from "store/general";
import { searchFarmCapacityTableHead, capacityTrayConfigurationTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchCapacity, DeleteCapacityTrayConfig } from "services/AdminPortal/CapacityService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/capacityStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchCapacityResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const searchResultsCount = useSelector(store => store.admin.capacity.search.searchResultsCount);
  const searchResults = useSelector(store => store.admin.capacity.search.searchResults);
  const farmCapacitySummary = useSelector(store => store.admin.capacity.search.farmCapacitySummary);
  const [order, setOrder] = React.useState({cageCapacity: 'asc', trayConfig: 'asc'});
  const [orderBy, setOrderBy] = React.useState({cageCapacity: 'productId', trayConfig: 'productId'});
  const [page, setPage] = React.useState({cageCapacity: 0, trayConfig: 0});
  const [id, setId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState({cageCapacity: false, trayConfig: false});

  const cageCapacityCount = Math.ceil(searchResultsCount.cageCapacityList / rowsPerTable);
  const trayConfigCount = Math.ceil(searchResultsCount.trayConfigList / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRequestSortCageCapacity = (e, property) => {
    const isAsc = orderBy.cageCapacity === property && order.cageCapacity === 'asc';
    setOrder({...order, cageCapacity: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, cageCapacity: property});
  };
  
  const handleRequestSortTrayConfig = (e, property) => {
    const isAsc = orderBy.trayConfig === property && order.trayConfig === 'asc';
    setOrder({...order, trayConfig: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, trayConfig: property});
  };

  const handleModal = (idNum) => {
    setId(idNum);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteCapacityTrayConfig(id))
    .then(() =>{
      dispatch(SearchCapacity(params.keyword));
      resetState();
    })
  }

  const resetState = () => {
    setId(null);
    setPage({cageCapacity: 0, trayConfig: 0});
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    dispatch(reset());
    dispatch(SearchCapacity(params.keyword));
  },[params.keyword]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  const totalCount = _.isEmpty(searchResultsCount) ? 0 : Object.values(searchResultsCount).reduce((a, b) => a + b);
  const totalTableCount = Object.keys(searchResults).length;
  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(totalCount) + " Search Result(s) From " + totalTableCount + " Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount.cageCapacityList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.cageCapacityList + " Result(s) From Farm Capacity"}
              <IconButton 
                className={collapsed.cageCapacity ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, cageCapacity: !collapsed.cageCapacity})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.cageCapacity && 
            <React.Fragment>
              <div className={classes.table}>
                <Table
                  header={searchFarmCapacityTableHead}
                  order={order.cageCapacity}
                  orderBy={orderBy.cageCapacity}
                  onRequestSort={handleRequestSortCageCapacity}
                >
                  { _.orderBy(searchResults.cageCapacityList, [orderBy.cageCapacity], [order.cageCapacity])
                  .slice(page.cageCapacity * rowsPerTable, page.cageCapacity * rowsPerTable + rowsPerTable)
                  .map((item,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{highlightValue(item.productId)}</TableCell>
                        <TableCell>{highlightValue(item.productName)}</TableCell>
                        <TableCell>
                          <Link to={{pathname: "/admin/capacity/farm-capacity"}} className={classes.orange}>
                            View
                          </Link>
                        </TableCell>
                        <TableCell>
                          {farmCapacitySummary.calculationCages.map((cage, i) => {
                            if (_.includes(item.cageCategories, cage.description)) {
                              return (
                                <Typography key={i}>{highlightValue(cage.description+": "+cage.count)}</Typography>
                              )
                            }
                          })}
                        </TableCell>
                        <TableCell>{highlightValue(item.cageCategories.join(", "))}</TableCell>
                        <TableCell align="right">
                          {canWrite &&
                            // Link to Cages Categories edit
                            <Link to={{pathname: "/admin/capacity/farm-capacity/edit", state: {prevPath: location.pathname+location.search}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </div>
              { cageCapacityCount > 1 &&
                <Pagination 
                  count={cageCapacityCount} 
                  page={page.cageCapacity+1}
                  onChange={(e, pageNo)=>setPage({...page, cageCapacity: pageNo-1})}
                />
              }
            </React.Fragment>
          }
        </Card>
      }
      { searchResultsCount.trayConfigList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.trayConfigList + " Result(s) From Trays Per Configuration"}
              <IconButton 
                className={collapsed.item ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, item: !collapsed.item})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.trayConfig && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={capacityTrayConfigurationTableHead.map((head, index) => {
                  if (index === 5) {
                    head.label = "Weight per Plant (kg)"
                  } 
                  return head
                })}
                order={order.trayConfig}
                orderBy={orderBy.trayConfig}
                onRequestSort={handleRequestSortTrayConfig}
              >
                { _.orderBy(searchResults.trayConfigList, [orderBy.trayConfig], [order.trayConfig])
                .slice(page.trayConfig * rowsPerTable, page.trayConfig * rowsPerTable + rowsPerTable)
                .map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{highlightValue(item.product.productID)}</TableCell>
                      <TableCell>{highlightValue(item.product.productName)}</TableCell>
                      <TableCell>{highlightValue(item.process)}</TableCell>
                      <TableCell>{highlightValue(formatNumbers(item.plantPerTray))}</TableCell>
                      <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(Number(item.trayPerCage))))}</TableCell>
                      <TableCell>{highlightValue(item.weightPerTray ? formatNumbers(roundTo2Decimal(Number(item.weightPerPlant))) : "NA")}</TableCell>
                      <TableCell>{highlightValue(item.weightPerPlant ? formatNumbers(roundTo2Decimal(Number(item.weightPerTray))) : "NA")}</TableCell>
                      <TableCell>{highlightValue(item.weightPerCage ? formatNumbers(roundTo2Decimal(Number(item.weightPerCage))) : "NA")}</TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { trayConfigCount > 1 &&
              <Pagination 
                count={trayConfigCount} 
                page={page.trayConfig+1}
                onChange={(e, pageNo)=>setPage({...page, trayConfig: pageNo-1})}
              />
            }
          </React.Fragment>
          }
        </Card>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
