import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { reportReservedEquipTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllEquipmentReserved } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function EquipmentTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.planning.report.filter);
  const planning = useSelector(store => store.user.planning.report.planning);
  const totalCount = useSelector(store => store.user.planning.report.equipmentCount);
  const result = useSelector(store => store.user.planning.report.equipment);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productId');
  const [page, setPage] = React.useState(0);
  
  const count = Math.ceil(totalCount / rowsPerTable);
  const workOrder = planning.length ? {workOrderNumber: planning[0].workOrderNumber, workOrderId: planning[0].workOrderId} : {};
  
  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const getEquipmentReserved = (pageNo, stopLoading) => {
    if (!_.isEmpty(workOrder)) {
      dispatch(GetAllEquipmentReserved({...filter, page: pageNo, workOrderId: workOrder.workOrderId, stopLoading}));
    }
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      setPage(0);
      getEquipmentReserved(0);
    }
  },[filter]);

  React.useEffect(() => {
    getEquipmentReserved(page);
  },[page]);

  useInterval(() => {
    getEquipmentReserved(page, true);
  });

  return (
    <React.Fragment>
      <Table 
        className={classes.table}
        header={reportReservedEquipTableHead}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          return (
            <TableRow key={index}>
              <TableCell>{item.equipmentName}</TableCell>
              <TableCell>{item.equipmentID}</TableCell>
              <TableCell>{item.uom}</TableCell>
              <TableCell>{formatNumbers(roundTo2Decimal(item.qtyReserve))}</TableCell>
              <TableCell>{moment(item.reserveDate).format("DD/MM/YYYY")}</TableCell>
              <TableCell>{item.productName}</TableCell>
              <TableCell>{item.product_Id}</TableCell>
              <TableCell>{item.processName}</TableCell>
            </TableRow>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
    </React.Fragment>
  );
}
