// Create Edit Devices
// Device Function List
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import Alert from "shared-components/Modal/Alert";
import Radio from 'shared-components/Radio/Radio';
import IconButton from 'shared-components/Button/IconButton';

import * as validate from "common/validations";
import { renderValue } from "common/functions";
import { setValues, updateRequest, updateLibraryRequest, updateFunctionsRequest, deleteRequest, deleteNewRequest, reset } from "./store/functions";
import { setValues as setDeviceTypeValues } from "./store/type";
import { GetAllDeviceType, GetAllFunctionLibrary, CreateOrUpdateDeviceType, CreateOrUpdateFunctionLibrary } from "services/AdminPortal/DeviceService";
import { functionsTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/deviceStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceFunctions(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const deviceTypes = useSelector(store => store.admin.device.type.deviceTypes);
  const functions = useSelector(store => store.admin.device.functions);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [funcIndex, setFuncIndex] = React.useState(false);
  const [param, setParam] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);
  const [isExisting, setIsExisting] = React.useState(true);

  const handleOnChange_text = (e) => {
    setIsChanged(true);
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_radio = (value) => {
    setIsExisting(value);
    dispatch(updateRequest({functionLibraryId : null, newFunctionLibraryName: null, deviceFunctions: [{}]}));
  }
  
  const handleOnChange_table_text = (e, index) => {
    setIsChanged(true);
    const key = e.target.id.replace(/\d/g, '');
    let value = e.target.value;
    if (key === "shortDeviceFunctionName") {
      value = value.toUpperCase();
    }
    dispatch(updateFunctionsRequest({index, value:{[key] : value}}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "deviceTypeId") {
      const payload = deviceTypes.find((type) => type.id === e.target.value).functionLibrary;
      if (payload) {
        handleOnChange_radio(true);
        dispatch(updateLibraryRequest(payload));
      } else {
        dispatch(updateRequest({functionLibraryId : null, newFunctionLibraryName: null, deviceFunctions: [{}]}));
      }
    }
    if (e.target.name === "functionLibraryId") {
      setIsChanged(true);
      dispatch(updateLibraryRequest(functions.functionLibraryNames.find((library) => library.id === e.target.value)));
    }
  };

  const handleButtonClick_exit = () => {
    if (props.setFunction) {
      props.setFunction(false);
    } else {
      const prop = props.location && props.location.state;  // from search
      history.push((prop && prop.prevPath) || "/admin/device");
    }
  }

  const handleModal_alert = () => {
    if (isChanged) {
      setOpenAlertModal(!openAlertModal);
    } else {
      handleButtonClick_exit();
    }
  }

  const handleButtonClick_confirm = (e) => {
    setOpenWarningModal(false);
    setIsChanged(false);
    setErrorMsg(false);
    if (e.target.id && e.target.id === "existingLibrary") {
      handleOnChange_radio(true)
    } else if (e.target.id && e.target.id == "newLibrary") {
      handleOnChange_radio(false)
    } else if (e.target.name) {
      handleOnChange_select(e)
    }
  }

  const handleModal_warning = (e) => {
    if (isChanged) {
      setParam(e);
      setOpenWarningModal(!openWarningModal);
    } else {
      handleButtonClick_confirm(e);
    }
  }

  const handleModal_delete = (index) => {
    setFuncIndex(index);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    setOpenDeleteModal(!openDeleteModal);
    setIsChanged(true);
    if (functions.deviceFunctions[funcIndex].id) {
      dispatch(deleteRequest({
        index: funcIndex, 
        value: {
          ...functions.deviceFunctions[funcIndex],
          isDeleted: true
        }
      }));
    } else {
      dispatch(deleteNewRequest(funcIndex));
    }
  }

  const handleButtonClick_add = () => {
    setIsChanged(true);
    let payload = [...functions.deviceFunctions, {}];
    dispatch(setValues({key: "deviceFunctions", payload}));
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      let body = {
        deviceFunctions: functions.deviceFunctions,
      }
      if (isExisting) { 
        Object.assign(body, _.omit(functions.functionLibraryNames.find((item) => item.id == functions.functionLibraryId),["deviceFunctions"]));
      } else {
        Object.assign(body, {functionLibraryName: functions.newFunctionLibraryName});
      }
      dispatch(CreateOrUpdateFunctionLibrary(body))
      .then((response)=>{
        if (response.error) {
          setErrorMsg({field: "functions", msg: response.payload.message});
        } else {
          const value = deviceTypes.find((type) => type.id === functions.deviceTypeId);
          dispatch(setDeviceTypeValues({
            ...value,
            functionLibraryId: response.payload.result.id,
          }));
          dispatch(CreateOrUpdateDeviceType())
          .then(() => {
            resetState();
          });
        }
      })
    }
  }

  const validateFields = () => {
    if (!functions.deviceTypeId) {
      setErrorMsg({field: "deviceTypeId", msg: "Please select a device type"});
      return false;
    }
    if (!isExisting) {
      if (_.isEmpty(functions.newFunctionLibraryName)) {
        setErrorMsg({field: "newFunctionLibraryName", msg: "Please add in your new library name"});
        return false;
      }
    } else {
      if (!functions.functionLibraryId) {
        setErrorMsg({field: "functionLibraryId", msg: "Please select an existing library"});
        return false;
      }
    }
    for (let i = 0; i < functions.deviceFunctions.length; i++) {
      const func = functions.deviceFunctions[i];
      if (_.isEmpty(func.deviceFunctionName)) {
        setErrorMsg({field: "deviceFunctionName"+i, msg: "Please add in your function name"});
        return false;
      }
      if (_.isEmpty(func.shortDeviceFunctionName)) {
        setErrorMsg({field: "shortDeviceFunctionName"+i, msg: "Please add in your function shortform"});
        return false;
      }
      if (!func.isCombine && !validate.isShortform(func.shortDeviceFunctionName)) {
        setErrorMsg({field: "shortDeviceFunctionName", msg: "Shortform should contain at most 3 upper case characters"});
        return false;
      } 
    }
    if (!validate.isUnique(functions.deviceFunctions, "deviceFunctionName")) {
      setErrorMsg({field: "functions", msg: "Function names should be unique"});
      return false;
    }
    if (!validate.isUnique(functions.deviceFunctions, "shortDeviceFunctionName")) {
      setErrorMsg({field: "functions", msg: "Function shortforms should be unique"});
      return false;
    }
    setErrorMsg({field: "", msg: ""});
    return true;
  }

  const resetState = () => {
    setIsChanged(false);
    setErrorMsg(false);
    dispatch(GetAllDeviceType());
    dispatch(GetAllFunctionLibrary());
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllDeviceType())
    .then((response) => {
      const prop = props.location && props.location.state;
      if (prop) {
        const payload = _.reduce(response.payload.result.items, (prev, curr) => {
          return prev.id < curr.id ? prev : curr;
        })
        dispatch(setValues({key: "deviceTypeId", payload: payload.id}));
        if (payload.functionLibrary) {
          handleOnChange_radio(true);
          dispatch(updateLibraryRequest(payload.functionLibrary));
        }
      }
    });
    dispatch(GetAllFunctionLibrary());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="View Device Function"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleModal_alert()}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isChanged}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.content}>
          <div className={clsx(classes.subContent, classes.funcLeftContent)}>
            <Paper className={classes.paper} elevation={0}>
              <Select
                label="Device Type"
                name="deviceTypeId"
                onChange={(e)=>handleModal_warning(e)}
                value={renderValue(functions.deviceTypeId)}
                placeholder="Please select a device type"
                errorMsg={errorMsg}
              >
                {deviceTypes && deviceTypes.map((item,index) => {
                  return <MenuItem key={index} value={item.id}>{item.deviceTypeName}</MenuItem>;
                })} 
              </Select>
              <div className={classes.radioContainer}>
                <Typography className={classes.radioLabel}>Existing Library</Typography>
                <Radio 
                  id="existingLibrary"
                  checked={isExisting}
                  onChange={(e)=>handleModal_warning(e)}
                />
                <Typography className={classes.radioLabel}>New Library</Typography>
                <Radio 
                  id="newLibrary"
                  checked={!isExisting}
                  onChange={(e)=>handleModal_warning(e)}
                />
              </div>
              <Select
                label={<Typography className={!isExisting && classes.disabledLabel}>Existing Library Name</Typography>}
                name="functionLibraryId"
                onChange={(e)=>handleModal_warning(e)}
                value={renderValue(functions.functionLibraryId)}
                placeholder="Please select a library"
                disabled={!isExisting}
                errorMsg={errorMsg}
              >
                {functions.functionLibraryNames && functions.functionLibraryNames.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.functionLibraryName}</MenuItem>;
                })} 
              </Select>
              <TextField 
                label={<Typography className={isExisting && classes.disabledLabel}>New Library</Typography>}
                id="newFunctionLibraryName"
                variant="outlined" 
                inputProps={{ maxLength: 50 }}
                placeholder="Enter new library name"
                onChange={(e) => handleOnChange_text(e)}
                value={renderValue(functions.newFunctionLibraryName)}
                errorMsg={errorMsg}
                disabled={isExisting}
              />
            </Paper>
          </div>
          <div className={classes.subContent}>
            <Card
              variant="outlined"
              classes={{root:classes.cardPaper}}
              title="Add New Functions into Library"
            >
              { errorMsg.field === "functions" && <Typography className={classes.error}>{errorMsg.msg}</Typography> }
              <Table
                className={clsx(classes.table, classes.funcTable)}
                header={functionsTableHead}
              >
                { functions.deviceFunctions && functions.deviceFunctions.map((item,index) => {
                  if (!item.isDeleted) {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <TextField 
                            id={"deviceFunctionName"+index}
                            className={ errorMsg.field === "deviceFunctionName"+index ? clsx(classes.alignTextfield, classes.funcTextField) : classes.funcTextField}
                            variant="outlined" 
                            inputProps={{ maxLength: 50 }}
                            placeholder="Enter function name"
                            onChange={(e) => handleOnChange_table_text(e, index)}
                            value={renderValue(item.deviceFunctionName)}
                            errorMsg={errorMsg}
                            disabled={!(functions.newFunctionLibraryName || functions.functionLibraryId)}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField 
                            id={"shortDeviceFunctionName"+index}
                            className={ errorMsg.field === "shortDeviceFunctionName"+index ? clsx(classes.alignTextfield, classes.funcTextField) : classes.funcTextField}
                            variant="outlined" 
                            inputProps={{ maxLength: 3 }}
                            placeholder="Enter shortform"
                            onChange={(e) => handleOnChange_table_text(e, index)}
                            value={renderValue(item.shortDeviceFunctionName)}
                            errorMsg={errorMsg}
                            disabled={!(functions.newFunctionLibraryName || functions.functionLibraryId) || item.isCombine}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField 
                            id={"unit"+index}
                            className={classes.funcTextField}
                            variant="outlined" 
                            placeholder="Enter unit"
                            onChange={(e) => handleOnChange_table_text(e, index)}
                            value={renderValue(item.unit)}
                            disabled={!(functions.newFunctionLibraryName || functions.functionLibraryId)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          { index !== 0 &&
                            <IconButton
                              type="delete" 
                              onClick={() => handleModal_delete(index)}
                              disabled={!(functions.newFunctionLibraryName || functions.functionLibraryId)}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  } else {
                    return null
                  }
                })}
              </Table>
              <Button
                className={clsx(classes.buttonSecondary, classes.addButton)}
                startIcon={<AddIcon />}
                onClick={()=>handleButtonClick_add()}
                disabled={!(functions.newFunctionLibraryName || functions.functionLibraryId)}
              >
                Add More Functions
              </Button>
            </Card>
          </div>
        </div>
      </Card>
      <Modal
        open={openDeleteModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete one of your functions? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="You have not save your current changes. Changing your selection will wipe your current changes."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_warning(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_confirm(param)}>Confirm</Button>
          </React.Fragment>
        }
      />
      <Alert open={openAlertModal} onConfirm={()=>props.setFunction(false)} onCancel={()=>handleModal_alert()} />
    </React.Fragment>
  )
}
