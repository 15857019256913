/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { renderValue, filterParam } from "common/functions";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { operationsCageTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/overview";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { GetCageIDList } from "services/UserPortal/OperationsService";

import { rowsPerTable } from "config";
import styles from "assets/jss/components/UserPortal/operationsStyle.js";

const useStyles = makeStyles(styles);

export default function CageDetails({setView, ...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const totalCount = useSelector(store => store.user.operations.overview.totalCount);
  const processOverview = useSelector(store => store.user.operations.overview.processOverview);
  const cageIDList = useSelector(store => store.user.operations.overview.cageIDList);
  const filter = useSelector(store => store.user.operations.overview.filter);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null});

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleOnChange_select = (e) => {
    if (e.target.name == "workOrderId") {
      setNewFilter({...newFilter, [e.target.name]: e.target.value, productId: null});
    } else {
      setNewFilter({...newFilter, [e.target.name]: e.target.value});
    }
  };
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
      dispatch(GetProductList(search.workOrderId));
    } else if (workOrderList.length) {
      dispatch(setValues({filter: {workOrderId: workOrderList[0].id, productId: null}}));
    } else {
      dispatch(setValues({filter: {workOrderId: null, productId: null}}));
    }
  },[workOrderList]);

  React.useEffect(() => {
    if (newFilter.workOrderId) {
      dispatch(GetProductList(newFilter.workOrderId));
    }
  },[newFilter.workOrderId]);

  // componentDidMount
  React.useEffect(() => {
    const param = {
      Id: processOverview.id,
      OperationTaskName: props.operationTaskName
    }
    dispatch(GetCageIDList(param));
  },[]);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: classes.cardPaper,
        }}
        title="Cage ID List"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={() => setView("details")}
            >
              Back
            </Button>
            <Button
              className={classes.button}
              // onClick={() => history.push("/user/operations/process-overview")}
            >
              Cancel Cage Management
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.masterTable}
            header={operationsCageTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { cageIDList && cageIDList.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.cageID}</TableCell>
                  <TableCell>{item.locationID}</TableCell>
                  <TableCell>{item.retrieved}</TableCell>
                  <TableCell>{item.completed}</TableCell>
                  <TableCell>{item.remarks}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
