import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetWorkOrderTimeLine, GetBankLookUp } from "services/UserPortal/MonitoringService";

// initial state
const initialState = {
  filter: {},
  workOrder: [],
  //lookup
  bankList: [],
};

const workOrderMonitoring = createSlice({
  name: "workOrderMonitoring",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetBankLookUp.fulfilled]: (state, action) => {
      state.bankList = _.sortBy(action.payload.result, ["bankName"]);
    },
    [GetWorkOrderTimeLine.fulfilled]: (state, action) => {
      state.workOrder = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = workOrderMonitoring.actions;

// export the reducer
export default workOrderMonitoring.reducer;