import { createSlice } from "@reduxjs/toolkit";

import { GetFunctionLibrary } from "services/AdminPortal/DeviceService";

// initial state
const initialState = {
  functionLibrary: {},
  library: {},
};

const reading = createSlice({
  name: "reading",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.library = action.payload;
    },
    setFunctionLibraryValues: (state, action) => {
      state.functionLibrary = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.library[key] = action.payload[key];
      })
    },
    resetLibrary: (state) => {
      state.library = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetFunctionLibrary.fulfilled]: (state, action) => {
      state.functionLibrary = action.payload.result;
    },
  },
});

// export actions
export const { setValues, setFunctionLibraryValues, updateRequest, resetLibrary, reset } = reading.actions;

// export the reducer
export default reading.reducer;