import React from "react";
import PropTypes from "prop-types";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Autocomplete from '@mui/material/Autocomplete';
// @mui/icons-material
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// core components
import TextField from "shared-components/TextField/TextField";
import styles from "assets/jss/shared-components/selectStyle.js";

const useStyles = makeStyles(styles);
export default function CustomAutocomplete({label, options, placeholder, inputProps, errorMsg, ...props}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Autocomplete
        {...props} 
        disableClearable
        options={options}
        PaperComponent={props.PaperComponent 
          ? props.PaperComponent 
          : ({ children }) => <Paper elevation={8}>{children}</Paper>
        }
        renderInput={(params) => 
          <TextField 
            {...params} 
            className={classes.input}
            variant="outlined" 
            placeholder={placeholder} 
            inputProps={{ ...params.inputProps, ...inputProps}}
            InputProps={{
              ...params.InputProps,
              endAdornment: 
                <InputAdornment position="end" className={classes.endAdornment}>
                  <KeyboardArrowDownIcon />
                </InputAdornment>,
            }}
            errorMsg={errorMsg}
            // error={errorMsg && errorMsg.field === props.id}
            // helperText={errorMsg && errorMsg.field === props.id ? errorMsg.msg : ""}
          />
        }
      />
    </React.Fragment>
  );
}

CustomAutocomplete.propTypes = {
  label: PropTypes.node,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  errorMsg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};