import React from "react";
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { withStyles, makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// core components
import styles from "assets/jss/shared-components/textFieldStyle.js";
import { backgroundColor, grayColor, whiteColor } from "assets/jss/generaliseStyle.js";

export const TextFieldCss = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0.104vw solid #DDDDDD',
      },
      '&:hover fieldset': {
        border: '0.104vw solid #444444'
      },
      '&.Mui-focused fieldset': {
        border: '0.104vw solid #444444'
      },
      '&.Mui-error fieldset': {
        border: '0.104vw solid #FF0000'
      },
      backgroundColor: whiteColor,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& fieldset': {
        border: '0.104vw solid #DDDDDD'
      },
      '&:hover fieldset': {
        border: '0.104vw solid #DDDDDD'
      },
      color: "initial",
      backgroundColor
    },
    '& .MuiFormHelperText-root.Mui-error': {
      textAlign: "right",
      color: "#FF0000",
      position: "absolute",
      right: 0,
      bottom: "-1.250vw",
      whiteSpace: "nowrap"
    },
    '& .MuiFormHelperText-contained': {
      marginRight: 0
    }
  },
})(TextField);

const NoBorderTextFieldCss = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px'
      },
      '&:hover fieldset': {
        border: '0px'
      },
      '&.Mui-focused fieldset': {
        border: '0px'
      },
      backgroundColor: whiteColor,
      height: "-webkit-fill-available"
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& fieldset': {
        border: '0px'
      },
      '&:hover fieldset': {
        border: '0px'
      },
      color: grayColor[0],
      backgroundColor
    },
    '& .MuiFormHelperText-root.Mui-error': {
      textAlign: "right",
      color: "#FF0000",
      position: "absolute",
      right: 0,
      bottom: "-1.250vw",
      whiteSpace: "nowrap"
    },
    '& .MuiFormHelperText-contained': {
      marginRight: 0
    }
  },
})(TextField);

const useStyles = makeStyles(styles);

export default function CustomTextField({label, noBorder, className, errorMsg, ...props}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {label && <Typography className={classes.label}>{label}</Typography>}
      { noBorder 
        ? <NoBorderTextFieldCss 
          {...props} 
          className={clsx(className, classes.textfield)}
          InputProps={{
            ...props.InputProps,
            classes: {
              input: classes.input
            }
          }}
          inputProps={{ maxLength: 255, ...props.inputProps }}
          onKeyDown={ (evt) => {
            if (props.type && props.type.toLowerCase() === "number") {
              if (evt.key === 'e' || evt.key === '+' || evt.key === '-') {
                evt.preventDefault();
              }
            }
          } }
          onWheel={(e) => e.target.blur()}
          error={errorMsg && errorMsg.field === props.id}
          helperText={errorMsg && errorMsg.field === props.id ? errorMsg.msg : ""}
        />
        : <TextFieldCss 
          {...props} 
          className={clsx(className, classes.textfield)}
          InputProps={{
            ...props.InputProps,
            classes: {
              input: classes.input
            }
          }}
          inputProps={{ maxLength: 255, ...props.inputProps }}
          onKeyDown={ (evt) => {
            if (props.type && props.type.toLowerCase() === "number") {
              if (evt.key === 'e' || evt.key === '+') { // || evt.key === '-'
                evt.preventDefault();
              }
            }
          } }
          onWheel={(e) => e.target.blur()}
          error={errorMsg && errorMsg.field === props.id}
          helperText={props.helperText ?? (errorMsg && errorMsg.field === props.id ? errorMsg.msg : "")}
        />
      }
    </React.Fragment>
  );
}

CustomTextField.propTypes = {
  label: PropTypes.node,
  noBorder: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};