import { createSlice } from "@reduxjs/toolkit";

import { GetAllASRSMaintenanceMasterList, GetASRSMaintenanceMaster, GetASRSMaintenanceStatus, GetAllCages, GetLocationIDListByCageId } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  maintenanceList: [],
  maintenance: {},
  maintenanceEdit: {}, //editing fields
  //lookup
  statusList: [],
  cageList: [],
  locationList: [],
};

const maintenanceASRS = createSlice({
  name: "maintenanceASRS",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.maintenance[key] = action.payload[key];
      })
    },
    updateMaintenanceRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.maintenanceEdit[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllASRSMaintenanceMasterList.fulfilled]: (state, action) => {
      state.maintenanceList = action.payload.result.items;
      state.totalCount = action.payload.result.totalCount;
    },
    [GetASRSMaintenanceMaster.fulfilled]: (state, action) => {
      state.maintenance = action.payload.result ?? {};
    },
    // lookup
    [GetASRSMaintenanceStatus.fulfilled]: (state, action) => {
      state.statusList = action.payload.result;
    },
    [GetAllCages.fulfilled]: (state, action) => {
      state.cageList = action.payload.result;
    },
    [GetLocationIDListByCageId.fulfilled]: (state, action) => {
      state.locationList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateMaintenanceRequest, reset } = maintenanceASRS.actions;

// export the reducer
export default maintenanceASRS.reducer;