import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import FilterButton from "shared-components/Button/FilterButton";
import Modal from "shared-components/Modal/Modal";
import Tag from 'shared-components/Chip/Tag';
import CustomIconButton from "shared-components/Button/IconButton";
import Autocomplete from "shared-components/Select/Autocomplete";
import InfiniteAutocomplete from "shared-components/Select/InfiniteAutocomplete";

// import { useRouteCanWrite } from "hooks";
import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { filterParam } from 'common/functions';
import { currentListingTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/currentListings";
import { GetAllCurrentListingList, GetAllASRSSoftzone, GetAllCages, CreateOrUpdateASRSOnboardingRecord, DeleteASRSOnboardingRecord } from "services/UserPortal/ASRSService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/asrsStyle.js";

const useStyles = makeStyles(styles);

export default function CurrentListings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const canWrite = useRouteCanWrite();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const filter = useSelector(store => store.user.asrs.currentListings.filter);
  const totalCount = useSelector(store => store.user.asrs.currentListings.totalCount);
  const result = useSelector(store => store.user.asrs.currentListings.onboardingList);
  const softzoneList = useSelector(store => store.user.asrs.currentListings.softzoneList);
  const cageList = useSelector(store => store.user.asrs.currentListings.cageList);
  const [softzonePage, setSoftzonePage] = React.useState(0); // for softzone list
  const [isLoadingMoreSoftzone, setIsLoadingMoreSoftzone] = React.useState(false); // for softzone list
  const [searchSoftzoneValue, setSearchSoftzoneValue] = React.useState(""); // for softzone list
  const [selectedSoftzone, setSelectedSoftzone] = React.useState({}); // for softzone list
  const [newFilter, setNewFilter] = React.useState({cageId: null, softzoneId: null, page: 0});
  const [editItem, setEditItem] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const handleOnChange_filter = (target, newValue) => {
    const item = _.find(cageList, ({cageRefNo}) => cageRefNo === newValue);
    setNewFilter({...newFilter, [target]: item.id});
  };

  const handleOnChange_softzoneFilter = (value) => {
    setNewFilter({...newFilter, softzoneId: value.softzoneID.toString()});
    setSelectedSoftzone(value);
  };

  const handleButtonClick_deleteTag = (key) => {
    if (!editItem) {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const handleOnEdit = (item) => {
    dispatch(GetAllASRSSoftzone({page: 0, softzoneID: item.softzoneID}))
    .then((response) => {
      if (response.payload.result && response.payload.result.items) {
        dispatch(setValues({softzoneList: response.payload.result.items}));
        setSearchSoftzoneValue(response.payload.result.items[0].softzoneID.toString());
        setEditItem({...item, softzone : response.payload.result.items[0]});
      }
    })
  };

  const handleOnCancel = () => {
    setSearchSoftzoneValue("");
    setEditItem(null);
    dispatch(GetAllASRSSoftzone({page: 0}))
    .then((response) => {
      if (response.payload.result) {
        setSoftzonePage(softzonePage+1);
        dispatch(setValues({softzoneList: response.payload.result.items}));
      }
    });
  };

  const handleOnChange_select = (target, newValue) => {
    const item = _.find(cageList, ({cageRefNo}) => cageRefNo === newValue);
    setEditItem({...editItem, [target] : newValue, cageId: item.id});
  };

  const handleOnChange_autocomplete = (value) => {
    setEditItem({...editItem, softzoneId: value.softzoneID.toString(), softzone: value});
  };

  const validateFields = () => {
    if (!editItem.cageRefNo) {
      setErrorMsg({field: "cageRefNo", msg: "Please select a cage"});
      return false;
    }
    if (!editItem.softzoneId) {
      setErrorMsg({field: "softzoneId", msg: "Please enter a softzone id"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const handleButtonClick_confirmEdit = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateASRSOnboardingRecord(editItem))
      .then(({error}) =>{
        if (!error) {
          dispatch(setValues({filter: {...filter, page: 0}}));
          setEditItem(null);
          setSearchSoftzoneValue(null);
        }
      })
    }
  }

  const handleModal = (id) => {
    setDeleteId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteASRSOnboardingRecord(deleteId))
    .then(() =>{
      dispatch(setValues({filter: {...filter, page: 0}}));
      handleModal(null);
      setDeleteId(null);
    })
  }

  const fetchMoreSoftzoneData = (search, pageNo) => {
    if (softzonePage !== -1 || pageNo !== undefined) {
      setIsLoadingMoreSoftzone(true);
      const param = {
        softzoneID: search ? search : 0,
        page: pageNo !== undefined ? pageNo : softzonePage,
      }
      dispatch(GetAllASRSSoftzone(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMoreSoftzone(false);
          if (response.payload.result) {
            setSoftzonePage(pageNo !== undefined ? pageNo+1 : softzonePage+1);
            if (search || softzonePage === 0 || pageNo === 0) {
              dispatch(setValues({softzoneList: response.payload.result.items}));
            } else {
              dispatch(setValues({softzoneList: _.unionBy(softzoneList, response.payload.result.items, "softzoneID")}));
            }
          } else {
            setSoftzonePage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }
  
  const getAllASRS = (stopLoading) => {
    dispatch(GetAllCurrentListingList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllASRS();
    }
  },[filter]);
  
  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllASRSSoftzone({page: 0}))
    .then((response) => {
      if (response.payload.result) {
        setSoftzonePage(softzonePage+1);
        dispatch(setValues({softzoneList: response.payload.result.items}));
      }
    });
    dispatch(GetAllCages());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));  // no filter button
  },[]);

  useInterval(() => {
    getAllASRS(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="VIFS - Current Listing of Cage ID with Softzone ID"
        subheader={
          <React.Fragment>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page") {
                let label = filter[key];
                if (key === "cageId") {
                  const cage = cageList.find(({id}) => id == filter[key]);
                  label = cage && cage.cageRefNo;
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_deleteTag(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <div className={classes.cardHeader}>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              disabled={editItem}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the VIFS list
                  <Autocomplete
                    className={classes.filterDropdown}
                    id="cageRefNo"
                    inputProps={{ maxLength: 50 }}
                    value={newFilter.cageRefNo}
                    onChange={(e, newValue) => {
                      handleOnChange_filter('cageId', newValue);
                    }}
                    options={cageList.map((listItem) => listItem.cageRefNo)}
                    placeholder="Select a cage"
                    errorMsg={errorMsg}
                  />
                  <InfiniteAutocomplete
                    className={classes.filterDropdown}
                    dataCount={softzonePage*10}
                    options={softzoneList}
                    fetchData={()=>fetchMoreSoftzoneData()}
                    renderOption={(option) => option.softzoneID}
                    isLoadingMore={isLoadingMoreSoftzone}
                    placeholder="Choose softzone ID"
                    onInputChange={(e, newInputValue) => {
                      if (e && e._reactName == "onClick") {
                        setSoftzonePage(0);
                        setSearchSoftzoneValue(newInputValue);
                        fetchMoreSoftzoneData(undefined, 0);
                      }
                      if (e && e._reactName == "onChange") {
                        setSoftzonePage(0);
                        setSearchSoftzoneValue(newInputValue);
                        fetchMoreSoftzoneData(newInputValue, 0);
                      }
                    }}
                    onChange={(_, newValue) => {
                      handleOnChange_softzoneFilter(newValue);
                    }}
                    inputValue={searchSoftzoneValue}
                    value={searchSoftzoneValue!==(selectedSoftzone&&selectedSoftzone.softzoneID) ? null : selectedSoftzone}
                    renderValue={"softzoneID"}
                  />
                </React.Fragment>
              }
            />
          </div>
        }
      >
        <div className={classes.table}>
          <Table
            header={currentListingTableHead}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes.snCell}>{('0'+((filter.page*rowsPerTable)+1+index)).slice(-2)}</TableCell>
                  <TableCell>
                    {(editItem && item.id === editItem.id)
                    ?  <Autocomplete
                        id="cageRefNo"
                        inputProps={{ maxLength: 50 }}
                        value={editItem.cageRefNo}
                        onChange={(e, newValue) => {
                          handleOnChange_select('cageRefNo', newValue);
                        }}
                        options={cageList.map((listItem) => listItem.cageRefNo)}
                        placeholder="Select a cage"
                        errorMsg={errorMsg}
                      />
                    : item.cageRefNo
                    }
                  </TableCell>
                  <TableCell>
                    {(editItem && item.id === editItem.id)
                    ? <InfiniteAutocomplete
                        dataCount={softzonePage*10}
                        options={softzoneList}
                        fetchData={()=>fetchMoreSoftzoneData()}
                        renderOption={(option) => option.softzoneID}
                        isLoadingMore={isLoadingMoreSoftzone}
                        placeholder="Choose softzone ID"
                        onInputChange={(e, newInputValue) => {
                          if (e && e._reactName == "onClick") {
                            setSoftzonePage(0);
                            setSearchSoftzoneValue(newInputValue);
                            fetchMoreSoftzoneData(undefined, 0);
                          }
                          if (e && e._reactName == "onChange") {
                            setSoftzonePage(0);
                            setSearchSoftzoneValue(newInputValue);
                            fetchMoreSoftzoneData(newInputValue, 0);
                          }
                        }}
                        onChange={(_, newValue) => {
                          handleOnChange_autocomplete(newValue);
                        }}
                        inputValue={searchSoftzoneValue}
                        value={searchSoftzoneValue!==(editItem.softzoneID) ? null : editItem.softzone}
                        renderValue={"softzoneID"}
                      />
                    : item.softzoneID
                    }
                  </TableCell>
                  <TableCell>{item.locationID}</TableCell>
                  <TableCell>{item.amelechID}</TableCell>
                  <TableCell>{item.aranetID}</TableCell>
                  <TableCell align="right">
                    {editItem && item.id === editItem.id
                    ? <React.Fragment>
                        <CustomIconButton 
                          type="confirm"
                          onClick={() => handleButtonClick_confirmEdit()}
                        />
                        <CustomIconButton 
                          type="close"
                          onClick={() => handleOnCancel()}
                        />
                      </React.Fragment>
                    : 
                    // canWrite &&
                      <React.Fragment>
                        <CustomIconButton 
                          type="edit"
                          onClick={() => handleOnEdit(item)}
                          disabled={editItem?true:false}
                        />
                        {item.reserveByUserId!==0 &&
                          <CustomIconButton 
                            type="delete"
                            onClick={() => handleModal(item.id)}
                            disabled={editItem?true:false}
                          />
                        }
                      </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
            disabled={editItem?true:false}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
