import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Switch from "@mui/material/Switch";
// core components
import styles from "assets/jss/shared-components/switchStyle.js";

const useStyles = makeStyles(styles);

export default function CustomSwitch({size, ...props}) {
  const classes = useStyles();

  return (
    <Switch
      {...props}
      focusVisibleClassName={classes.focusVisible}
      classes={{
        root: size?classes.rootsm:classes.root,
        switchBase: size?classes.switchBasesm:classes.switchBase,
        thumb: size?classes.thumbsm:classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
}

CustomSwitch.propTypes = {
  size: PropTypes.string,
};