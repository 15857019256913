import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles";
// core components
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { graphColors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/sustainabilityStyle";

const useStyles = makeStyles(styles);

export default function MonthlyChart(props) {
  const classes = useStyles();
  const results = useSelector(store => store.user.sustainability.chart.conversion);

  const start = moment(props.date.startDate);
  const end = moment(props.date.endDate);
  const months = [];

  while (end.isSameOrAfter(start, 'month')) {
    months.push({id: start.format('M YYYY'), label: start.format('MMM YYYY')});
    start.add(1, 'month');
  }

  const handleOnChange_date = (value) => {
    if (value) {
      props.setDate({startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")});
    }
  };

  const config = () => {
    return ({
      colors: graphColors,
      chart: {
        zoomType: 'x',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false
      },
      xAxis: [{
        categories: months.map(({label}) => label),
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        title: {
          text: 'Carbon Emissions (tonnes CO2)',
          // style: {
          //   color: Highcharts.getOptions().colors[0]
          // }
        },
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Energy Consumption (kWh)',
          // style: {
          //   color: Highcharts.getOptions().colors[2]
          // }
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        align: 'left',
      },
      series: [{
        name: 'Energy Consumption (PV)',
        type: 'column',
        yAxis: 1,
        data: months.map((item, i) => {
          const value = results.kwh && results.kwh.find((val) => val && val.month+" "+val.year === item.id);
          return {
            x: i,
            y: value && -value.pV_Value,
            color: graphColors[0]
          };
        }),
        tooltip: {
          valueSuffix: ' kWh'
        }
      }, {
        name: 'Energy Consumption (Grid)',
        type: 'column',
        yAxis: 1,
        data: months.map((item, i) => {
          const value = results.kwh && results.kwh.find((val) => val && val.month+" "+val.year === item.id);
          return {
            x: i,
            y: value && value.grid_Value,
            color: graphColors[1]
          };
        }),
        tooltip: {
          valueSuffix: ' kWh'
        }
      }, {
        name: 'Carbon Emission (PV)',
        type: 'spline',
        data: months.map((item, i) => {
          const value = results.kgco2 && results.kgco2.find((val) => val && val.month+" "+val.year === item.id);
          return {
            x: i,
            y: value && -value.pV_Value,
            color: graphColors[2]
          };
        }),
        tooltip: {
          valueSuffix: ' tonnes CO2'
        }
      }, {
        name: 'Carbon Emission (Grid)',
        type: 'spline',
        data: months.map((item, i) => {
          const value = results.kgco2 && results.kgco2.find((val) => val && val.month+" "+val.year === item.id);
          return {
            x: i,
            y: value && value.grid_Value,
            color: graphColors[3]
          };
        }),
        tooltip: {
          valueSuffix: ' tonnes CO2'
        }
      }],
    })
  };
  
  return (
    <React.Fragment>
      <div className={classes.action}>
        <DateRangePicker
          clearable
          className={classes.datepicker}
          placeholder="Date"
          value={{startDate: props.date.startDate, endDate: props.date.endDate}}
          onChange={(e) => handleOnChange_date(e)}
          onClear={()=>props.setDate({startDate: null, endDate: null})}
        />
      </div>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.highChart }} />
    </React.Fragment>
  );
}
