import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from 'shared-components/Modal/Modal';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Popper from "shared-components/Popper/Popper";

import { formatNumbers, roundTo2Decimal, renderValue } from "common/functions";
import { rowsPerTable } from "config";
import { setValues, reset } from "../store/search";
import { updateRequest } from "../store/issue";
import { setSearchText } from "store/general";
import { equipmentIssueTableHead } from "enums/UserPortal/TableHeaders";
import { SearchEquipmentReserveOrIssue, SaveIssuedEquipment } from "services/UserPortal/EquipmentService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/equipmentStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchIssueResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const userList = useSelector(store => store.common.userList);
  const totalCount = useSelector(store => store.user.equipment.search.issueTotalCount);
  const results = useSelector(store => store.user.equipment.search.issueResults);
  const issuedId = useSelector(store => store.user.equipment.issue.issuedId);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [issue, setIssue] = React.useState({});
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openIssueModal, setOpenIssueModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleModal_issue = (item) => {
    setOpenIssueModal(!openIssueModal);
    if (item) {
      setIssue({equipmentReserveIdList: [item.id], equipment: item});
    } else {
      setIssue({});
    }
  };

  const handleOnChange_issue = (e) => {
    if (e.target.id) {
      setIssue({...issue, [e.target.id]: e.target.value});
    } else {
      setIssue({...issue, [e.target.name]: e.target.value});
    }
  };
  
  const handleButtonClick_issue = () => {
    dispatch(SaveIssuedEquipment(issue))
    .then(({error}) => {
      if (!error) {
        setPage(0);
        setOpenIssueModal(!openIssueModal);
        dispatch(SearchEquipmentReserveOrIssue({keyword: params.keyword, page: 0}))
        .then((response) => {
          dispatch(setValues({issueTotalCount: response.payload.result.totalCount, issueResults: response.payload.result.items}))
        });
      }
    });
  }

  const handleButtonClick_checkbox = (e) => {
    let payload = _.cloneDeep(issuedId);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      payload.push(e.target.id);
    }
    dispatch(updateRequest(payload));
  };

  const handleButtonClick_save = () => {
    dispatch(SaveIssuedEquipment())
    .then(() => {
      setPage(0);
      dispatch(SearchEquipmentReserveOrIssue({keyword: params.keyword, page: 0}))
      .then((response) => {
        dispatch(setValues({issueTotalCount: response.payload.result.totalCount, issueResults: response.payload.result.items}))
      });
      dispatch(updateRequest([]));
    });
  };

  React.useEffect(() => {
    dispatch(SearchEquipmentReserveOrIssue({keyword: params.keyword, page, stopLoading: Boolean(page)}))
    .then((response) => {
      dispatch(setValues({issueTotalCount: response.payload.result.totalCount, issueResults: response.payload.result.items}))
    });
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Issue List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
            cardActions={!collapsed &&
              <React.Fragment>
                <Button disabled/>
                {canWrite &&
                  <Button 
                    className={classes.button}
                    onClick={()=>handleButtonClick_save()}
                    disabled={issuedId && issuedId.length === 0}
                  >
                    Save
                  </Button>
                }
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={equipmentIssueTableHead}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.workOrderNo)}</TableCell>
                            <TableCell>{highlightValue(item.equipmentName)}</TableCell>
                            <TableCell>{highlightValue(item.equipmentRefNo)}</TableCell>
                            <TableCell>{highlightValue(moment(item.timeStampReserved).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(item.productName)}</TableCell>
                            <TableCell>{highlightValue(item.productRefNo)}</TableCell>
                            <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.reservedQty)))}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.currentQty)))}</TableCell>
                            <TableCell>{highlightValue(item.uom)}</TableCell>
                            <TableCell>{highlightValue(moment(item.startDateTime).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(moment(item.endDateTime).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>
                              { item.location
                              ? item.location.cageLocationRefNo 
                                ? "Near "+item.location.cageLocationRefNo 
                                : item.location.floorNumber && item.location.sectorNumber ? "Floor "+item.location.floorNumber + " Sector "+ item.location.sectorNumber : ""
                              : ""
                              }
                            </TableCell>
                            <TableCell>{highlightValue(item.reservedUser)}</TableCell>
                            <TableCell>{highlightValue(item.status)}</TableCell>
                            <TableCell>{highlightValue(item.issuedUser ? moment(item.issuedTimeStamp).format("DD/MM/yyyy HH:mm") : "")}</TableCell>
                            <TableCell>{highlightValue(item.issuedUser)}</TableCell>
                            <TableCell align="center">
                              <Popper
                                noButton
                                open={openPopper === index}
                                className={classes.popper}
                                closePopper={() => setOpenPopper(false)}
                                placement="left"
                                content="Unable to issue equipment due to technical difficulties. Click on the checkbox to overwrite."
                              >
                                <div 
                                  onMouseOver={()=>item.unableToIssue && setOpenPopper(index)} 
                                  onMouseOut={()=>setOpenPopper(false)}
                                  onClick={()=>item.unableToIssue && handleModal_issue(item)}
                                >
                                  <Checkbox 
                                    id={String(item.id)}
                                    checked={Boolean(item.issuedUser || issuedId.includes(String(item.id)))}
                                    onChange={(e) => handleButtonClick_checkbox(e)}
                                    disabled={Boolean(item.unableToIssue || item.issuedUser || !canWrite)}
                                  />
                                </div>
                              </Popper>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={openIssueModal}
        onClose={() => handleModal_issue()}
        title="Issuance of Equipment Overwrite"
        content={
          <React.Fragment>
            Overwrite issuance for {issue.equipment&&issue.equipment.equipmentRefNo} ({issue.equipment&&issue.equipment.workOrderNo})
            <Select
              className={classes.filterDropdown}
              name="approvedUserId"
              onChange={(e)=>handleOnChange_issue(e)}
              placeholder="Select approved person"
              value={renderValue(issue.approvedUserId)}
            >
              {userList && userList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.userName}</MenuItem>;
              })} 
            </Select>
            <TextField
              className={classes.filterDropdown}
              id="reason"
              variant="outlined" 
              inputProps={{ maxLength: 255 }}
              placeholder="Add reason"
              onChange={(e) => handleOnChange_issue(e)}
              value={renderValue(issue.reason)}
            />
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_issue()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_issue()}>Save</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
