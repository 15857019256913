/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import OperationsTimeline from "shared-components/Timeline/OperationsTimeline";
import FilterButton from "shared-components/Button/FilterButton";

import { useInterval } from 'common/utils';
import { GetWorkOrderTimeLine, GetBankLookUp } from "services/UserPortal/MonitoringService";
import { farmConfig } from "enums/Constants";
import { setSearchText } from "store/general";
import { filterParam, renderValue } from "common/functions";
import { setValues } from "../store/workOrder";

import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function WorkOrder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.monitoring.workOrder.filter);
  const bankList = useSelector(store => store.user.monitoring.workOrder.bankList);
  const workOrder = useSelector(store => store.user.monitoring.workOrder.workOrder);
  const [newFilter, setNewFilter] = React.useState({bankId: 0});

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const getWorkOrderTimeline = (stopLoading) => {
    if (filter.bankId) {
      dispatch(GetWorkOrderTimeLine({...filter, stopLoading}));
    } else {
      dispatch(setValues({filter: {workOrder: {}}}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
    } else {
      if (bankList.length) {
        dispatch(setValues({filter: {bankId: bankList[0].bankId}}));
      } else {
        dispatch(setValues({filter: {bankId: null}}));
      }
    }
  },[bankList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      const param = {
        bankId: filter.bankId
      }
      getWorkOrderTimeline(param);
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetBankLookUp());
  },[]);

  useInterval(() => {
    getWorkOrderTimeline(true);
  });

  const bank = bankList && bankList.find(({bankId}) => bankId == filter.bankId);
  return (
    <React.Fragment>
      <Card 
        title={"Work Order for " + (bank && bank.bankName)}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose a {farmConfig.bank}
                  <Select
                    className={classes.filterDropdown}
                    name="bankId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder={"Select a "+farmConfig.bank}
                    value={renderValue(newFilter.bankId)}
                  >
                    {bankList && bankList.map((item, index) => {
                      return <MenuItem key={index} value={item.bankId}>{item.bankName}</MenuItem>;
                    })}
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <OperationsTimeline
          value={workOrder}
        />
      </Card>
    </React.Fragment>
  );
}
