import { createSlice } from "@reduxjs/toolkit";

import { GetAllCountries, GetAllCompanySizes, GetAllCurrencies, GetAllIndustries, GetAllTimeZones, GetAllUOMs, GetAllWorkForceTypes, GetCreditTermList } from "services/AdminPortal/GeneralService";
import { GetWarehouseAllUnitOfMeasurement } from "services/AdminPortal/WarehouseService";

// initial state
const initialState = {
  countryLookup: [],
  companySizeLookup: [],
  currencyLookup: [],
  industryLookup: [],
  timezoneLookup: [],
  uomLookup: [],
  workforceTypeLookup: [],
  creditTermLookup: [],
  filteredUomLookup: [],
};

// create reducer and action creators
const lookup = createSlice({
  name: "lookup",
  initialState,
  reducers: {
    reset: (state) => {
      state.countryLookup=[];
      state.companySizeLookup=[];
      state.currencyLookup=[];
      state.industryLookup=[];
      state.uomLookup=[];
      state.workforceTypeLookup=[];
      state.creditTermLookup=[];
      state.filteredUomLookup=[];
    },
  },
  extraReducers: {
    [GetAllCountries.fulfilled]: (state, action) => {
      state.countryLookup = action.payload.result;
    },
    [GetAllCompanySizes.fulfilled]: (state, action) => {
      state.companySizeLookup = action.payload.result;
    },
    [GetAllCurrencies.fulfilled]: (state, action) => {
      state.currencyLookup = action.payload.result;
    },
    [GetAllIndustries.fulfilled]: (state, action) => {
      state.industryLookup = action.payload.result;
    },
    [GetAllTimeZones.fulfilled]: (state, action) => {
      state.timezoneLookup = action.payload.result;
    },
    [GetAllUOMs.fulfilled]: (state, action) => {
      state.uomLookup = action.payload.result;
    },
    [GetAllWorkForceTypes.fulfilled]: (state, action) => {
      state.workforceTypeLookup = action.payload.result;
    },
    [GetCreditTermList.fulfilled]: (state, action) => {
      state.creditTermLookup = action.payload.result;
    },
    [GetWarehouseAllUnitOfMeasurement.fulfilled]: (state, action) => {
      state.filteredUomLookup = action.payload.result;
    },
  },
});

// export actions
export const { reset } = lookup.actions;

// export the reducer
export default lookup.reducer;