import {
  icon_24,
  icon_38,
  icon_64,
  cardRadius,
  border,
  button,
  buttonSecondary,
  backgroundColor,
  whiteColor,
  primaryColor,
  secondaryColor,
  graphColors
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  cardPaper,
  collapsedIcon,
  rotate,
  loading,
  link,
} from "assets/jss/components/commonStyle.js";

const capacityStyle = {
  icon_24,
  icon_64,
  cardRadius,
  button,
  buttonSecondary,
  orange,
  icon,
  table,
  cardPaper,
  collapsedIcon,
  rotate,
  loading,
  link,

  cardHeader: {
    alignSelf: "baseline"
  },
  cardMargin: {
    marginTop: "2.604vw"
  },
  buttonMargin: {
    marginLeft: "1.563vw"
  },
  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center",
    textAlign: "left"
  },
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
  },
  legend: {
    display: "flex",
    marginTop: "1.667vw",
    marginLeft: "1.563vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  colorBox: {
    border,
    width: "1.146vw",
    height: "1.146vw",
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  legendColor: {
    "&:nth-child(1)": {
      backgroundColor: graphColors[0]
    },
    "&:nth-child(2)": {
      backgroundColor: graphColors[1],
    },
    "&:nth-child(3)": {
      backgroundColor: graphColors[2],
    },
    "&:nth-child(4)": {
      backgroundColor: graphColors[3],
    },
    "&:nth-child(5)": {
      backgroundColor: graphColors[4],
    }
  },
  heatMapLegend: {
    textAlign: "center",
    width: "5.573vw",
    fontWeight: 500
  },
  smallText: {
    fontSize: "0.833vw"
  },
  bankView: {
    overflow: "overlay",
    width: "-webkit-fill-available",
    maxHeight: "26.042vw",
  },
  floorPlanView: {
    padding: "1.042vw 2.083vw",
    overflow: "overlay",
    width: "-webkit-fill-available",
    maxHeight: "26.042vw",
    "&>div:first-child": {
      width: "initial",
    }
  },
  markerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-40%, -20%)",
    cursor: "pointer"
  },
  marker: {
    ...icon_38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: primaryColor[8],
    borderRadius: "50%",
    fontSize: "0.938vw",
    fontWeight: 500,
    backgroundColor: "#a1adaa",
    //border: "0.208vw solid " + secondaryColor[0],
  },
  markerText: {
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
    backgroundColor: "#FFFFFFA6"
  },
  reserved: {
    backgroundColor: "#a1adaa",
    color: whiteColor
  },
  partially: {
    backgroundColor: primaryColor[6],
    color: whiteColor
  },
  fully: {
    backgroundColor: primaryColor[7],
  },
  unassigned: {
    backgroundColor: primaryColor[1],
    color: whiteColor
  },
  legendIcon: {
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  mainTable: {
    '& thead > tr > th:first-child': {
      width: "6vw",
      minWidth: "6vw"
    },
    '& thead > tr > th:nth-child(2)': {
      width: "12vw",
      minWidth: "12vw"
    },
    "& tbody > tr": {
      backgroundColor: whiteColor
    },
    "& tbody > tr:nth-child(4n+1)": {
      backgroundColor: backgroundColor
    },
    "& tbody > tr:nth-child(4n+2)": {
      backgroundColor: backgroundColor
    },
    "& tbody > tr > td:first-child": {
      paddingLeft: "2.292vw"
    },
    "& tbody > tr > td:last-child": {
      paddingRight: "2.292vw"
    },
  },
  subTable: {
    margin: "0 4.167vw",
    '& thead > tr > th:first-child': {
      width: "unset !important",
      minWidth: "unset !important"
    },
    '& thead > tr > th': {
      backgroundColor: "transparent !important"
    },
    "& tbody > tr": {
      backgroundColor: "transparent !important",
      padding: "0 2.292vw"
    },
  },
  progress: {
    width: "2.500vw !important",
    height: "2.500vw !important",
    color: secondaryColor[0],
    marginTop: "2.083vw",
    display: "flex",
    margin: "auto"
  },
  modal: {
    width: "52.083vw"
  },
  modalContent: {
    maxHeight: "20.833vw",
    overflow: "overlay",
    textAlign: "left"
  },
  modalGrid: {
    paddingTop: "2.813vw"
  },
  modalGridItem: {
    display: "flex",
    alignItems: "start",
    marginTop: "0.313vw",
    textAlign: "left"
  },
  modalGridButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  modalLabel: {
    minWidth: "fit-content",
    color: primaryColor[4],
    textAlign: "left",
    marginTop: "0.521vw",
  },
  modalLink: {
    display: "flex",
    textAlign: "left",
    color: secondaryColor[0],
    marginTop: "0.521vw",
    cursor: "pointer",
    "& :first-child": {
      marginRight: "0.521vw"
    },
  },
  boardDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.521vw",
  },
  boardLabel: {
    color: primaryColor[4],
    textAlign: "left",
    minWidth: "fit-content",
    paddingRight: "0.521vw",
  },
  topRightTabSpacing: {
    marginLeft: "1.465vw"
  },
  bankFloorTab: {
    border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw",
    // margin: "2.604vw 0 1.042vw"
  },
  bankFloorLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minHeight: "1.875vw"
  },
  selectedTab: {
    color: secondaryColor[0] + " !important",
    backgroundColor: secondaryColor[0] + "20",
  },
  moreList: {
    border,
    ...cardRadius,
    height: "13.021vw",
    overflow: "overlay",
    marginTop: "1.250vw"
  },
  // Device Control Form
  formTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "1.927vw",
    "&:not(:first-child)": {
      marginTop: "2.604vw"
    },
  },
  formLabel: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.521vw",
    whiteSpace: "nowrap",
    color: primaryColor[4],
    marginTop: "0.833vw",
    // alignSelf: "center",
    width: "7.813vw",
    minWidth: "7.813vw"
  },
  ruleGrid: {
    paddingBottom: "1.927vw",
  },
  ruleTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    alignSelf: "center",
  },
  grid: {
    display: "flex",
  },
  gridButton: {
    textAlign: "right",
  },
  switchLabel: {
    marginRight: "0.521vw",
    alignSelf: "center",
  },
  switch: {
    alignSelf: "center",
  },
  checkbox: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.573vw",
    alignSelf: "baseline"
  },
  checkboxPadding: {
    marginLeft: "10.417vw",
    marginRight: "0.573vw",
    alignSelf: "center"
  },
  checkboxLabel: {
    marginRight: "2.292vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  checkboxLabel2: {
    alignSelf: "start",
    marginTop: "0.625vw"
  },
  error: {
    marginLeft: "11.458vw",
    marginTop: "-0.833vw",
    color: "#FF0000",
    fontSize: "0.729vw",
  },
  helperText: {
    textAlign: "right",
    fontSize: 12,
  },
}

export default capacityStyle;