// Create Edit Devices
// Step 3: Device Testing
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";

import DeviceStepper from "./DeviceStepper";
import { deviceInfoTableHead, deviceTestTableHead } from "enums/AdminPortal/TableHeaders";
import { updateRequest } from "./store/device";

import styles from "assets/jss/components/AdminPortal/deviceStyle.js";
import { Typography } from "@mui/material";

const useStyles = makeStyles(styles);

export default function TestingForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const device = useSelector(store => store.admin.device.device.device);

  const onButtonClick_test = () => {
    dispatch(updateRequest({testTime: Date.now(), testConfigurationString: "error"}))
  }

  return (
    <Card 
      title="Step 3: Testing of Device Information" 
      subheader={<DeviceStepper activeStep={props.step} />}
      cardActions={
        <React.Fragment>
          <Button
            className={classes.buttonSecondary}
            onClick={()=>props.setStep(1)}
          >
            Go Back
          </Button>
          <div>
            <Button
              className={clsx(classes.buttonSecondary, classes.buttonMargin)}
              onClick={()=>props.setStep(3)}
            >
              Skip Test
            </Button>
            <Button
              className={clsx(classes.button, classes.buttonMargin)}
              onClick={()=>onButtonClick_test()}
              disabled={!device.manufacturerID ? true : false}
            >
              Test Now
            </Button>
            <Button
              className={classes.button}
              onClick={()=>props.setStep(3)}
              disabled={device.testing ? false : true} // if no test result
            >
              Next Step
            </Button>
          </div>
        </React.Fragment>
      }
    >
      <div className={classes.content}>
        <div className={classes.subContent}>
          <Paper className={clsx(classes.paper, classes.infoPaper)} elevation={0}>
            <Table
              className={classes.table}
              header={deviceInfoTableHead}
            >
              <TableRow>
                <TableCell>{device.deviceRefNo}</TableCell>
                <TableCell>{device.manufacturerID}</TableCell>
              </TableRow>
            </Table>
          </Paper>
        </div>
        <div className={classes.subContent}>
          { device.testConfigurationString &&
            <Paper className={clsx(classes.paper, classes.infoPaper)} elevation={0}>
              <Typography className={classes.paperTitle}>Readings Obtained:</Typography>
              <Table
                className={classes.table}
                header={deviceTestTableHead}
              >
                <TableRow>
                  <TableCell>{format(device.testTime, "dd/MM/yyyy H:mm:ss")}</TableCell>
                  <TableCell>{device.testConfigurationString}</TableCell>
                </TableRow>
              </Table>
            </Paper>
          }
        </div>
      </div>
    </Card>
  )
}
