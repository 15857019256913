import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import { graphColors as colors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/reportStyle.js";

const useStyles = makeStyles(styles);

export default function DashboardChart(props) {
  const classes = useStyles();

  const results = props.results.graphDataList ?? [];

  const config = () => {
    const categories = _.uniq(_.flattenDeep(results.map((item) => {
      return item.dataList.map(({xValue}) => xValue);
    }))).sort();
    if (props.results.visualisationType && props.results.visualisationType.includes("Bar")) {
      return ({
        chart: {
          type: 'column',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories,
          title: {
            text: results[0] && results[0].xaxisVariableName
          }
        },
        yAxis: {
          title: {
            text: results[0] && results[0].yaxisVariableName
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true
        },
        series: results.map((item, i) => {
          return {
            data: item.dataList.map(({xValue, yValue}) => [_.indexOf(categories, xValue), Number(yValue)]),
            name: item.productName,
            color: colors[i]
          };
        })
      })
    } else if (props.results.visualisationType && props.results.visualisationType.includes("Line")) {
      return ({
        chart: {
          zoomType: 'x',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories,
          title: {
            text: results[0] && results[0].xaxisVariableName
          }
        },
        yAxis: {
          title: {
            text: results[0] && results[0].yaxisVariableName
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true
        },
        series: results.map((item, i) => {
          return {
            data: item.dataList.map(({xValue, yValue}) => [_.indexOf(categories, xValue), Number(yValue)]),
            name: item.productName,
            color: colors[i]
          };
        })
      })
    } else {
      return ({
        chart: {
          type: 'scatter',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: categories,
          title: {
            text: results[0] && results[0].xaxisVariableName
          }
        },
        yAxis: {
          title: {
            text: results[0] && results[0].yaxisVariableName
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: true
        },
        series: results.map((item, i) => {
          return {
            data: item.dataList.map(({xValue, yValue}) => [_.indexOf(categories, xValue), Number(yValue)]),
            name: item.productName,
            color: colors[i]
          };
        })
      })
    }
  };
  
  return (
    <React.Fragment>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.highChart }} />
    </React.Fragment>
  );
}
