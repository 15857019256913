import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { filterParam, filterUrl } from 'common/functions';
import { reset, setValues } from "../store/overview";
import { flowControlTableHead, subFlowControlTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllWaterFlowControl, CancelWaterFlowControl, ExecuteWaterFlowControl } from "services/UserPortal/FlowService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/flowControlStyle.js";

const useStyles = makeStyles(styles);

export default function FlowControl() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.flowControl.overview.totalCount);
  const results = useSelector(store => store.user.flowControl.overview.results);
  const filter = useSelector(store => store.user.flowControl.overview.filter);
  const [newFilter, ] = React.useState({page: 0});
  const [subPage, setSubPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const count = Math.ceil(totalCount / rowsPerTable);

  const getAllWaterFlowControl = (stopLoading) => {
    dispatch(GetAllWaterFlowControl({...filter, stopLoading}));
  };

  const handleButtonClick_cancel = (device) => {
    dispatch(CancelWaterFlowControl({deviceId: device.deviceId}))
    .then(() => {
      getAllWaterFlowControl();
    });
  }

  const handleModal = (item) => {
    setSelected(item);
    if (item) {
      dispatch(setValues({flowControl: {deviceId: item.deviceId, targetFlow: item.volumeOfWater}}));
    } else {
      dispatch(setValues({flowControl: {}}));
    }
    setOpenModal(!openModal);
  }

  const handleButtonClick_execute = () => {
    dispatch(ExecuteWaterFlowControl())
    .then(() => {
      getAllWaterFlowControl();
    });
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllWaterFlowControl();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllWaterFlowControl(true);
  }, [1000]);

  return (
    <React.Fragment>
      <Card 
        title="Status of Control Taken"
      >
        <div className={classes.table}>
          <Table
            expandable
            className={classes.mainTable}
            header={flowControlTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { results.map((item,index) => {
              const subCount = Math.ceil(item.waterFlowRecordList.length / rowsPerTable);
              return (
                <React.Fragment key={index}>
                  <TableRow 
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => open === index? setOpen(false) : setOpen(index)}
                  >
                    <TableCell>
                      <IconButton 
                        className={open === index ? classes.rotate : null}
                        onClick={() => open === index? setOpen(false) : setOpen(index)}
                      >
                        <img className={classes.icon} src={arrow} alt="arrow" />
                      </IconButton>
                    </TableCell>
                    <TableCell>{item.deviceName ?? item.deviceId}</TableCell>
                    <TableCell>{item.volumeOfWater}</TableCell>
                    <TableCell>{item.actualVolumeOfWater}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      { item.status === 'Pending' 
                        ? <Button className={classes.cancelButton} onClick={()=>handleButtonClick_cancel(item)}>Cancel</Button> 
                        : <Button className={classes.executeButton} onClick={()=>handleModal(item)}>Re-execute</Button> 
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    { open===index && 
                      <TableCell className="pt-0 pb-0" colSpan={flowControlTableHead.length}>
                        <Collapse in={open === index}>
                          <Table
                            className={classes.subTable}
                            header={subFlowControlTableHead}
                          >
                            { item.waterFlowRecordList.slice(subPage * rowsPerTable, subPage * rowsPerTable + rowsPerTable)
                            .map((subItem, subIndex) => {
                              return (
                                <TableRow key={subIndex}>
                                  <TableCell>{subItem.deviceIntegrationRefNo}</TableCell>
                                  <TableCell>{subItem.flow}</TableCell>
                                  <TableCell>{moment(subItem.updatedTime).format('DD/MM/YYYY HH:mm')}</TableCell>
                                </TableRow>
                              )
                            })}
                          </Table>
                          { subCount > 1 &&
                            <Pagination 
                              count={subCount} 
                              page={subPage+1}
                              onChange={(e, pageNo)=>setSubPage(pageNo-1)}
                            />
                          }
                        </Collapse>
                      </TableCell>
                    }
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
            />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        title="Confirm Execution?"
        content={selected && <span>Confirm to execute <b>{selected.volumeOfWater} litre(s)</b> from <b>{selected.deviceName}</b>?</span>}
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_execute()}>Confirm</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
