/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrStatus } from "enums/Constants";
import { amrListingTableHead, amrDetailsTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/overview";
import { ExecuteGetAllRobots, GetUpcomingMissions } from "services/UserPortal/AMRService";

import DateTabs from "./DateTabs";
import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function AMRListing() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.amr.overview.filter);
  const result = useSelector(store => store.user.amr.overview.result);
  const upcoming = useSelector(store => store.user.amr.overview.upcoming);
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState({startDate: moment().format(), endDate: moment().endOf('day').format()});

  const count = Math.ceil(result.length / rowsPerTable);
  
  const openDetails = (id) => {
    setOpen(id);
    getUpcomingMission(id);
  }
  
  const getUpcomingMission = (id) => {
    if (id) {
      dispatch(GetUpcomingMissions({robot_id: id, ...date}));
    }
  }
  
  const getAllAMR = (stopLoading) => {
    dispatch(ExecuteGetAllRobots({stopLoading}));
  }

  React.useEffect(() => {
    getAllAMR();
  },[]);

  useInterval(() => {
    getAllAMR(true);
    getUpcomingMission(open);
  });

  return (
    <React.Fragment>
      <div className={classes.table}>
        <Table
          expandable
          className={classes.mainTable}
          header={amrListingTableHead}
        >
          { result.map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  onClick={() => openDetails(open === item.id ? false : item.id)}
                >
                  <TableCell>{item.alias}</TableCell>
                  <TableCell>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <LinearProgress 
                        classes={{
                          root: classes.progressBar, 
                          barColorPrimary: classes["progress"+(item.battery ? parseInt(item.battery/10) : "1")]
                        }} 
                        variant="determinate" 
                        value={item.battery ?? 0} /> 
                      {item.battery ?? 0}%
                    </div>
                  </TableCell>
                  <TableCell>{item.hoursLeft}</TableCell>
                  <TableCell>{item.currentTask}</TableCell>
                  <TableCell>{amrStatus[item.run_state]}</TableCell>
                  <TableCell><Typography className={classes.link} onClick={() => openDetails(open === item.id ? false : item.id)}>View Details</Typography></TableCell>
                </TableRow>
                <TableRow>
                  { open===item.id && 
                    <TableCell className="pt-0 pb-0" colSpan={9}>
                      <div className={classes.scaleContainer}>
                        <DateTabs setDate={setDate} date={date} />
                      </div>
                      <Collapse in={open === item.id}>
                        <Table
                          className={classes.subTable}
                          header={amrDetailsTableHead}
                        >
                          { upcoming.length
                            ? upcoming.map((subItem, subIndex) => {
                                return (
                                  <React.Fragment key={subIndex}>
                                    <TableRow>
                                      <TableCell>{subItem.id}</TableCell>
                                      <TableCell>{moment(subItem.delayDateTime).format("DD/MM/YYYY")}</TableCell>
                                      <TableCell>{moment(subItem.creationTime).format("DD/MM/YYYY HH:mm")}</TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                )
                              })
                            : <TableRow>
                                <TableCell colSpan={4}>There are no upcoming tasks</TableCell>
                              </TableRow>
                          }
                        </Table>
                      </Collapse>
                    </TableCell>
                  }
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={filter.page+1}
          onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
        />
      }
    </React.Fragment>
  );
}
