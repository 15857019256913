import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

import { GetData, GetWattageData, GetAllCostChartRequest, GetTodayYesterdayData, GetDBNames, GetAllWattageAddresses, GetWattageHourAddresses, GetCurrentAddresses, GetVoltageAddresses } from "services/UserPortal/SmartMeteringFlagshipService";

// initial state
const initialState = {
  filter: {startDate: moment().subtract(1,'hours').format(), endDate: moment().format()},
  result: [],
  consumption: {},
  // lookup
  deviceList: [],
  databaseList: [],
};

const smOverview = createSlice({
  name: "smOverview",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    // updateRequest: (state, action) => {
    //   Object.keys(action.payload).forEach((key) => {
    //     state.batch[key] = action.payload[key];
    //   })
    // },
    reset: () => initialState,
  },
  extraReducers: {
    [GetData.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
    [GetWattageData.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
    [GetAllCostChartRequest.fulfilled]: (state, action) => {
      state.result = action.payload.result ?? [];
    },
    [GetTodayYesterdayData.fulfilled]: (state, action) => {
      state.consumption = action.payload.result ?? [];
    },
    // lookup
    [GetDBNames.fulfilled]: (state, action) => {
      const payload = _.cloneDeep(action.payload.result);
      state.databaseList = payload.sort();
    },
    [GetAllWattageAddresses.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
    [GetWattageHourAddresses.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
    [GetCurrentAddresses.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
    [GetVoltageAddresses.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = smOverview.actions;

// export the reducer
export default smOverview.reducer;