import moment from "moment";
import en from "assets/flags/uk.svg";
import id from "assets/flags/id.svg";
import th from "assets/flags/th.svg";

export var snackbarTime = 6000;
export var rowsPerTable = 5;
export var minDate = moment("0001-01-01");
export var minDateTime = moment("0001-01-01 00:00:00");
export var datetime = moment().set({second: 0, millisecond: 0});
export var minTime = "00:00:00";

export var location = {
  longitude: "103.78930650819217",
  latitude: "1.3757771917613002"
};

export var hideCages = false;

//this is for on-premise environment
export var FOMS_URL = "http://172.17.252.212";

// ML
export var ML_URL = 'https://mlapi-staging1.greenphyto.com';

// chatbot
// export var AIBOT_URL = "https://greenphyto-aibot.greenphyto.com";
export var AIBOT_URL = "http://172.17.253.207";

// energy management
export var Energy_URL = "https://fomsapi-energy-integration.greenphyto.com";

// amr
export var AMR_URL = "https://fomsapi-staging1.greenphyto.com";

// Strapi
export var STRAPI_URL = 'http://localhost:1337/api';
export var STRAPI_TOKEN = 'cc2f939a9d8fbae30bcf67587cabbef7a969b57e2bbce653fe62fdbb6c368e514bed9e06b75c10e8ae848a013e50d80dc08332f13121d48d3e7dcb8ba4c20f9fabad965e4eed50d6a8fbb2fe55f9d824685de825005d33a11246a647126d72276865532233446b102c8dc16dec2a995de71ba3c89e8981a1941fe7dbb2f6def9';

export var locale = [
  {
    name: "ENG",
    code: "en",
    flag: en
  },
  {
    name: "IND",
    code: "id",
    flag: id
  },
  {
    name: "THA",
    code: "th",
    flag: th
  }
]

export var contentType = {
  sidebar: "sidebar",
}
