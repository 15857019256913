import { 
  icon_24, 
  button, 
  buttonSecondary 
} from "assets/jss/generaliseStyle";

const formStyle = {
  button,
  buttonSecondary,

  title: {
    fontSize: "1.875vw",
    fontWeight: 500,
    paddingBottom: "1.042vw"
  },
  subtitle: {
    fontSize: "0.938vw",
    opacity: 0.5,
    paddingBottom: "2.344vw"
  },
  buttonStyle: {
    width: "100%",
    marginTop: "1.563vw",
    height: "2.604vw"
  },
  textfield: {
    marginBottom: "1.563vw"
  },
  adornment: {
    ...icon_24,
    padding: 0,
    position: "absolute"
  },
};
  
export default formStyle;
      