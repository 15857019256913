import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Drawer from '@mui/material/Drawer';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Checkbox from "shared-components/Checkbox/Checkbox";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues, updateRequest } from './store/report';
import { GetDataSourceList, GetVegetableType, GetXaxisVariableName, GetYaxisVariableName, GetVisualisationType, GetQCReportConfiguration, GenerateGraph, CreateOrUpdateQCReportConfiguratiion } from "services/UserPortal/ReportService";

import ReportChart from "./ReportChart";
import styles from "assets/jss/components/UserPortal/reportStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditConfigurations(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const graph = useSelector(store => store.user.report.report.graph);
  const dataSourceList = useSelector(store => store.user.report.report.dataSourceList);
  const vegetableTypeList = useSelector(store => store.user.report.report.vegetableTypeList);
  const xaxisList = useSelector(store => store.user.report.report.xaxisList);
  const yaxisList = useSelector(store => store.user.report.report.yaxisList);
  const visualisationTypeList = useSelector(store => store.user.report.report.visualisationTypeList);
  const config = useSelector(store => store.user.report.report.config);
  const [open, setOpen] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const prop = props.location.state ?? {};

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id]: e.target.value}));
  }

  const handleOnChange_select = (e) => {
    if (e.target.name === "dataSource") {
      dispatch(updateRequest({xaxis: null, yaxis: null, [e.target.name]: e.target.value}));
    } else {
      dispatch(updateRequest({[e.target.name]: e.target.value}));
    }
  }

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateRequest({vegetableType: value}));
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(updateRequest({startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")}));
    }
  };

  const handleOnGenerate = () => {
    if (validateFields()) {
      dispatch(GenerateGraph());
      setOpen(false);
    }
  }

  const handleOnSave = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateQCReportConfiguratiion())
      .then(({error}) => {
        if (!error) {
          Promise.all([dispatch(setDirty(false))])
          .then(() => {
            history.goBack();
          })
        }
      });
    }
  }

  const validateFields = () => {
    if (!config.configurationName) {
      setErrorMsg({field: "configurationName", msg: "Enter configuration name"});
      return false;
    }
    if (!config.dataSource) {
      setErrorMsg({field: "dataSource", msg: "Select data source"});
      return false;
    }
    if (!config.vegetableType) {
      setErrorMsg({field: "vegetableType", msg: "Select vegetable type"});
      return false;
    }
    if (!(config.vegetableType && config.vegetableType.length)) {
      setErrorMsg({field: "vegetableType", msg: "Select vegetable type"});
      return false;
    }
    if (!config.startDate) {
      setErrorMsg({field: "date", msg: "Enter a start date"});
      return false;
    }
    if (!moment(config.startDate).isValid()) {
      setErrorMsg({field: "date", msg: "Invalid Date"});
      return false;
    }
    if (!config.endDate) {
      setErrorMsg({field: "date", msg: "Enter an end date"});
      return false;
    }
    if (!moment(config.endDate).isValid()) {
      setErrorMsg({field: "date", msg: "Invalid Date"});
      return false;
    }
    if (config.endDate && moment(config.endDate).isBefore(moment(config.startDate))) {
      setErrorMsg({field: "date", msg: "End date cannot be before start date"});
      return false;
    }
    if (!config.xaxis) {
      setErrorMsg({field: "xaxis", msg: "Select X Axis"});
      return false;
    }
    if (!config.yaxis) {
      setErrorMsg({field: "yaxis", msg: "Select Y Axis"});
      return false;
    }
    if (!config.visualisationType) {
      setErrorMsg({field: "visualisationType", msg: "Select visualisation type"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    if (config.dataSource && config.xaxis) {
      dispatch(GetYaxisVariableName({dataSource: config.dataSource, xaxis: config.xaxis}));
    }
  },[config.dataSource, config.xaxis]);

  React.useEffect(() => {
    if (config.dataSource) {
      dispatch(GetVegetableType({dataSource: config.dataSource}));
      dispatch(GetXaxisVariableName({dataSource: config.dataSource}));
    }
  },[config.dataSource]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetDataSourceList());
    dispatch(GetVisualisationType());
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "configurations");
    if (path[index+1] !== "create") {
      dispatch(GetQCReportConfiguration(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.goBack();
        } else {
          dispatch(setDirty(true));
        }
      });
    } else {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setValues({config: {}}));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {(prop.id ? "Edit" : "Add") + " Configurations"}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Go Back
            </Button>
            <Button 
              className={classes.button}
              onClick={handleOnSave}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.content}>
          <IconButton
            className={classes.formIconButton2}
            onClick={()=>setOpen(!open)}
          >
            {!open ? <KeyboardArrowRightIcon className={classes.icon_24} /> : <KeyboardArrowLeftIcon className={classes.icon_24} />}
          </IconButton>
          {_.isEmpty(graph)
          ? <Typography>Click on &quot;Generate&quot; to view graph</Typography>
          : <ReportChart />
          }
          <Drawer
            sx={{
              // width: drawerWidth,
              // flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: "90%",
                position: 'absolute',
                padding: "1.563vw",
                paddingLeft: "3.125vw",
                marginLeft: "-1.979vw",
                border: "0.052vw solid #D9D9D6",
                borderLeft: 0,
                borderBottomRightRadius: "0.833vw",
                overflow: "visible"
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Paper className={classes.paper} elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    id="configurationName"
                    label="Configuration Name"
                    variant="outlined" 
                    inputProps={{ maxLength: 50 }}
                    placeholder="Type in the Configuration name"
                    onChange={(e) => handleOnChange_text(e)}
                    value={config.configurationName}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="dataSource"
                    label="Data Source"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={config.dataSource}
                    placeholder="Select Data Source"
                    errorMsg={errorMsg}
                  >
                    { dataSourceList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    dataCount={vegetableTypeList.length}
                    id="vegetableType"
                    label="Vegetable Types"
                    value={config.vegetableType ? config.vegetableType : []}
                    options={vegetableTypeList}
                    renderOption={(option) => (
                      <div key={option}>
                        <Checkbox checked={config.vegetableType ? config.vegetableType.indexOf(option) > -1 : false} />
                        {option}
                      </div>
                    )}
                    onChange={(_, newValue) => {
                      handleOnChange_autocomplete(newValue);
                    }}
                    placeholder="Select vegetable types" 
                    disabled={!config.dataSource}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateRangePicker
                    noButton
                    name="date"
                    style={{width: '100%' }}
                    label="Date Range"
                    placeholder="Select Date Range"
                    value={{startDate: config.startDate, endDate: config.endDate}}
                    onChange={(e) => handleOnChange_date(e)}
                    errorMsg={errorMsg}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="xaxis"
                    label="X Axis"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={config.xaxis}
                    placeholder="Select X Axis"
                    errorMsg={errorMsg}
                    disabled={!config.dataSource}
                  >
                    { xaxisList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="yaxis"
                    label="Y Axis"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={config.yaxis}
                    placeholder="Select Y Axis"
                    errorMsg={errorMsg}
                    disabled={!config.xaxis}
                  >
                    { yaxisList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    name="visualisationType"
                    label="Visualisation Type"
                    onChange={(e)=>handleOnChange_select(e)}
                    value={config.visualisationType}
                    placeholder="Select Visualisation Type"
                    errorMsg={errorMsg}
                  >
                    { visualisationTypeList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </Grid>
                <Grid item xs={6} className={classes.formButton}>
                  <Button
                    className={classes.buttonSecondary}
                    onClick={()=>handleOnGenerate()}
                  >
                    Generate
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <IconButton
              className={classes.formIconButton}
              onClick={()=>setOpen(!open)}
            >
              {!open ? <KeyboardArrowRightIcon className={classes.icon_24} /> : <KeyboardArrowLeftIcon className={classes.icon_24} />}
            </IconButton>
          </Drawer>
        </div>
      </Card>
    </React.Fragment>
  );
}
