import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Modal from 'shared-components/Modal/Modal';
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam, filterActionTableHead } from "common/functions";
import { setValues } from "../store/unassigned";
import { unassignedOrdersTableHead } from "enums/UserPortal/TableHeaders";
import { GetUnassignedList, GetPurchaseOrderLookUp, GetSubSaleOrderLookUp, GetUnAssignedStatusLookUp } from "services/UserPortal/PlanningService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/planningStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function UnassignedOrders() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.planning.unassigned.filter);
  const subSaleOrders = useSelector(store => store.user.planning.unassigned.subSaleOrders);
  const purchaseOrders = useSelector(store => store.user.planning.unassigned.purchaseOrders);
  const status = useSelector(store => store.user.planning.unassigned.status);
  const totalCount = useSelector(store => store.user.planning.unassigned.totalCount);
  const result = useSelector(store => store.user.planning.unassigned.unassigned);
  const [newFilter, setNewFilter] = React.useState({subSaleOrderNumber: null, purchaseOrderNumber: null, status: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [statusMsg, setStatusMsg] = React.useState(null);
  const [openStatusModal, setOpenStatusModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleModal_status = (msg) => {
    setOpenStatusModal(!openStatusModal);
    setStatusMsg(msg);
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_edit = (soId) => {
    history.push("/user/sales-order/management/"+soId)
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };
  
  const getUnassignedList = (stopLoading) => {
    dispatch(GetUnassignedList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getUnassignedList();
    }
  },[filter]);

  React.useEffect(() => {
    dispatch(GetPurchaseOrderLookUp());
    dispatch(GetSubSaleOrderLookUp());
    dispatch(GetUnAssignedStatusLookUp());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getUnassignedList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title="Unassigned Orders"
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => (
              <Tag
                key={key}
                variant="outlined"
                tabIndex={-1}
                label={filter[key]}
                className={classes.tag}
                onDelete={() => handleButtonClick_delete(key)}
              />
            ))}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the unassigned order list
                  <Select
                    className={classes.filterDropdown}
                    name="purchaseOrderNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a purchase order"
                    value={renderValue(newFilter.purchaseOrderNumber)}
                  >
                    {purchaseOrders && purchaseOrders.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="subSaleOrderNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a sub sales order"
                    value={renderValue(newFilter.subSaleOrderNumber)}
                  >
                    {subSaleOrders && subSaleOrders.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a status"
                    value={renderValue(newFilter.status)}
                  >
                    {status && status.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={filterActionTableHead(unassignedOrdersTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.purchaseOrderNumber}</TableCell>
                  <TableCell>{item.subSaleOrderNumber}</TableCell>
                  <TableCell>{moment(item.deliveryDate).isAfter(minDate) ? moment(item.deliveryDate).format("DD/MM/yyyy") : ""}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.productId}</TableCell>
                  <TableCell>{item.productComponent}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.customerOrderWeight))}{item.uom}</TableCell>
                  <TableCell>
                    {item.status 
                    ? <img className={clsx(classes.icon_24, classes.link)} src={alert} alt="alert" onClick={()=>handleModal_status(item.status)} /> 
                    : ""}
                  </TableCell>
                  <TableCell><span className={classes.link} onClick={()=>handleButtonClick_edit(item.soId)}>Edit</span></TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openStatusModal}
        onClose={() => handleModal_status(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Status Alert"
        content={
          <React.Fragment>
            Order is recommended to be cancelled or postponed due to:<br /> <br />
            <span className="font-medium">{statusMsg}</span>
          </React.Fragment>
        }
        actions={
          <Button className={classes.button} onClick={() => handleModal_status(null)}>Okay</Button>
        }
      />
    </React.Fragment>
  );
}
