import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import { GetAllFarm, GetFarm, GetAllBank, SearchFarm } from "services/AdminPortal/FarmService"

// initial state
const initialState = {
  totalCount: 0,
  farms: [],
  farm: {},
  totalBankCount: 0,
  banks: [{}],
  initialMarker: {
    longitude: "103.78930650819217",
    latitude: "1.3757771917613002"
  },
  searchResultsCount: {},
  searchResults: {}
};

// create reducer and action creators
const farm = createSlice({
  name: "farm",
  initialState,
  reducers: {
    setFarmValues: (state, action) => {
      state.farm = action.payload;
    },
    updateFarmRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.farm[key] = action.payload[key];
      })
    },
    updateBanksRequest: (state, action) => {
      Object.keys(action.payload.value).forEach((key) => {
        state.banks[action.payload.index][key] = action.payload.value[key];
      })
    },
    addRequest: (state) => {
      state.banks = [...state.banks, {}];
    },
    resetBanks: (state) => {
      state.banks = [{}];
    },
    resetFarm: (state) => {
      state.farm = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = {};
      state.searchResults = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllFarm.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.farms = action.payload.result.items;
    },
    [GetFarm.fulfilled]: (state, action) => {
      state.banks = _.orderBy(action.payload.result.bankList, ["bankName"], ["asc"]);
      state.farm = _.omit(action.payload.result, ["bankList"]);
      state.initialMarker = _.pick(action.payload.result, ["longitude", "latitude"]);
    },
    [GetAllBank.fulfilled]: (state, action) => {
      if (action.payload.result) {
        state.totalBankCount = action.payload.result.totalCount;
        state.banks = action.payload.result.items;
      } else {
        state.banks = [];
      }
    },
    [SearchFarm.fulfilled]: (state, action) => {
      Object.keys(action.payload.result).forEach((key) => {
        state.searchResultsCount[key] = action.payload.result[key] ? action.payload.result[key].totalCount : 0;
        state.searchResults[key] = action.payload.result[key] ? action.payload.result[key].items : [];
      })
    },
  },
});

// export actions
export const { 
  setFarmValues, updateFarmRequest, resetFarm, 
  updateBanksRequest, addRequest, resetBanks, 
  resetSearch, reset } = farm.actions;

// export the reducer
export default farm.reducer;