import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";

import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { filterParam, filterUrl } from 'common/functions';
import { softzoneTableHead } from "enums/UserPortal/TableHeaders";
import { reset, setValues } from "../store/softzone";
import { GetAllASRSSoftzone } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/asrsStyle.js";

const useStyles = makeStyles(styles);

export default function Softzone() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.asrs.softzone.filter);
  const totalCount = useSelector(store => store.user.asrs.softzone.totalCount);
  const result = useSelector(store => store.user.asrs.softzone.result);
  const [newFilter, ] = React.useState({page: 0});

  const count = Math.ceil(totalCount / 10);

  const getAllSoftzone = (stopLoading) => {
    dispatch(GetAllASRSSoftzone({...filter, stopLoading}));
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllSoftzone();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    dispatch(setSearchText(""));
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllSoftzone(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="VIFS - Softzones List"
        subheader="List of Softzones is as shown below. Editing and Deleting of softzones will need to be done on Daifuku’s end."
      >
        <div className={classes.table}>
          <Table
            header={softzoneTableHead}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.softzoneID}</TableCell>
                  <TableCell>{item.softzoneName}</TableCell>
                  <TableCell>{item.fromBank}</TableCell>
                  <TableCell>{item.toBank}</TableCell>
                  <TableCell>{item.fromBay}</TableCell>
                  <TableCell>{item.toBay}</TableCell>
                  <TableCell>{item.fromLevel}</TableCell>
                  <TableCell>{item.toLevel}</TableCell>
                  <TableCell>{item.totalLocations}</TableCell>
                  <TableCell align="center">
                    { <Checkbox
                        id="supportUV"
                        checked={item.supportUV}
                        disabled={true}
                      />
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
