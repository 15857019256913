import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper"
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Autocomplete from "shared-components/Select/Autocomplete";

import { updateRequest } from "./store/vegetable";
import { renderValue, roundTo2Decimal } from "common/functions";
import { GetProductInfoById, GetGerminationDetailByWorkOrderIdAndProductId, GetBatchIDList, GetTrayPosition, GetQCDetailsFromOperation, CalculateDatesByWorkOrderIdAndProductId, GetSeedSupplierNameByBatchID,
  CalculateNurseryDuration, CalculateGrowthDuration, CalculateAverageNetWeightPerPlant, CalculateNutrientsVolPerPlant, CalculateActualNutrientsVolPerPlant, CalculateActualNutrientsVolPerTray,
  GeneratePlantMatrixFormat } from "services/UserPortal/QualityControlService";
import { GetProductList } from "services/UserPortal/CommonLookupService";

import HealthCondition from "./HealthCondition";
import styles from "assets/jss/components/UserPortal/qualityControlStyle.js";

const useStyles = makeStyles(styles);

export default function OldDetailForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const totalHorizontal = useSelector(store => store.user.rnd.vegetable.totalHorizontal);
  const totalVertical = useSelector(store => store.user.rnd.vegetable.totalVertical);
  const batchIDList = useSelector(store => store.user.rnd.vegetable.batchIDList);
  const boardTypeList = useSelector(store => store.user.rnd.vegetable.boardTypeList);
  const nutrientCatList = useSelector(store => store.user.rnd.vegetable.nutrientCatList);
  const trayPositionList = useSelector(store => store.user.rnd.vegetable.trayPositionList)
  const qualityControl = useSelector(store => store.user.rnd.vegetable.qualityControl);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "quality-control");

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "workOrderId") {
      setErrorMsg(false);
      dispatch(updateRequest({productId: null, vegetableVariety: null}));
    }
    if (e.target.name === "boardType") {
      dispatch(GeneratePlantMatrixFormat({boardType: e.target.value}));
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateRequest({trayPosition: value}));
    dispatch(GetQCDetailsFromOperation({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, trayPositionId: value, isGerminationReport: false}));
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_matrix = (e, y, x) => {
    let tempMatrix = qualityControl.plantMatrices ? _.cloneDeep(qualityControl.plantMatrices) : [];
    const foundIndex = tempMatrix.findIndex((item) => item.horizontal == x && item.vertical==y)
    if (foundIndex != -1) {
      tempMatrix[foundIndex].value = e.target.value;
    } else {
      tempMatrix.push({
        value: e.target.value,
        horizontal: x,
        vertical: y,
        qualityControlEntryId: qualityControl.id
      })
    }
    dispatch(updateRequest({plantMatrices: tempMatrix}));
  };

  const renderColumn = (vertical) => {
    let component = [];
    for (let i = 1; i <= totalHorizontal; i++) {
      const matrixValue = qualityControl.plantMatrices && qualityControl.plantMatrices.find((item) => item.horizontal == i && item.vertical == vertical);
      component.push(
        <TextField 
          key={vertical+":"+i}
          className={clsx(i !== totalHorizontal && classes.textFieldMargin, classes.textFieldSmall3, (matrixValue && matrixValue.isDisabled) && classes.coloredTextField)}
          id="plantMatrices"
          variant="outlined" 
          type="Number"
          onChange={(e) => handleOnChange_matrix(e, vertical, i)}
          placeholder=""
          value={matrixValue && renderValue(matrixValue.value)}
        />
      )
    }
    return component;
  }

  const renderRow = () => {
    let component = [];
    for (let i = totalVertical; i >= 1; i--) {
      component.push(
        <div className="flex" key={i}>
          {renderColumn(i)}
        </div>
      )
    }
    return component;
  }

  const handleOnClick_next = () => {
    if (validateFields()) {
      props.setStep(1);
    }
  }

  const validateFields = () => {
    if (!qualityControl.productId) {
      setErrorMsg({field: "productId", msg: "Please select a product"});
      return false;
    }
    for (let i = 0; i < qualityControl.healthConditions; i++) {
      if (qualityControl.healthConditions[i].netWeight < 0) {
        setErrorMsg({field: "netWeight", msg: "Net weight cannot be negative"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }
  
  React.useEffect(() => {
    if (qualityControl.workOrderId) {
      dispatch(GetProductList(qualityControl.workOrderId));
    }
  },[qualityControl.workOrderId]);
  
  React.useEffect(() => {
    if (qualityControl.productId) {
      dispatch(GetProductInfoById(qualityControl.productId));
      dispatch(GetBatchIDList({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}));
      dispatch(GetTrayPosition({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId, isGerminationReport: false}));
      if (path[index+2] === "create") {
        dispatch(GetGerminationDetailByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then((response) => {
          if (response.payload && response.payload.result) {
            if (!response.payload.result.germinationSerialNo) {
              setErrorMsg({field: "productId", msg: "Germination report not created"});
            } else {
              setErrorMsg(false);
            }
          }
        });
        dispatch(CalculateDatesByWorkOrderIdAndProductId({workOrderId: qualityControl.workOrderId, productId: qualityControl.productId}))
        .then(({payload}) => {
          if (payload.result) {
            dispatch(CalculateNurseryDuration({transplantDate: moment(payload.result.transplantDate).format("DD MMMM YYYY"), germinationDate: moment(payload.result.germinateDate).format("DD MMMM YYYY")}));
            dispatch(CalculateGrowthDuration({transplantDate: moment(payload.result.transplantDate).format("YYYY-MM-DD"), harvestDate: moment(payload.result.harvestDate).format("YYYY-MM-DD")}));
          }
        });
      }
    }
  },[qualityControl.productId]);

  React.useEffect(() => {
    if (qualityControl.batchNo) {
      dispatch(GetSeedSupplierNameByBatchID({batchID: qualityControl.batchNo}));
    }
  },[qualityControl.batchNo]);
  
  React.useEffect(() => {
    if (qualityControl.totalGrossWeight && qualityControl.noOfPlantsHarvested && qualityControl.spongeWeightConstant) {
      const result = Number(qualityControl.totalGrossWeight) - (Number(qualityControl.noOfPlantsHarvested) * Number(qualityControl.spongeWeightConstant));
      dispatch(updateRequest({totalNetWeight: result}));
    } else {
      dispatch(updateRequest({totalNetWeight: 0}));
    }
  },[qualityControl.totalGrossWeight, qualityControl.noOfPlantsHarvested, qualityControl.spongeWeightConstant]);
  
  React.useEffect(() => {
    if (qualityControl.totalNetWeight && qualityControl.noOfPlantsHarvested) {
      dispatch(CalculateAverageNetWeightPerPlant({totalNetWeight: qualityControl.totalNetWeight, totalHarvestQty: qualityControl.noOfPlantsHarvested}));
    }
  },[qualityControl.totalNetWeight, qualityControl.noOfPlantsHarvested]);
  
  React.useEffect(() => {
    if (qualityControl.nutrientsVolPerTray && qualityControl.noOfPlantsTransplanted) {
      dispatch(CalculateNutrientsVolPerPlant({nutrientsVolPerTray: qualityControl.nutrientsVolPerTray, noOfPlantTransplanted: qualityControl.noOfPlantsTransplanted}));
    }
  },[qualityControl.actualNutrientsVolPerTray, qualityControl.noOfPlantsTransplanted]);

  React.useEffect(() => {
    if (qualityControl.nutrientsVolPerPlant && qualityControl.ratioA && qualityControl.ratioB && qualityControl.initialEC) {
      dispatch(CalculateActualNutrientsVolPerPlant({nutrientsVolPerPlant: qualityControl.nutrientsVolPerPlant, ratioA: qualityControl.ratioA, ratioB: qualityControl.ratioB, initialEC: qualityControl.initialEC}));
    }
  },[qualityControl.nutrientsVolPerPlant, qualityControl.ratioA, qualityControl.ratioB, qualityControl.initialEC]);

  React.useEffect(() => {
    if (qualityControl.nutrientsVolPerTray && qualityControl.ratioA && qualityControl.ratioB && qualityControl.initialEC) {
      dispatch(CalculateActualNutrientsVolPerTray({nutrientsVolPerTray: qualityControl.nutrientsVolPerTray, ratioA: qualityControl.ratioA, ratioB: qualityControl.ratioB, initialEC: qualityControl.initialEC}));
    }
  },[qualityControl.nutrientsVolPerTray, qualityControl.ratioA, qualityControl.ratioB, qualityControl.initialEC]);
  
  return (
    <React.Fragment>
      <Card 
        title = {"Edit Experiment Results"}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.push("/user/r&d")}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleOnClick_next()}
              disabled={!qualityControl.workOrderId}
            >
              Next
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Select Work Order to Work On</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Work Order:</Typography>
            <Select
              name="workOrderId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a work order"
              value={renderValue(qualityControl.workOrderId)}
              errorMsg={errorMsg}
              disabled={path[index+2] !== "create"}
            >
              {workOrderList && workOrderList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Vegetable Name:</Typography>
            {path[index+2] === "create"
            ? <Select
                name="productId"
                onChange={(e)=>handleOnChange_select(e)}
                placeholder="Select a vegetable name"
                value={renderValue(qualityControl.productId)}
                errorMsg={errorMsg}
                disabled={!qualityControl.workOrderId}
              >
                {productList && productList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.productName}</MenuItem>;
                })}
              </Select>
            : <TextField 
                id="productId"
                variant="outlined" 
                value={renderValue(qualityControl.productName)}
                disabled
              />
            }
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Germination Serial No.:</Typography>
            <TextField 
              id="germinationSerialNo"
              variant="outlined" 
              placeholder=""
              value={renderValue(qualityControl.germinationSerialNo)}
              errorMsg={errorMsg}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>General Information</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Serial No:</Typography>
            <TextField 
              id="vegetableSerialNo"
              variant="outlined" 
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.vegetableSerialNo )}
              disabled = { !qualityControl.workOrderId }
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Vegetable Variety:</Typography>
            <TextField 
              id="vegetableVariety"
              variant="outlined" 
              placeholder=""
              value={renderValue(qualityControl.vegetableVariety)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>No. of Plants Transplanted:</Typography>
            <TextField 
              id="noOfPlantsTransplanted"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.noOfPlantsTransplanted)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Batch No:</Typography>
            <Select
              name="batchNo"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a batch ID"
              value={renderValue(qualityControl.batchNo)}
              errorMsg={errorMsg}
              disabled={!qualityControl.productId}
            >
              {batchIDList && batchIDList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Tray Position:</Typography>
            <Autocomplete
              id="trayPosition"
              freeSolo
              inputProps={{ maxLength: 50 }}
              onInputChange={(e, newInputValue) => {
                handleOnChange_autocomplete(newInputValue);
              }}
              inputValue={renderValue(qualityControl.trayPosition)}
              options={_.compact(trayPositionList.map((item) => item.trayPosition))}
              placeholder="Select a tray position" 
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>No. of Plants Harvested:</Typography>
            <TextField 
              id="noOfPlantsHarvested"
              variant="outlined" 
              type="Number"
              onInput={(e)=>{ 
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
              }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.noOfPlantsHarvested)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Order No.:</Typography>
            <TextField 
              id="orderNo"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.orderNo)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Seed Supplier:</Typography>
            <TextField
              id="supplierName"
              variant="outlined"
              value={renderValue(qualityControl.supplierName)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Dates</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Germinate Date:</Typography>
            <TextField 
              name="germinateDate"
              variant="outlined" 
              value={renderValue(qualityControl.germinateDate && moment(qualityControl.germinateDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Harvest Date:</Typography>
            <TextField 
              name="harvestDate"
              variant="outlined" 
              value={renderValue(qualityControl.harvestDate && moment(qualityControl.harvestDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Nursery Duration:</Typography>
            <TextField 
              id="nurseryDuration"
              variant="outlined" 
              value={renderValue(qualityControl.nurseryDuration)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Transplant Date:</Typography>
            <TextField 
              name="transplantDate"
              variant="outlined" 
              value={renderValue(qualityControl.transplantDate && moment(qualityControl.transplantDate).format("DD/MM/YYYY"))}
              disabled
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Growth Duration:</Typography>
            <TextField 
              id="growthDuration"
              variant="outlined" 
              value={renderValue(qualityControl.growthDuration)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Weights</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Total Gross Weight (kg):</Typography>
            <TextField 
              id="totalGrossWeight"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.totalGrossWeight)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Total Net Weight (kg):</Typography>
            <TextField 
              id="totalNetWeight"
              variant="outlined" 
              type="Number"
              placeholder=""
              value={renderValue(qualityControl.totalNetWeight)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Avr Net Weight/Plant (g):</Typography>
            <TextField 
              id="avrNetWeightPerPlant"
              variant="outlined" 
              type = "number"
              inputProps={{ maxLength: 50 }}
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(parseFloat(qualityControl.avrNetWeightPerPlant).toFixed(0))}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Sponge Weight Constant (kg):</Typography>
            <TextField 
              id="spongeWeightConstant"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.spongeWeightConstant)}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <Typography className={classes.formTitle}>Plant Board</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.formTitle}>Lighting</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Board Type:</Typography>
            <Select
              name="boardType"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a board type"
              value={renderValue(qualityControl.boardType)}
            >
              {boardTypeList && boardTypeList.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>LED Type:</Typography>
            <TextField 
              id="ledType"
              variant = "outlined"
              onChange={(e) => handleOnChange_text(e)} 
              placeholder=""
              value={renderValue(qualityControl.ledType)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Light Intensity (µmol/m2/s):</Typography>
            <TextField 
              id="lightIntensity"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.lightIntensity)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Growing Media Type:</Typography>
            <TextField 
              id="growingMediaType"
              variant="outlined" 
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.growingMediaType)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Lighting Duration (hr):</Typography>
            <TextField 
              id="lightingDuration"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.lightingDuration)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.formTitle}>Nutrients Settings</Typography>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Nutrients Cat:</Typography>
            <Select
              name="nutrientsCategory"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a nutrient category" 
              value={renderValue(qualityControl.nutrientsCategory)}
            >
              {nutrientCatList && nutrientCatList.map((item, index) => {
                return <MenuItem key={index} value={String(item)}>{item}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Ratio A:</Typography>
            <TextField 
              id="ratioA"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.ratioA)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Ratio B:</Typography>
            <TextField 
              id="ratioB"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.ratioB)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Nutrients Vol Per Tray (liter):</Typography>
            <TextField 
              id="nutrientsVolPerTray"
              variant="outlined" 
              type="Number"
              placeholder=""
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(qualityControl.nutrientsVolPerTray)}
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Nutrients Vol Per Plant (ml):</Typography>
            <TextField 
              id="nutrientsVolPerPlant"
              variant="outlined"
              value={renderValue(qualityControl.nutrientsVolPerPlant)}
              disabled
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Actual Nutrients Vol Per Tray (liter):</Typography>
            <TextField 
              id="actualNutrientsVolPerTray"
              variant="outlined"
              value={renderValue(qualityControl.actualNutrientsVolPerTray)}
              disabled
            />
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Typography className={classes.formLabel}>Actual Nutrients Vol Per Plant (ml):</Typography>
            <TextField 
              id="actualNutrientsVolPerPlant"
              variant="outlined"
              value={renderValue(qualityControl.actualNutrientsVolPerPlant)}
              disabled
            />
          </Grid>
          <Grid item xs={7}>
            <Typography className={classes.formTitle}>Environment Conditions</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.formTitle}>Plant Parameters</Typography>
          </Grid>
          <Grid item xs={7} className={classes.grid}>
            <Grid container spacing={4}>
              <Grid item xs={7} className={classes.grid}>
                <Typography className={classes.formLabel}>Temp (°C):</Typography>
                <TextField 
                  id="temperature"
                  variant="outlined" 
                  type = "number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  inputProps={{ maxLength: 50 }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.temperature)}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>Water Level (cm):</Typography>
                <div>
                  <Typography className={classes.formLabel3}>Initial</Typography>
                  <TextField 
                    className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                    id="initialWaterLevel"
                    variant="outlined" 
                    type="Number"
                    onInput={(e)=>{ 
                      // decimal
                      const reg = /^\d*(\.\d{0,2})?$/
                      if (!reg.test(e.target.value)) {
                        e.target.value = roundTo2Decimal(e.target.value);
                      }
                    }}
                    placeholder=""
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(qualityControl.initialWaterLevel)}
                  />
                </div>
                <div>
                  <Typography className={classes.formLabel3}>Final</Typography>
                  <TextField 
                    className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                    id="finalWaterLevel"
                    variant="outlined" 
                    type="Number"
                    onInput={(e)=>{ 
                      // decimal
                      const reg = /^\d*(\.\d{0,2})?$/
                      if (!reg.test(e.target.value)) {
                        e.target.value = roundTo2Decimal(e.target.value);
                      }
                    }}
                    placeholder=""
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(qualityControl.finalWaterLevel)}
                  />
                </div>
              </Grid>
              <Grid item xs={7} className={classes.grid}>
                <Typography className={classes.formLabel}>Humidity (%):</Typography>
                <TextField 
                  id="humidity"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.humidity)}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>EC (mS/cm):</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="initialEC"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.initialEC)}
                />
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalEC"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalEC)}
                />
              </Grid>
              <Grid item xs={7} className={classes.grid}>
                <Typography className={classes.formLabel}>CO2 (ppm):</Typography>
                <TextField 
                  id="carbondioxide"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.carbondioxide)}
                />
              </Grid>
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>TDS (ppm):</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="initialTDS"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.initialTDS)}
                />
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalTDS"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalTDS)}
                />
              </Grid>
              <Grid item xs={7} className={classes.grid} />
              <Grid item xs={5} className={classes.grid}>
                <Typography className={classes.formLabel2}>pH Level:</Typography>
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="initialPHLevel"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.initialPHLevel)}
                />
                <TextField 
                  className={clsx(classes.textFieldMargin, classes.textFieldSmall)}
                  id="finalPHLevel"
                  variant="outlined" 
                  type="Number"
                  onInput={(e)=>{ 
                    // decimal
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.finalPHLevel)}
                />
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <Typography className={classes.formTitle}>Production Remarks</Typography>
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <TextField 
                  id="productionRemark"
                  variant="outlined" 
                  multiline
                  minRows={12}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.productionRemark)}
                />
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <Typography className={classes.formTitle}>R&D Remarks</Typography>
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <TextField 
                  id="remark"
                  variant="outlined" 
                  multiline
                  minRows={12}
                  placeholder=""
                  onChange={(e) => handleOnChange_text(e)}
                  value={renderValue(qualityControl.remark)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.grid}>
            <HealthCondition errorMsg={errorMsg} hcImages={props.hcImages} setHcImages={props.setHcImages} />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <Typography className={classes.formTitle}>Plant Matrix</Typography>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            {qualityControl.boardType
            ? <Paper className={classes.paper} elevation={0}>
                {renderRow()}
              </Paper>
            : <Typography className={classes.formLabel4}>Select a Board Type</Typography>
            }
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
