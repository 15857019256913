import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";
import Modal from "shared-components/Modal/Modal";

import { rowsPerTable } from "config";
import { reset } from "../store/search";
import { setSearchText } from "store/general";
import { qualityControlGerminationTableHead } from "enums/UserPortal/TableHeaders";
import { SearchGerminationReport, DeleteGerminationReport } from "services/UserPortal/QualityControlService";

import report from "assets/icons/orange/view-report.svg";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/qualityControlStyle.js";

const useStyles = makeStyles(styles);
import { useRouteCanWrite } from "hooks";

export default function SearchGerminationResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.qualityControl.search.germinationTotalCount);
  const results = useSelector(store => store.user.qualityControl.search.germinationResults);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteGerminationReport(id))
    .then(() =>{
      setPage(0);
      dispatch(SearchGerminationReport({keyword: params.keyword, page: 0}));
    })
  }

  React.useEffect(() => {
    dispatch(SearchGerminationReport({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Germination List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={qualityControlGerminationTableHead}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.workOrderNumber)}</TableCell> 
                            <TableCell>{highlightValue(item.productName)}</TableCell> 
                            <TableCell>{highlightValue(moment(item.transplantDate).format("DD/MM/YYYY"))}</TableCell> 
                            <TableCell>{highlightValue(moment(item.submittedDate).format("DD/MM/YYYY"))}</TableCell> 
                            <TableCell>{highlightValue(item.submittedBy)}</TableCell> 
                            <TableCell align="right">
                              <IconButton 
                                onClick={() => history.push("/user/quality-control/germination/report/"+item.id)}
                              >
                                <img className={classes.icon} src={report} alt="report" />
                              </IconButton>
                              {canWrite &&
                                <React.Fragment>
                                  <Link to={{pathname: "/user/quality-control/germination/"+item.id}}>
                                    <CustomIconButton type="edit" />
                                  </Link>
                                  <CustomIconButton
                                    type="delete" 
                                    onClick={() => handleModal(item.id)}
                                  />
                                </React.Fragment>
                              }
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
