import {
  icon_16,
  icon_64,
  cardRadius,
  border,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  cardTitle,
  table,
  subTable,
  icon,
  collapsedIcon,
  rotate,
  orange,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const operationsStyle = {
  icon_16,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  cardTitle,
  table,
  subTable,
  icon,
  collapsedIcon,
  rotate,
  orange,
  tag,
  link,

  // Overview
  cardActionDropdown: {
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  hrDivider: {
    paddingTop: "0.750vw",
  },
  image: {
    margin: "auto",
  },
  checkFont: {
    fontWeight: 500,
  },

  // Tasks
  mainTable: {
    '& thead > tr > th:last-child': {
      width: "24vw",
      minWidth: "24vw"
    },
  },
  paddingBottom: {
    paddingBottom: "1.563vw !important"
  },
  mixTable: {
    margin: "0 4.167vw",
    '& thead > tr > th:first-child': {
      width: "unset",
      minWidth: "unset"
    },
  },
  productCell: {
    display: "flex",
    alignItems: "center",
    "& :first-child": {
      marginRight: "1.042vw"
    },
  },
  harvestTitle: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    "&:not(:first-child)": {
      marginTop: "2.083vw"
    }
  },
  divider: {
    width: "91%",
  },
  taskSwitch: {
    display: "flex",
    marginRight: "1.042vw",
    alignItems: "center"
  },
  switchText: {
    whiteSpace: "nowrap",
    marginRight: "0.521vw",
    color: primaryColor[3]
  },
  tasksTitle: {
    fontWeight: 500,
    color: primaryColor[3]+"BF",
  },
  tasksFont: {
    fontWeight: 400
  },
  indicator: {
    width: "0.833vw",
    height: "0.833vw",
    marginLeft: "0.417vw",
    marginRight: "0.417vw",
    backgroundColor: secondaryColor[0],
    borderRadius: "50%"
  },
  indicatorText: {
    fontSize: "0.938vw",
    alignSelf: "end"
  },
  subIndicator: {
    marginLeft: 0,
    marginTop: "0.208vw",
  },
  green: {
    color: "#00891B"
  },
  accordion: {
    margin: "unset !important",
    "&:not(:first-child)": {
      marginTop: "1.563vw !important",
    },
  },
  subPaper: {
    display: "flex",
    width: "100%",
    backgroundColor: whiteColor,
    borderRadius: "0 !important",
    padding: "1.563vw",
    margin: "0 !important",
    "&:first-child": {
      borderRadius: "0.625vw 0.625vw 0 0 !important",
    },
    "&:last-child": {
      borderRadius: "0 0 0.625vw 0.625vw !important",
    },
    "&:not(:last-child)": {
      borderBottom: border,
    },
    "&:first-child:last-child": {
      borderRadius: "0.625vw !important",
    },
  },
  header: {
    color: primaryColor[3]+"BF",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subTitle: {
    color: "#13322B80",
    fontWeight: 400
  },
  accordionButton: {
    height: "2.083vw",
    fontSize: "0.833vw",
    "&:not(:first-child)": {
      marginLeft: "1.042vw"
    }
  },
  completedButton: {
    "&:disabled": {
      backgroundColor: secondaryColor[0],
      color: whiteColor,
      padding: "0.781vw 1.250vw",
      borderRadius: "0.313vw",
    }
  },
  checkbox: {
    padding: "0 !important"
  },
  checkout: {
    position: "relative"
  },
  checkoutDropdown: {
    width: "15.990vw",
    zIndex: 99
  },
  checkoutPaper: {
    paddingTop: "1.042vw",
    paddingBottom: "1.042vw",
  },
  checkoutItem : {
    "&:hover": {
      ...orange
    }
  },
  checkedoutButton: {
    backgroundColor: whiteColor,
    color: "#00891BB2",
    border: "0.104vw solid #00891BB2",
    "&:disabled": {
      backgroundColor: whiteColor,
      color: "#00891BB2",
      border: "0.104vw solid #00891BB2",
      padding: "0.677vw 1.146vw",
      borderRadius: "0.313vw",
    }
  },
  paper: {
    ...cardRadius,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    height: "4.479vw",
    padding: "0.521vw",
    "&:hover": {
      opacity: "0.8",
      cursor: "pointer"
    }
  },
  productText: {
    fontWeight: 500,
    fontSize: "0.833vw",
    marginLeft: "0.521vw"
  },
  productImage: {
    ...cardRadius,
    width: "3.438vw",
    height: "3.438vw",
  },
  taskAction: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "2.083vw"
  },
  timer: {
    marginLeft: "0.677vw",
    width: "5.208vw",
    "& div": {
      height: "2.083vw",
      backgroundColor: whiteColor + " !important",
    },
  },
  taskSubheader: {
    "& p": {
      fontSize: "0.833vw !important"
    }
  },
  detailTable: {
    '& thead > tr > th:first-child': {
      width: "6vw",
      minWidth: "6vw"
    },
    '& thead > tr > th:not(:first-child)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  modalPaper: {
    width: "32.125vw",
    height: "fit-content",
    maxHeight: "35.781vw"
  },
  editPaper: {
    width: "28.125vw",
    height: "fit-content",
  },
  modalTitle: {
    marginTop: 0,
    marginBottom: "2.083vw",
    fontSize: "1.667vw"
  },
  modalField: {
    textAlign: "left",
    marginTop: "1.042vw"
  },
  modalContent: {
    height: "fit-content",
    maxHeight: "16.667vw",
    overflow: "overlay"
  },
  weightField: {
    width: "13.542vw",
    minWidth: "13.542vw",
    marginRight: "1.042vw"
  },
  smallCell: {
    width: "6vw",
    minWidth: "6vw"
  },
  bigCell: {
    width: "10vw",
    minWidth: "10vw"
  },
  contentCell: {
    width: "fit-content",
    minWidth: "fit-content",
    whiteSpace: "nowrap"
  },
  durationCell: {
    display: "flex",
    alignItems: "baseline"
  },
  textFieldCell: {
    width: "14.583vw",
    minWidth: "14.583vw"
  },
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
    textAlign: "right"
  },
  assignmentTime: {
    width: "7.292vw !important"
  },
  popper: {
    width: "16.667vw"
  },
  editModal: {
    textAlign: "left",
    alignItems: "center",
  },
  editContent: {
    overflow: "overlay",
    height: "15.625vw"
  },
  assignedPerson: {
    display: "flex",
    width: "50%",
  },
  assignedPersonHeader: {
    width: "20vw",
    margin: "auto",
    fontWeight: "400"
  },
  assignedPersonSelect: {
    marginRight: "2vw"
  },
  // Settings
  settingsPaper: {
    ...cardRadius,
    border,
    width: "41.667vw",
    margin: "auto",
    marginBottom: "1.302vw",
    padding: "1.823vw 7.292vw",
    color: primaryColor[3],
  },
  settingsLabel: {
    color: primaryColor[4],
    alignSelf: "center"
  },
  settingsButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.083vw"
  },
}

export default operationsStyle;