// Create Edit Raw Material
// Stepper
import React from "react";
// core components
import Stepper from "shared-components/Stepper/Stepper";

export default function RawMaterialStepper(props) {

  const rawMaterialSteps = ['Key in details for Raw Material Creation', 'Key in Quantity, Safety Level and UOM']; // , 'Key in Starting Batch for Raw Material'

  return (
    <Stepper 
      steps={rawMaterialSteps}
      activeStep={props.activeStep}
    />
  )
}
