import {
    cardRadius,
    icon_24,
    icon_64,
    button,
    buttonSecondary,
    blackColor,
    grayColor,
    secondaryColor,
    border,
  } from "assets/jss/generaliseStyle.js";

  import {
    table,
    icon,
    result,
    collapsedIcon,
    rotate,
    highlight,
    orange,
    loading
  } from "assets/jss/components/commonStyle.js";
  
  const rawMaterialStyle = {
    cardRadius,
    button,
    buttonSecondary,
    icon_64,
    icon,
    table,
    loading,

    // Search RawMaterial
    result,
    highlight,
		collapsedIcon,
    rotate,
    orange,

    iconButton: {
      height: "fit-content",
      alignSelf: "center",
      marginRight: "-0.625vw",
      marginLeft: "1.771vw"
    },

    buttonSpacing: {
      marginLeft: "1.042vw"
    },

    alignTextfield: {
      marginLeft: "-0.200vw"
    },

    detail: {
      color: secondaryColor[0],
      cursor: "pointer"
    },

    topCard: {
			marginBottom: "2.604vw"
		},

    subtitle: {
      color: grayColor[0],
      fontSize: "0.833vw",
      lineHeight: "0.977vw",
      marginTop: "0.521vw",
      display: "flex"
    },

    boldSubtitle: {
      color: blackColor,
      fontSize: "0.833vw",
      lineHeight: "0.977vw",
      marginLeft: "5px",
    },


    content: {
      display: "flex",
      width: "100%",
    },

    topContent: {
      margin: "auto",
      display: "flex",
    },


    columnSpacing: {
      //marginLeft: "9.375vw",
      marginRight: "2.083vw",
    },

    topLeftPaper: {
      ...cardRadius,
      border,
      padding: "2.083vw 1.979vw",
      '&:not(:first-child)': {
        marginTop: "1.563vw",
      },
      width: "38.125vw",
    },

    bottomContent: {
      ...cardRadius,
      border,
      padding: "2.083vw 1.979vw",
      '&:not(:first-child)': {
        marginTop: "1.563vw",
      },
      width: "57.79vw",
      margin: "auto",
      display: "flex",
    },

    leftField: {
      width: "100%",
      marginTop: "1.563vw"
    },

    rightField: {
      marginTop: "3.125vw",
      alignSelf: "center"
    },

    rawMaterialType: {
      ...cardRadius,
      border,
      padding: "1.083vw 0vw",
      width: "18.438vw"
    },

    rawMaterialTypeTitle: {
      fontSize: "0.938vw",
      fontWeight: "500",
      lineHeight: "1.094vw",
      marginLeft: "2.083vw",
      marginTop: "0.781vw",
      marginRight: "-0.625vw",
      width: "10.417vw"
    },

    goBackButton: {
      marginTop: "1.563vw",
    },

    safetyLevelAndUOMTable: {
      ...cardRadius,
      border,
      margin: "auto",
      padding: "1.302vw 1.979vw",
    },

    addMoreProcess: {
      marginTop: "0.781vw"
    },

    newPaper: {
      ...cardRadius,
      border,
      display: "flex",
      flexDirection: "column",
      margin: "1.563vw 15.104vw 5.208vw",
      padding: "3.646vw 7.292vw "
    },

    newLabel: {
      alignSelf: "center",
    },

    semicolonAlign: {
      paddingLeft: "0.433vw",
    },

    empty: {
      height: "-webkit-fill-available",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "1.094vw",
      marginTop: "16.927vw",
      fontWeight: 500,
      color: "rgba(19, 50, 43, 0.25)",
    },

    helperIcon: {
      ...icon_24,
    },

    iconButton_helper: {
      margin: "-0.938vw 0 -0.938vw 0",
      padding: 0
    },

    UOMHeader: {
      fontWeight: 500
    },

    lossRate: {
      marginTop: "-0.521vw"
    },

    lossRateSubheader: {
      color: "rgba(19, 50, 43, 0.5)",
      fontSize: "0.729vw",
    },

    error: {
      color: "#FF0000",
      fontSize: "0.729vw",
      textAlign: "center"
    },

    buttonMargin: {
      marginRight: "1.042vw"
    },

    checkboxPadding: {
      marginLeft: "-0.625vw",
    }
  }
  
  export default rawMaterialStyle;