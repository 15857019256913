import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { formatNumbers, roundTo2Decimal } from "common/functions";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/homeStyle.js";

const useStyles = makeStyles(styles);

export default function RawMaterial() {
  const classes = useStyles();
  let history = useHistory();
  const results = useSelector(store => store.user.home.rawMaterialWidget);
  const [page, setPage] = React.useState(0);

  return (
    <React.Fragment>
      {results.length
      ? <React.Fragment>
          <Typography className={classes.widgetTitle}>5 Latest Raw Material Requests</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.tableTitle}>Raw Material Name</Typography>
                <Typography className={classes.paperContent}>{results[page] && results[page].rawMaterialName}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.tableTitle}>Status</Typography>
                <Typography className={classes.paperContent}>{results[page] && results[page].status}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.tableTitle}>WO Number</Typography>
                <Typography className={classes.paperContent}>{results[page] && results[page].workOrderNumber}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.tableTitle}>Qty Requested</Typography>
                <Typography className={classes.paperContent}>{formatNumbers(roundTo2Decimal(results[page] && results[page].qtyRequested))}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.tableTitle}>Est. Date Delivered</Typography>
                <Typography className={classes.paperContent}>{moment(results[page] && results[page].estDateDelivered).format("DD/MM/YYYY")}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      : <Typography className={classes.tableTitle}>Nothing to show</Typography>
      }
      <div className={classes.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(classes.buttonSecondary, classes.viewAllButton)} onClick={()=>history.push("/user/raw-material/management")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
