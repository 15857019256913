import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles";
// core components
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { graphColors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/sustainabilityStyle.js";

const useStyles = makeStyles(styles);

export default function ScopeChart(props) {
  const classes = useStyles();
  const results = useSelector(store => store.user.sustainability.chart.conversion);

  const handleOnChange_date = (value) => {
    if (value) {
      props.setDate({startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD")});
    }
  };

  const config = () => {
    return ({
      colors: graphColors,
      chart: {
        type: 'bar',
        zoomType: 'x',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'tonCO2e',
        }
      },
      series: [
        {
          name: "Scopes",
          data: [
            {
              name: "Scope 1",
              y: Number(results.scope1),
              color: graphColors[0]
            },
            {
              name: "Scope 2",
              y: Number(results.scope2),
              color: graphColors[1]
            },
            {
              name: "Scope 3",
              y: Number(results.scope3),
              color: graphColors[2]
            }
          ]
        }
      ],
    })
  };

  return (
    <React.Fragment>
      <div className={classes.action}>
        <DateRangePicker
          clearable
          className={classes.datepicker}
          placeholder="Date"
          value={{startDate: props.date.startDate, endDate: props.date.endDate}}
          onChange={(e) => handleOnChange_date(e)}
          onClear={()=>props.setDate({startDate: null, endDate: null})}
        />
      </div>
      <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.highChart }} />
    </React.Fragment>
  );
}
