import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Checkbox from "shared-components/Checkbox/Checkbox";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Modal from "shared-components/Modal/Modal";

import { GetReceiptDetail, GetWarehouseRackByWarehouseId, UpdateRawMaterialRequestReject, RawMaterialReceiptReceive } from "services/UserPortal/RawMaterialService";

import { minDate } from "config";
import { setDirty } from "store/general";
import { isValidPrice, isPositiveDecimal } from "common/validations";
import { formatNumbers, roundTo2Decimal, renderValue } from 'common/functions';
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { updateRequest, updateReceiptDate, setValues } from "../store/receipt";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";
import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function ViewEditReceipt(props) {
  const classes = useStyles();
  let history = useHistory();
  const canWrite = useRouteCanWrite();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const receipt = useSelector(store => store.user.rawMaterial.receiptRM.receipt);
  const allRackNumber = useSelector(store => store.user.rawMaterial.receiptRM.allRackNumber);
  const supplierList = useSelector(store => store.common.supplierList);
  const warehouseList = useSelector(store => store.common.warehouseList);
  const [page, setPage] = React.useState(0); // for rack list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for rack list
  const [searchValue, setSearchValue] = React.useState(""); // for rack list
  const [isView, setIsView] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const selectedReceipt = props.location.state ? props.location.state.selectedReceipt : "";

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
    if (e.target.name === "warehouseId") {
      dispatch(updateRequest({["rackNumbers"] : []}));
      setPage(0);
      const param = {
        warehouseId: e.target.value,
        page: 0
      }
      dispatch(GetWarehouseRackByWarehouseId(param))
      .then((response) => {
        if (response.payload.result) {
          setPage(page+1);
          dispatch(setValues({allRackNumber: response.payload.result.items}))
        }
      });
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateRequest({rackNumbers : value}));
  };

  const fetchMoreData = (search, pageNo) => {
    if (page !== -1 || pageNo !== undefined || receipt.rawMaterialBatch.warehouseId) {
      setIsLoadingMore(true);
      const param = {
        warehouseId: receipt.rawMaterialBatch.warehouseId,
        page: pageNo !== undefined ? pageNo : page,
        rackID: search ? search : ""
      }
      dispatch(GetWarehouseRackByWarehouseId(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({allRackNumber: response.payload.result.items}));
            } else {
              dispatch(setValues({allRackNumber: _.unionBy(allRackNumber, response.payload.result.items, "rackID")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      dispatch(updateRequest({[field] : moment(value).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateRequest({[field] : value}));
    }
  };

  const handleOnChange_receiptDate = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      dispatch(updateReceiptDate({[field] : moment(value).format()}));
    } else {
      dispatch(updateReceiptDate({[field] : value}));
    }
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(setDirty(false))
      let body = _.cloneDeep(receipt);
      if (body.rawMaterialBatch.rackNumbers) {
        body.rawMaterialBatch.rackNumbers = body.rawMaterialBatch.rackNumbers.map((item) => item.rackID)
      }
      dispatch(RawMaterialReceiptReceive(body))
      .then(({error})=>{
        if (error) {
          dispatch(setDirty(true));
        } else {
          history.push("/user/raw-material/receipt");
        }
      });
    }
  };

  const handleButtonClick_back = () => {
    history.push("/user/raw-material/receipt");
  };

  const handleButtonClick_cancel = () => {
    if (selectedReceipt && selectedReceipt.status === "Received") {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "receipt");
      dispatch(GetReceiptDetail(path[index+1]))
      .then(() =>{
        setIsView(true);
        setErrorMsg(false);
      })
    } else {
      history.push("/user/raw-material/receipt");
    }
  };

  const handleButtonClick_reject = () => {
    dispatch(UpdateRawMaterialRequestReject(receipt.id))
    .then(({error}) => {
      if (error) {
        setOpenModal(!openModal);
      } else {
        history.push("/user/raw-material/receipt");
      }
    })
  };

  const handleButtonClick_edit = () => {
    setIsView(false);
    dispatch(setDirty(true));
  }
  
  const handleModal = () => {
    setOpenModal(!openModal);
  }

  const validateFields = () => {
    if (receipt.receiptDate === null) {
      setErrorMsg({field: "receiptDate", msg: "Please input date"});
      return false;
    }
    if (!moment(receipt.receiptDate).isValid()) {
      setErrorMsg({field: "receiptDate", msg: "Invalid date"});
      return false;
    }
    if (!receipt.rawMaterialBatch.qtyAdd) {
      setErrorMsg({field: "qtyAdd", msg: "Please enter a quantity"});
      return false;
    }
    if (!isPositiveDecimal(receipt.rawMaterialBatch.qtyAdd)) {
      setErrorMsg({field: "qtyAdd", msg: "Please enter a valid quantity"});
      return false;
    }
    if (!receipt.rawMaterialBatch.totalCost) {
      setErrorMsg({field: "totalCost", msg: "Please enter total cost"});
      return false;
    }
    if (!isValidPrice(receipt.rawMaterialBatch.totalCost)) {
      setErrorMsg({field: "totalCost", msg: "Please enter a valid total cost"});
      return false;
    }
    if (receipt.rawMaterialBatch.expiryDate === null) {
      setErrorMsg({field: "expiryDate", msg: "Please enter an expiry date"});
      return false;
    }
    if (!moment(receipt.rawMaterialBatch.expiryDate).isValid()) {
      setErrorMsg({field: "expiryDate", msg: "Invalid date"});
      return false;
    }
    if (receipt.rawMaterialBatch.expiryDate < receipt.dateOfCreation) {
      setErrorMsg({field: "expiryDate", msg: "Expiry date should be later than created date"});
      return false;
    }
    if (receipt.rawMaterialBatch.isSeed && !receipt.rawMaterialBatch.lossRatePercent) {
      setErrorMsg({field: "lossRatePercent", msg: "Please enter loss rate"});
      return false;
    }
    if (receipt.rawMaterialBatch.isSeed && receipt.rawMaterialBatch.lossRatePercent > 100) {
      setErrorMsg({field: "lossRatePercent", msg: "Loss rate cannot be more than 100%"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  React.useEffect(() => {
    setPage(0);
    const param = {
      warehouseId: receipt.rawMaterialBatch && receipt.rawMaterialBatch.warehouseId,
      page: 0
    }
    dispatch(GetWarehouseRackByWarehouseId(param))
    .then((response) => {
      if (response.payload.result) {
        setPage(page+1);
        dispatch(setValues({allRackNumber: response.payload.result.items}))
      }
    });
  },[receipt.rawMaterialBatch && receipt.rawMaterialBatch.warehouseId]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "receipt");
    dispatch(GetReceiptDetail(path[index+1]));
    if (selectedReceipt && (selectedReceipt.status === "Received" || selectedReceipt.status === "Rejected")) {
      setIsView(true);
    }
    if (selectedReceipt && selectedReceipt.status === "Requested") {
      dispatch(setDirty(true));
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(setValues({receipt: {}}));
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {"Receipt of Raw Materials " + (selectedReceipt && selectedReceipt.status === "Rejected" ? "- Rejected" : "")}
        subheader={(selectedReceipt && selectedReceipt.status === "Rejected" ? "You are currently viewing the rejected receipt for " : "You are currently configuring for ") + selectedReceipt.rawMaterialName + " (" + selectedReceipt.rawMaterialRefNo + ")"}
      >
        <Paper className={classes.paper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={classes.label}>
              <Typography>RM ID :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="rawMaterialRefNo"
                variant="outlined" 
                value={renderValue(receipt.rawMaterialRefNo)}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>PO Number :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="poNumber"
                variant="outlined" 
                value={renderValue(receipt.poNumber)}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Qty Requested :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="qtyRequest"
                variant="outlined" 
                value={formatNumbers(roundTo2Decimal(receipt.qtyRequest))}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Batch Number :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="batchRefNo"
                variant="outlined" 
                value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.batchRefNo)}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Receipt Date/Time {!isView?<span className={classes.orange}>* </span>:""}:</Typography>
            </Grid>
            <Grid item xs={8}>
            { !isView && canWrite
            ? <DateTimePicker
                type="datetime"
                name="receiptDate"
                value={receipt.receiptDate}
                onChange={(e) => handleOnChange_receiptDate(e, "receiptDate")}
              />
            : <TextField 
                variant="outlined" 
                value={moment(receipt.receiptDate).isAfter(minDate) ? moment(receipt.receiptDate).format("DD/MM/YYYY, HHmm") : ""}
                disabled
              />
            }
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Quantity Added {!isView?<span className={classes.orange}>* </span>:""}:</Typography>
            </Grid>
            <Grid item xs={!isView && canWrite ? 5 : 8}>
              { !isView && canWrite
              ? <TextField 
                  id="qtyAdd"
                  type="Number"
                  variant="outlined" 
                  onInput={(e)=>{ 
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,10)
                  }}
                  placeholder="Enter quantity added"
                  onChange={(e) => handleOnChange_text(e)}
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.qtyAdd)}
                  errorMsg={errorMsg}
                />
              : <TextField 
                  variant="outlined" 
                  value={(receipt.rawMaterialBatch && formatNumbers(roundTo2Decimal(receipt.rawMaterialBatch.qtyAdd))) + " " + (receipt.rawMaterialBatch && receipt.rawMaterialBatch.quantityUOM)}
                  disabled
                />
              }
            </Grid>
            {(!isView && canWrite) &&
              <Grid item xs={3}>
                <TextField 
                  variant="outlined" 
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.quantityUOM)}
                  disabled
                />
              </Grid>
            }
            <Grid item xs={4} className={classes.label}>
              <Typography>Total Cost {!isView?<span className={classes.orange}>* </span>:""}:</Typography>
            </Grid>
            <Grid item xs={8}>
              { !isView && canWrite
              ? <TextField 
                  id="totalCost"
                  type="Number"
                  variant="outlined" 
                  onInput={(e)=>{
                    // decimal 
                    const reg = /^\d*(\.\d{0,2})?$/
                    if (!reg.test(e.target.value)) {
                      e.target.value = roundTo2Decimal(e.target.value);
                    }
                  }}
                  onChange={(e) => handleOnChange_text(e)}
                  placeholder="Enter total cost"
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.totalCost)}
                  errorMsg={errorMsg}
                />
              : <TextField 
                  variant="outlined" 
                  value={receipt.rawMaterialBatch && formatNumbers(roundTo2Decimal(receipt.rawMaterialBatch.totalCost))}
                  disabled
                /> 
              }
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Expiry Date {!isView?<span className={classes.orange}>* </span>:""}:</Typography>
            </Grid>
            <Grid item xs={8}>
              { !isView && canWrite
              ? <DateTimePicker
                  className={classes.datepicker}
                  name="expiryDate"
                  minDate={receipt.receiptDate ? receipt.receiptDate : moment().format("YYYY-MM-DD")}
                  value={receipt.rawMaterialBatch && receipt.rawMaterialBatch.expiryDate}
                  onChange={(e) => handleOnChange_date(e, "expiryDate")}
                />
              : <TextField 
                  variant="outlined" 
                  value={(receipt.rawMaterialBatch && moment(receipt.rawMaterialBatch.expiryDate).isAfter(minDate))?moment(receipt.rawMaterialBatch.expiryDate).format("DD/MM/YYYY"):""}
                  disabled
                />
              }
            </Grid>
            {(receipt.rawMaterialBatch && receipt.rawMaterialBatch.isSeed) &&
            <React.Fragment>
              <Grid item xs={4} className={classes.label}>
                <Typography>Loss Rate (%) {!isView?<span className={classes.orange}>* </span>:""}:</Typography>
              </Grid>
              <Grid item xs={8}>
                { !isView && canWrite
                ? <React.Fragment>
                    <TextField 
                      id="lossRatePercent"
                      type="Number"
                      variant="outlined" 
                      onInput={(e)=>{ 
                        e.target.value = e.target.value > 100 ? 100 : Math.max(0, parseInt(e.target.value)).toString().slice(0,3)
                      }}
                      placeholder="Enter loss rate"
                      onChange={(e) => handleOnChange_text(e)}
                      value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.lossRatePercent)}
                      errorMsg={errorMsg}
                      disabled={isView && !canWrite}
                    />
                  </React.Fragment>
                : <TextField 
                    variant="outlined" 
                    value={receipt.rawMaterialBatch && formatNumbers(roundTo2Decimal(receipt.rawMaterialBatch.lossRatePercent))}
                    disabled
                  />
                }
              </Grid>
            </React.Fragment>
            }
            <Grid item xs={4} className={classes.label}>
              <Typography>Location :</Typography>
            </Grid>
            <Grid item xs={8}>
              { !isView && canWrite
              ? <Select
                  name="warehouseId"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select a location"
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.warehouseId)}
                  errorMsg={errorMsg}
                >
                  {warehouseList && warehouseList.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                  })} 
                </Select>
              : <TextField 
                  variant="outlined" 
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.warehouseName)}
                  disabled
                />
              }
            </Grid>
            <Grid item xs={4} className={classes.label2}>
              <Typography>Rack Number :</Typography>
            </Grid>
            <Grid item xs={8}>
              { !isView && canWrite
              ? <Autocomplete
                  dataCount={page*10}
                  options={allRackNumber}
                  fetchData={()=>fetchMoreData()}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox checked={receipt.rawMaterialBatch && _.findIndex(receipt.rawMaterialBatch.rackNumbers, ({rackID}) => rackID === option.rackID) > -1} />
                      {option.rackID}
                    </React.Fragment>
                  )}
                  isLoadingMore={isLoadingMore}
                  placeholder="Eg. R01-01"
                  onInputChange={(_, newInputValue) => {
                    setPage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(newInputValue, 0);
                  }}
                  onChange={(_, newValue) => {
                    handleOnChange_autocomplete(newValue);
                  }}
                  inputValue={searchValue}
                  value={receipt.rawMaterialBatch && receipt.rawMaterialBatch.rackNumbers ? receipt.rawMaterialBatch.rackNumbers : []}
                  renderValue={"rackID"}
                  multiple
                  disabled={!(receipt.rawMaterialBatch && receipt.rawMaterialBatch.warehouseId)}
                />
              : <TextField 
                  variant="outlined" 
                  value={receipt.rawMaterialBatch && receipt.rawMaterialBatch.rackNumbers ? receipt.rawMaterialBatch.rackNumbers.map(({rackID})=> {return rackID}).join(", ") : null}
                  disabled
                />
              }
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>Supplier Name :</Typography>
            </Grid>
            <Grid item xs={8}>
              { !isView && canWrite
              ? <Select
                  name="supplierId"
                  onChange={(e)=>handleOnChange_select(e)}
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.supplierId)}
                  placeholder="Select a supplier"
                  errorMsg={errorMsg}
                >
                  {supplierList && supplierList.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>{item.supplierName} ({item.supplierId})</MenuItem>;
                  })} 
                </Select>
              : <TextField 
                  variant="outlined" 
                  value={receipt.rawMaterialBatch && renderValue(receipt.rawMaterialBatch.supplierName)}
                  disabled
                />
              }
            </Grid>
          </Grid>
          <div className={classes.action}>
            { !isView && canWrite
            ? 
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>handleButtonClick_cancel()}
              >
                Cancel
              </Button>
              <Button
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
                disabled={!isDirty}
              >
                Save
              </Button>
            </React.Fragment>
            : 
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>handleButtonClick_back()}
              >
                Back
              </Button>
              <div>
                <Button
                  className={classes.buttonSecondary}
                  onClick={()=>handleModal()}
                  disabled={selectedReceipt.status === "Rejected"}
                >
                  Reject
                </Button>
                <Button
                  className={classes.button}
                  onClick={()=>handleButtonClick_edit()}
                  disabled={selectedReceipt.status === "Rejected"}
                >
                  Edit
                </Button>
              </div>
            </React.Fragment>
            }
          </div>
        </Paper>
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to reject this receipt? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_reject()}>Reject</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
