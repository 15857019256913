import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import DateTabs from "shared-components/Tabs/DateTabs";
import CustomTabs from "shared-components/Tabs/Tabs";

import { reset } from ".";

import styles from "assets/jss/components/AdminPortal/reportStyle.js";
import { ExportReport, GetActivationRatePercentReport, GetActiveRatePercentReport, GetActiveUsersReport, GetModuleStatistics, ExportModuleStatistics } from "../../../services/AdminPortal/ReportService";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');

export default function Report() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeUsers = useSelector(store => store.admin.report.activeUsers);
  const activeRatePercent = useSelector(store => store.admin.report.activeRatePercent);
  const activationRatePercent = useSelector(store => store.admin.report.activationRatePercent);
  const moduleStatistics = useSelector(store => store.admin.report.moduleStatistics);
  const [tab, setTab] = React.useState(0);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }

  const handleButtonClick_export = () => {
    if (tab === 0) {
      dispatch(ExportReport({startDate: date.startDate, endDate: date.endDate}))
      .then((response) => {
        FileDownload(response.payload, 'Overall_Statistics.xlsx');
      });
    } else {
      dispatch(ExportModuleStatistics({startDate: date.startDate, endDate: date.endDate}))
      .then((response) => {
        FileDownload(response.payload, 'Module_Statistics.xlsx');
      });
    }
  };

  const config = (title, colour) => {
    let array = [];
    let newTitle = "";
    if (title === "Active Users") {
      array = activeUsers.map((item) => {
        return Number(item.value)
      })
    } else if (title === "Active Rate (%)") {
      array = activeRatePercent.map((item) => {
        return Number(item.value)
      })
    } else if (title === "Activation Rate (%)") {
      array = activationRatePercent.map((item) => {
        return Number(item.value)
      })
    } else {
      newTitle = (title.charAt(0).toUpperCase() + title.slice(1)).match(/[A-Z][a-z]+|[0-9]+/g).join(" ").replace('Module', '')
      array = moduleStatistics[title].map((item) => {
        return Number(item.value)
      })
    }
    return ({
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: "Period of Time"
        },
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: newTitle ? newTitle : title
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          data: array,
          label: {
            connectorAllowed: false
          },
          pointStart: new Date(date.startDate).getTime(),
          pointInterval: 24 * 3600 * 1000, // one day
          color: colour
        }
      },
      series: [{
        data: array,
        name: newTitle ? newTitle : title
      }],
    })
  };

  React.useEffect(() => {
    if (moment(date.startDate).isValid() && moment(date.endDate).isValid()) {
      if (tab === 0) {
        dispatch(GetActiveUsersReport({startDate: date.startDate, endDate: date.endDate}));
        dispatch(GetActivationRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
        dispatch(GetActiveRatePercentReport({startDate: date.startDate, endDate: date.endDate}));
      } else {
        dispatch(GetModuleStatistics({startDate: date.startDate, endDate: date.endDate}));
      }
    }
  },[date, tab]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      title = "Users Statistics"
      classes={{
        header: classes.cardHeader
      }}
      action={
        <React.Fragment>
          <DateTabs setDate={setDate} date={date} />
          <Button 
            className={classes.buttonSecondary}
            onClick={() => handleButtonClick_export()}
          >
            Export
          </Button>
        </React.Fragment>
      }
      subheader={
        <CustomTabs 
          tabs={["Overall Statistics", "Module Statistics"]}
          value={tab} 
          onChange={handleOnChange_tab}
        />
      }
    >
      { tab === 0
      ? 
      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:classes.cardBorder}}
              title = "Active Users"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Active Users", "#FF8F3D")} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:classes.cardBorder}}
              title = "Active Rate"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Active Rate (%)", "#86D295")} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card
              variant="outlined"
              classes={{root:classes.cardBorder}}
              title = "Activation Rate"
            >
              <HighchartsReact highcharts={Highcharts} options={config("Activation Rate (%)", "#FF8F3D")} />
            </Card>
          </Grid>
        </Grid>
      </div> 
      :
      <div className={classes.content}>
        <Grid container spacing={4}>
          { Object.keys(moduleStatistics).map((item,index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={6}>
                <Card
                  variant="outlined"
                  classes={{root:classes.cardBorder}}
                  title = {(item.charAt(0).toUpperCase() + item.slice(1)).match(/[A-Z][a-z]+|[0-9]+/g).join(" ").replace('Module', '')}
                >
                  <HighchartsReact highcharts={Highcharts} options={config(item, (index % 2 ? "#86D295" : "#FF8F3D"))} />
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
      }
    </Card>
  );
}
