import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { formatNumbers, roundTo2Decimal, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { setValues, reset } from "../store/search";
import { setSearchText } from "store/general";
import { equipmentReserveTableHead } from "enums/UserPortal/TableHeaders";
import { SearchEquipmentReserveOrIssue, DeleteEquipmentReserve } from "services/UserPortal/EquipmentService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/equipmentStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchReserveResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.equipment.search.reserveTotalCount);
  const results = useSelector(store => store.user.equipment.search.reserveResults);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleModal = (id) => {
    setDeleteId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteEquipmentReserve(deleteId))
    .then(() =>{
      dispatch(SearchEquipmentReserveOrIssue({keyword: params.keyword, page: 0, status: "Reserved"}))
      .then((response) => {
        dispatch(setValues({reserveTotalCount: response.payload.result.totalCount, reserveResults: response.payload.result.items}))
      });
      setPage(0);
      handleModal(null);
      setDeleteId(null);
    })
  }

  React.useEffect(() => {
    dispatch(SearchEquipmentReserveOrIssue({keyword: params.keyword, page, stopLoading: Boolean(page), status: "Reserved"}))
    .then((response) => {
      dispatch(setValues({reserveTotalCount: response.payload.result.totalCount, reserveResults: response.payload.result.items}))
    });
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Reserve List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={filterActionTableHead(equipmentReserveTableHead, canWrite)}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.workOrderNo)}</TableCell>
                            <TableCell>{highlightValue(item.equipmentName)}</TableCell>
                            <TableCell>{highlightValue(item.equipmentRefNo)}</TableCell>
                            <TableCell>{highlightValue(moment(item.timeStampReserved).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(item.productName)}</TableCell>
                            <TableCell>{highlightValue(item.productRefNo)}</TableCell>
                            <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.reservedQty)))}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.currentQty)))}</TableCell>
                            <TableCell>{highlightValue(item.uom)}</TableCell>
                            <TableCell>{highlightValue(moment(item.startDateTime).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>{highlightValue(moment(item.endDateTime).format("DD/MM/yyyy HH:mm"))}</TableCell>
                            <TableCell>
                              { item.location
                              ? item.location.cageLocationRefNo 
                                ? "Near "+item.location.cageLocationRefNo 
                                : item.location.floorNumber && item.location.sectorNumber ? "Floor "+item.location.floorNumber + " Sector "+ item.location.sectorNumber : ""
                              : ""
                              }
                            </TableCell>
                            <TableCell>{highlightValue(item.reservedUser)}</TableCell>
                            <TableCell>{highlightValue(item.status)}</TableCell>
                            <TableCell align="right">
                              {canWrite &&
                                <React.Fragment>
                                  <Link to={{pathname: "/user/equipment/reserve/"+item.id}}>
                                    <IconButton type="edit" />
                                  </Link>
                                  {item.reservedUser !== "Planning" &&
                                    <IconButton 
                                      type="delete"
                                      onClick={() => handleModal(item.id)}
                                    />
                                  }
                                </React.Fragment>
                              }
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this reservation? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
