import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
// core components
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import IconButton from "shared-components/Button/IconButton";

import { minDate } from "config";
import { UpdateDeviceCO2CanisterDate } from "services/UserPortal/ClimateService";

import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);
export default function CO2CanisterDate({data, isDirty, ...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [co2Canister, setCo2Canister] = React.useState(null);

  const handleDateChange = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      setCo2Canister({...co2Canister, cO2CanisterDate: newValue.format()});
    } else {
      setCo2Canister({...co2Canister, cO2CanisterDate: value});
    }
  };

  const handleDateSave = () => {
    props.setIsLoading(true);
    dispatch(UpdateDeviceCO2CanisterDate(co2Canister))
    .then(() => {
      props.getDeviceControl(true);
      setCo2Canister(null);
    });
  }

  const handleDateEdit = () => {
    if (data[0]) {
      const date = data[0].cO2CanisterDate;
      setCo2Canister({id: data[0].id, cO2CanisterDate: moment(date).isAfter(minDate) ? moment(date).format() : moment().format()});
    }
  }

  const date = data[0] && data[0].cO2CanisterDate;
  return (
    <div className={classes.modalGridItem}>
      <Typography>Date of latest change of CO2 Canister:</Typography>
      {props.isLoading
      ? <CircularProgress
          variant="indeterminate"
          className={classes.progress2}
          size={24}
          thickness={4}
        />
      : <React.Fragment>
          <DateTimePicker
            type="datetime"
            value={co2Canister ? co2Canister.cO2CanisterDate : (moment(date).isAfter(minDate) ? date : null)}
            // minDate={moment().format("YYYY-MM-DD")}
            onChange={handleDateChange}
            disabled={!co2Canister}
          />
          { co2Canister
          ? <React.Fragment>
              <IconButton 
                type="confirm"
                onClick={handleDateSave}
              />
              <IconButton 
                type="close"
                onClick={() => setCo2Canister(null)}
              />
            </React.Fragment>
          : <IconButton 
              type="edit"
              onClick={handleDateEdit}
              disabled={isDirty}
            />
          }
        </React.Fragment>
      }
    </div>
  );
}

CO2CanisterDate.propTypes = {
  data: PropTypes.node,
  isDirty: PropTypes.bool,
};