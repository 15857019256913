/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Radio from 'shared-components/Radio/Radio';
import DateTimePicker from "shared-components/DatePicker/DateTimePicker";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";

import { minDateTime } from "config";
import { renderValue } from "common/functions";
import { setDirty, setSuccess, setError } from "store/general";
import { setValues, updateRequest, reset } from "../store/adhoc";
import { ExecuteGetAllRobots, ExecuteGetMapByName, CreateOrUpdateMission } from "services/UserPortal/AMRService";

import search from "assets/icons/grey/search.svg";
import batchUpdate from "assets/img/batchUpdate-img.svg";
import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function Route() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const task = useSelector(store => store.user.amr.adhoc.task);
  const amrList = useSelector(store => store.user.amr.adhoc.amrList);
  const nodeList = useSelector(store => store.user.amr.adhoc.nodeList);
  const [selectedRobot, setSelectedRobot] = React.useState(null);
  const [startDate, setStartDate] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_radio = (e) => {
    setStartDate(e.target.value);
    dispatch(updateRequest({delayDateTime : moment(minDateTime).format("DD/MM/YYYY HH:mm:ss")}));
  }

  const handleOnChange_date = (value, field) => {
    if(value && value.isValid() && value != "Invalid Date") {
      const newValue = moment(value).set({second: 0, millisecond: 0});
      let payload = {[field]: newValue.format()};
      dispatch(updateRequest(payload));
    } else {
      dispatch(updateRequest({delayDateTime : moment(value).format("DD/MM/YYYY HH:mm:ss")}));
    }
  };

  const handleOnChange_select = (e) => {
    if (e.target.name === "robot_id") {
      const robot = _.find(amrList, ({id}) => id === e.target.value);
      dispatch(updateRequest({[e.target.name] : e.target.value, req_robgroups: robot.req_robgroups}));
    } else {
      dispatch(updateRequest({[e.target.name] : e.target.value}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleButtonClick_location = (id) => {
    dispatch(updateRequest({steps : [{map_name: selectedRobot.map_name, target: id}]}));
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateMission({missionId: 0, ...task}))
        .then((response)=>{
          if (response.error) {
            dispatch(setError("An error has occurred. Please try again."));
          } else {
            if (task.startDate === "Now") {
              dispatch(setSuccess("Success! Your mission will start now."));
            } else {
              dispatch(setSuccess("Success! Your mission is saved."));
            }
            dispatch(reset());
          }
        })
      })
    }
  }

  const validateFields = () => {
    if (!task.robot_id) {
      setErrorMsg({field: "robot_id", msg: "Select a robot"});
      return false;
    }
    if (!startDate === "Now" && !task.delayDateTime) {
      setErrorMsg({field: "delayDateTime", msg: "Enter a start date"});
      return false;
    }
    if (!moment(task.delayDateTime).isValid()) {
      setErrorMsg({field: "delayDateTime", msg: "Invalid Date"});
      return false;
    }
    if (!task.steps.length) {
      setErrorMsg({field: "endLocation", msg: "Select an end location"});
      return false;
    }
    // if (!task.isNoEndDateTime && !task.endDateTime) {
    //   setErrorMsg({field: "endDateTime", msg: "Please enter an end date"});
    //   return false;
    // }
    // if (!moment(task.endDateTime).isValid()) {
    //   setErrorMsg({field: "endDateTime", msg: "Invalid Date"});
    //   return false;
    // }
    // if (task.endDateTime && moment(task.endDateTime).isBefore(moment(task.delayDateTime))) {
    //   setErrorMsg({field: "endDateTime", msg: "End date cannot be before start date"});
    //   return false;
    // }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  React.useEffect(() => {
    if (task.robot_id) {
      const robot = _.find(amrList, ({id}) => id === task.robot_id);
      setSelectedRobot(robot);
      dispatch(ExecuteGetMapByName({name: robot.map_name}));
    }
  },[task.robot_id]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(ExecuteGetAllRobots());
  },[]);

  return (
    <React.Fragment>
      <Grid container spacing={4} className={classes.gridContainer}>
        <Grid item xs={7} className={classes.formField}>
          <Typography>Start Date/Time:</Typography>
          <Radio 
            id="startDate"
            className={classes.radio}
            value={"Now"}
            checked={startDate === "Now"}
            onChange={(e)=>handleOnChange_radio(e)}
          />
          <Typography className={classes.radioLabel}>Now</Typography>
          <Radio 
            id="startDate"
            className={classes.radio}
            value={"Schedule"}
            checked={startDate === "Schedule"}
            onChange={(e)=>handleOnChange_radio(e)}
          />
          <Typography className={classes.radioLabel}>Schedule</Typography>
          <DateTimePicker
            type="datetime"
            name="delayDateTime"
            value={moment(task.delayDateTime).isAfter(minDateTime) && task.delayDateTime}
            onChange={(e) => handleOnChange_date(e, "delayDateTime")}
            errorMsg={errorMsg}
            disabled={startDate === "Now"}
          />
        </Grid>
        <Grid item xs={5} />
        {/* <Grid item xs={5} className={classes.formField}>
          <Typography>End Date/Time:</Typography>
          <DateTimePicker
            type="datetime"
            name="endDate"
            value={task.endDate}
            minDateTime={task.startDate}
            onChange={(e) => handleOnChange_date(e, "endDate")}
            errorMsg={errorMsg}
          />
        </Grid> */}
        <Grid item xs={5} className={classes.formField}>
          <Typography>AMR:</Typography>
          <Select
            name="robot_id"
            onChange={(e)=>handleOnChange_select(e)}
            value={task.robot_id}
            placeholder="Select Value"
            errorMsg={errorMsg}
          >
            { amrList.map((item, index) => {
              return <MenuItem key={index} value={item.id}>{item.alias}</MenuItem>;
            })} 
          </Select>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={5} className={classes.formField}>
          <Typography>Nature of Task:</Typography>
          <TextField 
            id="nature"
            variant="outlined" 
            onChange={(e) => handleOnChange_text(e)}
            placeholder="Enter Value"
            value={renderValue(task.nature)}
            errorMsg={errorMsg}
          />
        </Grid>
        <Grid item xs={5} className={classes.formField}>
          <Typography>Task Type:</Typography>
          <Select
            name="task_type"
            onChange={(e)=>handleOnChange_select(e)}
            value={task.task_type}
            placeholder="Select Value"
            errorMsg={errorMsg}
          >
            { ["AdHoc", "Recurring"].map((item, index) => {
              return <MenuItem key={index} value={item}>{item}</MenuItem>;
            })} 
          </Select>
        </Grid>
        {task.task_type === "Recurring" &&
          <Grid item xs={5} className={classes.formField}>
            <Typography>Frequency:</Typography>
            <Select
              name="frequency"
              onChange={(e)=>handleOnChange_select(e)}
              value={task.frequency}
              placeholder="Select Value"
              errorMsg={errorMsg}
            >
              { ["Daily", "Weekly", "Monthly"].map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </Grid>
        }
        {task.robot_id && 
          <React.Fragment>
            <Grid item xs={12}>
              <Typography>End Location:</Typography>
              {(errorMsg && errorMsg.field === 'endLocation') && <Typography className={classes.error}>{errorMsg.msg}</Typography>}
              {_.sortBy(nodeList, ['id']).map((item) => {
                return (
                  <Button key={item.id} className={clsx(classes.locationButton, task.steps && task.steps.length && task.steps[0] && task.steps[0].target === item.id && classes.selectedButton)} onClick={()=>handleButtonClick_location(item.id)}>{item.id}</Button>
                );
              })}
            </Grid>
          </React.Fragment>
        }
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button 
            className={classes.button}
            onClick={() => handleButtonClick_save()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
