// Raw Material Type
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";

// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import Pagination from "shared-components/Table/Pagination";
import CustomIconButton from "shared-components/Button/IconButton";

import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { configureRawMaterialTypeTableHead } from "enums/AdminPortal/TableHeaders";
import { DeleteRawMaterialType, GetAllRawMaterialType } from "services/AdminPortal/RawMaterialService";
import { rowsPerTable } from "config";
import AddEditRawMaterialType from "./AddEditRawMaterialType";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/rawMaterialStyle.js";

const useStyles = makeStyles(styles);

export default function RawMaterialTypeSetting(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const allRawMaterialType = useSelector(store => store.admin.rawMaterial.allRawMaterialType);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('rawMaterialTypeName');
  const [page, setPage] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [addEditMode, setAddEditMode] = React.useState(false);
  const [rawMaterialType, setRawMaterialType] = React.useState("");
  const [shortform, setShortform] = React.useState("");
  const [id, setId] = React.useState(0);

  const count = Math.ceil(allRawMaterialType.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleButtonClick_edit = (name, shortform, id) => {
    Promise.all([
      setRawMaterialType(name),
      setShortform(shortform.toUpperCase()),
      setId(id),
    ])
    .then(() => {
      setAddEditMode("Edit");
    })
  }

  const handleButtonClick_add = () => {
    setAddEditMode("Add");
  }

  const handleButtonClick_goBack = () => {
    resetState();
    if (props.setRawMaterialTypeSetting) {
      props.setRawMaterialTypeSetting(false);
    } else {
      const prop = props.location && props.location.state;
      history.push((prop && prop.prevPath) || "/admin/raw-material");
    }
  }

  const handleModal = (id) => {
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    Promise.all([
      dispatch(DeleteRawMaterialType(id))
    ])
    .then(() => {
      setOpenModal(!openModal);
      dispatch(GetAllRawMaterialType());
      resetState();
      setPage(0);
    })
  }

  const resetState = () => {
    setRawMaterialType("");
    setShortform("");
    setId(0);
    setAddEditMode(false);
  }

  React.useEffect(() => {
    dispatch(GetAllRawMaterialType());
    setRawMaterialType("");
    setShortform("");
    setId(0);
  },[addEditMode]);

  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    // componentDidUnmount
    return () => {
      const prop = props.location && props.location.state;
      if (prop) {
        dispatch(resetNav());
      }
    }
  },[]);

  if (addEditMode) {
    return (
      <AddEditRawMaterialType rawMaterialType={rawMaterialType} shortform={shortform} id={id} addEditMode={addEditMode} setAddEditMode={setAddEditMode}/>
    )
  } else {
    return (
      <React.Fragment>
        <Card 
          title = {"Configuring Raw Material Type"}
          cardActions={
            <React.Fragment>
              <div>
                <div>
                  <Button
                    className={classes.buttonSecondary}
                    onClick={() => handleButtonClick_add()}
                  >
                    + Add New Raw Material Type
                  </Button>
                </div>
                <Button
                  className={clsx(classes.buttonSecondary, classes.goBackButton)}
                  onClick={() => handleButtonClick_goBack()}
                >
                  Go Back
                </Button>
              </div>
            </React.Fragment>
          }
        >
          <Table
            className={classes.table}
            header={configureRawMaterialTypeTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(allRawMaterialType, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.rawMaterialTypeName}</TableCell>
                  <TableCell>{item.rawMaterialTypeShortForm}</TableCell>
                  <TableCell align="right">
                    <React.Fragment>
                      <CustomIconButton
                        type="edit" 
                        onClick={() => handleButtonClick_edit(item.rawMaterialTypeName, item.rawMaterialTypeShortForm, item.id)}
                      />
                      <CustomIconButton
                        type="delete" 
                        onClick={() => handleModal(item.id)}
                      />
                    </React.Fragment>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Card>
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this raw material type? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal(0)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
