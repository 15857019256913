import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/finishedGoods";
import { checkPermission, formatNumbers, roundTo2Decimal, renderValue, filterParam } from "common/functions";
import { warehouseFGTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllFinishedGoodsByWarehouseId, GetProductListLookUp, GetSaleOrderLookUp } from "services/UserPortal/WarehouseService";

import styles from "assets/jss/components/UserPortal/warehouseStyle.js";

const useStyles = makeStyles(styles);

export default function FinishedGoods() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lotIdList = useSelector(store => store.common.lotIdList);
  const warehouseList = useSelector(store => store.common.warehouseList);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const filter = useSelector(store => store.user.warehouse.finishedGoods.filter);
  const productList = useSelector(store => store.user.warehouse.finishedGoods.productList);
  const saleOrderList = useSelector(store => store.user.warehouse.finishedGoods.saleOrderList);
  const totalCount = useSelector(store => store.user.warehouse.finishedGoods.totalCount);
  const result = useSelector(store => store.user.warehouse.finishedGoods.finishedGoods);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('dateIn');
  const [newFilter, setNewFilter] = React.useState({warehouseId: null, workOrderId: null, productRefNo: null, saleOrderId: null, page: 0});

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };
  
  const getFGList = (stopLoading) => {
    if (filter.warehouseId) {
      dispatch(GetAllFinishedGoodsByWarehouseId({...filter, stopLoading}));
    } else {
      dispatch(setValues({finishedGoods: []}))
    }
  }

  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (warehouseList.length) {
        dispatch(setValues({filter: {warehouseId: warehouseList[0].id, page: 0}}));
      } else {
        dispatch(setValues({filter: {warehouseId: null, page: 0}}));
      }
    }
  },[warehouseList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getFGList();
    }
  },[filter]);

  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetProductListLookUp());
    dispatch(GetSaleOrderLookUp());
  },[]);

  useInterval(() => {
    getFGList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title={"Finished Goods List"}
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "warehouseId") {
                const warehouse = warehouseList.find(({id}) => id == filter[key]);
                label = warehouse && (warehouse.warehouseName+" ("+warehouse.warehouseId+")");
              }
              if (key === "workOrderId") {
                const workOrder = workOrderList.find(({id}) => id == filter[key]);
                label = workOrder && workOrder.workOrderNo;
              }
              if (key === "productRefNo") {
                const product = productList.find(({productId}) => productId == filter[key]);
                label = product && (product.productName+" ("+product.productId+")");
              }
              if (key === "saleOrderId") {
                const saleOrder = saleOrderList.find(({saleOrderId}) => saleOrderId == filter[key]);
                label = saleOrder && saleOrder.saleOrderNumber;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the finished goods list
                  <Select
                    className={classes.filterDropdown}
                    name="warehouseId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a warehouse"
                    value={renderValue(newFilter.warehouseId)}
                  >
                    {warehouseList && warehouseList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="productRefNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productRefNo)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.productId}>{item.productName} ({item.productId})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="saleOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a sale order"
                    value={renderValue(newFilter.saleOrderId)}
                  >
                    {saleOrderList && saleOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.saleOrderId}>{item.saleOrderNumber}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.masterTable}
            header={warehouseFGTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{moment(item.dateIn).isAfter(minDate) ? moment(item.dateIn).format("DD/MM/YYYY") : ""}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.productRefNo}</TableCell>
                  <TableCell>{item.workOrderNo}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{item.saleOrderNo}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.quantity))}</TableCell>
                  <TableCell>{item.rackRefNo && <ViewMoreModal list={item.rackRefNo} title="Rack Reference Number" />}</TableCell>
                  <TableCell>{item.palletId && <ViewMoreModal list={item.palletId} title="Pallet ID" />}</TableCell>
                  <TableCell>{moment(item.expectedDateOut).isAfter(minDate) ? moment(item.expectedDateOut).format("DD/MM/YYYY") : ""}</TableCell>
                  <TableCell>{moment(item.expiryDate).isAfter(minDate) ? moment(item.expiredDate).format("DD/MM/YYYY, HH:mm") : ""}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
        {checkPermission("Finished Goods", "readEdit") &&
          <Typography className={classes.link}>
            <Link to={{pathname: "/user/finished-goods/master-list"}}>Finished Goods Module Page</Link>
          </Typography>
        }
      </Card>
    </React.Fragment>
  );
}
