import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";

import { graphColors as colors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/smartMeteringStyle.js";

const useStyles = makeStyles(styles);

export default function LineChart(props) {
  const classes = useStyles();
  const [config, setConfig] = React.useState({chart: { zoomType: 'x' }});

  React.useEffect(() => {
    if (props.results) {
      props.setIsLoading(false);
      setConfig(({
        chart: {
          zoomType: 'x',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          title: {
            text: props.title ? props.title.name + " (" + props.title.unit + ")" : ""
          }
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: Boolean(props.viewDetails),
          verticalAlign: 'bottom',
          align:'left',
          symbolRadius: 0,
        },
        plotOptions: {
          series: {
            animation: props.isLoading,
            label: {
              connectorAllowed: false
            },
            marker: {
              symbol: "circle"
            }
          }
        },
        series: props.results.map((device, i) => {
          if (device) {
            return {
              data: device.mainDetails.map((item) => [moment(item.timestamp).valueOf(), Number(item.realValue)]),
              name: device.name,
              color: colors[i]
            }
          }
        }),
      }))
    }
  },[props.results]);
  
  if (props.isLoading) {
    return (
      <div className={classes.chartContainer}>
        <CircularProgress
          variant="indeterminate"
          className={classes.progress}
          thickness={6}
        />
      </div>
    ) 
  } else {
    return (
      <React.Fragment>
        {props.results && props.results.length
        ? <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ className: classes.highChart }} />
        : <div className={classes.chartContainer}><Typography className={classes.graphText}>No Data</Typography></div>
        }
      </React.Fragment>
    );
  }
}
