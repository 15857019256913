import {
    cardRadius,
    border,
    icon_64,
    button,
    buttonSecondary,
    primaryColor
  } from "assets/jss/generaliseStyle.js";
  
  import {
    orange,
    table,
    subTable,
    icon,
  } from "assets/jss/components/commonStyle.js";
  
  const flowControlStyle = {
    cardRadius,
    button,
    buttonSecondary,
    icon_64,
    
    orange,
  
    // Display 
    table,
    subTable,
    icon,
    cancelButton: {
      ...buttonSecondary,
      paddingTop: "0.250vw",
      paddingBottom: "0.250vw"
    },
    executeButton: {
      ...button,
      paddingTop: "0.250vw",
      paddingBottom: "0.250vw",
      textWrap: "nowrap"
    },
  
    // Add Edit
    paper: {
      ...cardRadius,
      border,
      display: "flex",
      flexDirection: "column",
      margin: "1.563vw 15.104vw 5.208vw",
      padding: "3.646vw 7.292vw 0"
    },
    label: {
      marginTop: "0.833vw",
    },
    iconButton: {
      marginLeft: "-0.625vw"
    },
    action: {
      display: "flex",
      justifyContent: "space-between",
      margin: "2.708vw 0",
      padding: 0
    },

    // Settings
    settingPaper: {
      ...cardRadius,
      border,
      width: "45vw",
      margin: "auto",
      marginBottom: "1.302vw",
      padding: "1.823vw 7.292vw",
      color: primaryColor[3],
    },
    settingLabel: {
      color: primaryColor[4],
      marginTop: "0.833vw"
    },
    settingButton: {
      display: "flex",
      justifyContent: "space-between"
    },
  }
  
  export default flowControlStyle;