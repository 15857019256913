import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetAllRawMaterialRequestOrReceived, GetRawMaterialStatusList } from "services/UserPortal/RawMaterialService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  requestList: [],
  request: {estDeliveryDate: moment().format("YYYY-MM-DD")},
  //filter
  statusList: {},
};

const requestRM = createSlice({
  name: "requestRM",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.request[key] = action.payload[key];
      })
    },
    resetRequest: (state) => {
      state.request = {estDeliveryDate: moment().format("YYYY-MM-DD")};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllRawMaterialRequestOrReceived.fulfilled]: (state, action) => {
      state.totalCount= action.payload.result.totalCount;
      state.requestList = action.payload.result.items;
    },
    [GetRawMaterialStatusList.fulfilled]: (state, action) => {
      state.statusList = action.payload.result;
    },
  },
});

// export actions
export const { updateRequest, reset, resetRequest } = requestRM.actions;

// export the reducer
export default requestRM.reducer;