import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

import { GetWorkOrderList, GetFrequencyList, GetAllUnitOfMeasurement, GetWorkOrderListByLoginUser, GetProductListByWorkOrderId, GetProductListByMixProductId, GetOperationTaskDetail } from "services/UserPortal/OperationsService";

// initial state
const initialState = {
  filter: {},
  frequencyList: [],
  uomList: [],
  allRackNumber: [],
  userTask: [],
  workOrderList: [],
  products: [],
  mixProducts: [],
  task: {},
  taskDetail: {},
  preHarvestTask: [],
  postHarvestTask: [],
  rawMaterial: [],
  equipment: [],
  other: [],
  selectedAction: [],
  storage: {},
  qc: {},
  taskConfig: {}
};

const task = createSlice({
  name: "task",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.taskConfig[key] = action.payload[key];
      })
    },
    updateStorageRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.storage[key] = action.payload[key];
      })
    },
    updateQCRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.qc[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetFrequencyList.fulfilled]: (state, action) => {
      state.frequencyList = action.payload.result;
    },
    [GetAllUnitOfMeasurement.fulfilled]: (state, action) => {
      state.uomList = action.payload.result;
    },
    [GetWorkOrderListByLoginUser.fulfilled]: (state, action) => {
      state.userTask = _.orderBy(action.payload.result, ["workOrderNo"], ["desc"]);
    },
    [GetWorkOrderList.fulfilled]: (state, action) => {
      state.workOrderList = _.orderBy(action.payload.result, ["workOrderNo"], ["desc"]);
    },
    [GetProductListByWorkOrderId.fulfilled]: (state, action) => {
      state.products = action.payload.result;
    },
    [GetProductListByMixProductId.fulfilled]: (state, action) => {
      state.mixProducts = action.payload.result;
    },
    [GetOperationTaskDetail.fulfilled]: (state, action) => {
      const result = action.payload.result;
      state.taskDetail = _.omit(result, ['operationProcessList']);
      let preHarvestTask = result && _.filter(result.operationProcessList, 'isPreHarvest');
      let postHarvestTask = result && _.reject(result.operationProcessList, 'isPreHarvest');
      state.preHarvestTask = _.map(preHarvestTask, (item) => {item.operationTaskList = item.operationTaskList.sort((a,b) => a.taskName.localeCompare(b.taskName, 'en', { numeric: true, })); return item;});
      state.postHarvestTask = _.map(postHarvestTask, (item) => {item.operationTaskList = item.operationTaskList.sort((a,b) => a.taskName.localeCompare(b.taskName, 'en', { numeric: true, })); return item;});
    },
  },
});

// export actions
export const { setValues, updateRequest, updateStorageRequest, updateQCRequest, reset } = task.actions;

// export the reducer
export default task.reducer;