import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Modal from 'shared-components/Modal/Modal';
import ViewMoreModal from 'shared-components/Modal/ViewMoreModal';

import { formatNumbers, roundTo2Decimal } from "common/functions";
import { rowsPerTable } from "config";
import { reset } from "../store/search";
import { setValues } from "../store/out";
import { setSearchText } from "store/general";
import { finishedGoodsOutTableHead } from "enums/UserPortal/TableHeaders";
import { SearchFinishedGoodsOutList, CheckOutFinishedGoods } from "services/UserPortal/FinishedGoodsService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/finishedGoodsStyle.js";

const useStyles = makeStyles(styles);

import { useRouteCanWrite } from "hooks";

export default function SearchOutResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const totalCount = useSelector(store => store.user.finishedGoods.search.outTotalCount);
  const results = useSelector(store => store.user.finishedGoods.search.outResults);
  const checkOutList = useSelector(store => store.user.finishedGoods.finishedGoodsOut.checkOutList);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleButtonClick_checkbox = (e) => {
    const value = e.target.value;
    let payload = _.cloneDeep(checkOutList);
    if (payload.includes(value)) {
      payload.splice(payload.indexOf(value), 1);
    } else {
      payload.push(value);
    }
    dispatch(setValues({[e.target.id] : payload}));
  };
  
  const handleButtonClick_save = () => {
    dispatch(CheckOutFinishedGoods())
    .then(({error})=>{
      if (!error) {
        setOpenSuccessModal(openSuccessModal);
      }
      setPage(0);
      dispatch(SearchFinishedGoodsOutList({keyword: params.keyword, page: 0}));
      dispatch(setValues({checkOutList : []}));
    });
  };

  const handleModal_success = () => {
    setOpenSuccessModal(!openSuccessModal);
  };

  React.useEffect(() => {
    dispatch(SearchFinishedGoodsOutList({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Out List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
            cardActions={!collapsed &&
              <React.Fragment>
                <Button disabled/>
                {canWrite &&
                  <Button 
                    className={classes.button}
                    onClick={()=>handleButtonClick_save()}
                    disabled={!checkOutList.length}
                  >
                    Check Out
                  </Button>
                }
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={finishedGoodsOutTableHead}
                  >
                    { _.map(results, (item,index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.productName)}</TableCell>
                            <TableCell>{highlightValue(item.productRefNo)}</TableCell>
                            <TableCell>{highlightValue(item.workOrderNo)}</TableCell>
                            <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                            <TableCell>{highlightValue(item.subSaleOrderNo)}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.qtyIn)))}{item.uom}</TableCell>
                            <TableCell>{highlightValue(formatNumbers(roundTo2Decimal(item.numberOfPacket)))}</TableCell>
                            <TableCell>{highlightValue(item.uomForWeightPerPacket)}</TableCell>
                            <TableCell>{item.palletId && <ViewMoreModal list={item.palletId} title="Pallet ID" />}</TableCell>
                            <TableCell>{highlightValue(moment(item.expectedDateOut).format("DD/MM/YYYY"))}</TableCell>
                            <TableCell>{highlightValue(moment(item.dateOfExpiry).format("DD/MM/YYYY"))}</TableCell>
                            <TableCell>
                              <Checkbox 
                                id="checkOutList"
                                value={item.id}
                                onClick={(e)=>handleButtonClick_checkbox(e)}
                                checked={checkOutList.includes(item.id)}
                                disabled={!canWrite}
                              />
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
      <Modal
        open={openSuccessModal}
        onClose={() => handleModal_success()}
        icon={<img className={classes.icon_64} src={confirm} alt="confirm" />}
        title="Success"
        content={
          <React.Fragment>
            Selected products has been checked out of Finished Goods module. You can proceed to the Delivery Order Creation to create Delivery Order.
          </React.Fragment>
        }
        actions={
          <Button className={classes.button} onClick={() => handleModal_success()}>Okay</Button>
        }
      />
    </React.Fragment>
  );
}
