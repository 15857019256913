import {
  border,
  whiteColor,
  secondaryColor,
  primaryColor
} from "assets/jss/generaliseStyle.js";

const checkboxStyle = {
  // Checkbox
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // default
  icon: {
    borderRadius: "0.156vw",
    width: "1.354vw",
    height: "1.354vw",
    boxShadow: 'inset 0 0 0 0.052vw rgba(16,22,26,.2), inset 0 -0.052vw 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: secondaryColor[0],
    },
    'input:disabled ~ &': {
      //boxShadow: 'none',
      background: primaryColor[1],
      opacity: 0.5
    },
    'input:checked:disabled ~ &': {
      //boxShadow: 'none',
      background: secondaryColor[0],
    },
  },
  indeterminateIcon: {
    backgroundColor: secondaryColor[0]+" !important",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: "1.354vw",
      height: "1.354vw",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z'" +
        " fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: secondaryColor[1],
    },
  },
  checkedIcon: {
    backgroundColor: secondaryColor[0],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: "1.354vw",
      height: "1.354vw",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: secondaryColor[1],
    },
  },
  // outlined
  outlinedIcon: {
    borderRadius: 3,
    width: "1.354vw",
    height: "1.354vw",
    background: whiteColor,
    border: '0.052vw solid '+secondaryColor[0],
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      border,
      background: whiteColor,
      // opacity: 0.5
    },
    'input:checked:disabled ~ &': {
      border,
      background: whiteColor,
    },
  },
  indeterminateOutlinedIcon: {
    backgroundColor: secondaryColor[0],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: "1.354vw",
      height: "1.354vw",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z'" +
        " fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      border: '0.052vw solid '+secondaryColor[1],
      backgroundColor: secondaryColor[1],
    },
  },
  checkedOutlinedIcon: {
    backgroundColor: whiteColor,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: "1.250vw",
      height: "1.250vw",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23FF8A3D'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      border: '0.052vw solid '+secondaryColor[1],
      backgroundColor: whiteColor,
    },
  },

  // Numbering
  numberBox: {
    borderRadius: "0.156vw",
    width: "1.354vw",
    height: "1.354vw",
    cursor: "pointer",
    margin: "auto",
    boxShadow: 'inset 0 0 0 0.052vw rgba(16,22,26,.2), inset 0 -0.052vw 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '&:hover': {
      backgroundColor: secondaryColor[0],
    },
  },
  checkedNumber: {
    backgroundColor: secondaryColor[0],
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    textAlign: "center",
    color: whiteColor,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: secondaryColor[1],
    },
  },
  disabled: {
    border,
    borderRadius: "0.156vw",
    width: "1.354vw",
    height: "1.354vw",
    margin: "auto",
    background: primaryColor[1],
    opacity: 0.5
  }
};

export default checkboxStyle;
  