import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import clsx from 'clsx'
// @mui/material components
import { makeStyles } from "@mui/styles"
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// core components
import styles from "assets/jss/shared-components/textFieldStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTextFieldWithLabel({className, startAdornment, endAdornment, error, errorMsg, ...props}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.textFieldDiv)}>
      <div className={(error || (errorMsg && errorMsg.field==props.id)) ?  clsx(classes.root, classes.error) : classes.root}>
        <Grid container spacing={0} className={classes.container} alignItems="center">
          {startAdornment && 
            <Grid item className={classes.adornment}>
              {startAdornment}
            </Grid>
          }
          <Grid item className={classes.textfieldGrid}>
            <TextField 
              {...props} 
              className={classes.textfield}
              InputProps={{ className: classes.focused, disableUnderline: true}} 
              InputLabelProps={{ className: classes.label }} 
            />
          </Grid>
          {endAdornment && 
            <Grid item className={classes.adornment}>
              {endAdornment}
            </Grid>
          }
        </Grid>
      </div>
      {(errorMsg && errorMsg.field==props.id) && <Typography className={classes.message}>{errorMsg.msg}</Typography>}
    </div>
  );
}

CustomTextFieldWithLabel.propTypes = {
  className: PropTypes.node,
  startAdornment: PropTypes.object,
  endAdornment: PropTypes.object,
};