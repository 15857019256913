import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { rmReconciliationTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/reconciliation";
import { GetAllRawMaterialReconciliation, GetAllRawMaterialBatchInfo } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

const useStyles = makeStyles(styles);

export default function Reconciliation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const filter = useSelector(store => store.user.rawMaterial.reconciliationRM.filter);
  const batchList = useSelector(store => store.user.rawMaterial.reconciliationRM.batchList);
  const totalCount = useSelector(store => store.user.rawMaterial.reconciliationRM.totalCount);
  const result = useSelector(store => store.user.rawMaterial.reconciliationRM.result);
  const [newFilter, setNewFilter] = React.useState({rawMaterialId: null});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };
  
  const getRMList = (stopLoading) => {
    dispatch(GetAllRawMaterialReconciliation({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getRMList();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllRawMaterialBatchInfo());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getRMList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title="Reconciliation Notes"
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "rawMaterialId") {
                const rm = rawMaterialList.find(({id}) => id == filter[key]);
                label = rm ? rm.rawMaterialName+" ("+rm.rawMaterialRefNo+")" : "";
              }
              if (key === "rawMaterialBatchId") {
                const batch = batchList.find(({id}) => id == filter[key]);
                label = batch && batch.batchRefNo;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the reconciliation list
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material"
                    value={renderValue(newFilter.rawMaterialId)}
                  >
                    {rawMaterialList && rawMaterialList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.rawMaterialName} ({item.rawMaterialRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialBatchId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a batch reference number"
                    value={renderValue(newFilter.rawMaterialBatchId)}
                  >
                    {batchList && batchList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.batchRefNo}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={rmReconciliationTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{item.rawMaterialRefNo}</TableCell>
                  <TableCell>{item.batchRefNo}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.adjustment))}</TableCell>
                  <TableCell>{item.uom}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.newBalanceForBatch))}</TableCell>
                  <TableCell>{item.reason}</TableCell>
                  <TableCell>{moment(item.creationTime).format("DD/MM/YYYY HH:mm")}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
