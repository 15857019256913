import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";

import { renderValue } from "common/functions";
import { updateRequest } from ".";
import { LightingPowerAndPercentagePrediction, GetLightingType, GetFarmLocation, TracingWattCalculatorModuleForStatisticsReport } from "services/UserPortal/PredictionService";

import styles from "assets/jss/components/UserPortal/predictionStyle.js";

const useStyles = makeStyles(styles);

export default function Prediction() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lighting = useSelector(store => store.user.prediction.lighting);
  const lightingType = useSelector(store => store.user.prediction.lightingType);
  const farmLocation = useSelector(store => store.user.prediction.farmLocation);
  const results = useSelector(store => store.user.prediction.results);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleButtonClick_calculate = () => {
    if (validateFields()) {
      dispatch(LightingPowerAndPercentagePrediction());
    }
  }

  const validateFields = () => {
    const fields = ['lighting_type', 'farm_location', 'umol', 'distance'];
    for (let field of fields) {
      if (!lighting[field]) {
        setErrorMsg({field: field, msg: "Please enter a value"});
        return false;
      }
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(TracingWattCalculatorModuleForStatisticsReport());
    dispatch(GetLightingType());
    dispatch(GetFarmLocation());
    // componentDidUnmount
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="Intensity and Watt Calculator"
        // subheader="Please enter the targeted umol figure"
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <div className={classes.margin}>
              <div className={classes.flexContainer}>
                <Typography className={classes.label}>Farm:</Typography>
                <Select
                  name="farm_location"
                  onChange={(e)=>handleOnChange_select(e)}
                  value={lighting.farm_location}
                  placeholder="Select Farm"
                  errorMsg={errorMsg}
                >
                  { farmLocation.map((item, index) => {
                    return <MenuItem key={index} value={item}>{item}</MenuItem>;
                  })} 
                </Select>
              </div>
              <div className={classes.flexContainer}>
                <Typography className={classes.label}>Lighting Type:</Typography>
                <Select
                  name="lighting_type"
                  onChange={(e)=>handleOnChange_select(e)}
                  value={lighting.lighting_type}
                  // value={productProcess}
                  placeholder="Select Lighting Type"
                  errorMsg={errorMsg}
                >
                  { lightingType.map((item, index) => {
                    return <MenuItem key={index} value={item}>{item}</MenuItem>;
                  })} 
                </Select>
              </div>
              <div className={classes.flexContainer}>
                <Typography className={classes.label}>umol/m2/s:</Typography>
                <TextField
                  variant="outlined" 
                  id="umol"
                  type="Number"
                  onInput={(e)=>{ 
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                  }}
                  onChange={(e)=>handleOnChange_text(e)}
                  value={renderValue(lighting.umol)}
                  placeholder="Enter a value"
                  errorMsg={errorMsg}
                />
              </div>
              <div className={classes.flexContainer}>
                <Typography className={classes.label}>Distance:</Typography>
                <TextField
                  variant="outlined" 
                  id="distance"
                  type="Number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                  onChange={(e)=>handleOnChange_text(e)}
                  value={renderValue(lighting.distance)}
                  placeholder="Enter a value"
                  errorMsg={errorMsg}
                />
              </div>
              <div className={clsx(classes.flexContainer, classes.buttonAlign)}>
                <Button
                  className={classes.button}
                  onClick={handleButtonClick_calculate}
                >
                  Calculate
                </Button>
              </div>
            </div>
          </Grid>
          {!_.isEmpty(results) &&
            <Grid item xs={6}>
              <Typography>Predicted Percentage: <b>{results.predicted_percentage * 100}%</b></Typography>
              <Typography>Predicted Power: <b>{results.predicted_power}W</b></Typography>
            </Grid>
          }
        </Grid>
      </Card>
    </React.Fragment>
  );
}
