import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// @mui/icons-material
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrowOutlined';
import TableChart from '@mui/icons-material/TableChartOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDownOutlined';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Accordion from "shared-components/Accordion/Accordion";
import Modal from 'shared-components/Modal/Modal';
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Switch from "shared-components/Switch/Switch";

import { minTime } from 'config';
import { useInterval } from 'common/utils';
import { checkPermission } from "common/functions";
import { setSearchText } from "store/general";
import { operationTaskStatus, taskDetailStatus, farmConfig } from "enums/Constants";
import { setValues } from "../store/task";
import { GetOperationTaskDetail, SetCompleteTask, SetCheckoutProcess, SetRetrievalProcess, UpdateTaskTime } from "services/UserPortal/OperationsService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/operationsStyle.js";

const useStyles = makeStyles(styles);

const status = {
  Pending: "Pending",
  InProgress: "In Progress",
  Complete: "Completed",
  Pause: "Paused",
  CheckOut: "Checked Out",
  Retrieved: "Retrieved"
}
export default function Tasks(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.operations.task.filter);
  const taskDetail = useSelector(store => store.user.operations.task.taskDetail);
  const preHarvestTask = useSelector(store => store.user.operations.task.preHarvestTask);
  const postHarvestTask = useSelector(store => store.user.operations.task.postHarvestTask);
  const [expanded, setExpanded] = React.useState(false);
  const [taskId, setTaskId] = React.useState(null);
  const [isViewOnly, setIsViewOnly] = React.useState(false);
  const [openRemoveDropdown, setOpenRemoveDropdown] = React.useState(false);
  const [openCheckoutDropdown, setOpenCheckoutDropdown] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [newFilter, setNewFilter] = React.useState({date: filter.startDate, showOwnTasks: null, filter: null});

  const prop = props.location && props.location.state;
  const pathname = location.pathname.split("/").slice(0, -1).join("/");

  const handleOnChange_date = (value) => {
    if(value && value.isValid() && value != "Invalid Date") {
      setNewFilter({...newFilter, date: moment(value).format("YYYY-MM-DD")});
    } 
  };

  const handleButtonClick_accordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleModal_warning = (id, item) => {
    if (item) {
      dispatch(setValues({
        selectedAction: _.filter(item.operationSubTaskList, "isMyTask").map((item)=>{
          let res = _.pick(item, ["id", "startDate", "endDate", "frequency", "assignedUserId", "isAction", "remark"]);
          res.isAction = true;
          return res;
        })
      }));
    } else {
      dispatch(setValues({selectedAction: []}));
    }
    setTaskId(id);
    setOpenWarningModal(!openWarningModal);
  }

  const handleClickRemove = (event) => {
    if (openRemoveDropdown && openRemoveDropdown.contains(event.target)) {
      setOpenRemoveDropdown(null);
    } else {
      setOpenRemoveDropdown(event.currentTarget);
    }
  };

  const handleClickCheckout = (event) => {
    if (openCheckoutDropdown && openCheckoutDropdown.contains(event.target)) {
      setOpenCheckoutDropdown(null);
    } else {
      setOpenCheckoutDropdown(event.currentTarget);
    }
  };
  
  const handleButtonClick_exit = () => {
    history.push(pathname);
  };

  const handleButtonClick_table = (item, processName) => {
    let taskStatus = null;
    if (item) {
      if (item.operationTaskStatus == operationTaskStatus.pending || item.operationTaskStatus == operationTaskStatus.paused) {
        taskStatus = taskDetailStatus.pending;
      } else if (item.operationTaskStatus == operationTaskStatus.completed) {
        taskStatus = taskDetailStatus.completed;
      }
    }
    dispatch(setValues({task: item}));
    history.push({
      pathname: location.pathname+"/"+item.id, 
      state: {
        expanded: expanded,
        workOrderId: taskDetail.workOrderId, 
        productId: taskDetail.productId,
        operationId: prop.operationId, 
        processName: processName, 
        taskStatus: taskStatus,
        isMixProduct: prop.isMixProduct,
        dateFilter: newFilter.date,
        showOwnTasks: newFilter.showOwnTasks,
      },
      search: location.search
    });
  }

  const handleButtonClick_remove = (id, processId) => {
    const payload = {
      workOrderId: taskDetail.workOrderId, 
      productId: taskDetail.productId,
      productGrowthProcessId: processId,
      operationTaskId: id,
      operationId: prop.operationId
    }
    dispatch(SetRetrievalProcess(payload))
    .then(({error}) => {
      if (!error) {
        getOperationTask();
      }
    })
  }

  const handleButtonClick_checkout = (id, processId) => {
    const payload = {
      workOrderId: taskDetail.workOrderId, 
      productId: taskDetail.productId,
      productGrowthProcessId: processId,
      operationTaskId: id,
      operationId: prop.operationId
    }
    dispatch(SetCheckoutProcess(payload))
    .then(({error}) => {
      if (!error) {
        getOperationTask();
      }
    })
  }

  const handleButtonClick_end = () => {
    dispatch(SetCompleteTask(taskId))
    .then(({error}) => {
      if(!error) {
        setTaskId(null);
        setOpenWarningModal(false);
        handleButtonClick_action({operationTaskId: taskId, isEndTime: true});
        getOperationTask();
      }
    });
    
  }

  const handleButtonClick_action = (body) => {
    dispatch(UpdateTaskTime(body))
    .then(({error}) => {
      if (!error) {
        getOperationTask(true);
      }
    })
  }
  
  const getOperationTask = (stopLoading) => {
    if (prop && prop.workOrderId) {
      dispatch(GetOperationTaskDetail({
        startDate: newFilter.date,
        endDate: newFilter.date,
        showOwnTasks: newFilter.showOwnTasks,
        workOrderId: prop.workOrderId, 
        productId: prop.productId, 
        operationId: prop.operationId, 
        stopLoading
      })).then(({error}) => {
        if (error) {
          history.push("/user/operations/overall-tasks");
        }
      });
    } else {
      handleButtonClick_exit();
    }
  }

  const timer = (data) => {
    let task = _.cloneDeep(data);
    task = task.map((t)=> {
      t.operationTaskList = t.operationTaskList.map((item) => {
        if (item.operationTaskStatus === operationTaskStatus.inProgress) {
          const time = moment.duration(item.timer ? item.timer : item.workTimer).add(1, "s");
          item.timer=formatTimer(time);
        }
        return item;
      })
      return t;
    })
    return task;
  }

  const formatTimer = (time) => {
    return parseInt(time.asHours()).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })+":"+(parseInt(time.asMinutes())%60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })+":"+(parseInt(time.asSeconds())%60%60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }

  React.useEffect(() => {
    if (!(filter.date && !newFilter.date)) {
      getOperationTask();
    }
  },[newFilter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    if (pathname.includes("task-assignment")) {
      setIsViewOnly(true);
    }
    if (prop) {
      setExpanded(prop.expanded);
    }
    // componentDidUnmount
    return () => {
      // dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getOperationTask(true);
  });

  useInterval(() => {
    dispatch(setValues({preHarvestTask: timer(preHarvestTask), postHarvestTask: timer(postHarvestTask)}));
  }, 1000);

  const renderButton = (task) => {
    if (task.workTimer == minTime) {
      return (
        <Button 
          className={clsx(classes.button, classes.accordionButton)}
          startIcon={<TimerOutlined />}
          onClick={()=>handleButtonClick_action({operationTaskId: task.id, isStartTime: true})}
          disabled={(task.ableToCheckOutEquipment && !task.checkedOutProcessName) || (task.ableToRetrieve && !task.retrievalProcessName)}
        >
          Start Tasks
        </Button>
      )
    } else {
      if (task.operationTaskStatus === operationTaskStatus.paused || task.operationTaskStatus === operationTaskStatus.completed || task.operationTaskStatus === operationTaskStatus.pending) {
        return (
          <Button 
            className={clsx(classes.button, classes.accordionButton)}
            startIcon={<PlayArrow />}
            onClick={()=>handleButtonClick_action({operationTaskId: task.id, isStartTime: true})}
            disabled={task.operationTaskStatus === operationTaskStatus.completed}
          >
            Resume Tasks
          </Button>
        )
      } else {
        return (
          <Button 
            className={clsx(classes.buttonSecondary, classes.accordionButton)}
            startIcon={<Pause />}
            onClick={()=>handleButtonClick_action({operationTaskId: task.id, isPause: true})}
          >
            Pause Tasks
          </Button>
        )
      }
    }
  }
  
  const renderTask = (task, index) => {
    const checkoutList = task.processListForCheckOut;
    return (
      <Accordion
        classes={{
          root: classes.accordion,
        }}
        expanded={expanded === index}
        onChange={handleButtonClick_accordion(index)}
        header={
          <div className="flex items-center">
            {task.productGrowthProcessName} Process
            <span className={classes.tasksFont}>&nbsp;&nbsp;|&nbsp;&nbsp;{task.taskCount} Tasks</span>
            {!isViewOnly && task.isMyTask && <React.Fragment><div className={classes.indicator} /><span className={clsx(classes.orange, classes.indicatorText)}>Your Task</span></React.Fragment>}
            {(isViewOnly && task.isUnAssigned) && <React.Fragment><div className={classes.indicator} /><span className={clsx(classes.orange, classes.indicatorText)}>Unassigned Task</span></React.Fragment>}
          </div>
        }
      >
        <div className="w-full">
          {task.operationTaskList.map((item, index) => {
            return (
              <Paper
                key={index}
                className={classes.subPaper}
              >
                {(!isViewOnly && item.isMyTask) && <div className={clsx(classes.indicator, classes.subIndicator)} />}
                {(isViewOnly && item.isUnAssigned) && <div className={clsx(classes.indicator, classes.subIndicator)} />}
                <div className="w-full">
                  <div className={classes.subHeader}>
                    <div className={classes.tasksTitle}>
                      {item.taskName}<span className={classes.tasksFont}>&nbsp;&nbsp;|&nbsp;&nbsp;{item.subTaskCount}&nbsp;Subtasks</span>&nbsp;
                      {!isViewOnly && <span className={clsx((item.operationTaskStatus === operationTaskStatus.inProgress && classes.orange), (item.operationTaskStatus === operationTaskStatus.completed && classes.green))}>({status[item.operationTaskStatus]})</span>}<br/>
                      <span className={classes.subTitle}>{item.taskDetails}</span>
                    </div>
                    <Button 
                      className={clsx(classes.button, classes.accordionButton)}
                      startIcon={<TableChart />}
                      onClick={()=>handleButtonClick_table(item, task.productGrowthProcessName)}
                      disabled={(item.ableToCheckOutEquipment && !item.checkedOutProcessName) || (item.ableToRetrieve && !item.retrievalProcessName)}
                    >
                      View Details
                    </Button>
                  </div>
                  <div className={classes.taskAction}>
                    {(!isViewOnly && item.isMyTask && !item.isAdditionalPersonTask)
                    ? <div>
                        Time Duration:
                        <TextField 
                          className={classes.timer}
                          variant="outlined"
                          value={item.timer ? item.timer : formatTimer(moment.duration(item.workTimer))}
                          disabled
                        />
                        {renderButton(item)}
                      </div>
                    : <div />
                    }
                    <div className="flex">
                    { item.ableToRetrieve && isViewOnly
                      ? <div className={classes.checkout}>
                          <Button 
                            className={item.retrievalProcessName ? clsx(classes.checkedoutButton, classes.accordionButton) : clsx(classes.buttonSecondary, classes.accordionButton)}
                            endIcon={<KeyboardArrowDown />}
                            onClick={handleClickRemove}
                            disabled={!checkPermission("Task Assignment", "readEdit") && item.retrievalProcessName}
                          >
                            Remove {farmConfig.cages} {item.retrievalProcessName ? ("From "+item.retrievalProcessName) : null}
                          </Button>
                          <Popper
                            open={Boolean(openRemoveDropdown)}
                            anchorEl={openRemoveDropdown}
                            placement="bottom-start"
                            transition
                            disablePortal 
                            className={classes.checkoutDropdown}
                          >
                            {({ TransitionProps }) => (
                              <Grow {...TransitionProps}>
                                <Paper className={classes.checkoutPaper}>
                                  <ClickAwayListener onClickAway={()=>setOpenRemoveDropdown(null)}>
                                    <div>
                                        {_.map(checkoutList, (process, i) => {
                                          return (
                                            <MenuItem 
                                              key={i} 
                                              className={classes.checkoutItem}
                                              onClick={()=>handleButtonClick_remove(item.id, process.id)}
                                            >
                                              Remove {farmConfig.cages} From {process.processName}
                                            </MenuItem>
                                          )                                
                                        })}
                                    </div>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      : null
                      }
                      { item.ableToCheckOutEquipment && isViewOnly
                      ? <div className={classes.checkout}>
                          <Button 
                            className={item.checkedOutProcessName ? clsx(classes.checkedoutButton, classes.accordionButton) : clsx(classes.buttonSecondary, classes.accordionButton)}
                            endIcon={<KeyboardArrowDown />}
                            onClick={handleClickCheckout}
                            disabled={!checkPermission("Task Assignment", "readEdit") && item.checkedOutProcessName}
                          >
                            Checkout {item.checkedOutProcessName}
                          </Button>
                          <Popper
                            open={Boolean(openCheckoutDropdown)}
                            anchorEl={openCheckoutDropdown}
                            placement="bottom-start"
                            transition
                            disablePortal 
                            className={classes.checkoutDropdown}
                          >
                            {({ TransitionProps }) => (
                              <Grow {...TransitionProps}>
                                <Paper className={classes.checkoutPaper}>
                                  <ClickAwayListener onClickAway={()=>setOpenCheckoutDropdown(null)}>
                                    <div>
                                        {_.map(checkoutList, (process, i) => {
                                          return (
                                            <MenuItem 
                                              key={i} 
                                              className={classes.checkoutItem}
                                              onClick={()=>handleButtonClick_checkout(item.id, process.id)}
                                            >
                                              Checkout {process.processName}
                                            </MenuItem>
                                          )                                
                                        })}
                                    </div>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      : null
                      }
                      {(!isViewOnly && item.isMyTask) &&
                      <Button 
                        className={clsx(classes.buttonSecondary, classes.accordionButton, item.operationTaskStatus===operationTaskStatus.completed && classes.completedButton)}
                        endIcon={<Checkbox className={classes.checkbox} type="outlined" checked={item.operationTaskStatus===operationTaskStatus.completed} disabled={item.operationTaskStatus != operationTaskStatus.inProgress} />}
                        onClick={()=>handleModal_warning(item.id, item)}
                        disabled={item.operationTaskStatus != operationTaskStatus.inProgress}
                      >
                        All Assigned Tasks Done
                      </Button>
                      }
                    </div>
                  </div>
                </div>
              </Paper>
            )
          })}
        </div>
      </Accordion>
    )
  }

  return (
    <React.Fragment>
      <Card 
        title="Operations Management"
        subheader={
          <div className={classes.taskSubheader}>
            <Typography>Product:&nbsp;{(prop && prop.isMixProduct) ? taskDetail.productName : (taskDetail.productName ?? taskDetail.parentProductName)}</Typography>
            {(prop && prop.isMixProduct) && <Typography>Parent Product:&nbsp;{taskDetail.parentProductName}</Typography>}
            <Typography>Work Order:&nbsp;{taskDetail.workOrderNumber}</Typography>
            <Typography>Lot ID:&nbsp;{taskDetail.lotId}</Typography>
          </div>
        }
        action={
          <React.Fragment>
            {!isViewOnly && 
              <div className={classes.taskSwitch}>
                <Typography className={classes.switchText}>My Tasks: </Typography>
                <Switch 
                  checked={Boolean(newFilter.showOwnTasks)}
                  onChange={() => setNewFilter({...newFilter, showOwnTasks: !newFilter.showOwnTasks})}
                />
              </div>
            }
            <DateTimePicker
              closeOnSelect
              clearable
              placeholder="Start Date"
              className={classes.cardActionDropdown}
              value={newFilter.date}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>setNewFilter({...newFilter, date: null})}
            />
          </React.Fragment>
        }
        cardActions={
          <Button 
            className={classes.buttonSecondary}
            onClick={()=>handleButtonClick_exit()}
          >
            Go Back
          </Button>
        }
      >
        {preHarvestTask.length > 0 && <div className={classes.harvestTitle}>Pre Harvesting<Divider className={classes.divider} /></div>}
        {preHarvestTask.map((item, index)=> {
          return <React.Fragment key={index}>{renderTask(item, index)}</React.Fragment>
        })}
        {postHarvestTask.length > 0 && <div className={classes.harvestTitle}>Post Harvesting<Divider className={classes.divider} /></div>}
        {postHarvestTask.map((item, index)=> {
          return <React.Fragment key={index}>{renderTask(item, preHarvestTask.length+index)}</React.Fragment>
        })}
      </Card>
      <Modal
        open={openWarningModal}
        onClose={() => handleModal_warning(null, null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="All Tasks Done?"
        content="Clicking all task done will end the running timer. Do you want to proceed?"
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_warning(null, null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_end()}>Confirm</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
