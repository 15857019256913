import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";

import { GetAllCapacityTrayConfig, GetCapacityTrayConfig, GetAllProduct, GetAllProductProcess } from "services/AdminPortal/CapacityService";

// initial state
const initialState = {
  configurations: [],
  configuration: {},
  product: [],
  process: []
};

const configuration = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.configuration = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.configuration[key] = action.payload[key];
      })
    },
    addDeleteRequest: (state, action) => {
      state.configurations = action.payload;
    },
    resetConfiguration: (state) => {
      state.configuration = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllCapacityTrayConfig.fulfilled]: (state, action) => {
      state.configurations = action.payload.result;
    },
    [GetCapacityTrayConfig.fulfilled]: (state, action) => {
      state.configuration = action.payload.result;
    },
    [GetAllProduct.fulfilled]: (state, action) => {
      state.product = _.orderBy(action.payload.result, ["productName"], ["asc"]);
    },
    [GetAllProductProcess.fulfilled]: (state, action) => {
      state.process = _.orderBy(action.payload.result, ["processName"], ["asc"]);
    },
  },
});

// export actions
export const { setValues, updateRequest, addDeleteRequest, resetConfiguration, reset } = configuration.actions;

// export the reducer
export default configuration.reducer;