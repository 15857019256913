// Create Edit Devices
// Device Reading Unit List
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import AddEditReadingUnits from "./AddEditReadingUnits";
import { rowsPerTable } from "config";
import { setValues } from "./store/reading";
import { GetFunctionLibrary, CreateOrUpdateFunctionLibrary } from "services/AdminPortal/DeviceService";
import { readingUnitsTableHead, combineFunctionsTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/deviceStyle.js";

const useStyles = makeStyles(styles);

export default function ReadingUnits(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const functionLibrary = useSelector(store => store.admin.device.reading.functionLibrary);
  const library = useSelector(store => store.admin.device.reading.library);
  const [order, setOrder] = React.useState({library: 'asc', combined: 'asc'});
  const [orderBy, setOrderBy] = React.useState({library: 'deviceFunctionName', combined: 'combineFunctionName'});
  const [page, setPage] = React.useState({library: 0, combined: 0});
  const [libraryId, setLibraryId] = React.useState(null);   // to know which library is selected
  const [newOrUpdate, setNewOrUpdate] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const libraries = functionLibrary.deviceFunctions;
  const libraryCount = Math.ceil((_.filter(libraries, (el) => !el.isCombine).length) / rowsPerTable);
  const combinedCount = Math.ceil((_.filter(libraries, (el) => el.isCombine).length) / rowsPerTable);

  const handleRequestSortLibrary = (e, property) => {
    const isAsc = orderBy.library === property && order.library === 'asc';
    setOrder({...order, library: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, library: property});
  };

  const handleRequestSortCombined = (e, property) => {
    const isAsc = orderBy.combined === property && order.combined === 'asc';
    setOrder({...order, combined: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, combined: property});
  };

  const handleButtonClick_edit = (id) => {
    setLibraryId(id);
    setNewOrUpdate(true);
    dispatch(setValues(libraries.find((item) => item.id === id)));
  }

  const handleModal_delete = (id) => {
    setLibraryId(id);
    setOpenDeleteModal(!openDeleteModal);
    dispatch(setValues({
      ...libraries.find((item) => item.id === id),
      isDeleted: true
    }));
  }

  const handleButtonClick_delete = () => {
    const deviceFunctions = _.filter(libraries, (item) => item.id !== library.id);
    const body = {
      ...functionLibrary,
      deviceFunctions: [...deviceFunctions, library],
    };
    dispatch(CreateOrUpdateFunctionLibrary(body))
    .then(() => {
      dispatch(GetFunctionLibrary(functionLibrary.id));
      resetState();
    });
  }

  const handleButtonClick_exit = () => {
    props.setIsReading(false);
  }
  
  const resetState = () => {
    setLibraryId(null);
    setOpenDeleteModal(false);
  }

  // componentDidMount
  React.useEffect(() => {
    if(props.id) {
      dispatch(GetFunctionLibrary(props.id));
    }
  },[newOrUpdate]);

  if (newOrUpdate) {
    return <AddEditReadingUnits id={libraryId} setLibraryId={setLibraryId} setNewOrUpdate={setNewOrUpdate} />
  } else {
    return (
      <React.Fragment>
        <Card 
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>handleButtonClick_exit()}
              >
                Go Back
              </Button>
            </React.Fragment>
          }
        >
          <Card 
            classes={{
              root: classes.cardPaper,
            }}
            title = "Device Functions"
            cardActions={
              <Button
                className={clsx(classes.buttonSecondary, classes.addButton)}
                startIcon={<AddIcon />}
                onClick={()=>setNewOrUpdate("reading")}
              >
                Add New Function
              </Button>
            }
          >
            <Table
              className={classes.table}
              header={readingUnitsTableHead}
              order={order.library}
              orderBy={orderBy.library}
              onRequestSort={handleRequestSortLibrary}
            >
              { _.orderBy(_.filter(libraries, (el) => !el.isCombine), [orderBy.library], [order.library])
              .slice(page.library * rowsPerTable, page.library * rowsPerTable + rowsPerTable)
              .map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.deviceFunctionName}</TableCell>
                    <TableCell>{item.deviceFunctionUnit}</TableCell>
                    <TableCell>{item.shortDeviceFunctionName}</TableCell>
                    <TableCell align="right">
                      <IconButton 
                        type="edit"
                        onClick={() => handleButtonClick_edit(item.id)}
                      />
                      <IconButton 
                        type="delete"
                        onClick={() => handleModal_delete(item.id )}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
            { libraryCount > 1 &&
              <Pagination 
                count={libraryCount} 
                page={page.library+1}
                onChange={(e, pageNo)=>setPage({...page, library: pageNo-1})}
              />
            }
          </Card>
          {combinedCount > 0 &&
            <Card 
              classes={{
                root: clsx(classes.cardPaper, classes.bottomCard),
              }}
              title = "Combined Functions"
            >
              <Table
                className={classes.table}
                header={combineFunctionsTableHead}
                order={order.combined}
                orderBy={orderBy.combined}
                onRequestSort={handleRequestSortCombined}
              >
                { _.orderBy(_.filter(libraries, (el) => el.isCombine), [orderBy.combined], [order.combined])
                .slice(page.combined * rowsPerTable, page.combined * rowsPerTable + rowsPerTable)
                .map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.deviceFunctionName}</TableCell>
                      <TableCell>{item.shortDeviceFunctionName}</TableCell>
                      <TableCell align="right">
                        <IconButton 
                          type="edit"
                          onClick={() => handleButtonClick_edit(item.id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
              { combinedCount > 1 &&
                <Pagination 
                  count={combinedCount} 
                  page={page.combined+1}
                  onChange={(e, pageNo)=>setPage({...page, combined: pageNo-1})}
                />
              }
            </Card>
          }
        </Card>
        <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this reading library? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
