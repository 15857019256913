import {
  button,
  buttonSecondary,
  cardRadius,
  border,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const rfidStyle = {
  button,
  buttonSecondary,

  icon,
  table,
  snCell: {
    width: "5vw",
    maxWidth: "5vw"
  },
  // Search RFID
  result,
  collapsedIcon,
  rotate,
  highlight,

  // RFID
  marginRight: {
    marginRight: "1.563vw"
  },
  paper: {
    ...cardRadius,
    border,
    padding: "2.083vw 1.979vw",
    width: "57.292vw"
  },
  // Groupings
  cardContent: {
    display: "flex",
    justifyContent: "center"
  },
  groupPaper: {
    ...cardRadius,
    border,
    padding: "2.083vw 1.979vw",
    width: "38.646vw"
  },
  paperButton: {
    marginTop: "2.083vw"
  },
  // Batch Update
  batchPaper: {
    ...cardRadius,
    textAlign: "center",
    padding: "3.021vw"
  },
  img: {
    margin: "auto",
    width: "26.406vw",
    height: "19.479vw"
  },
  batchTitle:{
    margin: "1.250vw",
    fontWeight: 500,
    fontSize: "1.771vw",
    color: primaryColor[4],
  },
  batchDesc: {
    fontSize: "1.094vw",
    color: "rgba(17, 42, 17, 0.7)",
    marginBottom: "1.250vw"
  },
  batchButton: {
    ...buttonSecondary,
    margin: "0 0.833vw",
  },
}

export default rfidStyle;