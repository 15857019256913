import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import edit from "assets/icons/orange/edit.svg";
import edit_disabled from "assets/icons/dark-grey/edit.svg";
import bin from "assets/icons/orange/delete-bin.svg";
import bin_disabled from "assets/icons/dark-grey/delete-bin.svg";
import confirm from "assets/icons/orange/confirm.svg";
import close from "assets/icons/orange/close-circle.svg";
import styles from "assets/jss/shared-components/buttonStyle.js";

const useStyles = makeStyles(styles);
export default function CustomIconButton({type, ...props}) {
  const classes = useStyles();

  switch (type) {
    case "edit":
      return (
        <Tooltip title="Edit" placement="top">
          <IconButton 
            {...props}
          >
            { props.disabled 
            ? <img className={classes.icon} src={edit_disabled} alt="edit" />
            : <img className={classes.icon} src={edit} alt="edit" />
            }
          </IconButton>
        </Tooltip>
      );
    case "delete": 
      return (
        <Tooltip title="Delete" placement="top">
          <IconButton 
            {...props}
          >
            { props.disabled 
            ? <img className={classes.icon} src={bin_disabled} alt="delete" />
            : <img className={classes.icon} src={bin} alt="delete" />
            }
          </IconButton>
        </Tooltip>
      )
    case "confirm": 
      return (
        <Tooltip title="Confirm" placement="top">
          <IconButton 
            {...props}
          >
            <img className={classes.icon} src={confirm} alt="confirm" />
          </IconButton>
        </Tooltip>
      )
    case "close": 
      return (
        <Tooltip title="Close" placement="top">
          <IconButton 
            {...props}
          >
            <img className={classes.icon} src={close} alt="close" />
          </IconButton>
        </Tooltip>
      )
    default: return null
  }
}

CustomIconButton.propTypes = {
  type: PropTypes.string,
};