import { createSlice } from "@reduxjs/toolkit";

import { GetUnassignedList, GetPurchaseOrderLookUp, GetSubSaleOrderLookUp, GetUnAssignedStatusLookUp } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  unassigned: [],
  subSaleOrders: [],
  purchaseOrders: [],
  status: [],
};

const unassigned = createSlice({
  name: "unassigned",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetUnassignedList.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.unassigned = action.payload.result.items;
    },
    [GetPurchaseOrderLookUp.fulfilled]: (state, action) => {
      state.purchaseOrders = action.payload.result.sort();
    },
    [GetSubSaleOrderLookUp.fulfilled]: (state, action) => {
      state.subSaleOrders = action.payload.result.sort();
    },
    [GetUnAssignedStatusLookUp.fulfilled]: (state, action) => {
      state.status = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = unassigned.actions;

// export the reducer
export default unassigned.reducer;