import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import styles from "assets/jss/shared-components/modalStyle.js";

const useStyles = makeStyles(styles);
export default function Alert({onCancel, onConfirm, ...props}) {
  const classes = useStyles();

  return (
    <Modal {...props} className={classes.alert}>
      <Paper className={classes.alertPaper}>
        <Typography className={classes.alertTitle}>Leave without changes?</Typography>
        <Divider />
        <Typography className={classes.alertContent}>You have not saved your current changes. Are you sure you want to leave?</Typography>
        <div className={classes.alertActions}>
          <Button
            className={classes.buttonSecondary}
            onClick={onCancel}
          >
            No
          </Button>
          <Button
            className={classes.button}
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

Alert.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};