import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
// core components
import styles from "assets/jss/shared-components/modalStyle.js";

const useStyles = makeStyles(styles);
export default function CustomModal({icon, title, content, actions, className, ...props}) {
  const classes = useStyles();
  
  return (
    <Modal {...props} className={classes.modal}>
      <Paper className={clsx(className, classes.paper)}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <Typography className={classes.title}>{title}</Typography>
        <div className={clsx(props.classes && props.classes.content, classes.content)}>
          {content}
        </div>
        <div className={classes.actions}>{actions}</div>
      </Paper>
    </Modal>
  );
}

CustomModal.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node
};