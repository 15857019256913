import { createSlice } from "@reduxjs/toolkit";

import { GetAllCurrentListingList, UploadASRSOnboarding, GetAllCages } from "services/UserPortal/ASRSService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  onboardingList: [],
  batchResult: [],
  batch: {},
  //lookup
  softzoneList: [],
  cageList: [],
};

const currentListings = createSlice({
  name: "currentListings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batch[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllCurrentListingList.fulfilled]: (state, action) => {
      state.onboardingList = action.payload.result.items;
      state.totalCount = action.payload.result.totalCount;
    },
    [UploadASRSOnboarding.fulfilled]: (state, action) => {
      state.batchResult = action.payload.result.items;
    },
    [GetAllCages.fulfilled]: (state, action) => {
      state.cageList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = currentListings.actions;

// export the reducer
export default currentListings.reducer;