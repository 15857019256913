import {
  border,
  whiteColor,
  primaryColor
} from "assets/jss/generaliseStyle.js";

const accordionStyle = {
  icon:{
    width: "1.667vw",
    height: "1.667vw",
    color: primaryColor[3],
  },
  accordion: {
    width: "100%",
    borderRadius: "0.625vw !important",
    backgroundColor: "#FAFAFA",
    color: primaryColor[3],
    "&:before": {
      display: 'none',
    },
    "&.Mui-disabled": {
      backgroundColor: "#FAFAFA",
    }
  },
  header: {
    fontSize: "1.094vw",
    fontWeight: 500,
    width: "100%"
  },
  // Card Accordion
  cardAccordion: {
    border,
    width: "100%",
    borderRadius: "0.625vw !important",
    color: primaryColor[3],
    "&:before": {
      display: 'none',
    },
    "&:hover": {
      backgroundColor: whiteColor,
    }
  },
  accordionDetails: {
    padding: 0,
    flexWrap: "wrap"
  },
  accordionContent: {
    width: "100%",
    padding: "1.302vw 1.979vw"
  },
  divider: {
    width: "100%",
  },
}

export default accordionStyle;