import { farmConfig } from "enums/Constants";

// Home
export const homeCOTableHead = [
  { id: 'customerName', align: "left", label: 'Name and Customer PO' },
  { id: 'productName', align: "left", label: 'Products' },
  { id: 'totalWeight', align: "left", label: 'Total Weight' },
  { id: 'saleOrderListForCOWiget', align: "left", label: 'Sale Order' },
]

export const homeOperationsTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'Work Order' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'actions', align: "left", label: '' },
]

// Customer Order
export const customerOrderTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'salesOrderNumber', align: "left", label: 'Sales Orders' },
  { id: 'purchaseOrderNo', align: "left", label: 'Purchase Orders' },
  { id: 'customerName', align: "left", label: 'Customer Name' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'orderType', align: "left", label: 'Order Type' },
  { id: 'saleOrderStaus', align: "left", label: 'Status' },
  { id: 'details', align: "left", label: 'Details' },
]

export const subCustomerOrderTableHead = [
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'workOrderId', align: "left", label: 'Work Orders' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'productComponent', align: "left", label: 'Product Component' },
]

export const orderDetailTableHead = [
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'isRootIncluded', align: "left", label: 'Root' },
  { id: 'quantity', align: "left", label: 'Quantity' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'packageName', align: "left", label: 'Packaging' },
  { id: 'totalNetWeight', align: "left", label: 'Total Weight' },
  { id: 'unitPrice', align: "left", label: 'Unit Price ($)' },
  { id: 'totalPrice', align: "left", label: 'Total Price ($)' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'productComponent', align: "left", label: 'Product Component' },
]

export const productionOrderTableHead = [
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'capacity', align: "left", label: 'Capacity' },
  { id: 'pricing', align: "left", label: 'Total Pricing' },
  { id: 'subSoNumber', align: "left", label: 'Sub SO Covered' },
  { id: 'process', align: "left", label: 'Current Stages of Product' },
  { id: 'customer', align: "left", label: 'Customer' },
  { id: 'utilize', align: "left", label: 'Utilized (%)' },
]

// Planning
export const planningOutcomeTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'Work Order' },
  { id: 'purchaseOrderNumber', align: "left", label: 'Purchase Order' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Order' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'productComponent', align: "left", label: 'Product Components' },
  { id: 'customerOrderWeight', align: "left", label: 'Customer Order' },
  { id: 'afterLossRateWeight', align: "left", label: 'Production Catered for Loss Rate' },
]

export const unassignedOrdersTableHead = [
  { id: 'purchaseOrderNumber', align: "left", label: 'Purchase Orders' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'productComponent', align: "left", label: 'Product Components' },
  { id: 'customerOrderWeight', align: "left", label: 'Capacity Used' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'action', align: "left", label: 'Cancel or Postpone' },
]

export const maximumOrdersTableHead = [
  { id: 'productRefNo', align: "left", label: 'Product Name' },
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'totalCapacity', align: "left", label: 'Total Capacity Per Batch' },
  { id: 'totalDays', align: "left", label: 'Total Number of Days of Production' },
  { id: 'capacity', align: "left", label: 'Capacity per Day' },
  { id: 'buffer', align: "left", label: 'Buffer Allowed (+%)' },
  { id: 'maxCapacity', align: "left", label: 'Final Maximum Capacity per Day' },
  { id: 'action', align: "left", label: '' },
]

export const excessProductionTableHead = [
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'weight', align: "left", label: 'Excess Capacity' },
]

export const conversionTableHead = [
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productID', align: "left", label: 'Product ID' },
  { id: 'weight', align: "left", label: 'Weight' },
  { id: 'weightAfterLossRate', align: "left", label: 'Weight including Loss Rate' },
  { id: 'actualProductionWeight', align: "left", label: 'Actual Production Weight' },
  { id: 'noOfTraysSeeding', align: "left", label: 'Nearest No. of Trays (Nursery)' },
  { id: 'noOfCageSeeding', align: "left", label: 'Nearest No. of ' + farmConfig.cages + ' (Nursery)' },
  { id: 'noOfTraysGermination', align: "left", label: 'Nearest No. of Trays (Growth)' },
  { id: 'noOfCageGermination', align: "left", label: 'Nearest No. of ' + farmConfig.cages + ' (Growth)' },
]

export const adjustmentTableHead = [
  { id: 'purchaseOrderNumber', align: "left", label: 'Purchase Orders' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'customerOrderWeight', align: "left", label: 'Customer Order' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'action', align: "center", label: 'Selection' },
]

export const reportOrderTableHead = [
  { id: 'purchaseOrderNumber', align: "left", label: 'Purchase Orders' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productId', align: "left", label: 'Product ID' },
  { id: 'customerOrderWeight', align: "left", label: 'Capacity Used' },
  { id: 'seedingDate', align: "left", label: 'Seeding' },
  { id: 'transplantingDate', align: "left", label: 'Transplanting' },
  { id: 'harvestingDate', align: "left", label: 'Harvesting' },
]

export const reportMissingTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'rawMaterialName', align: "left", label: 'Raw Material' },
  { id: 'rawMaterialId', align: "left", label: 'Raw Material ID' },
  { id: 'quantityRequired', align: "left", label: 'Quantity Required' },
  { id: 'link', align: "left", label: 'Link to Raw Materials Request (Select All)' },
]

export const reportMissingDetailTableHead = [
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'quantityRequired', align: "left", label: 'Quantity Required' },
  { id: 'link', align: "left", label: 'Link to Raw Materials Request' },
  { id: 'action', align: "left", label: 'Reject' },
]

export const reportReservedRMTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'Raw Material' },
  { id: 'rawMaterial_ID', align: "left", label: 'Raw Material ID' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'qtyReserve', align: "left", label: 'Reserved Quantity for Work Order' },
  { id: 'reserveDate', align: "left", label: 'Reserved Date' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'product_Id', align: "left", label: 'Product ID' },
  { id: 'processName', align: "left", label: 'Process' },
]

export const reportReservedEquipTableHead = [
  { id: 'equipmentName', align: "left", label: 'Equipment' },
  { id: 'equipmentID', align: "left", label: 'Equipment ID' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'qtyReserve', align: "left", label: 'Reserved Quantity for Work Order' },
  { id: 'reserveDate', align: "left", label: 'Reserved Date' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'product_Id', align: "left", label: 'Product ID' },
  { id: 'processName', align: "left", label: 'Process' },
]

export const reportReservedDeviceTableHead = [
  { id: 'deviceName', align: "left", label: 'Device' },
  { id: 'device_ID', align: "left", label: 'Device ID' },
  { id: 'unit', align: "left", label: 'Unit' },
  { id: 'qtyReserve', align: "left", label: 'Reserved Quantity for Work Order' },
  { id: 'reserveDate', align: "left", label: 'Reserved Date' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'product_Id', align: "left", label: 'Product ID' },
  { id: 'processName', align: "left", label: 'Process' },
]

export const readjustTableHead = [
  { id: 'rawMaterialId', align: "left", label: 'RM ID' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'quantityFrom', align: "left", label: 'From' },
  { id: 'arrow', align: "left", label: '' },
  { id: 'quantity', align: "left", label: 'To' },
]

// Raw Material
export const rawMaterialTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialRefNo', align: "left", label: 'RM ID' },
  { id: 'rawMaterialDescription', align: "left", label: 'RM Description' },
  { id: 'unitOfMeasurement', align: "left", label: 'UOM' },
  { id: 'safetyLevel', align: "left", label: 'Safety Level' },
  { id: 'maximumLevel', align: "left", label: 'Maximum Levels' },
  { id: 'currentQty', align: "left", label: 'Current Quantity' },
  { id: 'minimumOrderQuantity', align: "left", label: 'Minimum Order Quantity (MOQ)' },
  { id: 'requestLeadTime', align: "left", label: 'Request Lead Time (Business Days)' },
  { id: 'batchDetails', align: "left", label: 'Batch Details' },
]

export const batchListTableHead = [
  { id: 'batchRefNo', align: "left", label: 'Batch ID' },
  { id: 'dateOfCreation', align: "left", label: 'Date (Creation)' },
  { id: 'expiryDate', align: "left", label: 'Date (Expiry)' },
  { id: 'qtyAdd', align: "left", label: 'Qty Added' },
  { id: 'qtyUsed', align: "left", label: 'Qty Used' },
  { id: 'qtyReconciled', align: "left", label: 'Qty Reconciled' },
  { id: 'qtyLeft', align: "left", label: 'Qty Left' },
  { id: 'quantityUOM', align: "left", label: 'UOM' },
  // { id: 'totalCost', align: "left", label: 'Unit Cost' },
  { id: 'lossRatePercent', align: "left", label: 'Loss Rate (%)' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'supplierName', align: "left", label: 'Supplier Name' },
  { id: 'SupplierId', align: "left", label: 'Supplier ID' },
  { id: 'warehouseId', align: "left", label: 'Warehouse ID' },
  { id: 'rackID', align: "left", label: 'Rack ID' },
  { id: 'action', align: "right", label: '' },
]

export const rmReserveTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'WO Number' },
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialId', align: "left", label: 'RM ID' },
  { id: 'rawMaterialBatchRefNo', align: "left", label: 'Batch ID' },
  { id: 'reserveDate', align: "left", label: 'Timestamp Reserved' },
  { id: 'qtyReserve', align: "left", label: 'Qty Reserved' },
  { id: 'currentQty', align: "left", label: 'Current Qty' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'creationDate', align: "left", label: 'Date of Usage' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'unitCost', align: "left", label: 'Unit Cost' },
  { id: 'lossRate', align: "left", label: 'Loss Rate (%)' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'reservedUser', align: "left", label: 'Reserved User' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'action', align: "right", label: '' },
]

export const rmIssueTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'WO Number' },
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialId', align: "left", label: 'RM ID' },
  { id: 'rawMaterialBatchRefNo', align: "left", label: 'Batch ID' },
  { id: 'reserveDate', align: "left", label: 'Timestamp Reserved' },
  { id: 'qtyReserve', align: "left", label: 'Qty Reserved' },
  { id: 'currentQty', align: "left", label: 'Current Qty' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'creationDate', align: "left", label: 'Date of Usage' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'unitCost', align: "left", label: 'Unit Cost' },
  { id: 'lossRate', align: "left", label: 'Loss Rate (%)' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'issuedTimeStamp', align: "left", label: 'Issued Timestamp' },
  { id: 'issuedUser', align: "left", label: 'Issued User' },
  { id: 'action', align: "center", label: 'Issued' },
]

export const rmRequestTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialRefNo', align: "left", label: 'RM ID' },
  { id: 'workOrderNo', align: "left", label: 'WO Number' },
  { id: 'po', align: "left", label: 'Corresponding PO' },
  { id: 'requestedDate', align: "left", label: 'Timestamp Requested' },
  { id: 'qtyrequest', align: "left", label: 'Qty Requested' },
  { id: 'estDeliveryDate', align: "left", label: 'Est Date Delivered' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'requestByUserId', align: "left", label: 'Requested User' },
]

export const rmReceiptTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialRefNo', align: "left", label: 'RM ID' },
  { id: 'workOrderNumber', align: "left", label: 'WO Number' },
  { id: 'po', align: "left", label: 'Corresponding PO' },
  { id: 'requestDate', align: "left", label: 'Timestamp Requested' },
  { id: 'qtyrequest', align: "left", label: 'Qty Requested' },
  { id: 'estDeliveryDate', align: "left", label: 'Est Date Delivered' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'requestByUserId', align: "left", label: 'Requested User' },
  { id: 'action', align: "left", label: 'Receipt Details' },
]

export const rmCostTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialRefNo', align: "left", label: 'RM ID' },
  { id: 'process', align: "left", label: 'Process' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'quantity', align: "left", label: 'Quantity' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'unitPrice', align: "left", label: 'Unit Price' },
  { id: 'subtotal', align: "left", label: 'Subtotal ($)' },
]

export const rmReturnTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialRefNo', align: "left", label: 'RM ID' },
  { id: 'issuedAmount', align: "left", label: 'Issued Quantity' },
  { id: 'unitOfMeasurement', align: "left", label: 'UOM' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'returnAmount', align: "left", label: 'Returned Quantity' },
  { id: 'issuedDate', align: "left", label: 'Issued Date' },
  { id: 'totalUsedAmount', align: "left", label: 'Net Issued Quantity' },
  { id: 'returnedUser', align: "left", label: 'Returned User' },
  { id: 'rawMaterialBatchRefNo', align: "left", label: 'Returned Batch' },
  { id: 'action', align: "right", label: '' },
]

export const rmReconciliationTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialRefNo', align: "left", label: 'RM ID' },
  { id: 'batchRefNo', align: "left", label: 'Batch ID' },
  { id: 'adjustment', align: "left", label: 'Adjustment' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'newBalance', align: "left", label: 'New Balance for Batch' },
  { id: 'reason', align: "left", label: 'Reason' },
  { id: 'timestamp', align: "left", label: 'Timestamp' },
]

// Operations
export const overallWorkOrderTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'workOrder', align: "left", label: 'Work Order' },
  { id: 'task', align: "left", label: 'Tasks' },
  { id: 'status', align: "left", label: 'Completed' },
]

export const overallProductTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'product', align: "left", label: 'Product' },
  { id: 'task', align: "left", label: 'Tasks' },
  { id: 'status', align: "left", label: 'Completed' },
]

export const operationsCageTableHead = [
  { id: 'cageId', align: "left", label: 'Cage ID' },
  { id: 'locationId', align: "left", label: 'Location ID' },
  { id: 'retrieved', align: "left", label: 'Retrieved' },
  { id: 'completed', align: "left", label: 'Completed' },
  { id: 'remarks', align: "left", label: 'Remarks' },
]

export const productComponentTableHead = [
  { id: 'product', align: "left", label: 'Product Component' },
  { id: 'task', align: "left", label: 'Tasks' },
  { id: 'status', align: "left", label: 'Completed' },
]

export const assignmentWorkOrderTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'workOrder', align: "left", label: 'Work Order' },
  { id: 'unAssignedTaskStatus', align: "left", label: 'Assigned' },
]

export const assignmentProductTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'product', align: "left", label: 'Product' },
  { id: 'unAssignedTaskStatus', align: "left", label: 'Assigned' },
]

export const assignmentProductComponentTableHead = [
  { id: 'product', align: "left", label: 'Product Component' },
  { id: 'unAssignedTaskStatus', align: "left", label: 'Assigned' },
]

export const taskDetailTableHead = [
  { id: 'action', align: "center", label: 'Action' },
  { id: 'sequenceOrder', align: "left", label: 'Sub Task Sequence' },
  { id: 'subTaskName', align: "left", label: 'Sub Task Name' },
  { id: 'refNo', align: "left", label: 'ID (If Required)' },
  { id: 'qty', align: "left", label: 'Qty Required' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'startDate', align: "left", label: 'Start Date' },
  { id: 'timeRange', align: "left", label: 'Time Range (eg. 1000 - 1200)' },
  { id: 'frequency', align: "left", label: 'Frequency' },
  { id: 'assignedUserName', align: "left", label: 'Assigned Person' },
  { id: 'additionalPersons', align: "left", label: 'Additional Person(s) (If Required)' },
  { id: 'remark', align: "left", label: 'Remarks / Feedback' },
]

export const taskAssignmentTableHead = [
  { id: 'action2', align: "center", label: '' },
  { id: 'sequenceOrder', align: "left", label: 'Sub Task Sequence' },
  { id: 'subTaskName', align: "left", label: 'Sub Task Name' },
  { id: 'refNo', align: "left", label: 'ID (If Required)' },
  { id: 'qty', align: "left", label: 'Qty Required' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'startDate', align: "left", label: 'Start Date' },
  { id: 'timeRange', align: "left", label: 'Time Range (eg. 1000 - 1200)' },
  { id: 'frequency', align: "left", label: 'Frequency' },
  { id: 'numberOfRepeat', align: "left", label: 'No. of Repeat' },
  { id: 'assignedUserName', align: "left", label: 'Assigned Person' },
  { id: 'additionalPersons', align: "left", label: 'Additional Person(s) (If Required)' },
  { id: 'action', align: "right", label: '' },
]

// Warehouse
export const warehouseTableHead = [
  { id: 'warehouseID', align: "left", label: 'Warehouse ID' },
  { id: 'warehouseName', align: "left", label: 'Warehouse Name' },
  { id: 'capacity', align: "left", label: 'Capacity' },
  { id: 'capacityUsed', align: "left", label: 'Capacity Used' },
  { id: 'address', align: "left", label: 'Address' },
  { id: 'rawMaterial', align: "left", label: 'Raw Materials' },
  { id: 'finishedGoods', align: "left", label: 'Finished Goods' },
]

export const warehouseRMTableHead = [
  { id: 'batchRefNo', align: "left", label: 'Batch ID' },
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'dateOfCreation', align: "left", label: 'Date (Creation)' },
  { id: 'rackNumbers', align: "left", label: 'Rack ID' },
  { id: 'expiryDate', align: "left", label: 'Date (Expiry)' },
  { id: 'qtyAdd', align: "left", label: 'Qty Added' },
  { id: 'qtyUsed', align: "left", label: 'Qty Used' },
  { id: 'qtyLeft', align: "left", label: 'Qty Left' },
  { id: 'quantityUOM', align: "left", label: 'UOM' },
  // { id: 'unitCost', align: "left", label: 'Unit Cost' },
]

export const warehouseFGTableHead = [
  { id: 'dateIn', align: "left", label: 'Date In' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'workOrderNo', align: "left", label: 'Work Order' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'subSaleOrderNo', align: "left", label: 'Sales Order' },
  { id: 'quantity', align: "left", label: 'Quantity' },
  { id: 'rackRefNo', align: "left", label: 'Rack ID' },
  { id: 'palletId', align: "left", label: 'Pallet ID' },
  { id: 'expectedDateOut', align: "left", label: 'Expected Date Out' },
  { id: 'expiredDate', align: "left", label: 'Date (Expiry)' },
]

// Finished Goods
export const finishedGoodsInTableHead = [
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'workOrderNumber', align: "left", label: 'Work Orders' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'quantity', align: "left", label: 'Qty In' },
  { id: 'orderWeight', align: "left", label: 'Ordered Qty' },
  { id: 'numberOfPackets', align: "left", label: '# of Packets' },
  { id: 'orderPacket', align: "left", label: 'Ordered # of Packets' },
  { id: 'weightOfPackets', align: "left", label: 'Weight of Packets' },
  { id: 'palletId', align: "left", label: 'Pallet ID' },
  { id: 'expectedDate', align: "left", label: 'Expected Date' },
  { id: 'action', align: "center", label: 'Check In' },
]

export const finishedGoodsOutTableHead = [
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'workOrderNumber', align: "left", label: 'Work Orders' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'subSaleOrderNumber', align: "left", label: 'Sub Sales Orders' },
  { id: 'qtyIn', align: "left", label: 'Qty In' },
  { id: 'numberOfPackets', align: "left", label: 'No. of Packets' },
  { id: 'weightOfPackets', align: "left", label: 'Weight of Packets' },
  { id: 'palletId', align: "left", label: 'Pallet ID' },
  { id: 'expectedDate', align: "left", label: 'Expected Date Out' },
  { id: 'dateOfExpiry', align: "left", label: 'Date of Expiry' },
  { id: 'action', align: "left", label: 'Check Out' },
]

export const finishedGoodsListTableHead = [
  { id: 'dateIn', align: "left", label: 'Date In' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'workOrderNo', align: "left", label: 'Work Orders' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'subSaleOrderNo', align: "left", label: 'Sub Sales Orders' },
  { id: 'qtyIn', align: "left", label: 'Qty In' },
  { id: 'numberOfPacket', align: "left", label: '# of Packets' },
  { id: 'weightPerPacket', align: "left", label: 'Weight of Packets' },
  { id: 'palletId', align: "left", label: 'Pallet ID' },
  { id: 'warehouseID', align: "left", label: 'Warehouse ID' },
  { id: 'rackRefNo', align: "left", label: 'Rack ID' },
  { id: 'expectedDate', align: "left", label: 'Expected Date Out' },
  { id: 'dateOut', align: "left", label: 'Date Out' },
  { id: 'qtyOut', align: "left", label: 'Qty Out' },
  { id: 'expiredDate', align: "left", label: 'Date of Expiry' },
  { id: 'action', align: "left", label: 'Dispose Off' },
  { id: 'disposedDate', align: "left", label: 'Disposal Date' },
  { id: 'status', align: "left", label: 'Status' },
  // { id: 'action2', align: "left", label: '' },
]

export const disposalListTableHead = [
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'quantity', align: "left", label: 'Qty Disposed' },
  // { id: 'value', align: "left", label: 'Corresponding $ Value' },
]

export const disposalDetailListTableHead = [
  { id: 'workOrderRefNo', align: "left", label: 'Work Order' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'disposedBy', align: "left", label: 'Disposed User' },
  { id: 'quantityDisposed', align: "left", label: 'Qty Disposed' },
  { id: 'value', align: "left", label: 'Corresponding $ Value' },
]

export const deliveryOrderCreationTableHead = [
  { id: 'saleOrderNumber', align: "left", label: 'Sales Orders' },
  { id: 'customerName', align: "left", label: 'Customer' },
  { id: 'deliverDate', align: "left", label: 'Delivery Date' },
  { id: 'noOfSubSaleOrderCheckOut', align: "left", label: '# of Sub Orders Checked Out' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'details', align: "left", label: 'Details' },
]

export const doDetailTableHead = [
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'quantity', align: "left", label: 'Quantity' },
  { id: 'uom', align: "left", label: 'uom' },
  { id: 'root', align: "left", label: 'Root' },
  { id: 'unitPrice', align: "left", label: 'Unit Price' },
  { id: 'totalWeight', align: "left", label: 'Total Weight' },
  { id: 'orderType', align: "left", label: 'Order Type' },
  { id: 'parentOrder', align: "left", label: 'Parent Order' },
  { id: 'checkedOut', align: "left", label: 'Checked Out' },
  { id: 'doIssued', align: "left", label: 'DO Released' },
  { id: 'doNumber', align: "left", label: 'DO Number' },
]

// Delivery
export const assignmentTableHead = [
  { id: 'deliveryOrderRefNo', align: "left", label: 'Delivery Orders' },
  { id: 'saleOrderNumber', align: "left", label: 'Sales Orders' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'customerName', align: "left", label: 'Customer Name' },
  { id: 'deliveryAddress', align: "left", label: 'Customer Address' },
  { id: 'contactPerson', align: "left", label: 'Contact Person' },
  { id: 'contactDetail', align: "left", label: 'Contact Details' },
  { id: 'action', align: "center", label: 'Assignment' },
]

export const deliveryTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'deliveryTripRefNo', align: "left", label: 'Trip ID' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'driverName', align: "left", label: 'Assigned Driver' },
  { id: 'deliveryTime', align: "left", label: 'Delivery Time' },
  { id: 'deliveryTime', align: "left", label: 'Actual Delivery Time' },
  { id: 'estimatedDuration', align: "left", label: 'Estimated Duration' },
  { id: 'actualDuration', align: "left", label: 'Actual Duration' },
  { id: 'details', align: "left", label: 'Details' },
  { id: 'action', align: "right", label: '' },
]

export const deliveryDetailsTableHead = [
  { id: 'deliveryOrderRefNo', align: "left", label: 'Delivery Orders' },
  { id: 'workOrderNumber', align: "left", label: 'Work Orders' },
  { id: 'saleOrderNumber', align: "left", label: 'Sales Orders' },
  { id: 'customerName', align: "left", label: 'Customer Name' },
  { id: 'deliveryAddress', align: "left", label: 'Customer Address' },
  { id: 'deliveryOrderStatus', align: "left", label: 'Status' },
  { id: 'signedTime', align: "left", label: 'Signed Time' },
  { id: 'remarks', align: "left", label: 'Remarks' },
]

export const deliveryOrderTableHead = [
  { id: 'deliveryTripRefNo', align: "left", label: 'Trip Number' },
  { id: 'deliveryOrderRefNo', align: "left", label: 'Delivery Order Number' },
  { id: 'driverName', align: "left", label: 'Driver in Charge' },
  { id: 'deliveryOrderStatus.status', align: "left", label: 'Status' },
  { id: 'signedDateTime', align: "left", label: 'Signed Timestamp' },
  { id: 'details', align: "left", label: 'DO Details' },
  // { id: 'invoice', align: "left", label: 'Invoice' },
]

export const deliveryOrderDetailTableHead = [
  { id: 's/n', align: "left", label: 'S/N' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'product_Id', align: "left", label: 'Product ID' },
  { id: 'numberOfPacket', align: "left", label: 'Quantity' },
  { id: 'weightPerPacket', align: "left", label: 'UOM' },
  { id: 'doNumberOfPacket', align: "left", label: 'Actual Quantity' },
  { id: 'doRemarks', align: "left", label: 'Remark' },
]

export const deliveryOrderInvoiceTableHead = [
  { id: 's/n', align: "left", label: 'S/N' },
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'product_Id', align: "left", label: 'Product ID' },
  { id: 'numberOfPacket', align: "left", label: 'Quantity' },
  { id: 'price', align: "left", label: 'Price' },
  { id: 'amount', align: "left", label: 'Amount (S$)' },
]

export const searchAssignmentTableHead = [
  { id: 'deliveryOrderRefNo', align: "left", label: 'Delivery Orders' },
  { id: 'saleOrderNumber', align: "left", label: 'Sales Orders' },
  { id: 'deliveryDate', align: "left", label: 'Delivery Date' },
  { id: 'customerName', align: "left", label: 'Customer Name' },
  { id: 'deliveryAddress', align: "left", label: 'Customer Address' },
  { id: 'contactPerson', align: "left", label: 'Contact Person' },
  { id: 'contactDetail', align: "left", label: 'Contact Details' },
]

// Monitoring
export const monitoringEquipTableHead = [
  { id: 'equipmentName', align: "left", label: 'Equipment Name' },
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'detail', align: "left", label: '' },
]

export const monitoringStorageTableHead = [
  { id: 'warehouseName', align: "left", label: 'Warehouse Name' },
  { id: 'warehouseRefNo', align: "left", label: 'Warehouse ID' },
  { id: 'address', align: "left", label: 'Address' },
  { id: 'capacity', align: "left", label: 'Capacity' },
  { id: 'inventory', align: "left", label: 'Inventory' },
  { id: 'status', align: "left", label: 'Status' },
]

export const monitoringSettingsProductionTableHead = [
  { id: 'bankName', align: "left", label: farmConfig.bank },
  { id: 'humiditySensorRefNo', align: "left", label: 'Humidity' },
  { id: 'temperatureSensorRefNo', align: "left", label: 'Temperature' },
  { id: 'cO2SensorRefNo', align: "left", label: 'CO2' },
  { id: 'ledControlRefNo', align: "left", label: 'LED Control' },
  { id: 'ventilationFanRefNo', align: "left", label: 'Ventilation Fan' },
  { id: 'action', align: "right", label: '' },
]

export const monitoringSettingsStorageTableHead = [
  { id: 'warehouseName', align: "left", label: 'Warehouse ID' },
  { id: 'humiditySensorRefNo', align: "left", label: 'Humidity' },
  { id: 'temperatureSensorRefNo', align: "left", label: 'Temperature' },
  { id: 'ledControlRefNo', align: "left", label: 'LED Control' },
  { id: 'ventilationFanRefNo', align: "left", label: 'Ventilation Fan' },
  { id: 'action', align: "right", label: '' },
]

// Climate
export const deviceControlTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'deviceFunctionId', align: "left", label: 'Device Function' },
  { id: 'deviceId', align: "left", label: 'Device ID' },
  { id: 'action', align: "right", label: '' },
]

export const subDeviceControlTableHead = [
  { id: 'ruleNo', align: "left", label: 'Rule Number' },
  { id: 'daysSelected', align: "left", label: 'Days Selected' },
  { id: 'startDate', align: "left", label: 'Start Date' },
  { id: 'endDate', align: "left", label: 'End Date' },
  { id: 'turnOnTime', align: "left", label: 'Turn On Time' },
  { id: 'turnOffTime', align: "left", label: 'Turn Off Time' },
]

export const deviceHistoryTableHead = [
  { id: 'creationTime', align: "left", label: 'Date' },
  { id: 'creationTime', align: "left", label: 'Time' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'deviceName', align: "left", label: 'Sensor Name' },
  { id: 'deviceRefNo', align: "left", label: 'Sensor ID' },
  { id: 'percentage', align: "left", label: 'Percentage (%)' },
  { id: 'status', align: "left", label: 'Action' },
]

export const climateMonitoringTableHead = [
  { id: 'sensorName', align: "left", label: 'Sensor Name' },
  { id: 'sensorId', align: "left", label: 'Sensor ID' },
  { id: 'value', align: "left", label: 'Value' },
  { id: 'timestamp', align: "left", label: 'Timestamp' },
  { id: 'status', align: "left", label: 'Status of Sensor' },
]

export const sensorControlTableHead = [
  { id: 'functionName', align: "left", label: 'Function Name'},
  { id: 'shortform', align: "center", label: 'Shortform'},
  { id: 'sensors', align: "center", label: 'Sensors'},
  { id: 'controlDevice', align: "center", label: 'Control Device'},
  { id: 'camera', align: "center", label: 'Camera'},
]

export const pairingConfigTableHead = [
  { id: 'sensorDeviceID', align: "left", label: 'Sensor Device ID'},
  { id: 'controlDeviceID', align: "left", label: 'Control Device ID'},
  { id: 'action', align: "right", label: ''},
]

export const sensorSettingsTableHead = [
  { id: 'ruleNo', align: "left", label: 'Rule Number' },
  { id: 'ruleName', align: "left", label: 'Rule Name' },
  { id: 'deviceFunctionName', align: "left", label: 'Device Function' },
  { id: 'deviceRefNo', align: "left", label: 'Device ID' },
  { id: 'isActive', align: "left", label: 'Active' },
  { id: 'creationTime', align: "left", label: 'Active Timestamp' },
  { id: 'action', align: "right", label: '' },
]

export const activeAlarmTableHead = [
  { id: 'deviceId', align: "left", label: 'Device ID' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'alertTime', align: "left", label: 'Alert Timestamp' },
  { id: 'remark', align: "left", label: 'Remark' },
  { id: 'action', align: "center", label: 'Resolve' },
]

export const closedAlarmTableHead = [
  { id: 'deviceId', align: "left", label: 'Device ID' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'alertTime', align: "left", label: 'Alert Timestamp' },
  { id: 'remark', align: "left", label: 'Remark' },
  { id: 'resolveTime', align: "left", label: 'Resolved Timestamp' },
  { id: 'resolvedPerson', align: "left", label: 'Resolved Person' },
]

// HR
export const hrLogSheetTableHead = [
  { id: 'workOrderNo', align: "left", label: 'Work Order No.' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'processName', align: "left", label: 'Process' },
  { id: 'taskNo', align: "left", label: 'Task' },
  { id: 'timeSpent', align: "left", label: 'Time Spent (min)' },
  { id: 'details', align: "left", label: 'Details' },
  { id: 'action', align: "right", label: '' },
]

export const hrPastLogSheetTableHead = [
  { id: 'workOrderNo', align: "left", label: 'Work Order No.' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'processName', align: "left", label: 'Process' },
  { id: 'taskNo', align: "left", label: 'Task' },
  { id: 'timeSpent', align: "left", label: 'Time Spent (min)' },
]

export const labourCostTableHead = [
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'productProcessName', align: "left", label: 'Process' },
  { id: 'taskNumber', align: "left", label: 'Task' },
  { id: 'userName', align: "left", label: 'Username' },
  { id: 'employeeRefNo', align: "left", label: 'Employee ID' },
  { id: 'expectedDuration', align: "left", label: 'Expected Duration (min)' },
  { id: 'actualDuration', align: "left", label: 'Actual Duration (min)' },
  { id: 'expectedCost', align: "left", label: 'Expected Cost ($)' },
  { id: 'actualCost', align: "left", label: 'Actual Cost ($)' },
  { id: 'differentPercent', align: "left", label: 'Difference Bet. Expected vs Actual (%)' },
]

export const durationTableHead = [
  { id: 'startTime', align: "left", label: 'Start Time' },
  { id: 'endTime', align: "left", label: 'End Time' },
  { id: 'duration', align: "left", label: 'Duration' },
]

export const hrOverallStatusTableHead = [
  { id: 'employeeRefNo', align: "left", label: 'Employee ID' },
  { id: 'userName', align: "left", label: 'Username' },
  { id: 'noOfHoursLogged', align: "left", label: 'No. of Hours Logged' },
  { id: 'totalWorkingHour', align: "left", label: 'Total Working Hours' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'logStatus', align: "left", label: 'Log Status' },
  { id: 'remark', align: "left", label: 'Remarks' },
  { id: 'action', align: "right", label: '' },
]

// Equipment
export const equipmentReserveTableHead = [
  { id: 'workOrderNo', align: "left", label: 'Work Order No.' },
  { id: 'equipmentName', align: "left", label: 'Equipment Name' },
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'timeStampReserved', align: "left", label: 'Timestamp Reserved' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'reservedQty', align: "left", label: 'Duration Reserved' },
  { id: 'currentQty', align: "left", label: 'Current Duration' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'startDateTime', align: "left", label: 'Start Date / Time' },
  { id: 'endDateTime', align: "left", label: 'End Date / Time' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'reservedUser', align: "left", label: 'Reserved User' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'action', align: "right", label: '' },
]

export const equipmentIssueTableHead = [
  { id: 'workOrderNo', align: "left", label: 'Work Order No.' },
  { id: 'equipmentName', align: "left", label: 'Equipment Name' },
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'timeStampReserved', align: "left", label: 'Timestamp Reserved' },
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'reservedQty', align: "left", label: 'Duration Reserved' },
  { id: 'currentQty', align: "left", label: 'Current Duration' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'startDateTime', align: "left", label: 'Start Date / Time' },
  { id: 'endDateTime', align: "left", label: 'End Date / Time' },
  { id: 'location', align: "left", label: 'Location' },
  { id: 'reservedUser', align: "left", label: 'Reserved User' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'issuedTimeStamp', align: "left", label: 'Issued Timestamp' },
  { id: 'issuedUser', align: "left", label: 'Issued User' },
  { id: 'action', align: "center", label: 'Issued' },
]

export const equipmentCostTableHead = [
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'productRefNo', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'processName', align: "left", label: 'Process' },
  { id: 'taskName', align: "left", label: 'Task' },
  { id: 'equipmentName', align: "left", label: 'Equipment' },
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'actualDuration', align: "left", label: 'Actual Duration ' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'actualCost', align: "left", label: 'Actual Cost ($)' },
]

export const equipmentStatusTableHead = [
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'equipmentName', align: "left", label: 'Equipment Name' },
  { id: 'operationHourFromLastMaintenance', align: "left", label: 'Operation Hours From Last Maintenance' },
  { id: 'lastMaintenanceDate', align: "left", label: 'Last Maintenance Date' },
  { id: 'hoursLimitToScheduleForMaintenance', align: "left", label: 'Hours Limit to Schedule for Maintenance' },
  { id: 'expectedDateToRequireMaintenance', align: "left", label: 'Expected Date to Require Maintenance' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'action', align: "left", label: '' },
  { id: 'schedule', align: "left", label: 'Schedule for Maintenance' },
]

export const maintenanceScheduleTableHead = [
  { id: 'equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'equipmentName', align: "left", label: 'Equipment Name' },
  { id: 'startDate', align: "left", label: 'Start Date' },
  { id: 'endDate', align: "left", label: 'End Date' },
  { id: 'remark', align: "left", label: 'Remark' },
  { id: 'action', align: "right", label: '' },
]

// Cost and Productivity
export const overviewTableHead = [
  { id: 'categories', align: "left", label: 'Categories' },
  { id: 'subtotal', align: "left", label: 'Subtotal ($)' },
  { id: 'details', align: "left", label: 'Details' },
]

export const variableCostTableHead = [
  { id: 'name', align: "left", label: 'Name' },
  { id: 'referenceNo', align: "left", label: 'Reference ID' },
  { id: 'processName', align: "left", label: 'Stages' },
  { id: 'quantity', align: "left", label: 'Quantity' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'unitPrice', align: "left", label: 'Unit Price' },
  { id: 'subTotal', align: "left", label: 'Subtotal ($)' },
]

export const variableCostLabourTableHead = [
  { id: 'name', align: "left", label: 'Name' },
  { id: 'referenceNo', align: "left", label: 'Reference ID' },
  { id: 'processName', align: "left", label: 'Stages' },
  { id: 'taskId', align: "left", label: 'Task ID' },
  { id: 'quantity', align: "left", label: 'Quantity' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'unitPrice', align: "left", label: 'Unit Price' },
  { id: 'subTotal', align: "left", label: 'Subtotal ($)' },
]

export const fixedCostTableHead = [
  { id: 'description', align: "left", label: 'Name' },
  { id: 'costPerKg', align: "left", label: 'Cost Per Kg' },
  { id: 'weightOfVegetable', align: "left", label: 'Kg of Vegetables Harvested' },
  { id: 'subTotal', align: "left", label: 'Subtotal' },
  { id: 'action', align: "right", label: '' },
]

export const harvestTableHead = [
  { id: 'productName', align: "left", label: 'Product Name' },
  { id: 'product_Id', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'weightHarvested', align: "left", label: 'Weight Harvested (kg)' },
  { id: 'harvestedDate', align: "left", label: 'Harvested Date' },
  { id: 'unitPrice', align: "left", label: 'Unit Price Per Kg' },
  { id: 'projectedRevenue', align: "left", label: 'Projected Revenue ($)' },
]

export const cpSettingsTableHead = [
  { id: 'description', align: "left", label: 'Description' },
  { id: 'uom', align: "left", label: 'UOM' },
  { id: 'value', align: "left", label: 'Value' },
  { id: 'action', align: "right", label: '' },
]

export const revenueTableHead = [
  { id: 'description', align: "left", label: 'Product Name' },
  { id: 'value', align: "left", label: 'Product ID' },
  { id: 'unit', align: "left", label: 'Unit Price per kg' },
  { id: 'action', align: "right", label: '' },
]

// Quality Control
export const qualityControlGerminationTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'Work Order' },
  { id: 'productName', align: "left", label: 'Vegetable Name' },
  { id: 'transplantDate', align: "left", label: 'Transplant Date' },
  { id: 'submittedDate', align: "left", label: 'Date Submitted' },
  { id: 'submittedBy', align: "left", label: 'Submitted By' },
  { id: 'action2', align: "right", label: '' },
]

export const qualityControlPhenotypeTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'Work Order' },
  { id: 'productName', align: "left", label: 'Vegetable Name' },
  { id: 'noOfPlantsRecorded', align: "left", label: 'No. of Plants Recorded' },
  { id: 'submittedDate', align: "left", label: 'Date Submitted' },
  { id: 'submittedBy', align: "left", label: 'Submitted By' },
  { id: 'action2', align: "right", label: '' },
]

export const qualityControlVegetableTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'Work Order' },
  { id: 'productName', align: "left", label: 'Vegetable Name' },
  { id: 'trayPosition', align: "left", label: 'Tray Position' },
  { id: 'avrNetWeightPerPlant', align: "left", label: 'Avr Net Weight/Plant (g)' },
  { id: 'harvestDate', align: "left", label: 'Harvest Date' },
  { id: 'submittedDate', align: "left", label: 'Date Submitted' },
  { id: 'submittedBy', align: "left", label: 'Submitted By' },
  { id: 'action2', align: "right", label: '' },
]

export const qualityControlVegetableGrowthTableHead = [
  { id: 'workOrderNumber', align: "left", label: 'Work Order' },
  { id: 'productName', align: "left", label: 'Vegetable Name' },
  { id: 'trayPosition', align: "left", label: 'Tray Position' },
  { id: 'harvestDate', align: "left", label: 'Harvest Date' },
  { id: 'submittedDate', align: "left", label: 'Date Submitted' },
  { id: 'submittedBy', align: "left", label: 'Submitted By' },
  { id: 'action2', align: "right", label: '' },
]

// Report
export const reportSettingTableHead = [
  { id: 'configurationName', align: "left", label: 'Configuration Name' },
  { id: 'add', align: "center", label: 'Add To Dashboard' },
]

export const reportConfigTableHead = [
  { id: 'configurationName', align: "left", label: 'Configuration Name' },
  { id: 'vegetableType', align: "left", label: 'Vegetable Type' },
  { id: 'xVariable', align: "left", label: 'X-Axis Variable' },
  { id: 'yVariable', align: "left", label: 'Y-Axis Variable' },
  { id: 'dateRange', align: "left", label: 'Date Range' },
  { id: 'visualisationType', align: "left", label: 'Visualisation Type' },
  { id: 'action2', align: "right", label: '' },
]

// Traceability
export const traceabilityTableHead = [
  { id: 'rawMaterialName', align: "left", label: 'RM Name' },
  { id: 'rawMaterialId', align: "left", label: 'RM ID' },
  { id: 'batchNumber', align: "left", label: 'Batch Number' },
  { id: 'issuedDate', align: "left", label: 'Date Issued' },
  { id: 'supplierName', align: "left", label: 'Supplier' },
  { id: 'usedQty', align: "left", label: 'Qty Used' },
  { id: 'uom', align: "left", label: 'UOM' },
]

export const environmentDataTableHead = [
  { id: 'deviceId', align: "left", label: 'Device Id' },
  { id: 'readingDate', align: "left", label: 'Reading Date' },
  { id: 'readingDataUrl', align: "left", label: 'File' },
]

// Alarm
export const operationActiveAlarmTableHead = [
  { id: 'operation.workOrderNo', align: "left", label: 'Work Order #' },
  { id: 'operation.productRefNo', align: "left", label: 'Product ID' },
  { id: 'lotId', align: "left", label: 'Lot ID' },
  { id: 'operation.taskName', align: "left", label: 'Task / Subtask' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'action', align: "left", label: 'Resolved' },
]

export const operationClosedAlarmTableHead = [
  { id: 'operation.workOrderNo', align: "left", label: 'Work Order #' },
  { id: 'operation.productRefNo', align: "left", label: 'Product ID' },
  { id: 'operation.taskName', align: "left", label: 'Task / Subtask' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'resolvedUserName', align: "left", label: 'Resolved Person' },
  { id: 'resolveDateTime', align: "left", label: 'Resolved Timestamp' },
]

export const finishedGoodsAlarmTableHead = [
  { id: 'finishedGoods.workOrderNo', align: "left", label: 'Work Order #' },
  { id: 'finishedGoods.productRefNo', align: "left", label: 'Product ID' },
  { id: 'finishedGoods.weight', align: "left", label: 'Weight' },
  { id: 'finishedGoods.userName', align: "left", label: 'User' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
]

export const planningAlarmTableHead = [
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
]

export const equipmentActiveAlarmTableHead = [
  { id: 'equipment.equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'equipment.locationDescription', align: "left", label: 'Location of Equipment' },
  { id: 'equipment.userName', align: "left", label: 'User' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'action', align: "left", label: 'Resolved' },
]

export const equipmentClosedAlarmTableHead = [
  { id: 'equipment.equipmentRefNo', align: "left", label: 'Equipment ID' },
  { id: 'equipment.locationDescription', align: "left", label: 'Location of Equipment' },
  { id: 'equipment.userName', align: "left", label: 'User' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'resolvedUserName', align: "left", label: 'Resolved Person' },
  { id: 'resolveDateTime', align: "left", label: 'Resolved Timestamp' },
]


export const rawMaterialActiveAlarmTableHead = [
  { id: 'rawMaterial.rawMaterialName', align: "left", label: 'Raw Material Name' },
  { id: 'rawMaterial.weight', align: "left", label: 'Weight' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'action', align: "left", label: 'Resolved' },
]


export const rawMaterialClosedAlarmTableHead = [
  { id: 'rawMaterial.rawMaterialName', align: "left", label: 'Raw Material Name' },
  { id: 'rawMaterial.weight', align: "left", label: 'Weight' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'resolvedUserName', align: "left", label: 'Resolved Person' },
  { id: 'resolveDateTime', align: "left", label: 'Resolved Timestamp' },
]

export const deliveryAlarmTableHead = [
  { id: 'delivery.tripName', align: "left", label: 'Trip Name' },
  { id: 'delivery.driverName', align: "left", label: 'Driver Name' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
]

export const climateActiveAlarmTableHead = [
  { id: 'climate.locationDescription', align: "left", label: 'Device ID /Device Function' },
  { id: 'climate.locationDescription', align: "left", label: 'Location' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'action', align: "left", label: 'Resolved' },
]

export const climateClosedAlarmTableHead = [
  { id: 'climate.deviceRefNo', align: "left", label: 'Device ID /Device Function' },
  { id: 'climate.locationDescription', align: "left", label: 'Location' },
  { id: 'creationTime', align: "left", label: 'Timestamp' },
  { id: 'message', align: "left", label: 'Message' },
  { id: 'resolvedUserName', align: "left", label: 'Resolved Person' },
  { id: 'resolveDateTime', align: "left", label: 'Resolved Timestamp' },
]

// Prediction
export const predictionTableHead = [
  { id: 'gap', align: "left", label: 'Gap' },
  { id: 'umol/m2/s', align: "left", label: 'umol/m2/s' },
  { id: 'pre', align: "left", label: 'Predicted Watt' },
]

//ASRS
export const currentListingTableHead = [
  { id: 'S/N', align: "left", label: 'S/N' },
  { id: 'cageRefNo', align: "left", label: 'Cage ID' },
  { id: 'softzoneId', align: "left", label: 'Softzone ID' },
  { id: 'locationId', align: "left", label: 'Location ID' },
  { id: 'amelechID', align: "left", label: 'Amelech ID' },
  { id: 'aranetID', align: "left", label: 'Aranet ID' },
  { id: 'action', align: "right", label: '' },
]

export const uploadedOnboardingTableHead = [
  { id: 'S/N', align: "left", label: 'S/N' },
  { id: 'cageRefNo', align: "left", label: 'Cage ID' },
  { id: 'softzoneID', align: "left", label: 'Softzone ID' },
  { id: 'amelechID', align: "left", label: 'Amelech ID' },
  { id: 'aranetID', align: "left", label: 'Aranet ID' },
  { id: 'status', align: "right", label: '' },
  { id: 'action', align: "right", label: '' },
]

export const asrsMaintenanceTableHead = [
  { id: 'date', align: "left", label: 'Date' },
  { id: 'time', align: "left", label: 'Time' },
  { id: 'nameOfJob', align: "left", label: 'Maintenance Job' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'details', align: "left", label: 'Details' },
  { id: 'action', align: "right", label: '' },
]

export const asrsMovementTableHead = [
  { id: 'date', align: "left", label: 'Date' },
  { id: 'time', align: "left", label: 'Time' },
  { id: 'nameOfJob', align: "left", label: 'Movement Job' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'details', align: "left", label: 'Details' },
  { id: 'action', align: "right", label: '' },
]

export const asrsAddMaintenanceTableHead = [
  { id: 'S/N', align: "left", label: 'S/N' },
  { id: 'cageID', align: "left", label: 'Cage ID' },
  { id: 'locationID', align: "left", label: 'Location ID' },
  { id: 'action', align: "right", label: '' },
]

export const asrsAddMovementTableHead = [
  { id: 'S/N', align: "left", label: 'S/N' },
  { id: 'locationID', align: "left", label: 'Location ID' },
  { id: 'action', align: "right", label: '' },
]

export const maintenanceDetailsTableHead = [
  { id: 'S/N', align: "left", label: 'S/N' },
  { id: 'CageID', align: "left", label: 'Cage ID' },
  { id: 'locationID', align: "left", label: 'Location ID' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'timestamp', align: "left", label: 'Timestamp of Update' },
]

export const movementDetailsTableHead = [
  { id: 'S/N', align: "left", label: 'S/N' },
  { id: 'locationID', align: "left", label: 'Location ID' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'timestamp', align: "left", label: 'Timestamp of Update' },
]

export const softzoneTableHead = [
  { id: 'softzoneId', align: "left", label: 'Softzone ID' },
  { id: 'softzoneName', align: "left", label: 'Softzone Name' },
  { id: 'fromBank', align: "left", label: 'From Bank' },
  { id: 'toBank', align: "left", label: 'To Bank' },
  { id: 'fromBay', align: "left", label: 'From Bay' },
  { id: 'toBay', align: "left", label: 'To Bank' },
  { id: 'fromLevel', align: "left", label: 'From Level' },
  { id: 'toLevel', align: "left", label: 'To Level' },
  { id: 'totalLocations', align: "left", label: 'Total Locations' },
  { id: 'supportUV', align: "center", label: 'UV' },
]

export const stationTableHead = [
  { id: 'stationID', align: "left", label: 'Station ID' },
  { id: 'functionName', align: "left", label: 'Function Name' },
  { id: 'status', align: "left", label: 'Status' },
]

// AMR
export const amrListingTableHead = [
  { id: 'amrID', align: "left", label: 'AMR' },
  { id: 'batteryPercentage', align: "left", label: 'Battery Percentage' },
  { id: 'hoursLeft', align: "left", label: 'Hours Left' },
  { id: 'currentTask', align: "left", label: 'Current Task' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'details', align: "left", label: '' },
]

export const amrDetailsTableHead = [
  { id: 'upcomingTask', align: "left", label: 'Upcoming Task' },
  { id: 'date', align: "left", label: 'UT Date' },
  { id: 'time', align: "left", label: 'UT Time' },
  { id: 'creationTime', align: "left", label: 'Creation Time' },
  { id: 'details', align: "left", label: '' },
]

export const maintenanceInformationTableHead = [
  { id: 'amrID', align: "left", label: 'AMR' },
  { id: 'currentStatus', align: "left", label: 'Current Operational Status' },
  { id: 'maintenanceStatus', align: "left", label: 'Maintenance Status' },
  { id: 'parts', align: "left", label: 'Parts Required for Change' },
  { id: 'datetime', align: "left", label: 'Date & Time of Last Parts Change' },
]

export const amrTaskTableHead = [
  { id: 'amrID', align: "left", label: 'AMR' },
  { id: 'missions', align: "left", label: 'Mission Detail' },
  { id: 'state', align: "left", label: 'Mission Status' },
  { id: 'start_stamp', align: "left", label: 'Start Date/Time' },
  { id: 'finish_stamp', align: "left", label: 'End Date/Time' },
  { id: 'create_stamp', align: "left", label: 'Create Date/Time' },
  { id: 'action', align: "left", label: '' },
]

export const amrPastTaskTableHead = [
  { id: 'amrID', align: "left", label: 'AMR' },
  { id: 'taskType', align: "left", label: 'Task Type' },
  { id: 'taskDetails', align: "left", label: 'Task Details' },
  { id: 'startDateTime', align: "left", label: 'Start Date & Time' },
  { id: 'endDateTime', align: "left", label: 'End Date & Time' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'ic', align: "left", label: 'IC' },
]

export const amrFutureTaskTableHead = [
  { id: 'amrID', align: "left", label: 'AMR' },
  { id: 'mission', align: "left", label: 'Mission' },
  { id: 'delayDateTime', align: "left", label: 'Start Date/Time' },
  { id: 'creationTime', align: "left", label: 'Create Date/Time' },
  { id: 'createdBy', align: "left", label: 'Created By' },
  { id: 'action', align: "right", label: '' },
]

//flow control
export const flowControlTableHead = [
  { id: 'collapse', align: "left", label: '' },
  { id: 'flowControlDevice', align: "left", label: 'Flow Control Device' },
  { id: 'volumeOfWater', align: "left", label: 'Volume of Water (L)' },
  { id: 'actualVolumeOfWater', align: "left", label: 'Actual Volume of Water (L)' },
  { id: 'status', align: "left", label: 'Status' },
  { id: 'timeExecuted', align: "left", label: 'Time Executed' },
  { id: 'action', align: "right", label: '' },
]

export const subFlowControlTableHead = [
  { id: 'deviceIntegrationRefNo', align: "left", label: 'Device Integration Ref No' },
  { id: 'flow', align: "left", label: 'Flow' },
  { id: 'updatedTime', align: "left", label: 'Updated Time' },
]

export const flowControlSettingsTableHead = [
  { id: 'productName', align: "left", label: 'Product' },
  { id: 'recipeVolumePerTrayNursery', align: "left", label: 'Recipe Volume per Tray (Nursery)' },
  { id: 'recipeVolumePerTrayGrowth', align: "left", label: 'Recipe Volume per Tray (Growth)' },
  { id: 'nurseryRequirement', align: "left", label: 'Nursery Requirement' },
  { id: 'growthRequirement', align: "left", label: 'Growth Requirement' },
  { id: 'action', align: "right", label: '' },
]