import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
  grayColor,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  sectionHeader,
} from "assets/jss/components/commonStyle.js";

const humanResourceStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  sectionHeader,
  
  orange,

  // Search Employee
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display HR
  table,
  icon,
  ellipsis: {
    width: "6.250vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  // Add Edit Human Resource
  paper: {
    ...cardRadius,
    border,
    padding: "3.021vw"
  },
  sectionSubHeader: {
    color: grayColor[0],
    fontSize: "0.833vw",
    fontWeight: 400,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  textField: {
    margin: "0 1.250vw",
  },
  select: {
    margin: "0 1.250vw",
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.708vw 0 0",
    padding: 0
  },
  snCell: {
    width: "5vw",
    maxWidth: "5vw"
  },

  // Task Settings
  cardContent: {
    display: "flex",
    justifyContent: "center"
  },
  settingsPaper: {
    ...cardRadius,
    border,
    padding: "2.083vw 1.979vw",
    width: "38.646vw"
  },
  tableCell: {
    verticalAlign: "top"
  },
  addButton: {
    marginTop: "1.042vw !important",
  },
}

export default humanResourceStyle;