import { createSlice } from "@reduxjs/toolkit";

import { GetCageConfiguration, SaveCageConfigurations, GetCapacityTable } from "services/AdminPortal/CapacityService";

// initial state
const initialState = {
  cagesConfiguration: [],
  capacityCategories: [],
  capacityTable: [],
};

const batchCapacity = createSlice({
  name: "batchCapacity",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      state.capacityCategories = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetCageConfiguration.fulfilled]: (state, action) => {
      Object.keys(action.payload.result).forEach((key) => {
        state[key] = action.payload.result[key];
      })
    },
    [SaveCageConfigurations.fulfilled]: (state, action) => {
      Object.keys(action.payload.result).forEach((key) => {
        state[key] = action.payload.result[key];
      })
    },
    [GetCapacityTable.fulfilled]: (state, action) => {
      state.capacityTable = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, addRequest, reset } = batchCapacity.actions;

// export the reducer
export default batchCapacity.reducer;