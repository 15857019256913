import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { rmCostTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/cost";
import { GetAllRawMaterialCost } from "services/UserPortal/RawMaterialService";

import styles from "assets/jss/components/UserPortal/rawMaterialStyle.js";

const useStyles = makeStyles(styles);

export default function Cost() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lotIdList = useSelector(store => store.common.lotIdList);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const rawMaterialList = useSelector(store => store.common.allRawMaterialInfo);
  const filter = useSelector(store => store.user.rawMaterial.costRM.filter);
  const totalCost = useSelector(store => store.user.rawMaterial.costRM.totalCost);
  const totalCount = useSelector(store => store.user.rawMaterial.costRM.totalCount);
  const result = useSelector(store => store.user.rawMaterial.costRM.costs);
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, rawMaterialId: null, lotId: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('rawMaterialName');

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_delete = (key) => {
    if (key === "workOrderNo") {
      dispatch(setValues({filter: {...filter, workOrderNo: null, rawMaterialId: null, lotId: null, page: 0}}));
    } else {
      dispatch(setValues({filter: {...filter, [key]: null}}));
    }
  };
  
  const getCostList = (stopLoading) => {
    if (filter.workOrderNo) {
      dispatch(GetAllRawMaterialCost({...filter, stopLoading}));
    } else {
      dispatch(setValues({totalCount: 0, costs: [], totalCost: 0}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search, page: 0}}));
    } else {
      if (workOrderList.length) {
        dispatch(setValues({filter: {workOrderNo: workOrderList[0].workOrderNo, page: 0}}));
      } else {
        dispatch(setValues({filter: {workOrderNo: null, page: 0}}));
      }
    }
  },[workOrderList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getCostList();
    }
  },[filter]);

  useInterval(() => {
    getCostList(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page");
  return (
    <React.Fragment>
      <Card 
        title={filter.workOrderNo ? "Raw Material Cost for "+ filter.workOrderNo : "Raw Material Cost"}
        subheader={filteredKeys.length > 0
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "rawMaterialId") {
                const rm = rawMaterialList.find(({id}) => id == filter[key]);
                label = rm ? rm.rawMaterialName+" ("+rm.rawMaterialRefNo+")" : "";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the cost list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="rawMaterialId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a raw material"
                    value={renderValue(newFilter.rawMaterialId)}
                  >
                    {rawMaterialList && rawMaterialList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.rawMaterialName} ({item.rawMaterialRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={rmCostTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { result.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.rawMaterialName}</TableCell>
                  <TableCell>{item.rawMaterialRefNo}</TableCell>
                  <TableCell>{item.process}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                  <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.quantity))}</TableCell>
                  <TableCell>{item.uom}</TableCell>
                  <TableCell>{item.unitCost.toFixed(4)}</TableCell>
                  <TableCell>{formatNumbers(roundTo2Decimal(item.subtotal))}</TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colSpan={7} />
              <TableCell className={classes.bold}>Total Cost</TableCell>
              <TableCell className={classes.bold}>{formatNumbers(roundTo2Decimal(totalCost))}</TableCell>
            </TableRow>
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
    </React.Fragment>
  );
}
