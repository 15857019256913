import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllFunctionLibrary } from "services/AdminPortal/DeviceService";

// initial state
const initialState = {
  deviceTypeId: null,
  functionLibraryNames: [],
  functionLibraryId: null,
  newFunctionLibraryName: null,
  deviceFunctions: [{}],
};

// create reducer and action creators
// cannot use module due to package conflict
const functions = createSlice({
  name: "functions",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state[action.payload.key] = action.payload.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateLibraryRequest: (state, action) => {
      state.functionLibraryId = action.payload.id;
      state.deviceFunctions = action.payload.deviceFunctions;
    },
    updateFunctionsRequest: (state, action) => {
      Object.keys(action.payload.value).forEach((key) => {
        state.deviceFunctions[action.payload.index][key] = action.payload.value[key];
      })
    },
    deleteRequest: (state, action) => {
      state.deviceFunctions[action.payload.index] = action.payload.value;
    },
    deleteNewRequest: (state, action) => {
      if (state.deviceFunctions.length > 1) {
        state.deviceFunctions.splice(action.payload, 1);
      } else {
        state.deviceFunctions = [{}];
      }
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllFunctionLibrary.fulfilled]: (state, action) => {
      if (action.payload.result) {
        state.functionLibraryNames = _.orderBy(action.payload.result.items, ["functionLibraryName"], ["asc"]);
      } else {
        state.functionLibraryNames = [];
      }
    },
  },
});

// export actions
export const { setValues, updateRequest, updateLibraryRequest, updateFunctionsRequest, deleteRequest, deleteNewRequest, reset } = functions.actions;

// export the reducer
export default functions.reducer;