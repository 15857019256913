/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { amrPastTaskTableHead } from "enums/UserPortal/TableHeaders";
import { filterParam, filterUrl } from 'common/functions';
import { setValues } from "../store/overview";
import { GetAllCurrentListingList, GetAllCages } from "services/UserPortal/ASRSService";

import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function PastTaskRepository() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.amr.overview.filter);
  const totalCount = useSelector(store => store.user.amr.overview.totalCount);
  const result = useSelector(store => store.user.amr.overview.result);
  const [newFilter, setNewFilter] = React.useState({cageId: null, softzoneId: null, page: 0});

  const count = Math.ceil(totalCount / rowsPerTable);
  
  const getAllAMR = (stopLoading) => {
    dispatch(GetAllCurrentListingList({...filter, stopLoading}));
  }

  React.useEffect(() => {
    window.history.pushState({}, '', filterUrl(filter));  // no filter button
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllAMR();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllCages());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllAMR(true);
  });

  return (
    <React.Fragment>
      <div className={classes.table}>
        <Table
          header={amrPastTaskTableHead}
        >
          { result.map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.amrID}</TableCell>
                  <TableCell>{item.taskType}</TableCell>
                  <TableCell>{item.taskDetails}</TableCell>
                  <TableCell>{moment(item.startDateTime).format("DD/MM/YYYY, HHmm")}</TableCell>
                  <TableCell>{moment(item.endDateTime).format("DD/MM/YYYY, HHmm")}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.ic}</TableCell>
                </TableRow>
              </React.Fragment>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={filter.page+1}
          onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
        />
      }
    </React.Fragment>
  );
}
