import React from "react";
import PropTypes from "prop-types";
import { DateRange } from 'react-date-range';
import _ from "lodash";
import moment from "moment";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
// @mui/icons-material
import EventIcon from '@mui/icons-material/Event';
// core components
import TextField from "shared-components/TextField/TextField";

import styles from "assets/jss/shared-components/dateTimePickerStyle.js";
import { secondaryColor } from "assets/jss/generaliseStyle";

const useStyles = makeStyles(styles);

export default function DateRangePicker({errorMsg, label, value, name, clearable, onChange, onClear, placement, noButton, disabled, ...props}) {
  const classes = useStyles();
  const [date, setDate] = React.useState({key: "selection"});
  const [open, setOpen] = React.useState(false);

  const handleClickCalendar = (event) => {
    if (open && open.contains(event.target)) {
      setOpen(null);
    } else {
      setOpen(event.currentTarget);
    }
  };

  const handleCloseCalendar = () => {
    setOpen(null);
  };

  const handleOnChange = (item) => {
    setDate(item.selection);
    if (noButton) {
      onChange(item.selection);
    }
  };

  const handleOnSave = (val) => {
    onChange(val);
    setOpen(null);
  };

  const handleOnClear = () => {
    if (!disabled) {
      setOpen(null);
      setDate({endDate: new Date(null), key: "selection"});
      onClear();
    }
  }

  React.useEffect(() => {
    Object.keys(value).forEach((key) => {
      if (!value[key] || value[key] === 'Invalid date') {
        if (key === 'endDate') {
          value[key] = new Date(null);
        } else {
          value[key] = null;
        }
      } else {
        value[key] = new Date(value[key])
      }
    })
    setDate({...date, ...value});
  },[value]);

  return (
    <React.Fragment>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <TextField 
        {...props}
        id={name}
        className={clsx((date.startDate && date.endDate) ? classes.datePickerFieldValue : classes.datePickerField, props.className)}
        value={date.startDate && date.endDate ? moment(date.startDate).format("DD/MM/YYYY") + ' - ' + moment(date.endDate).format("DD/MM/YYYY") : ""}
        InputProps={{
          onClick: handleClickCalendar,
          endAdornment: 
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickCalendar}
              >
                <EventIcon className={classes.secondaryColor} />
              </IconButton>
            </InputAdornment>
        }}
        errorMsg={errorMsg}
        disabled={disabled}
        helperText={ (clearable && !_.isEmpty(_.omitBy(date, _.isNil)) && date.startDate)
          ? <Typography className={classes.helperLink} onClick={handleOnClear}>Clear</Typography>
          : errorMsg && errorMsg.field === name ? errorMsg.msg : ""
        }
      />
      <Popper 
        className={classes.popper}
        open={Boolean(open)} 
        anchorEl={open} 
        placement={placement ?? "bottom-end"}
      >
        <Paper>
          <ClickAwayListener onClickAway={()=>handleCloseCalendar()}>
            <div className={classes.paperContainer}>
              <DateRange
                minDate={props.minDate}
                maxDate={props.maxDate}
                editableDateInputs={true}
                ranges={[date]}
                rangeColors={[secondaryColor[0]]}
                color={secondaryColor[0]}
                onChange={handleOnChange}
                moveRangeOnFirstSelection={false}
              />
              {!noButton &&
                <Button 
                  className={classes.button}
                  onClick={() => handleOnSave(date)}
                >
                  OK
                </Button>
              }
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </React.Fragment>
  )
}

DateRangePicker.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  noButton: PropTypes.bool,
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
  placement: PropTypes.string,
  errorMsg: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};