import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllCostType, GetAllCostCategory, GetAllCostSetting, GetCostSettingById, GetAllUnitOfMeasurement } from "services/UserPortal/CostProductivityService";

// initial state
const initialState = {
  totalCount: 0,
  settings: [],
  setting: {},
  // Lookup
  costTypes: [],
  costCategories: [],
  uomList: []
};

const cpSettings = createSlice({
  name: "cpSettings",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllCostType.fulfilled]: (state, action) => {
      state.costTypes = action.payload.result;
    },
    [GetAllCostCategory.fulfilled]: (state, action) => {
      state.costCategories = action.payload.result;
    },
    [GetAllCostSetting.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.settings = action.payload.result.items;
    },
    [GetCostSettingById.fulfilled]: (state, action) => {
      state.setting = {..._.omit(action.payload.result, ["costCategory"]), costCategoryId: action.payload.result.costCategory.id};
    },
    [GetAllUnitOfMeasurement.fulfilled]: (state, action) => {
      state.uomList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExclusionRequest, reset } = cpSettings.actions;

// export the reducer
export default cpSettings.reducer;