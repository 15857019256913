import {
  icon_64,
  cardRadius,
  border,
  button,
  buttonSecondary,
  backgroundColor,
  grayColor,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const qualityControlStyle = {
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  link,
  tag,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  filterSwitch: {
    display: "flex",
    marginRight: "1.042vw",
    alignItems: "center"
  },
  switchText: {
    whiteSpace: "nowrap",
    marginRight: "0.521vw",
    color: primaryColor[3]
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  // Main
  mainTable: {
    '& thead > tr > th:last-child': {
      width: "11vw",
      minWidth: "11vw"
    },
  },

  // Add Edit
  formTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "0.521vw",
    marginTop: "1.042vw"
  },
  formLabel: {
    marginLeft: "1.250vw",
    marginRight: "0.521vw",
    color: primaryColor[4],
    alignSelf: "center",
    minWidth: "9.063vw"
  },
  formLabel2: {
    marginLeft: "1.042vw",
    marginRight: "0.521vw",
    color: primaryColor[4],
    alignSelf: "center",
    width: "5.208vw",
    minWidth: "5.208vw"
  },
  formLabel3: {
    margin: "-1.823vw 1.250vw 0.521vw",
  },
  formLabel4: {
    marginBottom: "1.250vw",
    color: grayColor[0],
  },
  grid: {
    display: "flex",
    alignItems: "center",
  },
  gridTop: {
    marginTop: "1.667vw"
  },
  textFieldMargin: {
    marginRight: "1.042vw"
  },
  textFieldSmall: {
    width: "4.688vw",
    minWidth: "4.688vw"
  },
  textFieldSmall2: {
    width: "6.354vw",
    minWidth: "6.354vw"
  },
  textFieldSmall3: {
    width: "4.167vw",
    minWidth: "4.167vw",
    marginBottom: "1.250vw",
    "& div": {
      backgroundColor: whiteColor + " !important"
    }
  },
  textFieldSmall4: {
    width: "6.354vw",
    minWidth: "6.354vw",
    "& div": {
      backgroundColor: whiteColor + " !important"
    }
  },
  coloredTextField: {
    "& div": {
      backgroundColor: backgroundColor + " !important"
    }
  },
  paper: {
    ...cardRadius,
    border,
    padding: "1.250vw",
    paddingBottom: "0",
    textAlign: "center",
  },
  paper2: {
    ...cardRadius,
    border,
    padding: "1.250vw",
    textAlign: "center",
  },
  padding: {
    display: "flex",
    paddingLeft: "1.250vw",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2.604vw",
  },
  emptyLabel: {
    fontSize: "1.094vw",
    textAlign: "center",
    color: "rgba(19, 50, 43, 0.4)"
  },
  hcImageDiv: {
    position: "relative",
    minWidth: "6.354vw",
    maxWidth: "6.354vw"
  },
  hcImageContainer: {
    border,
    position: "relative"
  },
  hcImage: {
    width: "100%",
    aspectRatio: "1 / 1",
    backgroundColor: primaryColor[1]
  },
  dropZone: {
    marginTop: "1.563vw",
    width: "100%",
    height: "26.042vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor,
  },
  activeDropZone: {
    backgroundColor: primaryColor[1],
  },
  imageContainer: {
    display: "flex",
  },
  imageList: {
    height: "26.042vw",
    overflow: "overlay"
  },
  leftContainer: {
    width: "26.823vw",
    marginRight: "2.083vw"
  },
  imageNameList: {
    display: "flex",
  },
  imageName: {
    width: "80%",
    padding: "0.521vw",
    color: secondaryColor[0],
    overflow: "overlay",
  },
  image: {
    position:"relative"
  },
  imageBorder: {
    border: "0.052vw solid #FFFFFF",
    height: "19.271vw",
    width: "19.271vw",
    aspectRatio: "1 / 1"
  },
  deleteImage: {
    fontSize:"1.042vw",
    minWidth: "unset",
    maxWidth: "1.563vw",
    width: "1.563vw",
    height: "1.563vw",
    position: "absolute",
    background: "white",
    color: "black",
    top:"0vw",
    right: "0vw",
    cursor: "pointer",
  },
  // Report
  gridEnd: {
    textAlign: "right"
  },
  gridMargin: {
    marginLeft: "1.042vw",
  },
  printTitle: {
    fontSize: "14px",
    fontWeight: 500,
  },
  printLabel: {
    fontSize: "12px",
    width: "100px",
  },
  printLabel2: {
    fontSize: "10px",
    marginTop: "-28px",
    marginBottom: "14px"
  },
  printLabel3: {
    fontSize: "10px",
    marginTop: "-14px",
    marginBottom: "8px"
  },
  printFont: {
    fontSize: "12px",
  },
  printText: {
    "& div": {
      backgroundColor: whiteColor + " !important"
    }
  },
  printImage: {
    border: "0.052vw solid #FFFFFF",
    aspectRatio: "1 / 1"
  },
  printFooter: {
    margin: "auto"
  },
  reportImg: {
    cursor: "zoom-in"
  },
  zoomModalContent: {
    marginRight: 0,
    paddingRight: 0,
    position: 'relative',
  },
  zoomModal: {
    padding: "0.833vw",
    width: "52.083vw",
  },
  zoomImage: {
    width: "100%",
    maxHeight: "36.458vw",
    objectFit: "contain"
  },
  carouselButton: {
    left: 0,
    top: 0,
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-0.833vw',
    marginRight: '-0.833vw',
    position: "absolute",
    height: "100%",
    width: "auto",
    padding: "0.833vw",
    fontWeight: 500,
    fontSize: "0.938vw",
    transition: "0.6s ease",
    borderRadius: "0 0.156vw 0.156vw 0",
    userSelect: "none",
    "&:hover:not(:disabled)": {
      cursor: "pointer",
      backgroundColor: '#DDD',
    }
  },
  next: {
    left: 'unset',
    right: 0,
    borderRadius: "0.156vw 0 0 0.156vw",
  },
}

export default qualityControlStyle;