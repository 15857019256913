import {
  secondaryColor
} from "assets/jss/generaliseStyle.js";

const dateTimePickerStyle = {
  secondaryColor: {
    color: secondaryColor[0]
  },
  label: {
    margin: "1.563vw 0 0.365vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  helperLink: {
    position: "absolute",
    right: 0,
    color: secondaryColor[0],
    cursor: "pointer",
    fontSize: "0.729vw"
  },
  paperContainer: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    color: secondaryColor[0],
    alignSelf: "self-end",
    marginBottom: "1.042vw"
  },
  datePickerField: {
    width: "11.771vw",
  },
  datePickerFieldValue: {
    minWidth: "15.625vw"
  },
  popper: {
    zIndex: 9999
  }
};

export default dateTimePickerStyle;
  