import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
// core components
import DateTabs from "shared-components/Tabs/DateTabs";
import Select from "shared-components/Select/Select";

import { renderValue } from "common/functions";
import { useInterval } from 'common/utils';
import { updateRequest } from "../store/climateMonitoring"
import { GetClimateMonitoring } from "services/UserPortal/ClimateService";

import { graphColors as colors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);
export default function Graph(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.climate.climateMonitoring.result);
  const deviceUnits = useSelector(store => store.user.climate.climateMonitoring.deviceUnits);
  const [config, setConfig] = React.useState({chart: { zoomType: 'x' }});
  const [isLoading, setIsLoading] = React.useState(false);
  const [hourTab, setHourTab] = React.useState(3);
  
  const getClimateMonitoring = (stopLoading) => {
    setIsLoading(!stopLoading);
    if (props.bank && (props.date.startDate != "Invalid Date" && props.date.endDate != "Invalid Date") && props.filter.deviceId.length) {
      const param = {...props.date, bankId: props.bank.id, deviceIds: props.filter.deviceId , unit: props.filter.unit};
      dispatch(GetClimateMonitoring({...param, stopLoading: true}));
    } else {
      dispatch(updateRequest({result: [], heatMapResult: []}))
    }
  }

  const handleOnChange_select = (e) => {
    props.setFirstLoad(true);
    props.setFilter({...props.filter, [e.target.name]: e.target.value});
  };

  const handleOnChange_hourTab = (e, value) => {
    if (value === 0) {  // 1hr
      props.setDate({startDate: moment().subtract(1,'hours').format(), endDate: moment().format()});
    } else if (value === 1) {  // 3hr
      props.setDate({startDate: moment().subtract(3, 'hours').format(), endDate: moment().format()});
    } else if (value === 2) {  // 12hr
      props.setDate({startDate: moment().subtract(12,'hours').format(), endDate: moment().format()});
    } else if (value === 3) {  // 24hr
      props.setDate({startDate: moment().subtract(24,'hours').format(), endDate: moment().format()});
    }
    props.setFirstLoad(true);
    setHourTab(value);
  };
  
  React.useEffect(() => {
    getClimateMonitoring();
  },[props.date, props.filter]);
  
  React.useEffect(() => {
    props.setDate({startDate: moment().subtract(24,'hours').format(), endDate: moment().format()});
  },[]);
  
  React.useEffect(() => {
    if (result) {
      setIsLoading(false);
      props.setFirstLoad(false);
      setConfig({
        chart: {
          zoomType: 'x',
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          gridLineWidth: 1
        },
        yAxis: {
          title: {
            text: props.unit
          },
          minorTickInterval: 'auto',
        },
        time: {
          useUTC: false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: props.firstLoad,
            label: {
              connectorAllowed: false
            },
            marker: {
              symbol: "circle"
            }
          }
        },
        series: result.map((device, i) => {
          if (device) {
            return {
              data: moment(props.date.endDate).diff(moment(props.date.startDate), 'days') > 7
              ? device.sensorReadingData && device.sensorReadingData.map(({readingDate, averageValue}) => 
                  [moment(readingDate).valueOf(), averageValue]
                )
              : (device.sensorReadingData.length) 
                ? _.uniqWith(_.flatten(device.sensorReadingData.map((item) => {
                    return(
                      item.readingDataList.map(({readingDate, averageValue}) => 
                        [moment(readingDate).valueOf(), averageValue]
                      )
                    )
                  })), _.isEqual)
                : [],
              name: device.deviceRefNo,
              color: colors[i]
            }
          }
        })
      })
    }
  },[result]);
  
  useInterval(() => {
    getClimateMonitoring(true);
  });

  if (props.filter.deviceId.length === 0) {
    return (<Typography className={classes.graphText}>Select device to view graph</Typography>);
  }

  return (
    <div className={classes.graphContainer}>
      <div className={classes.dateContainer}>
        <DateTabs setDate={props.setDate} date={props.date} hourTab={hourTab} onChange={()=>setHourTab(false)} />
      </div>
      <Tabs 
        className={classes.dateTab}
        classes={{
          indicator: classes.dateIndicator
        }}
        value={hourTab} 
        onChange={handleOnChange_hourTab}
      >
        <Tab className={hourTab === 0 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<1hr" />
        <Tab className={hourTab === 1 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<3hr" />
        <Tab className={hourTab === 2 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<12hr" />
        <Tab className={hourTab === 3 ? clsx(classes.dateLabel, classes.selectedHourTab) : classes.dateLabel} label="<24hr" />
      </Tabs> 
      <div className={classes.content}>
        <Typography className={classes.smallText}>* Click and drag in the plot area to zoom in</Typography>
        <div className={classes.unitSelectContainer}>
          <Typography className={classes.label}>Unit: </Typography>
          <div className={classes.unitSelect}>
            <Select 
              name="unit"
              onChange={(e)=>handleOnChange_select(e)}
              value={renderValue(props.filter.unit)}
              placeholder={deviceUnits.length ? "Select a Unit" : "No Unit"}
            >
              {deviceUnits.map((item, index) => {
                return <MenuItem key={index} value={item}>{item}</MenuItem>;
              })} 
            </Select>
          </div>
        </div>
      </div>
      {isLoading 
      ? <CircularProgress
          variant="indeterminate"
          className={classes.progress}
          thickness={6}
        /> 
      : (config.series && config.series.every(({data}) => data.length)) 
        ? <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ className: classes.highChart }} />
        : <Typography className={classes.graphText}>No Data</Typography>
      }
      <div className={classes.legend}>
        {result && result.map((item, index) => {
          if (item) {
            return (
              <React.Fragment key={index}>
                <div className={clsx(classes.colorBox, classes.legendColor)} />{item.deviceRefNo}
              </React.Fragment>
            )
          }
        })}
      </div>
    </div>
  );
}
