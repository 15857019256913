import {
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const adminLogStyle = {
  buttonSecondary,
  table,
  icon,

  // Search admin log
  result,
  collapsedIcon,
  rotate,
  highlight,
}

export default adminLogStyle;