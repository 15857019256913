import {
  icon_24,
  cardRadius,
  whiteColor,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  link,
} from "assets/jss/components/commonStyle.js";

const timelineStyle = {
  link,
  icon_24,
  cardRadius,
  recipeTable: {
    // height: "31.771vw",
    maxHeight: "31.771vw",
    overflow: "overlay",
    position: "relative"
  },
  emptyCell: {
    width: "3.333vw",
    minWidth: "3.333vw",
    maxWidth: "3.333vw"
  },
  hidden: {
    padding: 0,
    color: whiteColor,
  },
  tableHead: {
    fontSize: "0.625vw !important",
    opacity: 0.5,
    width: "9.375vw",
    minWidth: "9.375vw"
  },
  tableRow: {
    backgroundColor: "transparent !important"
  },
  tableCell: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  statusCell: {
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
  },
  chip: {
    borderRadius: "1.250vw",
    padding: "0.625vw 1.016vw",
    fontSize: "0.729vw",
    fontWeight: 500
  },
  mixProduct: {
    fontWeight: 500,
    fontSize: "0.833vw",
    // paddingBottom: 0,
    paddingLeft: "0.521vw !important",
  },
  rowTitle: {
    position: "sticky",
    left: "1.016vw",
    paddingRight: "1.016vw"
  },
  timelineHeader: {
    fontWeight: 500,
    fontSize: "0.833vw",
    backgroundColor: whiteColor,
    position: "fixed",
    padding: "0 0.521vw",
    marginLeft: "0.833vw",
    marginTop: "-0.521vw",
  },
  timelineBorder: {
    ...cardRadius,
    borderCollapse: "separate",
    padding: "0 0.208vw",
    border: "0.104vw solid"
  },
  timelineLabel: {
    backgroundColor: whiteColor,
    marginBottom: "0.625vw",
    zIndex: 1,
    fontWeight: 500,
    fontSize: "0.833vw"
  },
  tableMargin: {
    marginBottom: "1.042vw"
  },
  status: {
    display: "flex",
    position: "absolute",
    marginTop: "-0.833vw",
    transform: "translateX(-8.333vw)",
    border: "0.052vw solid "+primaryColor[3],
    borderRadius: "1.198vw",
    padding: "0.313vw 0.833vw",
    "& img": {
      margin: "0 0.521vw"
    },
    width: "max-content"
  },
  arrow: {
    transform: "translate(750%, 250%)",
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: whiteColor,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
      transformOrigin: "100% 0",
      border: "solid "+primaryColor[3],
      borderWidth: "0 1px 1px 0",
    }
  },
  seeding: {
    backgroundColor: "#CC81B8",
    color: "#5B254C",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#DAA4CC",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  germination: {
    backgroundColor: "#ED86B5",
    color: "#B21A5F",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#F4B4D1",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  harvesting: {
    backgroundColor: "#F5A35C",
    color: "#603006",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#F8BE8C",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  packaging: {
    backgroundColor: "#F7DE64",
    color: "#615105",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#F9E894",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  cutting: {
    backgroundColor: "#D1DE6C",
    color: "#4C5313",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#DEE897",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  transferGrowth: {
    backgroundColor: "#94CE85",
    color: "#474852",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#B2DCA8",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  transfer: {
    backgroundColor: "#6AAFB9",
    color: "#214145",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#8CC2C9",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  growth: {
    backgroundColor: "#93D1DE",
    color: "#18454E",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#B9E1EA",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
  nursery: {
    backgroundColor: "#CCCCCC",
    color: "#4D4D4D",
    // "&:hover:not(:disabled)": {
    //   backgroundColor: "#DEDEDE",
    //   textDecoration: "underline",
    //   cursor: "pointer"
    // }
  },
}

export default timelineStyle;