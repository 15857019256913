import {
  buttonSecondary,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

const utilStyle = {
  buttonSecondary,
  paper: {
    margin: "0 15vw 1.979vw",
    color: primaryColor[3],
  },
  bankImage: {
    margin: "auto",
    marginBottom: "1.250vw"
  },
  bankDetails: {
    marginBottom: "2.813vw"
  }
}

export default utilStyle;