import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllBanks, GetFunctionLibraryList, GetAllSensorControlDeviceFunction, 
  GetWorkOrderList, GetEquipmentList, GetProductList, GetProductListByWorkOrderId,
  GetProductGrowthProcessList, GetProductTaskList, GetUserList, 
  GetAllUserUOMs, GetNotYetStartedWorkOrderList, GetAllRawMaterialInfo, GetRawMaterialList, GetRawMaterialBatchList,
  GetAllSupplierInfo, GetAllWarehouseInfo, GetAllRawMaterialInfoForMapping, GetAllLotIdLookup } from "services/UserPortal/CommonLookupService";

// initial state
const initialState = {
  bankList: [],
  workOrderList: [],
  userList: [],
  uomList: [],
  allRawMaterialInfo: [],
  supplierList: [],
  warehouseList: [],
  equipmentList: [],
  lotIdList: [],
  // will change
  deviceList: [],
  libraryList: [],
  functionList: [],
  productList: [],
  processList: [],
  taskList: [],
  rawMaterialList: [],
  batchList: [],
  unstartedWorkOrderList: [],
  saleOrderList: [],
};

// create reducer and action creators
const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    resetValues: (state) => {
      state.deviceList = [];
      state.libraryList = [];
      state.functionList = [];
      state.productList = [];
      state.processList = [];
      state.taskList = [];
      state.unstartedWorkOrderList = [];
      state.saleOrderList = [];
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllBanks.fulfilled]: (state, action) => {
      state.bankList = _.orderBy(action.payload.result, ["bankName"], ["asc"]);
    },
    [GetFunctionLibraryList.fulfilled]: (state, action) => {
      state.libraryList = _.orderBy(action.payload.result, ["functionLibraryName"], ["asc"]);
    },
    [GetAllSensorControlDeviceFunction.fulfilled]: (state, action) => {
      state.functionList = _.orderBy(action.payload.result, ["deviceFunctionName"], ["asc"]);
    },
    [GetWorkOrderList.fulfilled]: (state, action) => {
      state.workOrderList = _.orderBy(action.payload.result, ["workOrderNo"], ["desc"]);
    },
    [GetEquipmentList.fulfilled]: (state, action) => {
      state.equipmentList = _.orderBy(action.payload.result, ["equipmentRefNo", "equipmentName",], ["asc", "asc"]);
    },
    [GetAllLotIdLookup.fulfilled]: (state, action) => {
      state.lotIdList = action.payload.result.sort();
    },
    [GetProductList.fulfilled]: (state, action) => {
      state.productList = _.orderBy(action.payload.result, ["productName"], ["asc"]);
    },
    [GetProductListByWorkOrderId.fulfilled]: (state, action) => {
      state.productList = _.orderBy(action.payload.result, ["productName"], ["asc"]);
    },
    [GetProductGrowthProcessList.fulfilled]: (state, action) => {
      state.processList = action.payload.result;
    },
    [GetProductTaskList.fulfilled]: (state, action) => {
      state.taskList = action.payload.result;
    },
    [GetUserList.fulfilled]: (state, action) => {
      state.userList = _.orderBy(action.payload.result, ["userName"], ["asc"]);
    },
    [GetAllUserUOMs.fulfilled]: (state, action) => {
      state.uomList = action.payload.result;
    },
    [GetNotYetStartedWorkOrderList.fulfilled]: (state, action) => {
      state.unstartedWorkOrderList = _.orderBy(action.payload.result, ["workOrderNo"], ["asc"]);
    },
    [GetAllRawMaterialInfo.fulfilled]: (state, action) => {
      state.allRawMaterialInfo = _.orderBy(action.payload.result, ["rawMaterialName"], ["asc"]);
    },
    [GetRawMaterialList.fulfilled]: (state, action) => {
      state.rawMaterialList = _.orderBy(action.payload.result, ["rawMaterialName"], ["asc"]);
    },
    [GetRawMaterialBatchList.fulfilled]: (state, action) => {
      state.batchList = _.orderBy(action.payload.result, ["batchRefNo"], ["asc"]);
    },
    [GetAllSupplierInfo.fulfilled]: (state, action) => {
      state.supplierList = _.orderBy(action.payload.result, ["supplierName"], ["asc"]);
    },
    [GetAllWarehouseInfo.fulfilled]: (state, action) => {
      state.warehouseList = _.orderBy(action.payload.result, ["warehouseName"], ["asc"]);
    },
    [GetAllRawMaterialInfoForMapping.fulfilled]: (state, action) => {
      state.allRawMaterialInfoForMapping = _.orderBy(action.payload.result, ["rawMaterialName"], ["asc"]);
    },
  },
});

// export actions
export const { setValues, resetValues, reset } = common.actions;

// export the reducer
export default common.reducer;