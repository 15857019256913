import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
// core components
import styles from "assets/jss/shared-components/checkboxStyle.js";

const useStyles = makeStyles(styles);

export default function Numbering({value, ...props}) {
  const classes = useStyles();

  return (
    <div 
      {...props} 
      className={props.disabled ? classes.disabled : (value ? clsx(classes.numberBox, classes.checkedNumber) : classes.numberBox)}
      onClick={()=>!props.disabled && props.onClick()}
    >
      {value ? <span>{value}</span> : null}
    </div>
  );
}

Numbering.propTypes = {
  value: PropTypes.string,
};