import {
    button,
    buttonSecondary,
    grayColor,
} from "assets/jss/generaliseStyle.js";

import {
  table,
} from "assets/jss/components/commonStyle.js";
  
  const sustainabilityStyle = {
    table,
    button,
    buttonSecondary,

    mainTable: {
      '& thead > tr > th:first-child': {
        width: "7.729vw",
        minWidth: "7.729vw",
        maxWidth: "7.729vw",
      },
      '& thead > tr > th:not(:first-child)': {
        width: "fit-content",
        minWidth: "fit-content",
        whiteSpace: "nowrap"
      },
    },
    accordion: {
      margin: "unset !important",
      "&:not(:first-child)": {
        marginTop: "1.25vw !important",
      },
    },
    gridContainer: {
      marginTop: "1vw !important",
    },
    gridItem: {
      display: "flex",
      alignItems: "center"
    },
    action: {
      display: "flex",
      justifyContent: "flex-end",
    },
    datepicker: {
      width: "fit-content"
    },
    subtitle: {
      color: grayColor[0],
      fontSize: "0.833vw",
      marginTop: "1vw",
      textAlign: "right"
    },
    highChart: {
      display: "flex",
      justifyContent: "center",
      // height: "31.250vw",
      width: "100%",
      overflow: "visible !important"
    },
  }
  
  export default sustainabilityStyle;