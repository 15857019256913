import { createSlice } from "@reduxjs/toolkit";

import { GetAllFinishedGoodsByWarehouseId, GetProductListLookUp, GetSaleOrderLookUp } from "services/UserPortal/WarehouseService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  finishedGoods: [],
  // Lookup
  productList: [],
  saleOrderList: []
};

const fgWarehouse = createSlice({
  name: "fgWarehouse",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllFinishedGoodsByWarehouseId.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.finishedGoods = action.payload.result.items;
    },
    [GetProductListLookUp.fulfilled]: (state, action) => {
      state.productList = action.payload.result;
    },
    [GetSaleOrderLookUp.fulfilled]: (state, action) => {
      state.saleOrderList = action.payload.result.sort();
    },
  },
});

// export actions
export const { setValues, reset } = fgWarehouse.actions;

// export the reducer
export default fgWarehouse.reducer;