import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
// @mui/icons-material components
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// core components
import styles from "assets/jss/shared-components/tableStyle.js";
import TableCell from "./TableCell";

const useStyles = makeStyles(styles);
export default function CustomTable({className, header, children, orderBy, order, onRequestSort, headerProps, ...props}) {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <Table {...props} className={clsx(className, props.expandable ? classes.expandableTable : classes.table)}>
      { header &&
      <TableHead>
        <TableRow
          classes={{
            root: props.expandable ? classes.expandableHeadRow : classes.headRow
          }}
        >
          {header.map((headCell) => {
            if (!headCell.hidden) {
              return (
                <TableCell
                  key={headCell.id}
                  className={headCell.id === "action" ? clsx(headerProps, classes.headFont, classes.headButton) : clsx(headerProps, classes.headFont)}
                  align={headCell.align}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {onRequestSort && headCell.id !== "action"
                  ? <TableSortLabel
                      IconComponent={KeyboardArrowDownIcon}
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}{headCell.required && <span className={classes.required}>&nbsp;*</span>}
                    </TableSortLabel>
                  : <React.Fragment>{headCell.label}{headCell.required && <span className={classes.required}>&nbsp;*</span>}</React.Fragment>
                  }
                </TableCell>
              )
            }
          })}
        </TableRow>
      </TableHead>
      }
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
}

CustomTable.propTypes = {
  header: PropTypes.array,
  children: PropTypes.node,
  orderBy: PropTypes.string, 
  order: PropTypes.oneOf(['asc', 'desc']), 
  onRequestSort: PropTypes.func,
  headerProps: PropTypes.string
};