import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { GetAllEnergyMonitoring, GetAllEnergyDevices } from "services/UserPortal/SmartMeteringService";
import { GetData, GetDBNames } from "services/UserPortal/SmartMeteringFlagshipService";

// initial state
const initialState = {
  filter: {startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")},
  result: {},
  // lookup
  deviceList: [],
  databaseList: [],
};

const smOverview = createSlice({
  name: "smOverview",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    // updateRequest: (state, action) => {
    //   Object.keys(action.payload).forEach((key) => {
    //     state.batch[key] = action.payload[key];
    //   })
    // },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllEnergyMonitoring.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
    [GetData.fulfilled]: (state, action) => {
      state.result = action.payload.result;
    },
    // lookup
    [GetAllEnergyDevices.fulfilled]: (state, action) => {
      state.deviceList = action.payload.result;
    },
    [GetDBNames.fulfilled]: (state, action) => {
      state.databaseList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExcessOrderRequest, reset } = smOverview.actions;

// export the reducer
export default smOverview.reducer;