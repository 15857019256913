// Create Edit Product Category Type
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setDirty, setLoading } from "store/general";
import { isShortform, isPositiveDecimal } from "common/validations";
import { GetFinishedProductForMix, GetProductGroupByCategoryID, CreateOrUpdateProductCategoryType, CreateOrUpdateProductGroup, GetProductCategoryTypeByID } from "services/AdminPortal/ProductService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditProductCategoryTypeSetting(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct); //to see if its mixed or not selectedProductCategory.combinationProduct
  const finishedCategoryTypeList = useSelector(store => store.admin.product.lookup.finishedCategoryTypeList);
  const productGroup = useSelector(store => store.admin.product.lookup.productGroup);
  const [productCategoryTypeName, setProductCategoryTypeName] = React.useState("");
  const [productCategoryTypeShortForm, setProductCategoryTypeShortForm] = React.useState("");
  const [productGroupId, setProductGroupId] = React.useState(0);
  const [productGroupName, setProductGroupName] = React.useState("");
  const [id, setId] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState(false);
  //for mix flow
  const [productMixList, setProductMixList] = React.useState([]);
  const [initialProductMixList, setInitialProductMixList] = React.useState([]); //to compare with productMixList to see which have been deleted/edited to add the isDelete flag
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleButtonClick_cancel = () => {
    if (props.setAddEditMode) {
      props.setAddEditMode(false);
    } else {
      const prop = props.location && props.location.state;  // from search
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        history.push((prop && prop.prevPath) || "/admin/products");
      });
    }
  }

  const validateFieldsProductCategoryType = () => {
    if (_.isEmpty(productCategoryTypeName)) {
      setErrorMsg({field: "category", msg: "Please add in a Product Category Type"});
      return false;
    }
    if (_.isEmpty(productCategoryTypeShortForm)) {
      setErrorMsg({field: "shortform", msg: "Please add in a Shortform"});
      return false;
    }
    if (!isShortform(productCategoryTypeShortForm)) {
      setErrorMsg({field: "shortform", msg: "Shortform should contain at most 3 upper case characters"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const validateFieldsMix = () => {
    const totalValue = productMixList.map(item => item.productMixPercent).reduce((prev, next) => Number(prev) + Number(next));
    if(productMixList.length > 0) {
      for (let i = 0; i < productMixList.length; i++) {
        if (productMixList[i].productCategoryTypeRefID === "") {
          setErrorMsg({field: "productCategoryTypeRefID"+i, msg: "Please enter a Product Category Type"});
          return false;
        }
        if (!productMixList[i].productMixPercent) {
          setErrorMsg({field: "productMixPercent"+i, msg: "Please enter a percentage"});
          return false;
        }
        if (!isPositiveDecimal(productMixList[i].productMixPercent)) {
          setErrorMsg({field: "productMixPercent"+i, msg: "Please enter a valid percentage"});
          return false;
        }
      }
    }
    // if (Number(totalValue) > 100) {
    //   setErrorMsg({field: "percentage"+(productMixList.length-1), msg: "Total percentage must be lower than 100%"});
    //   return false;
    // }
    if (Number(totalValue) !== 100) {
      setErrorMsg({field: "productMixPercent"+(productMixList.length-1), msg: "Total percentage must be 100%"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const validateFieldsProductGrouping = () => {
    if (_.isEmpty(productGroupName)) {
      setErrorMsg({field: "group", msg: "Please add in a Group Name"});
      return false;
    }
    setErrorMsg({field: "", msg: ""});
    return true;
  }

  const handleButtonClick_saveProductCategoryType = () => {
    if (validateFieldsProductCategoryType() && (productMixList.length ? validateFieldsMix() : true)) {
      let body = {
        productCategoryID: props.productCategoryID,
        productCategoryType: [
          {
            productCategoryTypeName: productCategoryTypeName,
            productCategoryTypeShortForm: productCategoryTypeShortForm,
            productGroupId: productGroupId,
            id: id
          }
        ],
      };
      if(selectedProduct.combinationProduct) {
        const reducedList = productMixList.reduce((item,{id}) => item.add(id), new Set());
        const deletedCategoryType = initialProductMixList.filter(({id}) => !reducedList.has(id));
        body.productCategoryType[0].productMixList = [...productMixList, ..._.map(deletedCategoryType, (data)=>{return {...data, isDeleted: true}})];
      }
      dispatch(CreateOrUpdateProductCategoryType(body))
      .then((response)=>{
        if (response.error) {
          dispatch(setLoading(false));
          if (response.payload.message.includes('Short Form')) {
            setErrorMsg({field: "shortform", msg: response.payload.message});  //validation for existing product type shortform
          } else {
            setErrorMsg({field: "category", msg: response.payload.message});  //validation for existing product type name
          }
        } else {
         resetState();
        }
      })
    }
  }
  
  const resetState = () => {
    setProductCategoryTypeName("");
    setProductCategoryTypeShortForm("");
    setProductGroupName("");
    setId(0);
    setProductGroupId(0);
    setProductMixList([]);
    setErrorMsg(false);
    if (props.setAddEditMode) {
      props.setAddEditMode(false);
    } else {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        const prop = props.location && props.location.state;  // from search
        history.push((prop && prop.prevPath) || "/admin/products");
      });
    }
  }

  const handleButtonClick_saveGrouping = () => {
    if (validateFieldsProductGrouping()) {
      let body = {
        productCategoryID: selectedProduct.productCategoryID,
        productGroupName: productGroupName,
        id: id,
      };
      dispatch(CreateOrUpdateProductGroup(body))
      .then((response)=>{
        if (response.error) {
          dispatch(setLoading(false));
          if (response.payload.message.includes('group')) {
            setErrorMsg({field: "group", msg: response.payload.message});  //validation for existing product type shortform
          }
        } else {
          resetState();
        }
      })
    }
  }

  //mix flow
  const handleModal = (index) => {
    setDeleteIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    setProductMixList(productMixList.filter((e, i) => i !== deleteIndex));
    setDeleteIndex(null);
    setOpenModal(!openModal);
  }

  const handleAddMix = () => {
    const obj = {
      productCategoryTypeID: id,
      productCategoryTypeRefID: "",
      productMixPercent: ""
    }
    const update = productMixList ? [...productMixList, obj] : [obj]
    setProductMixList(update);
  }

  const handleOnChangeMix = (e, arrIndex) => {
    let updatedList = productMixList.map((item, index) => {
      const key = e.target.name.replace(/\d/g, '');
      if (key === "productMixPercent" && index == arrIndex){
        return {...item, productMixPercent: Number(e.target.value)};
      } else if (key === "productCategoryTypeRefID" && index == arrIndex) {
        return {...item, productCategoryTypeRefID: e.target.value};
      } else {
        return item
      }
      });
    setProductMixList(updatedList);
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    const prop = props.location && props.location.state;  // from search
    if (prop) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "type");
      dispatch(GetFinishedProductForMix());
      dispatch(GetProductCategoryTypeByID(path[index+1]))
      .then((response) => {
        if (response.error) {
          Promise.all([dispatch(setDirty(false))])
          .then(() => {
            history.push((prop && prop.prevPath) || "/admin/products");
          });
        } else {
          const result = response.payload.result[0];
          dispatch(GetProductGroupByCategoryID(result.productCategoryId));
          setId(result.id);
          setProductCategoryTypeName(result.productCategoryTypeName);
          setProductCategoryTypeShortForm(result.productCategoryTypeShortForm);
          setProductGroupId(result.productGroupId);
          if (result.productMixList) {
            setProductMixList(result.productMixList);
          }
        }
      })
    } else {
      if (props.mode === "Edit") {
        if (props.type === "grouping") {
          setProductGroupName(props.editData.productGroupName);
          setId(props.editData.id);
        } else if (props.type === "product") {
          setId(props.editData.id);
          setProductCategoryTypeName(props.editData.productCategoryTypeName);
          setProductCategoryTypeShortForm(props.editData.productCategoryTypeShortForm);
          setProductGroupId(props.editData.productGroupId);
          if (props.editData.productMixList) {
            setProductMixList(props.editData.productMixList);
            setInitialProductMixList(props.editData.productMixList);
          }
        }
      }
      if(props.mode === "Add New" && productMixList.length) {
        handleAddMix()
      }
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      if (prop) {
        dispatch(resetNav());
      }
    }
  },[]);

  if (props.type === "product" || (props.location && props.location.state)) {
    const reducedList = productMixList.reduce((item,{productCategoryTypeRefID}) => item.add(productCategoryTypeRefID), new Set());
    const categoryTypeLookup = finishedCategoryTypeList.filter(({productCategoryTypeID}) => !reducedList.has(productCategoryTypeID));
    return (
      <Card 
        title = {props.location && props.location.state ? "Edit Product Type" : props.mode + " Product Type"}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={() => handleButtonClick_cancel()}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleButtonClick_saveProductCategoryType()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField 
              id="category"
              label={<React.Fragment>{props.productType} Type <span className={classes.orange}>*</span></React.Fragment>}
              variant="outlined" 
              placeholder="Enter Product Category Type"
              onChange={(e) => setProductCategoryTypeName(e.target.value)}
              value={productCategoryTypeName}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField 
              id="shortform"
              label={<React.Fragment>{props.productType} Shortform <span className={classes.orange}>*</span></React.Fragment>}
              variant="outlined" 
              placeholder="Enter Product Type ShortForm"
              onChange={(e) => setProductCategoryTypeShortForm(e.target.value.toUpperCase())}
              inputProps={{ maxLength: 3 }}
              value={productCategoryTypeShortForm}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Select
              label="Grouping"
              onChange={(e)=> setProductGroupId(e.target.value)}
              value={productGroupId}
              placeholder="Select Grouping"
            >
              <MenuItem value={0}>
                No Grouping
              </MenuItem>
              {productGroup && productGroup.map((item) => {
                return <MenuItem key={item.id} value={item.id}>{item.productGroupName}</MenuItem>;
              })}  
            </Select>
          </Grid>
        </Grid>
        { selectedProduct.combinationProduct || productMixList.length
          ? 
          <React.Fragment>
            <Typography className={classes.productMixTypography}>Product Mix</Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.label}>Composition of above Product Category Type <span className={classes.orange}>*</span></Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className={classes.label}>Percentage (%) <span className={classes.orange}>*</span></Typography>
              </Grid>
            </Grid>
            {productMixList ? productMixList.map((value,index) => {
              const selected = _.find(finishedCategoryTypeList, (item) => item.productCategoryTypeID === value.productCategoryTypeRefID);
              const categoryTypeList = value.productCategoryTypeRefID ? [selected, ...categoryTypeLookup] : categoryTypeLookup;
              return(
                <Grid container spacing={4} key={index}>
                  <Grid item xs={12} sm={5} md={4}>
                    <Select
                      name={"productCategoryTypeRefID"+index}
                      onChange={(e)=>handleOnChangeMix(e, index)}
                      value={value.productCategoryTypeRefID}
                      placeholder={"Select " + props.productType +" Type"}
                      errorMsg={errorMsg}
                    >
                      {categoryTypeList && categoryTypeList.map((item) => {
                        return <MenuItem key={item.productCategoryTypeID} value={item.productCategoryTypeID}>{item.productCategoryName}</MenuItem>;
                      })}  
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4}>
                    <TextField 
                      name="productMixPercent"
                      id={"productMixPercent"+index}
                      variant="outlined" 
                      placeholder="Enter Product Percentage"
                      onChange={(e) => handleOnChangeMix(e, index)}
                      value= {value.productMixPercent}
                      type="Number"
                      errorMsg={errorMsg}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    {productMixList.length !== 1
                    ? <IconButton
                        type="delete" 
                        onClick={() => handleModal(index)}
                      />
                    : null
                    } 
                  </Grid>
                </Grid>
              )
            })
            : null}
            <Button
              className={clsx(classes.buttonSecondary, classes.addNewButton)}
              onClick={() => handleAddMix()}
            >
              + Add New {props.productType} Type
            </Button>
          </React.Fragment>
          :
          null
        }
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content={"Do you really want to delete this ingredient? This process cannot be undone."}
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </Card>
    )
  } else {
    return (
      <Card 
        title = "Add new Grouping"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={() => handleButtonClick_cancel()}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleButtonClick_saveGrouping()}
            >
              Save
            </Button>
          </React.Fragment>
        }
      >
        <div className="flex flex-row items-baseline">
          <Typography>Grouping Name <span className={classes.orange}>*</span> :</Typography>
          <TextField 
            className={classes.groupingName}
            id="group"
            variant="outlined" 
            placeholder="Enter a grouping name"
            onChange={(e)=> setProductGroupName(e.target.value)}
            value= {productGroupName}
            errorMsg={errorMsg}
          />
        </div>
      </Card>
    )
  }
  
}
