import { createSlice } from "@reduxjs/toolkit";

import { GetAllAlarmType } from "services/UserPortal/AlarmService";

// initial state
const initialState = {
  totalCount: 0,
  alarms: [],
  setting: {},
  alarmType: [],
  resolve: [],
  // for all alarms
  allAlarms: [],
  notifications: []
};

const alarm = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.setting[key] = action.payload[key];
      })
    },
    resolveRequest: (state, action) => {
      state.resolve = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllAlarmType.fulfilled]: (state, action) => {
      state.alarmType = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, resolveRequest, reset } = alarm.actions;

// export the reducer
export default alarm.reducer;