import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  totalCount: 0,
  result: [],
  qr: {},
  barcode: {}
};

// create reducer and action creators
const generateCode = createSlice({
  name: "generateCode",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
  },
});

// export actions
export const { setValues, reset } = generateCode.actions;

// export the reducer
export default generateCode.reducer;