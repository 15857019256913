import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllUser, GetUserByFarmId, UploadBatchUser, TaggedUsersToFarm, SearchUser, GetAllRoles } from "services/AdminPortal/UserService";
import { UploadUserProfile } from "services/AdminPortal/UploadService";

// initial state
const initialState = {
  batchUsers: [],
  batchUser: {},
  totalCount: 0,
  users: [],
  user: {
  },
  existingUsers: [""],
  tagResult: [],
  searchResultsCount: 0,
  searchResults: [],
};

// create reducer and action creators
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.user = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.user[key] = action.payload[key];
      })
    },
    setBatchValues: (state, action) => {
      state.batchUser = action.payload;
    },
    deleteBatchRequest: (state, action) => {
      state.batchUsers = _.filter(state.batchUsers, (item) => item.lineNo !== action.payload)
    },
    updateExistingRequest: (state, action) => {
      state.existingUsers = action.payload;
    },
    updateBatchesRequest: (state, action) => {
      state.batchUsers = state.batchUsers.map((item) => {
        if (item.lineNo === action.payload) {
          return state.batchUser;
        } else {
          return item;
        }
      });
    },
    updateBatchRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.batchUser[key] = action.payload[key];
      })
    },
    resetBatch: (state) => {
      state.batchUser = {};
    },
    resetUser: (state) => {
      state.user = {};
    },
    resetExisting: (state) => {
      state.existingUsers = [""];
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllUser.fulfilled]: (state, action) => {
      state.users = action.payload.result.items;
      state.totalCount = action.payload.result.totalCount;
    },
    [GetUserByFarmId.fulfilled]: (state, action) => {
      state.user = _.pick(action.payload.result, ["id", "userName", "name", "emailAddress", "phone", "gender", "country", "workforceType", "employeeId", "roleId"])
    },
    [UploadBatchUser.fulfilled]: (state, action) => {
      action.payload.result.results.map(((item) => {
        item.roles=_.startCase(_.toLower(item.roles));
        item.gender=_.startCase(_.toLower(item.gender));
        item.workforceType=_.startCase(_.toLower(item.workforceType));
        item.country=_.startCase(_.toLower(item.country));
      }))
      state.batchUsers = action.payload.result.results;
    },
    [UploadUserProfile.fulfilled]: (state, action) => {
      state.user.profileUrl = action.payload.result;
    },
    [TaggedUsersToFarm.fulfilled]: (state, action) => {
      state.tagResult = action.payload.result;
    },
    [SearchUser.fulfilled]: (state, action) => {
      state.searchResultsCount = action.payload.result.userList ? action.payload.result.userList.totalCount : 0;
      state.searchResults = action.payload.result.userList ? action.payload.result.userList.items : 0;
    },
    [GetAllRoles.fulfilled]: (state, action) => {
      state.roles = action.payload.result.items;
    },
  },
});

// export actions
export const { setValues, updateRequest, resetUser, 
  updateExistingRequest, resetExisting,
  setBatchValues, deleteBatchRequest, updateBatchRequest, updateBatchesRequest, resetBatch,
  resetSearch, reset } = user.actions;

// export the reducer
export default user.reducer;