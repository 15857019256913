import {
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  indicator,
  tab,
  tabLabel,
} from "assets/jss/components/commonStyle.js";

const tabStyle = {
  orange,
  indicator,
  tab,
  tabLabel,
  tabLabelSelected: {
    ...tabLabel,
    ...orange
  },

  // Date Tab
  dateTab: {
    border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw"
  },
  dateLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minWidth: "4.167vw !important",
    maxWidth: "4.167vw",
    minHeight: "1.875vw"
  },
  selectedTab: {
    color: secondaryColor[0] + " !important",
    backgroundColor: secondaryColor[0] + "20",
  },
  dateIndicator: {
    backgroundColor: "transparent"
  },
  datepicker: {
    margin: "0 0.938vw",
    width: "15.625vw",
    "&:last-child": {
      marginRight: 0
    }
  }
}

export default tabStyle;