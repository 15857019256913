/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Card from 'shared-components/Card/Card';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import { monitoringStorageTableHead } from "enums/UserPortal/TableHeaders";
import { formatNumbers, roundTo2Decimal } from 'common/functions';

import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function Summary(props) {
  const classes = useStyles();
  const detail = useSelector(store => store.user.monitoring.storage.detail);

  return (
    <React.Fragment>
      <Card
        classes={{
          root: clsx(classes.cardPaper, classes.cardStorage),
          title: classes.cardMargin,
          content: classes.cardContent
        }}
        title="Storage Summary"
      >
        <Table
          className={classes.table}
          header={monitoringStorageTableHead}
        >
          <TableRow>
            <TableCell>{detail.warehouseName}</TableCell>
            <TableCell>{detail.warehouseID}</TableCell>
            <TableCell>{detail.address}</TableCell>
            <TableCell>{detail.capacity} {detail.uom}</TableCell>
            <TableCell>{formatNumbers(roundTo2Decimal(detail.capacityUsed))}%</TableCell>
            <TableCell>{detail.status}</TableCell>
          </TableRow>
        </Table>
      </Card>
    </React.Fragment>
  );
}
