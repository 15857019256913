import {
  border,
  backgroundColor,
  primaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  loading,
} from "assets/jss/components/commonStyle.js";

const selectStyle = () => ({
  loading,
  selectDisabled: {
    backgroundColor,
    color: "currentColor !important",
    borderRadius: "0.313vw",
    '&:hover': {
      border: '0.104vw solid #DDDDDD'
    },
  },
  selectError: {
    border: '0.104vw solid #FF0000',
    '&:hover': {
      border: '0.104vw solid #FF0000'
    },
    '&:focus': {
      borderRadius: "0.313vw",
      border: '0.104vw solid #FF0000',
    },
  },
  label: {
    textAlign: "left",
    margin: "2.083vw 0 0.365vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  placeholder: {
    color: "rgba(19, 50, 43, 0.5) !important"
  },
  icon: {
    top: "unset",
    right: "1.094vw",
    color: primaryColor[3],
  },
  errorMsg: {
    textAlign: "right",
    color: "#FF0000",
    position: "absolute",
    right: 0,
    bottom: "-1.250vw",
    whiteSpace: "nowrap"
  },
  menuPaper: {
    maxHeight: "25vw"
  },

  //Autocomplete
  input: {
    '& >[class*="MuiOutlinedInput-root"]': {
      flexWrap: "wrap",
    },
    '& >[class*="MuiOutlinedInput-root"] > input': {
      padding: "0.052vw !important"
    },
    "& div > input": {
      zIndex: 1,
    },
  },
  endAdornment: {
    paddingRight: "1.094vw",
    color: primaryColor[3],
    position: "absolute",
    right: 0,
    zIndex: 0
  },
  paper: {
    transition: "opacity 307ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 204ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },

  // InfiniteAutocomplete
  autocomplete: {
    // position: "relative",
    width: "100%",
    alignSelf: "start"
  },
  textfield: {
    width: '-webkit-fill-available',
    '& >[class*="MuiOutlinedInput-root"]': {
      flexWrap: "wrap",
      paddingRight: "3.125vw"
    },
    '& > [class*="MuiOutlinedInput-root"]': {
      border,
      flexWrap: "wrap",
      maxHeight: "7vw",
      overflow: "overlay",
    },
    '& > [class*="MuiOutlinedInput-root"]:hover > fieldset': {
      border: 'none'
    },
    '& > [class*="MuiOutlinedInput-root"]:focus > fieldset': {
      border: 'none'
    },
    '& > [class*="MuiOutlinedInput-root"]:not(:focus) > fieldset': {
      border: 'none'
    },
    '& >[class*="MuiOutlinedInput-root"] > input': {
      padding: "0.052vw !important"
    },
  },
  infiniteInput: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "rgba(19, 50, 43, 0.5)",
      opacity: 1
    },
  },
  infinitePopover: {
    '& >[class*="MuiPaper-root"]': {
      width: "100%",
      minWidth: "17.708vw",
      maxWidth: "20.833vw",
      top: "26.042vw !important",
    },
  },
  infinitePaper: {
    zIndex: 9999,
    // position: "absolute",
    minWidth: "20.833vw",
    // width: '100%',
    maxHeight: "12.500vw",
    overflow: "overlay",
    boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
  },
  // infinitePaper: {
  //   maxHeight: "17.708vw",
  //   height: "fit-content",
  //   overflow: "overlay",
  //   paddingTop: "8px",
  //   paddingBottom: "8px",
  //   top: "3.125vw",
  // },
  menuItem: {
    padding: "0.313vw 16px",
    cursor: "pointer",
    fontSize: "0.938vw",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  disabled: {
    padding: "0.313vw 16px",
    fontSize: "0.938vw",
    opacity: 0.5,
    backgroundColor: "rgba(0, 0, 0, 0.08)"
  }
});

export default selectStyle;
  