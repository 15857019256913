/* eslint-disable no-unused-vars */ // TO REMOVE
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";

import { setSearchText } from "store/general";

import AMRListing from "./AMRListing";
import Maintenance from "./Maintenance";
import Route from "./Route";
import styles from "assets/jss/components/UserPortal/amrStyle.js";

const useStyles = makeStyles(styles);

export default function Overview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const [tab, setTab] = React.useState(0);

  const handleOnChange_tab = (e, value) => {
    setTab(value);
  }
  
  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  const renderComponent = () => {
    switch (tab) {
      case 0:
        return <AMRListing />
      case 1:
        return <Maintenance />
      case 2:
        return <Route />
    }
  }

  return (
    <React.Fragment>
      <Card 
        title="AMR Management - Overview"
        subheader={
          <Tabs 
            tabs={["AMR Listing", "Maintenance Information", "Route Visualisation"]}
            value={tab} 
            onChange={handleOnChange_tab}
          />
        }
      >
        {renderComponent()}
      </Card>
    </React.Fragment>
  );
}
