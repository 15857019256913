import {
  blackColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

const chipStyle = {
  //Chip
  chipRoot: {
    border: "0.104vw solid #DDDDDD",
    margin: "0.260vw 0.156vw",
    maxWidth: "calc(100% - 0.313vw)",
    color: blackColor,
    height: "2.292vw",
    borderRadius: "1.146vw",
    fontSize: "0.833vw",
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.4)',
    },
  },

  // Tag
  tagRoot: {
    border: "none",
    maxWidth: "calc(100% - 0.313vw)",
    backgroundColor: "#FFF0E5",
    color: blackColor,
    height: "2.292vw",
    borderRadius: "0.417vw",
    fontSize: "0.833vw",
    '&:hover': {
      backgroundColor: "#FFE0C9",
    },
  },
  tagDelete: {
    color: secondaryColor[0],
    '&:hover': {
      color: secondaryColor[1]
    },
  }
};

export default chipStyle;
  