import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetProfitAndLoss, GetSubSaleOrderLookup, GetCustomerLookUp, 
  GetCostingBySaleOrder, GetCostingbyProduct, GetTotalCostByCustomer,
  GetTotalRevenuebySaleOrder, GetTotalRevenueByProduct, GetTotalRevenueByCustomer } from "services/UserPortal/CostProductivityService";

// initial state
const initialState = {
  totalCount: 0,
  reports: [],
  profitLoss: {},
  // lookup
  subSaleOrderList: [],
  customerList: []
};

const cpReport = createSlice({
  name: "cpReport",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetProfitAndLoss.fulfilled]: (state, action) => {
      state.profitLoss = action.payload.result;
    },
    [GetSubSaleOrderLookup.fulfilled]: (state, action) => {
      state.subSaleOrderList = _.orderBy(action.payload.result,["subSaleOrderNumber"], ['desc']);
    },
    [GetCustomerLookUp.fulfilled]: (state, action) => {
      state.customerList = _.orderBy(action.payload.result, ["customerName"]);
    },
    [GetCostingBySaleOrder.fulfilled]: (state, action) => {
      state.reports = action.payload.result;
    },
    [GetCostingbyProduct.fulfilled]: (state, action) => {
      state.reports = action.payload.result;
    },
    [GetTotalCostByCustomer.fulfilled]: (state, action) => {
      state.reports = action.payload.result;
    },
    [GetTotalRevenuebySaleOrder.fulfilled]: (state, action) => {
      state.reports = action.payload.result;
    },
    [GetTotalRevenueByProduct.fulfilled]: (state, action) => {
      state.reports = action.payload.result;
    },
    [GetTotalRevenueByCustomer.fulfilled]: (state, action) => {
      state.reports = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, updateExclusionRequest, reset } = cpReport.actions;

// export the reducer
export default cpReport.reducer;