import {
  icon_64,
  button,
  buttonSecondary,
  blackColor,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  subTable,
  icon,
  result,
  collapsedIcon,
  highlight,
  rotate,
  orange,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const customerOrderStyle = {
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  subTable,
  icon,
  orange,
  tag,
  link,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Management
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  cardActionDropdown: {
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  tablePopper: {
    width: "26.396vw",
  },
  transparentTable: {
    height: "16.396vw",
    margin: "0 -1.563vw",
    overflow: "overlay",
    '& thead > tr > th': {
      backgroundColor: "transparent !important",
      color: whiteColor
    },
    "& tbody > tr": {
      backgroundColor: "transparent !important",
      "& td": {
        color: whiteColor
      }
    }
  },
  detailTable: {
    '& thead > tr > th': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  cardMargin: {
    marginTop: "2.604vw",
  },
  formTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "1.927vw",
    "&:not(:first-child)": {
      marginTop: "2.604vw"
    },
  },
  formLabel: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.521vw",
    whiteSpace: "nowrap",
    color: primaryColor[4],
    marginTop: "0.833vw",
    // alignSelf: "center",
    width: "7.813vw",
    minWidth: "7.813vw"
  },
  formLabel2: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.521vw",
    whiteSpace: "nowrap",
    color: primaryColor[4],
    marginTop: "0.833vw",
    // alignSelf: "center",
    width: "5.208vw",
    minWidth: "5.208vw"
  },
  error: {
    marginLeft: "11.458vw",
    marginTop: "-0.833vw",
    color: "#FF0000",
    fontSize: "0.729vw",
  },
  grid: {
    display: "flex",
  },
  checkbox: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.573vw",
    alignSelf: "center"
  },
  checkboxLabel: {
    marginRight: "2.292vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  radio: {
    display: "flex",
    margin: "0 2.604vw"
  },
  radioLabel: {
    color: primaryColor[4],
    alignSelf: "center",
    textAlign: "left"
  },
  unitSelect: {
    width: "5.469vw",
    marginLeft: "1.042vw",
  },
  subheader: {
    // paddingTop: "2.083vw",
    color: primaryColor[3]
  },
  subheaderLabel: {
    fontWeight: 500
  },
  actions: {
    marginLeft: "1.042vw",
  },
  addButton: {
    marginTop: "2.604vw",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.563vw",
  },

  // Capacity
  legend: {
    display: "flex",
    alignItems: "center",
    color: blackColor,
    fontSize: "0.938vw"
  },
  eventIcon: {
    borderRadius: "50%",
    width: "1.354vw",
    height: "1.354vw",
    color: whiteColor,
    textAlign: "center",
    margin: "0 0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    }
  },
  event2Icon: {
    borderRadius: "50%",
    width: "1.094vw",
    height: "1.094vw",
    color: whiteColor,
    textAlign: "center",
    marginRight: "0.521vw",
  },
  weight: {
    backgroundColor: secondaryColor[0],
  },
  percentage: {
    backgroundColor: "#94CE85",
  },
  remain: {
    backgroundColor: "#f2d238",
  },
  excess: {
    backgroundColor: "#CC81B8",
  },
  forecast: {
    backgroundColor: "#6AAFB9",
  },
  calendarHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "1.563vw"
  },
  calendarAction: {
    display: "flex",
    alignItems: "center",
  },
  calendarTitle: {
    fontSize: "1.250vw"
  },
  event: {
    position: "relative",
    cursor: "default",
    margin: "0 0.521vw",
    "&:hover > div": {
      opacity: 1
    }
  },
  eventText: {
    display: "flex",
    fontSize: "0.833vw",
    lineHeight: "1.354vw",
    whiteSpace: "break-spaces"
  },
  hoverEvent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#00000080",
    top: 0,
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    margin: "-1.979vw -0.625vw -0.729vw",
    opacity: 0,
  },
  viewMore: {
    height: "2.135vw"
  },
  popper: {
    width: "15.625vw"
  }
}

export default customerOrderStyle;