import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { formatNumbers, roundTo2Decimal } from "common/functions";

import Pagination from "./Pagination";
import styles from "assets/jss/components/UserPortal/homeStyle.js";

import { secondaryColor } from "assets/jss/generaliseStyle";
const useStyles = makeStyles(styles);

export default function Cost() {
  const classes = useStyles();
  let history = useHistory();
  const results = useSelector(store => store.user.home.costReportWidget);
  const [page, setPage] = React.useState(0);
  
  const config = () => {
    return ({
      colors: ["#00CFB4", secondaryColor[0]],
      title: {
        text: "",
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '120%'],
          size: '240%'
        }
      },
      series: [{
        type: 'pie',
        innerSize: '50%',
        data: [['Cost', results[page] && Number(results[page].totalCost)],
        {
          name: 'Net Profit / Loss:',
          y: results[page] && Math.abs(results[page].profitOrLoss),
        }],
      }],
    })
  };

  return (
    <React.Fragment>
      {results.length
      ? <React.Fragment>
          <Typography className={classes.widgetTitle}>Profit/Loss from {moment(results[page] && results[page].startDate).format("DD/MM/YYYY")} to {moment(results[page] && results[page].endDate).format("DD/MM/YYYY")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.halfPieChart }}/>
            </Grid>
            <Grid item xs={6} className={classes.legend}>
              <div className={classes.legendColor} /><Typography className={classes.legendLabel}>Cost:</Typography><span className={classes.paperContent}>${formatNumbers(roundTo2Decimal(results[page] && results[page].totalCost))}</span>
            </Grid>
            <Grid item xs={6} className={classes.legend}>
              <div className={classes.legendColor2} /><Typography className={classes.legendLabel}>Net Profit / Loss:</Typography><span className={classes.paperContent}>${formatNumbers(roundTo2Decimal(results[page] && results[page].profitOrLoss))}</span>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} className={clsx(classes.paper, classes.progressTitle)}>
                <Typography className={classes.tableTitle}>Total Revenue:</Typography>
                <Typography className={classes.paperValue}>${formatNumbers(roundTo2Decimal(results[page] && results[page].totalRevenue))}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      : <Typography className={classes.tableTitle}>Nothing to show</Typography>
      }
      <div className={classes.widgetAction}>
        { results.length > 1 &&
          <Pagination 
            count={results.length} 
            page={page+1}
            onChange={(e, pageNo)=>setPage(pageNo-1)}
          />
        }
        <Button className={clsx(classes.buttonSecondary, classes.viewAllButton)} onClick={()=>history.push("/user/cost-and-productivity/report")}>View All</Button>
      </div>
    </React.Fragment>
  );
}
