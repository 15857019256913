import {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardRadius,
  border,
  blackColor,
  whiteColor,
  secondaryColor,
  primaryColor
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  subTable,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const planningStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  subTable,
  icon,
  tag,
  link,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  cardAction: {
    position: "absolute",
    right: "2.083vw",
    top: "1vw",
  },
  cardActionDropdown: {
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },

  // Planning
  excessCard: {
    width: "26.146vw",
    marginTop: "1.750vw"
  },
  unassignedCard: {
    marginTop: "1.750vw"
  },
  noOrder: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  noOrderImg: {
    height: "16.146vw",
    margin: "2.500vw 0"
  },
  noOrderText: {
    fontSize: "1.250vw",
    textAlign: "center",
    marginBottom: "2.500vw"
  },

  // Conversion
  woDropdown: {
    width: "13.542vw"
  },
  conversionTable: {
    '& thead > tr > th:nth-child(-n+3)': {
      width: "fit-content",
      minWidth: "8vw",
      whiteSpace: "nowrap"
    },
  },
  linkDiv: {
    marginTop: "1.563vw",
    display: "flex",
    flexDirection: "column"
  },
  
  // Adjustment
  adjustContent: {
    width: "62.500vw",
    margin: "auto",
  },
  adjustPaper: {
    ...cardRadius,
    border,
    width: "62.500vw",
    margin: "auto",
    marginTop: "1.042vw",
    marginBottom: "1.302vw",
    padding: "1.823vw 1.979vw",
    color: primaryColor[3],
  },
  adjustButton: {
    display: "flex",
    justifyContent: "flex-end"
  },

  // Report
  calendarHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "2.448vw"
  },
  calendarAction: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    margin: "0 0.521vw 0 1.563vw"
  },
  dropdown: {
    width: "10.729vw"
  },
  calendarTitle: {
    fontSize: "1.250vw"
  },
  event: {
    position: "relative",
    cursor: "default",
    margin: "0 0.521vw",
    "&:hover > div": {
      opacity: 1
    }
  },
  eventText: {
    fontSize: "0.833vw",
    lineHeight: "1.354vw",
    whiteSpace: "break-spaces"
  },
  eventIcon: {
    width: "1.094vw",
    height: "1.094vw",
    color: whiteColor,
    backgroundColor: secondaryColor[0],
    borderRadius: "50%"
  },
  eventIcon2: {
    backgroundColor: blackColor,
  },
  hoverEvent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#00000080",
    top: 0,
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    margin: "-1.979vw -0.625vw -0.729vw",
    opacity: 0,
  },
  viewMore: {
    height: "2.135vw"
  },
  accordion: {
    width: "50.677vw",
    margin: "auto !important",
    "&:not(:first-child)": {
      marginTop: "2.083vw !important"
    }
  },
  tabMargin: {
    marginTop: "1.250vw",
    marginLeft: "-1.563vw",
    marginRight: "-1.563vw",
  },
  mainTable: {
    margin: "0 -1.979vw",
  },
  tableButton: {
    float: "right",
    marginTop: "1.042vw"
  },
  detailButton: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    width: "50.677vw",
    marginTop: "2.083vw"
  },
  readjustModal: {
    textAlign: "left",
    padding: "0 1.979vw 1.302vw",
    width: "40vw"
  },
  readjustTitle: {
    fontSize: "1.354vw",
    fontWeight: 500,
  },
  divider: {
    margin: "1.719vw -1.979vw 2.083vw",
  },
  readjustAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableMargin: {
    verticalAlign: "top",
    paddingTop: "1.667vw"
  },
  tableText: {
    width: "10.417vw",
    verticalAlign: "top"
  },
  tableArrow: {
    width: "1.563vw",
  },
  reorderList: {
    marginTop: "0.521vw",
    maxHeight: "20.833vw",
    overflow: "overlay"
  },
  reorderListItem: {
    width: "max-content",
    margin: "auto",
  },
  reorderText: {
    fontWeight: 500,
  },

  // Settings
  settingsPaper: {
    ...cardRadius,
    border,
    width: "41.667vw",
    margin: "auto",
    marginBottom: "1.302vw",
    padding: "1.823vw 7.292vw",
    color: primaryColor[3],
  },
  settingsLabel: {
    color: primaryColor[4],
    alignSelf: "center"
  },
  settingsButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.083vw"
  },
  settingsTitle: {
    fontWeight: 500,
    fontSize: "1.094vw",
    marginBottom: "1.042vw",
    "&:not(:first-child)": {
      marginTop: "3.125vw"
    }
  },
  settingsDiv: {
    display: "flex",
    alignItems: "center"
  },
  checkboxPadding: {
    marginLeft: "1.563vw"
  },
  settingsTextfield: {
    width: "6.771vw",
    margin: "1.042vw"
  },
  dateContent: {
    maxWidth: "18.750vw",
    // display: "flex",
  },
  dateField: {
    display: "flex",
    marginLeft: "1.563vw",
    marginBottom: "1.250vw"
  }
}

export default planningStyle;