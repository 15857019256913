import { 
  button, 
  buttonSecondary,
  icon_24, 
  blackColor, 
  primaryColor,
} from "assets/jss/generaliseStyle";

import {
  orange,
} from "assets/jss/components/commonStyle.js";

const authStyle = {
  orange,
  title: {
    fontSize: "1.875vw",
    fontWeight: "700",
    color: blackColor
  },
  subtitle: {
    marginBottom: "2.396vw",
    color: blackColor,
    opacity: 0.5,
    fontSize: "0.938vw",
  },
  subfield: {
    textAlign: "right",
    fontSize: "0.833vw",
    color: primaryColor[3],
    marginBottom: "2.083vw",
    alignItems: "center",
  },
  formlabel: {
    fontSize: "0.833vw",
    color: primaryColor[3],
    opacity: 0.6
  },
  description: {
    color: "#868686",
    fontSize: "0.938vw",
    textAlign: "center"
  },
  textfield: {
    marginBottom: "1.563vw",
  },
  adornment: {
    ...icon_24,
    padding: 0,
    position: "absolute"
  },
  button: {
    ...button,
    width: '100%',
    height: '2.604vw',
    marginBottom: "2.083vw",
  },
  termsConditions: {
    display: "flex",
    color: "#868686",
    marginBottom: "1.250vw",
    alignItems: "center"
  },
  checkboxError: {
    "& span": {
      border: "0.052vw solid #FF0000"
    }
  },
  googleButton: {
    ...buttonSecondary,
    width: '100%',
    height: '2.604vw',
    marginBottom: "2.083vw",
  },
  googleContainer: {
    marginBottom: "2.083vw",
  },
};

export default authStyle;
  