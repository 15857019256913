import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Accordion from "shared-components/Accordion/CardAccordion";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';

import { useInterval } from 'common/utils';
import { renderValue, formatNumbers, roundTo2Decimal } from "common/functions";
import { setValues, reset } from "../store/dashboard";
import { GetAllSustainability, GetAllGMF, GetAllSellingPrice, ConvertkWhToCO2, CreateOrUpdateSustainability, CreateOrUpdateGMF, CreateOrUpdateSellingPrice } from "services/UserPortal/SustainabilityService";

import styles from "assets/jss/components/UserPortal/sustainabilityStyle.js";
import { setDirty } from "store/general";

const useStyles = makeStyles(styles);

export default function Chart() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sustainability = useSelector(store => store.user.sustainability.dashboard.sustainability);
  const price = useSelector(store => store.user.sustainability.dashboard.price);
  const gmf = useSelector(store => store.user.sustainability.dashboard.gmf);
  const conversion = useSelector(store => store.user.sustainability.dashboard.conversion);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isEditPrice, setIsEditPrice] = React.useState(false);
  const [date, setDate] = React.useState({startDate: moment().startOf('month'), endDate: moment().startOf('month').add(1, 'y')});
  const [months, setMonths] = React.useState([]);
  const [years, setYears] = React.useState([]);

  const handleOnChange_date = (value) => {
    if (value) {
      setDate({startDate: moment(value.startDate), endDate: moment(value.endDate)});
    }
  };

  const handleOnChange_text = (e, date, field) => {
    dispatch(setDirty(true));
    let payload = [];
    if (e.target.id === 'price') {
      payload = _.cloneDeep(price);
      setIsEditPrice(true);
      const index = payload.findIndex((val) => val && val.year === date);
      if (index === -1) {
        payload = [...payload, {
          year: Number(date),
          [e.target.id]: e.target.value ? Number(e.target.value) : null,
          id: 0
        }];
      } else {
        payload[index] = {
          ...payload[index],
          [e.target.id]: e.target.value ? Number(e.target.value) : null
        }
      }
    } else {
      if (field === 'sustainability') {
        payload = _.cloneDeep(sustainability);
        setIsEdit(true);
      } else if (field === 'gmf') {
        payload = _.cloneDeep(gmf);
        setIsEdit(true);
      }
      const index = payload.findIndex((val) => val && val.month+" "+val.year === date);
      if (index === -1) {
        payload = [...payload, {
          month: Number(date.split(' ')[0]),
          year: Number(date.split(' ')[1]),
          [e.target.id]: e.target.value ? Number(e.target.value) : null,
          id: 0
        }];
      } else {
        payload[index] = {
          ...payload[index],
          [e.target.id]: e.target.value ? Number(e.target.value) : null
        }
      }
    }
    dispatch(setValues({ [field] : payload }));
  };

  const handleButtonClick_save = () => {
    if (isEdit) {
      Promise.all([
        dispatch(setDirty(false)),
        dispatch(CreateOrUpdateSustainability()),
        dispatch(CreateOrUpdateGMF()),
      ]).then(() => {
        dispatch(GetAllSustainability({stopLoading: true}));
        dispatch(GetAllGMF({stopLoading: true}));
        dispatch(ConvertkWhToCO2({stopLoading: true}));
        setIsEdit(false);
      })
    } else if (isEditPrice) {
      dispatch(setDirty(false)),
      dispatch(CreateOrUpdateSellingPrice())
      .then(() => {
        dispatch(GetAllSellingPrice({stopLoading: true}));
        setIsEditPrice(false);
      })
    }
  };

  const getListOfMonthAndYear = () => {
    let {startDate, endDate} = _.cloneDeep(date);
    let monthsPayload = [];
    let yearsPayload = [];
    while (endDate.isSameOrAfter(startDate, 'month')) {
      monthsPayload.push({id: startDate.format('M YYYY'), label: startDate.format('MMM YYYY')});
      let index = yearsPayload.findIndex(({id}) => id === startDate.year());
      if (index === -1) {
        yearsPayload.push({id: startDate.year(), label: startDate.year()});
      }
      startDate.add(1, 'month');
    }
    setMonths(monthsPayload);
    setYears(yearsPayload);
  }

  const getAllSustainability = (stopLoading) => {
    if (!isEdit || !isEditPrice) {
      dispatch(GetAllSustainability({stopLoading}));
      dispatch(GetAllGMF({stopLoading}));
      dispatch(GetAllSellingPrice({stopLoading}));
      dispatch(ConvertkWhToCO2({stopLoading}));
    }
  }

  React.useEffect(() => {
    if (date.startDate && date.endDate) {
      getAllSustainability({startDate: date.startDate.format('YYYY-MM-DD'), endDate: date.endDate.format('YYYY-MM-DD')});
      getListOfMonthAndYear();
    }
  },[date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  useInterval(() => {
    getAllSustainability(true);
  });

  return (
    <React.Fragment>
      <Card 
        title = "Dashboard - Carbon Emission Profile"
        subheader = "Definition of year starts from May to May"
        action={
          <DateRangePicker
            clearable
            className={classes.datepicker}
            placeholder="Date"
            value={{startDate: date.startDate, endDate: date.endDate}}
            onChange={(e) => handleOnChange_date(e)}
            onClear={()=>setDate({startDate: null, endDate: null})}
          />
        }
      >
        <Accordion
          classes={{
            root: classes.accordion,
          }}
          header="Consumption (kWh)"
        >
          <div className={classes.table}>
            <Table
              className={classes.mainTable}
              header={[{}, ...months]}
            >
              <TableRow>
                <TableCell>Solar PV</TableCell>
                { months.map((item,index) => {
                  const value = sustainability.find((val) => val && val.month+" "+val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      <TextField 
                        id="pV_Value"
                        variant="outlined" 
                        type="Number"
                        inputProps={{ maxLength: 20 }}
                        InputProps={{ 
                          startAdornment: <InputAdornment position="start">{value.pV_Value > 0 && "-"}</InputAdornment>,
                          inputProps: { min: 0 } 
                        }}
                        onInput={(e)=>{ 
                          e.target.value = e.target.value < 0 ? 0 : e.target.value
                        }}
                        value={renderValue(value.pV_Value)}
                        onChange={(e) => handleOnChange_text(e, item.id, "sustainability")}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                <TableCell>Purchase Electricity</TableCell>
                { months.map((item,index) => {
                  const value = sustainability.find((val) => val && val.month+" "+val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      <TextField 
                        id="grid_Value"
                        variant="outlined" 
                        type="Number"
                        inputProps={{ maxLength: 20, inputProps: { max: 0 } }}
                        value={value.grid_Value}
                        onChange={(e) => handleOnChange_text(e, item.id, "sustainability")}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                <TableCell><b>Total</b></TableCell>
                { months.map((item,index) => {
                  const value = sustainability.find((val) => val && val.month+" "+val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      <b>{formatNumbers(roundTo2Decimal((Number(value.grid_Value ?? 0) - Number(value.pV_Value ?? 0))))}</b>
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                <TableCell>Grid Emission Factor (kgCO2/kWh)</TableCell>
                { months.map((item,index) => {
                  const value = gmf.find((val) => val && val.month+" "+val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      <TextField 
                        id="value"
                        variant="outlined" 
                        type="Number"
                        inputProps={{ maxLength: 20 }}
                        value={value.value}
                        onChange={(e) => handleOnChange_text(e, item.id, "gmf")}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
            </Table>
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.action}>
              <Button 
                className={classes.button}
                onClick={() => handleButtonClick_save()}
                disabled={!isEdit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Accordion>
        <Accordion
          classes={{
            root: classes.accordion,
          }}
          header="Carbon Emission (tonCO2)"
        >
          <div className={classes.table}>
            <Table
              header={[{}, ...months]}
            >
              <TableRow>
                <TableCell>Solar PV</TableCell>
                { months.map((item,index) => {
                  const value = conversion.kgco2 && conversion.kgco2.find((val) => val && val.month+" "+val.year === item.id);
                    return (
                      <TableCell key={index}>
                        {value && -roundTo2Decimal(value.pV_Value)}
                      </TableCell>
                    )
                  })
                }
              </TableRow>
              <TableRow>
                <TableCell>Purchase Electricity</TableCell>
                { months.map((item,index) => {
                  const value = conversion.kgco2 && conversion.kgco2.find((val) => val && val.month+" "+val.year === item.id);
                    return (
                      <TableCell key={index}>
                        {value && roundTo2Decimal(value.grid_Value)}
                      </TableCell>
                    )
                  })
                }
              </TableRow>
              <TableRow>
                <TableCell><b>Total</b></TableCell>
                { months.map((item,index) => {
                  const value = conversion.kgco2 && conversion.kgco2.find((val) => val && val.month+" "+val.year === item.id);
                    return (
                      <TableCell key={index}>
                        <b>{value && formatNumbers(roundTo2Decimal(Number(value.grid_Value ?? 0) - Number(value.pV_Value ?? 0)))}</b>
                      </TableCell>
                    )
                  })
                }
              </TableRow>
              {/* <TableRow>
                <TableCell>Ton</TableCell>
                { Array.from({length: 13}).map((item,index) => {
                  return (
                    <TableCell key={index}>
                      
                    </TableCell>
                  )
                })}
              </TableRow> */}
            </Table>
          </div>
        </Accordion>
        <Accordion
          classes={{
            root: classes.accordion,
          }}
          header="Scope Emission"
        >
          <Grid container spacing={2}>
            <Grid item xs={1} className={classes.gridItem}>
              <Typography>Scope 1</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField 
                id="scope1"
                variant="outlined" 
                inputProps={{ maxLength: 20 }}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">tonCO2e</InputAdornment>,
                }}
                value={renderValue(formatNumbers(conversion.scope1))}
              />
            </Grid>
            <Grid item xs={7} />
            <Grid item xs={1} className={classes.gridItem}>
              <Typography>Scope 2</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField 
                // id="scope2"
                className={classes.disabledTextField}
                variant="outlined" 
                inputProps={{ maxLength: 20 }}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">tonCO2e</InputAdornment>,
                }}
                value={renderValue(formatNumbers(conversion.scope2))}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={1} className={classes.gridItem}>
              <Typography>Total</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField 
                id="scope3"
                className={classes.disabledTextField}
                variant="outlined" 
                inputProps={{ maxLength: 20 }}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">tonCO2e</InputAdornment>,
                }}
                value={formatNumbers(roundTo2Decimal(Number(conversion.scope1 ?? 0) + Number(conversion.scope2 ?? 0) + Number(conversion.scope3 ?? 0)))}
              />
            </Grid>
            <Grid item xs={1} className={classes.gridItem}>
              <Typography>Scope 3</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField 
                id="scope3"
                className={classes.disabledTextField}
                variant="outlined" 
                inputProps={{ maxLength: 20 }}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">tonCO2e</InputAdornment>,
                }}
                value={renderValue(formatNumbers(conversion.scope3))}
              />
            </Grid>
          </Grid>
        </Accordion>
        <Accordion
          classes={{
            root: classes.accordion,
          }}
          header="Renewable Energy Certificates (RECs) Utilised"
        >
          {/* <Grid container spacing={2}>
            <Grid item xs={1} className={classes.gridItem}>
              <Typography>REC</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField 
                className={classes.disabledTextField}
                id="scope1"
                variant="outlined"
                inputProps={{ maxLength: 20 }}
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">/MWh</InputAdornment>,
                }}
                value={renderValue(formatNumbers(conversion.rec))}
              />
            </Grid>
            <Grid item xs={7} />
          </Grid> */}
          <div className={classes.table}>
            <Table
              className={classes.mainTable}
              header={[{}, ...years]}
            >
              <TableRow>
                <TableCell>REC (/MWh)</TableCell>
                { years.map((item,index) => {
                  const value = price.find((val) => val && val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      {value && roundTo2Decimal(formatNumbers(value.rec))}
                    </TableCell>
                  )
                })}
              </TableRow>
            </Table>
          </div>
        </Accordion>
        <Accordion
          classes={{
            root: classes.accordion,
          }}
          header="Cost of REC"
        >
          <div className={classes.table}>
            <Table
              className={classes.mainTable}
              header={[{}, ...years]}
            >
              <TableRow>
                <TableCell>Selling Price</TableCell>
                { years.map((item,index) => {
                  const value = price.find((val) => val && val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      <TextField 
                        id="price"
                        variant="outlined" 
                        type="Number"
                        inputProps={{ maxLength: 20 }}
                        value={value.value}
                        onChange={(e) => handleOnChange_text(e, item.id, "price")}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                <TableCell>Per Year Estimate</TableCell>
                { years.map((item,index) => {
                  const value = price.find((val) => val && val.year === item.id) ?? {};
                  return (
                    <TableCell key={index}>
                      {value && formatNumbers(roundTo2Decimal(Number(value.rec ?? 0) * Number(value.value ?? 0)))}
                    </TableCell>
                  )
                })}
              </TableRow>
            </Table>
          </div>
          <Grid container spacing={2} className={classes.gridContainer}>
            {/* <Grid item xs={2} className={classes.gridItem}>
              <Typography>Per Year Estimate</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField 
                // id="deliveryAddress"
                className={classes.disabledTextField}
                variant="outlined" 
                inputProps={{ maxLength: 20 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  readOnly: true,
                }}
              />
            </Grid> */}
            <Grid item xs={12} className={classes.action}>
              <Button 
                className={classes.button}
                onClick={() => handleButtonClick_save()}
                disabled={!isEditPrice}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Accordion>
      </Card>
    </React.Fragment>
  );
}
