import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllDeliveryTrip, GetDeliveryTripDetail, GetDeliveryTripLocationByTripId, GetAllVehicles, GetAllDriver, GetDeliveryTripLookUp, GetDeliveryOrderLookUp } from "services/UserPortal/DeliveryService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  results: [],
  trip: {},
  details: {},
  lastKnownLocation: {},
  //Lookup
  vehicleList: [],
  driverList: [],
  tripList: [],
  doList: []
};

const deliveryMaster = createSlice({
  name: "deliveryMaster",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.trip[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllDeliveryTrip.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.results = action.payload.result.items;
    },
    [GetDeliveryTripDetail.fulfilled]: (state, action) => {
      state.details = action.payload.result;
    },
    [GetDeliveryTripLocationByTripId.fulfilled]: (state, action) => {
      state.lastKnownLocation = _.pick(action.payload.result, ["lastKnownLocationLatitude", "lastKnownLocationLongitude"]);
    },
    [GetAllVehicles.fulfilled]: (state, action) => {
      state.vehicleList = _.sortBy(action.payload.result, ["vehicleRefNo"]);
    },
    [GetAllDriver.fulfilled]: (state, action) => {
      state.driverList = _.sortBy(action.payload.result, ["name"]);
    },
    [GetDeliveryTripLookUp.fulfilled]: (state, action) => {
      state.tripList = _.sortBy(action.payload.result, ["deliveryTripRefNo"]);
    },
    [GetDeliveryOrderLookUp.fulfilled]: (state, action) => {
      state.doList = _.sortBy(action.payload.result, ["deliveryOrderRefNo"]);
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = deliveryMaster.actions;

// export the reducer
export default deliveryMaster.reducer;