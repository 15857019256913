/*!

 =========================================================
 * Material Dashboard React - v1.10.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let start = input[0];
    let middle = input[1];
    let end = input[2];
    input = start + start + middle + middle + end + end;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = "13.333vw";

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const container = {
  marginRight: "auto",
  marginLeft: "auto",
  minHeight: "3.333vw",
  // height: "100%"
};

const defaultFont = {
  fontWeight: "400",
  lineHeight: "1.5em",
};

// const primaryColor = ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc5"];
const warningColor = ["#ff9800", "#ffa726", "#fb8c00", "#ffa21a"];
const dangerColor = ["#f44336", "#ef5350", "#e53935", "#f55a4e"];
const successColor = ["#4caf50", "#66bb6a", "#43a047", "#5cb860"];
const infoColor = ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573"];
const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#b4b4b4",
  "#555555",
  "#333",
  "#a9afbb",
  "#eee",
  "#e7e7e7",
];
const blackColor = "#000";
const whiteColor = "#FFF";

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb("#9c27b0") +
    ",.4)",
};
const infoBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(infoColor[0]) +
    ",.4)",
};
const successBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(successColor[0]) +
    ",.4)",
};
const warningBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(warningColor[0]) +
    ",.4)",
};
const dangerBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(dangerColor[0]) +
    ",.4)",
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(roseColor[0]) +
    ",.4)",
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 3px 20px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
};

// custom
const primaryColor = ["#849490", "#D3DDD3", "#225422", "#13322B", "#112A11", "#F5F7F5", "#CFEDD5", "#86D295", "#297037", "#3CB043"];
const secondaryColor = ["#FF8A3D", "#FF6B0A"];
const backgroundColor = "#F5F7F5";
const graphColors = [secondaryColor[0], primaryColor[7], "#00CFB4", "#C28DD1", "#F7DE64"];
const border = "0.052vw solid #D9D9D6";

const cardRadius = {
  borderRadius: "0.833vw",
}

const button = {
  backgroundColor: secondaryColor[0],
  color: whiteColor,
  padding: "0.781vw 1.250vw",
  borderRadius: "0.313vw",
  '&:hover': {
    backgroundColor: secondaryColor[1]
  },
  '&:disabled': {
    backgroundColor: primaryColor[1],
    color: whiteColor,
  },
}

const buttonSecondary = {
  backgroundColor: whiteColor,
  color: secondaryColor[0],
  border: "0.104vw solid "+ secondaryColor[0],
  padding: "0.677vw 1.146vw",
  borderRadius: "0.313vw",
  '&:hover': {
    backgroundColor: whiteColor,
    color: secondaryColor[1],
    border: "0.104vw solid "+ secondaryColor[1],
  },
  '&:disabled': {
    backgroundColor: whiteColor,
    color: primaryColor[1],
    border: "0.104vw solid " + primaryColor[1],
  },
}

const icon_16 = {
  width: "0.833vw",
  height: "0.833vw"
}

const icon_24 = {
  width: "1.250vw",
  height: "1.250vw"
}

const icon_38 = {
  width: "1.979vw",
  height: "1.979vw"
}

const icon_48 = {
  width: "2.500vw",
  height: "2.500vw"
}

const icon_64 = {
  width: "3.333vw",
  height: "3.333vw"
}

export {
  hexToRgb,
  //variables
  drawerWidth,
  transition,
  container,
  boxShadow,
  defaultFont,
  // primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  blackColor,
  whiteColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  defaultBoxShadow,
  backgroundColor,
  primaryColor,
  secondaryColor,
  graphColors,
  border,
  cardRadius,
  button,
  buttonSecondary,
  icon_16,
  icon_24,
  icon_38,
  icon_48,
  icon_64,
};
