/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
// @mui/material components
import { makeStyles } from "@mui/styles"
import { AppBar, Toolbar, Tooltip, Hidden, Button } from "@mui/material";
// @mui/icons-material
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from '@mui/icons-material/Add';
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import { setAddNewButton } from "./";

//hooks
import { useRouteName, useRouteCanCreate } from "hooks";

import chatbot from "assets/img/chatbot.svg";
import styles from "assets/jss/material-dashboard-react/shared-components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const strapiNavigation = useSelector(store => store.strapi.navigation);
  const routeName = useRouteName();
  const canCreate = useRouteCanCreate();

  const handleButtonClick_addNew = () => {
    const pathName = location.pathname.replace("/search", "");
    if (pathName.includes("device-tagging")) {
      history.push(pathName+"/edit");
    } else if (pathName.includes("admin/equipment")) {
      history.push(pathName+"/configure");
    } else {
      history.push(pathName+"/create");
    }
    dispatch(setAddNewButton(true));
  }

  const openChatbot = () => {
    props.setChatbotOpen(!props.chatbotOpen);
    localStorage.setItem('chatbotOpen', true);
  }

  const attributes = _.find(strapiNavigation, ({attributes}) => attributes.title=="Add New");
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        {/* **Hidden is deprecated in mui v5** */}
        {/* <Hidden mdUp implementation="css">
          <IconButton
            className={classes.menu} 
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}
        <div className={classes.leftContainer}>
          <div className={classes.title}>
            {routeName}
          </div>
          {canCreate &&
            <Button
              onClick={ () => handleButtonClick_addNew() }
              className={classes.addNewButton}
              startIcon={<AddIcon />}
              disabled={isDisabled}
            > 
              {attributes ? attributes.attributes.label : "Add New" + (location.pathname.includes("raw-material/receipt") ? " Request" : "")}
            </Button>
          }
        </div>
        {!props.chatbotOpen &&
          <Tooltip title="AI Chatbot" placement="top">
            <Button onClick={openChatbot}>
              <img className={classes.chatbotImg} src={chatbot} alt="chatbot" />
            </Button>
          </Tooltip>
        }
        {/* <Hidden smDown implementation="css"> */}
          <AdminNavbarLinks />
        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
