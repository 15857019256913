import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Tag from 'shared-components/Chip/Tag';
import Select from "shared-components/Select/Select";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import FilterButton from "shared-components/Button/FilterButton";

import { minDate, rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue } from 'common/functions';
import { setValues } from "../store/planningOutcome";
import { planningOutcomeTableHead } from "enums/UserPortal/TableHeaders";
import { GetWorkOrderPlanningOutcomeList, GetPlanningConfiguration } from "services/UserPortal/PlanningService";

import noOrder from "assets/img/no-order.svg";
import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function Outcomes(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const filter = useSelector(store => store.user.planning.planningOutcome.filter);
  const overallCount = useSelector(store => store.user.planning.planningOutcome.totalCount);
  const totalCount = useSelector(store => store.user.planning.planningOutcome.outcomesTotalCount);
  const outcomes = useSelector(store => store.user.planning.planningOutcome.outcomes);
  const setting = useSelector(store => store.user.planning.settings.setting);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('purchaseOrderNumber');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const handleOnChange_select = (e) => {
    props.setNewFilter({...props.newFilter, [e.target.name]: e.target.value});
  };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, deliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), deliveryEndDate: moment(value.endDate).format("YYYY-MM-DD")}}));
    }
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };
  
  const getPlanningOutcomeList = (pageNo) => {
    if (filter.workOrderId || filter.deliveryStartDate) {
      dispatch(GetWorkOrderPlanningOutcomeList({...filter, page: pageNo, stopLoading: true}))
      .then((response) => {
        if (!response.error) {
          const result = response.payload.result;
          dispatch(setValues({totalCount: 
            Number(result.workOrderPlanningOutComeList && result.workOrderPlanningOutComeList.totalCount) + 
            Number(result.excessStockList && result.excessStockList.totalCount) + 
            Number(result.unassignedOrdersList && result.unassignedOrdersList.totalCount) 
          }));
          if (result.workOrderPlanningOutComeList) {
            dispatch(setValues({outcomesTotalCount: result.workOrderPlanningOutComeList.totalCount, outcomes: result.workOrderPlanningOutComeList.items}));
          } else {
            dispatch(setValues({outcomesTotalCount: 0, outcomes: []}));
          }
        }
      });
    } else {
      dispatch(setValues({outcomesTotalCount: 0, outcomes: [], totalCount: 0}));
    }
  }

  const generateDate = () => {
    if (moment().isBefore(moment(outcomes[0].workOrderDate))) {
      return moment().format("DD MMMM YYYY, ") + moment(setting.timeToRun, "hh:mm A"). format("HHmm")
    }
    return moment(outcomes[0].workOrderDate).subtract(1, 'd').format("DD MMMM YYYY, ") + moment(setting.timeToRun, "hh:mm A"). format("HHmm");
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      if (page === 0) {
        getPlanningOutcomeList(0);
      } else {
        setPage(0);
      }
    }
  },[filter]);

  React.useEffect(() => {
    getPlanningOutcomeList(page);
  },[page]);

  React.useEffect(() => {
    dispatch(GetPlanningConfiguration());
  },[]);

  useInterval(() => {
    getPlanningOutcomeList(page);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title={outcomes.length ? "Results from Planning Run on "+generateDate() : "Results from Planning"}
        subheader={(outcomes.length && filteredKeys.length) 
        ? <React.Fragment>
            <Typography>{outcomes.length ? "SOs recommended to be added in for Operation on "+moment(outcomes[0].workOrderDate).format("DD MMMM YYYY") : ""}</Typography>
            {filteredKeys.length > 0 && (
              <React.Fragment>
                {filteredKeys.map((key) => {
                  let label = filter[key];
                  if (key === "workOrderId") {
                    const workOrder = workOrderList.find(({id}) => id == filter[key]);
                    label = workOrder && workOrder.workOrderNo;
                  }
                  return (
                    <Tag
                      key={key}
                      variant="outlined"
                      tabIndex={-1}
                      label={label}
                      className={classes.tag}
                      onDelete={() => handleButtonClick_delete(key)}
                    />
                  )
                })}
              </React.Fragment>
            )}
          </React.Fragment>
        : null
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...props.newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={props.setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the planning outcomes
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(props.newFilter.workOrderId)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Delivery date"
              className={classes.cardActionDate}
              value={{startDate: filter.deliveryStartDate, endDate: filter.deliveryEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, deliveryStartDate: null, deliveryEndDate: null}}))}
            />
          </React.Fragment>
        }
      >
        { overallCount
        ? <React.Fragment>
            <div className={classes.table}>
              <Table
                header={planningOutcomeTableHead}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { outcomes.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.workOrderNumber}</TableCell>
                      <TableCell>{item.purchaseOrderNumber}</TableCell>
                      <TableCell>{item.subSaleOrderNumber}</TableCell>
                      <TableCell>{moment(item.deliveryDate).isAfter(minDate) ? moment(item.deliveryDate).format("DD/MM/yyyy") : ""}</TableCell>
                      <TableCell>{item.productName}</TableCell>
                      <TableCell>{item.productId}</TableCell>
                      <TableCell>{item.productComponent}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(item.customerOrderWeight))}{item.uom}</TableCell>
                      <TableCell>{formatNumbers(roundTo2Decimal(item.afterLossRateWeight))}{item.uom}</TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </React.Fragment>
        : <div className={classes.noOrder}>
            <img className={classes.noOrderImg} src={noOrder} alt="no-order" />
            <Typography className={classes.noOrderText}>{filter.workOrderId ? "There are no orders selected for this work order.": "Select a work order number"}</Typography>
          </div>
        }
      </Card>
    </React.Fragment>
  );
}
