// Create Edit Devices
// Device Type List
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import IconButton from "shared-components/Button/IconButton";

import DeviceFunctions from "./DeviceFunctions";
import AddEditDeviceTypes from "./AddEditDeviceTypes";
import { rowsPerTable } from "config";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { setValues } from "./store/type";
import { GetAllDeviceType, DeleteDeviceType } from "services/AdminPortal/DeviceService";
import { deviceTypesTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/deviceStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceTypes(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const deviceTypes = useSelector(store => store.admin.device.type.deviceTypes);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('readings');
  const [page, setPage] = React.useState(0);
  const [typeId, setTypeId] = React.useState(null);   // to know which unit is selected
  const [isViewFunction, setIsViewFunction] = React.useState(false);
  const [newOrUpdate, setNewOrUpdate] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const count = Math.ceil(deviceTypes.length / rowsPerTable);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleButtonClick_edit = (id) => {
    setTypeId(id);
    setNewOrUpdate(true);
    dispatch(setValues(deviceTypes.find((deviceType) => deviceType.id === id)));
  }

  const handleModal_delete = (id) => {
    setTypeId(id);
    setOpenDeleteModal(!openDeleteModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteDeviceType(typeId))
    .then(() => {
      dispatch(GetAllDeviceType());
      resetState();
    })
  }

  const handleButtonClick_exit = () => {
    if (props.setIsDeviceType) {
      props.setIsDeviceType(false);
    } else {
      const prop = props.location && props.location.state;
      history.push((prop && prop.prevPath) || "/admin/device");
    }
  }

  const resetState = () => {
    setTypeId(null);
    setPage(0);
    setOpenDeleteModal(!openDeleteModal);
  }

  React.useEffect(() => {
    dispatch(GetAllDeviceType());
  },[isViewFunction, newOrUpdate]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(GetAllDeviceType());
    // componentDidUnmount
    return () => {
      const propLocation = props.location && props.location.state;
      if (propLocation) {
        dispatch(resetNav());
      }
    }
  },[]);

  if (isViewFunction) {
    return <DeviceFunctions setFunction={setIsViewFunction} />
  } else if (newOrUpdate) {
    return <AddEditDeviceTypes id={typeId} setTypeId={setTypeId} setNewOrUpdate={setNewOrUpdate} />
  } else {
    return (
      <React.Fragment>
        <Card 
          title="Device Types"
          action={
            <Button 
              className={classes.headerButton}
              onClick={()=>setIsViewFunction(true)}
              disabled={deviceTypes.length==0}
            >
              Add / View Libraries
            </Button>
          }
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>handleButtonClick_exit()}
              >
                Go Back
              </Button>
            </React.Fragment>
          }
        >
          <Table
            className={classes.table}
            header={deviceTypesTableHead}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          >
            { _.orderBy(deviceTypes, [orderBy], [order])
            .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
            .map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.deviceTypeName}</TableCell>
                  <TableCell>{item.deviceShortForm}</TableCell>
                  <TableCell>{item.functionLibrary && item.functionLibrary.functionLibraryName}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      type="edit" 
                      onClick={() => handleButtonClick_edit(item.id)}
                    />
                    <IconButton 
                      type="delete"
                      onClick={() => handleModal_delete(item.id )}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
          <Button
            className={clsx(classes.buttonSecondary, classes.addButton)}
            startIcon={<AddIcon />}
            onClick={()=>setNewOrUpdate(true)}
            disabled={typeId !== null}
          >
            Add New Device Type
          </Button>
        </Card>
        <Modal
          open={openDeleteModal}
          onClose={() => handleModal_delete(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this device type? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
