import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllDeliveryOrder, GetDeliveryOrderById, GetAllDriver, GetDeliveryTripLookUp, GetDeliveryOrderLookUp, UpdateDeliveryOrder } from "services/UserPortal/DeliveryService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  results: [],
  details: {},
  uploadImage: {},
  // lookup
  driverList: [],
  tripList: [],
  doList: []
};

const deliveryOrder = createSlice({
  name: "deliveryOrder",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.uploadImage[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllDeliveryOrder.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.results = action.payload.result.items;
    },
    [GetDeliveryOrderById.fulfilled]: (state, action) => {
      state.details = action.payload.result;
      state.uploadImage = {
        ..._.pick(action.payload.result, ["farmId", "id", "deliveryOrderImages"]),
        isUploadImage: true
      };
    },
    [GetAllDriver.fulfilled]: (state, action) => {
      state.driverList = action.payload.result;
    },
    [GetDeliveryTripLookUp.fulfilled]: (state, action) => {
      state.tripList = action.payload.result;
    },
    [GetDeliveryOrderLookUp.fulfilled]: (state, action) => {
      state.doList = _.sortBy(action.payload.result, ["deliveryOrderRefNo"]);
    },
    [UpdateDeliveryOrder.fulfilled]: (state, action) => {
      state.details = action.payload.result;
      state.uploadImage = {
        ..._.pick(action.payload.result, ["farmId", "id", "deliveryOrderImages"]),
        isUploadImage: true
      };
    },
  },
});

// export actions
export const { setValues, updateRequest, reset } = deliveryOrder.actions;

// export the reducer
export default deliveryOrder.reducer;