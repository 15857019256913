import React from "react";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";

import styles from "assets/jss/components/AdminPortal/utilStyle.js";
import bank from "assets/img/bank-img.svg";

const useStyles = makeStyles(styles);

export default function BankExplanation(props) {
  const classes = useStyles();

  return (
    <Card 
      classes={{
        cardActions: classes.paper
      }}
      title="Explanation of the terms"
      cardActions={
        <Button
          className={classes.buttonSecondary}
          onClick={() => props.setShowExplanation(false)}
        >
          Go Back
        </Button>
      }
    >
      <Paper className={classes.paper} elevation={0}>
        <img className={classes.bankImage} src={bank} alt="bank-img" />
        <Typography className={classes.bankDetails}>
          Above example shows a Bank setup. In this setup, it is made of 4 x Bays and 4 x Levels for the farm.
          <br/><br/>
          Each box will be indicated as a cage. For the orange colored cage, the label should be call 01-02-03 (Bank# - Bay# - Level#).
          <br/><br/>
          Admin can also configure the number of trays in each cage.
        </Typography>
      </Paper>
    </Card>
  )
}
