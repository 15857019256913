// Supplier
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
// import IconButton from "shared-components/Button/IconButton";

import Empty from "error-page/Empty";
import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { reset } from ".";
import { supplierTableHead } from "enums/AdminPortal/TableHeaders";
import { GetAllSupplier, DeleteSupplier, GetAllRawMaterialAndDevice } from "services/AdminPortal/SupplierService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/supplierStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function Supplier() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFirstUpdate = React.useRef(true);
  const canWrite = useRouteCanWrite();
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const totalCount = useSelector(store => store.admin.supplier.totalCount);
  const suppliers = useSelector(store => store.admin.supplier.suppliers);
  const rawMaterialDeviceList = useSelector(store => store.admin.supplier.rawMaterialDeviceList);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('supplierCode');
  const [page, setPage] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleModal = (id) => {
    setSupplierId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteSupplier(supplierId))
    .then(() =>{
      dispatch(GetAllSupplier({page}));
      resetState();
    })
  }

  const resetState = () => {
    setSupplierId(null);
    setPage(0);
    setOpenModal(!openModal);
  }

  const showReferenceNo = (array) => {
    let result = "";
    if (rawMaterialDeviceList.length !== 0) {
      array && array.map((item) => {
        const refNo = _.find(rawMaterialDeviceList, {"id": item}) && _.find(rawMaterialDeviceList, {"id": item}).referenceNo;
        if (result === "") {
          result = refNo;
        } else {
          result = result + ", " + refNo
        }
      })
    }
    return result
  }

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllSupplier({page}));
    dispatch(GetAllRawMaterialAndDevice());
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[page]);

  return (
    <React.Fragment>
      { suppliers.length > 0 
      ? <React.Fragment>
          <Card 
            title = "Supplier List"
          >
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(supplierTableHead, canWrite)}
                // order={order}
                // orderBy={orderBy}
                // onRequestSort={handleRequestSort}
              >
                { suppliers.map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item.supplierRefNo}</TableCell>
                      <TableCell>{item.supplierName}</TableCell>
                      <TableCell>{item.address}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.contactPerson}</TableCell>
                      <TableCell>{formatNumbers(item.creditLimit)}</TableCell>
                      <TableCell>{item.creditTerms}</TableCell>
                      <TableCell>{showReferenceNo(item.rmDeviceId)}</TableCell>
                      <TableCell align="right">
                        {/* {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/supplier/"+item.id}}>
                              <IconButton type="edit" />
                            </Link>
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal(item.id)}
                            />
                          </React.Fragment>
                        } */}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { count > 1 &&
              <Pagination 
                count={count} 
                page={page+1}
                onChange={(e, pageNo)=>setPage(pageNo-1)}
              />
            }
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content="Do you really want to delete this supplier? This process cannot be undone."
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </React.Fragment>
      : <React.Fragment>
          <Empty 
            description={"Your page is empty. " + (canWrite ? "Add a supplier to get started!" : "")}
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
}
