import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllHRAvailability } from "services/UserPortal/HumanResourceService";

// initial state
const initialState = {
  availability: [],
};

const availabilityHR = createSlice({
  name: "availabilityHR",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllHRAvailability.fulfilled]: (state, action) => {
      state.availability = _.sortBy(action.payload.result, ["employeeName"])
    },
  },
});

// export actions
export const { updateRequest, reset } = availabilityHR.actions;

// export the reducer
export default availabilityHR.reducer;