import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from 'lodash';
// @mui/material
import { makeStyles } from "@mui/styles"
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import IconButton from "shared-components/Button/IconButton";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';
import Modal from "shared-components/Modal/Modal";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import ViewMoreModal from "shared-components/Modal/ViewMoreModal";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { filterActionTableHead, formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { setSearchText } from "store/general";
import { setValues } from "../store/reserve";
import { equipmentReserveTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllEquipmentReserve, DeleteEquipmentReserve } from "services/UserPortal/EquipmentService";

import { useRouteCanWrite } from "hooks";
import styles from "assets/jss/components/UserPortal/equipmentStyle.js";

const useStyles = makeStyles(styles);

export default function Reserve() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isFirstUpdate = React.useRef(true);
  const lotIdList = useSelector(store => store.common.lotIdList);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const filter = useSelector(store => store.user.equipment.reserve.filter);
  const totalCount = useSelector(store => store.user.equipment.reserve.totalCount);
  const reservations = useSelector(store => store.user.equipment.reserve.reservations);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const equipmentList = useSelector(store => store.common.equipmentList);
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, equipmentId: null, startDate: null, endDate: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('workOrderId');
  const [deleteId, setDeleteId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value})
  };

  const handleModal = (id) => {
    setDeleteId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    dispatch(DeleteEquipmentReserve(deleteId))
    .then(() =>{
      dispatch(setValues({filter: {...filter, page: 0}}));
      getAllEquipmentReserve();
      handleModal(null);
      setDeleteId(null);
    })
  }

  const handleButtonClick_deleteTag = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };
  
  const getAllEquipmentReserve = (stopLoading) => {
    dispatch(GetAllEquipmentReserve({...filter, status: "Reserved", stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllEquipmentReserve();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllEquipmentReserve(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title = {filter.workOrderNo ? "Reservation List for "+ filter.workOrderNo : "Reservation List"}
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "equipmentId") {
                const equipment = equipmentList.find(({id}) => id == filter[key]);
                label = equipment && equipment.equipmentName;
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_deleteTag(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the issuance list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="equipmentId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select an equipment"
                    value={renderValue(newFilter.equipmentId)}
                  >
                    {equipmentList && equipmentList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.equipmentName}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Usage date"
              className={classes.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, startDate: null, endDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.mainTable}
            header={filterActionTableHead(equipmentReserveTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { reservations.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.workOrderNo}</TableCell>
                    <TableCell>{item.equipmentName}</TableCell>
                    <TableCell>{item.equipmentRefNo}</TableCell>
                    <TableCell>{moment(item.timeStampReserved).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.productRefNo}</TableCell>
                    <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.reservedQty))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.currentQty))}</TableCell>
                    <TableCell>{item.uom}</TableCell>
                    <TableCell>{moment(item.startDateTime).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>{moment(item.endDateTime).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>
                      { item.location
                      ? item.location.cageLocationRefNo 
                        ? "Near "+item.location.cageLocationRefNo 
                        : item.location.floorNumber && item.location.sectorNumber ? "Floor "+item.location.floorNumber + " Sector "+ item.location.sectorNumber : ""
                      : ""
                      }
                    </TableCell>
                    <TableCell>{item.reservedUser}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell align="right">
                      {canWrite &&
                        <React.Fragment>
                          <Link to={{pathname: "/user/equipment/reserve/"+item.id}}>
                            <IconButton type="edit" />
                          </Link>
                          {item.reservedUser !== "Planning" &&
                            <IconButton 
                              type="delete"
                              onClick={() => handleModal(item.id)}
                            />
                          }
                        </React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this reservation? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
