import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { withStyles } from "@mui/styles";
import TableRow from "@mui/material/TableRow";
import { primaryColor } from "assets/jss/generaliseStyle.js";

const TableRowCss = withStyles({
  root: {
    color: primaryColor[3],
    '& td': {
      fontSize: "0.938vw",
    },
    // '&:nth-of-type(odd)': {
    //   backgroundColor,
    // },
    // '&:nth-of-type(even)': {
    //   backgroundColor: whiteColor,
    // },
    '& td:first-child': {
      paddingLeft: "2.292vw"
    },
    '& td:last-child': {
      paddingRight: "2.292vw"
    },
  },
})(TableRow);

export default function CustomTableRow({children, ...props}) {

  return (
    <TableRowCss {...props}>
      {children}
    </TableRowCss>
  );
}

CustomTableRow.propTypes = {
  children: PropTypes.node,
};