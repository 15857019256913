import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from "@mui/material/Divider";
// @mui/icons-material components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// core components
import styles from "assets/jss/shared-components/accordionStyle.js";

const useStyles = makeStyles(styles);
export default function CardAccordion({header, children, ...props}) {
  const classes = useStyles();

  return (
    <Accordion 
      {...props}
      className={props.classes ? clsx(props.classes.root, classes.cardAccordion) : classes.cardAccordion}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
      >
        <div className={props.classes ? clsx(props.classes.header, classes.header) : classes.header}>{header}</div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Divider className={classes.divider} />
        <div className={classes.accordionContent}>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

CardAccordion.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};