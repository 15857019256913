import {
  icon_24,
  icon_64,
  border,
  cardRadius,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const finishedGoodsStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  orange,
  link,
  tag,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw !important"
  },
  // In
  mainTable: {
    '& thead > tr > th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(11)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  cardButton: {
    display: "flex",
    "& button:not(:last-child)": {
      marginRight: "1.302vw"
    },
  },
  paperDiv: {
    display: "flex",
    overflow: "overlay",
    margin: "0 -1.979vw",
    padding: "0 1.979vw 2.083vw"
  },
  paper: {
    ...cardRadius,
    backgroundColor: primaryColor[5],
    color: primaryColor[4],
    padding: "1.250vw 1.563vw",
    width: "18.073vw",
    "&:not(:last-child)": {
      marginRight: "1.563vw"
    },
    cursor: "pointer"
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  paperTitle: {
    fontSize: "1.250vw",
    fontWeight: 500,
    marginBottom: "1.042vw"
  },
  paperText: {
    "&:not(:last-child)": {
      marginBottom: "0.625vw"
    }
  },
  whiteText: {
    color: whiteColor
  },
  orangeBackground: {
    backgroundColor: secondaryColor[0]
  },
  divider: {
    margin: "0 -1.979vw",
  },
  popper: {
    width: "16.667vw"
  },

  // Add Finished Goods
  addPaper: {
    ...cardRadius,
    border,
    display: "flex",
    flexDirection: "column",
    margin: "1.563vw 15.104vw 5.208vw",
    padding: "3.646vw 7.292vw 0"
  },
  label: {
    alignSelf: "center"
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.708vw 0",
    padding: 0,
    "& button:not(:first-child)": {
      marginLeft: "1.042vw"
    }
  },

  // Master List
  masterTable: {
    '& thead > tr > th:not(:last-child)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  filterModal: {
    width: "29.167vw"
  },
}

export default finishedGoodsStyle;