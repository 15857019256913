// Create Edit Vehicle Type
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";

import { renderValue } from "common/functions";
import { setDirty } from "store/general";
import { updateVehicleTypeRequest, resetVehicleType } from "./";
import { GetVehicleType, CreateOrUpdateVehicleType } from "services/AdminPortal/VehicleService";

import styles from "assets/jss/components/AdminPortal/vehicleStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditVehicleType(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vehicleType = useSelector(store => store.admin.vehicle.vehicleType);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    let value = e.target.value;
    if (e.target.id === "shortCuts") {
      value = value.toUpperCase();
    }
    dispatch(updateVehicleTypeRequest({[e.target.id] : value}));
  };

  const handleButtonClick_exit = () => {
    props.setNewOrUpdate(false);
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      dispatch(CreateOrUpdateVehicleType())
      .then((response) => {
        if (response.error) {
          if (response.payload.message.includes("Name")) {
            setErrorMsg({field: "vehicleTypes", msg: "Vehicle type name already exist"});
          } else {
            setErrorMsg({field: "shortCuts", msg: "Vehicle type short cut already exist"});
          }
        } else {
          resetState();
        }
      })
    }
  }

  const validateFields = () => {
    if (!vehicleType.vehicleTypes) {
      setErrorMsg({field: "vehicleTypes", msg: "You must provide a vehicle type name"});
      return false;
    }
    if (!vehicleType.shortCuts) {
      setErrorMsg({field: "shortCuts", msg: "You must provide a vehicle short form"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const resetState = () => {
    props.setNewOrUpdate(false);
    dispatch(resetVehicleType());
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setDirty(true));
    if(props.id) {
      dispatch(GetVehicleType(props.id));
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetVehicleType());
      props.setTypeId(null);
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {props.id ? "Edit Vehicle Type" : "Add New Vehicle Type"}
        cardActions={
          <React.Fragment>
            <Button disabled/>
            <div>
              <Button
                className={clsx(classes.buttonSecondary, classes.buttonMargin)}
                onClick={()=>handleButtonClick_exit()}
              >
                Cancel
              </Button>
              <Button
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
              >
                Save
              </Button>
            </div>
          </React.Fragment>
        }
      >
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <TextField 
              label={<React.Fragment>{!props.id && "New "}Vehicle Type <span className={classes.orange}>*</span></React.Fragment>}
              id="vehicleTypes"
              variant="outlined" 
              inputProps={{ maxLength: 50 }}
              placeholder="Enter Vehicle Type Name Here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(vehicleType.vehicleTypes)}
              errorMsg={errorMsg}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              label={<React.Fragment>{!props.id && "New "}Shortform <span className={classes.orange}>*</span></React.Fragment>}
              id="shortCuts"
              variant="outlined" 
              inputProps={{ maxLength: 3 }}
              placeholder="Enter Shortcut Here"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(vehicleType.shortCuts)}
              errorMsg={errorMsg}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
