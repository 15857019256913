import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// core components
import Checkbox from "shared-components/Checkbox/Checkbox";

import { setValues } from "../store/flagship";
import { GetDBNames } from "services/UserPortal/SmartMeteringFlagshipService";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/smartMeteringStyle.js";

const useStyles = makeStyles(styles);

export default function ParamList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDirty = useSelector(store => store.general.isDirty);
  const databaseList = useSelector(store => store.user.smartMetering.flagship.databaseList);
  const deviceList = useSelector(store => store.user.smartMetering.flagship.deviceList);
  const [levelCollapsed, setLevelCollapsed] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);

  const handleOnCollapse = (index, dbName) => {
    if (collapsed === index) {
      setCollapsed(false);
      dispatch(setValues({deviceList: []}))
    } else {
      props.getAddresses(dbName);
      setCollapsed(index);
    }
  }

  const handleOnChange_checkbox = (e, dbName) => {
    let payload = _.cloneDeep(props.selected);
    if (payload.includes(Number(e.target.value)) && props.selectedDB.includes(dbName)) {
      payload.splice(payload.indexOf(Number(e.target.value)), 1);
    } else {
      payload.push(Number(e.target.value));
    }
    props.setSelected(payload);

    // update selected db
    let payload2 = _.cloneDeep(props.selectedDB);
    let index = payload2.findIndex((item) => item === dbName);
    if (deviceList.some(({address}) => payload.includes(Number(address)))) {
      if (index === -1) { // add db if does not exists
        payload2.push(dbName);
      }
    } else {
      if (index > -1) { // remove db if exists
        payload2.splice(index, 1);
      }
    }
    props.setSelectedDB(payload2);
  };
  
  // const handleOnChange_checkAll = (e) => {
  //   const device = databaseList.find(({mainDeviceName}) => mainDeviceName === e.target.value);
  //   let payload = _.cloneDeep(props.selected);
  //   if (device.detailList.every(({address}) => payload.includes(Number(address)))) {
  //     const addressList = device.detailList.map(({address}) => address);
  //     payload = payload.filter((element) => !addressList.filter((add) => add === element).length);
  //   } else {
  //     payload = _.uniq([...payload, ...device.detailList.map(({address}) => address)]);
  //   }
  //   props.setSelected(payload);
  // };

  React.useEffect(() => {
    dispatch(GetDBNames());
  },[]);

  return (
    <React.Fragment>
      {/* <TextField 
        id="search"
        variant="outlined" 
        placeholder="Search Addresses" 
        value={searchValue}
        InputProps={{
          startAdornment: 
            <InputAdornment position="start">
              <img src={search} alt="search" className={classes.icon_24}/>
            </InputAdornment>,
        }}
        onChange={(e) => handleOnChange_text(e)}
      /> */}
      <Typography>{props.selected.length} Addresses Selected</Typography>
      <List className={classes.list2}>
        {databaseList && databaseList.map((item, i)=>{
          return (
            <React.Fragment key={i}>
              <ListItem classes={{gutters: classes.listPadding}}>
                <React.Fragment>
                  {/* <Checkbox 
                    value={item.level} 
                    // indeterminate={deviceList.some(({address}) => props.selected.includes(Number(address))) && !deviceList.every(({address}) => props.selected.includes(Number(address)))} 
                    checked={props.selectedDB.includes(item)} 
                    // onChange={(e)=>handleOnChange_checkAll(e)}
                    disabled
                  />  */}
                  {item.level}
                  <IconButton 
                    className={levelCollapsed === i ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                    onClick={() => setLevelCollapsed(levelCollapsed === i ? null : i)}
                  >
                    <img className={classes.icon} src={arrow} alt="arrow" />
                  </IconButton>
                </React.Fragment>
              </ListItem>
              { levelCollapsed === i &&
                item.dbName.map((dbName, index) => {
                  return(
                    <React.Fragment key={index}>
                      <ListItem classes={{gutters: classes.listPadding}}>
                        <React.Fragment>
                          <Checkbox 
                            value={dbName} 
                            // indeterminate={deviceList.some(({address}) => props.selected.includes(Number(address))) && !deviceList.every(({address}) => props.selected.includes(Number(address)))} 
                            checked={props.selectedDB.includes(dbName)} 
                            // onChange={(e)=>handleOnChange_checkAll(e)}
                            disabled
                          /> 
                          {dbName}
                          <IconButton 
                            className={collapsed === index ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                            onClick={() => handleOnCollapse(index, dbName)}
                          >
                            <img className={classes.icon} src={arrow} alt="arrow" />
                          </IconButton>
                        </React.Fragment>
                      </ListItem>
                      {collapsed === index &&
                        deviceList.map((add, j)=>{
                          return (
                            <ListItem key={j} classes={{gutters: classes.subListPadding}}>
                              <Checkbox 
                                value={add.address} 
                                checked={props.selected.includes(Number(add.address)) && props.selectedDB.includes(dbName)} 
                                onChange={(e)=>handleOnChange_checkbox(e, dbName)}
                                disabled={isDirty}
                              /> {add.address} {add.use && "(" + add.use + ")"}
                            </ListItem>
                          )
                        })
                      }
                    </React.Fragment>
                  )
                })
              }
            </React.Fragment>
          )
        })}
      </List>
    </React.Fragment>
  );
}
