// Product Category Type
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import Pagination from "shared-components/Table/Pagination";
import IconButton from "shared-components/Button/IconButton";

import { rowsPerTable } from "config";
import { checkPermission } from "common/functions";
import { configureProductCategoryTypeMixTableHead, addViewGroupingsTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";
import AddEditProductCategoryTypeSetting from "./AddEditProductCategoryTypeSetting";
import { GetProductGroupByCategoryID, DeleteProductGroup, DeleteProductCategoryType, GetProductCategoryByID, GetFinishedProductForMix } from "services/AdminPortal/ProductService";
import { Typography } from "@mui/material";

const useStyles = makeStyles(styles);

export default function ProductCategoryTypeSetting(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const allProductCategoryType = useSelector(store => store.admin.product.lookup.allProductCategoryType);
  const productGroup = useSelector(store => store.admin.product.lookup.productGroup);
  const finishedCategoryTypeList = useSelector(store => store.admin.product.lookup.finishedCategoryTypeList);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('displayName');
  const [page, setPage] = React.useState(0);
  const [groupPage, setGroupPage] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [productGrouping, setProductGrouping] = React.useState(false);
  const [addEditMode, setAddEditMode] = React.useState(false);
  const [editData, setEditData] = React.useState("");
  const [type, setType] = React.useState("");
  const [id, setId] = React.useState(0);

  const productcategoryTypeCount = Math.ceil(allProductCategoryType.length / rowsPerTable);
  const groupCount = Math.ceil(productGroup.length / rowsPerTable);

  const tableHead = () => {
    configureProductCategoryTypeMixTableHead[0].label = selectedProduct.productCategoryName + " Type";
    configureProductCategoryTypeMixTableHead[1].label = selectedProduct.productCategoryName + " Shortform";
    if (!selectedProduct.combinationProduct || checkPermission("Admin.Products (Mix Product)", "none")) {
      return _.filter(configureProductCategoryTypeMixTableHead, ({id}) => {
        return id !== "productMix";
      });
    }
    return configureProductCategoryTypeMixTableHead;
  }

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleButtonClick_editProductCategoryType = (mode, selectedProductCategoryType) => {
    setProductGrouping("product");
    setEditData(selectedProductCategoryType);
    setAddEditMode(mode);
  }

  const handleButtonClick_addProductCategoryType = (mode) => {
    setProductGrouping("product");
    setAddEditMode(mode);
  }

  const handleButtonClick_editGrouping = (mode, selectedGrouping) => {
    setProductGrouping("grouping");
    setEditData(selectedGrouping);
    setAddEditMode(mode);
  }

  const handleButtonClick_goBack = () => {
    if (props.setProductCategoryTypeSetting) {
      props.setProductCategoryTypeSetting(false);
    } else {
      const prop = props.location && props.location.state;
      history.push((prop && prop.prevPath) || "/admin/products");
    }
  }

  const handleModal = (type, id) => {
    setType(type);
    setId(id);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    if (type === "grouping") {
      let body = {
        categoryId: selectedProduct.productCategoryID,
        groupId: id,
      };
      dispatch(DeleteProductGroup(body))
      .then(() => {
        setOpenModal(!openModal);
        dispatch(GetProductGroupByCategoryID(selectedProduct.productCategoryID));
      })
    } else if (type === "product") {
      let body = {
        productCategoryTypeID: id
      };
      dispatch(DeleteProductCategoryType(body))
      .then(() => {
        setOpenModal(!openModal);
        dispatch(GetProductCategoryByID(selectedProduct.productCategoryID));
      })
    }
  }

  const getProductGroupName = (id) => {
    if (productGroup && id !== 0) {
      return _.find(productGroup, {"id": id}) && _.find(productGroup, {"id": id}).productGroupName; //xtra check cz someone in BE fk up the values
      //const result = _.find(productGroup, {"id": id}).productGroupName;
    }
  }

  const displayMixList = (list) => {
    let result = [];
    if (finishedCategoryTypeList.length !== 0) {
      for (let i = 0; i < list.length; i++) {
        const categoryTypeName = _.find(finishedCategoryTypeList, {"productCategoryTypeID": list[i].productCategoryTypeRefID}) && _.find(finishedCategoryTypeList, {"productCategoryTypeID": list[i].productCategoryTypeRefID}).productCategoryName //xtra check cz someone in BE manipulated DB deleting data n fk up the values
        //const categoryTypeName = _.find(finishedCategoryTypeList, {"productCategoryTypeID": list[i].productCategoryTypeRefID}).productCategoryName
        result.push(
          <Typography>
            {categoryTypeName} ({list[i].productMixPercent}%)
          </Typography>
        );
      }
    }
    return result;
  }

  // componentDidMount
  React.useEffect(() => {
    if (selectedProduct.combinationProduct) {
      dispatch(GetFinishedProductForMix());
    }
  },[]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetProductGroupByCategoryID(selectedProduct.productCategoryID));
    dispatch(GetProductCategoryByID(selectedProduct.productCategoryID));
  },[addEditMode]);

  if (addEditMode) {
    return (
      <AddEditProductCategoryTypeSetting
        mode={addEditMode}
        type={productGrouping} 
        productType={selectedProduct.productCategoryName}
        productCategoryID={selectedProduct.productCategoryID}
        productCategoryTypeListID={selectedProduct.productCategoryTypeID}
        editData = {editData}
        setAddEditMode={setAddEditMode}
      />
    )
  } else {
    return (
      <React.Fragment>
        <Paper className={classes.catTypeContent}>
          <div className={classes.productSettingTopCard}>
            <Card 
              title = {"Configuring " + selectedProduct.productCategoryName + " Type"}
              classes={{root:classes.cardPaper}}
              cardActions={
                <React.Fragment>
                  <Button
                    className={classes.buttonSecondary}
                    onClick={() => handleButtonClick_addProductCategoryType("Add New")}
                  >
                    + Add New Product Type
                  </Button>
                </React.Fragment>
              }
            >
              <Table
                className={classes.table}
                header={tableHead()}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              >
                { _.orderBy(allProductCategoryType, [orderBy], [order])
                .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
                .map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item.productCategoryTypeName}</TableCell>
                      <TableCell>{item.productCategoryTypeShortForm}</TableCell>
                      <TableCell>{productGroup.length>0?getProductGroupName(item.productGroupId):null}</TableCell>
                      {selectedProduct.combinationProduct || !checkPermission("Admin.Products (Mix Product)", "none") && <TableCell>{displayMixList(item.productMixList)}</TableCell>}
                      <TableCell align="right">
                        <React.Fragment>
                          <IconButton 
                            type="edit"
                            onClick={() => handleButtonClick_editProductCategoryType("Edit", item)}
                          />
                          <IconButton
                            type="delete" 
                            onClick={() => handleModal("product", item.id)}
                          />
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
              { productcategoryTypeCount > 1 &&
                <Pagination 
                  count={productcategoryTypeCount} 
                  page={page+1}
                  onChange={(e, pageNo)=>setPage(pageNo-1)}
                />
              }
            </Card>
          </div>
          <Card
            title = "Add/View Groupings"
            classes={{root:classes.cardPaper}}
            cardActions={
              <React.Fragment>
                <div>
                  <div>
                    <Button
                      className={classes.buttonSecondary}
                      onClick={() => handleButtonClick_editGrouping("Add New")}
                    >
                      + Add New Grouping
                    </Button>
                  </div>
                  <Button
                    className={clsx(classes.buttonSecondary, classes.goBackButton)}
                    onClick={() => handleButtonClick_goBack()}
                  >
                    Go Back
                  </Button>
                </div>
              </React.Fragment>
            }
          >
            <React.Fragment>
              <Table
                className={classes.table}
                header={addViewGroupingsTableHead}
              >
                { _.orderBy(productGroup, [orderBy], [order])
                .slice(groupPage * rowsPerTable, groupPage * rowsPerTable + rowsPerTable)
                .map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item.productGroupName}</TableCell>
                      <TableCell align="right">
                        <React.Fragment>
                          <IconButton
                            type="edit" 
                            onClick={() => handleButtonClick_editGrouping("Edit", item)}
                          />
                          <IconButton
                            type="delete" 
                            onClick={() => handleModal("grouping", item.id)}
                          />
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
              { groupCount > 1 &&
                <Pagination 
                  count={groupCount} 
                  page={groupPage+1}
                  onChange={(e, pageNo)=>setGroupPage(pageNo-1)}
                />
              }
            </React.Fragment>
          </Card>
          <Modal
            open={openModal}
            onClose={() => handleModal(null)}
            icon={<img className={classes.icon_64} src={alert} alt="alert" />}
            title="Are you sure?"
            content={type==="grouping"?"Do you really want to delete this Group? This process cannot be undone.":"Do you really want to delete this Category Type? This process cannot be undone."}
            actions={
              <React.Fragment>
                <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
                <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
              </React.Fragment>
            }
          />
        </Paper>
      </React.Fragment>
    );
  }
}
