import React from "react";
import PropTypes from "prop-types";
// @mui/material components
import { withStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";

const TableCellCss = withStyles({
  root: {
    borderBottom: "none",
    padding: "0.833vw",
    fontSize: "0.938vw"
  }
})(TableCell);

const LeftStickyTableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "0.833vw",
    fontSize: "0.938vw",
    backgroundColor: "inherit",
    zIndex: 99,
  },
  body: {
    left: 0,
    position: "sticky"
  }
})(TableCell);

export default function CustomTableCell({children, sticky, ...props}) {
  if (sticky) {
    return (
      <LeftStickyTableCell {...props}>
        {children}
      </LeftStickyTableCell>
    );
  } else {
    return (
      <TableCellCss {...props}>
        {children}
      </TableCellCss>
    );
  }
}

CustomTableCell.propTypes = {
  children: PropTypes.node,
};