import { createSlice } from "@reduxjs/toolkit";

import { 
  GetOverallAvailability
} from "services/UserPortal/EquipmentService";

// initial state
const initialState = {
  filter: {},
  availability: [],
};

const availabilityEquip = createSlice({
  name: "availabilityEquip",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetOverallAvailability.fulfilled]: (state, action) => {
      state.availability = action.payload.result
    },
  },
});

// export actions
export const { setValues, reset } = availabilityEquip.actions;

// export the reducer
export default availabilityEquip.reducer;