import {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  collapsedIcon,
  rotate,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const codeGeneratorStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  collapsedIcon,
  rotate,
  link,
  tag,

  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },

  // Add View QR
  inputContainer: {
    display: "flex",
    width: "30%",
    "&>:not(:first-child)": {
      marginLeft: "0.677vw"
    },
  },
  qrContainer: {
    marginTop: "2.083vw",
    margin: "auto",
    width: "26.042vw",
    alignItems: "center",
    padding: "1.563vw"
  },
  qrContent: {
    textAlign: "-webkit-center",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "2.604vw",
    justifyContent: "space-between"
  }
}

export default codeGeneratorStyle;