import {
  icon_64,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
  secondaryColor,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const equipmentStyle = {
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  orange,
  tag,
  link,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  cellWidth: {
    width: "11vw",
    minWidth: "11vw"
  },
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },

  // Reserve
  mainTable: {
    '& thead > tr > th:not(:last-child):not(:nth-child(8)):not(:nth-child(9))': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  formTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "1.927vw",
    "&:not(:first-child)": {
      marginTop: "2.604vw"
    },
  },
  formLabel: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.521vw",
    whiteSpace: "nowrap",
    color: primaryColor[4],
    marginTop: "0.833vw",
    // alignSelf: "center",
    width: "7.813vw",
    minWidth: "7.813vw"
  },

  // Issue
  popper: {
    width: "16.667vw"
  },

  // Overall Availability
  highChart: {
    height: "33.698vw",
    width: "100%",
  },
  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  overallTitle: {
    fontWeight: 500,
    fontSize: "0.938vw",
    textAlign: "center"
  },
  timeline: {
    overflow: "overlay"
  },
  timelineTable: {
    borderCollapse: "separate",
    borderSpacing: "0 0.521vw"
  },
  colWidth: {
    "& > td:not(:first-child)": {
      width: "5.729vw",
      minWidth: "5.729vw",
      maxWidth: "5.729vw",
    },
    "& > td:nth-child(2)": {
      paddingLeft: "0 !important"
    },
    "& > td:first-child": {
      width: "10.417vw",
      minWidth: "10.417vw",
      maxWidth: "10.417vw"
    }
  },
  tableLabel: {
    fontWeight: 500,
    fontSize: "0.938vw",
    borderTop: "-10px solid " + whiteColor,
  },
  stickyBackground: {
    position:"absolute",
    top: "-0.625vw",
    left: 0,
    height: "1.042vw",
    width: "10.417vw",
    backgroundColor: whiteColor,
  },
  availableTimeline: {
    "&:nth-child(2)": {
      paddingLeft: "0.833vw !important",
      borderLeft: "0.156vw solid" + secondaryColor[0],
      borderTopLeftRadius: "0.938vw",
      borderBottomLeftRadius: "0.938vw",
    },
    "&:last-child": {
      paddingRight: "0.833vw !important",
      borderRight: "0.156vw solid" + secondaryColor[0],
      borderTopRightRadius: "0.938vw",
      borderBottomRightRadius: "0.938vw",
    },
    borderTop: "0.156vw solid"+secondaryColor[0],
    borderBottom: "0.156vw solid"+secondaryColor[0]+"!important",
  },
  availableTimelineLabel:{
    position:"absolute",
    top: "-0.625vw",
    color: secondaryColor[0],
    backgroundColor: whiteColor,
    fontWeight: 500,
    fontSize: "0.833vw",
    padding: "0 0.208vw",
    width: "max-content",
    zIndex: 1,
  },
  labelFont: {
    fontSize: "1.354vw",
    fontWeight: 500
  },
  tableCell: {
    position: "relative",
    borderBottom: "none",
    paddingLeft: "0 !important",
    paddingRight: "0 !important"
  },
  chip: {
    borderRadius: "1.250vw",
    padding: "0.625vw 1.016vw",
    fontSize: "0.729vw",
    fontWeight: 500,
    textAlign: "center",
    border: "0.104vw solid #000",
  },
  woChip: {
    backgroundColor: "#FFA366"
  },
  mainChip: {
    backgroundColor: "#C4C4C4"
  },
  idleChip: {
    backgroundColor: "#F6F972"
  },
  smallText: {
    fontSize: "0.833vw"
  },

  // Equipment Cost
  bold: {
    fontWeight: 500
  },

  // Maintenance Status
  statusCell: {
    width: "9vw",
    minWidth: "9vw"
  },
  actionCell: {
    display: "flex",
    // width: "10vw",
    // minWidth: "10vw"
  },
  statusButton: {
    "&:not(:first-child)": {
      marginLeft: "0.625vw"
    },
    backgroundColor: whiteColor,
    boxShadow: "0 0.104vw 0 #D3DDD3",
    borderRadius: "0.417vw",
    minWidth: "3.750vw",
    height: "2.500vw",
  },
  selectedStatusButton: {
    "&:nth-child(1)": {
      backgroundColor: "#5FC473",
      boxShadow: "inset 0 0.313vw 0.313vw #338A44",
    },
    "&:nth-child(2)": {
      backgroundColor: secondaryColor[0],
      boxShadow: "inset 0 0.313vw 0.313vw #D65500",
    },
    "&:nth-child(3)": {
      backgroundColor: "#FF3D3D",
      boxShadow: "inset 0 0.313vw 0.313vw #A30000",
    },
  },
  legend: {
    display: "flex",
    marginTop: "1.667vw",
  },
  legendIcon: {
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
}

export default equipmentStyle;