import {
  cardRadius,
  grayColor
} from "assets/jss/generaliseStyle.js";

const cardStyle = {
  card: {
    ...cardRadius,
    zIndex: 1,
    position: "relative"
  },
  header: {
    padding: "1.25vw 2.083vw",
    minHeight: "5.208vw"
  },
  margin: {
    paddingBottom: 0, 
    marginBottom: "1.250vw" 
  },
  marginTop: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "1.250vw",
    fontWeight: 500,
    marginRight: "1.563vw",
    display: "flex",
    alignItems: "center",
  },
  subheader: {
    color: grayColor[0],
    marginTop: "0.521vw",
    fontSize: "0.833vw",
    "& p": {
      fontSize: "0.833vw",
    }
  },
  action: {
    display: "flex",
    alignItems: "center",
    color: "rgba(19, 50, 43, 0.5)",
    fontSize: "1.094vw",
    margin: 0
  },
  content: {
    padding: "1.302vw 1.979vw",
    "&:last-child": {
      paddingBottom: "2.083vw",
    }
  },
  cardActions: {
    padding: "0 1.979vw 1.302vw 1.979vw ",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
}

export default cardStyle;