import {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardRadius,
  border,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  orange,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const rawMaterialStyle = {
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  cardPaper,
  table,
  icon,
  orange,
  tag,
  link,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Batch List
  mainTable: {
    '& thead > tr > th:nth-child(-n+3)': {
      width: "fit-content",
      minWidth: "8vw",
      whiteSpace: "nowrap"
    },
  },
  batchTable: {
    '& thead > tr > th:not(:last-child)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  batchAction: {
    minWidth: "fit-content",
    alignSelf: "center",
    color: primaryColor[3]
  },
  cardActionDropdown: {
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  cardActionDate: {
    marginLeft: "1.042vw",
  },
  rmDropdown: {
    width: "13.542vw",
    marginLeft: "1.042vw"
  },
  addNewButton: {
    marginTop: "1.563vw",
  },
  detail: {
    color: secondaryColor[0],
    cursor: "pointer",
  },
  subheader: {
    fontSize: "0.833vw",
  },
  // Add Edit Batch
  paper: {
    ...cardRadius,
    border,
    display: "flex",
    flexDirection: "column",
    margin: "1.563vw 15.104vw 5.208vw",
    padding: "3.646vw 6.771vw 0"
  },
  label: {
    alignSelf: "center"
  },
  label2: {
    marginTop: "0.833vw",
  },
  action: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2.708vw 0",
    padding: 0,
    "& button:not(:first-child)": {
      marginLeft: "1.042vw"
    }
  },
  lossRateSubheader: {
    color: "rgba(19, 50, 43, 0.5)",
    fontSize: "0.729vw",
  },
  //Reserve
  reserveTable: {
    '& thead > tr > th:not(:last-child)': {
      width: "fit-content",
      minWidth: "fit-content",
      whiteSpace: "nowrap"
    },
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw !important"
  },
  // Add Edit Reserve
  formTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "1.927vw",
    "&:not(:first-child)": {
      marginTop: "2.604vw"
    },
  },
  formLabel: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.521vw",
    whiteSpace: "nowrap",
    color: primaryColor[4],
    marginTop: "0.833vw",
    // alignSelf: "center",
    width: "7.813vw",
    minWidth: "7.813vw"
  },
  grid: {
    display: "flex",
  },
  //Reserve
  issueAction: {
    "& button:not(:first-child)": {
      marginLeft: "1.042vw"
    }
  },
  // Cost
  bold: {
    fontWeight: 500
  },
}

export default rawMaterialStyle;