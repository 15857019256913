import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// core components
import * as Auth from "auth/Authenticate";
import { reset } from "store/general";

import styles from "assets/jss/shared-components/modalStyle.js";

const useStyles = makeStyles(styles);
export default function AuthAlert({...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const onButtonClick = () => {
    Auth.logout();
    dispatch(reset());
    history.push("/login");
  }

  return (
    <Modal {...props} className={classes.alert}>
      <Paper className={classes.alertPaper}>
        <Typography className={classes.alertTitle}>Session Expired</Typography>
        <Divider />
        <Typography className={classes.alertContent}>Please login again</Typography>
        <div className={classes.alertActions}>
          <Button
            className={classes.button}
            onClick={()=>onButtonClick()}
          >
            OK
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

AuthAlert.propTypes = {
  onConfirm: PropTypes.func,
};