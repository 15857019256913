import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";

import { durationTableHead } from "enums/UserPortal/TableHeaders";
import { GetDurationDetailByLogSheetId } from "services/UserPortal/HumanResourceService";

import styles from "assets/jss/components/UserPortal/hrStyle.js";


const useStyles = makeStyles(styles);

export default function DetailsModal({data, title, id, ...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const result = useSelector(store => store.user.hr.logSheet.duration);

  React.useEffect(() => {
    if (data) {
      dispatch(GetDurationDetailByLogSheetId(data[id]));
    }
  },[data]);

  return (
    <Modal
      {...props}
      className={classes.detailModal}
      classes={{
        content: classes.modalContent
      }}
      title={data && "Duration Details for "+data[title]}
      content={
        <Table
          className={classes.table}
          header={durationTableHead}
        >
          { result.length 
          ? result.map((item,index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.startTime}</TableCell>
                  <TableCell>{item.endTime}</TableCell>
                  <TableCell>{item.duration}</TableCell>
                </TableRow>
              </React.Fragment>
            )
          })
        : <React.Fragment>
            <TableRow>
              <TableCell>Nil</TableCell>
              <TableCell>Nil</TableCell>
              <TableCell>Nil</TableCell>
            </TableRow>
          </React.Fragment>
        }
        </Table>
      }
      actions={
        <React.Fragment>
          <Button className={classes.button} onClick={()=>props.onClose()}>Okay</Button>
        </React.Fragment>
      }
    />
  );
}
