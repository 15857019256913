import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { setValues } from "../store/planningOutcome";
import { excessProductionTableHead } from "enums/UserPortal/TableHeaders";
import { GetWorkOrderPlanningOutcomeList } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function Excess() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.planning.planningOutcome.filter);
  const totalCount = useSelector(store => store.user.planning.planningOutcome.excessTotalCount);
  const excess = useSelector(store => store.user.planning.planningOutcome.excess);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productId');
  const [page, setPage] = React.useState(0);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  
  const getPlanningOutcomeList = (pageNo) => {
    if (filter.workOrderId) {
      dispatch(GetWorkOrderPlanningOutcomeList({...filter, page: pageNo, stopLoading: true}))
      .then((response) => {
        if (!response.error) {
          const result = response.payload.result.excessStockList;
          if (result) {
            dispatch(setValues({excessTotalCount: result.totalCount, excess: result.items}));
          } else {
            dispatch(setValues({excessTotalCount: 0, excess: []}));
          }
        }
      });
    } else {
      dispatch(setValues({excessTotalCount: 0, excess: []}));
    }
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      if (page) {
        getPlanningOutcomeList(0);
      } else {
        setPage(0);
      }
    }
  },[filter]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getPlanningOutcomeList(page);
    }
  },[page]);

  useInterval(() => {
    getPlanningOutcomeList(page);
  });

  return (
    <React.Fragment>
      {totalCount
      ? <Card 
          classes={{root: classes.excessCard}}
          title="Excess Production from Farm"
        >
          <div className={classes.table}>
            <Table
              header={excessProductionTableHead}
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
            >
              { excess.map((item,index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.productId}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.weight))}{item.uom}</TableCell>
                  </TableRow>
                )
              })}
            </Table>
          </div>
          { count > 1 &&
            <Pagination 
              count={count} 
              page={page+1}
              onChange={(e, pageNo)=>setPage(pageNo-1)}
            />
          }
        </Card>
      : null
      }
    </React.Fragment>
  );
}
