import { createSlice } from "@reduxjs/toolkit";

import { GetSidebar, GetNavigation, GetContent } from "services/StrapiService";

// initial state
const initialState = {
  sidebar: [],
  navigation: [],
  content: {}
};

// create reducer and action creators
const strapi = createSlice({
  name: "strapi",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [GetSidebar.fulfilled]: (state, action) => {
      state.sidebar = action.payload.data;
    },
    [GetNavigation.fulfilled]: (state, action) => {
      state.navigation = action.payload.data;
    },
    [GetContent.fulfilled]: (state, action) => {
      state.content = action.payload.data;
    },
  }
});

// export actions
export const { reset } = strapi.actions;

// export the reducer
export default strapi.reducer;