import React from "react";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

import styles from "assets/jss/error-page/errorStyle.js";

const useStyles = makeStyles(styles);

export default function Error() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <CircularProgress
          variant="indeterminate"
          className={classes.progress}
          thickness={6}
        />
        <Typography className={classes.loadingDesc}>Loading, please wait...</Typography>
      </div>
    </div>
  );
}
