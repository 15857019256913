// Raw Material Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable } from "config";
import { resetSearch } from "./index";
import { setSearchText } from "store/general";
import { rawMaterialTableHead, configureRawMaterialTypeTableHead, configureVariantTypeTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchRawMaterial, DeleteRawMaterial, DeleteRawMaterialType, DeleteRawMaterialVariantType } from "services/AdminPortal/RawMaterialService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/rawMaterialStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchRawMaterialResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const searchResultsCount = useSelector(store => store.admin.rawMaterial.searchResultsCount);
  const searchResults = useSelector(store => store.admin.rawMaterial.searchResults);
  const [order, setOrder] = React.useState({rawMaterial: 'asc', type: 'asc', variant: 'asc'});
  const [orderBy, setOrderBy] = React.useState({rawMaterial: 'RMID', type: 'rawMaterialTypeName', variant: 'variantTypeName'});
  const [page, setPage] = React.useState({rawMaterial: 0, type: 0, variant: 0});
  const [id, setId] = React.useState(null);
  const [table, setTable] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState({rawMaterial: false, type: false, variant: false});

  const rawMaterialCount = Math.ceil(searchResultsCount.rawMaterialList / rowsPerTable);
  const typeCount = Math.ceil(searchResultsCount.rawMaterialTypeList / rowsPerTable);
  const variantCount = Math.ceil(searchResultsCount.rawMaterialVariantTypeList / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRequestSortRawMaterial = (e, property) => {
    const isAsc = orderBy.rawMaterial === property && order.rawMaterial === 'asc';
    setOrder({...order, rawMaterial: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, rawMaterial: property});
  };
  
  const handleRequestSortType = (e, property) => {
    const isAsc = orderBy.type === property && order.type === 'asc';
    setOrder({...order, type: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, type: property});
  };
  
  const handleRequestSortVariant = (e, property) => {
    const isAsc = orderBy.variant === property && order.variant === 'asc';
    setOrder({...order, variant: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, variant: property});
  };

  const handleModal = (table, id) => {
    setId(id);
    setTable(table);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    if (table === "rawMaterial") {
      dispatch(DeleteRawMaterial(id))
      .then(() =>{
        dispatch(SearchRawMaterial(params.keyword));
        resetState();
      })
    } else if (table === "type") {
      dispatch(DeleteRawMaterialType(id))
      .then(() =>{
        dispatch(SearchRawMaterial(params.keyword));
        resetState();
      })
    } else if (table === "variant") {
      dispatch(DeleteRawMaterialVariantType(id))
      .then(() =>{
        dispatch(SearchRawMaterial(params.keyword));
        resetState();
      })
    }
  }

  const resetState = () => {
    setId(null);
    setPage({rawMaterial: 0, type: 0, variant: 0});
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
  dispatch(resetSearch());
    dispatch(SearchRawMaterial(params.keyword));
  },[params.keyword]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(resetSearch());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  const totalCount = _.isEmpty(searchResultsCount) ? 0 : Object.values(searchResultsCount).reduce((a, b) => a + b);
  const totalTableCount = Object.keys(searchResults).length;
  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(totalCount) + " Search Result(s) From " + totalTableCount + " Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount.rawMaterialList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.rawMaterialList + " Result(s) From Raw Material List"}
              <IconButton 
                className={collapsed.rawMaterial ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, rawMaterial: !collapsed.rawMaterial})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.rawMaterial && 
            <React.Fragment>
              <div className={classes.table}>
                <Table
                  header={filterActionTableHead(rawMaterialTableHead, canWrite)}
                  order={order.rawMaterial}
                  orderBy={orderBy.rawMaterial}
                  onRequestSort={handleRequestSortRawMaterial}
                >
                  { _.orderBy(searchResults.rawMaterialList, [orderBy.rawMaterial], [order.rawMaterial])
                  .slice(page.rawMaterial * rowsPerTable, page.rawMaterial * rowsPerTable + rowsPerTable)
                  .map((rawMaterial,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{highlightValue(rawMaterial.rawMaterialRefNo)}</TableCell>
                        <TableCell>{highlightValue(rawMaterial.rawMaterialName)}</TableCell>
                        <TableCell>{highlightValue(rawMaterial.rawMaterialDescription)}</TableCell>
                        <TableCell>{highlightValue(rawMaterial.unitOfMeasurement)}</TableCell>
                        <TableCell>{highlightValue(rawMaterial.safetyLevel)}</TableCell>
                        <TableCell>{highlightValue(formatNumbers(rawMaterial.currentQty))}</TableCell>
                        <TableCell>{highlightValue(formatNumbers(rawMaterial.requestLeadTime))}</TableCell>
                        <TableCell align="right">
                          {canWrite &&
                            <React.Fragment>
                              <Link to={{pathname: "/admin/raw-material/"+rawMaterial.id, state: {prevPath: location.pathname+location.search}}}>
                                <CustomIconButton type="edit" />
                              </Link>
                              <CustomIconButton 
                                type="delete"
                                onClick={() => handleModal("rawMaterial", rawMaterial.id)}
                              />
                            </React.Fragment>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </div>
              { rawMaterialCount > 1 &&
                <Pagination 
                  count={rawMaterialCount} 
                  page={page.rawMaterial+1}
                  onChange={(e, pageNo)=>setPage({...page, rawMaterial: pageNo-1})}
                />
              }
            </React.Fragment>
          }
        </Card>
      }
      { searchResultsCount.rawMaterialTypeList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.rawMaterialTypeList + " Result(s) From Raw Material Type"}
              <IconButton 
                className={collapsed.type ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, type: !collapsed.type})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.type && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(configureRawMaterialTypeTableHead, canWrite)}
                order={order.type}
                orderBy={orderBy.type}
                onRequestSort={handleRequestSortType}
              >
                { _.orderBy(searchResults.rawMaterialTypeList, [orderBy.type], [order.type])
                .slice(page.type * rowsPerTable, page.type * rowsPerTable + rowsPerTable)
                .map((type,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{highlightValue(type.rawMaterialTypeName)}</TableCell>
                      <TableCell>{highlightValue(type.rawMaterialTypeShortForm)}</TableCell>
                      <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/raw-material/type/"+type.id, state: {prevPath: location.pathname+location.search}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton
                              type="delete" 
                              onClick={() => handleModal("type", type.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { typeCount > 1 &&
              <Pagination 
                count={typeCount} 
                page={page.type+1}
                onChange={(e, pageNo)=>setPage({...page, type: pageNo-1})}
              />
            }
          </React.Fragment>
          }
        </Card>
      }
      { searchResultsCount.rawMaterialVariantTypeList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.rawMaterialVariantTypeList + " Result(s) From Raw Material Variant Type"}
              <IconButton 
                className={collapsed.variant ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, variant: !collapsed.variant})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.variant && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(configureVariantTypeTableHead, canWrite)}
                order={order.variant}
                orderBy={orderBy.variant}
                onRequestSort={handleRequestSortVariant}
              >
                { _.orderBy(searchResults.rawMaterialVariantTypeList, [orderBy.variant], [order.variant])
                .slice(page.variant * rowsPerTable, page.variant * rowsPerTable + rowsPerTable)
                .map((variant,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{highlightValue(variant.variantTypeName)}</TableCell>
                      <TableCell>{highlightValue(variant.variantTypeShortForm)}</TableCell>
                      <TableCell align="right">
                        {canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/raw-material/variant/"+variant.id, state: {prevPath: location.pathname+location.search}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton
                              type="delete" 
                              onClick={() => handleModal("variant", variant.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { variantCount > 1 &&
              <Pagination 
                count={variantCount} 
                page={page.variant+1}
                onChange={(e, pageNo)=>setPage({...page, variant: pageNo-1})}
              />
            }
          </React.Fragment>
          }
        </Card>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to delete this item? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
