// Admin Log
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import DateTabs from "shared-components/Tabs/DateTabs";

import { rowsPerTable } from "config";
import { reset } from ".";
import { adminLogTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchAdminLog, ExportAdminLog } from "services/AdminPortal/AdminLogService";

import styles from "assets/jss/components/AdminPortal/adminLogStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');
export default function AdminLog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.admin.adminLog.totalCount);
  const logs = useSelector(store => store.admin.adminLog.logs);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('employeeId');
  const [page, setPage] = React.useState(0);
  const [date, setDate] = React.useState({startDate: moment().subtract(7,'d').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD")});

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleButtonClick_export = () => {
    dispatch(ExportAdminLog({startDate: date.startDate, endDate: date.endDate}))
    .then((response) => {
      FileDownload(response.payload.data, 'admin_log.xlsx');
    });
  };

  React.useEffect(() => {
    dispatch(SearchAdminLog({page, startDate: date.startDate, endDate: date.endDate}));
  },[page, date]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      title = "Administration Logs"
      action={
        <React.Fragment>
          <DateTabs setDate={setDate} date={date} />
          <Button 
            className={classes.buttonSecondary}
            onClick={() => handleButtonClick_export()}
          >
            Export
          </Button>
        </React.Fragment>
      }
    >
      <div className={classes.table}>
        <Table
          header={adminLogTableHead}
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
        >
          { logs.map((item,index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.eventType}</TableCell>
                <TableCell>{item.eventDetail}</TableCell>
                <TableCell>{item.portalType}</TableCell>
                <TableCell>{item.userName}</TableCell>
                <TableCell>{moment(item.date).format("DD/MM/YYYY H:mm:ss")}</TableCell>
                <TableCell>{item.clientIpAddress}</TableCell>
              </TableRow>
            )
          })}
        </Table>
      </div>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
    </Card>
  );
}
