import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { minDate, rowsPerTable } from "config";
import { reset } from "../store/search";
import { setSearchText } from "store/general";
import { equipmentStatusTableHead } from "enums/UserPortal/TableHeaders";
import { SearchEquipmentStatus, UpdateStatusEquipmentMaintenance } from "services/UserPortal/EquipmentService";

import calendar from "assets/icons/orange/calendar.svg";
import use from "assets/icons/others/in-use.svg";
import maintenance from "assets/icons/orange/in-maintenance.svg";
import down from "assets/icons/others/is-down.svg";
import use_selected from "assets/icons/white/in-use.svg";
import maintenance_selected from "assets/icons/white/in-maintenance.svg";
import down_selected from "assets/icons/white/is-down.svg";
import arrow from "assets/icons/orange/droplist-arrow.svg";
import styles from "assets/jss/components/UserPortal/equipmentStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchStatusResult() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const equipmentList = useSelector(store => store.common.equipmentList);
  const totalCount = useSelector(store => store.user.equipment.search.statusTotalCount);
  const results = useSelector(store => store.user.equipment.search.statusResults);
  const [page, setPage] = React.useState(0);
  const [collapsed, setCollapsed] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleButtonClick_icon = (param) => {
    dispatch(UpdateStatusEquipmentMaintenance(param))
    .then(() => {
      dispatch(SearchEquipmentStatus({keyword: params.keyword, page}));
    });
  };

  const renderIcon = (currentStatus, equipmentId, id, equipment) => {
    const status = ["In Operation", "In Maintenance", "Is Down"];
    const boolean = ["isOperation", "isMaintenance", "isDown"];
    const icon = [use, maintenance, down];
    const icon_selected = [use_selected, maintenance_selected, down_selected];
    let component = [];
    for (let i = 0; i < status.length; i++) {
      component.push(
        <Button 
          key={i}
          className={currentStatus === status[i] ? clsx(classes.statusButton, classes.selectedStatusButton) : classes.statusButton}
          onClick={()=>{ i === 1
            ? history.push({pathname: "/user/equipment/maintenance-status/"+(id ? id : "create"), state: {equipment: equipment, isDisabled: true, id: id}})
            : handleButtonClick_icon({...Object.assign(...boolean.map(k=>({[k]:false}))), equipmentConfigId: equipmentId, [boolean[i]]: true, id})
          }}
          disabled={(currentStatus === status[i]) || !canWrite}
        >
          {currentStatus === status[i]
          ? <img className={classes.icon} src={icon_selected[i]} alt="status" />
          : <img className={classes.icon} src={icon[i]} alt="status" />
          }
        </Button>
      )
    }
    return component
  }

  React.useEffect(() => {
    dispatch(SearchEquipmentStatus({keyword: params.keyword, page, stopLoading: Boolean(page)}));
  },[params.keyword, page]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={[params.keyword]}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  return (
    <React.Fragment>
      { totalCount > 0 &&
        <React.Fragment>
          <Card 
            classes={{
              root: classes.result,
            }}
            title = {
              <React.Fragment>
                {totalCount + " Result(s) From Status List"}
                <IconButton 
                  className={collapsed ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <img className={classes.icon} src={arrow} alt="arrow" />
                </IconButton>
              </React.Fragment>
            }
          >
            { !collapsed &&
              <React.Fragment>
                <div className={classes.table}>
                  <Table
                    header={equipmentStatusTableHead}
                  >
                    { _.map(results, (item,index) => {
                      const equipment = _.find(equipmentList, ({id}) => id === item.equipmentConfigId);
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>{highlightValue(item.equipmentRefNo)}</TableCell>
                            <TableCell>{highlightValue(item.equipmentName)}</TableCell>
                            <TableCell>{highlightValue(item.operationHourFromLastMaintenance)}</TableCell>
                            <TableCell>{highlightValue(moment(item.lastMaintenanceDate).isAfter(minDate) ? moment(item.lastMaintenanceDate).format("DD/MM/yyyy") : "")}</TableCell>
                            <TableCell>{highlightValue(item.hoursLimitToScheduleForMaintenance)}</TableCell>
                            <TableCell>
                              {(moment(item.lastMaintenanceDate).isAfter(minDate) && moment(item.expectedDateToRequireMaintenance).isAfter(minDate)) 
                              ? moment(item.expectedDateToRequireMaintenance).format("DD/MM/yyyy") 
                              : ""
                              }
                            </TableCell>
                            <TableCell>{highlightValue(item.status)}</TableCell>
                            <TableCell>
                              <div className={classes.actionCell}>
                                {renderIcon(item.status, item.equipmentConfigId, item.id, equipment)}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              {item.scheduleForMaintenance 
                              ? item.scheduleForMaintenance
                              : <Link to={!canWrite?"#":{pathname: "/user/equipment/maintenance-status/"+(item.id ? item.id : "create"), state: {equipment: equipment, id: item.id}}}>
                                  <IconButton>
                                    <img className={classes.icon} src={calendar} alt="calendar"/>
                                  </IconButton>
                                </Link>
                              }
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })}
                  </Table>
                </div>
                { count > 1 &&
                  <Pagination 
                    count={count} 
                    page={page+1}
                    onChange={(e, pageNo)=>setPage(pageNo-1)}
                  />
                }
              </React.Fragment>
            }
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
