import {
  icon_24,
  icon_38,
  icon_64,
  cardRadius,
  border,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
  secondaryColor,
  graphColors,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  subTable,
  icon,
  cardPaper,
  result,
  collapsedIcon,
  rotate,
  highlight,
  loading,
  link,
  tag,
} from "assets/jss/components/commonStyle.js";

const climateStyle = {
  icon_24,
  icon_64,
  cardRadius,
  button,
  buttonSecondary,
  orange,
  icon,
  table,
  subTable,
  cardPaper,
  loading,
  link,
  tag,

  // Search
  result,
  collapsedIcon,
  rotate,
  highlight,

  cardActionDate: {
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },
  cardHeader: {
    alignSelf: "baseline"
  },
  cardMargin: {
    marginTop: "2.604vw"
  },
  buttonMargin: {
    marginLeft: "1.563vw"
  },
  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center",
    textAlign: "left"
  },
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
  },
  legend: {
    display: "flex",
    marginTop: "1.667vw",
    marginLeft: "1.563vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  colorBox: {
    border,
    width: "1.146vw",
    height: "1.146vw",
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  legendColor: {
    "&:nth-child(1)": {
      backgroundColor: graphColors[0],
    },
    "&:nth-child(2)": {
      backgroundColor: graphColors[1],
    },
    "&:nth-child(3)": {
      backgroundColor: graphColors[2],
    },
    "&:nth-child(4)": {
      backgroundColor: graphColors[3],
    },
    "&:nth-child(5)": {
      backgroundColor: graphColors[4],
    }
  },
  heatMapLegend: {
    textAlign: "center",
    width: "5.573vw",
    fontWeight: 500
  },
  smallText: {
    fontSize: "0.833vw"
  },
  // Device Control
  bankView: {
    overflow: "overlay",
    width: "-webkit-fill-available",
    maxHeight: "26.042vw",
  },
  floorPlanView: {
    padding: "1.042vw 2.083vw",
    overflow: "overlay",
    width: "-webkit-fill-available",
    maxHeight: "26.042vw",
    "&>div:first-child": {
      width: "initial",
    }
  },
  markerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-40%, -20%)",
    cursor: "pointer"
  },
  marker: {
    ...icon_38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: primaryColor[8],
    borderRadius: "50%",
    fontSize: "0.938vw",
    fontWeight: 500,
    backgroundColor: "#a1adaa",
    //border: "0.208vw solid " + secondaryColor[0],
  },
  markerText: {
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
    backgroundColor: "#FFFFFFA6"
  },
  off: {
    backgroundColor: "#a1adaa",
    color: whiteColor
  },
  notUse: {
    backgroundColor: primaryColor[4],
    color: whiteColor
  },
  on: {
    backgroundColor: primaryColor[9],
    color: whiteColor
  },
  selected: {
    backgroundColor: secondaryColor[0],
    color: whiteColor
  },
  notAvailable: {
    backgroundColor: primaryColor[1],
    color: whiteColor
  },
  legendIcon: {
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  modalTab: {
    marginBottom: 0,
    paddingBottom: "1.25vw",
  },
  detailModalContent: {
    marginRight: 0,
    paddingLeft: "3.333vw",
    position: 'relative',
  },
  detailModal: {
    paddingLeft: "0.833vw",
    paddingRight: "0.833vw",
    width: "52.083vw",
  },
  carouselButton: {
    left: 0,
    top: 0,
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-0.833vw',
    marginRight: '-0.833vw',
    position: "absolute",
    height: "100%",
    width: "auto",
    padding: "0.833vw",
    fontWeight: 500,
    fontSize: "0.938vw",
    transition: "0.6s ease",
    borderRadius: "0 0.156vw 0.156vw 0",
    userSelect: "none",
    "&:hover:not(:disabled)": {
      cursor: "pointer",
      backgroundColor: '#DDD',
    }
  },
  next: {
    left: 'unset',
    right: 0,
    borderRadius: "0.156vw 0 0 0.156vw",
  },
  mainTable: {
    '& thead > tr > th:nth-child(2)': {
      width: "12vw",
      minWidth: "12vw"
    },
  },
  modalGrid: {
    maxHeight: "24.833vw",
    overflowY: "overlay",
    overflowX: "hidden"
  },
  modalGridItem: {
    display: "flex",
    textAlign: "left",
    alignItems: "center"
  },
  topRightTabSpacing: {
    marginLeft: "1.465vw",
  },
  bankFloorTab: {
    border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw",
    marginLeft: "2.604vw"
  },
  bankFloorLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minHeight: "1.875vw"
  },
  selectedTab: {
    color: secondaryColor[0] + " !important",
    backgroundColor: secondaryColor[0] + "20",
  },
  moreList: {
    border,
    ...cardRadius,
    height: "13.021vw",
    overflow: "overlay",
    marginTop: "1.250vw"
  },
  // Device Control Form
  formTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "1.927vw",
    "&:not(:first-child)": {
      marginTop: "2.604vw"
    },
  },
  formLabel: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.521vw",
    whiteSpace: "nowrap",
    color: primaryColor[4],
    marginTop: "0.833vw",
    // alignSelf: "center",
    width: "7.813vw",
    minWidth: "7.813vw"
  },
  ruleGrid: {
    paddingBottom: "1.927vw",
  },
  ruleTitle: {
    fontSize: "1.094vw",
    fontWeight: 500,
    alignSelf: "center",
  },
  grid: {
    display: "flex",
  },
  gridButton: {
    textAlign: "right",
  },
  switchLabel: {
    marginRight: "0.521vw",
    alignSelf: "center",
  },
  switch: {
    alignSelf: "center",
  },
  checkbox: {
    "&:first-child": {
      marginLeft: "2.604vw",
    },
    marginRight: "0.573vw",
    alignSelf: "baseline"
  },
  checkboxPadding: {
    marginLeft: "10.417vw",
    marginRight: "0.573vw",
    alignSelf: "center"
  },
  checkboxLabel: {
    marginRight: "2.292vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  checkboxLabel2: {
    alignSelf: "start",
    marginTop: "0.625vw"
  },
  error: {
    marginLeft: "11.458vw",
    marginTop: "-0.833vw",
    color: "#FF0000",
    fontSize: "0.729vw",
  },
  helperText: {
    textAlign: "right",
    fontSize: 12,
  },

  // Climate Monitoring
  dateIndicator: {
    backgroundColor: "transparent"
  },
  filterButton: {
    marginRight: "0.938vw"
  },
  filterModal: {
    width: "34.375vw",
  },
  filterContent: {
    display: "flex",
    alignItems: "center",
    marginTop: "1.042vw !important",
    textAlign: "left"
  },
  helper: {
    fontSize: "0.729vw",
    color: "#AAAAAA"
  },
  filterLabelGrid: {
    alignSelf: "flex-start",
  },
  filterLabel: {
    marginTop: "0.417vw",
    color: primaryColor[4],
  },
  unitSelectContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  unitSelect: {
    width: "7.813vw",
  },
  filterPaper: {
    border,
    borderRadius: "0 0.313vw 0.313vw ",
    padding: "0.469vw 0.625vw",
    width: "20.260vw",
    height: "15.156vw",
    textAlign: "left"
  },
  filterTabLabel: {
    border: '0.104vw solid #DDDDDD',
    borderRight: "none",
    borderRadius: "0.313vw 0 0 0.313vw",
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    opacity: 1,
    padding: "0.521vw 0.625vw",
    minHeight: "1.875vw",
    maxWidth: "10.729vw",
    "&:not(:first-child)": {
      marginTop: "0.313vw"
    },
    "& > span": {
      alignItems: "start",
      textAlign: "-webkit-left"
    },
  },
  selectedFilter: {
    ...orange,
  },
  // list: {
  //   overflow: "overlay",
  //   marginTop: "0.521vw",
  //   height: "10.938vw",
  // },
  listPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  highChart: {
    display: "flex",
    justifyContent: "center",
    // height: "31.250vw",
    width: "100%",
    overflow: "visible !important"
  },
  paramDropdown: {
    marginBottom: "1.100vw",
  },
  leftCardContent: {
    height: "33.292vw"
  },
  list: {
    height: "20.833vw",
    marginTop: "1.042vw",
    overflow: "overlay",
  },
  listItem: {
    display: "flex",
    marginLeft: "-1.042vw",
    "& :first-child": {
      marginRight: "1.042vw"
    }
  },
  graphContent: {
    display: "flex",
    padding: "1.563vw",
    position: "relative",
    // height: "38vw",
  },
  paper: {
    position: "relative",
    // overflow: "overlay",
    height: "29vw",
    marginRight: "-1.563vw",
    paddingRight: "1.563vw"
  },
  formIconButton: {
    border,
    position: "absolute",
    right: "-2.292vw",
    top: "-0.052vw",
    borderRadius: 0,
    borderLeft: 0,
    backgroundColor: 'white',
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  formIconButton2: {
    border,
    position: "absolute",
    left: "-1.979vw",
    top: 0,
    borderRadius: 0,
    borderLeft: 0,
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
  },
  formButton: {
    display: 'flex',
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  graphContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  progress: {
    color: secondaryColor[0],
    margin: "auto"
  },
  progress2: {
    color: secondaryColor[0],
    margin: "auto",
    marginLeft: "1.25vw"
  },
  graphText: {
    margin: "auto"
  },

  // Date Tab
  dateContainer: {
    display: "flex",
    marginLeft: "auto",
    marginTop: "-1.250vw",
    marginBottom: "0.250vw",
  },
  dateTab: {
    // border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw",
    marginBottom: "0.250vw",
    marginLeft: "auto",
    height: "3vw",
    width: "fit-content"
  },
  dateLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minWidth: "4.167vw !important",
    maxWidth: "4.167vw",
    minHeight: "1.875vw"
  },
  selectedHourTab: {
    color: secondaryColor[0] + " !important",
    backgroundColor: secondaryColor[0] + "20",
  },

  // Sensor Settings
  sensorTitle:{
    fontSize: "1.094vw",
    fontWeight: 500,
    marginBottom: "1.823vw"
  },
  switchAction: {
    display: "flex",
    alignItems: "center"
  },

  // Sensor Control
  content: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  leftContent: {
    width: "25.104vw !important"
  },
  subContent: {
    width: "100%",
    '&:first-child': {
      marginRight: "0.625vw",
    },
    '&:last-child': {
      marginLeft: "0.625vw",
    },
    '&:not(:first-child):not(:last-child)': {
      margin: "0 0.625vw",
    },
  },
  newPaper: {
    ...cardRadius,
    border,
    padding: "2.083vw 1.563vw"
  },
  select: {
    marginBottom: "0.938vw"
  },
  funcTable: {
    '& thead > tr > th': {
      top: "-1.302vw",
      left: "unset"
    },
    "& tbody > tr": {
      backgroundColor: whiteColor + " !important"
    }
  },
  dropdown: {
    width: "10.417vw",
    maxWidth: "10.417vw"
  },
  addButton: {
    marginTop: "1.563vw"
  },

  // Pairing Configuration
  pairPaper: {
    ...cardPaper,
    margin: "auto",
    width: "54.271vw",
    overflow: "initial"
  },
  pairCell: {
    verticalAlign: "top"
  },
  cellButton: {
    marginTop: "0.208vw",
  },

  // Alarms
  bottomCard: {
    marginTop: "1.750vw"
  },
}

export default climateStyle;