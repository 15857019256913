import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { GetAllEquipment, GetEquipment, GetLibraryList, GetAllUOM, GetProductNameList, SearchEquipment } from "services/AdminPortal/EquipmentService";

// initial state
const initialState = {
  totalCount: 0,
  equipments: [],
  equipment: {},
  product: {},
  libraries: [],
  functions: [],
  config: [],
  uom: [],
  searchResultsCount: 0,
  searchResults: [],
};

// create reducer and action creators
const equipment = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateConfigRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.config[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.equipment[key] = action.payload[key];
      })
    },
    updateProductRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.product[key] = action.payload[key];
      })
    },
    resetEquipment: (state) => {
      state.equipment = {};
    },
    resetProduct: (state) => {
      state.product = {};
    },
    resetFunction: (state) => {
      state.functions = [];
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllEquipment.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.equipments = action.payload.result.items;
    },
    [GetEquipment.fulfilled]: (state, action) => {
      state.equipment = action.payload.result;
    },
    [GetLibraryList.fulfilled]: (state, action) => {
      state.libraries = _.orderBy(action.payload.result, ["functionLibraryName"], ["asc"]);
    },
    [GetAllUOM.fulfilled]: (state, action) => {
      state.uom = action.payload.result;
    },
    [GetProductNameList.fulfilled]: (state, action) => {
      state.products = action.payload.result;
    },
    [SearchEquipment.fulfilled]: (state, action) => {
      state.searchResultsCount = action.payload.result.totalCount;
      state.searchResults = action.payload.result.items;
    },
  },
});

export const { setValues, 
  updateConfigRequest, updateRequest, updateProductRequest, updatePackageRequest,
  resetEquipment, resetProduct, resetFunction, resetSearch, reset } = equipment.actions;

// export the reducer
export default equipment.reducer;