import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Modal from 'shared-components/Modal/Modal';
import Popper from "shared-components/Popper/Popper";
import Tag from 'shared-components/Chip/Tag';
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import FilterButton from "shared-components/Button/FilterButton";
import ViewMoreModal from 'shared-components/Modal/ViewMoreModal';

import { setDirty } from "store/general";
import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal, renderValue, filterParam } from 'common/functions';
import { setSearchText } from "store/general";
import { setValues, updateRequest } from "../store/issue";
import { equipmentIssueTableHead } from "enums/UserPortal/TableHeaders";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { GetAllEquipmentReserve, SaveIssuedEquipment } from "services/UserPortal/EquipmentService";

import styles from "assets/jss/components/UserPortal/equipmentStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function Issue() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.equipment.issue.filter);
  const lotIdList = useSelector(store => store.common.lotIdList);
  const totalCount = useSelector(store => store.user.equipment.issue.totalCount);
  const issues = useSelector(store => store.user.equipment.issue.issues);
  const issuedId = useSelector(store => store.user.equipment.issue.issuedId);
  const workOrderList = useSelector(store => store.common.workOrderList);
  const userList = useSelector(store => store.common.userList);
  const equipmentList = useSelector(store => store.common.equipmentList);
  const productList = useSelector(store => store.common.productList);
  const [newFilter, setNewFilter] = React.useState({workOrderNo: null, status: null, startDate: null, endDate: null, page: 0});
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('workOrderId');
  const [issue, setIssue] = React.useState({});
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openIssueModal, setOpenIssueModal] = React.useState(false);

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, startDate: moment(value.startDate).format("YYYY-MM-DD"), endDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleModal_issue = (item) => {
    setOpenIssueModal(!openIssueModal);
    if (item) {
      setIssue({equipmentReserveIdList: [item.id], equipment: item});
    } else {
      setIssue({});
    }
  };

  const handleOnChange_issue = (e) => {
    if (e.target.id) {
      setIssue({...issue, [e.target.id]: e.target.value});
    } else {
      setIssue({...issue, [e.target.name]: e.target.value});
    }
  };
  
  const handleButtonClick_issue = () => {
    dispatch(SaveIssuedEquipment(issue))
    .then(({error}) => {
      if (!error) {
        dispatch(setValues({filter: {...filter, page: 0}}));
        dispatch(setDirty(false));
        setOpenIssueModal(!openIssueModal);
        getAllEquipment(0);
      }
    });
  }

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(issuedId);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      payload.push(e.target.id);
    }
    dispatch(updateRequest(payload));
  };

  const handleButtonClick_save = () => {
    dispatch(SaveIssuedEquipment())
    .then(() => {
      dispatch(setValues({filter: {...filter, page: 0}}));
      dispatch(setDirty(false));
      getAllEquipment(0);
      dispatch(updateRequest([]));
    });
  };
  
  const getAllEquipment = (stopLoading) => {
    dispatch(GetAllEquipmentReserve({...filter, stopLoading}));
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllEquipment();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetProductList());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllEquipment(true);
  });

  const filteredKeys = Object.keys(filter).filter(key => filter[key] && key !== "page" && !key.includes("Date"));
  return (
    <React.Fragment>
      <Card 
        title = "Issuance List"
        subheader={filteredKeys.length > 0 
        ? <React.Fragment>
            {filteredKeys.map((key) => {
              let label = filter[key];
              if (key === "equipmentId") {
                const equipment = equipmentList.find(({id}) => id == filter[key]);
                label = equipment && equipment.equipmentName;
              }
              if (key === "productId") {
                const product = productList.find(({id}) => id == filter[key]);
                label = product && product.productName+"("+product.productRefNo+")";
              }
              return (
                <Tag
                  key={key}
                  variant="outlined"
                  tabIndex={-1}
                  label={label}
                  className={classes.tag}
                  onDelete={() => handleButtonClick_delete(key)}
                />
              )
            })}
          </React.Fragment>
        : null}
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the issuance list
                  <Select
                    className={classes.filterDropdown}
                    name="workOrderNo"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a work order"
                    value={renderValue(newFilter.workOrderNo)}
                  >
                    {workOrderList && workOrderList.map((item, index) => {
                      return <MenuItem key={index} value={item.workOrderNo}>{item.workOrderNo}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="status"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a status"
                    value={renderValue(newFilter.status)}
                  >
                    <MenuItem value={"Issued"}>Issued</MenuItem>
                    <MenuItem value={"Reserved"}>Reserved</MenuItem>
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="productId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a product"
                    value={renderValue(newFilter.productId)}
                  >
                    {productList && productList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.productName} ({item.productRefNo})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="equipmentId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select an equipment"
                    value={renderValue(newFilter.equipmentId)}
                  >
                    {equipmentList && equipmentList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.equipmentName}</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="lotId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a Lot ID"
                    value={renderValue(newFilter.lotId)}
                  >
                    {lotIdList && lotIdList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Usage date"
              className={classes.cardActionDate}
              value={{startDate: filter.startDate, endDate: filter.endDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, startDate: null, endDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
        cardActions={
          <React.Fragment>
            <Button disabled/>
            {canWrite &&
              <Button 
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
                disabled={issuedId && issuedId.length === 0}
              >
                Save
              </Button>
            }
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            className={classes.mainTable}
            header={equipmentIssueTableHead}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { issues.map((item,index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{item.workOrderNo}</TableCell>
                    <TableCell>{item.equipmentName}</TableCell>
                    <TableCell>{item.equipmentRefNo}</TableCell>
                    <TableCell>{moment(item.timeStampReserved).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.productRefNo}</TableCell>
                    <TableCell>{item.lotId && <ViewMoreModal list={item.lotId} title="Lot ID" />}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.reservedQty))}</TableCell>
                    <TableCell>{formatNumbers(roundTo2Decimal(item.currentQty))}</TableCell>
                    <TableCell>{item.uom}</TableCell>
                    <TableCell>{moment(item.startDateTime).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>{moment(item.endDateTime).format("DD/MM/yyyy HH:mm")}</TableCell>
                    <TableCell>
                      { item.location
                      ? item.location.cageLocationRefNo 
                        ? "Near "+item.location.cageLocationRefNo 
                        : item.location.floorNumber && item.location.sectorNumber ? "Floor "+item.location.floorNumber + " Sector "+ item.location.sectorNumber : ""
                      : ""
                      }
                    </TableCell>
                    <TableCell>{item.reservedUser}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.issuedUser ? moment(item.issuedTimeStamp).format("DD/MM/yyyy HH:mm") : ""}</TableCell>
                    <TableCell>{item.issuedUser}</TableCell>
                    <TableCell align="center">
                      <Popper
                        noButton
                        open={openPopper === index}
                        className={classes.popper}
                        closePopper={() => setOpenPopper(false)}
                        placement="left"
                        content="Unable to issue equipment due to technical difficulties. Click on the checkbox to overwrite."
                      >
                        <div 
                          onMouseOver={()=>item.unableToIssue && setOpenPopper(index)} 
                          onMouseOut={()=>setOpenPopper(false)}
                          onClick={()=>item.unableToIssue && handleModal_issue(item)}
                        >
                          <Checkbox 
                            id={String(item.id)}
                            checked={Boolean(item.issuedUser || issuedId.includes(String(item.id)))}
                            onChange={(e) => handleButtonClick_checkbox(e)}
                            disabled={Boolean(item.unableToIssue || item.issuedUser || !canWrite)}
                          />
                        </div>
                      </Popper>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <Modal
        open={openIssueModal}
        onClose={() => handleModal_issue()}
        title="Issuance of Equipment Overwrite"
        content={
          <React.Fragment>
            Overwrite issuance for {issue.equipment&&issue.equipment.equipmentRefNo} ({issue.equipment&&issue.equipment.workOrderNo})
            <Select
              className={classes.filterDropdown}
              name="approvedUserId"
              onChange={(e)=>handleOnChange_issue(e)}
              placeholder="Select approved person"
              value={renderValue(issue.approvedUserId)}
            >
              {userList && userList.map((item, index) => {
                return <MenuItem key={index} value={item.id}>{item.userName}</MenuItem>;
              })} 
            </Select>
            <TextField
              className={classes.filterDropdown}
              id="reason"
              variant="outlined" 
              inputProps={{ maxLength: 255 }}
              placeholder="Add reason"
              onChange={(e) => handleOnChange_issue(e)}
              value={renderValue(issue.reason)}
            />
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_issue()}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_issue()}>Save</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
