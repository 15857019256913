// Create Edit Role
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SubdirectoryArrowRight from '@mui/icons-material/SubdirectoryArrowRight';
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Radio from 'shared-components/Radio/Radio';
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Tabs from "shared-components/Tabs/Tabs";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { roleModuleTableHead } from "enums/AdminPortal/TableHeaders";
import { updateRequest, updateModuleRequest, resetRole } from ".";

import { CreateOrUpdateRole, GetAllModules, GetRoleData } from "services/AdminPortal/RoleService";

import styles from "assets/jss/components/AdminPortal/roleStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditRole() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const role = useSelector(store => store.admin.role.role);
  const [tab, setTab] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_radio = (e) => {
    dispatch(updateModuleRequest({name:e.target.name, value:e.target.value}));
    if (e.target.name === "Human Resource" && e.target.value === "none") {
      dispatch(updateModuleRequest({name:"Log Sheet Admin", value:e.target.value}));
      dispatch(updateModuleRequest({name:"Labour Cost", value:e.target.value}));
    }
    if (e.target.name === "Operations" && e.target.value === "none") {
      dispatch(updateModuleRequest({name:"Task Assignment", value:e.target.value}));
    }
    if (e.target.name === "Delivery" && e.target.value === "none") {
      dispatch(updateModuleRequest({name:"Assignment", value:e.target.value}));
    }
    // if (e.target.name === "Quality Control" && e.target.value === "none") {
    //   dispatch(updateModuleRequest({name:"QCGrowthReport", value:e.target.value}));
    // }
  };

  const handleButtonClick = () => {
    if (validateFields()) {
      Promise.all([
        dispatch(setDirty(false))
      ])
      .then(() => {
        dispatch(CreateOrUpdateRole())
        .then(({error})=>{
          if (error) {
            dispatch(setDirty(true));
            setErrorMsg({field: "name", msg: "Role name already exists"});
          } else {
            history.push("/admin/role");
          }
        });
      })
    }
  };

  const validateFields = () => {
    if (_.isEmpty(role.name)) {
      setErrorMsg({field: "name", msg: "Please enter a role name"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  const checkParentPermission = (name) => {
    if (name === "Log Sheet Admin" || name === "Labour Cost") {
      const index = _.findIndex(role.module, ['name', "Human Resource"]);
      return role.module[index].value === "none"
    } else if (name === "Task Assignment") {
      const index = _.findIndex(role.module, ['name', "Operations"]);
      return role.module[index].value === "none"
    } else if (name === "Assignment") {
      const index = _.findIndex(role.module, ['name', "Delivery"]);
      return role.module[index].value === "none"
    } 
    // else if (name === "QCGrowthReport") {
    //   const index = _.findIndex(role.module, ['name', "Quality Control"]);
    //   return role.module[index].value === "none"
    // }
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "role");
    if (path[index+1] === "create") {
      dispatch(setDirty(true));
      dispatch(GetAllModules());
    } else {
      dispatch(GetRoleData(path[index+1]))
      .then((response) => {
        if (response.error) {
          history.push("/admin/role");
        } else {
          if (response.payload.result.name === "Admin") {
            history.push("/admin/role");
          }
          dispatch(setDirty(true));
        }
      });
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetRole());
      dispatch(resetNav());
    }
  },[]);

  const subModules = ["Log Sheet Admin", "Labour Cost", "Task Assignment", "Assignment", "QCGrowthReport", "Admin.Products (Mix Product)"];
  const moduleList = tab === 0 ? _.filter(role.module, (el) => el.name.includes("Admin.")) : _.filter(role.module, (el) => !el.name.includes("Admin."));
  return (
    <Card 
      classes={{
        content: classes.cardContent,
      }}
      title="Role Creation" 
      subheader="Please key in details for customized roles." 
      cardActions={
        <React.Fragment>
          <Button disabled/>
          <Button
            className={classes.button}
            onClick={()=>handleButtonClick()}
          >
            {role.id? "Update" : "Create Role"}
          </Button>
        </React.Fragment>
      }
    >
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <Paper className={classes.leftPaper} elevation={0}>
            <TextField 
              label={<React.Fragment>Role Name <span className={classes.orange}>*</span></React.Fragment>}
              id="name"
              variant="outlined" 
              inputProps={{ maxLength: 32 }}
              placeholder="Enter Role Name"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(role.name)}
              errorMsg={errorMsg}
            />
            <TextField 
              label="Description of Role"
              id="description"
              variant="outlined" 
              inputProps={{ maxLength: 5000 }}
              placeholder="Enter Role Description"
              onChange={(e) => handleOnChange_text(e)}
              value={renderValue(role.description)}
              multiline
              minRows={4}
              maxRows={13}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Card 
            classes={{
              root: classes.cardPaper,
              content: classes.rightCardContent
            }}
            title="Module(s) Tag to Role"
            subheader={
              <Tabs 
                tabs={["Admin Portal", "User Portal"]}
                value={tab} 
                onChange={(e, value) => setTab(value)}
              />
            }
          >
            <Table
              stickyHeader
              className={clsx(classes.table, classes.tableHeader)}
              header={roleModuleTableHead}
            >
              {moduleList.map((module, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {subModules.indexOf(module.name) > -1 ? <SubdirectoryArrowRight className={classes.subdirectoryArrowRight} /> : null}
                      {module.name.replace("Admin.", "")}
                    </TableCell>
                    <TableCell align="center">
                      <Radio 
                        checked={module.value === "read"}
                        onChange={(e)=>handleOnChange_radio(e)}
                        value="read"
                        name={module.name}
                        disabled={checkParentPermission(module.name)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Radio
                        checked={module.value === "readEdit"}
                        onChange={(e)=>handleOnChange_radio(e)}
                        value="readEdit"
                        name={module.name}
                        disabled={checkParentPermission(module.name)}
                      />
                    </TableCell>
                    {!(module.name.includes("Company") || module.name==="Farm")
                    ? <TableCell align="center">
                        <Radio
                          checked={module.value === "none"}
                          onChange={(e)=>handleOnChange_radio(e)}
                          value="none"
                          name={module.name}
                        />
                      </TableCell>
                    : <TableCell />
                    }
                  </TableRow>
                )
              })}
            </Table>
          </Card>
        </Grid>
      </Grid>
    </Card>
  )
}
