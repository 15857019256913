import { createSlice } from "@reduxjs/toolkit";

import { GetAllSupplier, GetSupplier, SearchSupplier, GetAllRawMaterialAndDevice } from "services/AdminPortal/SupplierService";

// initial state
const initialState = {
  suppliers: [],
  supplier: {},
  rawMaterialDeviceList: [],
  searchResultsCount: 0,
  searchResults: [],

};

const supplier = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.supplier[key] = action.payload[key];
      })
    },
    resetSupplier: (state) => {
      state.supplier = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllSupplier.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.suppliers = action.payload.result.items;
    },
    [GetSupplier.fulfilled]: (state, action) => {
      state.supplier = action.payload.result;
    },
    [GetAllRawMaterialAndDevice.fulfilled]: (state, action) => {
      state.rawMaterialDeviceList = action.payload.result;
    },
    [SearchSupplier.fulfilled]: (state, action) => {
      state.searchResultsCount = action.payload.result.totalCount;
      state.searchResults = action.payload.result.items;
    },
  },
});

// export actions
export const { updateRequest, resetSupplier, resetSearch, reset } = supplier.actions;

// export the reducer
export default supplier.reducer;