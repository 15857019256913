/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// core components
import Card from 'shared-components/Card/Card';

import WorkOrder from "./WorkOrder";
import { farmConfig } from "enums/Constants";
import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function AllOverview(props) {
  const classes = useStyles();
  const bankOverview = useSelector(store => store.user.monitoring.production.bankOverview.bankSummary);

  const bankSummary = bankOverview && _.sortBy(bankOverview, ['bankName']);
  return (
    <div className={classes.cardContainer}>
      <Card
        classes={{
          root: clsx(classes.cardPaper, classes.cardLeft),
          title: classes.cardMargin,
          content: classes.cardMargin
        }}
        title={farmConfig.bank+" Summary"}
      >
        <Table className={classes.dashboard}>
          <TableRow>
            <TableCell className={clsx(classes.noBorder, classes.tableCage)} />
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(classes.noBorder, classes.tableTitle, classes.tableBank)} align="center">{item.bankName}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.border, classes.tableTitle)}>Assigned {farmConfig.cages}</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(classes.border)} align="center">{item.assignedCages}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.border, classes.tableTitle)}>Unassigned {farmConfig.cages}</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(classes.border)} align="center">{item.unAssignedCages}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.border, classes.tableTitle)}>{farmConfig.cages} Reserved</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(classes.border)} align="center">{item.reservedCages}</TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.border, classes.tableTitle)}>Total {farmConfig.cages}</TableCell>
            { bankSummary && bankSummary.map((item, index)=>{
              return (
                <TableCell key={index} className={clsx(classes.border, classes.tableTotal)} align="center">{item.totalCages}</TableCell>
              )
            })}
          </TableRow>
        </Table>
      </Card>
      <WorkOrder bankId={props.bankId} />
    </div>
  );
}
