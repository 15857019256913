import {
  cardRadius,
  border,
  icon_24,
  icon_64,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  cardPaper,
  cardTitle,
  link,
} from "assets/jss/components/commonStyle.js";

const deviceStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  orange,
  icon,
  cardPaper,
  cardTitle,
  link,

  // Search Device
  result,
  
  collapsedIcon,
  rotate,
  highlight,

  // Display device
  table,
  bottomCard: {
    marginTop: "1.750vw"
  },
  ellipsis: {
    width: "16.667vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deviceTableCell: {
    width: "10vw",
    minWidth: "10vw"
  },
  iconButton: {
    height: "fit-content",
    marginRight: "-0.625vw",
    marginLeft: "1.771vw"
  },
  iconButton_end: {
    float: "right",
    marginTop: "-0.625vw",
    marginRight: "-0.625vw",
  },
  headerAction: {
    display: "flex",
    alignItems: "center"
  },
  headerLabel: {
    color: primaryColor[3],
    marginRight: "1.042vw",
    width: "6.250vw"
  },
  headerSelect: {
    width: "11.771vw"
  },
  hierarchy: {
    padding: "1.823vw 0",
    overflow: "overlay",
  },
  node: {
    display: "inline-block",
    border: "0.260vw solid #82E362",
    padding: "1.823vw 0",
    width: "12.500vw",
    fontSize: "1.354vw",
    fontWeight: 500,
  },
  parentNode: {
    border: "0.260vw solid #456F45",
  },
  childNode: {
    border: "0.260vw solid " + secondaryColor[0],
  },
  legendContainer: {
    position: "relative",
  },
  legend: {
    position: "absolute",
    marginTop: "-1.302vw",
    marginRight: "-1.979vw",
    right: 0,
    color: primaryColor[3],
    width: "14.010vw",
    padding: "1.510vw",
    borderLeft: border,
    borderBottom: border,
    borderRadius: "0 0 0 0.833vw"
  },
  legendDesc: {
    display: "flex",
    alignItems: "center"
  },
  legendDot: {
    width: "0.729vw",
    height: "0.729vw",
    borderRadius: "50%",
    marginRight: "1.250vw",
    backgroundColor: "#82E362"
  },
  parentDot: {
    backgroundColor: "#456F45",
  },
  childDot: {
    backgroundColor: secondaryColor[0],
  },

  // Add Device
  headerButton: {
    ...buttonSecondary,
    marginLeft: "1.667vw"
  },
  buttonMargin: {
    marginRight: "1.042vw"
  },
  hide: {
    display: "none"
  },
  content: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  subContent: {
    width: "100%",
    '&:first-child': {
      marginRight: "0.625vw",
    },
    '&:last-child': {
      marginLeft: "0.625vw",
    },
    '&:not(:first-child):not(:last-child)': {
      margin: "0 0.625vw",
    },
  },
  leftContent: {
    width: "38.125vw",
  },
  rightContent: {
    width: "18.438vw",
    marginLeft: "2.083vw",
  },
  paper: {
    ...cardRadius,
    border,
    padding: "2.083vw 1.979vw",
    '&:not(:first-child)': {
      marginTop: "1.563vw",
    },
  },
  infoPaper: {
    paddingTop: "1.719vw",
  },
  paperTitle: {
    color: primaryColor[3],
    fontWeight: 500,
  },
  functionContainer: {
    margin: "0 -1.979vw",
    height: "-webkit-fill-available",
  },
  functionScroll: {
    width: "100%",
    height: "100%",
    overflow: "overlay",
    borderRadius: "0 0 0.833vw 0.833vw",
  },
  functionTable: {
    width: "100%",
    marginTop: "1.563vw"
  },
  tableRow: {
    "& td": {
      width: "50%",
    },
    "& td:first-child": {
      paddingRight: "1.250vw",
    },
    "& td:not(:first-child)": {
      paddingLeft: "1.250vw",
    }
  },
  tableCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "0.625vw"
  },
  checkboxPadding: {
    marginTop: "0.75vw"
  },
  leftField: {
    width: "100%",
  },
  marginTop: {
    marginTop: "1.563vw"
  },
  rightField: {
    marginTop: "3.125vw",
    alignSelf: "center"
  },
  description: {
    height: "18.333vw"
  },
  function: {
    height: "35.677vw"
  },
  empty: {
    display: "flex",
    height: "-webkit-fill-available",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1.094vw",
    fontWeight: 500,
    color: "rgba(19, 50, 43, 0.25)",
  },
  iconButton_helper: {
    margin: "-0.938vw 0 -0.938vw 0.833vw",
    padding: 0
  },
  helperIcon: {
    ...icon_24,
    padding: 0,
  },
  popper: {
    width: "23.021vw",
  },
  addButton: {
    marginTop: "1.042vw",
  },
  hierarchyField: {
    display: "flex",
    margin: "1.563vw 0 0.365vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  deleteButton: {
    marginTop: "1.563vw",
    alignSelf: "center"
  },

  // Reading Unit

  // Device Functions
  alignTextfield: {
    marginTop: "1.400vw"
  },
  funcLeftContent: {
    width: "32.396vw",
    // marginRight: "5.104vw !important"
  },
  radioContainer: {
    display: "flex",
    marginTop: "1.563vw",
  },
  radioLabel: {
    alignSelf: "center",
    '&:not(:first-child)': {
      marginLeft: "2.031vw"
    }
  },
  disabledLabel: {
    color: primaryColor[0]
  },
  funcTable: {
    "& tbody > tr": {
      backgroundColor: whiteColor + " !important"
    }
  },
  funcTextField: {
    minHeight: "inherit",
    "& div > input": {
      padding: "0.750vw 1.042vw"
    }
  },
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
  },
  
  // Batch Update
  batchPaper: {
    ...cardRadius,
    textAlign: "center",
    padding: "3.021vw"
  },
  img: {
    margin: "auto",
    width: "26.406vw",
    height: "19.479vw"
  },
  batchTitle:{
    margin: "1.250vw",
    fontWeight: 500,
    fontSize: "1.771vw",
    color: primaryColor[4],
  },
  batchDesc: {
    fontSize: "1.094vw",
    color: "rgba(17, 42, 17, 0.7)",
    marginBottom: "1.250vw"
  },
  batchButton: {
    ...buttonSecondary,
    margin: "0 0.833vw",
  },
  batchModal: {
    padding: "2.708vw 6.771vw",
    width: "37.083vw"
  },
  batchContent: {
    textAlign: "left",
  },
  batchLabel: {
    color: primaryColor[3],
    marginTop: "2.604vw",
    marginBottom: "0.365vw",
  },
  batchSelect: {
    width: "23.542vw"
  },
  errorList: {
    fontWeight: "700"
  },
}

export default deviceStyle;