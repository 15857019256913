// Company Edit
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Cropper from "shared-components/Modal/Cropper";

import { setDirty } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { timeUnitLookup } from "enums/Lookups.js";
import { renderValue } from "common/functions";
import { updateImg, updateRequest, resetCompany } from "./";
import { UploadCompanyLogo } from "services/AdminPortal/UploadService";
import { GetUserProfile } from "services/AdminPortal/UserService";
import { GetCompany, CreateOrUpdateCompany } from "services/AdminPortal/CompanyService";

import image from "assets/icons/black/image.svg";
import styles from "assets/jss/components/AdminPortal/companyStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditCompany(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const inputFile = React.createRef();
  const isDirty = useSelector(store => store.general.isDirty);
  const company = useSelector(store => store.admin.company.company);
  const companySizeLookup = useSelector(store => store.lookup.companySizeLookup);
  const timezoneLookup = useSelector(store => store.lookup.timezoneLookup);
  const countryLookup = useSelector(store => store.lookup.countryLookup);
  const currencyLookup = useSelector(store => store.lookup.currencyLookup);
  const industryLookup = useSelector(store => store.lookup.industryLookup);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [openCropModal, setOpenCropModal] = React.useState(false);

  const onButtonClick_image = () => {
    inputFile.current.click();
  }

  const handleOnChange_image = (e) => {
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    handleModal_crop();
    e.target.value = "";
  }

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateRequest({[e.target.name] : e.target.value}));
  };

  const handleModal_crop = () => {
    setOpenCropModal(!openCropModal);
  }

  const handleButtonClick_crop = (blob) => {
    setOpenCropModal(false);
    setImageFile(blob);
    dispatch(updateImg(URL.createObjectURL(blob)));
  }

  const handleButtonClick_exit = () => {
    const prop = props.location && props.location.state;  // from search
    if (prop) {
      history.push(prop.prevPath);
    } else {
      history.push("/admin/company");
    }
  }
  const createOrUpdateCompany = () => {
    dispatch(CreateOrUpdateCompany())
    .then((response) => {
      if (response.error) {
        dispatch(setDirty(true));
      } else {
        dispatch(GetUserProfile());
        history.push("/admin/company");
      }
    });
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        if (imageFile) {
          dispatch(UploadCompanyLogo(imageFile))
          .then(() => {
            createOrUpdateCompany();
          })
        } else {
          createOrUpdateCompany();
        }
      })
    }
  };

  const validateFields = () => {
    if (_.isEmpty(company.imageUrl)) {
      setErrorMsg({field: "imageFile", msg: "Please upload an image"});
      return false;
    }
    if (_.isEmpty(company.companyName)) {
      setErrorMsg({field: "companyName", msg: "Please add in your company name"});
      return false;
    }
    if (_.isEmpty(company.address)) {
      setErrorMsg({field: "address", msg: "Please add in your company address"});
      return false;
    }
    if (_.isEmpty(company.companySize)) {
      setErrorMsg({field: "companySize", msg: "Please select one of the options"});
      return false;
    }
    if (_.isEmpty(company.industry)) {
      setErrorMsg({field: "industry", msg: "Please select one of the options"});
      return false;
    }
    if (_.isEmpty(company.country)) {
      setErrorMsg({field: "country", msg: "Please select one of the options"});
      return false;
    }
    if (_.isEmpty(company.currency)) {
      setErrorMsg({field: "currency", msg: "Please select one of the options"});
      return false;
    }
    if (_.isEmpty(company.timeZone)) {
      setErrorMsg({field: "timeZone", msg: "Please select one of the options"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    const prop = props.location.state;
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.findIndex((item) => item === "company");
    if (path[index+1] === "create") {
      const companyId = JSON.parse(localStorage.getItem("userDetail")).companyId;
      if (companyId) {
        history.push((prop && prop.prevPath) || "/admin/company");
      } else {
        dispatch(setDirty(true));
      }
    } else {
      dispatch(GetCompany(path[index+1]))
      .then(({error}) => {
        if (error) {
          history.push((prop && prop.prevPath) || "/admin/company");
        } else {
          dispatch(setDirty(true));
        }
      });
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      dispatch(resetCompany());
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Please key in your company details"
      >
        <Paper className={classes.newPaper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Company Logo <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8} className="flex">
              <input 
                ref={inputFile} 
                style={{"display": "none"}} 
                type="file" 
                accept="image/*"
                onChange={(e) => handleOnChange_image(e)}
              />
              <FormControl>
                <div className={errorMsg.field === "imageUrl" ? clsx(classes.imgBorder, classes.imgBorderError) : classes.imgBorder}>
                  { company.imageUrl 
                  ? <img className={clsx(classes.cardRadius, classes.addImg)} src={company.imageUrl} alt="company-pic" />
                  : <img className={classes.newIcon} src={image} alt="company-pic" />
                  }
                </div>
                {errorMsg.field === "imageFile" && <FormHelperText className={classes.errorMsg}>{errorMsg.msg}</FormHelperText>}
              </FormControl>
              <Button className={classes.uploadButton} onClick={onButtonClick_image}>Upload Image</Button>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Name <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="companyName"
                variant="outlined" 
                inputProps={{ maxLength: 100 }}
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Please enter your company name"
                value={renderValue(company.companyName)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Address <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="address"
                variant="outlined" 
                inputProps={{ maxLength: 255 }}
                multiline
                minRows={4}
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Please enter your address"
                value={renderValue(company.address)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Company Size <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="companySize"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(company.companySize)}
                placeholder="Select One"
                errorMsg={errorMsg}
              >
                {companySizeLookup && companySizeLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Industry <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="industry"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(company.industry)}
                placeholder="Select One"
                errorMsg={errorMsg}
              >
                {industryLookup && _.orderBy(industryLookup, ["displayName"], ["asc"]).map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })}
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Country <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="country"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(company.country)}
                placeholder="Select a Country"
                errorMsg={errorMsg}
              >
                {countryLookup && countryLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.countryName}>{item.countryName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Currency <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="currency"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(company.currency)}
                placeholder="Please select an option"
                errorMsg={errorMsg}
              >
                {currencyLookup && currencyLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Time Zone <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="timeZone"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(company.timeZone)}
                placeholder="Select a Timezone"
                errorMsg={errorMsg}
              >
                {timezoneLookup && timezoneLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })} 
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Time Unit :</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="timeUnit"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(company.timeUnit)}
                placeholder="Select a Time Unit"
              >
                {timeUnitLookup && timeUnitLookup.map((item, index) => {
                  return <MenuItem key={index} value={item.displayName}>{item.displayName}</MenuItem>;
                })} 
              </Select>
            </Grid>
          </Grid>
          <div className={classes.action}>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleButtonClick_exit()}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleButtonClick_save()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Card>
      <Cropper
        open={openCropModal}
        onClose={() => handleModal_crop()}
        imageFile={imageUrl}
        onConfirm={(blob) => handleButtonClick_crop(blob)}
      />
    </React.Fragment>
  );
}
