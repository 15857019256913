import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Buffer } from 'buffer';
// @mui/material
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
// core components
import Portal from "layouts/Portal.js";
import Authentication from "layouts/Authentication.js";
import Form from "layouts/Form.js";

// import swDev from "./swDev";
import { interceptor } from "services";
import { border } from "assets/jss/generaliseStyle.js";
import 'react-date-range/dist/styles.css'; // react date range
import 'react-date-range/dist/theme/default.css'; // react date range
import "assets/css/tailwind.css";
import "assets/css/fullcalendar-vars.css";
import "assets/css/index.css";
import "assets/css/maps.css";

import store from "store";

const theme = createTheme({
  // Typography
  typography: {
    h3: {
      fontSize: "2.500vw"
    },
    h4: {
      fontSize: "1.771vw"
    },
    subtitle1: {
      fontSize: "0.833vw"
    },
    body1: {
      fontSize: "0.938vw"
    },
    body2: {
      fontSize: "0.729vw"
    },
    caption: {
      fontSize: "0.625vw"
    }
  },
  components: {
    // Divider
    MuiDivider: {
      styleOverrides: {
        root: {
          height: "0.052vw",
          borderColor: "#DDD",
          marginTop: "0 !important",
          marginBottom: "0 !important"
        }
      }
    },
    // Alert
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "0.313vw",
          padding: "0.833vw"
        },
        icon: {
          fontSize: "1.250vw",
          padding: "0",
          marginRight: "0.625vw",
        },
        message: {
          padding: "0",
          fontSize: "0.938vw"
        }
      }
    },
    // TextField
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0.860vw 0.625vw !important",
          borderRadius: "0.313vw",
        },
        adornedStart: {
          paddingLeft: "0.729vw"
        },
        adornedEnd: {
          paddingRight: "0.729vw"
        },
        multiline: {
          padding: "0.860vw 0.625vw",
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "0.313vw",
          fontSize: "0.938vw",
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.729vw",
          marginTop: "0.156vw"
        },
        contained: {
          marginLeft: "0",
          marginRight: "0",
          textAlign: "right"
        }
      }
    },
    // Select
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: "0.313vw",
          paddingBottom: "0.313vw",
          minHeight: "2.500vw"
        }
      }
    },
    // AutoComplete
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            padding: "0 2.500vw 0 0.729vw !important",
          },
          width: "100%",
          flexWrap: "unset"
        },
        option: {
          paddingTop: "0.313vw",
          paddingBottom: "0.313vw",
          paddingLeft: "0.833vw",
          paddingRight: "0.833vw"
        }
      }
    },
    // Chip
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          height: "1.146vw",
          width: "1.146vw"
        }
      }
    },
    // Button
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "0.938vw",
          minWidth: "7.292vw",
          borderRadius: "0.208vw",
          maxHeight: "2.917vw",
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.250vw !important"
        }
      }
    },
    // Tab
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "2.500vw",
        },
        scroller: {
          overflowX: "hidden"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "8.333vw !important",
          maxWidth: "fit-content",
          minHeight: "2.500vw",
          padding: "0.938vw 1.563vw"
        },
        wrapper: {
          minWidth: "max-content",
          flexDirection: "row",
        },
        textColorInherit: {
          opacity: 1,
          color: "#899895"
        }
      }
    },
    PrivateTabIndicator: {
      styleOverrides: {
        root: {
          height: "0.104vw"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0.833vw"
        }
      }
    },
    // Pagination
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: "1.667vw",
          width: "min-content",
          minWidth: "1.667vw",
          fontSize: "0.938vw"
        },
        outlined: {
          border,
          margin: 0,
        }
      }
    },
    // Accordion
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&[class*="Mui-expanded"]': {
            margin: "0.833vw 0"
          }
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 1.563vw",
          minHeight: "unset !important",
        },
        content: {
          margin: "1.250vw 0 !important"
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0.417vw 1.563vw 1.875vw"
        }
      }
    },
    // List
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: "0.417vw",
          paddingBottom: "0.417vw"
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: "0.417vw",
          paddingBottom: "0.417vw"
        },
        gutters: {
          paddingLeft: "0.833vw",
          paddingRight: "0.833vw"
        }
      }
    },
  }
});

const scrollbarStyle = {
  '*::-webkit-scrollbar': {
    width: "6px",
    height: "6px",
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: "#AAAAAA00",
    borderRadius: "6px",
    transition: "background-color .2s linear, width .2s ease-in-out",
    "-webkit-transition": "background-color .2s linear, opacity .2s linear",
  },
  "*:hover": {
    "&::-webkit-scrollbar": {
      display: "block",
      width: "6px",
      height: "6px",
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: "#AAAAAA",
    },
  },
  // "*::-webkit-scrollbar:hover": {
  //   width: "11px",
  //   height: "11px",
  // },
  "*::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#999",
  },
};

interceptor(store);

window.Buffer = Buffer;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={scrollbarStyle} />
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/account" render={() => !localStorage.getItem("accessToken") ? <Form /> : <Redirect to="/user/home" />} />
            <Route path="/login" render={() => !localStorage.getItem("accessToken") ? <Authentication /> : <Redirect to="/user/home" />} />
            <Route path="/signup" render={() => !localStorage.getItem("accessToken") ? <Authentication /> : <Redirect to="/user/home" />} />
            <Route path="/admin" render={() => localStorage.getItem("accessToken") ? <Portal /> : <Redirect to="/login" />} />
            <Route path="/user" render={() => localStorage.getItem("accessToken") ? <Portal /> : <Redirect to="/login" />} />
            <Route path="/settings" render={() => localStorage.getItem("accessToken") ? <Portal /> : <Redirect to="/login" />} />
            {localStorage.getItem("accessToken") 
            ? JSON.parse(localStorage.getItem("userDetail")).companyId ? <Redirect to="/user/home" /> : <Redirect to="/admin/home" /> 
            : <Redirect to="/login" />}
          </Switch>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
// swDev();