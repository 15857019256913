import { createSlice } from "@reduxjs/toolkit";

import { SearchGerminationReport, SearchVegetableReport } from "services/UserPortal/QualityControlService";

// initial state
const initialState = {
  germinationTotalCount: 0,
  vegetableTotalCount: 0,
  germinationResults: [],
  vegetableResults: [],
};

const qcSearch = createSlice({
  name: "qcSearch",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [SearchGerminationReport.fulfilled]: (state, action) => {
      state.germinationTotalCount = action.payload.result.totalCount;
      state.germinationResults = action.payload.result.items;
    },
    [SearchVegetableReport.fulfilled]: (state, action) => {
      state.vegetableTotalCount = action.payload.result.totalCount;
      state.vegetableResults = action.payload.result.items;
    },
  },
});

// export actions
export const { reset } = qcSearch.actions;

// export the reducer
export default qcSearch.reducer;