import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { maintenanceDetailsTableHead } from "enums/UserPortal/TableHeaders";
import { reset } from "../store/maintenance";
import { UpdateASRSMaintenanceStatus, GetASRSMaintenanceMaster } from "services/UserPortal/ASRSService";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/UserPortal/asrsStyle.js";

const useStyles = makeStyles(styles);

export default function MaintenanceDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const result = useSelector(store => store.user.asrs.maintenance.maintenance);
  const [openModal, setOpenModal] = React.useState(false);

  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "maintenance");

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleButtonClick_cancel = () => {
    dispatch(UpdateASRSMaintenanceStatus(path[index+1]))
    .then(() => {
      history.push("/user/vifs/maintenance");
    })
  };

  const handleBack = () => {
    dispatch(setDirty(false));
    history.goBack()
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetASRSMaintenanceMaster(path[index+1]))
    .then(({payload}) => {
      if (payload && payload.result === null) {
        history.push("/user/vifs/maintenance");
      } else {
        dispatch(setDirty(true));
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title="VIFS - Maintenance Details"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>handleBack()}
            >
              Go Back
            </Button>
            <Button
              className={classes.button}
              onClick={()=>handleModal()}
              disabled={result.status !== "Scheduled" }
            >
              Cancel
            </Button>
          </React.Fragment>
        }
      >
        <TextField 
          id="job"
          variant="outlined" 
          value={renderValue(result.nameOfJob)}
          disabled
        />
        <Typography className={classes.editDescription}>Choose the Cage ID or the Location ID for the cages required for Maintenance Work</Typography>
        <div className={classes.table}>
          <Table
            header={maintenanceDetailsTableHead}
          >
            { result && result.asrsMaintenanceDetailList && result.asrsMaintenanceDetailList.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes.snCell}>{('0'+(index+1)).slice(-2)}</TableCell>
                  <TableCell>{item.cageRefNo}</TableCell>
                  <TableCell>{item.locationID}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{moment(item.updateTimestamp).format('DD/MM/YYYY, HHmm')}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
      </Card>
      <Modal
        open={openModal}
        onClose={() => handleModal(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content="Do you really want to cancel? This process cannot be undone."
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>No</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_cancel()}>Yes</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
