import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// @mui/material
import { makeStyles } from "@mui/styles"

import { graphColors as colors } from "assets/jss/generaliseStyle";
import styles from "assets/jss/components/UserPortal/costProductivityStyle.js";

const useStyles = makeStyles(styles);

export default function ColumnChart({title}) {
  const classes = useStyles();
  const results = useSelector(store => store.user.costProductivity.report.reports);

  const config = () => {
    return ({
      chart: {
        type: 'column',
        zoomType: 'y',
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: results.map((item) => item.saleOrderNubmer),
      },
      yAxis: {
        title: {
          text: title
        }
      },
      legend: {
        enabled: false
      },
      series: [
        {
          name: title,
          data: results.map((item, i) => {
            return {
              x: i,
              y: item.value,
              color: colors[i]
            };
          })
        }
      ],
    })
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={config()} containerProps={{ className: classes.highChart }} />
  );
}
