import React from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Select from "shared-components/Select/Select";

import { patentTableHead } from "enums/AdminPortal/TableHeaders";
import styles from "assets/jss/components/userSettingsStyle.js";

import { reset } from ".";

const useStyles = makeStyles(styles);

export default function Patents(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('grant');
  const [filter, setFilter] = React.useState({patentTitle: 'All', country: 'All', year: 'All'});
  // const [page, setPage] = React.useState(0);

  const results = [
    {patentTitle: 'A POWER SYSTEM', country: 'SINGAPORE', grant: '11202010483U\n08-Jun-2023', date: 'PCT/SG2018/050215\n02-May-2018'},
    {patentTitle: 'FARMING MANAGEMENT SYSTEM', country: 'SINGAPORE', grant: '11201906379Y\n21-Feb-2022', date: 'PCT/SG2018/050033\n19-Jan-2018'},
    {patentTitle: 'FARMING MANAGEMENT SYSTEM', country: 'ISRAEL', grant: '268713\n02-Mar-2023', date: 'PCT/SG2018/05033\n19-Jan-2018'},
    {patentTitle: 'FARMING MANAGEMENT SYSTEM', country: 'KOREA', grant: '10-2566650\n09-Aug-2023', date: 'PCT/SG2018/05033\n19-Jan-2018'},
    {patentTitle: 'FARMING MANAGEMENT SYSTEM', country: 'USA', grant: '11737399\n29-Sep-2023', date: 'PCT/SG2018/05033\n19-Jan-2018'},
    {patentTitle: 'FARMING MANAGEMENT SYSTEM', country: 'JAPAN', grant: '7370051\n19-Oct-2023', date: '2019-560629\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'SINGAPORE', grant: '11201906378U\n14-Feb-2022', date: 'PCT/SG2018/050032\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'CHINA', grant: 'ZL 201880007875.0\n01-Apr-2022', date: 'PCT/SG2018/0500032\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'JAPAN', grant: '7072904\n13-May-2022', date: 'PCT/SG2018/0500032\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'HONG KONG', grant: 'HK40014075\n29-Jul-2022', date: 'PCT/SG2018/0500033\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'ISRAEL', grant: '268175\n03-Nov-2022', date: 'PCT/SG/2018/050032\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'USA', grant: '11622512\n11-Apr-2023', date: 'PCT/SG2018/050032\n19-Jan-2018'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'TAIWAN', grant: 'I778011\n21-Sep-2022', date: 'TAIWAN PATENT APP NO. 107102137\n20-Jan-2017'},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'EUROPE', grant: '3570657\n06-Dec-2023', date: ''},
    {patentTitle: 'SYSTEM AND METHOD FOR FARMING', country: 'AUSTRALIA', grant: 'WO2018/136007\n23-Feb-2024', date: ''},
  ];

  const patentTitleList = ['A POWER SYSTEM', 'FARMING MANAGEMENT SYSTEM', 'SYSTEM AND METHOD FOR FARMING'];
  const countryList = ['SINGAPORE', 'CHINA', 'JAPAN', 'HONG KONG', 'TAIWAN', 'ISRAEL', 'USA', 'KOREA', 'EUROPE', 'AUSTRALIA'];
  
  const handleOnChange_select = (e) => {
    setFilter({...filter, [e.target.name]: e.target.value});
  }

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      className={classes.container}
      title="Patents Information"
      cardActions={
        <Button className={classes.buttonSecondary} onClick={()=>props.setPage(null)}>Back</Button>
      }
    >
      <Grid container spacing={4} style={{paddingBottom: "1.250vw"}}>
        <Grid item xs={5} className={classes.filter}>
          <Typography className={classes.filterLabel}>Patent Title:</Typography>
          <Select
            name="patentTitle"
            onChange={(e)=>handleOnChange_select(e)}
            value={filter.patentTitle}
            placeholder="Choose one"
          >
            <MenuItem value={'All'}>All</MenuItem>
            { patentTitleList.map((item, index) => {
              return <MenuItem key={index} value={item}>{item}</MenuItem>;
            })} 
          </Select>
        </Grid>
        <Grid item xs={3} className={classes.filter}>
          <Typography className={classes.filterLabel}>Country:</Typography>
          <Select
            name="country"
            onChange={(e)=>handleOnChange_select(e)}
            value={filter.country}
            placeholder="Choose one"
          >
            <MenuItem value={'All'}>All</MenuItem>
            { countryList.sort().map((item, index) => {
              return <MenuItem key={index} value={item}>{item}</MenuItem>;
            })} 
          </Select>
        </Grid>
      </Grid>
      <Table
        className={classes.table}
        header={patentTableHead}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      >
        { _.orderBy(_.isEmpty(_.pickBy(filter, (value)=>value !== 'All')) ? results : _.filter(results, _.pickBy(filter, (value)=>value !== 'All')), [orderBy], [order])
        // .slice(page * rowsPerTable, page * rowsPerTable + rowsPerTable)
        .map((item,index) => {
          return (
            <TableRow key={index}>
              <TableCell>{item.patentTitle}</TableCell>
              <TableCell>{item.country}</TableCell>
              <TableCell>{item.grant.split('\n')[0]}<p>{item.grant.split('\n')[1]}</p></TableCell>
              {/* <TableCell>{item.date.split('\n')[0]}<p>{item.date.split('\n')[1]}</p></TableCell> */}
            </TableRow>
          )
        })}
      </Table>
    </Card>
  );
}
