import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
// core components
import Select from "shared-components/Select/Select";
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import { setDirty } from "store/general";
import { renderValue } from "common/functions";
import { setValues } from "store/common";
import { updateRequest } from "../store/sensorSettings";
import { GetAllSensorControlDeviceFunction, GetDeviceList } from "services/UserPortal/CommonLookupService";

import styles from "assets/jss/components/UserPortal/climateStyle.js";

const useStyles = makeStyles(styles);

export default function DeviceDetail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sensorSetting = useSelector(store => store.user.climate.sensorSettings.sensorSetting);
  const deviceList = useSelector(store => store.common.deviceList);
  const functionList = useSelector(store => store.common.functionList);
  const [page, setPage] = React.useState(0); // for device list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for device list
  const [searchValue, setSearchValue] = React.useState(""); // for device list

  const handleOnChange_text = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    if (e.target.name === "deviceFunctionId") {
      const func = functionList.find(({id}) => e.target.value === id);
      dispatch(updateRequest({
        [e.target.name]: e.target.value, 
        device: null,
        deviceId: 0,
        sensorSettingDetailList: _.map(func.functionRangeList, (item)=> _.mapKeys(item, (value, key) => {
          switch (key) {
            case "functionRangeName":
               return "sensorSettingDetailName";
            case "functionUnit":
               return "deviceFunctionUnit";
           }
        }))
      }));
    } else {
      dispatch(updateRequest({[e.target.name]: e.target.value}));
    }
  };

  const handleButtonClick_checkbox = (e) => {
    dispatch(setDirty(true));
    if (e.target.id === "isRuleAppliedWhenSensorOn") {
      dispatch(updateRequest({[e.target.id]: !sensorSetting.isRuleAppliedWhenSensorOn}));
    } else {
      dispatch(updateRequest({[e.target.id]: !sensorSetting.isForAllDeviceFunctionDevices, device: null, deviceId: 0}));
    }
    
  };

  const handleOnChange_autocomplete = (value) => {
    if (value) {
      dispatch(updateRequest({device : value, deviceId: value.id}));
    }
  };
  
  const fetchMoreData = (search, pageNo) => {
    if ((page !== -1 || pageNo !== undefined) && sensorSetting.deviceFunctionId) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : page,
        DeviceRefNo: search ? search : "",
        DeviceFunctionId: sensorSetting.deviceFunctionId
      }
      dispatch(GetDeviceList(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result&&response.payload.result.items.length) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({deviceList: response.payload.result.items}));
            } else {
              dispatch(setValues({deviceList: _.unionBy(deviceList, response.payload.result.items, "id")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  React.useEffect(() => {
    const param = {
      page: 0,
      DeviceFunctionId: sensorSetting.deviceFunctionId,
      IsSensorDevice: props.type==="Environment",
      IsControlDevice: props.type==="Monitoring",
    }
    dispatch(GetDeviceList(param))  // pass in page and/or search
    .then((response) => {
      if (response.payload.result) {
        setPage(page+1);
        dispatch(setValues({deviceList: response.payload.result.items}));
      }
    })
  },[sensorSetting.deviceFunctionId]);

  React.useEffect(() => {
    setSearchValue("");
    dispatch(GetAllSensorControlDeviceFunction({
      IsSensor: props.type==="Environment",
      IsControl: props.type==="Monitoring",
    }));
    dispatch(setValues({deviceList:[]}));
  },[props.type]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.formTitle}>Device Details</Typography>
      <Grid container spacing={4}>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Device Function:</Typography>
          <Select
            name="deviceFunctionId"
            onChange={(e)=>handleOnChange_select(e)}
            placeholder="Select a device function"
            value={renderValue(sensorSetting.deviceFunctionId)}
            errorMsg={props.errorMsg}
            disabled={props.isEdit}
          >
            {functionList && functionList.map((item, index) => {
              return <MenuItem key={index} value={item.id}>{item.deviceFunctionName}</MenuItem>;
            })} 
          </Select>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <React.Fragment>
            <Typography className={classes.formLabel}>Device ID:</Typography>
            {(sensorSetting.isForAllDeviceFunctionDevices || props.isEdit) || (sensorSetting.deviceFunctionId && !deviceList.length)
            ? <TextField 
                id="deviceId"
                disabled={true} 
                variant="outlined" 
                value={sensorSetting.deviceRefNo} 
                placeholder={!deviceList.length && "No Devices"} 
                errorMsg={props.errorMsg}
              />
            : <Autocomplete
                id="deviceId"
                dataCount={page*10}
                options={deviceList}
                fetchData={()=>fetchMoreData()}
                renderOption={(option) => option.deviceRefNo}
                isLoadingMore={isLoadingMore}
                placeholder="Select a device"
                onInputChange={(e, newInputValue) => {
                  if (e && e._reactName == "onBlur") {
                    setPage(0);
                    setSearchValue(!_.isEmpty(sensorSetting.device)?sensorSetting.device.deviceRefNo:"");
                    fetchMoreData(newInputValue, 0);
                  } 
                  if (e && (e._reactName == "onClick" || e._reactName == "onChange")) {
                    setPage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(undefined, 0);
                  }
                  if (e && e._reactName == "onClick") {
                    setPage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(undefined, 0);
                  }
                  if (e && e._reactName == "onChange") {
                    setPage(0);
                    setSearchValue(newInputValue);
                    fetchMoreData(newInputValue, 0);
                  }
                }}
                onChange={(_, newValue) => {
                  handleOnChange_autocomplete(newValue);
                }}
                inputValue={searchValue}
                value={searchValue!==(sensorSetting.device&&sensorSetting.device.deviceRefNo) ? null : sensorSetting.device}
                renderValue={"deviceRefNo"}
                errorMsg={props.errorMsg}
              />
            }
          </React.Fragment>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <Checkbox 
            id="isForAllDeviceFunctionDevices"
            className={classes.checkboxPadding} 
            value="isForAllDeviceFunctionDevices"
            checked={Boolean(sensorSetting.isForAllDeviceFunctionDevices)}
            onChange={(e) => handleButtonClick_checkbox(e)}
            disabled={props.isEdit}
          />
          <Typography className={classes.checkboxLabel}>For all devices with same device function</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Typography className={classes.formLabel}>Rule Name:</Typography>
          <TextField 
            id="ruleName"
            variant="outlined" 
            inputProps={{ maxLength: 50 }}
            onChange={(e) => handleOnChange_text(e)}
            placeholder="Enter rule name"
            value={renderValue(sensorSetting.ruleName)}
            errorMsg={props.errorMsg}
          />
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <Checkbox 
            id="isRuleAppliedWhenSensorOn"
            className={classes.checkboxPadding} 
            value="isRuleAppliedWhenSensorOn"
            checked={Boolean(sensorSetting.isRuleAppliedWhenSensorOn)}
            onChange={(e) => handleButtonClick_checkbox(e)}
            disabled={props.isEdit}
          />
          <Typography className={classes.checkboxLabel}>Rule applied only when sensor is on</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
