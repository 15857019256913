import {
  icon_24,
  icon_38,
  button,
  buttonSecondary,
  cardRadius,
  border,
  backgroundColor,
  whiteColor,
  primaryColor,
  secondaryColor,
  graphColors,
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  table,
  link,
} from "assets/jss/components/commonStyle.js";

const monitoringStyle = {
  icon_24,
  icon_38,
  cardPaper,
  table,
  button,
  buttonSecondary,
  cardRadius,
  link,

  label: {
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
    marginRight: "1.042vw",
    minWidth: "fit-content",
    color: primaryColor[4],
    alignSelf: "center"
  },
  cardActionDropdown: {
    width: "11.771vw",
    maxWidth: "11.771vw",
    marginLeft: "1.042vw",
  },
  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw !important"
  },

  // Overview
  cardContainer: {
    display: "flex",
    alignItems: "center",
    "&:not(:first-child)": {
      marginTop: "2.083vw"
    }
  },
  cardMargin: {
    margin: "-0.521vw"
  },
  cardLeft: {
    width: "100%",
    marginRight: "1.563vw"
  },
  cardWO: {
    width: "24.792vw",
    minWidth: "24.792vw",
  },
  cardBank: {
    marginLeft: "1.563vw",
    width: "18.750vw",
    minWidth: "18.750vw",
  },
  cardContent: {
    height: "16.2vw",
    maxHeight: "25.625vw",
    overflow: "overlay",
    paddingBottom: "0 !important",
    borderRadius: "0 0 0.833vw 0.833vw",
  },
  noBorder: {
    borderBottom: "none"
  }, 
  border: {
    border,
    padding: "1.250vw"
  },
  tableTitle: {
    fontWeight: 500
  },
  tableCage: {
    width: "9.375vw",
    maxWidth: "9.375vw"
  },
  tableBank: {
    width: "6.458vw"
  },
  tableTotal: {
    backgroundColor,
    fontWeight: 500
  },
  bankText: {
    fontSize: "0.729vw"
  },
  dashboard: {
    "& tr > *": {
      fontSize: "1.250vw"
    },
  },
  paper: {
    border,
    ...cardRadius,
    height: "21.354vw",
    marginBottom: "2.083vw",
    display: "flex"
  },
  camera: {
    minWidth: "50.156vw",
    width: "50.156vw",
    height: "21.354vw",
    borderRadius: "0.833vw 0 0 0.833vw",
    position: "relative",
  },
  detailDiv: {
    width: "100%",
  },
  detailHeaderDiv: {
    width: "100%",
    backgroundColor: primaryColor[2],
    color: whiteColor,
    borderRadius: "0 0.833vw 0 0",
  },
  detailHeader: {
    padding: "1.563vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  detailTitle: {
    fontWeight: 500,
    fontSize: "1.250vw"
  },
  detailContentDiv: {
    margin: "1.563vw",
  },
  contentTitle: {
    fontWeight: 500,
  },
  tag: {
    backgroundColor: primaryColor[7],
    borderRadius: "1.250vw",
    marginTop: "0.521vw",
    marginRight: "1.042vw",
  },
  cameraHeaderDiv: {
    width: "100%",
    backgroundColor: primaryColor[2],
    color: whiteColor,
    borderRadius: "0.833vw 0.833vw 0 0",
  },
  cameraView: {
    borderRadius: "0 0 0.833vw 0.833vw",
    position: "relative",
    margin: "auto"
  },
  noCamera: {
    //height:"11.354vw",
    margin: "auto"
  },
  cameraContent: {
    padding: "0 !important",
  },
  cameraAction: {
    color: whiteColor
  },
  envPaper: {
    backgroundColor,
    ...cardRadius,
    position: "relative",
    height: "auto",
    padding: "1.563vw",
    width: "100%",
    maxWidth: "18.333vw",
    "&:not(:last-child)": {
      marginRight: "1.042vw"
    }
  },
  envValueBold: {
    fontWeight: 500,
    fontSize: "1.250vw",
  },
  envValue: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between"
  },
  iconMargin: {
    marginBottom: "1.563vw",
  },
  enterButton: {
    float: "right",
    alignSelf: "center",
    cursor: "pointer"
  },
  title: {
    fontWeight: 500,
    fontSize: "1.094vw",
    marginBottom: "1.563vw",
  },
  productDropdown: {
    marginLeft: "1.042vw",
    "&:not(:last-child)": {
      marginRight: "2.500vw"
    }
  },
  viewButton: {
    marginLeft: "2.500vw",
    marginRight: "15.625vw"
  },
  legend: {
    display: "flex",
    marginTop: "1.667vw",
    marginLeft: "1.563vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  colorBox: {
    border,
    width: "1.146vw",
    height: "1.146vw",
    marginRight: "0.521vw",
    "&:not(:first-child)": {
      marginLeft: "1.563vw"
    },
  },
  legendColor: {
    "&:nth-child(1)": {
      backgroundColor: graphColors[0],
    },
    "&:nth-child(2)": {
      backgroundColor: graphColors[1],
    },
    "&:nth-child(3)": {
      backgroundColor: graphColors[2],
    },
    "&:nth-child(4)": {
      backgroundColor: graphColors[3],
    },
    "&:nth-child(5)": {
      backgroundColor: graphColors[4],
    }
  },
  cageView: {
    display: "flex",
    alignItems: "center",
    "&:not(:first-child)": {
      marginTop: "2.083vw"
    },
    marginBottom: "2.083vw"
  },
  // Equipment
  cardEquipment: {
    width: "100%",
  },
  equipIcon: {
    color: secondaryColor[0]
  },
  iconButton: {
    position: "absolute",
    right: "0.50vw",
    bottom: "0.50vw"
  },
  equipTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.250vw"
  },
  equipPaper: {
    alignSelf: "start",
    marginRight: "2.500vw !important"
  },
  equipView: {
    marginLeft: "1.563vw",
  },
  leftEquipOverview: {
    marginRight: "2.563vw",
    width: "20.833vw",
    alignSelf: "flex-start"
  },
  floorPlanImg: {
    height: "18.490vw",
    position: "relative",
    // width: "unset",
    marginTop: "2.083vw",
    objectFit: "cover",
    transition: "transform 0.25s ease",
  },
  markerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-40%, -20%)"
  },
  marker: {
    ...icon_38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: whiteColor,
    border: "0.208vw solid " + secondaryColor[0],
    borderRadius: "50%",
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
  },
  markerText: {
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
  },
  bankView: {
    overflow: "overlay",
    marginTop: "2.083vw",
    width: "-webkit-fill-available",
    height: "20.833vw",
    paddingRight: "0.833vw"
  },
  alarmContainer: {
    position: "relative",
  },
  alarm: {
    position: "absolute",
    width: "11.302vw",
    transform: "translate(-5.208vw, -6.771vw)",
    backgroundColor: secondaryColor[0],
    border: "0.208vw solid "+secondaryColor[0],
    textAlign: "center",
    zIndex: 9999
  },
  alarmTitle: {
    paddingTop: "0.729vw",
    paddingBottom: "0.521vw",
    color: secondaryColor[0],
    backgroundColor: whiteColor,
  },
  alarmDetail: {
    padding: "0.521vw 0.729vw",
    color: whiteColor,
    cursor: "pointer"
  },
  arrow: {
    transform: "translateY(-1.302vw)",
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: whiteColor,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: secondaryColor[0],
      transform: "rotate(45deg)",
      transformOrigin: "100% 0",
    }
  },
  // Floor Ops
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    width: "52.083vw",
    margin: "auto"
  },
  // Storage
  cardStorage: {
    width: "57.760vw",
    minWidth: "57.760vw",
  },
  // Settings
  settingsPaper: {
    ...cardRadius,
    border,
    width: "41.667vw",
    margin: "auto",
    marginBottom: "1.302vw",
    padding: "1.823vw 7.292vw",
    color: primaryColor[3],
  },
  settingsLabel: {
    color: primaryColor[4],
    alignSelf: "center"
  },
  settingsButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2.083vw"
  },
};

export default monitoringStyle;
  