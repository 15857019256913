// Create Edit Raw Material Type
import React from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";

import { setDirty, setLoading } from "store/general";
import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import * as validate from "common/validations";
import { CreateUpdateRawMaterialType, GetRawMaterialType } from "services/AdminPortal/RawMaterialService";
import styles from "assets/jss/components/AdminPortal/rawMaterialStyle.js";

const useStyles = makeStyles(styles);

export default function AddEditRawMaterialType(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const [rawMaterialType, setRawMaterialType] = React.useState("");
  const [shortform, setShortform] = React.useState("");
  const [id, setId] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleButtonClick_cancel = () => {
    resetState();
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      let body = {
        rawMaterialTypeName: rawMaterialType,
        rawMaterialTypeShortForm: shortform,
        id: id
      };
      dispatch(CreateUpdateRawMaterialType(body))
      .then((response)=>{
        if (response.error) {
          dispatch(setLoading(false));
          if (response.payload.message.includes('Short Form')) {
            setErrorMsg({field: "shortform", msg: response.payload.message});  //validation for existing raw material shortform
          } else {
            setErrorMsg({field: "rawMaterialType", msg: response.payload.message});  //validation for existing raw material type
          }
        } else {
          resetState();
        }
      })
    }
  }

  const resetState = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      if (props.setAddEditMode) {
        props.setAddEditMode(false);
      } else {
        const prop = props.location && props.location.state;  // from search
        history.push((prop && prop.prevPath) || "/admin/raw-material");
      }
      setRawMaterialType("");
      setShortform("");
      setId(0);
      setErrorMsg(false);
    });
  }

  const validateFields = () => {
    if (_.isEmpty(rawMaterialType)) {
      setErrorMsg({field: "rawMaterialType", msg: "Please add in a raw material type"});
      return false;
    }
    if (_.isEmpty(shortform)) {
      setErrorMsg({field: "shortform", msg: "Please add in a shortform"});
      return false;
    }
    if (!validate.isShortform(shortform)) {
      setErrorMsg({field: "shortform", msg: "Shortform should contain at most 3 upper case characters"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }

  React.useEffect(() => {
    const prop = props.location && props.location.state;  // from search
    dispatch(setAddNewButton(true));
    dispatch(setDirty(true));
    if (prop) {
      const path = location.pathname.split("/");
      const index = path.findIndex((item) => item === "type");
      dispatch(GetRawMaterialType(path[index+1]))
      .then((response) => {
        if (response.error) {
          Promise.all([dispatch(setDirty(false))])
          .then(() => {
            history.push((prop && prop.prevPath) || "/admin/raw-material");
          });
        } else {
          const result = response.payload.result;
          setRawMaterialType(result.rawMaterialTypeName);
          setShortform(result.rawMaterialTypeShortForm);
          setId(result.id);
        }
      })
    }
    if (props.id) {
      setRawMaterialType(props.rawMaterialType);
      setShortform(props.shortform);
      setId(props.id); 
    }
    // componentDidUnmount
    return () => {
      dispatch(setDirty(false));
      const prop = props.location && props.location.state;
      if (prop) {
        dispatch(resetNav());
      }
    }
  },[]);

  return (
		<Card 
			title = {props.addEditMode + " New Raw Material Type"}
			cardActions={
				<React.Fragment>
					<Button disabled/>
					<div>
						<Button
							className={clsx(classes.buttonSecondary, classes.buttonMargin)}
							onClick={() => handleButtonClick_cancel()}
						>
							Cancel
						</Button>
						<Button
							className={classes.button}
							onClick={() => handleButtonClick_save()}
						>
							Save
						</Button>
					</div>
				</React.Fragment>
			}
		>
			<Grid container spacing={8}>
				<Grid item xs={12} sm={6} md={6}>
					<TextField 
            id="rawMaterialType"
            label={<React.Fragment>{(props.addEditMode === "Add")? "New" : ""} Raw Material Type <span className={classes.orange}>*</span></React.Fragment>}
						variant="outlined" 
						placeholder="Enter Raw Material Type"
						onChange={(e) => setRawMaterialType(e.target.value)}
						value= {rawMaterialType}
            errorMsg={errorMsg}
						inputProps={{ maxLength: 120 }}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<TextField 
            id="shortform"
						label={<React.Fragment>{(props.addEditMode === "Add")? "New" : ""} Shortform <span className={classes.orange}>*</span></React.Fragment>}
						variant="outlined" 
						placeholder="Enter Shortform"
						onChange={(e) => setShortform(e.target.value.toUpperCase())}
						inputProps={{ maxLength: 3 }}
						value={shortform}
            errorMsg={errorMsg}
					/>
				</Grid>
			</Grid>
		</Card>
	)
}
