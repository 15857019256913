import {
  icon_24,
  border,
  cardRadius,
  button,
  buttonSecondary,
  whiteColor,
  primaryColor,
  secondaryColor,
  graphColors
} from "assets/jss/generaliseStyle.js";

import {
  cardPaper,
  icon,
  orange,
  table,
  tag,
  link,
} from "assets/jss/components/commonStyle.js";

const costProductivityStyle = {
  icon_24,
  cardPaper,
  icon,
  button,
  buttonSecondary,
  orange,
  table,
  link,
  tag,

  filterDropdown: {
    textAlign: "left",
    marginTop: "1.563vw"
  },

  // Cost Overview
  costTitle: {
    fontWeight: 500,
    fontSize: "1.094vw",
  },
  actionDropdown: {
    width: "11.771vw",
  },
  accordion: {
    margin: "unset !important",
    marginTop: "1.563vw !important",
  },
  subAccordion: {
    backgroundColor: whiteColor,
    borderRadius: "0 !important",
    margin: "0 !important",
    "&:first-child": {
      borderRadius: "0.625vw 0.625vw 0 0 !important",
    },
    "&:last-child": {
      borderRadius: "0 0 0.625vw 0.625vw !important",
    },
    "&:not(:last-child)": {
      borderBottom: border,
    },
  },
  header: {
    color: primaryColor[3]+"BF",
  },
  overviewHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "3.646vw"
  },
  totalFont: {
    fontWeight: 400,
    marginRight: "1.563vw"
  },
  content: {
    borderRadius: "0.625vw",
    width: "100%",
    backgroundColor: whiteColor,
    padding: "0 1.042vw 1.563vw"
  },
  accordionContent: {
    width: "100%",
  },
  overviewTable: {
    margin: "1.406vw 0"
  },
  tableMargin: {
    margin: "1.406vw -1.042vw"
  },
  categoriesCell: {
    width: "70%"
  },
  downloadButton: {
    display: "flex",
    justifyContent: "end",
    marginTop: "1.563vw"
  },

  //Efficiency
  action: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    '& div[class*="MuiFormControl-root"]': {
      width: "unset"
    },
  },
  dateLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minWidth: "4.167vw !important",
    maxWidth: "4.167vw",
    minHeight: "1.875vw"
  },
  selectedTab: {
    color: secondaryColor[0] + " !important",
    backgroundColor: secondaryColor[0] + "20",
  },
  dateIndicator: {
    backgroundColor: "transparent"
  },
  cardBorder: {
    border,
    boxShadow: "none",
  },
  graphContent: {
    margin: "2.083vw 0"
  },
  legend: { 
    color: primaryColor[3]+"BF",
    "& p": {
      marginTop: "1.563vw"
    }
  },
  legendLabel: {
    fontWeight: 500,
    fontSize: "1.094vw"
  },
  legendLabel2: {
    fontWeight: 500,
  },
  formulaLegend: {
    display: "flex",
    alignItems: "center"
  },
  formula: {
    display: "grid",
    textAlign: "center"
  },
  fraction: {
    borderBottom: "0.052vw solid "+primaryColor[3]+"BF"
  },
  small: {
    fontSize: "0.729vw",
    marginTop: "0.208vw"
  },
  legendTable: {
    marginTop: "1.563vw"
  },
  tableCell: {
    border
  },

  // Harvest
  subtotal: {
    "& td": {
      fontWeight: 500,
      fontSize: "1.094vw",
      opacity: 0.7
    }
  },

  // Report
  title: {
    fontSize: "1.042vw",
    fontWeight: 400,
    marginBottom: "1.458vw"
  },
  paramButton: {
    display: "flex",
    justifyContent: "space-between"
  },
  leftCard: {
    height: "40.625vw",
    width: "28%"
  },
  leftCardContent: {
    height: "32.292vw"
  },
  rightCard: {
    marginLeft: "1.563vw",
    width: "72%"
  },
  rightCardTitle: {
    fontWeight: 400
  },
  rightCardAction: {
    margin: "-0.781vw 0"
  },
  modalDateTab: {
    border: '0.104vw solid #DDDDDD',
    borderRadius: "0.313vw",
    margin: "2.604vw 0 1.042vw"
  },
  modalDateLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 400,
    padding: 0,
    borderRadius: "0.313vw",
    margin: "0.521vw 0.625vw",
    minWidth: "5.771vw !important",
    maxWidth: "5.771vw",
    minHeight: "1.875vw"
  },
  modalDatepicker: {
    marginTop: "1.042vw !important"
  },
  profitLossCard: {
    width: "48.073vw",
    margin: "auto"
  },
  revenue: {
    fontWeight: 500
  },
  list: {
    height: "27.083vw",
    marginTop: "1.042vw",
    overflow: "overlay",
    marginRight: "-1.979vw"
  },
  listItem: {
    display: "flex",
    marginLeft: "-1.042vw",
    "& :first-child": {
      marginRight: "1.042vw"
    }
  },
  leftCardHeader: {
    paddingTop: "1.563vw",
    padding: 0,
  },
  highChart: {
    // height: "33.698vw",
    width: "100%",
  },
  smallText: {
    fontSize: "0.833vw",
    marginBottom: "1.042vw"
  },
  graphLegend: {
    display: "flex",
    marginTop: "1.667vw",
    marginLeft: "-1.563vw",
    flexWrap: "wrap",
  },
  colorBox: {
    border,
    width: "1.146vw",
    height: "1.146vw",
    marginRight: "0.521vw",
  },
  legendColor: {
    display: "flex",
    marginLeft: "1.563vw",
    "&:nth-child(1) > div": {
      backgroundColor: graphColors[0]
    },
    "&:nth-child(2) > div": {
      backgroundColor: graphColors[1],
    },
    "&:nth-child(3) > div": {
      backgroundColor: graphColors[2],
    },
    "&:nth-child(4) > div": {
      backgroundColor: graphColors[3],
    },
    "&:nth-child(5) > div": {
      backgroundColor: graphColors[4],
    }
  },

  // Settings
  settingsPaper: {
    border,
    ...cardRadius,
    margin: "auto",
    width: "58.438vw",
    display: "flex",
    // padding: "2.083vw 0"
  },
  tabContainer: {
    borderRadius: "0.833vw 0 0 0.833vw",
    width: "15.938vw",
    backgroundColor: "#FAFAFA",
    padding: "2.083vw 0"
  },
  settingsIndicator: {
    backgroundColor: "transparent"
  },
  settingsTabLabel: {
    textTransform: "capitalize",
    fontSize: "0.938vw",
    fontWeight: 500,
    padding: "1.458vw 2.083vw 1.458vw",
    minWidth: "-webkit-fill-available !important", 
    "& *": {
      alignItems: "start"
    }
  },
  selectedSettings: {
    backgroundColor: whiteColor,
    color: secondaryColor[0] + " !important",
    borderLeft: "0.313vw solid " + secondaryColor[0]
  },
  contentContainer: {
    padding: "2.083vw",
    width: "100%"
  },
  settingLabel: {
    fontWeight: 500,
    fontSize: "1.094vw",
    marginBottom: "2.604vw",
    "&:not(:first-child)": {
      fontWeight: 400,
      marginBottom: "1.042vw",
    }
  },
  settingTable: {
    margin: "0 -2.083vw 1.042vw"
  },
  settingTable2: {
    marginBottom: "1.042vw"
  },
}

export default costProductivityStyle;