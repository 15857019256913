import { 
  border,
  whiteColor,
  primaryColor, 
  secondaryColor,
} from "assets/jss/generaliseStyle";

import {
  orange,
  indicator,
} from "assets/jss/components/commonStyle.js";

const headerLinksStyle = () => ({
  orange,
  indicator,
  tabLabel: {
    textTransform: "capitalize",
    marginTop: "0.521vw",
    padding: "0.938vw",
    display: "flex",
    flexDirection: "row"
  },
  divider: {
    height: "1.563vw",
    alignSelf: "auto"
  },
  manager: {
    alignItems: "center",
    width: "unset",
  },
  languageButton: {
    background: "transparent",
    maxWidth: "7.813vw",
    minWidth: "7.813vw",
    marginRight: "1.667vw",
    color: "#386538",
    fontSize: "0.833vw",
    padding: "1.042vw"
  },
  languageContainer: {
    width: "7.813vw",
    padding: "0 0.417vw"
  },
  languageItem: {
    padding: "0.521vw 0.625vw",
  },
  selectedLanguage: {
    backgroundColor: "#F2F2F2",
  },
  arrowIcon: {
    width: "1.042vw",
    alignSelf: "auto",
    marginLeft: "0.417vw"
  },
  flagIcon: {
    border,
    height: "1.563vw",
    width: "1.563vw",
    borderRadius: "50%",
    marginRight: "0.417vw"
  },
  notificationButton: {
    border,
    background: "transparent",
    borderRadius: "0.833vw",
    minWidth: "2.813vw",
    minHeight: "1.563vw",
    maxHeight: "1.563vw",
    margin: "0 1.667vw",
    color: "#648764",
    fontSize: "0.625vw",
    padding: "0 0.521vw",
  },
  NotificationState: {
    background: "linear-gradient(136.67deg, #86D295 8.34%, #00CFB4 95.26%)",  // #customise
    color: whiteColor,
  },
  AlertState: {
    background: "linear-gradient(118.74deg, #FF8A3D -3.59%, #FB7F2D -3.59%, #F04800 88.51%)", // #customise
    color: whiteColor,
  },
  hidePopper: {
    display: "none",
  },
  notificationIcon: {
    width: "1.042vw",
    height: "1.042vw",
    marginLeft: "0.104vw",
    marginRight: "-0.313vw",
  },
  notificationContainer: {
    width: "23.438vw",
    padding: "1.563vw"
  },
  notificationAction: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& p:first-child": {
      fontSize: "0.729vw",
      color: "#899895",
      paddingRight: "0.521vw"
    }
  },
  notificationChip: {
    marginLeft: "0.521vw",
    backgroundColor: "#899895",
    color: whiteColor,
    borderRadius: "0.833vw",
    padding: "0.313vw 0.625vw",
    fontSize: "0.833vw",
  },
  orangeBackground: {
    backgroundColor: secondaryColor[0],
  },
  notificationMenu: {
    margin: "0 -1.563vw",
    height: "fit-content",
    maxHeight: "20.833vw",
    overflow: "overlay",
    paddingTop: 0,
  },
  unread: {
    backgroundColor: "#FFF3EB", // #customise
  },
  unreadMsg: {
    color: secondaryColor[0]
  },
  unreadTime: {
    color: "#14342CBF"
  },
  notificationItem: {
    display: "block",
    padding: "1.042vw 2.083vw",
  },
  notificationMsg: {
    fontSize: "0.729vw",
    marginTop: "0.521vw",
    display: "flex",
    whiteSpace: "break-spaces"
  },
  notificationTime: {
    fontSize: "0.729vw",
    marginTop: "0.521vw",
    color: "#14342C80",
    "&:first-letter": {
      textTransform: "capitalize",
    }
  },
  link: {
    cursor: "pointer",
    fontWeight: 500,
    color: secondaryColor[0],
    marginTop: "0.521vw",
    width: "fit-content",
    textAlign: "right",
    "&:hover": {
      textDecoration: "underline"
    },
  },
  userDetailWrapper: {
    marginLeft: "1.875vw",
    cursor: "pointer",
  },
  userDetailHover: {
    alignItems: "center",
    padding: "0.625vw 0.938vw",
    '&:hover': {
      backgroundColor: "#F6F8F6",
    },
  },
  avatar: {
    width: "2.500vw",
    height: "2.500vw",
    marginRight: "0.729vw",
  },
  userName: {
    fontWeight: "500",
    fontSize: "0.938vw",
    color: primaryColor[4],
    lineHeight: "1.094vw",
    width: "8.333vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  userCompanyFarm: {
    fontSize: "0.729vw",
    color: "#648764",
    lineHeight: "0.833vw",
    marginTop: "0.104vw",
    width: "8.333vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expandMoreIcon: {
    color: primaryColor[4],
    marginLeft: "0.873vw",
  },
  dropdownContainer: {
    width: "13.021vw",
  },
  dropdownItem: {
    fontSize: "0.833vw",
    padding: "0.885vw 2.031vw",
    color: primaryColor[3],
    '&:hover': {
      backgroundColor: secondaryColor[0],
      color: whiteColor
    },
  },
  dropdownIcon: {
    marginRight: "0.885vw",
    width: "0.938vw",
    height: "0.938vw"
  },
  dropdownAvatar: {
    width: "3.646vw",
    height: "3.646vw",
    marginLeft: "4.688vw",
    marginTop: "1.302vw",
  },
  dropdownUsername: {
    fontWeight: "500",
    fontSize: "0.938vw",
    lineHeight: "1.094vw",
    width: "13.021vw",
    textAlign: "center",
    padding: "0.521vw 0.833vw 0.260vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dropdownUserDetail: {
    width: "13.021vw",
    textAlign: "center",
    fontSize: "0.625vw",
    color: primaryColor[3],
    padding: "0 0.833vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dropdownTopPadding: {
    paddingTop: "0.521vw"
  }
});

export default headerLinksStyle;
