import { createSlice } from "@reduxjs/toolkit";
import { GetAllWarehouses, GetWarehouseById, SearchWarehouse } from "services/AdminPortal/WarehouseService";

// initial state
const initialState = {
  totalCount: 0,
  warehouses: [],
  warehouse: {},
  searchResultsCount: 0,
  searchResults: [],
};

const warehouse = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setValues: (state, action) => {
      state.warehouse = action.payload;
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.warehouse[key] = action.payload[key];
      })
    },
    resetWarehouse: (state) => {
      state.warehouse = {};
    },
    resetSearch: (state) => {
      state.searchResultsCount = 0;
      state.searchResults = [];
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetWarehouseById.fulfilled]: (state, action) => {
      state.warehouse = action.payload.result;
    },
    [GetAllWarehouses.fulfilled]: (state, action) => {
      if (action.payload.result) {
        state.totalCount = action.payload.result.totalCount;
        state.warehouses = action.payload.result.items;
      }
    },
    [SearchWarehouse.fulfilled]: (state, action) => {
      state.searchResultsCount = action.payload.result.warehouseList ? action.payload.result.warehouseList.totalCount : 0;
      state.searchResults = action.payload.result.warehouseList ? action.payload.result.warehouseList.items : 0;
    },
  },
});

// export actions
export const { setValues, updateRequest, resetWarehouse, resetSearch, reset } = warehouse.actions;

// export the reducer
export default warehouse.reducer;