import { createSlice } from "@reduxjs/toolkit";

import { SearchPastLogSheet, SearchLabourCost } from "services/UserPortal/HumanResourceService";

// initial state
const initialState = {
  logSheetTotalCount: 0,
  costTotalCount: 0,
  logSheetResults: [],
  costResults: [],
};

const hrSearch = createSlice({
  name: "hrSearch",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [SearchPastLogSheet.fulfilled]: (state, action) => {
      state.logSheetTotalCount = action.payload.result.totalCount;
      state.logSheetResults = action.payload.result.items;
    },
    [SearchLabourCost.fulfilled]: (state, action) => {
      state.costTotalCount = action.payload.result.totalCount;
      state.costResults = action.payload.result.items;
    },
  },
});

// export actions
export const { reset } = hrSearch.actions;

// export the reducer
export default hrSearch.reducer;