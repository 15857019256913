// Create Edit Raw Material
// Step 3: Batch Form (Only accessible in creation)
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import DateTimePicker from 'shared-components/DatePicker/DateTimePicker';
import Checkbox from "shared-components/Checkbox/Checkbox";
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";

import RawMaterialStepper from "./RawMaterialStepper";
import { setDirty } from "store/general";
import { renderValue, roundTo2Decimal } from "common/functions";
import { isPositiveDecimal } from "common/validations";
import { GetProposedRawMaterialBatchRefNo, CreateOrUpdateRawMaterial, GetAllSupplier, GetAllWarehouses, GetWarehouseRackByWarehouseId } from "services/AdminPortal/RawMaterialService";
import { updateBatchForm, updateRequest, setValues } from "./";

import styles from "assets/jss/components/AdminPortal/rawMaterialStyle.js";

const useStyles = makeStyles(styles);
export default function BatchForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedRawMaterial = useSelector(store => store.admin.rawMaterial.selectedRawMaterial);
  const rawMaterialBatchRefNo = useSelector(store => store.admin.rawMaterial.rawMaterialBatchRefNo);
  const allRackNumber = useSelector(store => store.admin.rawMaterial.allRackNumber);
  const allSupplier = useSelector(store => store.admin.rawMaterial.allSupplier);
  const warehouses = useSelector(store => store.admin.rawMaterial.warehouses);
  //batch form states
  const [page, setPage] = React.useState(0); // for rack list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for rack list
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const lastBatch = _.last(selectedRawMaterial.batches);

  const handleCreationDateChange = (date) => {
    if(date && date != "Invalid Date") {
      dispatch(updateBatchForm({["dateOfCreation"] : moment(date).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateBatchForm({["dateOfCreation"] : date}));
    }
  };

  const handleExpiryDateChange = (date) => {
    if(date && date != "Invalid Date") {
      dispatch(updateBatchForm({["expiryDate"] : moment(date).format("YYYY-MM-DD")}));
    } else {
      dispatch(updateBatchForm({["expiryDate"] : date}));
    }
  };

  const handleOnChange_text = (e) => {
    dispatch(updateBatchForm({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(updateBatchForm({[e.target.name] : e.target.value}));
    if (e.target.name === "warehouseId") {
      dispatch(updateBatchForm({["rackNumbers"] : []}));
      setPage(0);
      const param = {
        warehouseId: e.target.value,
        page: 0
      }
      dispatch(GetWarehouseRackByWarehouseId(param))
      .then((response) => {
        if (response.payload.result) {
          setPage(page+1);
          dispatch(setValues({allRackNumber: response.payload.result.items}))
        }
      });
    }
  };

  const handleOnChange_autocomplete = (value) => {
    dispatch(updateBatchForm({rackNumbers : value}));
  };

  const fetchMoreData = (search, pageNo) => {
    if (page !== -1 || pageNo !== undefined) {
      setIsLoadingMore(true);
      const param = {
        warehouseId: lastBatch.warehouseId,
        page: pageNo !== undefined ? pageNo : page,
        rackID: search ? search : ""
      }
      dispatch(GetWarehouseRackByWarehouseId(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            if (search || page === 0 || pageNo === 0) {
              dispatch(setValues({allRackNumber: response.payload.result.items}));
            } else {
              dispatch(setValues({allRackNumber: _.unionBy(allRackNumber, response.payload.result.items, "rackID")}));
            }
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  // const addMoreBatch = () => {
  //   if (validateFields()) {
  //     dispatch(setDirty(true));
  //     dispatch(addNewBatch());
  //   }
  // }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      const result = _.omit(selectedRawMaterial, ['rawMaterialType', 'rawMaterialVariantType']);
      let body = _.cloneDeep(result);
      if (body.batches[0].rackNumbers) {
        body.batches[0].rackNumbers = body.batches[0].rackNumbers.map((item) => item.rackID)
      }
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateRawMaterial(body))
        .then(({error}) => {
          if (error) {
            dispatch(setDirty(true));
          } else {
            history.push("/admin/raw-material");
          }
        });
      });
    }
  }

  const handleButtonClick_back = () => {
      dispatch(updateRequest({["isDraft"] : true}))
      props.setStep(1)
  }

  const validateFields = () => {
    if (lastBatch.dateOfCreation === null) {
      setErrorMsg({field: "dateOfCreation", msg: "Please input date"});
      return false;
    }
    if (!moment(lastBatch.dateOfCreation).isValid()) {
      setErrorMsg({field: "dateOfCreation", msg: "Invalid date"});
      return false;
    }
    if (!lastBatch.qtyAdd) {
      setErrorMsg({field: "qtyAdd", msg: "Please enter a quantity"});
      return false;
    }
    if (!isPositiveDecimal(lastBatch.qtyAdd)) {
      setErrorMsg({field: "qtyAdd", msg: "Please enter a valid quantity"});
      return false;
    }
    if (!lastBatch.totalCost) {
      setErrorMsg({field: "totalCost", msg: "Please enter total cost"});
      return false;
    }
    if (!isPositiveDecimal(lastBatch.totalCost)) {
      setErrorMsg({field: "totalCost", msg: "Please enter a valid total cost"});
      return false;
    }
    if (lastBatch.expiryDate === null) {
      setErrorMsg({field: "expiryDate", msg: "Please enter an expiry date"});
      return false;
    }
    if (!moment(lastBatch.expiryDate).isValid()) {
      setErrorMsg({field: "expiryDate", msg: "Invalid date"});
      return false;
    }
    if (lastBatch.expiryDate < lastBatch.dateOfCreation) {
      setErrorMsg({field: "expiryDate", msg: "Expiry date should be later than created date"});
      return false;
    }
    if (selectedRawMaterial.isSeed && !lastBatch.lossRatePercent) {
      setErrorMsg({field: "lossRatePercent", msg: "Please enter loss rate"});
      return false;
    }
    if (selectedRawMaterial.isSeed && lastBatch.lossRatePercent > 100) {
      setErrorMsg({field: "lossRatePercent", msg: "Loss rate cannot be more than 100%"});
      return false;
    }
    else {
      setErrorMsg(false);
      return true;
    }
  }

  // componentDidMount
  React.useEffect(() => {
    const data = {
      rawMaterialTypeId: selectedRawMaterial.rawMaterialType.id,
      variantTypeId: selectedRawMaterial.rawMaterialVariantType.id
    }
    dispatch(GetProposedRawMaterialBatchRefNo(data));
    dispatch(updateBatchForm({["quantityUOM"] : selectedRawMaterial.unitOfMeasurement}));
    dispatch(GetAllWarehouses());
    dispatch(GetAllSupplier());
  },[]);

  return (
    <React.Fragment>
      <Card 
        title={
          <React.Fragment>
            Step 3: Key in Starting Batch for this Raw Material
          </React.Fragment>
        }
        subheader={<RawMaterialStepper activeStep={props.step} />}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={() => handleButtonClick_back()}
            >
              Go Back
            </Button>
            <div>
            {/* <Button
              className={clsx(classes.buttonSecondary, classes.buttonSpacing)}
              onClick={() => addMoreBatch()}
            >
              Add More Batch
            </Button> */}
            <Button
              className={clsx(classes.button, classes.buttonSpacing)}
              onClick={()=> handleButtonClick_save()}
            >
              Save
            </Button>
            </div>
          </React.Fragment>
        }
      >
        <Paper className={classes.newPaper} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Proposed Batch ID:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                value={rawMaterialBatchRefNo}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Date of Creation <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <DateTimePicker
                name="dateOfCreation"
                value={lastBatch.dateOfCreation}
                onChange={handleCreationDateChange}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Quantity Added <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField 
                id="qtyAdd"
                variant="outlined"
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Eg. 100"
                type="Number"
                value={renderValue(lastBatch.qtyAdd)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField 
                value={selectedRawMaterial.unitOfMeasurement}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Total Cost <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField 
                id="totalCost"
                variant="outlined"
                onChange={(e) => handleOnChange_text(e)}
                placeholder="Eg. 100"
                type="Number"
                onInput={(e)=>{ 
                  // decimal
                  const reg = /^\d*(\.\d{0,2})?$/
                  if (!reg.test(e.target.value)) {
                    e.target.value = roundTo2Decimal(e.target.value);
                  }
                }}
                value={renderValue(lastBatch.totalCost)}
                errorMsg={errorMsg}
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Expiry Date <span className={classes.orange}>*</span> :</Typography>
            </Grid>
            <Grid item xs={8}>
              <DateTimePicker
                name="expiryDate"
                value={lastBatch.expiryDate}
                minDate={lastBatch.dateOfCreation ? lastBatch.dateOfCreation : moment().format("YYYY-MM-DD")}
                onChange={handleExpiryDateChange}
                errorMsg={errorMsg}
              />
            </Grid>
            {selectedRawMaterial.isSeed && 
            <React.Fragment>
              <Grid item xs={4} className={classes.newLabel}>
                <div className={classes.lossRate}>
                  <Typography>Loss Rate (%) <span className={classes.orange}>*</span>:</Typography>
                  <Typography className={classes.lossRateSubheader}>Over the Product Loss Rate</Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <TextField 
                  id="lossRatePercent"
                  variant="outlined"
                  onChange={(e) => handleOnChange_text(e)}
                  placeholder="Eg. 10%"
                  type="Number"
                  onInput={(e)=>{ 
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                  }}
                  value={renderValue(lastBatch.lossRatePercent)}
                  errorMsg={errorMsg}
                />
              </Grid>
            </React.Fragment>
            }
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Warehouse:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="warehouseId"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(lastBatch.warehouseId)}
                placeholder="Input Warehouse Name"
              >
                {warehouses && warehouses.map((item) => {
                  return <MenuItem key={item.warehouseID} value={item.id}>{item.warehouseName}</MenuItem>;
                })}
              </Select>
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Rack Number:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                dataCount={page*10}
                options={allRackNumber}
                fetchData={()=>fetchMoreData()}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox checked={_.findIndex(lastBatch.rackNumbers, option) > -1} />
                    {option.rackID}
                  </React.Fragment>
                )}
                isLoadingMore={isLoadingMore}
                placeholder="Eg. R01-01"
                onInputChange={(_, newInputValue) => {
                  setPage(0);
                  setSearchValue(newInputValue);
                  fetchMoreData(newInputValue, 0);
                }}
                onChange={(_, newValue) => {
                  handleOnChange_autocomplete(newValue);
                }}
                inputValue={searchValue}
                value={lastBatch.rackNumbers ? lastBatch.rackNumbers : []}
                renderValue={"rackID"}
                multiple
                disabled={!lastBatch.warehouseId}
              />
            </Grid>
            <Grid item xs={4} className={classes.newLabel}>
              <Typography>Supplier Name:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                name="supplierId"
                onChange={(e)=>handleOnChange_select(e)}
                value={renderValue(lastBatch.supplierId)}
                placeholder="Input your vendor name"
                errorMsg={errorMsg}
              >
                {allSupplier && allSupplier.map((item,index) => {
                  return <MenuItem key={index} value={item.id}>{item.supplierName}</MenuItem>;
                })}
              </Select>
            </Grid>
          </Grid>
        </Paper>
      </Card>
    </React.Fragment>
  )
}
