import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import Checkbox from "shared-components/Checkbox/Checkbox";
import FilterButton from "shared-components/Button/FilterButton";
import DateRangePicker from 'shared-components/DatePicker/DateRangePicker';
import Autocomplete from "shared-components/Select/InfiniteAutocomplete";
import Tag from 'shared-components/Chip/Tag';

import { rowsPerTable } from "config";
import { setSearchText, setDirty } from "store/general";
import { setValues as setCommonValues } from "store/common";
import { useInterval } from 'common/utils';
import { renderValue, filterParam, filterActionTableHead, checkPermission } from 'common/functions';
import { setValues, updateRequest } from "../store/assignment";
import { assignmentTableHead } from "enums/UserPortal/TableHeaders";
import { GetAllDeliveryOrderForAssignment, GetDeliveryTripSetting, GetCustomerList } from "services/UserPortal/DeliveryService";
import { GetAllSaleOrder } from "services/UserPortal/CommonLookupService";

import AssignModal from "./AssignModal";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

import { useRouteCanWrite } from "hooks";
const useStyles = makeStyles(styles);

export default function Assignment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const filter = useSelector(store => store.user.delivery.assignment.filter);
  const totalCount = useSelector(store => store.user.delivery.assignment.totalCount);
  const results = useSelector(store => store.user.delivery.assignment.results);
  const deliveryTripDetails = useSelector(store => store.user.delivery.assignment.deliveryTripDetails);
  const assignment = useSelector(store => store.user.delivery.assignment.assignment);
  const inBetweenStopProcessingTime = useSelector(store => store.user.delivery.assignment.inBetweenStopProcessingTime);
  const saleOrderList = useSelector(store => store.common.saleOrderList);
  const customerList = useSelector(store => store.user.delivery.assignment.customerList);
  const [stops, setStops] = React.useState([]);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('deliveryOrderRefNo');
  const [newFilter, setNewFilter] = React.useState({saleOrderId: null, customerId: null, deliveryStartDate: null, deliveryEndDate: null, page: 0});
  const [openModal, setOpenModal] = React.useState(false);
  const [openDetailModal, setOpenDetailModal] = React.useState(false);
  const [soPage, setSOPage] = React.useState(0); // for SO list
  const [searchSOValue, setSearchSOValue] = React.useState(""); // for SO list
  const [isLoadingMoreSO, setIsLoadingMoreSO] = React.useState(false); // for SO list
  const [selectedSO, setSelectedSO] = React.useState({}); // for SO list

  const count = Math.ceil(totalCount / rowsPerTable);

  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleOnChange_date = (value) => {
    if (value) {
      dispatch(setValues({filter: {...filter, deliveryStartDate: moment(value.startDate).format("YYYY-MM-DD"), deliveryEndDate: moment(value.endDate).format("YYYY-MM-DD"), page: 0}}));
    }
  };

  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_checkbox = (item) => {
    dispatch(setDirty(true));
    let payload = _.cloneDeep(stops);
    const index = payload.findIndex(({deliveryOrderId})=>deliveryOrderId===item.id);
    if (index != -1) {
      payload.splice(index, 1);
    } else {
      payload.push({deliveryOrderId: item.id, deliveryOrderRefNo: item.deliveryOrderRefNo, deliveryAddress: item.deliveryAddress, routeOrder: payload.length+1, processingTimeInMinute: inBetweenStopProcessingTime});
    }
    setStops(payload);
  };

  const handleModal = (open) => {
    if (open) {
      dispatch(updateRequest({deliveryDate: moment().format("YYYY-MM-DD"), deliveryTime: moment().format("HH:mm"), time: moment()}));
    }
    setOpenModal(!openModal);
  }

  const handleModal_details = () => {
    setOpenDetailModal(!openDetailModal);
  }
  
  const getAllDeliveryAssignment = (stopLoading) => {
    dispatch(GetAllDeliveryOrderForAssignment({...filter, stopLoading}));
  }

  const handleOnChange_SOautocomplete = (value) => {
    setNewFilter({...newFilter, saleOrderId: value.saleOrderId, saleOrderNumber: value.saleOrderNumber});
    setSelectedSO(value);
  };

  const handleButtonClick_delete = (key) => {
    if (key === "saleOrderNumber") {
      dispatch(setValues({filter: {...filter, [key]: null, saleOrderId: null, page: 0}}));
      setSelectedSO({});
      setSearchSOValue("");
    } else {
      dispatch(setValues({filter: {...filter, [key]: null, page: 0}}));
    }
  };

  const fetchMoreSOData = (search, pageNo) => {
    if (soPage !== -1 || pageNo !== undefined) {
      setIsLoadingMoreSO(true);
      const param = {
        saleOrderNumber: search ? search : "",
        page: pageNo !== undefined ? pageNo : soPage,
      }
      dispatch(GetAllSaleOrder(param))  // pass in page and/or search
      .then((response) => {
        if (!response.error) {
          setIsLoadingMoreSO(false);
          if (response.payload.result) {
            setSOPage(pageNo !== undefined ? pageNo+1 : soPage+1);
            if (search || soPage === 0 || pageNo === 0) {
              dispatch(setCommonValues({saleOrderList: response.payload.result.items}));
            } else {
              dispatch(setCommonValues({saleOrderList: _.unionBy(saleOrderList, response.payload.result.items, "saleOrderId")}));
            }
          } else {
            setSOPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getAllDeliveryAssignment();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetDeliveryTripSetting());
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
    dispatch(GetCustomerList());
  },[]);

  React.useEffect(() => {
    const param = {
      page: 0,
    }
    dispatch(GetAllSaleOrder(param))
    .then((response)=> {
      if (!response.error) {
        setIsLoadingMoreSO(false);
        if (response.payload.result) {
          setSOPage(1);
          dispatch(setCommonValues({saleOrderList: response.payload.result.items}));
          // const search = filterParam(newFilter);
          // if (!_.isEmpty(search)) { 
          //   dispatch(setFilter({...search, url: location.pathname}));
          // } else {
          //   dispatch(setFilter({saleOrderNumber: response.payload.result.items[0].saleOrderNumber, saleOrderId: response.payload.result.items[0].saleOrderId, url: location.pathname}));
          // }
        } else {
          setSOPage(-1); // if no more result, set to -1
        }
      }
    })
    dispatch(setValues({filter: {page: 0, ...filterParam(newFilter)}}));
  },[]);

  useInterval(() => {
    getAllDeliveryAssignment(true);
  });

  return (
    <React.Fragment>
      <Card 
        title="Vehicle & Driver Assignment"
        subheader={
          <React.Fragment>
            <Typography>Below are the Delivery Orders to be assigned to the drivers and vehicles</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page" && key !== "saleOrderId" && !key.includes("Date")) {
                let label = filter[key];
                if (key === "customerId") {
                  const customer = customerList.find(({id}) => id == filter[key]);
                  label = customer && customer.customerName;
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose one or all filters to search through the assignment list
                  <Autocomplete
                    className={classes.filterDropdown}
                    dataCount={soPage*10}
                    options={saleOrderList}
                    fetchData={()=>fetchMoreSOData()}
                    renderOption={(option) => option.saleOrderNumber}
                    isLoadingMore={isLoadingMoreSO}
                    placeholder="Select a sale order"
                    onInputChange={(e, newInputValue) => {
                      if (e && e._reactName == "onBlur") {
                        setSOPage(0);
                        setSearchSOValue(newFilter.saleOrderNumber?newFilter.saleOrderNumber:"");
                        fetchMoreSOData(newInputValue, 0);
                      } 
                      if (e && e._reactName == "onClick") {
                        setSOPage(0);
                        setSearchSOValue(newInputValue);
                        fetchMoreSOData(undefined, 0);
                      }
                      if (e && e._reactName == "onChange") {
                        setSOPage(0);
                        setSearchSOValue(newInputValue);
                        fetchMoreSOData(newInputValue, 0);
                      }
                    }}
                    onChange={(_, newValue) => {
                      handleOnChange_SOautocomplete(newValue);
                    }}
                    inputValue={searchSOValue}
                    value={searchSOValue!==(selectedSO&&selectedSO.saleOrderNumber) ? null : selectedSO}
                    renderValue={"saleOrderNumber"}
                  />
                  <Select
                    className={classes.filterDropdown}
                    name="customerId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select customer"
                    value={renderValue(newFilter.customerId)}
                  >
                    {customerList && customerList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.customerName} ({item.customerRefNo})</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
            <DateRangePicker
              clearable
              placeholder="Delivery date"
              className={classes.cardActionDate}
              value={{startDate: filter.deliveryStartDate, endDate: filter.deliveryEndDate}}
              onChange={(e) => handleOnChange_date(e)}
              onClear={()=>dispatch(setValues({filter: {...filter, deliveryStartDate: null, deliveryEndDate: null, page: 0}}))}
            />
          </React.Fragment>
        }
        cardActions={checkPermission("Assignment", "readEdit") &&
          <React.Fragment>
            <Button disabled />
            <Button
              className={classes.button}
              onClick={()=>handleModal(true)}
              disabled={!stops.length}
            >
              Assign
            </Button>
          </React.Fragment>
        }
      >
        <div className={classes.table}>
          <Table
            header={filterActionTableHead(assignmentTableHead, canWrite)}
            // order={order}
            // orderBy={orderBy}
            // onRequestSort={handleRequestSort}
          >
            { results.map((item,index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.deliveryOrderRefNo}</TableCell>
                  <TableCell>{item.saleOrderNumber}</TableCell>
                  <TableCell>{moment(item.deliveryDate).format("DD/MM/YYYY")}</TableCell>
                  <TableCell>{item.customerName}</TableCell>
                  <TableCell>{item.deliveryAddress}</TableCell>
                  <TableCell>{item.contactPerson}</TableCell>
                  <TableCell>{item.contactDetail}</TableCell>
                  <TableCell align="center">
                    <Checkbox 
                      id={String(item.id)}
                      checked={Boolean(stops.find(({deliveryOrderId})=>deliveryOrderId===item.id))}
                      //disabled={stops.length ? Boolean(stops[0].deliveryOrderRefNo.slice(0, -5) !== item.deliveryOrderRefNo.slice(0, -5)) : false}
                      onChange={() => handleButtonClick_checkbox(item)}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </div>
        { count > 1 &&
          <Pagination 
            count={count} 
            page={filter.page+1}
            onChange={(e, pageNo)=>dispatch(setValues({filter: {...filter, page: pageNo-1}}))}
          />
        }
      </Card>
      <AssignModal open={openModal} onClose={handleModal} stops={stops} setStops={setStops} handleModal_details={handleModal_details} />
      <Modal
        className={classes.estimateModal}
        open={openDetailModal}
        onClose={() => handleModal_details()}
        title="Estimated Time Details"
        content={
          <Paper className={classes.orderPaper} elevation={0}>
            <List>
              {_.map(deliveryTripDetails, (item, index)=>{
                return (
                  <React.Fragment key={index}>
                    <ListItem>
                      <span className="font-medium">{index === 0 ? "Starting Point" : "Stop "+index} to {assignment.returnToStartPoint && index === deliveryTripDetails.length-1 ? "Ending Point" : "Stop "+(index+1)}:</span>&nbsp;{item.estimateTimeInMinute} minutes
                    </ListItem>
                    {!(assignment.returnToStartPoint && index === deliveryTripDetails.length-1) &&
                      <ListItem>
                        <span className="font-medium">Stop {index+1} Processing Time:</span>&nbsp;{inBetweenStopProcessingTime} minutes
                      </ListItem>
                    }
                  </React.Fragment>
                )
              })}
            </List>
          </Paper>
        }
        actions={
          <Button className={classes.buttonSecondary} onClick={() => handleModal_details()}>Go Back</Button>
        }
      />
    </React.Fragment>
  );
}
