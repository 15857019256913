import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"

import { updateRequest } from "../store/settings";
import { setDirty } from "store/general";

import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

const Maps = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapRef = React.useRef(null);
  const initialMarker = useSelector(store => store.user.delivery.settings.initialMarker);

  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    const geocoder = new google.maps.Geocoder();
    let lat = initialMarker.latitude;
    let lng = initialMarker.longitude;
    const defaultLatLng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 12,
      center: defaultLatLng,
      zoomControl: true,
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: defaultLatLng,
      map: map,
      draggable: true,
      animation: google.maps.Animation.DROP,
    });
    
    google.maps.event.addListener(marker, 'dragend', () => {
      const myLatLng = new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng());
      geocoder.geocode({'location': myLatLng}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          dispatch(setDirty(true));
          dispatch(updateRequest({address: results[0].formatted_address, latitude: marker.getPosition().lat(), longitude: marker.getPosition().lng()}));
        }
      });
    });

    if (props.address) {
      geocoder.geocode({'address': props.address}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
          marker.setPosition(results[0].geometry.location);
          dispatch(updateRequest({latitude: marker.getPosition().lat(), longitude: marker.getPosition().lng()}));
        }
      });
    }
  }, [props.address, initialMarker]);

  return (
    <div className={classes.settingsMap} ref={mapRef}></div>
  );
};

export default Maps;
