import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// @mui/material
import { makeStyles } from "@mui/styles"
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";

import { rowsPerTable } from "config";
import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { excessProductionTableHead } from "enums/UserPortal/TableHeaders";
import { setValues } from "../store/report";
import { GetPlanningReportByDateList } from "services/UserPortal/PlanningService";

import styles from "assets/jss/components/UserPortal/planningStyle.js";

const useStyles = makeStyles(styles);

export default function ExcessTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.planning.report.excessCount);
  const result = useSelector(store => store.user.planning.report.excess);
  // const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('productId');
  const [page, setPage] = React.useState(0);
  
  const count = Math.ceil(totalCount / rowsPerTable);
  
  // const handleRequestSort = (e, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const getPlanningReportByDateList = (stopLoading) => {
    if (props.state && props.state.date) {
      dispatch(GetPlanningReportByDateList({page, date: moment(props.state.date).format("YYYY-MM-DD"), stopLoading}))
      .then((response) => {
        const payload = response.payload.result.excessStockList;
        if (payload) {
          dispatch(setValues({excessCount: payload.totalCount, excess: payload.items}));
        }
      });
    }
  }

  React.useEffect(() => {
    getPlanningReportByDateList();
  },[page]);

  useInterval(() => {
    getPlanningReportByDateList(true);
  });

  return (
    <React.Fragment>
      <Table 
        className={classes.table}
        header={excessProductionTableHead}
        // order={order}
        // orderBy={orderBy}
        // onRequestSort={handleRequestSort}
      >
        { result.map((item,index) => {
          return (
            <TableRow key={index}>
            <TableCell>{item.productName}</TableCell>
              <TableCell>{item.productId}</TableCell>
              <TableCell>{formatNumbers(roundTo2Decimal(item.weight))}{item.uom}</TableCell>
            </TableRow>
          )
        })}
      </Table>
      { count > 1 &&
        <Pagination 
          count={count} 
          page={page+1}
          onChange={(e, pageNo)=>setPage(pageNo-1)}
        />
      }
    </React.Fragment>
  );
}
