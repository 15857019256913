// Create Edit Product
// Step 3: Create Raw Material
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
// core components
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Modal from "shared-components/Modal/Modal";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import IconButton from "shared-components/Button/IconButton";

import { isPositiveDecimal } from "common/validations";
import { productDeviceConfigurationTableHead } from "enums/AdminPortal/TableHeaders";

import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/productStyle.js";
import { setValues, updateSubTable } from "./store/product";
import { GetProductEquipment } from "services/AdminPortal/ProductService";

const useStyles = makeStyles(styles);

export default function CreateEquipment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(store => store.admin.product.product.selectedProduct);
  const selectedPreHarvestProcess = useSelector(store => store.admin.product.product.productPreHarvestGrowthProcess);
  const selectedPostHarvestProcess = useSelector(store => store.admin.product.product.productPostHarvestGrowthProcess);
  const equipment = useSelector(store => store.admin.product.product.subTable);
  const process = useSelector(store => store.admin.product.product.process);
  const allEquipmentDevice = useSelector(store => store.admin.product.lookup.equipmentList);
  const [isAdd, setIsAdd] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  var filteredEquipmentList = [];

  //filter rawmaterial and equipment list
  if (!props.isFromVersion) {
    const reducedEquipmentList = process.productEquipment && process.productEquipment.reduce((item,{equipmentName}) => item.add(equipmentName), new Set());
    filteredEquipmentList = reducedEquipmentList ? allEquipmentDevice.filter(({equipmentName}) => !reducedEquipmentList.has(equipmentName)) : allEquipmentDevice;
  }

  const handleOnChange_select = (e) => {
    const equip = _.find(allEquipmentDevice, {'id': e.target.value});
    let payload = _.cloneDeep(equipment);
    payload.uomeq = equip.lowestUOM;
    payload.deviceFunctionTypeMappingId = equip.id;
    dispatch(setValues({subTable: {
      ..._.omit(equip, ["farmId", "lowestUOM"]),
      ...payload
    }}))
  };

  const handleOnChange_text = (e) => {
    dispatch(updateSubTable({[e.target.id]: e.target.value}))
  };

  const handleButtonClick_add = () => {
    let payload = _.cloneDeep(process);
    if (payload.productEquipment) {
      payload.productEquipment.push({});
    } else {
      payload.productEquipment = [{}];
    }
    dispatch(setValues({process: payload}));
    setEditIndex(payload.productEquipment.length-1);
    setIsAdd(true);
    props.setEditMode(true);
  }

  const handleButtonClick_edit = (item, index) => {
    dispatch(setValues({subTable: item}));
    setEditIndex(index);
    props.setEditMode(true);
  }

  const handleButtonClick_confirm = () => {
    if (validateFields()) {
      let payload = _.cloneDeep(process);
      const obj = {
        ...equipment,
        uomProduct: props.type === "productPreHarvestGrowthProcess" ? "Per Tray" : "Per Kg",
      }
      payload.productEquipment[editIndex] = obj;

      // update the parent array 
      let processPayload = _.cloneDeep(selectedPostHarvestProcess);
      if (props.type === "productPreHarvestGrowthProcess") {
        processPayload = _.cloneDeep(selectedPreHarvestProcess);
      }
      processPayload[props.growthEditIndex] = payload;
      dispatch(setValues({[props.type]: processPayload, process: payload}));
      
      resetState();
    }
  }

  const handleButtonClick_cancel = () => {
    if (!equipment.id) {
      let payload = _.cloneDeep(process);
      payload.productEquipment.splice(editIndex, 1);
      dispatch(setValues({process: payload}));
    }
    resetState();
  }

  const handleModal = (index) => {
    setEditIndex(index);
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    let payload = _.cloneDeep(process);
    payload.productEquipment.splice(editIndex, 1);
    // update the parent array 
    let processPayload = _.cloneDeep(selectedPostHarvestProcess);
    if (props.type === "productPreHarvestGrowthProcess") {
      processPayload = _.cloneDeep(selectedPreHarvestProcess);
    }
    processPayload[props.growthEditIndex] = payload;
    dispatch(setValues({[props.type]: processPayload, process: payload}));
    setOpenModal(!openModal);
    resetState();
  }

  const resetState = () => {
    setErrorMsg(false);
    setIsAdd(false);
    setEditIndex(null);
    dispatch(setValues({subTable: {}}));
    props.setEditMode(false);
  }

  const validateFields = () => {
    if (!equipment.deviceFunctionTypeMappingId) {
      setErrorMsg({field: "deviceFunctionTypeMappingId", msg: "Please select a value"});
      return false;
    }
    if (!equipment.qtyeq) {
      setErrorMsg({field: "qtyeq", msg: "Please enter a quantity"});
      return false;
    }
    if (!isPositiveDecimal(equipment.qtyeq)) {
      setErrorMsg({field: "qtyeq", msg: "Please enter a valid quantity"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  const equipmentTable = () => {
    let component = [];
    process.productEquipment && process.productEquipment.map((item,index) => {
      const selected = _.find(allEquipmentDevice, (obj) => obj.equipmentName === item.equipmentName);
      const equipmentList = item.equipmentName ? [selected, ...filteredEquipmentList] : filteredEquipmentList;
      component.push(
        <TableRow key={index}>
          <TableCell>{props.type === "productPreHarvestGrowthProcess" ? "Per Tray" : "Per Kg"}</TableCell>
          <TableCell>
            { isAdd && editIndex === index
            ? 
            <Select
              name="deviceFunctionTypeMappingId"
              className={classes.alignTextfield}
              onChange={(e)=>handleOnChange_select(e)}
              value={equipment.deviceFunctionTypeMappingId}
              placeholder="Please select an equipment/device"
              errorMsg={errorMsg}
            >
              {equipmentList && equipmentList.map((item) => {
                return <MenuItem key={item.id} value={item.id}>{item.equipmentName}</MenuItem>;
              })} 
            </Select>
            : 
            item.equipmentName
            }
          </TableCell>
          <TableCell>
            { editIndex === index
            ? (equipment.uomeq ? equipment.uomeq : "Unit")
            : (item.uomeq ? item.uomeq : "Unit")}
          </TableCell>
          <TableCell>
            { editIndex === index
            ?
            <TextField 
              id="qtyeq"
              className={classes.alignTextfield}
              variant="outlined" 
              //inputProps={{ maxLength: 30 }}
              onChange={(e) => handleOnChange_text(e)}
              value={equipment.qtyeq}
              errorMsg={errorMsg}
            />
            :
            item.qtyeq
            }
          </TableCell>
          <TableCell>
            {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess"))
            ? (editIndex === index)
              ? <React.Fragment>
                  <IconButton 
                    type="confirm"
                    onClick={() => handleButtonClick_confirm(index)}
                  />
                  <IconButton 
                    type="close"
                    onClick={() => handleButtonClick_cancel()}
                  />
                </React.Fragment>
              : <React.Fragment>
                  <IconButton
                    type="edit" 
                    onClick={() => handleButtonClick_edit(item, index)}
                    disabled={props.editMode}
                  />
                  <IconButton 
                    type="delete"
                    onClick={() => handleModal(index)}
                    disabled={props.editMode}
                  />
                </React.Fragment>
            : <TableCell />
            }
          </TableCell>
        </TableRow>
      )
    })
    return component;
  }

  //componentDidMount
  React.useEffect(() => {
    dispatch(GetProductEquipment(props.type));
  },[]);

  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.deviceTable}>
        <Table
          className={classes.table}
          header={productDeviceConfigurationTableHead}
        >
          {equipmentTable()}
        </Table>
        {!props.isFromVersion && (!selectedProduct.combinationProduct || (selectedProduct.combinationProduct && props.type === "productPostHarvestGrowthProcess")) //check if user is in a non mix flow, or in a postharvest mix flow
        &&
        <Button
          className={clsx(classes.buttonSecondary, classes.addMoreProcess, classes.rawMaterialButton)}
          onClick={()=>handleButtonClick_add()}
          disabled={props.editMode}
        >
          + Add Equipment
        </Button>
        }
        {!props.isFromVersion
        && <React.Fragment>
            <br/><br/>
            <Link to={{pathname: "/admin/device"}} target="_blank" className={classes.link}>Go to Device/Equipment to add new Device/Equipment</Link>
          </React.Fragment>
        }
      </Paper>
      <Modal
				open={openModal}
				onClose={() => handleModal(null)}
				icon={<img className={classes.icon_64} src={alert} alt="alert" />}
				title="Are you sure?"
				content="Do you really want to delete this equipment/device? This process cannot be undone."
				actions={
					<React.Fragment>
						<Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
						<Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
					</React.Fragment>
				}
			/>
    </React.Fragment>
  )
}
