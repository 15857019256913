import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// @mui/icons-material
import CloseIcon from '@mui/icons-material/Close';
// core components
import TextField from "shared-components/TextField/TextField";

import { renderValue } from "common/functions";
import { updateRequest, updateHCRequest } from "../store/vegetable";
import { CalculateNetWeightUnderHealthCondition } from "services/UserPortal/QualityControlService";

import plus from "assets/icons/white/plus.svg"
import styles from "assets/jss/components/UserPortal/qualityControlStyle.js";

const useStyles = makeStyles(styles);

export default function HealthCondition(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputFileSmallest = React.createRef();
  const inputFileAverage = React.createRef();
  const inputFileBiggest = React.createRef();
  const qualityControl = useSelector(store => store.user.qualityControl.vegetable.qualityControl);

  const onButtonClick_image = (healthConditionName) => {
    switch (healthConditionName) {
      case "Smallest": 
        inputFileSmallest.current.click();
        break;
      case "Average": 
      inputFileAverage.current.click();
        break;
      case "Biggest": 
      inputFileBiggest.current.click();
        break;
    }
  }
  
  const handleOnChange_image = (e, healthConditionName) => {
    let tempHealthCondition = qualityControl.healthConditions ? _.cloneDeep(qualityControl.healthConditions) : [];
    const foundIndex = tempHealthCondition.findIndex((item) => item.healthConditionName == healthConditionName);
    if (foundIndex != -1) {
      tempHealthCondition[foundIndex].imageUrl = URL.createObjectURL(e.target.files[0]);
    } else {
      tempHealthCondition.push({
        imageUrl: URL.createObjectURL(e.target.files[0]),
        healthConditionName,
        qualityControlEntryId: qualityControl.id
      })
    }
    let images = _.cloneDeep(props.hcImages);
    const imageIndex = images.findIndex((item) => item.imageLocation == healthConditionName);
    if (imageIndex != -1) {
      images.splice(imageIndex, 0, {imageUrl: e.target.files[0], imageLocation: healthConditionName});
    } else {
      images.push({imageUrl: e.target.files[0], imageLocation: healthConditionName});
    }
    props.setHcImages(images);
    dispatch(updateRequest({healthConditions: tempHealthCondition}));
  }

  const handleButtonClick_deleteImage = (healthConditionName) => {
    let tempHealthCondition = qualityControl.healthConditions ? _.cloneDeep(qualityControl.healthConditions) : [];
    const foundIndex = tempHealthCondition.findIndex((item) => item.healthConditionName == healthConditionName);
    if (foundIndex != -1) {
      tempHealthCondition[foundIndex].imageUrl = "";
    }
    let images = _.cloneDeep(props.hcImages);
    const imageIndex = images.findIndex((item) => item.imageLocation == healthConditionName);
    if (imageIndex != -1) {
      images.splice(imageIndex, 1);
    }
    props.setHcImages(images);
    dispatch(updateRequest({healthConditions: tempHealthCondition}));
  }

  const handleOnChange_healthCondition = (e, healthConditionName) => {
    let tempHealthCondition = qualityControl.healthConditions ? _.cloneDeep(qualityControl.healthConditions) : [];
    const foundIndex = tempHealthCondition.findIndex((item) => item.healthConditionName == healthConditionName);
    if (foundIndex != -1) {
      tempHealthCondition[foundIndex][e.target.id] = e.target.value;
    } else {
      tempHealthCondition.push({
        [e.target.id]: e.target.value,
        healthConditionName,
        qualityControlEntryId: qualityControl.id
      })
    }
    dispatch(updateRequest({healthConditions: tempHealthCondition}));
  }
  
  React.useEffect(() => {
    if (qualityControl.healthConditions && qualityControl.healthConditions.length) {
      qualityControl.healthConditions.map((item, i) => {
        if (item.grossWeight && item.discardedLeavesWeight) {
          dispatch(CalculateNetWeightUnderHealthCondition({grosssWeight: item.grossWeight, discardedLeavesWeight: item.discardedLeavesWeight}))
          .then((response) => {
            dispatch(updateHCRequest({index: i, value: response.payload.result}));
          });
        }
      })
    }
  },[qualityControl.healthConditions]);
  
  const smallestHC = qualityControl.healthConditions && qualityControl.healthConditions.find(({healthConditionName}) => healthConditionName === "Smallest");
  const averageHC = qualityControl.healthConditions && qualityControl.healthConditions.find(({healthConditionName}) => healthConditionName === "Average");
  const biggestHC = qualityControl.healthConditions && qualityControl.healthConditions.find(({healthConditionName}) => healthConditionName === "Biggest");
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Photos:</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.grid}>
              <div className={classes.hcImageDiv}>
                <Typography className={classes.formLabel3}>Smallest</Typography>
                <input 
                  ref={inputFileSmallest} 
                  style={{"display": "none"}} 
                  type="file" 
                  accept="image/*"
                  onChange={(e) => handleOnChange_image(e, "Smallest", 0)}
                  onClick={event => event.target.value = null}
                />
                <div className={classes.hcImageContainer}>
                  {(smallestHC && smallestHC.imageUrl) && <Button className={classes.deleteImage} onClick={()=>handleButtonClick_deleteImage("Smallest")}><CloseIcon /></Button>}
                  <img src={(smallestHC && smallestHC.imageUrl) ? smallestHC.imageUrl : plus} className={classes.hcImage} onClick={()=>onButtonClick_image("Smallest")} />
                </div>
              </div>
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <div className={classes.hcImageDiv}>
                <Typography className={classes.formLabel3}>Average</Typography>
                <input 
                  ref={inputFileAverage} 
                  style={{"display": "none"}} 
                  type="file" 
                  accept="image/*"
                  onChange={(e) => handleOnChange_image(e, "Average", 1)}
                  onClick={event => event.target.value = null}
                />
                <div className={classes.hcImageContainer}>
                  {(averageHC && averageHC.imageUrl) && <Button className={classes.deleteImage} onClick={()=>handleButtonClick_deleteImage("Average")}><CloseIcon /></Button>}
                  <img src={(averageHC && averageHC.imageUrl) ? averageHC.imageUrl : plus} className={classes.hcImage} onClick={()=>onButtonClick_image("Average")} />
                </div>
              </div>
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <div className={classes.hcImageDiv}>
                <Typography className={classes.formLabel3}>Biggest</Typography>
                <input 
                  ref={inputFileBiggest} 
                  style={{"display": "none"}} 
                  type="file" 
                  accept="image/*"
                  onChange={(e) => handleOnChange_image(e, "Biggest", 2)}
                  onClick={event => event.target.value = null}
                />
                <div className={classes.hcImage}>
                  { (biggestHC && biggestHC.imageUrl) && <Button className={classes.deleteImage} onClick={()=>handleButtonClick_deleteImage("Biggest")}><CloseIcon /></Button>}
                  <img src={(biggestHC && biggestHC.imageUrl) ? biggestHC.imageUrl : plus} className={classes.hcImage} onClick={()=>onButtonClick_image("Biggest")} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* {qualityControl.isOldRecord &&
          <>
            <Grid item xs={4} className={classes.grid}>
              <Typography className={classes.formLabel}>Quantity</Typography>
            </Grid>
            <Grid item xs={8} className={classes.grid}>
              <Grid container spacing={1}>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="quantity"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onInput={(e)=>{ 
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                    }}
                    onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                    value={smallestHC && renderValue(smallestHC.quantity)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="quantity"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onInput={(e)=>{ 
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                    }}
                    onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                    value={averageHC && renderValue(averageHC.quantity)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="quantity"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onInput={(e)=>{ 
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                    }}
                    onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                    value={biggestHC && renderValue(biggestHC.quantity)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        } */}
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Gross Weight Less Growing Media & Roots (g):</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="grossWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                value={smallestHC && renderValue(smallestHC.grossWeight)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="grossWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                value={averageHC && renderValue(averageHC.grossWeight)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="grossWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                value={biggestHC && renderValue(biggestHC.grossWeight)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Discarded Leaves Weight (g):</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="discardedLeavesWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                value={smallestHC && renderValue(smallestHC.discardedLeavesWeight)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="discardedLeavesWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                value={averageHC && renderValue(averageHC.discardedLeavesWeight)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="discardedLeavesWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                value={biggestHC && renderValue(biggestHC.discardedLeavesWeight)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Net Weight (g):</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="netWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                value={smallestHC && renderValue(smallestHC.netWeight)}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="netWeight"
                variant="outlined"  
                type="Number"
                placeholder=""
                value={averageHC && renderValue(averageHC.netWeight)}
                disabled
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="netWeight"
                variant="outlined" 
                type="Number"
                placeholder=""
                value={biggestHC && renderValue(biggestHC.netWeight)}
                disabled
                errorMsg={props.errorMsg}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Height of Plant (cm):</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="heightOfPlant"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                value={smallestHC && renderValue(smallestHC.heightOfPlant)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="heightOfPlant"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                value={averageHC && renderValue(averageHC.heightOfPlant)}
                
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="heightOfPlant"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                value={biggestHC && renderValue(biggestHC.heightOfPlant)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <Typography className={classes.formLabel}>Width of Plant (cm):</Typography>
        </Grid>
        <Grid item xs={8} className={classes.grid}>
          <Grid container spacing={1}>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="widthOfPlant"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                value={smallestHC && renderValue(smallestHC.widthOfPlant)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="widthOfPlant"
                variant="outlined" 
                type="Number"
                placeholder=""
                onInput={(e)=>{ 
                  e.target.value = Math.max(0, e.target.value).toString().slice(0,4)
                }}
                onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                value={averageHC && renderValue(averageHC.widthOfPlant)}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <TextField 
                className={classes.textFieldSmall2}
                id="widthOfPlant"
                variant="outlined" 
                type="Number"
                placeholder=""
                onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                value={biggestHC && renderValue(biggestHC.widthOfPlant)}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* {qualityControl.isOldRecord &&
          <>
            <Grid item xs={4} className={classes.grid}>
              <Typography className={classes.formLabel}>Chlorophyll Level (CCI):</Typography>
            </Grid>
            <Grid item xs={8} className={classes.grid}>
              <Grid container spacing={1}>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="chlorophyllLevel"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                    value={smallestHC && renderValue(smallestHC.chlorophyllLevel)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="chlorophyllLevel"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                    value={averageHC && renderValue(averageHC.chlorophyllLevel)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="chlorophyllLevel"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                    value={biggestHC && renderValue(biggestHC.chlorophyllLevel)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <Typography className={classes.formLabel}>Brix (%):</Typography>
            </Grid>
            <Grid item xs={8} className={classes.grid}>
              <Grid container spacing={1}>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="brix"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onChange={(e) => handleOnChange_healthCondition(e, "Smallest")}
                    value={smallestHC && renderValue(smallestHC.brix)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="brix"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onChange={(e) => handleOnChange_healthCondition(e, "Average")}
                    value={averageHC && renderValue(averageHC.brix)}
                  />
                </Grid>
                <Grid item xs={4} className={classes.grid}>
                  <TextField 
                    className={classes.textFieldSmall2}
                    id="brix"
                    variant="outlined" 
                    type="Number"
                    placeholder=""
                    onChange={(e) => handleOnChange_healthCondition(e, "Biggest")}
                    value={biggestHC && renderValue(biggestHC.brix)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        } */}
      </Grid>
    </React.Fragment>
  );
}
