// Create Edit Device Tagging
// Step 3: Tag Bank to Floor Plan
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImageMarker from 'react-image-marker';
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// @mui/icons-material
import RemoveIcon from '@mui/icons-material/Remove';
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";

import Loading from "error-page/Loading";
import DeviceTaggingStepper from "./DeviceTaggingStepper";
import { farmConfig } from "enums/Constants";
import { setDirty } from "store/general";
import { resetSkipStep } from "./store/deviceTagging";
import { setValues, updateRequest, updateDeletedRequest, updateIndexRequest, resetBanks } from "./store/bank";
import { GetAllBank, GetAllFloorPlan, UpdateFloorPlanBank } from "services/AdminPortal/DeviceTaggingService";

import alert from "assets/icons/orange/alert-line.svg";
import bin from "assets/icons/orange/delete-bin.svg";
import bin_disabled from "assets/icons/dark-grey/delete-bin.svg";
import plus from "assets/icons/orange/plus.svg";
import plus_disabled from "assets/icons/dark-grey/plus.svg";
import styles from "assets/jss/components/AdminPortal/deviceTaggingStyle.js";

const useStyles = makeStyles(styles);
export default function BankForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const skipStep = useSelector(store => store.admin.deviceTagging.deviceTagging.skipStep);
  const floors = useSelector(store => store.admin.deviceTagging.floorPlan.floors);
  const bankList = useSelector(store => store.admin.deviceTagging.bank.bankList);
  const deletedBanks = useSelector(store => store.admin.deviceTagging.bank.deletedBanks);
  const banks = useSelector(store => store.admin.deviceTagging.bank.banks);
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState({});
  const [isChanged, setIsChanged] = React.useState(false);
  const [changeSelection, setChangeSelection] = React.useState(null);
  const [alertAction, setAlertAction] = React.useState(false);
  const [zoom, setZoom] = React.useState(0);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);

  const handleOnChange_select = (target) => {
    if (target.name === "floor") {
      const selectedFloor = floors.find((item) => item.id === target.value);
      setSelected({floor: target.value, sector: selectedFloor.sectors[0].id});
    } else {
      setSelected({...selected, sector: target.value});
    }
  };

  const handleButtonClick_icon = (id) => {
    setIsChanged(true);   
    let payload = [];
    const index = _.findIndex(banks, ["bankId", id]);
    const bank = bankList.find((item) => item.id === id);
    if (index !== -1) {  // alr exists,  remove
      const deleted = Object.assign({}, banks.find((item) => item.bankId === id));
      if (deleted.isInSector) {
        Object.assign(deleted, {isDeleted: true});
        dispatch(updateDeletedRequest([...deletedBanks, deleted]));
      }

      payload = banks.filter((item) => item.bankId !== id);
      markers.splice(index, 1);
      dispatch(updateRequest(payload));
    } else {
      payload = _.cloneDeep(_.find(deletedBanks, (item) => item.bankId === id));
      if (_.isEmpty(payload)) {
        payload = {bankId: id, bankName: bank.bankName};
      }
      Object.assign(payload, {isDeleted: false});
      dispatch(updateDeletedRequest(_.filter(deletedBanks, (item) => item.bankId !== id)));
      dispatch(updateRequest([...banks, payload]));
    }
    const sector = floor.sectors.find((item) => item.id === selected.sector);
    if (!sector.floorPlanDiagramUrl) {
      setMarkers([...markers, {top: 0, left: 0}]);
    }
  };

  const handleOnChange_marker = (marker) => {
    if (markers.length < banks.length) {
      setMarkers([...markers, marker]);
      const lastIndex = banks.length - 1;
      const transformed = { floorPlanY: marker.top, floorPlanX: marker.left };
      dispatch(updateIndexRequest({index: lastIndex, payload: transformed}));
    } 
  }

  const handleButtonClick_zoom = (action) => {
    if (action === "in") {
      setZoom(zoom+1);
    } else {
      setZoom(zoom-1);
    }
  }

  const handleButtonClick_exit = () => {
    setChangeSelection(null);
    setOpenAlertModal(!openAlertModal);
  }
  
  const handleButtonClick_alert = (action, value) => {
    resetState();
    if (action === "select") {
      handleOnChange_select(value);
    } else if (action === "back") {
      props.setStep(1);
    } else if (action === "next") {
      props.setStep(3);
    } else if (action === "complete") {
      handleButtonClick_skip();
    }
  }

  const handleModal_alert = (action, e) => {
    if (isChanged) {
      setOpenAlertModal(!openAlertModal);
      setAlertAction(action);
      if (action === "select") {
        setChangeSelection({name: e.target.name, value: e.target.value});
      }
    } else {
      if (e) {
        handleButtonClick_alert(action, {name: e.target.name, value: e.target.value});
      } else {
        handleButtonClick_alert(action);
      }
    }
  }

  const handleModal_warning = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const handleButtonClick_skip = () => {
    Promise.all([dispatch(setDirty(false))])
    .then(() => {
      history.push("/admin/device-tagging");
    })
  }

  const handleButtonClick_save = () => {
    dispatch(UpdateFloorPlanBank(selected.sector))
    .then((response) => {
      if (response.error) {
        setIsChanged(true);
      } else {
        resetState();
        getAllBanks();
        dispatch(GetAllFloorPlan());
      }
    });
  }

  const getAllBanks = () => {
    dispatch(GetAllBank({IsAssignFloorPlan: false}))
    .then((response) => {
      let value = response.payload.result;
      if (!_.isEmpty(selected)) {
        dispatch(GetAllBank({
          sectorId: selected.sector,
          IsAssignFloorPlan: true
        }))
        .then(({payload}) => {
          if (payload && payload.result) {
            const result = payload.result;
            dispatch(setValues(_.unionBy(value, result, "id")));
            let location = [];
            for (let obj of result) {
              location.push({ top: obj.floorPlanY, left: obj.floorPlanX });
            }
            setMarkers(location);
          }
        })
      } else {
        dispatch(setValues(value));
      }
    })
  }

  const CustomMarker = (obj) => {
    if (banks.length) {
      const bank = bankList.find((item) => item.id === banks[obj.itemNumber].bankId);
      if (bank) {
        return (
          <div className={classes.markerDiv}>
            <div className={classes.marker}>{bank.bankName.charAt(0)}</div>
            <Typography className={classes.markerText}>{bank.bankName}</Typography>
          </div>
        );
      }
    }
    return null;
  };

  const resetState = () => {
    setOpenAlertModal(false);
    setIsChanged(false);
    dispatch(resetBanks());
    setMarkers([]);
    setZoom(0);
  }

  React.useEffect(() => {
    dispatch(setDirty(isChanged));
  },[isChanged]);

  React.useEffect(() => {
    getAllBanks();
  },[selected]);

  // componentDidMount
  React.useEffect(() => {
    resetState();
    if (!_.isEmpty(skipStep) && skipStep.step === 2) {
      Promise.all([
        dispatch(GetAllFloorPlan()),
      ]).then((response) => {
        const floorList = response[0].payload.result.items;
        if (skipStep.sectorId) { // from device tagging main page
          setSelected({floor: skipStep.floorId, sector: skipStep.sectorId});
        } else {
          setSelected({floor: floorList && floorList[0].id, sector: floorList && floorList[0].sectors && floorList[0].sectors[0].id});
        }
      })
    } else {  // go by normal route
      setSelected({floor: floors[0].id, sector: floors[0].sectors && floors[0].sectors[0].id});
    }
    // componentDidUnmount
    return () => {
      resetState();
      dispatch(resetSkipStep());
    }
  },[]);
  
  const floor = floors.find((item) => item.id === selected.floor);
  if (!_.isEmpty(selected) && !_.isEmpty(floor)) {
    const sector = floor.sectors.find((item) => item.id === selected.sector);
    return (
      <React.Fragment>
        <Card 
          title={"Step 3: Add in " + farmConfig.bank + " Information"}
          subheader={
            <React.Fragment>
              Please add in {farmConfig.bank} information based on the floor plan, if there is no floor plan, click “Next Step”
              <DeviceTaggingStepper activeStep={props.step} />
            </React.Fragment>
          }
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={() => handleModal_alert("back")}
              >
                Go Back
              </Button>
              <div>
                <Button
                  className={clsx(classes.buttonSecondary, classes.buttonMargin)}
                  onClick={()=>isChanged ? handleModal_alert("complete") : handleModal_warning()}
                >
                  Save Draft
                </Button>
                <Button
                  className={classes.button}
                  onClick={() => handleModal_alert("next")}
                >
                  Next Step
                </Button>
              </div>
            </React.Fragment>
          }
        >
        <div className={classes.content}>
          <Card
            classes={{
              root: clsx(classes.cardPaper, classes.floorCardLeft),
              title: classes.cardTitle,
              content: classes.bankLeftContent,
              cardActions: classes.cardLeftActions
            }}
            title={farmConfig.bank}
            cardActions={
              <React.Fragment>
                <Button
                  className={classes.button}
                  onClick={()=>handleButtonClick_save()}
                  disabled={!isChanged}
                >
                  Save
                </Button>
              </React.Fragment>
            }
          >
            { _.orderBy(bankList, ["bankName"], ["asc"]).map((item, index) => {
              const itemIndex = _.findIndex(banks, ["bankId", item.id]);
              return (
                <div key={index} className={classes.buttonGrid}>
                  <IconButton 
                    className={classes.addTagButton}
                    onClick={() => handleButtonClick_icon(item.id)}
                    disabled={markers.length < banks.length && itemIndex !== banks.length-1 || !(sector && sector.floorPlanDiagramUrl)}
                  >
                    {itemIndex !== -1 
                    ? <React.Fragment>
                        { markers.length < banks.length && itemIndex !== banks.length-1
                        ? <img className={classes.icon} src={bin_disabled} alt="bin" />
                        : <img className={classes.icon} src={bin} alt="bin" />
                        }
                      </React.Fragment> 
                    : <React.Fragment>
                        { markers.length < banks.length
                        ? <img className={classes.icon} src={plus_disabled} alt="plus" />
                        : <img className={classes.icon} src={plus} alt="plus" />
                        }
                      </React.Fragment> 
                    }
                  </IconButton>
                  <Typography className={classes.buttonLabel}>{item.bankName}</Typography>
                </div>
              )
            })}
          </Card>
          <Card
            classes={{
              root: clsx(classes.cardPaper, classes.cardRight),
              title: classes.cardTitle,
              content: (sector && sector.floorPlanDiagramUrl) ? classes.cardRightContent : clsx(classes.cardRightContent, "justify-center")
            }}
            title= {floor && "Floor Plan Diagram for Floor " + floor.floorNumber +" & Sector " + sector.sectorNumber}
            action={
              <div className={classes.headerAction}>
                <Select
                  className={classes.headerSelect}
                  name="floor"
                  onChange={(e)=>handleModal_alert("select", e)}
                  value={selected.floor}
                >
                  { floors.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>Floor {item.floorNumber}</MenuItem>;
                  })} 
                </Select>
                <Select
                  className={classes.headerSelect}
                  name="sector"
                  onChange={(e)=>handleModal_alert("select", e)}
                  value={selected.sector}
                >
                  { floor.sectors && floor.sectors.map((item, index) => {
                    return <MenuItem key={index} value={item.id}>Sector {item.sectorNumber}</MenuItem>;
                  })} 
                </Select>
              </div>
            }
          >
            { sector && sector.floorPlanDiagramUrl 
            ? <React.Fragment>
                <div className={classes.zoomDiv}>
                  <IconButton 
                    className={classes.zoomButton}
                    onClick={()=>handleButtonClick_zoom("out")}
                    disabled={zoom === 0}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton 
                    className={classes.zoomButton}
                    onClick={()=>handleButtonClick_zoom("in")}
                    disabled={zoom === 4}
                  >
                    { zoom === 4
                    ? <img className={classes.icon} src={plus_disabled} alt="plus" />
                    : <img className={classes.icon} src={plus} alt="plus" />
                    }
                  </IconButton>
                </div>
                <ImageMarker
                  extraClass={clsx(classes.floorPlanImg, classes["floorPlanImg"+zoom])}
                  src={sector.floorPlanDiagramUrl}
                  markers={markers}
                  onAddMarker={(marker) => handleOnChange_marker(marker)}
                  markerComponent={CustomMarker}
                />
              </React.Fragment>
            : <Typography className={classes.emptyLabel}>No Floor Plan</Typography>
            }
          </Card>
        </div>
        </Card>
        <Modal
          open={openAlertModal}
          onClose={() => handleModal_alert()}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="You have not saved your current changes. Changing your selection will overwrite your current changes."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleButtonClick_exit()}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_alert(alertAction, changeSelection)}>OK</Button>
            </React.Fragment>
          }
        />
        <Modal
          open={openWarningModal}
          onClose={() => handleModal_warning()}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you want to save the details first? You will be directed to device tagging page."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal_warning()}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleButtonClick_skip()}>Confirm</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  } else {
    return <Loading />
  }
}
