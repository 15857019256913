/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import Tag from 'shared-components/Chip/Tag';

import { useInterval } from 'common/utils';
import { formatNumbers, roundTo2Decimal } from 'common/functions';
import { GetAllQCReportConfiguration } from "services/UserPortal/ReportService";

import qualityControl from "assets/icons/black/quality-control.svg";
import enter from "assets/icons/orange/enter.svg";
import styles from "assets/jss/components/UserPortal/reportStyle.js";

const useStyles = makeStyles(styles);

export default function Report(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const totalCount = useSelector(store => store.user.report.report.totalCount);

  const moduleList = [
    {title: 'Quality Control Module', icon: qualityControl, url: '/quality-control'}
  ]

  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllQCReportConfiguration({page: 0}));
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = "Report Modules"
      >
        <Grid container spacing={4}>
          {moduleList.map((item, index) => {
            return (
              <Grid key={index} item xs={3}>
                <Paper className={classes.card}>
                  <div className={classes.cardItem}>
                    <img className={classes.icon_48} src={item.icon} alt="warehouse" />
                    <div>
                      <Typography className={classes.title}>{item.title}</Typography>
                      <Typography>{formatNumbers(totalCount)} configurations available</Typography>
                    </div>
                  </div>
                  <div className={classes.cardLink}>
                    <Link className={classes.cardLink} to={{pathname: location.pathname + '/dashboard' +item.url, state: {title: item.title}}}>View Report</Link>
                  </div>
                </Paper>
              </Grid>
            )
          })}
          {/* <div className={classes.cardContainer}>
            <Summary equipmentId={props.equipmentId} />
          </div> */}
        </Grid>
      </Card>
    </React.Fragment>
  );
}
