import { createSlice } from "@reduxjs/toolkit";

import { GetAllCageList, GetCagePreview, GetChamberList, GetCageList, GetBarcodeIDList, GetLocationIDList, GetStatusList, SearchCages } from "services/AdminPortal/CagesService";

// initial state
const initialState = {
  filter: {},
  totalCount: 0,
  cages: [],
  cage: {},
  cagePreview: [],
  //lookup
  chamberList: [],
  cageList: [],
  barcodeList: [],
  locationList: [],
  statusList: [],
};

const cage = createSlice({
  name: "cage",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    updateRequest: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.cage[key] = action.payload[key];
      })
    },
    resetCage: (state) => {
      state.cage = {};
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetAllCageList.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.cages = action.payload.result.items;
    },
    [GetCagePreview.fulfilled]: (state, action) => {
      state.cagePreview = action.payload.result.items;
    },
    [SearchCages.fulfilled]: (state, action) => {
      state.totalCount = action.payload.result.totalCount;
      state.cages = action.payload.result.items;
    },
    //lookup
    [GetChamberList.fulfilled]: (state, action) => {
      state.chamberList = action.payload.result;
    },
    [GetCageList.fulfilled]: (state, action) => {
      state.cageList = action.payload.result;
    },
    [GetBarcodeIDList.fulfilled]: (state, action) => {
      state.barcodeList = action.payload.result;
    },
    [GetLocationIDList.fulfilled]: (state, action) => {
      state.locationList = action.payload.result;
    },
    [GetStatusList.fulfilled]: (state, action) => {
      state.statusList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, updateRequest, resetCage, reset } = cage.actions;

// export the reducer
export default cage.reducer;