// Create Edit Farm
// Step 2: Bank Details
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
// @mui/icons-material
import AddIcon from '@mui/icons-material/Add';
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import TextField from "shared-components/TextField/TextField";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import { hideCages } from "config";
import { setDirty } from "store/general";
import { farmConfig } from "enums/Constants";
import { bankTableHead } from "enums/AdminPortal/TableHeaders";
import { renderValue } from "common/functions";
import { updateBanksRequest, addRequest } from "./";
import { CreateOrUpdateFarm, GetUserProfile } from "services/AdminPortal/FarmService";

import FarmStepper from "./FarmStepper";
import BankExplanation from "components/AdminPortal/util/BankExplanation";
import helper from "assets/icons/black/helper.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/farmStyle.js";

const useStyles = makeStyles(styles);

export default function BankForm(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const banks = useSelector(store => store.admin.farm.banks);
  const farm = useSelector(store => store.admin.farm.farm);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [bankIndex, setBankIndex] = React.useState(null);
  const [showExplanation, setShowExplanation] = React.useState(false);

  const handleOnChange_text = (e, index) => {
    const key = e.target.id.replace(/\d/g, '');
    dispatch(updateBanksRequest({index, value: {[key] : e.target.value}}));
    let value = banks[index].numberOfLevels;
    if (key === "numberOfLevels") {
      value = banks[index].numberOfBays;
    }
    if (key === "numberOfBays" || key === "numberOfLevels") {
      dispatch(updateBanksRequest({index, value: {numberOfCages : !value && !e.target.value ? "" : Number(value) * Number(e.target.value)}}));
    }
  };

  const handleModal = (index) => {
    setBankIndex(index);
    setOpenModal(!openModal);
  }

  const handleAddBanks = () => {
    dispatch(addRequest());
    setErrorMsg(false);
  }

  const handleDelete = () => {
    dispatch(updateBanksRequest({index: bankIndex, value: {isDeleted : true}}));
    setOpenModal(!openModal);
    setErrorMsg(false);
  }

  const handleButtonClick_save = () => {
    if (validateFields()) {
      Promise.all([dispatch(setDirty(false))])
      .then(() => {
        dispatch(CreateOrUpdateFarm())
        .then((response) => {
          if (response.error) {
            dispatch(setDirty(true));
          } else {
            dispatch(GetUserProfile())
            .then(({error}) => {
              if (error) {
                dispatch(setDirty(true));
              } else {
                history.push("/admin/farm");
              }
            });
          }
        });
      })
    }
  };

  const validateFields = () => {
    for (let i = 0; i < banks.length; i++) {
      if (_.isEmpty(banks[i].bankName)) {
        setErrorMsg({field: "bankName"+i, msg: "Please add in your "+farmConfig.bank+" name"});
        return false;
      }
      if (!banks[i].numberOfBays) {
        setErrorMsg({field: "numberOfBays"+i, msg: "Please enter a number of "+farmConfig.bays});
        return false;
      }
      if (!banks[i].numberOfLevels) {
        setErrorMsg({field: "numberOfLevels"+i, msg: "Please enter a number of "+farmConfig.levels});
        return false;
      } 
    }
    setErrorMsg(false);
    return true;
  }

  if (showExplanation) {
    return <BankExplanation setShowExplanation={setShowExplanation} />;
  } else {
    return (
      <React.Fragment>
        <Card 
          title={
            <div className={classes.title}>
              Step 2: Add a {farmConfig.bank}
              <IconButton 
                className={classes.helpButton}
                onClick={() => setShowExplanation(true)}
              >
                <img className={classes.helperIcon} src={helper} alt="helper" />
              </IconButton>
            </div>
          }
          subheader={
            <React.Fragment>
              Please make sure you fill in all the fields before proceeding to the next step.
              <FarmStepper activeStep={props.step} />
            </React.Fragment>
          }
          cardActions={
            <React.Fragment>
              <Button
                className={classes.buttonSecondary}
                onClick={()=>props.setStep(0)}
              >
                Go Back
              </Button>
              <Button
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
              >
                { farm.id
                ? "Update Farm"
                : "Create New Farm"
                }
              </Button>
            </React.Fragment>
          }
        >
          <Table
            className={clsx(classes.table, classes.whiteTable)}
            header={bankTableHead}
          >
            { banks.map((bank, index) => {
              if (!bank.isDeleted) {
                return (
                  <TableRow key={index}>
                    <TableCell className={clsx(classes.textfieldCell, classes.bankName)}>
                      <TextField 
                        id={"bankName"+index}
                        variant="outlined" 
                        inputProps={{ maxLength: 30 }}
                        placeholder={"Enter "+farmConfig.bank+" name"}
                        onChange={(e) => handleOnChange_text(e, index)}
                        value={renderValue(bank.bankName)}
                        errorMsg={errorMsg}
                      />
                    </TableCell>
                    <TableCell className={classes.textfieldCell}>
                      <TextField 
                        id={"numberOfBays"+index}
                        variant="outlined" 
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                        }}
                        placeholder={"Enter number of "+farmConfig.bay}
                        onChange={(e) => handleOnChange_text(e, index)}
                        value={renderValue(bank.numberOfBays)}
                        errorMsg={errorMsg}
                      />
                    </TableCell>
                    <TableCell className={classes.textfieldCell}>
                      <TextField 
                        id={"numberOfLevels"+index}
                        variant="outlined" 
                        type="Number"
                        onInput={(e)=>{ 
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4)
                        }}
                        placeholder={"Enter number of "+farmConfig.level}
                        onChange={(e) => handleOnChange_text(e, index)}
                        value={renderValue(bank.numberOfLevels)}
                        errorMsg={errorMsg}
                      />
                    </TableCell>
                    {!hideCages && 
                      <TableCell className={classes.textfieldCell}>
                        <TextField 
                          id={"numberOfCages"+index}
                          variant="outlined" 
                          type="Number"
                          disabled
                          placeholder={"NumberOf"+farmConfig.cage+" = NumberOf"+farmConfig.bay+" * NumberOf"+farmConfig.level}
                          value={renderValue(bank.numberOfCages)}
                        />
                      </TableCell>
                    }
                    <TableCell className={classes.textfieldCell}>
                      { index !== 0 &&
                        <CustomIconButton 
                          type="delete"
                          className={classes.cellButton}
                          onClick={() => handleModal(index)}
                        />
                      }
                    </TableCell>
                  </TableRow>
                )
              } else {
                return null
              }
            })}
          </Table>
          <Button
            className={clsx(classes.buttonSecondary, classes.bankButton)}
            startIcon={<AddIcon />}
            onClick={() => handleAddBanks()}
          >
            Add More {farmConfig.bank}
          </Button>
        </Card>
        <Modal
          open={openModal}
          onClose={() => handleModal(null)}
          icon={<img className={classes.icon_64} src={alert} alt="alert" />}
          title="Are you sure?"
          content="Do you really want to delete this item? This process cannot be undone."
          actions={
            <React.Fragment>
              <Button className={classes.buttonSecondary} onClick={() => handleModal(null)}>Cancel</Button>
              <Button className={classes.button} onClick={() => handleDelete()}>Delete</Button>
            </React.Fragment>
          }
        />
      </React.Fragment>
    )
  }
}
