/* eslint-disable no-unused-vars */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import Tag from 'shared-components/Chip/Tag';
import FilterButton from "shared-components/Button/FilterButton";

import { renderValue, filterParam } from "common/functions";
import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { setValues } from "../store/overview";
import { GetProductList } from "services/UserPortal/CommonLookupService";
import { GetOperationTimeLineByWorkOrderId } from "services/UserPortal/OperationsService";

import errorImg from "assets/img/error-img.svg";
import styles from "assets/jss/components/UserPortal/operationsStyle.js";

const useStyles = makeStyles(styles);

export default function TransplantDetails({setView, ...props}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workOrderList = useSelector(store => store.common.workOrderList);
  const productList = useSelector(store => store.common.productList);
  const filter = useSelector(store => store.user.operations.overview.filter);
  const products = useSelector(store => store.user.operations.overview.products);
  const [newFilter, setNewFilter] = React.useState({workOrderId: null, productId: null});

  const handleOnChange_select = (e) => {
    if (e.target.name == "workOrderId") {
      setNewFilter({...newFilter, [e.target.name]: e.target.value, productId: null});
    } else {
      setNewFilter({...newFilter, [e.target.name]: e.target.value});
    }
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
  };
  
  const getOperationTimeline = (stopLoading) => {
    if (filter.workOrderId) {
      dispatch(GetOperationTimeLineByWorkOrderId({...filter, stopLoading}));
    } else {
      dispatch(setValues({products: []}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
      dispatch(GetProductList(search.workOrderId));
    } else if (workOrderList.length) {
      dispatch(setValues({filter: {workOrderId: workOrderList[0].id, productId: null}}));
    } else {
      dispatch(setValues({filter: {workOrderId: null, productId: null}}));
    }
  },[workOrderList]);

  React.useEffect(() => {
    if (newFilter.workOrderId) {
      dispatch(GetProductList(newFilter.workOrderId));
    }
  },[newFilter.workOrderId]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil))) || !location.search) {
      getOperationTimeline();
    }
  },[filter]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setSearchText(""));
  },[]);

  useInterval(() => {
    getOperationTimeline(true);
  });

  return (
    <React.Fragment>
      <Card
        classes={{
          root: classes.cardPaper,
        }}
        title="Transplanting in Progress"
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={() => setView("details")}
            >
              Back
            </Button>
          </React.Fragment>
        }
      >
        <Grid container spacing={1} className={classes.hrDivider}>
          <Grid item xs={2}>
            <img className={classes.image} src={errorImg} alt="greenphyto-error" />
          </Grid>
          <Grid item xs={2}>
            <img className={classes.image} src={errorImg} alt="greenphyto-error" />
          </Grid>
          <Grid item xs={2}>
            <img className={classes.image} src={errorImg} alt="greenphyto-error" />
          </Grid>
          <Grid item xs={2}>
            <img className={classes.image} src={errorImg} alt="greenphyto-error" />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
