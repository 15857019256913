// Farm Search
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Pagination from "shared-components/Table/Pagination";
import Modal from "shared-components/Modal/Modal";
import CustomIconButton from "shared-components/Button/IconButton";

import { formatNumbers, filterActionTableHead } from "common/functions";
import { rowsPerTable, hideCages } from "config";
import { resetSearch } from ".";
import { setSearchText } from "store/general";
import { farmConfig } from "enums/Constants";
import { farmTableHead, bankTableHead } from "enums/AdminPortal/TableHeaders";
import { SearchFarm, DeleteFarm, DeleteBank } from "services/AdminPortal/FarmService";

import arrow from "assets/icons/orange/droplist-arrow.svg";
import alert from "assets/icons/orange/alert-line.svg";
import styles from "assets/jss/components/AdminPortal/farmStyle.js";

import { useRouteCanWrite } from "hooks";

const useStyles = makeStyles(styles);

export default function SearchFarmResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResultsCount = useSelector(store => store.admin.farm.searchResultsCount);
  const searchResults = useSelector(store => store.admin.farm.searchResults);
  const canWrite = useRouteCanWrite();
  const [order, setOrder] = React.useState({farm: 'asc', bank: 'asc'});
  const [orderBy, setOrderBy] = React.useState({farm: 'farmName', bank: 'bankName'});
  const [page, setPage] = React.useState({farm: 0, bank: 0});
  const [farmId, setFarmId] = React.useState(null);
  const [bankId, setBankId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState({farm: false, bank: false});

  const userDetail = JSON.parse(localStorage.getItem("userDetail"));

  const farmCount = Math.ceil((searchResultsCount.farmList) / rowsPerTable);
  const bankCount = Math.ceil(searchResultsCount.bankList / rowsPerTable);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const handleRequestSortFarm = (e, property) => {
    const isAsc = orderBy.farm === property && order.farm === 'asc';
    setOrder({...order, farm: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, farm: property});
  };
  
  const handleRequestBank = (e, property) => {
    const isAsc = orderBy.bank === property && order.bank === 'asc';
    setOrder({...order, bank: isAsc ? 'desc' : 'asc'});
    setOrderBy({...orderBy, bank: property});
  };

  const handleModal_delete = (table, id) => {
    if (table === "farm") {
      setFarmId(id);
    } else {
      setBankId(id);
    }
    setOpenModal(!openModal);
  }

  const handleButtonClick_delete = () => {
    if (farmId) {
      dispatch(DeleteFarm(farmId))
      .then(() =>{
        dispatch(SearchFarm(params.keyword));
        resetState();
      })
    } else {
      dispatch(DeleteBank(bankId))
      .then(() => {
        dispatch(SearchFarm(params.keyword));
        resetState();
      })
    }
  }

  const resetState = () => {
    setFarmId(null);
    setBankId(null);
    setPage({farm: 0, bank: 0});
    setOpenModal(!openModal);
  }

  React.useEffect(() => {
    dispatch(resetSearch());
    dispatch(SearchFarm(params.keyword));
  },[params.keyword]);

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(resetSearch());
      dispatch(setSearchText(""));
    }
  },[]);

  const highlightValue = (value) => {
    if (value === 0 || value) {
      let searchWords = [params.keyword];
      if (!isNaN(Number(params.keyword))) {
        for (let i=0; i < params.keyword.length; i++) {
          searchWords.push(params.keyword.substring(0, i)+ "," + params.keyword.substring(i, params.keyword.length));
        }
      }
      return (
        <Highlighter
          highlightClassName={classes.highlight}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={value.toString()}
        />
      )
    }
  }

  const totalCount = _.isEmpty(searchResultsCount) ? 0 : Object.values(searchResultsCount).reduce((a, b) => a + b);
  const totalTableCount = Object.keys(searchResults).length;
  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(totalCount) + " Search Result(s) From " + totalTableCount + " Table:  ‘" + params.keyword + "’"}</Typography>
      { searchResultsCount.farmList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.farmList + " Result(s) From Farm List"}
              <IconButton 
                className={collapsed.farm ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, farm: !collapsed.farm})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.farm && 
            <React.Fragment>
              <div className={classes.table}>
                <Table
                  header={filterActionTableHead(farmTableHead, canWrite)}
                  order={order.farm}
                  orderBy={orderBy.farm}
                  onRequestSort={handleRequestSortFarm}
                >
                  { _.orderBy(searchResults.farmList, [orderBy.farm], [order.farm])
                  .slice(page.farm * rowsPerTable, page.farm * rowsPerTable + rowsPerTable)
                  .map((item,index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.tableName}>{highlightValue(item.farmName)}</TableCell>
                        <TableCell className={classes.tableAddress}>{highlightValue(item.address)}</TableCell>
                        <TableCell>{highlightValue(item.landSize + " Hectares")}</TableCell>
                        <TableCell>{highlightValue(item.numberOfBanks)}</TableCell>
                        <TableCell>{highlightValue(item.latitude+"°N, "+item.longitude+"°E")}</TableCell>
                        <TableCell align="right">
                          {userDetail.farms.find((userFarm) => userFarm.id === item.id) && canWrite &&
                            <React.Fragment>
                              <Link to={{pathname: "/admin/farm/"+item.id}}>
                                <CustomIconButton type="edit" />
                              </Link>
                              <CustomIconButton 
                                type="delete"
                                onClick={() => handleModal_delete("farm", item.id)}
                              />
                            </React.Fragment>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Table>
              </div>
              { farmCount > 1 &&
                <Pagination 
                  count={farmCount} 
                  page={page.farm+1}
                  onChange={(e, pageNo)=>setPage({...page, farm: pageNo-1})}
                />
              }
            </React.Fragment>
          }
        </Card>
      }
      { searchResultsCount.bankList > 0 &&
        <Card 
          classes={{
            root: classes.result,
          }}
          title = {
            <React.Fragment>
              {searchResultsCount.bankList + " Result(s) From "+farmConfig.bank+" Details"}
              <IconButton 
                className={collapsed.bank ? classes.collapsedIcon : clsx(classes.collapsedIcon, classes.rotate)}
                onClick={() => setCollapsed({...collapsed, bank: !collapsed.bank})}
              >
                <img className={classes.icon} src={arrow} alt="arrow" />
              </IconButton>
            </React.Fragment>
          }
        >
          { !collapsed.bank && 
          <React.Fragment>
            <div className={classes.table}>
              <Table
                header={filterActionTableHead(bankTableHead, canWrite)}
                order={order.bank}
                orderBy={orderBy.bank}
                onRequestSort={handleRequestBank}
              >
                { _.orderBy(searchResults.bankList, [orderBy.bank], [order.bank])
                .slice(page.bank * rowsPerTable, page.bank * rowsPerTable + rowsPerTable)
                .map((item,index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{highlightValue(item.bankName)}</TableCell>
                      <TableCell>{highlightValue(formatNumbers(item.numberOfBays))}</TableCell>
                      <TableCell>{highlightValue(formatNumbers(item.numberOfLevels))}</TableCell>
                      {!hideCages && <TableCell>{highlightValue(formatNumbers(item.numberOfCages))}</TableCell>}
                      <TableCell align="right">
                        {userDetail.farms.find((userFarm) => userFarm.id === item.farmId) && canWrite &&
                          <React.Fragment>
                            <Link to={{pathname: "/admin/farm/"+item.farmId, state: {step: 1, prevPath: location.pathname+location.search}}}>
                              <CustomIconButton type="edit" />
                            </Link>
                            <CustomIconButton 
                              type="delete" 
                              onClick={() => handleModal_delete("bank", item.id)}
                            />
                          </React.Fragment>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </div>
            { bankCount > 1 &&
              <Pagination 
                count={bankCount} 
                page={page.bank+1}
                onChange={(e, pageNo)=>setPage({...page, bank: pageNo-1})}
              />
            }
          </React.Fragment>
          }
        </Card>
      }
      <Modal
        open={openModal}
        onClose={() => handleModal_delete(null)}
        icon={<img className={classes.icon_64} src={alert} alt="alert" />}
        title="Are you sure?"
        content={"Do you really want to delete this " + (farmId ? "Farm" : farmConfig.bank) + "? This process cannot be undone."}
        actions={
          <React.Fragment>
            <Button className={classes.buttonSecondary} onClick={() => handleModal_delete(null)}>Cancel</Button>
            <Button className={classes.button} onClick={() => handleButtonClick_delete()}>Delete</Button>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
