import {
  icon_24,
  border,
  cardRadius,
  primaryColor,
  secondaryColor,
  grayColor
} from "assets/jss/generaliseStyle.js";

const chatbotStyle = {
  icon_24,
  chatbotModal: {
    border,
    ...cardRadius,
    position: "absolute",
    width: "23.438vw",
    height: "26.042vw",
    backgroundColor: "white",
    zIndex: 99,
    right: 20,
    bottom: 20,
    boxShadow: "0 0 2px 0 #AEC1AE",
  },
  modalIcon: {
    float: "right",
    margin: "0.313vw"
  },
  modalHeader: {
    backgroundColor: "#899995",
    width: "100%",
    height: "2.292vw",
    marginTop: "3.125vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalImg: {
    margin: "0.313vw",
    height: "3.646vw",
  },
  modalTitle: {
    color: "white",
    alignItems: "center"
  },
  modalScroll: {
    overflow: "overlay",
    height: "17.188vw",
    display: "flex",
    flexDirection: "column-reverse"
  },
  modalContent: {
    width: "22.917vw",
    padding: "0.938vw",
    display: "flex",
    flexDirection: "column"
  },
  aiChat: {
    ...cardRadius,
    width: "max-content",
    maxWidth: "80%",
    backgroundColor: primaryColor[1],
    padding: "0.521vw",
    margin: "0.521vw",
  },
  aiChatTyping: {
    ...cardRadius,
    width: "max-content",
    maxWidth: "80%",
    backgroundColor: primaryColor[1],
    padding: "0.521vw",
    margin: "0.521vw",
    color: grayColor[3]
  },
  userChat: {
    border,
    ...cardRadius,
    width: "max-content",
    maxWidth: "80%",
    padding: "0.521vw",
    margin: "0.521vw",
    alignSelf: "flex-end",
  },
  textField: {
    margin: "0 0.938vw",
    "& div": {
      paddingTop: "0.521vw !important",
      paddingBottom: "0.521vw !important",
    }
  },
  textFieldIcon: {
    ...icon_24,
    color: secondaryColor[0]
  }
}

export default chatbotStyle;