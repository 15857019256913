import React from "react";
import clsx from "clsx";
// @mui/material components
import { makeStyles } from "@mui/styles"
import Popover from "@mui/material/Popover";
// core components
import styles from "assets/jss/shared-components/popperStyle.js";


const useStyles = makeStyles(styles);
export default function CustomPopover({children,  ...props}) {
  const classes = useStyles();

  return (
    <Popover
      {...props}
      classes={{
        paper: clsx(props.classes.paper, classes.paper)
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{onMouseLeave: props.onClose}}
    >
      {children}
    </Popover>
  );
}