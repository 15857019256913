import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// core components
import Card from "shared-components/Card/Card";
import Tabs from "shared-components/Tabs/Tabs";
import Select from "shared-components/Select/Select";
import Modal from "shared-components/Modal/Modal";

import { setDirty } from "store/general";
import { farmConfig } from "enums/Constants";
import { setAddNewButton } from "shared-components/Navbars";
import { updateRequest, reset } from "../store/settings";
import { GetDeviceLookUp, GetMonitoringProductionSettingById, GetMonitoringStorageSettingById, CreateOrUpdateMonitoringProductionSetting, CreateOrUpdateMonitoringStorageSetting } from "services/UserPortal/MonitoringService";

import confirm from "assets/icons/orange/confirm.svg";
import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);
import { useRouteCanWrite } from "hooks";

export default function AddEditSettings(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const canWrite = useRouteCanWrite();
  const isFirstUpdate = React.useRef(true);
  const isDisabled = useSelector(store => store.navbar.isDisabled);
  const isDirty = useSelector(store => store.general.isDirty);
  const bankList = useSelector(store => store.common.bankList);
  const warehouseList = useSelector(store => store.common.warehouseList);
  const deviceList = useSelector(store => store.user.monitoring.settings.deviceList);
  const setting = useSelector(store => store.user.monitoring.settings.setting);
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "settings");

  const handleOnChange_select = (e) => {
    dispatch(setDirty(true));
    dispatch(updateRequest({[e.target.name]: e.target.value}));
  };

  const handleButtonClick_save = () => {
    if (validateFields()) {
      switch (tab) {
        case 0: 
          dispatch(CreateOrUpdateMonitoringProductionSetting())
          .then(({error})=> {
            if (!error) {
              dispatch(setDirty(false));
              setOpenModal(true);
            }
          })
          break;
        case 1: 
          dispatch(CreateOrUpdateMonitoringStorageSetting())
          .then(({error})=> {
            if (!error) {
              dispatch(setDirty(false));
              setOpenModal(true);
            }
          })
          break;
      }
    }
  }

  const handleButtonClick_cancel = () => {
    history.push({pathname: "/user/monitoring/settings", state: {tab: tab}});
  }

  const handleModal = () => {
    setOpenModal(!openModal);
  }
  
  const validateFields = () => {
    if (tab === 0 && !setting.bankId) {
      setErrorMsg({field: "bankId", msg: "Please select a "+farmConfig.bank});
      return false;
    }
    if (tab === 1 && !setting.wareHouseId) {
      setErrorMsg({field: "wareHouseId", msg: "Please select a warehouse"});
      return false;
    }
    setErrorMsg(false);
    return true;
  }

  // componentDidMount
  React.useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    }
  },[isDisabled]);

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    dispatch(GetDeviceLookUp());
    if (path[index+1] !== 'create') {
      switch (tab) {
        case 0: 
          dispatch(GetMonitoringProductionSettingById(path[index+1]))
          .then((response) => {
            if (response.error) {
              history.push({pathname: "/user/monitoring/settings", state: {tab: tab}});
            } else {
              dispatch(setDirty(true));
            }
          });
          break;
        case 1: 
          dispatch(GetMonitoringStorageSettingById(path[index+1]))
          .then((response) => {
            if (response.error) {
              history.push({pathname: "/user/monitoring/settings", state: {tab: tab}});
            } else {
              dispatch(setDirty(true));
            }
          });
          break;
      }
    }
    // componentDidUnmount
    return () => {
      dispatch(reset());
    }
  },[]);

  return (
    <Card 
      title="Storage"
      subheader={
        <React.Fragment>
          <Tabs 
            tabs={["Production", "Storage"]}
            value={tab} 
            onChange={(e,value) => setTab(value)}
            disabled={path[index+1] !== 'create'}
          />
        </React.Fragment>
      }
    >
      <Paper elevation={0} className={classes.settingsPaper}>
        <Grid container spacing={4}>
          <Grid item xs={4} className={classes.settingsLabel}>
            {tab === 0 ? farmConfig.bank : "Warehouse"}
          </Grid>
          <Grid item xs={8}>
            <Select
              name={tab === 0 ? "bankId" : "wareHouseId"}
              onChange={(e)=>handleOnChange_select(e)}
              placeholder={"Select a "+(tab === 0 ? farmConfig.bank : "warehouse")}
              value={tab === 0 ? setting.bankId : setting.wareHouseId}
              errorMsg={errorMsg}
            >
              {tab === 0 
              ? bankList && bankList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.bankName}</MenuItem>;
                })
              : warehouseList && warehouseList.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseId})</MenuItem>;
                })
              } 
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.settingsLabel}>
            Humidity:
          </Grid>
          <Grid item xs={8}>
            <Select
              name="humiditySensorId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a humidity sensor device"
              value={setting.humiditySensorId}
              errorMsg={errorMsg}
            >
              {deviceList && deviceList.map((item, index) => {
                return <MenuItem key={index} value={item.deviceId}>{item.deviceRefNo}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.settingsLabel}>
            Temperature:
          </Grid>
          <Grid item xs={8}>
            <Select
              name="temperatureSensorId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a temperature sensor device"
              value={setting.temperatureSensorId}
              errorMsg={errorMsg}
            >
              {deviceList && deviceList.map((item, index) => {
                return <MenuItem key={index} value={item.deviceId}>{item.deviceRefNo}</MenuItem>;
              })} 
            </Select>
          </Grid>
          { tab === 0 &&
            <React.Fragment>
              <Grid item xs={4} className={classes.settingsLabel}>
                CO2:
              </Grid>
              <Grid item xs={8}>
                <Select
                  name="cO2SensorId"
                  onChange={(e)=>handleOnChange_select(e)}
                  placeholder="Select a CO2 sensor device"
                  value={setting.cO2SensorId}
                  errorMsg={errorMsg}
                >
                  {deviceList && deviceList.map((item, index) => {
                    return <MenuItem key={index} value={item.deviceId}>{item.deviceRefNo}</MenuItem>;
                  })} 
                </Select>
              </Grid>
            </React.Fragment>
          }
          <Grid item xs={4} className={classes.settingsLabel}>
            LED Control:
          </Grid>
          <Grid item xs={8}>
            <Select
              name="ledControlId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a LED control device"
              value={setting.ledControlId}
              errorMsg={errorMsg}
            >
              {deviceList && deviceList.map((item, index) => {
                return <MenuItem key={index} value={item.deviceId}>{item.deviceRefNo}</MenuItem>;
              })} 
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.settingsLabel}>
            Ventilation Fan:
          </Grid>
          <Grid item xs={8}>
            <Select
              name="ventilationFanId"
              onChange={(e)=>handleOnChange_select(e)}
              placeholder="Select a ventilation fan device"
              value={setting.ventilationFanId}
              errorMsg={errorMsg}
            >
              {deviceList && deviceList.map((item, index) => {
                return <MenuItem key={index} value={item.deviceId}>{item.deviceRefNo}</MenuItem>;
              })} 
            </Select>
          </Grid>
          {canWrite &&
            <Grid item xs={12} className={classes.settingsButton}>
              <Button className={classes.buttonSecondary} onClick={() => handleButtonClick_cancel()} disabled={!isDirty}>Cancel</Button>
              <Button 
                className={classes.button}
                onClick={()=>handleButtonClick_save()}
                disabled={!isDirty}
              >
                Save
              </Button>
            </Grid>
          }
        </Grid>
      </Paper>
      <Modal
        open={openModal}
        onClose={() => handleModal()}
        icon={<img className={classes.icon_64} src={confirm} alt="confirm" />}
        title="Success"
        content="Your selection has been saved."
        actions={
          <React.Fragment>
            <Button className={classes.button} onClick={() => handleModal()}>Okay</Button>
          </React.Fragment>
        }
      />
    </Card>
  );
}
