import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
  whiteColor,
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  table,
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
  cardPaper,
  cardTitle,
} from "assets/jss/components/commonStyle.js";

const equipmentStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  orange,
  icon,
  cardPaper,
  cardTitle,

  // Search Equipment
  result,
  
  collapsedIcon,
  rotate,
  highlight,

  // Display Equipment
  table,
  numberCell: {
    width: "8vw",
    maxWidth: "8vw"
  },
  iconButton: {
    marginLeft: "1.458vw"
  },
  footerText: {
    margin: "2.344vw 0",
    fontSize: "1.094vw"
  },
  footerTextContent: {
    fontWeight: 300
  },

  // Configure Equipment
  newPaper: {
    ...cardRadius,
    border,
    padding: "2.083vw 1.563vw"
  },
  select: {
    marginBottom: "0.938vw"
  },
  funcTable: {
    '& thead > tr > th': {
      top: "-1.302vw",
      left: "unset"
    },
    "& tbody > tr": {
      backgroundColor: whiteColor + " !important"
    }
  },
  dropdown: {
    width: "10.417vw",
    maxWidth: "10.417vw"
  },

  // Configure Machine
  paper: {
    ...cardRadius,
    border,
    padding: "3.021vw"
  },
  label: {
    margin: "2.083vw 0 0.365vw",
    "&:first-child": {
      marginTop: 0
    }
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  leftContent: {
    width: "25.104vw !important"
  },
  subContent: {
    width: "100%",
    '&:first-child': {
      marginRight: "0.625vw",
    },
    '&:last-child': {
      marginLeft: "0.625vw",
    },
    '&:not(:first-child):not(:last-child)': {
      margin: "0 0.625vw",
    },
  },
  cardContent: {
    height: "25.521vw",
    overflow: "overlay"
  },
  tableNameCell: {
    width: "11vw",
    maxWidth: "11vw",
  },
  tableCell: {
    verticalAlign: "top"
  },
  tableCode:{
    paddingTop: "1.667vw"
  },
  addButton: {
    marginTop: "1.042vw",
  },
}

export default equipmentStyle;