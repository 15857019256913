import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";

import { setAddNewButton, reset as resetNav } from "shared-components/Navbars";
import { GetQCReportGraphById, ExportQCReportGraphData } from "services/UserPortal/ReportService";

import ReportChart from "./ReportChart";
import styles from "assets/jss/components/UserPortal/reportStyle.js";

const useStyles = makeStyles(styles);
const FileDownload = require('js-file-download');

export default function ConfigurationsReport(props) {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const config = useSelector(store => store.user.report.report.graph);

  const prop = props.location.state ?? {};

  const handleOnDownload = () => {
    const path = location.pathname.split("/");
    const index = path.lastIndexOf("report");
    dispatch(ExportQCReportGraphData(path[index+1]))
    .then((response) => {
      FileDownload(response.payload.data, 'reporting.xlsx');
    });
  }

  // componentDidMount
  React.useEffect(() => {
    dispatch(setAddNewButton(true));
    const path = location.pathname.split("/");
    const index = path.lastIndexOf("report");
    dispatch(GetQCReportGraphById(path[index+1]))
    .then(({error}) => {
      if (error) {
        history.goBack();
      }
    });
    // componentDidUnmount
    return () => {
      dispatch(resetNav());
    }
  },[]);

  return (
    <React.Fragment>
      <Card 
        title = {"Report Configurations - " + (prop.title)}
        cardActions={
          <React.Fragment>
            <Button
              className={classes.buttonSecondary}
              onClick={()=>history.goBack()}
            >
              Go Back
            </Button>
          </React.Fragment>
        }
      >
        <Button
          className={clsx(classes.buttonRight, classes.buttonSecondary)}
          onClick={()=>handleOnDownload()}
        >
          Download CSV
        </Button>
        <ReportChart />
        <Grid container spacing={4} className={classes.reportField}>
          <Grid item xs={4}>
            <TextField
              id="configurationName"
              label="Configuration Name"
              variant="outlined" 
              placeholder="Type in the Configuration name"
              value={config.configurationName}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="xaxis"
              label="X Axis"
              variant="outlined" 
              placeholder="Select X Axis"
              value={config.graphDataList && config.graphDataList[0].xaxisVariableName}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="vegetableType"
              label="Vegetable Types"
              variant="outlined" 
              placeholder="Vegetable Types"
              value={config.vegetableType}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="visualisationType"
              label="Visualisation Type"
              variant="outlined" 
              placeholder="Select Visualisation Type"
              value={config.visualisationType}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="date"
              label="Date Range"
              variant="outlined" 
              placeholder="Date Range"
              value={moment(config.startDate).format('DD/MM/YYYY') + " - " + moment(config.endDate).format('DD/MM/YYYY')}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="yaxis"
              label="Y Axis"
              variant="outlined" 
              placeholder="Select Y Axis"
              value={config.graphDataList && config.graphDataList[0].yaxisVariableName}
              disabled
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
