import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import clsx from "clsx";
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Checkbox from "shared-components/Checkbox/Checkbox";
import CustomTabs from "shared-components/Tabs/Tabs";

import { setValues } from "../store/report";

import search from "assets/icons/grey/search.svg";
import styles from "assets/jss/components/UserPortal/costProductivityStyle.js";

const useStyles = makeStyles(styles);

export default function ParamList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productList = useSelector(store => store.common.productList);
  const subSaleOrderList = useSelector(store => store.user.costProductivity.report.subSaleOrderList);
  const customerList = useSelector(store => store.user.costProductivity.report.customerList);
  const [filteredList, setFilteredList] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const handleOnChange_tab = (value) => {
    props.setTab(value);
    setSearchValue("");
    setFilteredList([]);
    dispatch(setValues({reports: []}))
    if (value === 0) {
      // select first 5
      props.setSelected(subSaleOrderList.length ? _.map(subSaleOrderList.slice(0, subSaleOrderList.length > 5 ? 5 : subSaleOrderList.length), ({subSaleOrderId}) => subSaleOrderId.toString()) : []);
    } else if (value === 1) {
      // select first
      props.setSelected(productList.length ? [productList[0].id.toString()] : []);
    } else if (value === 2) {
      // select first
      props.setSelected(customerList.length ? [customerList[0].id.toString()] : []);
    }
  }

  const handleOnChange_text = (e) => {
    setSearchValue(e.target.value);
    switch (props.tab) {
      case 0: {
        let payload = _.cloneDeep(subSaleOrderList);
        setFilteredList(_.filter(payload, ({subSaleOrderNumber}) => {
          return subSaleOrderNumber.toLowerCase().includes(e.target.value.toLowerCase());
        }));
        break;
      } 
      case 1: {
        let payload = _.cloneDeep(productList);
        setFilteredList(_.filter(payload, ({productName, productRefNo}) => {
          return productName.toLowerCase().includes(e.target.value.toLowerCase()) || productRefNo.toLowerCase().includes(e.target.value.toLowerCase());
        }));
        break;
      } 
      case 2: {
        let payload = _.cloneDeep(customerList);
        setFilteredList(_.filter(payload, ({customerName, customerRefNo}) => {
          return customerName.toLowerCase().includes(e.target.value.toLowerCase()) || customerRefNo.toLowerCase().includes(e.target.value.toLowerCase());
        }));
        break;
      } 
    }
  }

  const handleOnChange_checkbox = (e) => {
    let payload = _.cloneDeep(props.selected);
    if (payload.includes(e.target.id)) {
      payload.splice(payload.indexOf(e.target.id), 1);
    } else {
      if (props.selected.length < 5) {
        payload.push(e.target.id);
      }
    }
    props.setSelected(payload);
  }

  const handleOnClick_clear = () => {
    props.setSelected([]);
  }

  const generateList = ({index, style}) => {
    switch (props.tab) {
      case 0: {
        let list = searchValue ? filteredList : subSaleOrderList;
        if (list[index]) {
          return (
            <ListItem key={index} className={classes.listItem} style={style}>
            <Checkbox 
              id={String(list[index].subSaleOrderId)}
              checked={props.selected.includes(String(list[index].subSaleOrderId))} 
              onClick={(e)=>handleOnChange_checkbox(e)} 
            />
              {list[index].subSaleOrderNumber}
            </ListItem>
          );
        }
        return null;
      } 
      case 1: {
        let list = searchValue ? filteredList : productList;
        if (list[index]) {
          return (
            <ListItem key={index} className={classes.listItem} style={style}>
            <Checkbox 
              id={String(list[index].id)}
              checked={props.selected.includes(String(list[index].id))} 
              onClick={(e)=>handleOnChange_checkbox(e)} 
            />
              {list[index].productName} ({list[index].productRefNo})
            </ListItem>
          );
        }
        return null;
      } 
      case 2: {
        let list = searchValue ? filteredList : customerList;
        if (list[index]) {
          return (
            <ListItem key={index} className={classes.listItem} style={style}>
            <Checkbox 
              id={String(list[index].id)}
              checked={props.selected.includes(String(list[index].id))} 
              onClick={(e)=>handleOnChange_checkbox(e)} 
            />
              {list[index].customerName} ({list[index].customerRefNo})
            </ListItem>
          );
        }
        return null;
      } 
    }
  }

  const allLookups = [subSaleOrderList, productList, customerList];
  return (
    <React.Fragment>
      <Card
        classes={{
          root: clsx(classes.leftCard, classes.cardPaper),
          header: classes.leftCardHeader,
          content: classes.leftCardContent
        }}
        subheader={
          <React.Fragment>
            <CustomTabs 
              variant="scrollable"
              tabs={["SO (s)", "Product", "Customer"]}
              value={props.tab} 
              onChange={(e,value) => handleOnChange_tab(value)}
            />
          </React.Fragment>
        }
      >
        <TextField 
          id="search"
          variant="outlined" 
          placeholder="Search" 
          value={searchValue}
          InputProps={{
            startAdornment: 
              <InputAdornment position="start">
                <img src={search} alt="search" className={classes.icon_24}/>
              </InputAdornment>,
          }}
          onChange={(e) => handleOnChange_text(e)}
        />
        <Typography className={classes.link} onClick={handleOnClick_clear} disabled={!props.selected.length}>Clear Selection</Typography>
        <div className={classes.list}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                itemCount={searchValue ? filteredList.length : allLookups[props.tab].length}
                itemSize={0.02604 * window.innerWidth}
                width={width}
              >
                {generateList}
              </List>
            )}
          </AutoSizer>
        </div>
      </Card>
    </React.Fragment>
  );
}
