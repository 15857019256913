import React from "react";
// @mui/material components
import { makeStyles } from "@mui/styles"
import IconButton from "@mui/material/IconButton";
// core components
import Popper from "shared-components/Popper/Popper";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import styles from "assets/jss/components/UserPortal/customerOrderStyle.js";

import helper from "assets/icons/black/helper.svg";

const useStyles = makeStyles(styles);
export default function Helper() {
  const classes = useStyles();
  const [openPopper, setOpenPopper] = React.useState(false);

  return (
    <div className="flex items-center">
      Status
      <Popper 
        noButton
        open={openPopper}
        className={classes.tablePopper}
        closePopper={() => setOpenPopper(false)}
        // buttonClick={() => setOpenPopper(false)}
        placement="top-end"
        content={
          <div className={classes.transparentTable}>
            <Table
              header={[
                { id: 's/n', align: "left", label: 'S/N' },
                { id: 'status', align: "left", label: 'Status' },
                { id: 'meaning', align: "left", label: 'Meaning' },
              ]}
            >
              <TableRow>
                <TableCell>1.</TableCell>
                <TableCell>Received</TableCell>
                <TableCell>Order has been keyed in successfully</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2.</TableCell>
                <TableCell>Planned</TableCell>
                <TableCell>Work order has been issued but not yet started</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3.</TableCell>
                <TableCell>Pending</TableCell>
                <TableCell>Work order will be starting today</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4.</TableCell>
                <TableCell>Processing</TableCell>
                <TableCell>Work order is in progress</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>4.</TableCell>
                <TableCell>Packaged</TableCell>
                <TableCell>End of work order</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>5.</TableCell>
                <TableCell>Cancelled</TableCell>
                <TableCell>Order has been cancelled</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>6.</TableCell>
                <TableCell>Delivered</TableCell>
                <TableCell>Order has been delivered</TableCell>
              </TableRow>
            </Table>
          </div>
        }
      >
        <IconButton 
          onClick={() => setOpenPopper(true)}
        >
          <img className={classes.icon} src={helper} alt="helper" />
        </IconButton>
      </Popper>
    </div>
  );
}