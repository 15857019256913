import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import { formatNumbers } from "common/functions";
import { reset } from "../store/search";
import { setSearchText } from "store/general";

import styles from "assets/jss/components/UserPortal/finishedGoodsStyle.js";

import SearchInResult from "./SearchInResult";
import SearchOutResult from "./SearchOutResult";
import SearchFinishedGoodsResult from "./SearchFinishedGoodsResult";
import SearchDisposedResult from "./SearchDisposedResult";
import SearchDOResult from "./SearchDOResult";

const useStyles = makeStyles(styles);

export default function SearchResult() {
  const classes = useStyles();
  const dispatch = useDispatch();
  //count
  const fgTotalCount = useSelector(store => store.user.finishedGoods.search.fgTotalCount);
  const inTotalCount = useSelector(store => store.user.finishedGoods.search.inTotalCount);
  const outTotalCount = useSelector(store => store.user.finishedGoods.search.outTotalCount);
  const disposalTotalCount = useSelector(store => store.user.finishedGoods.search.disposalTotalCount);
  const doTotalCount = useSelector(store => store.user.finishedGoods.search.doTotalCount);

  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // componentDidMount
  React.useEffect(() => {
    // componentDidUnmount
    return () => {
      dispatch(reset());
      dispatch(setSearchText(""));
    }
  },[]);

  return (
    <React.Fragment>
      <Typography className={classes.result}>{formatNumbers(fgTotalCount + inTotalCount + outTotalCount + disposalTotalCount + doTotalCount) + " Search Result(s) From 5 Tables:  ‘" + params.keyword + "’"}</Typography>
      <SearchInResult />
      <SearchOutResult />
      <SearchFinishedGoodsResult />
      <SearchDisposedResult />
      <SearchDOResult />
    </React.Fragment>
  );
}
