// Device
// Hierarchy Details
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tree, TreeNode } from 'react-organizational-chart';
import _ from "lodash";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";

import { GetDeviceHierarchy } from "services/AdminPortal/DeviceService";

import styles from "assets/jss/components/AdminPortal/deviceStyle.js";
import clsx from "clsx";

const useStyles = makeStyles(styles);
export default function Hierarchy(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceHierarchy = useSelector(store => store.admin.device.device.deviceHierarchy);
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(props.deviceId);

  const handleOnChange_select = (e) => {
    setSelectedDeviceId(e.target.value);
  };
  
  React.useEffect(() => {
    dispatch(GetDeviceHierarchy(selectedDeviceId));
  },[selectedDeviceId]);

  const node = (hierarchy, value) => {
    return (
      <div className={hierarchy === "parent" ? clsx(classes.parentNode, classes.node) : hierarchy === "child" ? clsx(classes.childNode, classes.node) : classes.node}>
        {value}
      </div>
    )
  }

  const deviceRefNo = _.find(props.devices, ["id", selectedDeviceId]).deviceRefNo;
  return (
    <React.Fragment>
      <Card 
        className={classes.cardRadius}
        title = {"Mapping Hierarchy of " + deviceRefNo}
        cardActions={
          <Button
            className={classes.buttonSecondary}
            onClick={()=>props.setHierarchyDetail(false)}
          >
            Go Back
          </Button>
        }
        action={ 
          <div className={classes.headerAction}>
            <Typography className={classes.headerLabel}>Device ID:</Typography>
            <Select
              className={classes.headerSelect}
              name="id"
              onChange={(e)=>handleOnChange_select(e)}
              value={selectedDeviceId}
            >
              { _.orderBy(props.devices, ["deviceRefNo"], ["asc"]).map((item) => {
                return <MenuItem key={item.id} value={item.id}>{item.deviceRefNo}</MenuItem>;
              })} 
            </Select>
          </div>
        }
      >
        <div className={classes.legendContainer}>
          <div className={classes.legend}>
            <Typography>Legend</Typography>
            <div className={classes.legendDesc}><div className={classes.legendDot} /><Typography>Current Device</Typography></div>
            <div className={classes.legendDesc}><div className={clsx(classes.parentDot, classes.legendDot)} /><Typography>Parent Device</Typography></div>
            <div className={classes.legendDesc}><div className={clsx(classes.childDot, classes.legendDot)} /><Typography>Child Device</Typography></div>
          </div>
        </div>
        <div className={classes.hierarchy}>
          <Tree
            lineWidth={'0.104vw'}
            lineColor={'black'}
            lineBorderRadius={0}
            label={
              deviceHierarchy.parent
              ? node("parent", deviceHierarchy.parent.deviceRefNo)
              : node("root", deviceRefNo)
            }
          >
            { deviceHierarchy.parent
            ? <TreeNode 
                label={node("root", deviceRefNo)}
              >
                {deviceHierarchy.childs && deviceHierarchy.childs.map((item, index) => {
                  return (
                    <TreeNode key={index} label={node("child", item.deviceRefNo)} />
                  )
                })}
              </TreeNode>
            : deviceHierarchy.childs && deviceHierarchy.childs.map((item, index) => {
                return (
                  <TreeNode key={index} label={node("child", item.deviceRefNo)} />
                )
              })
            }
          </Tree>
        </div>
      </Card>
    </React.Fragment>
  );
}
