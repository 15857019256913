import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
// core components
import Select from "shared-components/Select/Select";
import Bank from "shared-components/Bank/Bank";

import { farmConfig } from "enums/Constants";
import { GetEquipmentByFloorandSectorIdOrBankId, GetEquipmentLocationBankView } from "services/UserPortal/MonitoringService";

import styles from "assets/jss/components/UserPortal/monitoringStyle.js";

const useStyles = makeStyles(styles);

export default function LocationView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bankList = useSelector(store => store.common.bankList);
  const equipmentList = useSelector(store => store.user.monitoring.equipment.equipment);
  const equipLocation = useSelector(store => store.user.monitoring.equipment.equipLocation);
  const [filter, setFilter] = React.useState({bankId: null, equipmentId: null});
  const [view, setView] = React.useState(false);
  const [selectedBank, setSelectedBank] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(false);
  
  const path = location.pathname.split("/");
  const index = path.findIndex((item) => item === "equipment");

  const handleOnChange_select = (e) => {
    setFilter({...filter, [e.target.name]: e.target.value});
  };

  const handleButtonClick_view = () => {
    if (validateFields()) {
      const param = {
        bankId: filter.bankId,
        equipmentId: filter.equipmentId === "all" ? null : filter.equipmentId,
        equipmentType: path[index+1].replace("%20", " ")
      }
      dispatch(GetEquipmentLocationBankView(param));
      setView(true);
    }
  };

  const validateFields = () => {
    if (!filter.bankId) {
      setErrorMsg({field: "bankId", msg: "Please select a "+farmConfig.bank});
      return false;
    }
    setErrorMsg(false);
    return true;
  };

  React.useEffect(() => {
    if (filter.bankId) {
      setSelectedBank(bankList.find(({id}) => id === filter.bankId));
      const param = {
        bankId: filter.bankId,
        equipmentType: path[index+1].replace("%20", " ")
      }
      dispatch(GetEquipmentByFloorandSectorIdOrBankId(param));
      setFilter({...filter, equipmentId: "all"});
      setView(false);
    }
  },[location.pathname, filter.bankId]);

  return (
    <React.Fragment>
      <div className={classes.cardContainer}>
        <Typography className={classes.label}>{farmConfig.bank}: </Typography>
        <Select
          name="bankId"
          placeholder={"Select a "+farmConfig.bank}
          onChange={(e)=>handleOnChange_select(e)}
          value={filter.bankId}
          errorMsg={errorMsg}
        >
          {bankList && bankList.map((item, index) => {
            return <MenuItem key={index} value={item.id}>{item.bankName}</MenuItem>;
          })} 
        </Select>
        <Typography className={classes.label}>Equipment ID: </Typography>
        <Select
          name="equipmentId"
          placeholder="Select an equipment"
          onChange={(e)=>handleOnChange_select(e)}
          value={filter.equipmentId}
          disabled={!filter.bankId}
        >
          <MenuItem key={index} value={"all"}>All Equipments</MenuItem>
          {equipmentList && equipmentList.map((item, index) => {
            return <MenuItem key={index} value={item.id}>{item.equipmentName + " (" + item.equipmentRefNo + ")"}</MenuItem>;
          })} 
        </Select>
        <Button 
          className={clsx(classes.button, classes.equipView)}
          onClick={()=>handleButtonClick_view()}
        >
          View
        </Button>
      </div>
      {view && 
        <div className={classes.bankView}>
          <Bank
            type="alarm"
            levels={selectedBank.numberOfLevels}
            bays={selectedBank.numberOfBays}
            data={equipLocation.deviceList ? equipLocation.deviceList : []}
          />
        </div>
      }
    </React.Fragment>
  );
}
