import { createSlice } from "@reduxjs/toolkit";

import { GetWorkOrderList } from "services/UserPortal/CapacityService";
import { GetBankInfoById, GetProductListForTrays } from "services/UserPortal/PlanningService";

// initial state
const initialState = {
  cageList: [],
  trays: [],
  cage: {},
  board: {},
  //lookup
  productList: [],
  workOrderList: []
};

const capacity = createSlice({
  name: "capacity",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetBankInfoById.fulfilled]: (state, action) => {
      state.cageList = action.payload.result.cagesList;
    },
    [GetProductListForTrays.fulfilled]: (state, action) => {
      state.productList = action.payload.result;
    },
    [GetWorkOrderList.fulfilled]: (state, action) => {
      state.workOrderList = action.payload.result;
    },
  },
});

// export actions
export const { setValues, reset } = capacity.actions;

// export the reducer
export default capacity.reducer;