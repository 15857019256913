import {
  cardRadius,
  border,
  icon_24,
  icon_38,
  icon_64,
  button,
  buttonSecondary,
  backgroundColor,
  blackColor,
  whiteColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  orange,
  icon,
  cardPaper,
  cardTitle,
  table,
  whiteTable,
} from "assets/jss/components/commonStyle.js";

const deviceTaggingStyle = {
  ...cardRadius,
  button,
  buttonSecondary,
  icon_24,
  icon_64,
  orange,
  cardPaper,
  cardTitle,
  table,
  whiteTable,
  bold: {
    fontWeight: 500
  },

  displayScroll: {
    marginBottom: "-1.302vw",
    paddingBottom: "1.302vw",
    overflow: "overlay"
  },
  mainTable: {
    width: "fit-content",
    '& tbody > tr > td:first-child': {
      width: "9.375vw"
    },
  },
  tagTableCell: {
    maxWidth: "10.417vw"
  },
  location: {
    fontWeight: 500,
    color: primaryColor[3],
  },
  label: {
    fontWeight: 500,
    fontSize: "1.094vw",
    color: primaryColor[3],
  },
  thumbnail: {
    backgroundColor,
    border,
    borderRadius: "0.417vw",
    display: "flex",
    width: "7.813vw",
    height: "6.563vw",
    cursor: "pointer"
  },
  img:{
    ...cardRadius,
    objectFit: "contain"
  },
  div: {
    backgroundColor,
    border,
    borderRadius: "0.417vw",
    display: "flex",
    width: "7.813vw",
    height: "3.073vw",
    cursor: "pointer"
  },
  imgText: {
    color: "rgba(0, 0, 0, 0.4)",
  },
  divText: {
    margin: "auto",
    fontSize: "0.833vw"
  },
  // AddEditDeviceTagging
  error: {
    color: "#FF0000",
    fontSize: "0.729vw",
  },
  buttonMargin: {
    marginRight: "1.042vw"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    '&:not(:first-child)': {
      marginTop: "1.563vw",
    },
  },
  subContent: {
    width: "100%",
    '&:first-child': {
      marginRight: "0.625vw",
    },
    '&:last-child': {
      marginLeft: "0.625vw",
    },
    '&:not(:first-child):not(:last-child)': {
      margin: "0 0.625vw",
    },
  },
  paper: {
    ...cardRadius,
    border,
    margin: "auto",
    width: "50.469vw",
    padding: "2.083vw 1.979vw",
    '&:not(:first-child)': {
      marginTop: "1.563vw",
    },
  },
  textfieldCell: {
    verticalAlign: "top"
  },
  cellButton: {
    marginTop: "0.208vw",
  },
  formLabel: {
    marginBottom: "0.365vw",
    '&:not(:first-child)': {
      marginTop: "1.563vw",
    },
  },
  iconButton: {
    height: "fit-content",
    marginTop: "1.771vw",
    marginRight: "-0.625vw",
  },
  icon,
  addButton: {
    marginTop: "1.042vw",
  },
  cardRight: {
    width: "100%",
    minWidth: "59.896vw",
    maxWidth: "59.896vw",
    height: "fit-content"
  },
  cardRightContent: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    // justifyContent: "center",
    height: "22.917vw",
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    },
    overflow: "overlay",
  },
  floorCardLeft: {
    width: "31.875vw",
    marginRight: "1.563vw",
    height: "fit-content"
  },
  floorCardAction: {
    display: "flex",
    justifyContent: "space-between"
  },
  clickable: {
    color: secondaryColor[0],
    fontWeight: 700,
    fontSize: "0.938vw",
    float: "right",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: secondaryColor[1]
    }
  },
  disabled: {
    color: primaryColor[1],
    fontWeight: 700,
    fontSize: "0.938vw",
    cursor: "default",
    float: "right",
    textDecoration: "none",
  },
  buttonGrid: {
    display: "flex",
  },
  radio: {
    marginLeft: "-0.625vw",
  },
  buttonLabel: {
    alignSelf: "center",
    '&:not(:last-child)': {
      marginRight: "2.031vw"
    }
  },
  emptyLabel: {
    textAlign: "center",
    fontSize: "1.094vw",
    color: "rgba(19, 50, 43, 0.4)"
  },
  dropZone: {
    width: "100%",
    height: "22.917vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  activeDropZone: {
    backgroundColor: primaryColor[1],
  },
  zoomDiv: {
    position: "sticky",
    top: 0,
    width: "100%",
    padding: "0.885vw 0 ",
    zIndex: 1,
    textAlign: "right"
  },
  zoomButton: {
    border,
    color: secondaryColor[0],
    backgroundColor: whiteColor + " !important",
    margin: "0 1.042vw",
    "&disabled": {
      color: "#5A706B",
      backgroundColor: whiteColor + " !important",
    }
  },
  floorPlanImg: {
    height: "18.490vw",
    position: "relative",
    // width: "unset",
    objectFit: "cover",
    transition: "transform 0.25s ease",
  },
  floorPlanImg0: {
    height: "18.490vw",
  },
  floorPlanImg1: {
    height: "23.698vw",
  },
  floorPlanImg2: {
    height: "28.906vw",
  },
  floorPlanImg3: {
    height: "34.115vw",
  },
  floorPlanImg4: {
    height: "39.323vw",
  },
  statusModal: {
    width: "34.635vw",
    height: "fit-content",
    maxHeight: "35.781vw"
  },
  statusContent: {
    height: "fit-content",
    maxHeight: "16.667vw",
    overflow: "overlay"
  },
  summaryModal: {
    width: "40.104vw",
  },
  summaryContent: {
    display: "flex",
    marginTop: "1.458vw",
  },
  summaryLabel: {
    color: blackColor,
    width: "50%",
    marginRight: "1.563vw",
    fontSize: "1.302vw",
    textAlign: "right",
  },
  summaryText: {
    width: "50%",
    color: secondaryColor[0],
    fontSize: "1.302vw",
    fontWeight: 700,
    textAlign: "left",
  },
  cardLeftActions: {
    justifyContent: "flex-end"
  },
  bankLeftContent: {
    height: "fit-content",
    maxHeight: "17.188vw",
    overflow: "overlay",
    marginBottom: "1.302vw"
  }, 
  headerAction: {
    display: "flex",
    alignItems: "center",
    '& *:not(:first-child)': {
      marginLeft: "1.563vw"
    }
  },
  headerSelect: {
    width: "11.771vw"
  },
  tagContent: {
    padding: "0 1.563vw",
    position: "relative",
    height: "fit-content"
  },
  listIconButton: {
    border,
    position: "absolute",
    right: "-2.292vw",
    top: "-0.052vw",
    borderRadius: 0,
    borderLeft: 0,
    backgroundColor: "white",
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
    "&:hover": {
      backgroundColor: backgroundColor + " !important"
    }
  },
  listIconButton2: {
    border,
    position: "absolute",
    left: "-2.292vw",
    top: 0,
    borderRadius: 0,
    borderLeft: 0,
    paddingLeft: "0.313vw !important",
    borderBottomRightRadius: "0.833vw",
    borderTopRightRadius: "0.833vw",
  },
  searchText: {
    marginTop: "1.146vw",
  },
  tagLeftContent: {
    height: "fit-content",
    minHeight: "20vw",
    maxHeight: "20vw",
    overflow: "overlay",
    paddingTop: "0.750vw",
    marginBottom: "0.750vw"
  }, 
  tagMainContent: {
    height: "fit-content",
    minHeight: "25vw",
    maxHeight: "25vw",
    overflow: "hidden",
    marginBottom: "0.750vw"
  }, 
  tagHeader: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.250vw",
  },
  saveButtonMargin: {
    ...button,
    marginLeft: "1.042vw"
  },
  bankView: {
    overflow: "overlay",
    width: "-webkit-fill-available",
    height: "25vw",
    paddingRight: "0.833vw"
  },
  addTagButton: {
    color: secondaryColor[0],
  },
  tagClickable: {
    color: secondaryColor[0],
    fontWeight: 700,
    fontSize: "0.938vw",
    float: "left",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: secondaryColor[1]
    }
  },
  markerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "translate(-40%, -20%)"
  },
  marker: {
    ...icon_38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: whiteColor,
    border: "0.208vw solid " + secondaryColor[0],
    borderRadius: "50%",
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
  },
  markerText: {
    color: primaryColor[3],
    fontSize: "0.938vw",
    fontWeight: 500,
  },
}

export default deviceTaggingStyle;