import React from "react";
import { useSelector } from "react-redux";
// @mui/material
import { makeStyles } from "@mui/styles"

import { location } from "config";
import truck from "assets/img/truck.svg";
import styles from "assets/jss/components/UserPortal/deliveryStyle.js";

const useStyles = makeStyles(styles);

const Maps = (props) => {
  const classes = useStyles();
  const mapRef = React.useRef(null);
  const startPoint = useSelector(store => store.user.delivery.assignment.startPoint);
  const [map, setMap] = React.useState();
  const [markers, setMarkers] = React.useState([]);

  React.useEffect(() => {
    return () => {
      setMap();
    }
  }, [])

  React.useEffect(() => {
    if (mapRef.current) {
      let google = window.google;
      let lat = location.latitude;
      let lng = location.longitude;
      const defaultLatLng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
          zoom: 11,
          center: defaultLatLng,
          zoomControl: true,
        };
      setMap(new window.google.maps.Map(mapRef.current, mapOptions));
    }
  }, [mapRef, startPoint])

  React.useEffect(() => {
    let google = window.google;
    const trafficLayer = new google.maps.TrafficLayer();
  
    trafficLayer.setMap(map);
  }, [map]);

  React.useEffect(() => {
    if (map && props.progress.length) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      let google = window.google;
      let markerList = [];
      const infoWindow = new google.maps.InfoWindow();
      for (let i = 0; i < props.progress.length; i++) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(props.progress[i].lastKnownLocationLatitude, props.progress[i].lastKnownLocationLongitude),
          title: props.progress[i].driverName,
          icon: truck,
        });
        google.maps.event.addListener(marker, 'mouseover', ((marker, i) => {
          return () => {
            infoWindow.setContent("<div class="+classes.infoWindow+">"+props.progress[i].driverName+"<br />"+props.progress[i].deliveryTripRefNo+"</div>");
            infoWindow.open(map, marker);
          }
        })(marker, i));
        google.maps.event.addListener(marker, 'mouseout', (() => {
          return () => {
            infoWindow.close()
          };
        })(marker, i));
        markerList.push(marker);
      }
      setMarkers(markerList)
    }
  }, [map, props.progress]);

  React.useEffect(() => {
    if (map) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    }
  }, [map, markers]);

  return (
    <div className={classes.map} ref={mapRef}></div>
  );
};

export default Maps;
