import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
// core components
import Card from "shared-components/Card/Card";
import Select from "shared-components/Select/Select";
import FilterButton from "shared-components/Button/FilterButton";
import Tag from 'shared-components/Chip/Tag';

import { useInterval } from 'common/utils';
import { setSearchText } from "store/general";
import { renderValue, filterParam } from "common/functions";
import { setValues } from "../store/rackUsage";
import { GetAllWarehouse, GetWarehouseRackUsage } from "services/UserPortal/WarehouseService";

import styles from "assets/jss/components/UserPortal/warehouseStyle.js";

const useStyles = makeStyles(styles);

export default function RackUsage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filter = useSelector(store => store.user.warehouse.rackUsage.filter);
  const warehouseList = useSelector(store => store.user.warehouse.master.warehouse);
  const rackList = useSelector(store => store.user.warehouse.rackUsage.rackList);
  const [newFilter, setNewFilter] = React.useState({warehouseId: null, laneNumber: null});
  const [laneList, setLaneList] = React.useState([]);
  
  const handleOnChange_select = (e) => {
    setNewFilter({...newFilter, [e.target.name]: e.target.value});
    if (e.target.name === "warehouseId") {
      const warehouse = warehouseList.find(({id}) => id === e.target.value);
      setLaneList(Array.from({length:warehouse.noOfLane},(v,k)=>k+1));
    }
  };

  const handleButtonClick_delete = (key) => {
    dispatch(setValues({filter: {...filter, [key]: null}}));
    dispatch(setValues({rackList: []}));
  };

  const getRackList = (stopLoading) => {
    if (filter.warehouseId && filter.laneNumber) {
      dispatch(GetWarehouseRackUsage({...filter, stopLoading}));
    }
  }
  
  React.useEffect(() => {
    const search = filterParam(newFilter);
    if (!_.isEmpty(search)) { 
      dispatch(setValues({filter: {...search}}));
    } else {
      if (warehouseList.length) {
        const warehouse = warehouseList.find(({id}) => id === warehouseList[0].id);
        setLaneList(Array.from({length:warehouse.noOfLane},(v,k)=>k+1));
        dispatch(setValues({filter: {warehouseId: warehouseList[0].id, laneNumber: 1}}));
      } else {
        dispatch(setValues({filter: {warehouseId: null, laneNumber: null}}));
      }
    }
  },[warehouseList]);

  React.useEffect(() => {
    if ((location.search && !_.isEmpty(_.omitBy(filter, _.isNil)))) {
      getRackList();
    }
  },[filter]);

  React.useEffect(() => {
    dispatch(setSearchText(""));
    dispatch(GetAllWarehouse({page: 0, maxResultCount: 99, stopLoading: true}));
  },[]);

  useInterval(() => {
    getRackList(true);
  });

  const result = _.groupBy(rackList, 'rowNumber');
  return (
    <React.Fragment>
      <Card 
        title="Rack Usage"
        subheader={
          <React.Fragment>
            <Typography>Choose a warehouse and lane number to view rack usage status</Typography>
            { Object.keys(filter).map((key) => {
              if (filter[key] && key !== "page") {
                let label = filter[key];
                if (key === "warehouseId") {
                  const warehouse = warehouseList.find(({id}) => id == filter[key]);
                  label = warehouse && (warehouse.warehouseName+" ("+warehouse.warehouseID+")");
                }
                if (key === "laneNumber") {
                  label = "Lane Number "+filter[key];
                }
                return (
                  <Tag
                    key={key}
                    variant="outlined"
                    tabIndex={-1}
                    label={label}
                    className={classes.tag}
                    onDelete={() => handleButtonClick_delete(key)}
                  />
                )
              }
            })}
          </React.Fragment>
        }
        action={
          <React.Fragment>
            <FilterButton 
              setFilter={()=>dispatch(setValues({filter: {...newFilter, page: 0}}))}
              filter={filter}
              setNewFilter={setNewFilter}
              content={
                <React.Fragment>
                  Choose filters to view rack usage status
                  <Select
                    className={classes.filterDropdown}
                    name="warehouseId"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a warehouse"
                    value={renderValue(newFilter.warehouseId)}
                  >
                    {warehouseList && warehouseList.map((item, index) => {
                      return <MenuItem key={index} value={item.id}>{item.warehouseName} ({item.warehouseID})</MenuItem>;
                    })} 
                  </Select>
                  <Select
                    className={classes.filterDropdown}
                    name="laneNumber"
                    onChange={(e)=>handleOnChange_select(e)}
                    placeholder="Select a lane number"
                    value={renderValue(newFilter.laneNumber)}
                    disabled={!newFilter.warehouseId}
                  >
                    {laneList && laneList.map((item, index) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>;
                    })} 
                  </Select>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      >
        <div className={classes.rackTableDiv}>
          <Table className={classes.rackTable}>
            <TableBody>
              {Object.keys(result).map(key => {
                return (
                  <TableRow key={key}>
                    {result[key].map((item, index) => {
                      return (
                        <TableCell key={index} className={item.hasOccupied ? clsx(classes.rackCell, classes.usedRackCell) : classes.rackCell}>{item.rackNumber}</TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <div className={classes.legend}>
          <div className={clsx(classes.colorBox, classes.legendColor)} />Rack Used ({_.filter(rackList, "hasOccupied").length}/{rackList.length})
          <div className={clsx(classes.colorBox, classes.legendColor)} />Rack Not in Use ({_.filter(rackList, ["hasOccupied", false]).length}/{rackList.length})
        </div>
      </Card>
    </React.Fragment>
  );
}
