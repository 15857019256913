import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
// @mui/material components
import { makeStyles } from "@mui/styles"
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
// core components
import Card from "shared-components/Card/Card";
import Switch from "shared-components/Switch/Switch";

import { useInterval } from 'common/utils';
import { setValues } from ".";
import { GetAllAlarmNotification, GetAlarmNotificationCount, SetAlarmNotificationReadByUser } from "services/UserPortal/AlarmService";

import styles from "assets/jss/components/UserPortal/alarmStyle.js";

const useStyles = makeStyles(styles);

export default function AllAlarms(props) {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const unread = useSelector(store => store.navbar.unread);
  const alarms = useSelector(store => store.user.alarm.allAlarms);
  const notifications = useSelector(store => store.user.alarm.notifications);
  const [isUnread, setIsUnread] = React.useState(false);
  const [tab, setTab] = React.useState(props.location.state ? props.location.state.tab : 0);
  const [page, setPage] = React.useState(0); // for list
  const [isLoadingMore, setIsLoadingMore] = React.useState(false); // for list

  const items = [
    {name: "UserPortal.Operation", url: "/user/alarm/details/operations"},
    {name: "UserPortal.FinishedGoods", url: "/user/alarm/details/finished-goods"},
    {name: "UserPortal.Planning", url: "/user/alarm/details/planning"},
    {name: "UserPortal.Equipment", url: "/user/alarm/details/equipment"},
    {name: "UserPortal.RawMaterial", url: "/user/alarm/details/raw-material"},
    {name: "UserPortal.Delivery", url: "/user/alarm/details/delivery"},
    {name: "UserPortal.ClimateEnvironment", url: "/user/alarm/details/climate"},
  ]

  const handleOnClick_menu = (moduleName, item) => {
    const module = items.find(({name}) => name === moduleName);
    if (module) {
      dispatch(SetAlarmNotificationReadByUser(item.id));
      dispatch(GetAlarmNotificationCount());
      if (module.name === "UserPortal.Operation" && item.operation.productId) {
        if (tab === 0) {
          history.push({pathname: "/user/operations/overall-tasks/"+item.operation.productId, state: _.pick(item.operation, ["workOrderId", "productId", item.operation.isMixProduct && "operationId"])});
        } else {
          history.push({pathname: "/user/operations/task-assignment/"+item.operation.productId, state: _.pick(item.operation, ["workOrderId", "productId", item.operation.isMixProduct && "operationId"])});
        }
      } else {
        history.push({pathname: module.url, state: {tab: tab===0 ? 0 : 2}});
      }
    }
  }

  const fetchMoreData = (pageNo) => {
    if ((page !== -1 || pageNo !== undefined)) {
      setIsLoadingMore(true);
      const param = {
        page: pageNo !== undefined ? pageNo : page,
        IsNotification: tab===1,
        IsShowOnlyForUnread: isUnread,
        stopLoading: true, 
        maxResultCount: 10
      }
      dispatch(GetAllAlarmNotification(param))  // pass in page
      .then((response) => {
        if (!response.error) {
          setIsLoadingMore(false);
          if (response.payload.result.items.length) {
            setPage(pageNo !== undefined ? pageNo+1 : page+1);
            dispatch(setValues({[tab===0?"allAlarms":"notifications"]: _.unionBy(tab===0?alarms:notifications, response.payload.result.items, "id")}));
          } else {
            setPage(-1); // if no more result, set to -1
          }
        }
      });
    }
  }

  // componentDidMount
  React.useEffect(() => {
    setPage(0);
    Promise.all([
      dispatch(GetAllAlarmNotification({IsNotification: false, maxResultCount: 10, IsShowOnlyForUnread: isUnread, page: 0, stopLoading: true})),
      dispatch(GetAllAlarmNotification({IsNotification: true, maxResultCount: 10, IsShowOnlyForUnread: isUnread, page: 0, stopLoading: true})),
    ]).then((response) => {
      let payload = {}; 
      for (let i = 0; i < response.length; i++) {
        if (response[i].payload && response[i].payload.result) {
          payload = {
            ...payload,
            [i===0?"allAlarms":"notifications"]: response[i].payload.result.items,
          };
        }
      }
      dispatch(setValues(payload));
    })
  },[isUnread]);

  useInterval(() => {
    Promise.all([
      dispatch(GetAllAlarmNotification({IsNotification: false, maxResultCount: 10, IsShowOnlyForUnread: isUnread, page: 0, stopLoading: true})),
      dispatch(GetAllAlarmNotification({IsNotification: true, maxResultCount: 10, IsShowOnlyForUnread: isUnread, page: 0, stopLoading: true})),
    ]).then((response) => {
      let payload = {}; 
      for (let i = 0; i < response.length; i++) {
        if (response[i].payload && response[i].payload.result) {
          payload = {
            ...payload,
            [i===0?"allAlarms":"notifications"]: _.orderBy(_.unionBy(i===0?alarms:notifications, response[i].payload.result.items, "id"),["creationTime"],["desc"])
          };
        }
      }
      dispatch(setValues(payload));
    })
  }, 1000*10);

  return (
    <Card 
      className={classes.container}
      classes={{
        root: classes.container,
        content: classes.content,
      }}
      title="Alarms & Notifications"
      subheader={
        <Tabs 
          className={classes.tab}
          classes={{
            indicator: classes.indicator
          }}
          value={tab} 
          onChange={(e, value) => setTab(value)}
        >
          <Tab 
            className={tab === 0 ? clsx(classes.tabLabel, classes.orange) : classes.tabLabel} 
            label={<React.Fragment>Alarms<span className={tab === 0 ? clsx(classes.notificationChip, classes.orangeBackground) : classes.notificationChip}>{unread&&unread.alarmCount}</span></React.Fragment>}
          />
          <Tab 
            className={tab === 1 ? clsx(classes.tabLabel, classes.orange) : classes.tabLabel}
            label={<React.Fragment>Notifications<span className={tab === 1 ? clsx(classes.notificationChip, classes.orangeBackground) : classes.notificationChip}>{unread&&unread.notificationCount}</span></React.Fragment>} 
          />
        </Tabs>
      }
      action={
        <div className={classes.notificationAction}>
          <Typography>Show Unread</Typography>
          <Switch 
            checked={isUnread}
            onChange={() => setIsUnread(!isUnread)}
          />
        </div>
      }
      cardActions={
        <Button
          className={classes.buttonSecondary}
          onClick={()=>history.push("/user/alarm/details")}
        >
          Go Back
        </Button>
      }
    >
      <MenuList role="menu" className={classes.notificationMenu} id="scrollableDiv">
        <InfiniteScroll
          dataLength={page*5}
          next={()=>fetchMoreData()}
          hasMore={true}
          loader={ isLoadingMore && 
            <div className={"w-full text-center"}>
              <CircularProgress className={classes.loading} size={"1.250vw"} />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {(tab===0?alarms:notifications).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <MenuItem 
                  className={item.isReadByUser ? classes.notificationItem : clsx(classes.notificationItem, classes.unread)}
                  onClick={() => handleOnClick_menu(item.moduleName, item)}
                >
                  <Typography className={item.isReadByUser ? classes.notificationMsg : clsx(classes.notificationMsg, classes.unreadMsg)}>{item.message}</Typography>
                  <Typography className={item.isReadByUser ? classes.notificationTime : clsx(classes.notificationTime, classes.unreadTime)}>{moment(item.creationTime).fromNow()}</Typography>
                </MenuItem>
                <Divider />
              </React.Fragment>
            )
          })}
        </InfiniteScroll>
      </MenuList>
    </Card>
  );
}
