import {
  cardRadius,
  border,
  icon_64,
  button,
  buttonSecondary,
  backgroundColor,
  primaryColor,
  secondaryColor
} from "assets/jss/generaliseStyle.js";

import {
  icon,
  result,
  collapsedIcon,
  rotate,
  highlight,
} from "assets/jss/components/commonStyle.js";

const userStyle = {
  cardRadius,
  button,
  buttonSecondary,
  icon_64,
  icon,

  // Search User
  result,
  collapsedIcon,
  rotate,
  highlight,

  // Display user
  table: {
    margin: "0 -1.979vw",
    // overflow: "overlay"
  },
  tableAction: {
    minWidth: "10.625vw"
  },
  ellipsis: {
    width: "6.250vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  emailEllipsis: {
    width: "11.458vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  popper: {
    width: "23.125vw"
  },

  // Add User
  createHeaderButton: {
    marginLeft: "9.375vw",
    marginBottom: "-1.563vw",
    "& button:not(:last-child)": {
      marginRight: "1.563vw"
    }
  },
  cardContent: {
    marginBottom: 0
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  leftContent: {
    ...cardRadius,
    backgroundColor,
    width: "52.188vw",
    marginRight: "0.781vw",
    padding: "2.083vw"
  },
  rightContent: {
    ...cardRadius,
    width: "25.313vw",
    marginLeft: "0.781vw",
  },
  rightPaper: {
    ...cardRadius,
    backgroundColor,
    width: "25.313vw",
    height: "34.896vw",
    padding: "1.563vw",
    paddingBottom: 0,
  },
  orange: {
    color: secondaryColor[0]
  },
  emptyList: {
    display: "flex",
    height: "inherit",
    alignItems: "center",
    justifyContent: "center"
  },
  empty: {
    fontSize: "1.354vw",
    fontWeight: 500,
    color: "rgba(19, 50, 43, 0.25)",
  },
  userScroll: {
    marginTop: "1.302vw",
    marginRight: "-1.563vw",
    height: "90%",
    overflow: "overlay"
  },
  userList: {
    marginRight: "1.563vw",
  },
  userDetail: {
    width: "100%",
    height: "7.813vw",
    padding: "1.042vw",
    marginBottom: "1.250vw"
  },
  userHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  name: {
    width: "13.021vw",
    color: primaryColor[4],
    fontSize: "1.250vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  details: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "0.833vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  buttonStyle: {
    marginTop: "1.250vw",
    "&:not(:last-child)": {
      marginRight: "1.563vw"
    }
  },

  // Existing User
  existingContent: {
    display: "flex",
    justifyContent: "center"
  },
  existingPaper: {
    ...cardRadius,
    border,
    padding: "1.563vw",
    width: "50.469vw"
  },
  existingLabel: {
    marginBottom: "0.365vw",
    '&:not(:first-child)': {
      marginTop: "1.563vw",
    },
  },
  existingAction: {
    display: "flex"
  },
  textfield: {
    width: "30.208vw",
    marginRight: "13.802vw"
  },
  existingButton: {
    ...buttonSecondary,
    margin: "1.563vw 0 0",
  },
  summaryModal: {
    height: "fit-content",
    maxHeight: "31.250vw"
  },
  summaryContent: {
    textAlign: "left",
    height: "fit-content",
    maxHeight: "13.021vw",
    overflow: "overlay"
  },
  summaryLabel: {
    margin: "1.042vw 0 0",
    fontWeight: 500,
  },

  // Batch Update
  batchPaper: {
    ...cardRadius,
    textAlign: "center",
    padding: "3.021vw"
  },
  img: {
    margin: "auto",
    width: "26.406vw",
    height: "19.479vw"
  },
  batchTitle:{
    margin: "1.250vw",
    fontWeight: 500,
    fontSize: "1.771vw",
    color: primaryColor[4],
  },
  batchDesc: {
    fontSize: "1.094vw",
    color: "rgba(17, 42, 17, 0.7)",
    marginBottom: "1.250vw"
  },
  batchButton: {
    ...buttonSecondary,
    margin: "0 0.833vw",
  },

  // Edit User
  editContent: {
    width: "48.125vw",
    margin: "auto"
  }
}

export default userStyle;