import { createSlice } from "@reduxjs/toolkit";

import { GetWidgetSetting, GetAllWidget, GetMonitoringInfoByBankId } from "services/UserPortal/WidgetService";

// initial state
const initialState = {
  monitoring: {},
  widgets: {},
  bankId: null,
  customerOrderWidget: [],
  finishedGoodsWidget: [],
  rawMaterialWidget: [],
  deliveryWidget: [],
  planningWidget: [],
  operationWidget: [],
  productionMonitoringWidget: {},
  costReportWidget: [],
  warehouseWidget: [],
  equipmentWidget: [],
  alarmAndNotiWidget: []
};

// create reducer and action creators
const userHome = createSlice({
  name: "userHome",
  initialState,
  reducers: {
    setValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      })
    },
    reset: () => initialState,
  },
  extraReducers: {
    [GetMonitoringInfoByBankId.fulfilled]: (state, action) => {
      state.monitoring = action.payload.result;
    },
    [GetWidgetSetting.fulfilled]: (state, action) => {
      state.widgets = action.payload.result ?? {};
    },
    [GetAllWidget.fulfilled]: (state, action) => {
      Object.keys(action.payload.result).forEach((key) => {
        if (key !== "id" || key !== "farmId") {
          state[key] = action.payload.result[key] ?? [];
        }
      })
    },
  }
});

// export actions
export const { setValues, reset } = userHome.actions;

// export the reducer
export default userHome.reducer;