// Create Edit Raw Material
// Step 1: Raw Material Details (Raw Material Type + Variant Type)
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";
// @mui/material
import { makeStyles } from "@mui/styles"
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
// core components
import Card from "shared-components/Card/Card";
import TextField from "shared-components/TextField/TextField";
import Select from "shared-components/Select/Select";
import Table from "shared-components/Table/Table";
import TableRow from "shared-components/Table/TableRow";
import TableCell from "shared-components/Table/TableCell";
import Radio from 'shared-components/Radio/Radio';

import RawMaterialStepper from "./RawMaterialStepper";
import VariantTypeSetting from "./VariantTypeSetting";
import RawMaterialTypeSetting from "./RawMaterialTypeSetting"
import { variantTypeTableHead } from "enums/AdminPortal/TableHeaders";

import settings from "assets/icons/orange/settings.svg";
import styles from "assets/jss/components/AdminPortal/rawMaterialStyle.js";
import { renderValue } from "common/functions";
import { updateRequest } from "./";
import { GetAllRawMaterialType, GetAllRawMaterialVariantType, GetRawMaterialType, GetRawMaterialVariantType, GetProposedRawMaterialRefNo, IsRawMaterialNameExist } from "services/AdminPortal/RawMaterialService";

const useStyles = makeStyles(styles);

export default function RawMaterialCreationForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allRawMaterialType = useSelector(store => store.admin.rawMaterial.allRawMaterialType);
  const allRawMaterialVariantType = useSelector(store => store.admin.rawMaterial.allRawMaterialVariantType);
  const selectedRawMaterial = useSelector(store => store.admin.rawMaterial.selectedRawMaterial);
  const [rawMaterialTypeSetting, setRawMaterialTypeSetting] = React.useState(false);
  const [variantTypeSetting, setVariantTypeSetting] = React.useState(false);
  const [rawMaterialTypeId, setRawMaterialtypeId] = React.useState(false);
  const [IDExist, setIDExist] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleOnChange_text = (e) => {
    dispatch(updateRequest({[e.target.id] : e.target.value}));
  };

  const handleOnChange_select = (e) => {
    dispatch(GetRawMaterialType(e.target.value))
    dispatch(updateRequest({"rawMaterialVariantType": null}));
    setRawMaterialtypeId(e.target.value);
    dispatch(GetAllRawMaterialVariantType(e.target.value));
  };

  const handleOnChange_variantType = (e) => {
    dispatch(GetRawMaterialVariantType(e.target.value));
    const data = {
      rawMaterialTypeId: rawMaterialTypeId,
      variantTypeId: e.target.value,
      id: selectedRawMaterial.id ? selectedRawMaterial.id : 0
    }
    dispatch(GetProposedRawMaterialRefNo(data))
    .then((result) =>{
      if(result.payload.result.isExist) {
        dispatch(updateRequest({"rawMaterialRefNo": result.payload.result.proposedRawMaterialRefNo}));
        setIDExist(true);
        setErrorMsg({field: "rawMaterialRefNo", msg: "Raw Material ID is already used"});
      } else {
        dispatch(updateRequest({"rawMaterialRefNo": result.payload.result.proposedRawMaterialRefNo}));
        setIDExist(false);
        setErrorMsg({field: "", msg: ""});
      }
    })
  };

  const handleButtonClick_next = () => {
    const data = {
      rawMaterialName: selectedRawMaterial.rawMaterialName,
      id: selectedRawMaterial.id ? selectedRawMaterial.id : 0
    }
    dispatch(IsRawMaterialNameExist(data))
    .then((result) =>{
      if(result.payload.result) {
        setErrorMsg({field: "rawMaterialName", msg: "Raw Material Name is already used"});
        return false;
      }
      if (validateFields()) {
        if (selectedRawMaterial.rawMaterialType) {
          dispatch(updateRequest({"rawMaterialTypeId": selectedRawMaterial.rawMaterialType.id}));
        }
        if (selectedRawMaterial.rawMaterialVariantType) {
          dispatch(updateRequest({"rawMaterialVariantTypeId": selectedRawMaterial.rawMaterialVariantType.id}));
        }
        props.setStep(1)
      }
    })
  }

  const validateFields = () => {
    if (_.isEmpty(selectedRawMaterial.rawMaterialName)) {
      setErrorMsg({field: "rawMaterialName", msg: "Please add in Raw Material Name"});
      return false;
    }
    if (_.isEmpty(selectedRawMaterial.rawMaterialType)) {
      setErrorMsg({field: "rawMaterialType", msg: "Please select a Raw Material Type"});
      return false;
    }
    if (_.isEmpty(selectedRawMaterial.rawMaterialVariantType)) {
      setErrorMsg({field: "rawMaterialVariantType", msg: "Please select a Raw Material Variant Type"});
      return false;
    }
    if (IDExist) {
      setErrorMsg({field: "rawMaterialRefNo", msg: "Raw Material ID is already used"});
      return false;
    }
    else {
      setErrorMsg({field: "", msg: ""});
      return true;
    }
  }
 
  // componentDidMount
  React.useEffect(() => {
    dispatch(GetAllRawMaterialType());
  },[]);

  React.useEffect(() => {
    if (selectedRawMaterial.rawMaterialType) {
      dispatch(GetAllRawMaterialVariantType(selectedRawMaterial.rawMaterialType.id));
      setRawMaterialtypeId(selectedRawMaterial.rawMaterialType.id)
    }
  },[selectedRawMaterial.rawMaterialType]);

  if (variantTypeSetting) {
    return <VariantTypeSetting setVariantTypeSetting={setVariantTypeSetting} rawMaterialTypeId={rawMaterialTypeId} rawMaterialTypeName={selectedRawMaterial.rawMaterialType.rawMaterialTypeName}/>
  } else if (rawMaterialTypeSetting) {
    return <RawMaterialTypeSetting setRawMaterialTypeSetting={setRawMaterialTypeSetting}/>
  } else {
    return (
      <React.Fragment>
        <Card 
          title={
            <React.Fragment>
              Step 1: Key in Details for Raw Material Creation
            </React.Fragment>
          }
          subheader={<RawMaterialStepper activeStep={props.step} />}
          cardActions={
            <React.Fragment>
              <Button disabled/>
              <Button
                className={classes.button}
                onClick={()=> handleButtonClick_next()}
              >
                Next Step
              </Button>
            </React.Fragment>
          }
        >
          <div className={classes.content}>
            <div className = {classes.topContent}>
              <div className={classes.columnSpacing}>
                <Paper className={classes.topLeftPaper} elevation={0}>
                  <TextField 
                    label={<React.Fragment>Raw Material Name <span className={classes.orange}>*</span></React.Fragment>}
                    id="rawMaterialName"
                    variant="outlined" 
                    onChange={(e) => handleOnChange_text(e)}
                    placeholder="Enter Raw Material Name"
                    value= {renderValue(selectedRawMaterial.rawMaterialName)}
                    inputProps={{ maxLength: 100 }}
                    errorMsg={errorMsg}
                  />
                  <div className="flex w-full">
                    <div className={classes.leftField}>
                      <Select 
                        label={<React.Fragment>Raw Material Type <span className={classes.orange}>*</span></React.Fragment>}
                        name="rawMaterialType"
                        onChange={(e)=>handleOnChange_select(e)}
                        value={selectedRawMaterial.rawMaterialType && renderValue(selectedRawMaterial.rawMaterialType.id)}
                        placeholder="Please select a raw material type"
                        disabled={props.isEdit}
                        errorMsg={errorMsg}
                      >
                        {allRawMaterialType && allRawMaterialType.map((item,index) => {
                          return <MenuItem key={index} value={item.id}>{item.rawMaterialTypeName}</MenuItem>;
                        })}  
                      </Select>
                    </div>
                    <IconButton 
                      className={clsx(classes.iconButton, classes.rightField)}
                      onClick={() => setRawMaterialTypeSetting(true)}
                    >
                    <img className={classes.icon} src={settings} alt="settings" />
                    </IconButton>
                  </div>
                  <TextField 
                    id="rawMaterialRefNo"
                    label="Proposed Raw Material ID"
                    variant="outlined" 
                    disabled
                    value= {renderValue(selectedRawMaterial.rawMaterialRefNo)}
                    errorMsg={errorMsg}
                  />
                </Paper>
                <Paper className={classes.topLeftPaper} elevation={0}>
                  <TextField 
                    label="Raw Material Description"
                    id="rawMaterialDescription"
                    variant="outlined" 
                    minRows={4}
                    multiline
                    placeholder="Please type your raw material description here"
                    inputProps={{ maxLength: 200 }}
                    onChange={(e) => handleOnChange_text(e)}
                    value={renderValue(selectedRawMaterial.rawMaterialDescription)}
                  />
                </Paper>
              </div>
              <Paper elevation={0} className={classes.rawMaterialType}>
                {_.isEmpty(selectedRawMaterial.rawMaterialType) 
                  ?
                  <Typography className={classes.empty}>No Raw Material Type Selected</Typography> 
                  : 
                  <React.Fragment>
                    <div className="flex">
                      <Typography className={classes.rawMaterialTypeTitle}>{selectedRawMaterial.rawMaterialType.rawMaterialTypeName} Variant <span className={classes.orange}>*</span></Typography>
                      <IconButton 
                        className={classes.iconButton}
                        onClick={() => setVariantTypeSetting(true)}
                      >
                      <img className={classes.icon} src={settings} alt="settings" />
                      </IconButton>
                    </div>
                    { errorMsg.field === "rawMaterialVariantType" && <Typography className={classes.error}>{errorMsg.msg}</Typography> }
                    <Table
                    header={variantTypeTableHead}
                    >
                      {_.isEmpty(allRawMaterialVariantType) ? null : allRawMaterialVariantType.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {item.variantTypeName}{" ("}{item.variantTypeShortForm}{")"}
                            </TableCell>
                            <TableCell align="right">
                              <Radio
                                name="rawMaterialVariantType"
                                checked={selectedRawMaterial.rawMaterialVariantType ? item.id === selectedRawMaterial.rawMaterialVariantType.id : false}
                                onChange={(e)=>handleOnChange_variantType(e)}
                                value={item.id}
                                disabled={props.isEdit}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </Table>
                  </React.Fragment>
                }
              </Paper>
            </div>
          </div>
        </Card>
      </React.Fragment>
    )
  }
}
